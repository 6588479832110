import * as _ from 'lodash';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subject } from "rxjs/Subject";
import { takeUntil, share, finalize } from 'rxjs/operators';

import { Page, Member, Worklab, EditionWarning } from '@models';
import { ServiceEditionWarning, ServiceItems } from '@services';

@Component({
    selector: 'edition-warning',
    templateUrl: './edition-warning.html',
    styleUrls: ['./edition-warning.scss']
})
export class EditionWarningComponent implements OnInit, OnDestroy {

    private _item: Page | Member | Worklab;
    @Input()
    set item(value: Page | Member | Worklab) {
        this._item = value;
    }
    get item(): Page | Member | Worklab {
        return this._item;
    }

    public warnings: EditionWarning[];

    private _interval$;
    private _destroyed$ = new Subject();

    constructor(
        private serviceItems: ServiceItems,
        private serviceEditionWarning: ServiceEditionWarning
    ) {
        this.warnings = [];
    }

    ngOnInit() {
        if (_.get(this, 'item.id') && (this.item instanceof Page || this.item instanceof Member || this.item instanceof Worklab)) {
            this.fetchWarningData(this._item);

            // Refresh data every 60 seconds
            this._interval$ = setInterval(() => {
                if (!this._item.isSaving) {
                    this.fetchWarningData(this._item);
                }
            }, 10000);
        }
        else{
            this.ngOnDestroy();
        }
    }

    ngOnDestroy() {
        clearInterval(this._interval$);
        this.warnings = [];

        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }

    trackWarnings(index: number, o: EditionWarning) {
        return o ? o.url : index;
    }

    private fetchWarningData(item: Page | Member | Worklab) {
        this.serviceEditionWarning.addWarningFlag(item)
            .pipe(
                takeUntil(this._destroyed$),
                share()
            ).subscribe(
                (warnings: EditionWarning[]) => {
                    this.warnings = warnings;
                }
            );
    }
}
