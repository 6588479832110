import { Component, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'dialog-preferences-listings.component',
    styleUrls: ['./dialog-preferences-listings.scss'],
    templateUrl: './dialog-preferences-listings.html',
})
export class DialogPreferencesListingsComponent {

    @ViewChild('preferencesTilesComponent', {static: false}) preferencesTilesComponent: any;
    @ViewChild('preferencesTableComponent', {static: false}) preferencesTableComponent: any;
    @ViewChild('preferencesSummariesComponent', {static: false}) preferencesSummariesComponent: any;

    constructor(
        @Inject(MAT_DIALOG_DATA) public listView: string,
        public dialogRef: MatDialogRef<DialogPreferencesListingsComponent>
    ) {}

    savePreferences(): void {
        if (this.listView === 'TILES') {
            this.preferencesTilesComponent.savePreferences();
        } else if(this.listView === 'TABLE') {
            this.preferencesTableComponent.savePreferences();
        } else if(this.listView === 'SUMMARY') {
            this.preferencesSummariesComponent.savePreferences();
        }
    }

    saveConfirmed() {
        this.dialogRef.close();
    }
}
