import * as _ from 'lodash';
import { Deserializable } from './deserializable.model';
import { Item, Page, Member, Worklab } from './item.model';
import { ServiceDatastore } from '@services/datastore.service';
import { TranslateService } from '@ngx-translate/core';

export class UserAction implements Deserializable {
    action: string;
    creator: UserActionExtraData;
    creation_date: string;
    field?: string;

    classAction?: string;
    classFa?: string;
    before?: string;
    after?: string;
    extra?: {
        id: string,
        url: string,
        title: string;
        mid?: string
    };
    value?: string;

    constructor(
        private serviceDatastore: ServiceDatastore,
        private serviceTranslate: TranslateService
    ) {
    }

    deserialize(input: any) {
        input.classAction = '';
        if (input.action === 'UPDATE') {
            if (input.field === 'content') {
                input.action = 'CONTENT_CHANGED';
                input.classAction = 'update';
                input.classFa = 'fas fa-pen';
                input.before = input.value_before;
                input.after = input.value_after;
            } else if (input.field === 'status') {
                input.action = 'STATUS_CHANGED';
                input.classAction = 'update';
                input.classFa = 'fas fa-pen';
                input.value_before = (input.value_before && input.value_before !== '') ? input.value_before : 'EMPTY';
                input.before = this.serviceTranslate.instant('ITEM.PAGE_STATUS.' + input.value_before);
                input.after = this.serviceTranslate.instant('ITEM.PAGE_STATUS.' + input.value_after);
            } else if (input.field === 'islocked' && input.value_after === true) {
                input.action = 'LOCKED';
                input.classAction = 'update';
                input.classFa = 'fas fa-lock';
            } else if (input.field === 'islocked' && input.value_after === false) {
                input.action = 'UNLOCKED';
                input.classAction = 'update';
                input.classFa = 'fas fa-lock-open';
            } else if (input.field === 'isPrivate' && input.value_after === true) {
                input.action = 'ISPRIVATE';
                input.classAction = 'update';
                input.classFa = 'fas fa-user-secret';
            } else if (input.field === 'isPrivate' && input.value_after === false) {
                input.action = 'ISNOTPRIVATE';
                input.classAction = 'update';
                input.classFa = 'fas fa-user-secret';
            } else if (input.field === 'mid') {
                input.action = 'AVATAR_CHANGED';
                input.classAction = 'update';
                input.classFa = 'fas fa-camera';
            } else if (input.field) {
                input.action = _.toUpper(input.field) + '_CHANGED';
                input.before = input.value_before;
                input.after = input.value_after;
                input.classAction = 'update';
                input.classFa = 'fas fa-pen';
            }
        } else if (
            input.action === 'ADD EXPERT' ||
            input.action === 'REMOVE EXPERT'
        ) {
            input.classFa = 'far fa-gem';
            input.extra = new UserActionExtraData(this.serviceDatastore).deserialize(input.expert);
        } else if (
            input.action === 'ADD EXPERTISE ON ITEM' ||
            input.action === 'REMOVE EXPERTISE ON ITEM'
        ) {
            input.classFa = 'far fa-gem';
            input.extra = new UserActionExtraData(this.serviceDatastore).deserialize(input.item);
        } else if (
            input.action === 'ADD CREATOR' ||
            input.action === 'REMOVE CREATOR'
        ) {
            input.extra = new UserActionExtraData(this.serviceDatastore).deserialize(input.newCreator);
            input.classFa = 'fas fa-user-edit';
        } else if (
            input.action === 'ADD ITEM AS CREATOR' ||
            input.action === 'REMOVE ITEM AS CREATOR'
        ) {
            input.extra = new UserActionExtraData(this.serviceDatastore).deserialize(input.item);
            input.classFa = 'fas fa-user-edit';
        } else if (
            input.action === 'ADD LINK TO'
        ) {
            if (_.get(input, 'itemFrom.group') === 'Page' && _.get(input, 'itemTo.group') === 'Page') {
                input.action = 'ADD LINK PAGE TO PAGE';
                input.classFa = 'fas fa-sign-out-alt';
            } else if (_.get(input, 'itemFrom.group') === 'Member' && _.get(input, 'itemTo.group') === 'Page') {
                input.action = 'ADD LINK MEMBER TO PAGE';
                input.classFa = 'fas fa-tags';
            } else if (_.get(input, 'itemFrom.group') === 'Member' && _.get(input, 'itemTo.group') === 'Member') {
                input.action = 'ADD LINK MEMBER TO MEMBER';
                input.classFa = 'fas fa-tags';
            } else if (_.get(input, 'itemFrom.group') === 'Page' && _.get(input, 'itemTo.group') === 'Worklab') {
                input.action = 'ADD LINK PAGE TO WORKLAB';
                input.classFa = 'fas fa-tags';
            } else if (_.get(input, 'itemFrom.group') === 'Member' && _.get(input, 'itemTo.group') === 'Worklab') {
                input.action = 'ADD LINK MEMBER TO WORKLAB';
                input.classFa = 'fas fa-users';
            }
            input.extra = new UserActionExtraData(this.serviceDatastore).deserialize(input.itemTo);
        } else if (
            input.action === 'REMOVE LINK TO'
        ) {
            if (_.get(input, 'itemFrom.group') === 'Page' && _.get(input, 'itemTo.group') === 'Page') {
                input.action = 'REMOVE LINK PAGE TO PAGE';
                input.classFa = 'fas fa-sign-out-alt';
            } else if (_.get(input, 'itemFrom.group') === 'Member' && _.get(input, 'itemTo.group') === 'Page') {
                input.action = 'REMOVE LINK MEMBER TO PAGE';
                input.classFa = 'fas fa-tags';
            } else if (_.get(input, 'itemFrom.group') === 'Member' && _.get(input, 'itemTo.group') === 'Member') {
                input.action = 'REMOVE LINK MEMBER TO MEMBER';
                input.classFa = 'fas fa-tags';
            } else if (_.get(input, 'itemFrom.group') === 'Page' && _.get(input, 'itemTo.group') === 'Worklab') {
                input.action = 'REMOVE LINK PAGE TO WORKLAB';
                input.classFa = 'fas fa-briefcase';
            } else if (_.get(input, 'itemFrom.group') === 'Member' && _.get(input, 'itemTo.group') === 'Worklab') {
                input.action = 'REMOVE LINK MEMBER TO WORKLAB';
                input.classFa = 'fas fa-briefcase';
            }
            input.extra = new UserActionExtraData(this.serviceDatastore).deserialize(input.itemTo);
        } else if (
            input.action === 'ADD LINK FROM'
        ) {
            if (_.get(input, 'itemFrom.group') === 'Page' && _.get(input, 'itemTo.group') === 'Page') {
                input.action = 'ADD LINK PAGE FROM PAGE';
                input.classFa = 'fas fa-sign-out-alt';
            } else if (_.get(input, 'itemFrom.group') === 'Member' && _.get(input, 'itemTo.group') === 'Page') {
                input.action = 'ADD LINK PAGE FROM MEMBER';
                input.classFa = 'fas fa-tags';
            } else if (_.get(input, 'itemFrom.group') === 'Member' && _.get(input, 'itemTo.group') === 'Member') {
                input.action = 'ADD LINK MEMBER FROM MEMBER';
                input.classFa = 'fas fa-tags';
            } else if (_.get(input, 'itemFrom.group') === 'Page' && _.get(input, 'itemTo.group') === 'Worklab') {
                input.action = 'ADD LINK WORKLAB FROM PAGE';
                input.classFa = 'fas fa-briefcase';
            } else if (_.get(input, 'itemFrom.group') === 'Member' && _.get(input, 'itemTo.group') === 'Worklab') {
                input.action = 'ADD LINK WORKLAB FROM MEMBER';
                input.classFa = 'fas fa-briefcase';
            }
            input.extra = new UserActionExtraData(this.serviceDatastore).deserialize(input.itemFrom);
        } else if (
            input.action === 'REMOVE LINK FROM'
        ) {
            if (_.get(input, 'itemFrom.group') === 'Page' && _.get(input, 'itemTo.group') === 'Page') {
                input.action = 'REMOVE LINK PAGE FROM PAGE';
                input.classFa = 'fas fa-sign-out-alt';
            } else if (_.get(input, 'itemFrom.group') === 'Member' && _.get(input, 'itemTo.group') === 'Page') {
                input.action = 'REMOVE LINK PAGE FROM MEMBER';
                input.classFa = 'fas fa-tags';
            } else if (_.get(input, 'itemFrom.group') === 'Member' && _.get(input, 'itemTo.group') === 'Member') {
                input.action = 'REMOVE LINK MEMBER FROM MEMBER';
                input.classFa = 'fas fa-tags';
            } else if (_.get(input, 'itemFrom.group') === 'Member' && _.get(input, 'itemTo.group') === 'Worklab') {
                input.action = 'REMOVE LINK WORKLAB FROM MEMBER';
                input.classFa = 'fas fa-briefcase';
            } else if (_.get(input, 'itemFrom.group') === 'Page' && _.get(input, 'itemTo.group') === 'Worklab') {
                input.action = 'REMOVE LINK WORKLAB FROM PAGE';
                input.classFa = 'fas fa-briefcase';
            }
            input.extra = new UserActionExtraData(this.serviceDatastore).deserialize(input.itemFrom);
        } else if (
            input.action === 'UPDATE DOCUMENT'
        ) {
            input.classAction = 'update';
            input.classFa = 'fas fa-paperclip';
            input.before = input['valueBefore'];
            input.after = input['valueAfter'];
        } else if (
            input.action === 'ADD DOCUMENT'
        ) {
            input.classFa = 'fas fa-paperclip';
            input.value = _.get(input, 'value');
        } else if (
            input.action === 'REMOVE DOCUMENT'
        ) {
            input.classFa = 'fas fa-paperclip';
            input.value = _.get(input, 'value');
        } else if (
            input.action === 'ADD ALIAS FROM TEXT SELECTION'
        ) {
            input.classAction = 'update';
            input.classFa = 'fas fa-align-justify';
        } else if (
            input.action === 'LINK TAG'
        ) {
            input.classAction = 'add';
            input.classFa = 'fas fa-tag';
            input.value = _.get(input, 'value');
        } else if (
            input.action === 'UNLINK TAG'
        ) {
            input.classAction = 'remove';
            input.classFa = 'fas fa-tag';
            input.value = _.get(input, 'value');
        } else if (
            input.action === 'NEW ROLE'
        ) {
            input.classAction = 'update';
            input.classFa = 'fas fa-key';
            const before = input.before;
            const after = input.after;
            input.before = (before) ? this.serviceTranslate.instant(before) : '';
            input.after = (after) ? this.serviceTranslate.instant(after) : '';

        } else {
            input.value = _.get(input, 'value');
        }

        if (_.get(input, 'action', '').substring(0, 3) === 'ADD') {
            input.classAction = 'add';
        } else if (_.get(input, 'action', '').substring(0, 6) === 'REMOVE') {
            input.classAction = 'remove';
        }
        input.action = 'ITEM.USER_ACTIONS.' + input.action;

        input.creator = (input.creator) ? new UserActionExtraData(this.serviceDatastore).deserialize(input.creator) : {};

        Object.assign(this, input);
        return this;
    }
}

export class UserActionExtraData implements Deserializable {
    title: string;
    id: string;
    group: string;
    mid: string;
    newTitle: string;
    removed: boolean;

    constructor(
        private serviceDatastore: ServiceDatastore
    ) {
    }

    deserialize(input: any) {
        input.id = input.id || '';
        input.group = input.group || 'Member';

        let member = this.serviceDatastore.findByTitle(input.title);
        if (!member) {
            member = this.serviceDatastore.find(input.id);
            if (!member) {
                input.removed = true;
            } else {
                input.id = member.id;
                // input.newTitle = member.title;
                input.mid = member.mid;
                input.removed = false;
            }
        } else {
            input.id = member.id;
            // input.newTitle = member.title;
            input.mid = member.mid;
            input.removed = false;
        }

        input.uid = input.id.replace('#', '').replace(':', '-');
        input.url = '/items/' + input.group.toLowerCase() + '/' + input.uid;

        Object.assign(this, input);
        return this;
    }
}

export class UserActionsGrouped {
    date: string;
    values: UserAction[];
}

export class UserActionsFilter {
    add?: boolean;
    remove?: boolean;
    update?: boolean;
    start?: string;
    end?: string;
    author?: string;
}

export interface MatDialogUserActionData {
    item: Item | Page | Member | Worklab;
    userAction: UserAction;
    contentModifications: UserAction[];
}
