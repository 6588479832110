import * as _ from 'lodash';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { first, finalize, takeUntil, filter } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';

import { Item, Role } from '@models';
import { ServiceToaster } from '@src/app/services/toasters.service';
import { ServiceErrors } from '@src/app/services/errors.service';
import { ServiceAliases } from '@src/app/services/aliases.service';
import { ServiceSecurity } from '@services/security.service';
import { RecommendationsService } from '@src/app/services/recommendations.service';
import { MatDialogRecommendationData } from '@src/app/models/recommendation.model';
import { ServiceItems } from '@src/app/services/items.service';

@Component({
    selector: 'dialog-recommendations-on-text-selection',
    styleUrls: ['./dialog-recommendations-on-text-selection.scss'],
    templateUrl: './dialog-recommendations-on-text-selection.html',
})
export class DialogRecommendationsOnTextSelectionComponent implements OnInit, OnDestroy {

    public loading = false;
    public createPageIsGranted = false;
    public printedText: string;
    public recommendedItems: Item[];
    public textIsAliasOrTitle = false;

    private _destroyed$ = new Subject();
    private _allAliasesAndTitles: string[];

    constructor(
        private serviceItems: ServiceItems,
        @Inject(MAT_DIALOG_DATA) public data: MatDialogRecommendationData,
        private serviceToaster: ServiceToaster,
        private serviceErrors: ServiceErrors,
        private serviceAliases: ServiceAliases,
        private serviceSecurity: ServiceSecurity,
        private recommendationsService: RecommendationsService,
        public dialogRef: MatDialogRef<DialogRecommendationsOnTextSelectionComponent>
    ) {
        this.createPageIsGranted = this.serviceSecurity.hasMinimumRole(Role.ROLE_CONTRIBUTOR);
        this.loading = false;
        this.serviceAliases.fetchAllAliasesAndTitles().pipe(
            takeUntil(this._destroyed$)
        ).subscribe(
            (allAliasesAndTitles: string[]) => {
                this.loading = false;
                this._allAliasesAndTitles = allAliasesAndTitles;
            }
        );
    }

    ngOnInit() {
        this.loading = true;
        const text = this.data.text;
        const item = this.data.item;

        const cleanText = this.serviceAliases.cleanAlias(text);
        if (this._allAliasesAndTitles.indexOf(cleanText) !== -1) {
            this.textIsAliasOrTitle = true;
        }

        this.printedText = (text.length > 100) ? text.substring(0, 100) + '...' : text;

        this.recommendationsService.getRecommendedItemsByText(text, item)
            .pipe(
                first(),
                finalize(() => {
                    this.loading = false;
                }),
                takeUntil(this._destroyed$)
            )
            .subscribe(
                (data: Item[]) => {
                    this.recommendedItems = _.orderBy(_.filter(data, function (o: Item) {
                        return o.group === 'Page' || o.group === 'Worklab';
                    }), ['score'], ['desc']);
                }
            );
    }

    trackId(index: number, item: Item) {
        return item ? item.id : index;
    }

    newPage(): void {
        this.serviceSecurity.setCookie('newTitleFromTextSelection', this.data.text);
        this.serviceSecurity.setCookie('sourceUid', this.data.item.uid);
        this.serviceSecurity.setCookie('sourceGroup', this.data.item.group);
        this.close();
        window.open('/items/page/add', '_blank');
    }

    createSynonyme(item: Item): void {
        const alias = _.toLower(this.data.text);

        this.loading = true;
        this.serviceAliases.addAliasFromText(item, alias).pipe(
            finalize(() => {
                this.loading = true;
                this.close();
            }),
            first(),
            takeUntil(this._destroyed$)
        ).subscribe(
            () => {
                const existingItem = this.serviceItems.item;
                this.serviceItems.itemSaved.emit(existingItem);
                this.serviceToaster.success('ITEM.CONTENT.MODAL.ADD_ALIAS', {title: item.title , alias: alias});
            },
            (error: HttpErrorResponse) => {
                this.serviceErrors.handleError('ITEM.CONTENT.MODAL.ADD_ALIAS', error);
            }
        );
    }

    close() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
        this.close();
    }
}
