import * as _ from 'lodash';
import { Component, Input, Attribute } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogConfig, MatDialog } from '@angular/material';

import { Item, DuplicateOptions } from '@models';
import { ServiceSecurity } from '@services';
import { DialogCopyItemComponent } from './dialog-copy-item/dialog-copy-item.component';

@Component({
    selector: 'button-duplicate-item',
    templateUrl: './button-duplicate-item.html',
    styleUrls: ['./button-duplicate-item.scss']
})
export class ButtonDuplicateItemComponent {
    @Input() disabled = false;

    private _item: Item;
    @Input()
    set item(value: Item) {
        this._item = value;
    }
    get item(): Item {
        return this._item;
    }

    public get propagateClick(): 'true' | 'false' {
        return this._propagateClick;
    }
    public set propagateClick(value: 'true' | 'false') {
        this._propagateClick = value;
    }

    constructor(
        private router: Router,
        private _dialog: MatDialog,
        private serviceSecurity: ServiceSecurity,
        @Attribute('propagateClick') private _propagateClick: 'true' | 'false' = 'true'
    ) {
    }

    openModalDuplicateOptions() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = { item: this.item };
        dialogConfig.minWidth = '10%';

        const dialogRef = this._dialog.open(DialogCopyItemComponent, dialogConfig);
        dialogRef.componentInstance.optionsChoosen.subscribe((options: DuplicateOptions) => {
            const uid = this.item.uid;
            const group = this.item.group;

            // Needed to know the origin of the action in case of failure: to go back to this item
            // And needed to know the values of the copied item
            this.serviceSecurity.setCookie('sourceUid', uid);
            this.serviceSecurity.setCookie('sourceGroup', group);

            this.serviceSecurity.setCookie('duplicateItem', 'true');
            this.serviceSecurity.setCookie('duplicateOptions', JSON.stringify(options));
            this.router.navigate(['/items/' + _.toLower(group) + '/add']);
        });
    }
}
