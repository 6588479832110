import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, Inject, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA, MatTabChangeEvent } from '@angular/material';
import { ServiceProsim } from '@src/app/services';
import { ProsimConstant, ProsimDynamic, Mol, ProsimProperty } from '@src/app/models';

@Component({
    selector: 'dialog-prosim-element',
    styleUrls: ['./dialog-prosim-element.scss'],
    templateUrl: './dialog-prosim-element.html',
})
export class DialogProsimElementComponent implements OnInit, OnDestroy {

    public isProsimDataEdition = false;
    public onAddProsimData = new EventEmitter();
    public mols: Mol[];
    public staticProperties: ProsimProperty[];
    public dynamicProperties: ProsimProperty[];
    public temperatureUnits: string[];
    public buttonLabel: string;
    public type: string;
    public selectedIndex = 0;
    public constant = new ProsimConstant();
    public dynamic = new ProsimDynamic();

    private _destroyed$ = new Subject();

    constructor(
        private serviceProsim: ServiceProsim,
        @Inject(MAT_DIALOG_DATA) public sentData: ProsimConstant | ProsimDynamic,
        public dialogRef: MatDialogRef<DialogProsimElementComponent>
    ) {
        this.type = this.sentData.type || 'constant';
        if(!this.sentData.id) {
            this.isProsimDataEdition = false;
            this.sentData.type = this.type;
        } else {
            this.isProsimDataEdition = true;
        }

        if (this.type === 'constant') {
            this.constant = <ProsimConstant> _.cloneDeep(this.sentData);
            if (this.isProsimDataEdition) {
                this.dynamic.id = this.constant.id;
                this.constant.type = 'constant';
            }
        } else {
            this.dynamic = <ProsimDynamic> _.cloneDeep(this.sentData);
            if (this.isProsimDataEdition) {
                this.constant.id = this.dynamic.id;
                this.dynamic.type = 'dynamic';
            }
        }

        this.buttonLabel = (this.isProsimDataEdition) ? 'ITEM.PROSIM.MODAL.SAVE' : 'ITEM.PROSIM.MODAL.INSERT';
        this.selectedIndex = (this.type === 'dynamic') ? 1 : 0;
    }

    tabSelected(tab: MatTabChangeEvent) {
        const tabIndex = tab.index;
        if (tabIndex === 0) {
            this.type = 'constant';

            if (this.dynamic.molKey !== this.constant.molKey) {
                this.constant.molKey = this.dynamic.molKey;
            }

            if (!this.constant.units) {
                this.changeProperty(this.constant);
            } else if (!this.constant.value) {
                this.changeUnit(this.constant);
            }
        } else {
            this.type = 'dynamic';

            if (this.dynamic.molKey !== this.constant.molKey) {
                this.dynamic.molKey = this.constant.molKey;
            }

            if (!this.dynamic.units) {
                this.changeProperty(this.dynamic);
                this.changeTemperatureUnit(this.dynamic);
            }
        }
    }

    changeMol(o: ProsimConstant | ProsimDynamic) {
        if (o.type === 'constant') {
            this.serviceProsim.searchValue(<ProsimConstant> o);
        } else {
            this.serviceProsim.searchTemperatures(<ProsimDynamic> o);
        }
    }

    changeProperty(o: ProsimConstant | ProsimDynamic) {
        this.serviceProsim.searchUnits(o);
    }

    changeUnit(o: ProsimConstant | ProsimDynamic) {
        if (o.type === 'constant') {
            this.serviceProsim.searchValue(<ProsimConstant> o);
        }
    }

    changeTemperatureUnit(o: ProsimDynamic) {
        this.serviceProsim.searchTemperatures(<ProsimDynamic> o);
    }

    ngOnInit() {
        this.mols = this.serviceProsim.getMols();
        this.constant.molKey = this.constant.molKey || _.get(_.first(this.mols), 'key');
        this.dynamic.molKey = this.dynamic.molKey || _.get(_.first(this.mols), 'key');

        this.staticProperties = this.serviceProsim.getStaticProperties();
        this.constant.propertyKey = this.constant.propertyKey || _.get(_.first(this.staticProperties), 'key');

        this.dynamicProperties = this.serviceProsim.getDynamicProperties();
        this.dynamic.propertyKey = this.dynamic.propertyKey || _.get(_.first(this.dynamicProperties), 'key');

        this.temperatureUnits = this.serviceProsim.getTemperatureUnits();
        this.dynamic.temperatureUnit = this.dynamic.temperatureUnit || _.first(this.temperatureUnits);

        if (!this.isProsimDataEdition) {
            this.changeProperty(this.constant);
        }
    }

    onNoClick() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }

    addProsimData() {
        if (this.type === 'constant') {
            this.onAddProsimData.emit(this.constant);
        } else {
            this.onAddProsimData.emit(this.dynamic);
        }
        this.dialogRef.close();
    }
}
