import * as _ from 'lodash';
import { Component, Input, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';

import { Page, Member, Worklab } from '@models';
import { ServiceItems, ServiceAliases, ServiceSecurity, ServiceDynamicLinks } from '@services';

@Component({
    selector: 'item-title',
    templateUrl: './item-title.component.html',
    styleUrls: ['./item-title.component.scss']
})
export class ItemTitleComponent implements OnDestroy {
    @Input()
    set item(value: Page | Member | Worklab) {
        this._item = value;
        this.titleMinSize = this._item.getTitleMinSize();
        this.displayedTitle = _.clone(this.item.title);
        this.handleEditionMode(this._editionMode);
        this.buildDynamicLinks();
    }
    get item(): Page | Member | Worklab {
        return this._item;
    }
    @Input()
    set editionMode(value: boolean) {
        this._editionMode = value;
        this.handleEditionMode(this._editionMode);
    }
    get editionMode(): boolean {
        return this._editionMode;
    }

    constructor(
        private serviceItems: ServiceItems,
        private serviceAliases: ServiceAliases,
        private serviceSecurity: ServiceSecurity,
        private serviceDynamicLinks: ServiceDynamicLinks
    ) {
        this._isAdmin = this.serviceSecurity.isAdmin();
    }

    @ViewChild('title', {static: false}) titleElementRef: ElementRef;

    private _item: Page | Member | Worklab;

    private _editionMode: boolean;

    public isGranted = false;
    public isDisabled = false;
    public tooltip: string;
    public placeholder: string;

    public titleMinSize: number;
    public tooShort = false;
    public titleAlreadyUsed = false;
    public displayedTitle: any; // type SafeHTML

    private _isAdmin = false;
    private _destroyed$ = new Subject();

    private _loadExistingAliasesAndTitles() {
        this.serviceAliases.fetchAllAliasesAndTitles().pipe(
            takeUntil(this._destroyed$)
        ).subscribe();
    }

    protected handleEditionMode(value: boolean) {
        this._setAttributes();
        if (this._item instanceof Page || this._item instanceof Member || this._item instanceof Worklab) {
            if (value === true && this.isGranted === true) {
                this._loadExistingAliasesAndTitles();
                this.titleAlreadyUsed = _.get(this, '_item.errors.title.key') === 'alreadyUsed';
                this.tooShort = _.get(this, '_item.errors.title.key') === 'tooShort';
            } else {
                this._destroyed$.next();
                this._destroyed$.complete();
                // this._destroyed$.unsubscribe();
            }
        }
    }

    private _setAttributes() {
        if (this._item instanceof Page || this._item instanceof Member || this._item instanceof Worklab) {
            const isLocked = this._item.islocked;
            this.isGranted = this.serviceItems.userCanModifyTitle(this._item);

            if (this.isGranted === true) {
                if (this._item.group === 'Member') {
                    this.isDisabled = true;
                    this.tooltip = 'ITEM.TITLE.TOOLTIP.NOT_MEMBER';
                } else if (isLocked === true && !this._isAdmin) {
                    this.isDisabled = true;
                    this.tooltip = 'ITEM.TITLE.TOOLTIP.LOCKED';
                } else {
                    this.tooltip = 'ITEM.TITLE.PLACEHOLDER.' + _.toUpper(this._item.group) + ' *';
                }
            }

            this.placeholder = (this._item instanceof Member) ? 'MEMBER.TITLE_INFO' : 'ITEM.TITLE.PLACEHOLDER.' + _.toUpper(this._item.group);
        }
    }

    checkNewTitle(): void {
        const title = this.item.title;
        this._item.title = title;
        this.serviceItems.item = this._item;
    }

    buildDynamicLinks() {
        if (this.item.dynlinksOut && this.titleElementRef && this.item.id!="#39:240") {
            this.displayedTitle = this.serviceDynamicLinks.getHtmlWithMarkers(this.item.title, this.item.dynlinksOut);
            this.serviceDynamicLinks.insertDynamicLinksInMarkedElements(this.titleElementRef.nativeElement);
        }
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }
}
