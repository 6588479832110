import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import { FilterByComponent } from '../abstract-filter-by.component';

@Component({
    selector: 'filters-by-creation-date',
    templateUrl: './filters-by-creation-date.component.html',
    styleUrls: ['./filters-by-creation-date.component.scss']
})
export class FiltersByCreationDateComponent extends FilterByComponent {
    public startDate: any;
    public endDate: any;

    constructor(
        protected injector: Injector
    ) {
        super(injector);
        this.filterKey = 'first-creation-date';
    }

    addDate(type: string, event: MatDatepickerInputEvent<Date>): void {
        if (type === 'start') {
            this.startDate = moment(event.value).format('YYYY-MM-DD');
        } else if (type === 'end') {
            this.endDate = moment(event.value).format('YYYY-MM-DD');
        }
        this.applyFilters();
    }

    initValue(listFilters: any) {
        const initialValue = _.get(listFilters, this.filterKey);
        if (initialValue && initialValue.start) {
            this.startDate = initialValue['start'];
        }
        if (initialValue && initialValue.end) {
            this.endDate = initialValue['endDate'];
        }
    }

    resetDate(type: string): void {
        if (type === 'start') {
            delete this.startDate;
        } else if (type === 'end') {
            delete this.endDate;
        }
        this.applyFilters();
    }

    applyFilters() {
        let value;
        let filters = this.serviceListings.filters;
        if (this.startDate || this.endDate) {
            value = {};
            if (this.startDate) {
                value['start'] = this.startDate;
            }
            if (this.endDate) {
                value['end'] = this.endDate;
            }

            filters = _.extend(filters, {
                'first-creation-date': value
            });
            this.checkIsDirty(value, this.filterKey);
        } else {
            delete this.serviceListings.filters[this.filterKey];
            this.isFiltersDirty.emit({ filterKey: this.filterKey, isDirty: false });
        }
        this.serviceListings.filters = filters;
        this.serviceListings.dataStoreChanged();
        this.saveFilter();
    }

    reset() {
        delete this.startDate;
        delete this.endDate;

        const filters = this.serviceListings.filters;
        delete filters[this.filterKey];
        this.serviceListings.filters = filters;
        this.isFiltersDirty.emit({ filterKey: this.filterKey, isDirty: false });
    }
}
