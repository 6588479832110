import { Deserializable } from './deserializable.model';

export class Tag implements Deserializable {
    id: string;
    uid: string;
    title: string;
    url: string;
    path: string;
    parent: {
        id: string;
        uid: string;
        title: string;
    };
    children: Tag[];

    deserialize(input: any, currentUser?: any) {
        input.uid = (input.id) ? input.id.replace('#', '').replace(':', '-') : '';
        input.url = (input.uid) ? '/tags/' + input.uid : '';
        input.path = (input.path) ? input.path : input.title;

        Object.assign(this, input);
        return this;
    }
}
