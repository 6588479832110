import * as _ from 'lodash';
import { Injectable, Injector } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';

import { Item } from '@models';

import { CommonService } from '@services/common.service';
import { ServiceItems } from '@services/items.service';
import { ServiceDatastore } from './datastore.service';
import { ServiceSecurity } from './security.service';

@Injectable({
    providedIn: 'root'
})
export class RecommendationsService extends CommonService {

    constructor(
        protected injector: Injector,
        private serviceItems: ServiceItems,
        private serviceSecurity: ServiceSecurity,
        private serviceDatastore: ServiceDatastore
    ) {
        super(injector);
    }

    getRecommendedItems(group: string, item?: Item): Observable<Item[]> {
        const user = this.serviceSecurity.user;
        const serviceDatastore = this.serviceDatastore;
        const itemId = (item) ? item.uid : user.uid;

        return this.http.get(this.urlApi + 'recommendations/' + itemId + '?limit=20&group=' + _.capitalize(group)).pipe(
            map(
                (result: Item[]) => {
                    return result.map((recommendedItem: Item) => {
                        const existingItem = serviceDatastore.find(recommendedItem.id);

                        if (item) {
                            recommendedItem.isLinked = this.isItemLinked(item, recommendedItem, group);
                            const serviceItem = this.serviceItems.getItemService(item.group);
                            recommendedItem.userCanModifyItem = serviceItem.userCanModifyItem<Item>(item);
                        } else {
                            recommendedItem.score = parseFloat(recommendedItem.score) * 100;
                            const serviceItem = this.serviceItems.getItemService(recommendedItem.group);
                            recommendedItem.userCanModifyItem = serviceItem.userCanModifyItem<Item>(recommendedItem);
                        }
                        return new Item(_.extend(recommendedItem, existingItem), user);
                    });
                }
            )
        );
    }

    getRecommendedItemsByText(text: string, item: Item): Observable<Item[]> {
        const user = this.serviceSecurity.user;
        const serviceDatastore = this.serviceDatastore;
        let params = new HttpParams();
         params = params.append('text', text);
        return this.http.get(this.urlApi + 'recommendations/related-items/' + item.uid , {params}).pipe(
            first(),
            map(
                (result: Item[]) => {
                    return result.map((recommendedItem: Item) => {
                        const serviceItem = this.serviceItems.getItemService(recommendedItem.group);
                        const existingItem = serviceDatastore.find(recommendedItem.id);
                        recommendedItem.score = parseFloat(recommendedItem.score) * 100;
                        recommendedItem.userCanModifyItem = serviceItem.userCanModifyItem<Item>(recommendedItem);
                        return new Item(_.extend(recommendedItem, existingItem), user);
                    });
                }
            )
        );
    }

    isItemLinked(item, recommendedItem, recommendationGroup): boolean {
        let isLinked = false;
        if (_.get(item, 'group') === 'Page') {
            if (recommendationGroup === 'Member') {
                isLinked = !_.isUndefined(_.find(_.get(item, 'followers', []), {id: recommendedItem.id, group: 'Member'}));
            } else if (recommendationGroup === 'Page') {
                isLinked = !_.isUndefined(_.find(_.get(item, 'associatedPagesOut', []), {id: recommendedItem.id, group: 'Page'}));
            } else if (recommendationGroup === 'Worklab') {
                isLinked = !_.isUndefined(_.find(_.get(item, 'linkedWorklabs', []), {id: recommendedItem.id, group: 'Worklab'}));
            }
        } else if (_.get(item, 'group') === 'Member') {
            if (recommendationGroup === 'Member') {
                isLinked = !_.isUndefined(_.find(_.get(item, 'followedMembers', []), {id: recommendedItem.id, group: 'Member'}));
            } else if (recommendationGroup === 'Page') {
                isLinked = !_.isUndefined(_.find(_.get(item, 'followedPages', []), {id: recommendedItem.id, group: 'Page'}));
            } else if (recommendationGroup === 'Worklab') {
                isLinked = !_.isUndefined(_.find(_.get(item, 'followedWorklabs', []), {id: recommendedItem.id, group: 'Worklab'}));
            }
        } else if (_.get(item, 'group') === 'Worklab') {
            if (recommendationGroup === 'Member') {
                isLinked = !_.isUndefined(_.find(_.get(item, 'participants', []), {id: recommendedItem.id, group: 'Member'}));
            } else if (recommendationGroup === 'Page') {
                isLinked = !_.isUndefined(_.find(_.get(item, 'linkedPages', []), {id: recommendedItem.id, group: 'Page'}));
            }
        }
        return isLinked;
    }

    getAllItemsKeywords(): Observable<any> {
        return this.http.get(this.urlApi + 'recommendations/all-items-keywords');
    }
}
