import * as _ from 'lodash';
import { Deserializable } from './deserializable.model';
import { Kb, Society } from './kb.model';
import { Item } from './item.model';

export class CookieNavigationHistory implements Deserializable {
    d: string;  // date
    ss: string; // society slug
    st: string; // society title
    ks: string; // kb slug
    kt: string; // kb title
    i: string;  // item oid
    t: string;  // item title
    g: string;  // item group

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}

export class VisitedItem implements Deserializable {
    date: string;
    kb: Kb;
    item: Item;
    isExternal: boolean;

    deserialize(input: any, extra: any) {
        const currentKb = extra.currentKb;
        const sld = extra.sld;
        const  isExternal = (currentKb.slug !== input.ks || currentKb.society.slug !== input.ss);

        const kb = new Kb().deserialize({
            title: input.kt,
            slug: input.ks,
            society: new Society().deserialize({
                title: input.st,
                slug: input.ss
            })
        });

        const urlPrefix = (!isExternal) ? '' : '//' + input.ks + '.' + input.ss + '.' + sld;
        const item = new Item({
            group: input.g,
            title: input.t,
            id: input.i
        });
        item.url = urlPrefix + '/items/' + _.toLower(item.group) + '/' + (item.id || '').replace('#', '').replace(':', '-');

        const navigationEntry = {
            date: input.d,
            kb: kb,
            item: item,
            isExternal: isExternal
        };

        Object.assign(this, navigationEntry);
        return this;
    }
}

export class NavigationTime implements Deserializable {
    dateAgo: string;
    visitedItems: VisitedItem[] = [];

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}