import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter } from '@angular/core';

import { FiltersByGroupComponent } from '@shared/components/filters/filters-by-group/filters-by-group.component';
import { FiltersByTitleComponent } from '@shared/components/filters/filters-by-title/filters-by-title.component';
import { FiltersBySocietyComponent } from '@shared/components/filters/filters-by-society/filters-by-society.component';
import { FiltersByEmailComponent } from '@shared/components/filters/filters-by-email/filters-by-email.component';
import { FiltersByTagComponent } from '@shared/components/filters/filters-by-tag/filters-by-tag.component';
import { FiltersByExpertComponent } from '@shared/components/filters/filters-by-expert/filters-by-expert.component';
import { FiltersByIsExpertComponent } from '@shared/components/filters/filters-by-is-expert/filters-by-is-expert.component';
import { FiltersByIsInactiveMemberComponent } from '@shared/components/filters/filters-by-is-inactive-member/filters-by-is-inactive-member.component';
import { FiltersByRoleComponent } from '@shared/components/filters/filters-by-role/filters-by-role.component';
import { FiltersByHasInactiveExpertComponent } from '@shared/components/filters/filters-by-has-inactive-expert/filters-by-has-inactive-expert.component';
import { FiltersByForumComponent } from '@shared/components/filters/filters-by-forum/filters-by-forum.component';
import { FiltersByModifierComponent } from '@shared/components/filters/filters-by-modifier/filters-by-modifier.component';
import { FiltersByCreatorComponent } from '@shared/components/filters/filters-by-creator/filters-by-creator.component';
import { FiltersByExpertNumberComponent } from '@shared/components/filters/filters-by-expert-number/filters-by-expert-number.component';
import { FiltersByEditionDateComponent } from '@shared/components/filters/filters-by-edition-date/filters-by-edition-date.component';
import { FiltersByCreationDateComponent } from '@shared/components/filters/filters-by-creation-date/filters-by-creation-date.component';
import { FiltersByPageStatusComponent } from '@shared/components/filters/filters-by-page-status/filters-by-page-status.component';
import { FiltersByLinkTypeComponent } from '@shared/components/filters/filters-by-link-type/filters-by-link-type.component';
import { FiltersByKbComponent } from '@shared/components/filters/filters-by-kb/filters-by-kb.component';
import { MapFiltersComponent } from '@shared/components/filters/map-filters/map-filters.component';
import { ServiceListings, ServicePreferences } from '@services';
import { takeUntil, first } from 'rxjs/operators';
import { AppSettings } from '@src/app/app.settings';
import { Subject } from 'rxjs/Subject';
import { FiltersOrderByComponent } from './filters-order-by/filters-order-by.component';


@Component({
    selector: 'filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit, OnDestroy {

    @Input() listViewType: string;
    @Output() filtersToggler = new EventEmitter<boolean>();

    @ViewChild('byMap', {static: false}) map: MapFiltersComponent;
    @ViewChild('byGroup', {static: false}) group: FiltersByGroupComponent;
    @ViewChild('byOrderBy', {static: false}) orderBy: FiltersOrderByComponent;
    @ViewChild('byTitle', {static: false}) title: FiltersByTitleComponent;
    @ViewChild('bySociety', {static: false}) society: FiltersBySocietyComponent;
    @ViewChild('byEmail', {static: false}) email: FiltersByEmailComponent;
    @ViewChild('byTag', {static: false}) tag: FiltersByTagComponent;
    @ViewChild('byExpert', {static: false}) expert: FiltersByExpertComponent;
    @ViewChild('byIsExpert', {static: false}) isExpert: FiltersByIsExpertComponent;
    @ViewChild('byIsInactiveMember', {static: false}) isInactiveMember: FiltersByIsInactiveMemberComponent;
    @ViewChild('byRole', {static: false}) role: FiltersByRoleComponent;
    @ViewChild('byHasInactiveExpert', {static: false}) hasInactiveExpert: FiltersByHasInactiveExpertComponent;
    @ViewChild('byForum', {static: false}) forum: FiltersByForumComponent;
    @ViewChild('byModifier', {static: false}) modifier: FiltersByModifierComponent;
    @ViewChild('byCreator', {static: false}) creator: FiltersByCreatorComponent;
    @ViewChild('byExpertNumber', {static: false}) expertNumber: FiltersByExpertNumberComponent;
    @ViewChild('byEditionDate', {static: false}) editionDate: FiltersByEditionDateComponent;
    @ViewChild('byCreationDate', {static: false}) creationDate: FiltersByCreationDateComponent;
    @ViewChild('byPageStatus', {static: false}) pageStatus: FiltersByPageStatusComponent;
    @ViewChild('byLinkType', {static: false}) linkType: FiltersByLinkTypeComponent;
    @ViewChild('byKb', {static: false}) kb: FiltersByKbComponent;


    public dirtyFilters: any;
    public filtersAreDirty = false;
    private _destroyed$ = new Subject();

    constructor(
        public appSettings: AppSettings,
        public servicePreferences: ServicePreferences,
        public serviceListings: ServiceListings
    ) {
    }

    ngOnInit() {
        const defaultFilters = this.serviceListings.getDefaultFilters();
        const filters = this.serviceListings.filters;
        const dirtyFilters = {groups: false};

        _.forEach(filters, function (value, key) {
            if (JSON.stringify(value) !== JSON.stringify(defaultFilters[key])) {
                dirtyFilters[key] = true;
            }
        });
        this.dirtyFilters = dirtyFilters;
    }

    toggleFilters() {
        this.filtersToggler.emit(true);
    }

    handleIsFilterDirty($event: any) {
        this.dirtyFilters[$event.filterKey] = $event.isDirty;
        this.serviceListings.dirtyFilters = this.dirtyFilters;
    }

    reset() {
        if (this.orderBy) {
            this.orderBy.reset();
        }
        if (this.map) {
            this.map.reset();
        }
        if (this.kb) {
            this.kb.reset();
        }
        if (this.pageStatus) {
            this.pageStatus.reset();
        }
        if (this.linkType) {
            this.linkType.reset();
        }
        if (this.group) {
            this.group.reset();
        }
        if (this.title) {
            this.title.reset();
        }
        if (this.society) {
            this.society.reset();
        }
        if (this.email) {
            this.email.reset();
        }
        if (this.tag) {
            this.tag.reset();
        }
        if (this.expert) {
            this.expert.reset();
        }
        if (this.isExpert) {
            this.isExpert.reset();
        }
        if (this.isInactiveMember) {
            this.isInactiveMember.reset();
        }
        if (this.hasInactiveExpert) {
            this.hasInactiveExpert.reset();
        }
        if (this.forum) {
            this.forum.reset();
        }
        if (this.modifier) {
            this.modifier.reset();
        }
        if (this.creator) {
            this.creator.reset();
        }
        if (this.expertNumber) {
            this.expertNumber.reset();
        }
        if (this.editionDate) {
            this.editionDate.reset();
        }
        if (this.creationDate) {
            this.creationDate.reset();
        }
        delete this.serviceListings.filters['itemIds'];
        this.serviceListings.dataStoreChanged();

        const keepFilters = this.servicePreferences.getPreference('keepFilters');
        if (keepFilters === true) {
            const listingKey = this.serviceListings.getKey();
            this.appSettings.settings.filters[listingKey] = this.serviceListings.filters;
            this.servicePreferences.savePreference({ 'filters': this.appSettings.settings.filters }).pipe(
                first(),
                takeUntil(this._destroyed$)
            ).subscribe();
        }
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }
}
