import * as _ from 'lodash';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { takeUntil } from 'rxjs/operators';

import { Item, Order, PreferencesLinkedBlocks } from '@models';
import { ServiceListings, ServicePreferences } from '@services';

@Component({
    selector: 'items-as-tiles',
    styleUrls: ['./items-as-tiles.component.scss'],
    templateUrl: './items-as-tiles.component.html',
})
export class ItemsAsTilesComponent implements OnInit, OnDestroy {
    @Input() options?: any;
    @Input() listOrder?: Order;

    public visibleItems: Item[];
    public displayedData: any;
    public showNoItem = false;

    public linkedBlocks: PreferencesLinkedBlocks;

    private filters: any;
    private _destroyed$ = new Subject();

    constructor(
        private servicePreferences: ServicePreferences,
        private serviceListings: ServiceListings
    ) {
        this.displayedData = {};
    }

    ngOnInit() {
        this.linkedBlocks = this.servicePreferences.getPreference('tilesLinkedBlocks');

        this.serviceListings.visibleItemsObservable.pipe(
            takeUntil(this._destroyed$)
        ).subscribe((visibleItems: Item[]) => {
            this.visibleItems = visibleItems;
            this.showNoItem = (_.size(visibleItems) === 0);
        });

        this.serviceListings.listingFiltersObservable.pipe(
            takeUntil(this._destroyed$)
        ).subscribe((filters) => {
            this.filters = filters;
            this.setDisplayedData();
        });
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }

    trackIds(index: number, o: Item) {
        // What if from an other KB?
        return o ? o.id : index;
    }

    private setDisplayedData() {

        const filters = this.filters;

        const showPages = (!filters || (_.has(filters, 'groups') && _.get(filters, 'groups').indexOf('Page') !== -1));
        const showMembers = (!filters || (_.has(filters, 'groups') && _.get(filters, 'groups').indexOf('Member') !== -1));
        const showWorklabs = (!filters || (_.has(filters, 'groups') && _.get(filters, 'groups').indexOf('Worklab') !== -1));

        this.displayedData.experts = (showPages);
        this.displayedData.linkedWorklabs = (showPages);
        this.displayedData.associatedPagesOut = (showPages);

        this.displayedData.followers = (showPages || showMembers);
        this.displayedData.creators = (showPages || showWorklabs);
        this.displayedData.modifications = (showPages || showWorklabs);
        this.displayedData.pictureItem = (showPages || showWorklabs);

        this.displayedData.expertises = (showMembers);
        this.displayedData.followedPages = (showMembers);
        this.displayedData.followedMembers = (showMembers);
        this.displayedData.followedWorklabs = (showMembers);
        this.displayedData.roleInDatabase = (showMembers);

        this.displayedData.expertises = (showMembers);
        this.displayedData.expertises = (showMembers);

        this.displayedData.participants = (showWorklabs);
        this.displayedData.linkedPagesToWorklab = (showWorklabs);
    }
}
