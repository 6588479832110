import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { finalize, first, takeUntil } from 'rxjs/operators';

import { Notification } from '@models';
import { ServiceNotifications, ServiceErrors, ServiceToaster } from '@services';
import { Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnDestroy {

    @Output() elementClicked = new EventEmitter();
    @Output() notificationRemoved = new EventEmitter();

    public removing = false;

    private _notification: Notification;
    @Input()
    set notification(value: Notification) {
        this._notification = value;
    }
    get notification(): Notification {
        return this._notification;
    }

    private _destroyed$ = new Subject();

    constructor(
        private serviceErrors: ServiceErrors,
        private serviceToaster: ServiceToaster,
        private serviceNotifications: ServiceNotifications
    ) {
    }

    click() {
        if (!this._notification.isExternal) {
            this.elementClicked.emit();
        }
    }

    removeNotification() {
        this.removing = true;
        this.serviceNotifications.removeNotification(this.notification).pipe(
            finalize(() => {
                this.removing = false;
            }),
            first(),
            takeUntil(this._destroyed$)
        ).subscribe(
            () => {
                this.notificationRemoved.emit(this.notification.id);
                this.serviceToaster.success('NOTIFICATIONS.REMOVE');
            },
            (error: HttpErrorResponse) => {
                this.serviceErrors.handleError('NOTIFICATIONS.REMOVE', error);
            }
        );
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }
}
