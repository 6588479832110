import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { PreferencesLinkedBlocks, PreferencesTableColumns, PreferencesDashboards, PreferencesListingOrder, PreferencesListingFilters } from './models';

export class Settings {
    public name: string;
    public loadingSpinner: boolean;
    public loadingInsideSpinner: boolean;
    public savingInsideSpinner: boolean;
    public fixedHeader: boolean;
    public sidenavIsOpened: boolean;
    public sidenavIsPinned: boolean;
    public sidenavUserBlock: boolean;
    public menu: string;
    public menuType: string;
    public rtl: boolean;

    public allowedSkins: any;

    public defaultListOrders: PreferencesListingOrder;
    public filters: PreferencesListingFilters;

    public dateTimeFormat: string;
    public defaultListingView: string;
    public defaultNbItems: number;
    public defaultListingViewOnItem: string;
    public defaultNbItemsOnItem: number;
    public emailOnTopic: boolean;
    public keepFilters: boolean;
    public language: 'fr' | 'en';
    public oldTitleAsAlias: boolean;
    public allNotifications: boolean;
    public theme: string;
    public summaryFrequency: 'NEVER' | 'WEEKLY' | 'DAILY' | 'MONTHLY';
    public timezone: string;
    public dashboards: PreferencesDashboards;
    public detailLinkedBlocks: PreferencesLinkedBlocks;
    public tilesLinkedBlocks: PreferencesLinkedBlocks;
    public summariesLinkedBlocks: PreferencesLinkedBlocks;
    public tableColumns: PreferencesTableColumns;
    public showTags: boolean;

    public AIresumeCache: {};

    constructor(
    ) {
        this.name = _.get(SOCIETY_PREFERENCES,  'name', 'i2kn');

        this.loadingSpinner = true;
        this.loadingInsideSpinner = false;
        this.savingInsideSpinner = false;
        this.fixedHeader = true;
        this.sidenavIsOpened = true;
        this.sidenavIsPinned = true;
        this.sidenavUserBlock = true;
        this.menu = 'vertical';
        this.menuType = 'default';
        this.rtl = false;
        this.AIresumeCache = {};

        const defaultSkins = [
            {
                'name': 'Emerald Sun',
                'INTERNAL_ID': 'teal-light',
                'enabled': true
            },
            /* {
                'name': 'Red Dwarf',
                'INTERNAL_ID': 'red-light',
                'enabled': true
            },
            {
                'name': 'Purple Dark',
                'INTERNAL_ID': 'pink-dark',
                'enabled': true
            },
            {
                'name': 'Blue Dark',
                'INTERNAL_ID': 'blue-dark',
                'enabled': true
            }, */
            {
                'name': 'Zesty Orange',
                'INTERNAL_ID': 'zesty-orange',
                'enabled': true
            },
            {
                'name': 'Indigo Island',
                'INTERNAL_ID': 'indigo-light',
                'enabled': true
            },
            /* {
                'name': 'Green Dark',
                'INTERNAL_ID': 'green-dark',
                'enabled': false
            } */
        ];

        const societySkins = _.get(SOCIETY_PREFERENCES, 'allowedSkins', []);
        this.allowedSkins = defaultSkins.concat(societySkins);


        this.defaultListOrders = new PreferencesListingOrder();
        this.filters = new PreferencesListingFilters();

        this.dateTimeFormat = _.get(SOCIETY_PREFERENCES, 'dateTimeFormat', 'dd/MM/yyyy HH:mm:ss');
        this.emailOnTopic = _.get(SOCIETY_PREFERENCES, 'emailOnTopic', true);
        this.keepFilters = _.get(SOCIETY_PREFERENCES, 'keepFilters', true);
        this.language = _.get(SOCIETY_PREFERENCES, 'language', 'fr');
        this.oldTitleAsAlias = _.get(SOCIETY_PREFERENCES, 'oldTitleAsAlias', true);
        this.allNotifications = _.get(SOCIETY_PREFERENCES, 'allNotifications', false);
        this.theme = _.get(SOCIETY_PREFERENCES, 'defaultTheme', 'Zesty Orange');
        this.summaryFrequency = _.get(SOCIETY_PREFERENCES, 'defaultSummaryEmailFrequency', 'NEVER');
        this.timezone = _.get(SOCIETY_PREFERENCES, 'defaultTimezone', 'Europe/Paris');

        this.dashboards = new PreferencesDashboards();

        this.detailLinkedBlocks = new PreferencesLinkedBlocks('detail');
        this.tilesLinkedBlocks = new PreferencesLinkedBlocks('tiles');
        this.summariesLinkedBlocks = new PreferencesLinkedBlocks('summaries');
        this.tableColumns = new PreferencesTableColumns();

        this.defaultListingView = _.get(SOCIETY_PREFERENCES, 'defaultListingView', 'TILES');
        this.defaultListingViewOnItem = _.get(SOCIETY_PREFERENCES, 'defaultListingViewOnItem', 'MAP');
        this.defaultNbItems = _.get(SOCIETY_PREFERENCES, 'defaultNbItems', 20);
        this.defaultNbItemsOnItem = _.get(SOCIETY_PREFERENCES, 'defaultNbItemsOnItem', 20);

        this.showTags = _.get(SOCIETY_PREFERENCES, 'showTags', true);
    }
}

@Injectable()
export class AppSettings {
    constructor() { }

    public settings = new Settings();
}
