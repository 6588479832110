import * as _ from 'lodash';
import { Component, OnInit, Input, ElementRef, ViewChild, Attribute, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { finalize, takeUntil, first } from 'rxjs/operators';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import { ServicePictures, ServiceSecurity, ServiceItems, ServiceDatastore, ServiceToaster, ServiceErrors } from '@services';
import { Page, Member, Worklab, Role, Item } from '@models';
import { Subject } from 'rxjs/Subject';

@Component({
    selector: 'picture-item',
    templateUrl: './picture-item.component.html',
    styleUrls: ['./picture-item.component.scss']
})
export class PictureItemComponent implements OnInit, OnDestroy {

    @ViewChild('pictureItemInput', {static: false}) pictureItemInput: ElementRef;

    public canEdit = false;
    public isMember = false;
    public showTrash = false;
    public isInactive = false;
    public isSavingNewPictureItem = false;
    public isRemovingPictureItem = false;
    public title: string;
    public tooltip: string;
    public imageSrc: string;
    public imageAlt: string;
    public defaultPicture: string;
    public uploadForm: FormGroup;

    public get showTooltip(): string {
        return this._showTooltip;
    }
    public set showTooltip(value: string) {
        this._showTooltip = value;
    }

    public get showTitle(): string {
        return this._showTitle;
    }
    public set showTitle(value: string) {
        this._showTitle = value;
    }

    private _file: File;
    private _imagePath: string;
    private _destroyed$ = new Subject();

    private _item: Page | Member | Worklab;
    @Input()
    set item(value: Page | Member | Worklab) {
        this._item = value;
        this._setAttributes();
        this._handleEditionMode(this._editionMode);
    }
    get item(): Page | Member | Worklab {
        return this._item;
    }

    private _editionMode: boolean;
    @Input()
    set editionMode(value: boolean) {
        this._editionMode = value;
        this._handleEditionMode(value);
    }
    get editionMode(): boolean {
        return this._editionMode;
    }

    constructor(
        @Attribute('display') public display: string,
        @Attribute('showTitle') private _showTitle: string = 'false',
        @Attribute('showTooltip') private _showTooltip: string = 'true',
        private serviceToaster: ServiceToaster,
        private serviceErrors: ServiceErrors,
        private router: Router,
        private formBuilder: FormBuilder,
        private serviceItems: ServiceItems,
        private servicePictures: ServicePictures,
        private serviceTranslate: TranslateService,
        private serviceDatastore: ServiceDatastore,
        private serviceSecurity: ServiceSecurity
    ) {
    }

    ngOnInit() {
        this.uploadForm = this.formBuilder.group({ filename: '' });
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }

    clickOnPicture(): void {
        if (this.editionMode === true) {
            this.selectFile();
        } else {
            if (!this._item.removed) {
                this.router.navigate([this._item.url]);
            }
        }
    }

    selectFile(): void {
        this.pictureItemInput.nativeElement.click();
    }

    addPictureItem(event: { target: HTMLInputElement; }) {
        const isMember = this._item.group === 'Member';
        const files = event.target.files;
        const isCreation = _.isUndefined(this._item.id);

        if (this.canEdit && files && files.length > 0) {
            this._file = files[0];

            this.uploadForm.get('filename').setValue(this._file.name);

            if (!isCreation) {
                this.isSavingNewPictureItem = true;
                this.servicePictures.addPictureItem(this._item, this._file).pipe(
                    finalize(() => {
                        this.isSavingNewPictureItem = false;
                        this.uploadForm = this.formBuilder.group({ filename: '' });
                    }),
                    takeUntil(this._destroyed$)
                ).subscribe(
                    (result: HttpResponse<any>) => {
                        if (result instanceof HttpResponse) {
                            if (_.get(result, 'body.type') !== 0) {
                                // Update item
                                if (isMember) {
                                    // mid is the uploaded image for Member and the thumbnail for Page and Worklab
                                    const mid = result.body.mid;
                                    this._item.mid = mid;
                                    this._item.witness.mid = mid;
                                } else {
                                    // cover is the uploaded image for Page and Worklab
                                    const cover = result.body.cover;
                                    this._item.cover = cover;
                                    this._item.witness.cover = cover;
                                }

                                this.serviceItems.item = this._item;

                                // Update current user
                                if (this.serviceSecurity.isCurrentUser(this._item)) {
                                    const mid = result.body.mid;
                                    const user = this.serviceSecurity.user;
                                    user.mid = mid;
                                    this.serviceSecurity.user = user;
                                }

                                this.serviceToaster.success('ITEM.PICTURE.ADD');
                            }
                        }
                    },
                    (error: HttpErrorResponse) => {
                        this.serviceErrors.handleError('ITEM.PICTURE.ADD', error);
                    }
                );
            } else {
                const reader = new FileReader();
                reader.readAsDataURL(this._file);
                reader.onload = (eventLoading: any) => {
                    this.imageSrc = eventLoading.target['result'];
                };

                this._item.newPicture = this._file;
                this.serviceItems.item = this._item;
            }
        }
    }

    removePictureItem() {
        const isMember = this._item.group === 'Member';
        const isCreation = _.isUndefined(this._item.id);

        if (this.canEdit) {
            if (!isCreation) {
                this.isRemovingPictureItem = true;
                this.servicePictures.removePictureItem(this._item).pipe(
                    finalize(() => {
                        this.isRemovingPictureItem = false;
                        this.uploadForm = this.formBuilder.group({ filename: '' });
                    }),
                    first(),
                    takeUntil(this._destroyed$)
                ).subscribe(
                    () => {
                        // Update item
                        if (isMember) {
                            // mid is the uploaded image for Member and the thumbnail for Page and Worklab
                            delete this._item.mid;
                            delete this._item.witness.mid;
                        } else {
                            // cover is the uploaded image for Page and Worklab
                            delete this._item.cover;
                            delete this._item.witness.cover;
                        }
                        this.serviceItems.item = this._item;

                        // Update current user
                        if (isMember && this.serviceSecurity.isCurrentUser(this._item)) {
                            const user = this.serviceSecurity.user;
                            delete user.mid;
                            this.serviceSecurity.user = user;
                        }
                        this.serviceToaster.success('ITEM.PICTURE.REMOVE');
                    },
                    (error: HttpErrorResponse) => {
                        this.serviceErrors.handleError('ITEM.PICTURE.REMOVE', error);
                    }
                );
            } else {
                delete this._item.newPicture;
                delete this._item.witness.newPicture;
                this.serviceItems.item = this._item;
            }
        }
    }

    private _handleEditionMode(value: boolean) {
        if (value === true && (this._item instanceof Page || this._item instanceof Member || this._item instanceof Worklab)) {
            this.canEdit = this.servicePictures.userCanModifyPictureItem(this._item);
        }
    }

    private _setAttributes() {
        const isCreation = _.isUndefined(this._item.id);
        const isMember = this._item.group === 'Member';

        this.isMember = isMember;
        if (isMember) {
            this.title = 'ITEM.PICTURE.AVATAR.TITLE';
            this.tooltip = (this.editionMode === true) ? 'ITEM.PICTURE.AVATAR.TOOLTIP' : this.title;
        } else {
            this.title = 'ITEM.PICTURE.COVER.TITLE';
            this.tooltip = (this.editionMode === true) ? 'ITEM.PICTURE.COVER.TOOLTIP' : this.title;
        }

        if (isCreation && this._item.newPicture) {
            const reader = new FileReader();
            reader.readAsDataURL(this._item.newPicture);
            reader.onload = (event) => {
                this.imageSrc = <string>event.target['result'];
            };
        } else if (isCreation && this._item.newPicture) {
            this.imageSrc = this.defaultPicture;
        } else {

            // mid is the uploaded image for Member and the thumbnail for Page and Worklab
            const mid = this._item.mid;

            // cover is the uploaded image for Page and Worklab
            const cover = this._item.cover;

            // from Search requests, item can be on foreign kbs
            const baseUrl = this.getBaseUrl(this._item);

            if (cover) {
                this._imagePath = '/images/' + _.toLower(this._item.group) + '/';
            } else {
                this._imagePath = (isMember) ? '/images/member/' : '/images/thumbnails/';
            }

            const defaultPicture = (isMember) ? 'no-avatar.png' : 'no-thumbnail.png';
            this.defaultPicture = this._imagePath + defaultPicture;

            if (cover) {
                this.imageSrc = (cover) ? baseUrl + this._imagePath + cover + '.png' : this.defaultPicture;
            } else {
                this.imageSrc = (mid) ? baseUrl + this._imagePath + mid + '.png' : this.defaultPicture;
            }

            if (isMember) {
                if (!(<Member>this._item).role) {
                    const member = this.serviceDatastore.find<Member>(this._item.id);
                    this.isInactive = (!isCreation && (!member || !member.role || member.role === Role['ROLE_NONE']));
                } else {
                    this.isInactive = (!isCreation && ((<Member>this._item).role === Role['ROLE_NONE']));
                }
            }
            this.showTrash = ((isMember && this.imageSrc !== this.defaultPicture) || (!isMember && cover && cover !== ''));
        }

        let imageAlt = this._item.title;
        if (this._item.removed) {
            imageAlt = this.serviceTranslate.instant('ITEM.PICTURE.REMOVED');
        } else if (this.isInactive) {
            imageAlt = this.serviceTranslate.instant('ITEM.PICTURE.INACTIVE');
        }
        this.imageAlt = imageAlt;
    }


    private getBaseUrl(item: Item) {
        let baseUrl = '';
        if (this.isItemExternal(item)) {
            let kbSlug = item.kb;
            let societySlug = item.society;
            baseUrl = '//' + kbSlug + '.' + societySlug + '.' + this.serviceItems.sld;
        }
        return baseUrl;
    }

    private isItemExternal(item: Item) {
        // Can happen with search requests
        const kbSlug = item.kb;
        const societySlug = item.society;
        if (
            kbSlug && societySlug &&
            (
                this.serviceItems.currentKbSlug !== kbSlug ||
                this.serviceItems.currentSocietySlug !== societySlug
            )
        ) {
            return true;
        }
        return false;
    }
}
