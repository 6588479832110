import * as _ from 'lodash';
import { Deserializable } from './deserializable.model';
import { Society, Kb } from './kb.model';
import { ServiceUrls } from '@services/urls.service';

export enum NotificationTypes {
    'UPDATE_ITEM' = 'NOTIFICATIONS.FILTERS.TYPES.UPDATE_ITEM',
    'CREATE_ITEM' = 'NOTIFICATIONS.FILTERS.TYPES.CREATE_ITEM',
    'REMOVE_ITEM' = 'NOTIFICATIONS.FILTERS.TYPES.REMOVE_ITEM',

    // 'LINK_PAGE_PAGE' = 'NOTIFICATIONS.FILTERS.TYPES.LINK_PAGE_PAGE',
    // 'LINK_PAGE_MEMBER' = 'NOTIFICATIONS.FILTERS.TYPES.LINK_PAGE_MEMBER',
    // 'LINK_PAGE_WORKLAB' = 'NOTIFICATIONS.FILTERS.TYPES.LINK_PAGE_WORKLAB',
    //
    // 'LINK_MEMBER_PAGE' = 'NOTIFICATIONS.FILTERS.TYPES.LINK_MEMBER_PAGE',
    // 'LINK_MEMBER_MEMBER' = 'NOTIFICATIONS.FILTERS.TYPES.LINK_MEMBER_MEMBER',
    // 'LINK_MEMBER_WORKLAB' = 'NOTIFICATIONS.FILTERS.TYPES.LINK_MEMBER_WORKLAB',
    //
    // 'LINK_WORKLAB_PAGE' = 'NOTIFICATIONS.FILTERS.TYPES.LINK_WORKLAB_PAGE',
    // 'LINK_WORKLAB_MEMBER' = 'NOTIFICATIONS.FILTERS.TYPES.LINK_WORKLAB_MEMBER',
    //
    // 'UNLINK_PAGE_PAGE' = 'NOTIFICATIONS.FILTERS.TYPES.UNLINK_PAGE_PAGE',
    // 'UNLINK_PAGE_MEMBER' = 'NOTIFICATIONS.FILTERS.TYPES.UNLINK_PAGE_MEMBER',
    // 'UNLINK_PAGE_WORKLAB' = 'NOTIFICATIONS.FILTERS.TYPES.UNLINK_PAGE_WORKLAB',
    //
    // 'UNLINK_MEMBER_PAGE' = 'NOTIFICATIONS.FILTERS.TYPES.UNLINK_MEMBER_PAGE',
    // 'UNLINK_MEMBER_MEMBER' = 'NOTIFICATIONS.FILTERS.TYPES.UNLINK_MEMBER_MEMBER',
    // 'UNLINK_MEMBER_WORKLAB' = 'NOTIFICATIONS.FILTERS.TYPES.UNLINK_MEMBER_WORKLAB',
    //
    // 'UNLINK_WORKLAB_PAGE' = 'NOTIFICATIONS.FILTERS.TYPES.UNLINK_WORKLAB_PAGE',
    // 'UNLINK_WORKLAB_MEMBER' = 'NOTIFICATIONS.FILTERS.TYPES.UNLINK_WORKLAB_MEMBER',

    'ADD_EXPERT' = 'NOTIFICATIONS.FILTERS.TYPES.ADD_EXPERT',
    'REMOVE_EXPERT' = 'NOTIFICATIONS.FILTERS.TYPES.REMOVE_EXPERT',
    'ADD_TOPIC' = 'NOTIFICATIONS.FILTERS.TYPES.ADD_TOPIC',
    // 'ADD_DOCUMENT' = 'NOTIFICATIONS.FILTERS.TYPES.ADD_DOCUMENT',
    // 'UPDATE_DOCUMENT' = 'NOTIFICATIONS.FILTERS.TYPES.UPDATE_DOCUMENT',
    // 'REMOVE_DOCUMENT' = 'NOTIFICATIONS.FILTERS.TYPES.REMOVE_DOCUMENT',
    'REQUESTS_WAITING' = 'NOTIFICATIONS.FILTERS.TYPES.REQUESTS_WAITING'
}

export class NotificationResults {
    size: number;
    data: Notification[];
}

export class Notification implements Deserializable {
    id: number;
    when: any;
    where: any;
    type: string;
    groupType: string;
    fullSlug: string;
    translationKey: string;  // For translation sentense
    translationKeyPre?: string; // For translation sentense
    translationKeyPost?: string; // For translation sentense
    jsonData: any;
    kb: Kb;
    author: NotificationAuthor;
    color: string;
    message: string;
    isExternal: boolean;
    item: {
        title?: string,
        nb?: number,
        url: string,
        queryParams?: any
    };
    item2?: {
        title: string,
        url: string
    };
    item3?: {
        title: string,
        url: string
    };

    constructor(
    ) {
    }

    deserialize(input: any) {
        const currentKbSlug = ServiceUrls.getCurrentKbSlug();
        const currentSocietySlug = ServiceUrls.getCurrentSocietySlug();

        this.id = input.id;

        this.when = input.date.date;

        this.type = input.type;

        this.jsonData = JSON.parse(input.data || {});

        this.kb = new Kb().deserialize({
            slug: input.kbSlug,
            title: input.kbTitle,
            society: new Society().deserialize({
                slug: input.societySlug,
                title: input.societyTitle
            })
        });

        this.fullSlug = input.societySlug + '-' + input.kbSlug;

        this.isExternal = (
            (currentSocietySlug === this.kb.society.slug && currentKbSlug !== this.kb.slug) ||
            (currentSocietySlug !== this.kb.society.slug)
        );

        let mid = _.get(this.jsonData, 'mid', 'no-avatar');
        mid = (_.trim(mid) === '') ? 'no-avatar' : mid;
        this.author = new NotificationAuthor().deserialize({
            title: _.get(this.jsonData, 'user', '?'),
            picture: this.kb.url + '/images/member/' + mid + '.png'
        });

        const domain = (!this.isExternal) ? '' : '//' + this.kb.slug + '.' + this.kb.society.slug + '.' + this.kb.society.sld;
        const kbSlug = this.kb.slug;
        const societySlug = this.kb.society.slug;
        const type = this.type;
        const oid = ServiceUrls.convertOIDtoID(this.jsonData.oid);
        const group = this.jsonData.group;
        const title = this.jsonData.title;
        const url = domain + '/items/' + group + '/' + oid;

        this.item = {
            title: title,
            url: url
        };

        if (_.trim(kbSlug) !== '' && _.trim(societySlug) !== '' && _.trim(type) !== '' && _.trim(group) !== '' && _.trim(oid) !== '') {
            if (type === 'UPDATE ITEM') {
                this.translationKey = 'NOTIFICATIONS.UPDATE_' + _.toUpper(group);
                this.color = 'warn-200';
                this.groupType = 'UPDATE_ITEM';
            } else if (type === 'CREATE ITEM') {
                this.translationKey = 'NOTIFICATIONS.CREATE_' + _.toUpper(group);
                this.color = 'primary';
                this.groupType = 'CREATE_ITEM';
            } else if (type === 'REMOVE ITEM') {
                this.translationKey = 'NOTIFICATIONS.REMOVE_' + _.toUpper(group);
                this.color = 'warn';
                this.groupType = 'REMOVE_ITEM';
            } else if (type === 'LINK ITEM TO' || type === 'LINK ITEM FROM') {
                const group2 = this.jsonData.group2;
                const title2 = this.jsonData.title2;
                const oid2 = ServiceUrls.convertOIDtoID(this.jsonData.oid2);
                const url2 = domain + '/items/' + group2 + '/' + oid2;

                this.item = {
                    title: title2,
                    url: url2
                };

                this.item2 = {
                    title: title,
                    url: url
                };

                if (_.trim(group2) !== '' && _.trim(oid2) !== '') {
                    this.translationKey = (type === 'LINK ITEM FROM') ? 'NOTIFICATIONS.LINKED_FROM' : 'NOTIFICATIONS.LINKED_TO';
                    if (type === 'LINK ITEM FROM') {
                        if (group === 'page' && group2 === 'worklab') {
                            this.translationKey = 'NOTIFICATIONS.LINKED_PAGE_FROM_WORKLAB';
                            this.groupType = 'LINK_PAGE_WORKLAB';
                            this.translationKeyPre = 'NOTIFICATIONS.LINKED_PAGE_FROM_WORKLAB_PRE';
                        } else if (group === 'member' && group2 === 'worklab') {
                            this.translationKey = 'NOTIFICATIONS.LINKED_MEMBER_FROM_WORKLAB';
                            this.groupType = 'LINK_MEMBER_WORKLAB';
                        } else if (group === 'member' && group2 === 'page') {
                            this.translationKey = 'NOTIFICATIONS.LINKED_MEMBER_FROM_PAGE';
                            this.groupType = 'LINK_MEMBER_PAGE';
                        } else if (group === 'member' && group2 === 'member') {
                            this.translationKey = 'NOTIFICATIONS.LINKED_MEMBER_FROM_MEMBER';
                            this.groupType = 'LINK_MEMBER_MEMBER';
                        } else if (group === 'page' && group2 === 'page') {
                            this.translationKeyPre = 'NOTIFICATIONS.LINKED_PAGE_FROM_PAGE';
                            this.groupType = 'LINK_PAGE_PAGE';
                            this.translationKey = 'NOTIFICATIONS.LINKED_PAGE_FROM_PAGE_PRE';
                        }
                    } else {
                        if (group === 'member' && group2 === 'page') {
                            this.translationKey = 'NOTIFICATIONS.LINKED_MEMBER_TO_PAGE';
                            this.groupType = 'LINK_MEMBER_PAGE';
                        } else if (group === 'member' && group2 === 'worklab') {
                            this.translationKey = 'NOTIFICATIONS.LINKED_MEMBER_TO_WORKLAB';
                            this.groupType = 'LINK_MEMBER_WORKLAB';
                        } else if (group === 'page' && group2 === 'worklab') {
                            this.translationKey = 'NOTIFICATIONS.LINKED_PAGE_TO_WORKLAB';
                            this.groupType = 'LINK_PAGE_WORKLAB';
                            this.translationKeyPre = 'NOTIFICATIONS.LINKED_PAGE_TO_WORKLAB_PRE';
                        } else if (group === 'page' && group2 === 'page') {
                            this.translationKey = 'NOTIFICATIONS.LINKED_PAGE_TO_PAGE';
                            this.groupType = 'LINK_PAGE_PAGE';
                            this.translationKeyPre = 'NOTIFICATIONS.LINKED_PAGE_TO_PAGE_PRE';
                        } else if (group === 'member' && group2 === 'member') {
                            this.translationKey = 'NOTIFICATIONS.LINKED_MEMBER_TO_MEMBER';
                            this.groupType = 'LINK_MEMBER_MEMBER';
                        }
                    }
                    this.color = 'primary';
                }
            } else if (type === 'UNLINK ITEM TO' || type === 'UNLINK ITEM FROM') {
                const group2 = this.jsonData.group2;
                const title2 = this.jsonData.title2;
                const oid2 = ServiceUrls.convertOIDtoID(this.jsonData.oid2);
                const url2 = domain + '/items/' + group2 + '/' + oid2;

                this.item = {
                    title: title2,
                    url: url2
                };

                this.item2 = {
                    title: title,
                    url: url
                };

                if (_.trim(group2) !== '' && _.trim(oid2) !== '') {
                    this.translationKey = (type === 'UNLINK ITEM FROM') ? 'NOTIFICATIONS.UNLINKED_FROM' : 'NOTIFICATIONS.UNLINK_TO';
                    if (type === 'UNLINK ITEM FROM') {
                        if (group === 'page' && group2 === 'worklab') {
                            this.translationKey = 'NOTIFICATIONS.UNLINKED_PAGE_FROM_WORKLAB';
                            this.groupType = 'UNLINK_PAGE_WORKLAB';
                            this.translationKeyPre = 'NOTIFICATIONS.UNLINKED_PAGE_FROM_WORKLAB_PRE';
                        } else if (group === 'member' && group2 === 'worklab') {
                            this.translationKey = 'NOTIFICATIONS.UNLINKED_MEMBER_FROM_WORKLAB';
                            this.groupType = 'UNLINK_MEMBER_WORKLAB';
                        } else if (group === 'member' && group2 === 'member') {
                            this.translationKey = 'NOTIFICATIONS.UNLINKED_MEMBER_TO_MEMBER';
                            this.groupType = 'UNLINK_MEMBER_MEMBER';
                        } else if (group === 'member' && group2 === 'page') {
                            this.translationKey = 'NOTIFICATIONS.UNLINKED_MEMBER_TO_PAGE';
                            this.groupType = 'UNLINK_MEMBER_PAGE';
                        } else if (group === 'page' && group2 === 'page') {
                            this.translationKey = 'NOTIFICATIONS.UNLINKED_PAGE_TO_PAGE';
                            this.groupType = 'UNLINK_PAGE_PAGE';
                            this.translationKeyPre = 'NOTIFICATIONS.UNLINK_PAGE_PAGE_PRE';
                        }
                    } else {
                        if (group === 'member' && group2 === 'page') {
                            this.translationKey = 'NOTIFICATIONS.UNLINKED_MEMBER_TO_PAGE';
                            this.groupType = 'UNLINK_MEMBER_PAGE';
                        } else if (group === 'page' && group2 === 'worklab') {
                            this.translationKey = 'NOTIFICATIONS.UNLINKED_PAGE_TO_WORKLAB';
                            this.groupType = 'UNLINK_PAGE_WORKLAB';
                            this.translationKeyPre = 'NOTIFICATIONS.UNLINK_PAGE_WORKLAB_PRE';
                        } else if (group === 'page' && group2 === 'page') {
                            this.translationKeyPre = 'NOTIFICATIONS.UNLINKED_PAGE_TO_PAGE_PRE';
                            this.translationKey = 'NOTIFICATIONS.UNLINKED_PAGE_TO_PAGE';
                            this.groupType = 'UNLINK_PAGE_PAGE';
                        } else if (group === 'member' && group2 === 'worklab') {
                            this.translationKey = 'NOTIFICATIONS.UNLINKED_MEMBER_TO_WORKLAB';
                            this.groupType = 'UNLINK_MEMBER_WORKLAB';
                        } else if (group === 'member' && group2 === 'member') {
                            this.translationKey = 'NOTIFICATIONS.UNLINKED_MEMBER_TO_MEMBER';
                            this.groupType = 'UNLINK_MEMBER_MEMBER';
                        }
                    }
                    this.color = 'warn';
                }
            } else if (type === 'ADD EXPERT' || type === 'ADD EXPERTISE') {
                const group2 = 'Member';
                const title2 = this.jsonData.expertTitle;
                const oid2 = ServiceUrls.convertOIDtoID(this.jsonData.expertId);
                const url2 = domain + '/items/' + group2 + '/' + oid2;

                this.item = {
                    title: title,
                    url: url
                };

                this.item2 = {
                    title: title2,
                    url: url2
                };

                if (_.trim(oid2) !== '' && _.trim(title2) !== '') {
                    this.translationKey = 'NOTIFICATIONS.ADD_EXPERT';
                    this.color = 'primary';
                    this.groupType = 'ADD_EXPERT';
                }
            } else if (type === 'REMOVE EXPERT' || type === 'REMOVE EXPERTISE') {
                const group2 = 'Member';
                const title2 = this.jsonData.expertTitle;
                const oid2 = ServiceUrls.convertOIDtoID(this.jsonData.expertId);
                const url2 = domain + '/items/' + group2 + '/' + oid2;

                this.item2 = {
                    title: title2,
                    url: url2
                };

                if (_.trim(oid2) !== '' && _.trim(title2) !== '') {
                    this.translationKey = 'NOTIFICATIONS.REMOVE_EXPERT';
                    this.color = 'warn';
                    this.groupType = 'REMOVE_EXPERT';
                }
            } else if (type === 'ADD TOPIC') {
                this.translationKey = 'NOTIFICATIONS.ADD_TOPIC';
                this.item.url = this.item.url + '/forum';
                this.color = 'accent';
                this.groupType = 'ADD_TOPIC';
            } else if (type === 'ADD DOCUMENT') {
                const documentTitle = this.jsonData.documentTitle;
                this.item2 = {
                    title: documentTitle,
                    url: ''
                };
                this.translationKeyPre = 'NOTIFICATIONS.ADD_DOCUMENT_PRE';
                this.color = 'warn';
                this.groupType = 'ADD_DOCUMENT';
                this.translationKey = 'NOTIFICATIONS.ADD_DOCUMENT';
            } else if (type === 'CHANGE STATUS') {
                const oldStatus = (this.jsonData.previousStatus && this.jsonData.previousStatus !== '') ? this.jsonData.previousStatus : 'EMPTY';
                const newStatus = this.jsonData.newStatus;

                this.item2 = {
                    title: title,
                    url: url
                };
                this.item = {
                    title: 'ITEM.PAGE_STATUS.' + oldStatus,
                    url: ''
                };

                this.item3 = {
                    title: 'ITEM.PAGE_STATUS.' + newStatus,
                    url: ''
                };

                this.translationKeyPre = 'NOTIFICATIONS.CHANGE_STATUS_PRE';
                this.translationKey = 'NOTIFICATIONS.CHANGE_STATUS';
                this.translationKeyPost = 'NOTIFICATIONS.CHANGE_STATUS_POST';

                this.color = 'warn';
                this.groupType = 'UPDATE_ITEM';
            } else if (type === 'UPDATE DOCUMENT') {
                const oldDocumentTitle = this.jsonData.oldDocumentTitle;
                const newDocumentTitle = this.jsonData.newDocumentTitle;

                this.item2 = {
                    title: oldDocumentTitle,
                    url: ''
                };
                this.item3 = {
                    title: newDocumentTitle,
                    url: ''
                };

                this.translationKeyPre = 'NOTIFICATIONS.UPDATE_DOCUMENT_PRE';
                this.translationKey = 'NOTIFICATIONS.UPDATE_DOCUMENT';
                this.translationKeyPost = 'NOTIFICATIONS.UPDATE_DOCUMENT_POST';

                this.color = 'warn';
                this.groupType = 'UPDATE_DOCUMENT';
            } else if (type === 'REMOVE DOCUMENT') {
                const documentTitle = this.jsonData.documentTitle;
                this.item2 = {
                    title: documentTitle,
                    url: ''
                };
                this.translationKeyPre = 'NOTIFICATIONS.REMOVE_DOCUMENT_PRE';
                this.translationKey = 'NOTIFICATIONS.REMOVE_DOCUMENT';
                this.color = 'warn';
                this.groupType = 'REMOVE_DOCUMENT';
            }
        } else if (_.trim(kbSlug) !== '' && _.trim(societySlug) !== '' && _.trim(type) !== '') {
            if (type === 'REQUESTS WAITING') {
                const nb = this.jsonData.nb || 1;
                this.item = {
                    nb: nb,
                    url: domain + '/kbs/manage',
                    queryParams: {tab: 'validations'}
                };
                if (nb > 1) {
                    this.translationKey = 'NOTIFICATIONS.REQUESTS_WAITING';
                    this.translationKeyPost = 'NOTIFICATIONS.REQUESTS_WAITING_POST';
                    this.translationKeyPre = nb + '';
                } else {
                    this.translationKey = 'NOTIFICATIONS.REQUEST_WAITING';
                    this.translationKeyPost = 'NOTIFICATIONS.REQUEST_WAITING_POST';
                }
                this.color = 'orange';
                this.groupType = 'REQUESTS_WAITING';
            }
        }
        return this;
    }
}

export class NotificationAuthor implements Deserializable {
    title: string;
    picture: string;

    deserialize(input: any) {
        this.title = input.title;
        this.picture = input.picture;
        return this;
    }
}
