export class ForgotPasswordRequest {
    email: string;
    language: string;
}

export class RegistrationRequest {
    email: string;
    lastname: string;
    firstname: string;
    language: string;
    loading?: boolean;
    date?: string;
}

export class RegistrationRequestResult {
    existingUser?: boolean;
}

export class BecomeContributorRequest {
    date: string;
    message: string;
    loading: boolean;
    email: string;
    who: {
        id: string;
        title: string;
    }
}

export class BecomeContributorResponse {
    requestContributor: string;
}

export class BecomeContributorParams {
    comment: string;
}
