import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'button-cancel',
    templateUrl: './button-cancel.html',
    styleUrls: ['./button-cancel.scss']
})
export class ButtonCancelComponent {
    public tooltip: string;

    private _isDirty = false;
    @Input()
    set isDirty(value: boolean) {
        if (!value) {
            this.tooltip = this.serviceTranslate.instant('CANCEL_BUTTON.TOOLTIP_NOT_DIRTY');
        } else {
            this.tooltip = this.serviceTranslate.instant('CANCEL_BUTTON.TOOLTIP_DIRTY');
        }
        this._isDirty = value;
    }
    get isDirty(): boolean {
        return this._isDirty;
    }
    @Output() cancelConfirmed = new EventEmitter();

    constructor(
        private serviceTranslate: TranslateService
    ) {}

    cancel() {
        this.cancelConfirmed.emit(true);
    }
}
