import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ServiceProsim } from '@src/app/services';
import { ProsimDynamic } from '@src/app/models';
import { TranslateService } from '@ngx-translate/core';
import * as d3 from 'd3';

@Component({
    selector: 'dialog-prosim-graph',
    styleUrls: ['./dialog-prosim-graph.scss'],
    templateUrl: './dialog-prosim-graph.html',
})
export class DialogProsimGraphComponent implements OnInit, OnDestroy {
    public molKey: string;
    public molLabel: string;
    public propertyKey: string;
    public propertyLabel: string;
    public upper: number;
    public lower: number;
    public formula: string;
    public loadingGraph = false;
    public loadingFormula = false;
    public formulaError: string;
    public coefficients: any;
    public unit: string;
    public temperatureUnit: string;
    public graphError: string;

    constructor(
        private serviceProsim: ServiceProsim,
        private serviceTranslate: TranslateService,
        @Inject(MAT_DIALOG_DATA) public sentData: ProsimDynamic,
        public dialogRef: MatDialogRef<DialogProsimGraphComponent>
    ) {
    }

    ngOnInit() {
        const self = this;
        this.molKey = this.sentData.molKey;
        this.propertyKey = this.sentData.propertyKey;
        this.upper = this.sentData.upper;
        this.lower = this.sentData.lower;
        this.unit = this.sentData.unit;
        this.temperatureUnit = this.sentData.temperatureUnit;
        this.unit = this.sentData.unit;
        this.unit = this.sentData.unit;

        const dynamicProperties = this.serviceProsim.getDynamicProperties();
        this.propertyLabel = _.get(_.find(dynamicProperties, {
            key: this.propertyKey
        }), 'label');

        const mols = this.serviceProsim.getMols();
        this.molLabel = _.get(_.find(mols, {
            key: this.molKey
        }), 'label');

        this.coefficients = _.reduce(this.sentData.coefficients, function (result, value, index) {
            result.push(String.fromCharCode(65 + parseInt(index, 10)) + ': ' + value);
            return result;
        }, []);

        this.loadingFormula = true;
        this.serviceProsim.getFormula(this.sentData.correlation).toPromise().then(
            function (result: any) {
                self.loadingFormula = false;
                self.formula = 'data:image/png;base64,' + result.value;
            },
            function () {
                self.loadingFormula = false;
                self.formulaError = self.serviceTranslate.instant('ITEM.PROSIM.ERROR');
            }
        );

        if (this.lower !== this.upper) {
            this.loadingGraph = true;
            this.serviceProsim.getDynamicPropertyValues(this.sentData).toPromise().then(
                function (resultGetDynamicPropertyValues) {
                    const series = resultGetDynamicPropertyValues.value;
                    self.loadingGraph = false;

                    // Code v1...
                    // var svg = d3.select("body").append("svg2")
                    const svg = d3.select('#svg');
                    const Tmin = self.lower.toExponential(5);
                    const Tmax = self.upper.toExponential(5);

                    if (svg) {
                        if (Tmin !== Tmax) {
                            // Dimensions of  the canvas
                            const margin = {
                                top: 30,
                                right: 30,
                                bottom: 40,
                                left: 80
                            };
                            const width = + svg.attr('width') - margin.left - margin.right;
                            const height = + svg.attr('height') - margin.top - margin.bottom;

                            // Set the ranges
                            const x = d3.scaleLinear().range([0, width]);
                            const y = d3.scaleLinear().range([height, 0]);

                            // Define the axes
                            const xAxis = d3.axisBottom(x).ticks(series.length);
                            const yAxis = d3.axisLeft(y).ticks(series.length).tickFormat(d3.formatPrefix('.1', 1e6));

                            // Define the line
                            const valueline = d3.line()
                                .x(function (d) {
                                    return x(d.x);
                                })
                                .y(function (d) {
                                    return y(d.y);
                                });

                            // Adds the svg canvas
                            svg.attr('width', width + margin.left + margin.right)
                                .attr('height', height + margin.top + margin.bottom)
                                .append('g')
                                .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

                            // Scale the range of the data
                            x.domain(d3.extent(series, function (d) {
                                return d.x;
                            }));
                            y.domain([0, d3.max(series, function (d) {
                                return d.y;
                            })]);

                            // Add the valueline path.
                            svg.append('path')
                                .attr('class', 'line')
                                .attr('d', valueline(series))
                                .attr('transform', 'translate(' + margin.left + ', 0)');

                            // Add the X Axis
                            svg.append('g')
                                .attr('class', 'x axis')
                                .attr('transform', 'translate(' + margin.left + ',' + height + ')')
                                .call(xAxis
                                    .tickFormat(d3.format('.3e')))
                                .selectAll('text')
                                .style('text-anchor', 'end')
                                .attr('dx', '-.8em')
                                .attr('dy', '.15em')
                                .attr('transform', function (d) {
                                    return 'rotate(-65)';
                                });

                            // Add the text label for the X axis
                            svg.append('text')
                                .attr('transform', 'translate(600, 450)')
                                // .attr('transform', 'translate(' + (width / 2) + margin.left + ' ,' + (height + margin.bottom) + ')')
                                .style('text-anchor', 'middle')
                                .text('Temp : ' + self.temperatureUnit);


                            // Add the Y Axis
                            svg.append('g')
                                .attr('class', 'y axis')
                                .attr('transform', 'translate(' + margin.left + ', 0)') // ' + margin.top+ '
                                .call(yAxis.tickFormat(d3.format('.3e')));

                            // Add the text label for the Y axis
                            svg.append('text')
                                .attr('transform', 'rotate(-90)')
                                .attr('dy', '1em')
                                .style('text-anchor', 'middle')
                                .text(self.unit)
                                .attr('transform', 'translate(100, 5)');
                        } else {
                            // var x, y;
                            // getTDependentPropertyValueOne(mol_id, id_prop, unit, temp_unit, Tmin, function(ret) {
                            //     select = getNthParentOf(e, 2).children[4].childNodes[0];
                            //     var parsed = JSON.parse(ret).data;
                            //     x = parseFloat(Object.keys(parsed[0])[0]);
                            //     y = parseFloat(Object.values(parsed[0])[0]).toFixed(3);
                            //     select.innerHTML = 'HW!';
                            // });
                            // var width = +svg.attr('width');
                            // var height = 40;
                            //
                            // svg.attr('width', width)
                            //     .attr('height', height);
                            // svg.append('text')
                            //     .attr('transform', 'translate(' + (width / 2) + ' ,' + (height / 2) + ')')
                            //     .style('text-anchor', 'middle')
                            //     .text(y + ' ' + unit + ' at ' + Tmin + ' ' + temp_unit);
                        }
                    }
                },
                function () {
                    self.loadingGraph = false;
                    self.graphError = self.serviceTranslate.instant('ITEM.PROSIM.ERROR');
                }
            );
        }
    }

    onNoClick() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        // this._destroyed$.next();
        // this._destroyed$.complete();
        // this._destroyed$.unsubscribe();
    }

    addProsimData() {
        // if (this.type === 'constant') {
        //     this.onAddProsimData.emit(this.constant);
        // } else {
        //     this.onAddProsimData.emit(this.dynamic);
        // }
        // this.dialogRef.close();
    }
}
