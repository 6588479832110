import * as _ from 'lodash';
import { Component, Input, Output } from '@angular/core';
import { finalize, takeUntil, first } from 'rxjs/operators';

import { ServiceItems, ServiceMembers, ServiceExpertises, ServiceSecurity, ServiceErrors, ServiceTextAnalyser } from '@services';
import { Role, Page, Member } from '@models';
import { LinkedItemsComponent } from '../linked-items.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'experts',
    templateUrl: './experts.component.html',
    styleUrls: ['./experts.component.scss']
})
export class ExpertsComponent extends LinkedItemsComponent {

    public canView = false;
    private _page: Page;
    @Input()
    set page(value: Page) {
        this._page = value;
        this.handleEditionMode(this._editionMode);
    }
    get page(): Page {
        return this._page;
    }

    constructor(
        private serviceItems: ServiceItems,
        private serviceMembers: ServiceMembers,
        private serviceErrors: ServiceErrors,
        private serviceExpertises: ServiceExpertises,
        private serviceSecurity: ServiceSecurity,
        protected serviceTextAnalyser: ServiceTextAnalyser
    ) {
        super(serviceMembers, serviceTextAnalyser);
        this.field = 'experts';
        this.canView = this.serviceSecurity.hasMinimumRole(Role.ROLE_READER);
    }

    protected handleOrder(orderDirection: 'asc' | 'desc') {
        if (this._page instanceof Page) {
            this._page.experts = this.serviceMembers.sortArray(this._page.experts, orderDirection);
        }
    }

    protected handleEditionMode(value: boolean) {
        if (this._page instanceof Page) {
            this.isCreation = _.isUndefined(this._page.id);
            this.canEdit = this.serviceExpertises.userCanModifyExperts(this._page);
            if (value === true && this.canEdit === true) {
                this.loadAllPotentialLinkedItems<Member>(this._page.experts, this._page);
            }
        }
    }

    addExpert(expert: Member) {
        expert.isLoading = true;
        this.ctrl.reset();
        this.ctrl.disable();
        this.actionInProgress = true;

        let experts = this._page.experts;
        experts.push(expert);
        experts = this.serviceMembers.sortArray(experts, 'asc');
        this._page.experts = experts;

        if (!this.isCreation) {
            this.serviceExpertises.addExpert(this._page, expert).pipe(
                finalize(() => {
                    this.ctrl.enable();
                    this.actionInProgress = false;
                    this.newLinkInput.nativeElement.focus();
                }),
                first(),
                takeUntil(this._destroyed$)
            ).subscribe(
                () => {
                    expert.isLoading = false;
                    _.remove(this.allPotentialLinkedItems, function (o: Member) { return o.id === expert.id; });

                    this._page.experts = experts;
                    if (this._page.witness) {
                        this._page.witness.experts = experts;
                    }
                    if (this.serviceSecurity.isCurrentUser(expert)) {
                        this._page.isUserExpert = true;
                        if (this._page.witness) {
                            this._page.witness.isUserExpert = true;
                        }
                    }
                    this.serviceItems.item = this._page;
                },
                (error: HttpErrorResponse) => {
                    _.remove(this._page.experts, function (o: Member) { return o.id === expert.id; });

                    this.serviceErrors.handleError('ITEM.EXPERTS.ADD', error);
                });
        } else {
            expert.isLoading = false;
            _.remove(this.allPotentialLinkedItems, function (o: Member) { return o.id === expert.id; });
            this.ctrl.enable();
            this.actionInProgress = false;
            this.newLinkInput.nativeElement.focus();
        }
    }

    removeExpert(expert: Member) {
        expert.isLoading = true;
        this.ctrl.disable();
        this.actionInProgress = true;

        if (!this.isCreation) {
            this.serviceExpertises.removeExpert(this._page, expert).pipe(
                finalize(() => {
                    expert.isLoading = false;
                    this.ctrl.enable();
                    this.actionInProgress = false;
                }),
                first(),
                takeUntil(this._destroyed$)
            ).subscribe(
                () => {
                    this.allPotentialLinkedItems.push(expert);

                    const experts = this._page.experts;
                    _.remove(experts, function (o: Member) { return o.id === expert.id; });

                    this._page.experts = experts;
                    if (this._page.witness) {
                        this._page.witness.experts = experts;
                    }
                    if (this.serviceSecurity.isCurrentUser(expert)) {
                        this._page.isUserExpert = false;
                        if (this._page.witness) {
                            this._page.witness.isUserExpert = false;
                        }
                    }
                    this.serviceItems.item = this._page;
                },
                (error: HttpErrorResponse) => {
                    let experts = this._page.experts;
                    experts.push(expert);
                    experts = this.serviceMembers.sortArray(experts, 'asc');

                    this._page.experts = experts;
                    if (this._page.witness) {
                        this._page.witness.experts = experts;
                    }
                    if (this.serviceSecurity.isCurrentUser(expert)) {
                        this._page.isUserExpert = true;
                        if (this._page.witness) {
                            this._page.witness.isUserExpert = true;
                        }
                    }
                    this.serviceItems.item = this._page;

                    this.serviceErrors.handleError('ITEM.EXPERTS.REMOVE', error);
                }
            );
        } else {
            this.allPotentialLinkedItems.push(expert);
            _.remove(this._page.experts, function (o: Member) { return o.id === expert.id; });
            expert.isLoading = false;
            this.ctrl.enable();
            this.actionInProgress = false;
        }
    }
}
