import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { Item, Page, Member, Worklab } from '@models/item.model';
import { MapNode } from '@models/map-node.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Params } from '@angular/router';
import { MatTableDataSource } from '@angular/material';
import { PreferencesTableColumns } from '../models';
import { ServiceTextAnalyser } from './text-analyser.service';
import { ServiceDatastore } from './datastore.service';
import { AppSettings } from '@app/app.settings';
import { ServiceTabs } from './tabs.service';
import { switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ServiceListings {
    private listingViewSubject: BehaviorSubject<string>;
    listingViewObservable: Observable<string>;

    private listingFiltersSubject: BehaviorSubject<any>;
    listingFiltersObservable: Observable<any>;

    private listingOrderSubject: BehaviorSubject<any>;
    listingOrderObservable: Observable<any>;

    private datasourceSubject: BehaviorSubject<any>;
    datasourceObservable: Observable<any>;

    private visibleItemsSubject: BehaviorSubject<Item[]>;
    visibleItemsObservable: Observable<Item[]>;

    private tableColumnsSubject: BehaviorSubject<PreferencesTableColumns>;
    tableColumnsObservable: Observable<PreferencesTableColumns>;

    public dirtyFiltersObservable: Observable<any>;
    private _dirtyFiltersSubject: BehaviorSubject<any>;

    public showFiltersObservable: Observable<boolean>;
    private _showFiltersSubject: BehaviorSubject<boolean>;

    mapTypeKeywordEnabled = false;
    type: string;
    group: string;
    key: string;
    baseData: any;

    constructor(
        public appSettings: AppSettings,
        private serviceTextAnalyser: ServiceTextAnalyser,
        private serviceDatastore: ServiceDatastore,
        private tabsService: ServiceTabs,
    ) {
        this.listingViewSubject = new BehaviorSubject<string>(null);
        this.listingViewObservable = this.listingViewSubject.asObservable();

        this.listingFiltersSubject = new BehaviorSubject<any>(null);
        this.listingFiltersObservable = this.listingFiltersSubject.asObservable();

        this.listingOrderSubject = new BehaviorSubject<any>(null);
        this.listingOrderObservable = this.listingOrderSubject.asObservable();

        this.datasourceSubject = new BehaviorSubject<any>(null);
        this.datasourceObservable = this.datasourceSubject.asObservable();

        this.visibleItemsSubject = new BehaviorSubject<any>([]);
        this.visibleItemsObservable = this.visibleItemsSubject.asObservable();

        this.tableColumnsSubject = new BehaviorSubject<PreferencesTableColumns>(new PreferencesTableColumns());
        this.tableColumnsObservable = this.tableColumnsSubject.asObservable();

        this._dirtyFiltersSubject = new BehaviorSubject<any>({});
        this.dirtyFiltersObservable = this._dirtyFiltersSubject.asObservable();

        this._showFiltersSubject = new BehaviorSubject<boolean>(false);
        this.showFiltersObservable = this._showFiltersSubject.asObservable();


        this.showFiltersObservable = this.tabsService.tabLabelObservable.pipe(
            //takeUntil(this._destroyed$),
            switchMap((tabLabel: string) => {
               console.log(tabLabel);
                switch (tabLabel) {
                    case 'LINKS':
                        return of(true);
   
                    case 'CONTENT':
                    case 'USER_ACTIONS':
                    case 'AUTHORIZATIONS':
                    case 'PASSWORD':
                    case 'TAGS':

                    default:
                        break;
                }

               
                return of(false);
            })
        );
    }

    private _arrayContainsArray(fullArray: any[], subsetArray: any[]) {
        if (0 === subsetArray.length) {
            return false;
        }
        return subsetArray.every(function (value: any) {
            return (fullArray.indexOf(value) >= 0);
        });
    }

    setDefaultSettings(params: Params, isHomepage: boolean) {
        this.group = this.findGroupInActivatedRoute(params, isHomepage);
        this.type = this.findTypeInActivatedRoute(params, isHomepage);
        this.key = this.getKey();
        this.filters = this.getDefaultFilters();
        this.listingOrder = this.getDefaultListingOrder();
        this.columns = this.getDefaultTableColumns();
    }

    // Listing view (table or tiles)
    get listingView(): string {
        return this.listingViewSubject.value;
    }

    set listingView(value: string) {
        this.listingViewSubject.next(value);
    }

    // Columns
    get columns(): any {
        return this.tableColumnsSubject.value;
    }

    set columns(value: any) {
        this.tableColumnsSubject.next(value);
    }

    getKey() {
        let key;
        const listingType = this.type;
        if ( listingType === 'all') {
            key = 'all';
        } else if ( listingType === 'detail') {
            key = 'detail';
        } else if (this.type && this.group) {
            const listingGroup = _.lowerCase(this.group);
            key = (listingGroup !== listingType) ? listingType + '-' + listingGroup : listingGroup;
        }
        return key || this.type;
    }

    // Filters
    get filters(): any {
        return this.listingFiltersSubject.value;
    }

    set filters(value: any) {
                this.listingFiltersSubject.next(value);
    }

    get dirtyFilters(): any {
        return this._dirtyFiltersSubject.value;
    }

    set dirtyFilters(value: any) {
        this._dirtyFiltersSubject.next(value);
    }

    get showFilters(): boolean {
        return this._showFiltersSubject.value;
    }

    set showFilters(value: boolean) {
        this._showFiltersSubject.next(value);
    }

    public getDefaultFilters() {
        let filters = {};
        if (this.group === 'Page' || this.group === 'Member' || this.group === 'Worklab') {
            filters = {
                groups: [this.group],
                tagOp: 'and'
            };
        } else {
            filters = {
                groups: ['Page', 'Member', 'Worklab'],
                tagOp: 'and'
            };
        }

        if (this.type === 'expertises') {
            filters = _.extend(filters, {
                isUserExpert: true
            });
        } else if (this.type === 'my-creations') {
            filters = _.extend(filters, {
                isUserCreator: true
            });
        } else if (this.type === 'my-modifications') {
            filters = _.extend(filters, {
                isUserModifier: true
            });
        } else if (this.type === 'favorites') {
            if (this.group === 'Worklab') {
                filters = _.extend(filters, {
                    isUserParticipant: true
                });
            } else {
                filters = _.extend(filters, {
                    isUserFollowing: true
                });
            }
        } else if (
            this.type === 'recommendations' ||
            this.type === 'search'
        ) {
            filters = _.extend(filters, {
                isRecommended: true
            });
        } else if (this.type === 'tags') {

        }

        /* if ( this.type === 'detail' && !this.mapTypeKeywordEnabled ) {
            filters = _.extend(filters, {
                enabledKbs: ['meetsys-rd']
            });
            // carefull: on search request, all kb in items results are enabled !
            // otherwise only current kb is enabled by default
        } */

        return filters;
    }

    // Listing order
    get listingOrder(): any {
        return this.listingOrderSubject.value;
    }

    set listingOrder(value: any) {
        this.listingOrderSubject.next(value);
    }

    getDefaultTableColumns(): any {
        const defaultTableColumns = new PreferencesTableColumns();
        return _.get(this, 'appSettings.settings.tableColumns', defaultTableColumns);
    }

    getDefaultListingOrder(): any {
        const defaultOrder = (this.type === 'search') ? { field: 'score', direction: 'desc' } : { field: 'title', direction: 'asc' };
        return _.get(this, 'appSettings.settings.defaultListOrders.' + this.type, defaultOrder);
    }

    changeListingOrder(field: string) {
        let currentOrderField = _.get(this, 'listingOrder.field');
        let currentOrderDirection = _.get(this, 'listingOrder.direction');

        if (field === 'lastModificationDate' || field === 'nbTopics' || field === 'score') {
            if (currentOrderField === field) {
                currentOrderDirection = (!currentOrderDirection || currentOrderDirection === '' || currentOrderDirection === 'asc') ? 'desc' : 'asc';
            } else {
                currentOrderField = field;
                currentOrderDirection = 'desc';
            }
        } else {
            if (currentOrderField === field) {
                currentOrderDirection = (!currentOrderDirection || currentOrderDirection === '' || currentOrderDirection === 'desc') ? 'asc' : 'desc';
            } else {
                currentOrderField = field;
                currentOrderDirection = 'asc';
            }
        }
        const sort = {
            field: field,
            direction: currentOrderDirection
        };

        this.listingOrder = sort;
        const paginator = this.datasource.paginator;
        if (paginator) {
            paginator.pageIndex = 0;
        }
        this.datasource = new MatTableDataSource<any>(this.sortItems(this.datasource.filteredData, sort));
    }

    // Datasource
    get datasource(): any {
        return _.get(this, 'datasourceSubject.value');
    }

    set datasource(value: any) {
        this.datasourceSubject.next(value);
        if (value.paginator) {
            const currentPage = value.paginator.pageIndex;
            const pageSize = value.paginator.pageSize;
            const end = (currentPage + 1) * pageSize;
            const start = currentPage * pageSize;
            this.visibleItems = value.filteredData.slice(start, end);
        } else {
            // First time no pagination yet
            this.visibleItems = value.filteredData.slice(0, 20);
        }
    }

    setBaseData(allItems) {
        this.baseData = allItems;
        this.dataStoreChanged(this.sortItems(this.filterItems(allItems, this.filters), this.listingOrder));
    }

    dataStoreChanged(allItems?: any) {
        if (allItems === undefined) {
            allItems = this.baseData;
        }
        this.datasource = new MatTableDataSource<any>(this.sortItems(this.filterItems(allItems, this.filters), this.listingOrder));
    }

    // Visible Items
    get visibleItems(): any {
        return _.get(this, 'visibleItemsSubject.value');
    }

    set visibleItems(value: any) {
        this.visibleItemsSubject.next(value);
    }

    reset() {
        localStorage.removeItem('prefs');
        this.listingViewSubject.next(null);
        this.listingFiltersSubject.next(null);
        this.listingOrderSubject.next(null);
        this.datasourceSubject.next(null);
        this.visibleItemsSubject.next(null);
        this._dirtyFiltersSubject.next({});
        this._showFiltersSubject.next(false);
    }

    private findGroupInActivatedRoute(params: Params, isHomepage: boolean) {
        if (!isHomepage && !params.id) {
            return _.capitalize(params.group); // Not item detail view (including homepage)
        }
    }

    private findTypeInActivatedRoute(params: Params, isHomepage: boolean) {
        let type = 'all';
        if (isHomepage || params && params.id) {
            type = 'detail'; // Item detail view (including homepage)
        } else if (params && params.tags) {
            type = 'tags';
        } else if (params) {
            type = params.type || params.group || 'all';
        }
        return type;
    }

    private sortItems(items: any[], listingOrder: any) {
        if (!listingOrder) {
            return items;
        }
        const orderByField = listingOrder.field;
        const orderByDirection = listingOrder.direction;
        return _.orderBy(items, [function (o) {
            if (orderByField === 'title') {
                if (_.get(o, 'group') === 'Member') {
                    return _.get(o, 'lastname', _.get(o, 'title')).toUpperCase();
                } else {
                    return _.get(o, 'title').toUpperCase();
                }
            } else if (orderByField === 'group') {
                if (_.get(o, 'group') === 'Page') {
                    return 1;
                } else if (_.get(o, 'group') === 'Member') {
                    return 2;
                } else {
                    return 3;
                }
            } else if (orderByField === 'roleInDatabase') {
                if (_.get(o, 'role') === 'ROLE_READER') {
                    return 1;
                } else if (_.get(o, 'role') === 'ROLE_CONTRIBUTOR') {
                    return 2;
                } else if (_.get(o, 'role') === 'ROLE_ADMIN_KB') {
                    return 3;
                } else if (_.get(o, 'role') === 'ROLE_SOCIETY_ADMIN') {
                    return 4;
                } else {
                    return 5;
                }
            } else if (orderByField === 'pageStatus') {
                const status = _.get(o, 'status');
                if (status === 'draft') {
                    return 1;
                } else if (status === 'to_validate') {
                    return 2;
                } else if (status === 'validated') {
                    return 3;
                } else {
                    return 4;
                }
            } else if (orderByField === 'nbTopics') {
                return _.get(o, 'nbTopics', 0);
            } else if (orderByField === 'isUserFollowing') {
                return (_.get(o, 'isUserFollowing', false) === true);
            } else if (orderByField === 'linkType') {
                return _.first(_.orderBy(_.get(o, 'relations', [])));
            } else if (orderByField === 'tags') {
                return (_.get(_.first(_.orderBy(_.get(o, 'tags'), ['title'], [orderByDirection])), 'title') + '').toUpperCase();
            } else if (
                orderByField === 'aliases'
            ) {
                if (_.size(_.get(o, orderByField, [])) > 0) {
                    const orderedAliases = _.map(_.orderBy(_.get(o, orderByField), function (i: string) {
                        return _.trim(_.toLower(i));
                    }, [orderByDirection]), function (j: string) {
                        return _.trim(_.toLower(j));
                    });
                    const joindAliases = _.join(orderedAliases, '');
                    return (joindAliases === '') ? 'zz' : joindAliases;
                } else {
                    return 'zz';
                }
            } else if (
                orderByField === 'tags'
            ) {
                if (_.size(_.get(o, orderByField, [])) > 0) {
                    const orderedTags = _.map(_.orderBy(_.get(o, orderByField), function (i) {
                        return _.toLower(_.get(i, 'title', ''));
                    }, [orderByDirection]), function (j) {
                        return _.toLower(_.get(j, 'title', ''));
                    });
                    const joindTags = _.join(orderedTags, '');
                    return (joindTags === '') ? 'zz' : joindTags;
                } else {
                    return 'zz';
                }
            } else if (
                orderByField === 'associatedPagesOut' ||
                orderByField === 'linkedWorklabs'
            ) {
                if (_.get(o, 'group') === 'Page') {
                    if (_.size(_.get(o, orderByField, [])) > 0) {
                        const orderedPages = _.map(_.orderBy(_.get(o, orderByField), function (i) {
                            return _.toLower(_.get(i, 'title', ''));
                        }, [orderByDirection]), function (j) {
                            return _.toLower(_.get(j, 'title', ''));
                        });
                        const joindPages = _.join(orderedPages, '');
                        return (joindPages === '') ? 'zz' : joindPages;
                    } else {
                        return 'zz';
                    }
                } else if (_.get(o, 'group') === 'Member') {
                    return (orderByDirection === 'asc') ? 'zzz' : '__';
                } else {
                    return (orderByDirection === 'asc') ? 'zzzz' : '_';
                }
            } else if (
                orderByField === 'expertises' ||
                orderByField === 'followedPages' ||
                orderByField === 'followedWorklabs'
            ) {
                if (_.get(o, 'group') === 'Member') {
                    if (_.size(_.get(o, orderByField, [])) > 0) {
                        const orderedPages = _.map(_.orderBy(_.get(o, orderByField), function (i) {
                            return _.trim(_.toLower(_.get(i, 'title', '')));
                        }, [orderByDirection]), function (j) {
                            return _.trim(_.toLower(_.get(j, 'title', '')));
                        });
                        const joindPages = _.join(orderedPages, '');
                        return (joindPages === '') ? 'zz' : joindPages;
                    } else {
                        return 'zz';
                    }
                } else if (_.get(o, 'group') === 'Page') {
                    return (orderByDirection === 'asc') ? 'zzz' : '__';
                } else {
                    return (orderByDirection === 'asc') ? 'zzzz' : '_';
                }
            } else if (
                orderByField === 'linkedPages'
            ) {
                if (_.get(o, 'group') === 'Worklab') {
                    if (_.size(_.get(o, orderByField, [])) > 0) {
                        const orderedPages = _.map(_.orderBy(_.get(o, orderByField), function (i) {
                            return _.toLower(_.get(i, 'title', ''));
                        }, [orderByDirection]), function (j) {
                            return _.toLower(_.get(j, 'title', ''));
                        });
                        const joindPages = _.join(orderedPages, '');
                        return (joindPages === '') ? 'zz' : joindPages;
                    } else {
                        return 'zz';
                    }
                } else if (_.get(o, 'group') === 'Page') {
                    return (orderByDirection === 'asc') ? 'zzz' : '__';
                } else {
                    return (orderByDirection === 'asc') ? 'zzzz' : '_';
                }
            } else if (orderByField === 'experts') {
                if (_.get(o, 'group') === 'Page') {
                    if (_.size(_.get(o, orderByField, [])) > 0) {
                        const orderedPersons = _.map(_.orderBy(_.get(o, orderByField), function (i) {
                            const v = _.toLower(_.get(i, 'lastname', '') + _.get(i, 'firstname', ''));
                            return (v === '') ? _.toLower(_.get(i, 'title', '')) : v;
                        }, [orderByDirection]), function (j) {
                            const v = _.toLower(_.get(j, 'lastname', '') + _.get(j, 'firstname', ''));
                            return (v === '') ? _.toLower(_.get(j, 'title', '')) : j;
                        });
                        const joindPersons = _.join(orderedPersons, '');
                        return (joindPersons === '') ? 'zz' : joindPersons;
                    } else {
                        return 'zz';
                    }
                } else if (_.get(o, 'group') === 'Page') {
                    return (orderByDirection === 'asc') ? 'zzz' : '__';
                } else {
                    return (orderByDirection === 'asc') ? 'zzzz' : '_';
                }
            } else if (orderByField === 'nbExperts') {
                if (_.get(o, 'group') === 'Page') {
                    const len = _.get(o, 'experts.length', 0);
                    return (orderByDirection === 'asc') ? -len : +len;
                } else {
                    return -9999; // (orderByDirection === 'asc') ? -9999 : 9999;
                }
            } else if (orderByField === 'creators') {
                if (_.size(_.get(o, orderByField, [])) > 0) {
                    const orderedPersons = _.map(_.orderBy(_.get(o, orderByField), function (i) {
                        const v = _.toLower(_.get(i, 'lastname', '') + _.get(i, 'firstname', ''));
                        return (v === '') ? _.toLower(_.get(i, 'title', '')) : v;
                    }, [orderByDirection]), function (j) {
                        const v = _.toLower(_.get(j, 'lastname', '') + _.get(j, 'firstname', ''));
                        return (v === '') ? _.toLower(_.get(j, 'title', '')) : j;
                    });
                    const joindPersons = _.join(orderedPersons, '');
                    return (joindPersons === '') ? 'zz' : joindPersons;
                } else {
                    return 'zz';
                }
            } else if (orderByField === 'followedMembers') {
                if (_.get(o, 'group') === 'Member') {
                    if (_.size(_.get(o, orderByField, [])) > 0) {
                        const orderedPersons = _.map(_.orderBy(_.get(o, orderByField), function (i) {
                            const v = _.toLower(_.get(i, 'lastname', '') + _.get(i, 'firstname', ''));
                            return (v === '') ? _.toLower(_.get(i, 'title', '')) : v;
                        }, [orderByDirection]), function (j) {
                            const v = _.toLower(_.get(j, 'lastname', '') + _.get(j, 'firstname', ''));
                            return (v === '') ? _.toLower(_.get(j, 'title', '')) : j;
                        });
                        const joindPersons = _.join(orderedPersons, '');
                        return (joindPersons === '') ? 'zz' : joindPersons;
                    } else {
                        return 'zz';
                    }
                } else if (_.get(o, 'group') === 'Page') {
                    return (orderByDirection === 'asc') ? 'zzz' : '__';
                } else {
                    return (orderByDirection === 'asc') ? 'zzzz' : '_';
                }
            } else if (orderByField === 'participants') {
                if (_.get(o, 'group') === 'Worklab') {
                    if (_.size(_.get(o, orderByField, [])) > 0) {
                        const orderedPersons = _.map(_.orderBy(_.get(o, orderByField), function (i) {
                            const v = _.toLower(_.get(i, 'lastname', '') + _.get(i, 'firstname', ''));
                            return (v === '') ? _.toLower(_.get(i, 'title', '')) : v;
                        }, [orderByDirection]), function (j) {
                            const v = _.toLower(_.get(j, 'lastname', '') + _.get(j, 'firstname', ''));
                            return (v === '') ? _.toLower(_.get(j, 'title', '')) : j;
                        });
                        const joindPersons = _.join(orderedPersons, '');
                        return (joindPersons === '') ? 'zz' : joindPersons;
                    } else {
                        return 'zz';
                    }
                } else if (_.get(o, 'group') === 'Page') {
                    return (orderByDirection === 'asc') ? 'zzz' : '__';
                } else {
                    return (orderByDirection === 'asc') ? 'zzzz' : '_';
                }
            } else if (orderByField === 'followers') {
                if (_.get(o, 'group') === 'Page' || _.get(o, 'group') === 'Member') {
                    if (_.size(_.get(o, orderByField, [])) > 0) {
                        const orderedPersons = _.map(_.orderBy(_.get(o, orderByField), function (i) {
                            const v = _.toLower(_.get(i, 'lastname', '') + _.get(i, 'firstname', ''));
                            return (v === '') ? _.toLower(_.get(i, 'title', '')) : v;
                        }, [orderByDirection]), function (j) {
                            const v = _.toLower(_.get(j, 'lastname', '') + _.get(j, 'firstname', ''));
                            return (v === '') ? _.toLower(_.get(j, 'title', '')) : j;
                        });
                        const joindPersons = _.join(orderedPersons, '');
                        return (joindPersons === '') ? 'zz' : joindPersons;
                    } else {
                        return 'zz';
                    }
                } else {
                    return (orderByDirection === 'asc') ? 'zzz' : '__';
                }
                // } else if (orderByField === 'linked-members-in') {
                //     return _.join(_.map(_.get(o, 'linksIn', []), function(i) {
                //         return _.toLower(_.get(i, 'lastname', '') + _.get(i, 'firstname', '') + _.get(i, 'title', ''));
                //     }).sort(), '');
                // } else if (orderByField === 'lastCreator') {
                //     return _.join(_.map(_.get(o, 'creators', []), function(i) {
                //         return _.toLower(_.get(i, 'lastname', '') + _.get(i, 'firstname', ''));
                //     }).sort(), '');
                // } else if (orderByField === 'lastCreationDate') {
                //     return (_.get(_.first(_.orderBy(_.get(o, 'creators'), ['date'], [orderByDirection])), 'date') + '').substring(0, 19);
                // } else if (orderByField === 'lastModifier') {
                //     return _.join(_.map(_.get(o, 'modifiers', []), function(i) {
                //         return _.toLower(_.get(i, 'lastname', '') + _.get(i, 'firstname', ''));
                //     }).sort(), '');
            } else if (orderByField === 'lastModificationDate') {
                if (_.size(_.get(o, 'modifiers', [])) === 0 || o.group === 'Member') {
                    return (orderByDirection === 'asc') ? '9999-99-99T99:99:99' : '0000-00-00T00:00:00';
                }
                return (_.get(_.first(_.orderBy(_.get(o, 'modifiers'), ['date'], [orderByDirection])), 'date') + '').substring(0, 19);
            } else if (orderByField === 'creationDate') {
                if (_.size(_.get(o, 'creators', [])) === 0 || o.group === 'Member') {
                    return (orderByDirection === 'asc') ? '9999-99-99T99:99:99' : '0000-00-00T00:00:00';
                }
                return (_.get(_.first(_.orderBy(_.get(o, 'creators'), ['date'], [orderByDirection])), 'date') + '').substring(0, 19);
            } else if (orderByField === 'score') {
                return _.get(o, 'score', 0);
            }
            return _.get(o, 'title').toUpperCase();
        }, function (o) {
            return _.get(o, 'title').toUpperCase();
        }], [orderByDirection, 'asc']);
    }

    private filterItems(items: Item[], filters): Item[] {
        let result = _.filter(items, (item: Item) => {
            let isAccepted = true;
            const itemGroup = _.get(item, 'group');

            if (_.get(filters, 'groups', ['Page', 'Member', 'Worklab']).indexOf(itemGroup) === -1) {
                return false;
            }

            if (_.get(filters, 'isUserExpert') === true && !(<Page>item).isUserExpert) {
                return false;
            }

            if (_.get(filters, 'isUserCreator') === true && !item.isUserCreator) {
                return false;
            }

            if (_.get(filters, 'isUserModifier') === true && !item.isUserModifier) {
                return false;
            }

            if (_.get(filters, 'isUserFollowing') === true) {
                if (itemGroup === 'Page') {
                    isAccepted = isAccepted && (<Page>item).isUserFollowing;
                } else if (itemGroup === 'Member') {
                    isAccepted = isAccepted && (<Member>item).isUserFollowing;
                } else {
                    return false;
                }
                if (!isAccepted) {
                    return false;
                }
            }

            if (_.get(filters, 'isRecommended') === true && !(<Item>item).score) {
                if ((<Item>item).score ===  0) {
                    return false;
                }
            }

            if (_.get(filters, 'isUserParticipant') === true && !(<Worklab>item).isUserParticipant) {
                return false;
            }

            if (_.get(filters, 'is-expert') === true && _.size((<Member>item).expertises) === 0) {
                return false;
            }

            if (_.get(filters, 'is-inactive-member') === true && (<Member>item).role !== 'ROLE_NONE') {
                return false;
            }

            if (_.has(filters, 'selectedRoles') && _.indexOf(_.get(filters, 'selectedRoles', []), (<Member>item).role) === -1) {
                return false;
            }

            if (_.has(filters, 'nb-experts')) {
                const nbExperts = _.get(filters, 'nb-experts');
                if (nbExperts === 'more' && _.size((<Page>item).experts) < 4) {
                    return false;
                } else if (_.isArray(nbExperts)) {
                    if (nbExperts.length > 0 && !nbExperts.includes(_.size((<Page>item).experts))) {
                        return false;
                    }
                } else if (nbExperts !== 'more' && _.size((<Page>item).experts) !== parseInt(_.get(filters, 'nb-experts'), 10)) {
                    return false;
                }
            }

            if (_.get(filters, 'has-inactive-expert') === true) {
                if (item.group !== 'Page') {
                    return false;
                } else {
                    let hasInactiveExpert = false;
                    _.forEach((<Page>item).experts, (e: Member) => {
                        if ((<Member>this.serviceDatastore.find(e.id)).role === 'ROLE_NONE') {
                            hasInactiveExpert = true;
                        }
                    });
                    if (!hasInactiveExpert) {
                        return false;
                    }
                }
            }

            if (_.has(filters, 'last-modification-date')) {
                const expectedLastModification = _.get(filters, 'last-modification-date');
                const lastModification = _.get(_.first(item.modifiers), 'date', '').substring(0, 10);
                if (_.has(expectedLastModification, 'start') && expectedLastModification.start) {
                    isAccepted = isAccepted && (lastModification >= expectedLastModification.start);
                }
                if (_.has(expectedLastModification, 'end') && expectedLastModification.end) {
                    isAccepted = isAccepted && (lastModification <= expectedLastModification.end);
                }
                if (!_.has(expectedLastModification, 'start') && !_.has(expectedLastModification, 'end')) {
                    isAccepted = isAccepted && (lastModification === expectedLastModification);
                }
                if (!isAccepted) {
                    return false;
                }
            }

            if (_.has(filters, 'first-creation-date')) {
                const expectedFirstCreation = _.get(filters, 'first-creation-date');
                const firstModification = _.get(_.first(item.creators), 'date', '').substring(0, 10);
                if (_.has(expectedFirstCreation, 'start') && expectedFirstCreation.start) {
                    isAccepted = isAccepted && (firstModification >= expectedFirstCreation.start);
                }
                if (_.has(expectedFirstCreation, 'end') && expectedFirstCreation.end) {
                    isAccepted = isAccepted && (firstModification <= expectedFirstCreation.end);
                }
                if (!_.has(expectedFirstCreation, 'start') && !_.has(expectedFirstCreation, 'end')) {
                    isAccepted = isAccepted && (firstModification === expectedFirstCreation);
                }
                if (!isAccepted) {
                    return false;
                }
            }

            if (_.has(filters, 'tags') && _.isArray(filters.tags) && filters.tags.length > 0) {
                const filterTagOperator = _.get(filters, 'tagOp', 'and');
                const tagsId = _.map(_.get(item, 'tags', []), 'id');
                if (filterTagOperator === 'and' && !this._arrayContainsArray(tagsId, filters.tags)) {
                    return false;
                } else if (!(_.intersection(filters.tags, tagsId).length > 0)) {
                    return false;
                }
            }

            if (_.has(filters, 'experts') && _.isArray(filters.experts) && filters.experts.length > 0) {
                if (!(_.intersection(filters.experts, _.map(_.get(item, 'experts', []), 'id')).length > 0)) {
                    return false;
                }
            }

            if (_.has(filters, 'modifiers') && _.isArray(filters.modifiers) && filters.modifiers.length > 0) {
                if (!(_.intersection(filters.modifiers, _.map(_.get(item, 'modifiers', []), 'id')).length > 0)) {
                    return false;
                }
            }

            if (_.has(filters, 'creators') && _.isArray(filters.creators) && filters.creators.length > 0) {
                if (!(_.intersection(filters.creators, _.map(_.get(item, 'creators', []), 'id')).length > 0)) {
                    return false;
                }
            }

            if (_.has(filters, 'enabledKbs')) {
                if (!((_.indexOf(filters.enabledKbs, item.society + '-' + item.kb)) !== -1)) {
                    return false;
                }
            }

            if (_.has(filters, 'pageStatus')) {
                if (item.group !== 'Page') {
                    // not a page, status do not exists
                    return false;
                } else if (_.indexOf(filters.pageStatus, (<Page>item).status) === -1) {
                    return false;
                }
            }

            if (_.has(filters, 'title') &&
                this.serviceTextAnalyser.removeDiacritics(item.title.toLowerCase()).indexOf(this.serviceTextAnalyser.removeDiacritics(filters.title.toLowerCase())) === -1
            ) {
                return false;
            }

            if (_.has(filters, 'society') && item.group === 'Member') {
                if (_.isEmpty((<Member>item).company) ||
                    this.serviceTextAnalyser.removeDiacritics(
                        (<Member>item).company.toLowerCase()).indexOf(
                            this.serviceTextAnalyser.removeDiacritics(filters.society.toLowerCase())
                        ) === -1
                ) {
                    return false;
                }
            }

            if (_.has(filters, 'email') && item.group === 'Member') {
                if (_.isEmpty((<Member>item).email) ||
                    this.serviceTextAnalyser.removeDiacritics(
                        (<Member>item).email.toLowerCase()).indexOf(
                            this.serviceTextAnalyser.removeDiacritics(filters.email.toLowerCase())
                        ) === -1
                ) {
                    return false;
                }
            }

            if (_.has(filters, 'forum')) {
                if (filters.forum === 'with' && item.nbTopics === 0) {
                    return false;
                } else if (filters.forum === 'without' && item.nbTopics !== 0) {
                    return false;
                }
            }

            if (_.has(filters, 'linkTypes') && _.intersection(filters.linkTypes, item.linkTypes).length === 0) {
                return false;
            }

            if (_.has(filters, 'itemIds') && _.isArray(filters.itemIds) && filters.itemIds.length > 0) {
                return filters.itemIds.indexOf(item.id) !== -1;
            }

            return true;
        });
        return result;
    }

    isKbVisible(node: MapNode): boolean {
        if (_.has(this.filters, 'enabledKbs')) {
            if (!((_.indexOf(this.filters.enabledKbs, node.societySlug + '-' + node.kbSlug)) !== -1)) {
                return false;
            }
        }
        return true;
    }
}
