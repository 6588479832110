import * as _ from 'lodash';
import { Component, Input } from '@angular/core';
import { finalize, first, takeUntil } from 'rxjs/operators';

import { ServiceItems, ServiceMembers, ServiceCreators, ServiceSecurity, ServiceErrors, ServiceToaster, ServiceTextAnalyser } from '@services';
import { Role, Item, Page, Member, Worklab } from '@models';
import { LinkedItemsComponent } from '../linked-items.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'creators',
    templateUrl: './creators.component.html',
    styleUrls: ['./creators.component.scss']
})
export class CreatorsComponent extends LinkedItemsComponent {

    public canView = false;
    private _item: Item;
    @Input()
    set item(value: Item) {
        this._item = value;
        this.handleEditionMode(this._editionMode);
    }
    get item(): Item {
        return this._item;
    }

    constructor(
        private serviceItems: ServiceItems,
        private serviceErrors: ServiceErrors,
        private serviceMembers: ServiceMembers,
        private serviceToaster: ServiceToaster,
        private serviceCreators: ServiceCreators,
        private serviceSecurity: ServiceSecurity,
        protected serviceTextAnalyser: ServiceTextAnalyser
    ) {
        super(serviceMembers, serviceTextAnalyser);
        this.field = 'creators';
        this.canView = this.serviceSecurity.hasMinimumRole(Role.ROLE_READER);
    }

    protected handleOrder(orderDirection: 'asc' | 'desc') {
        if (this._item instanceof Page || this._item instanceof Worklab) {
            this._item.creators = this.serviceMembers.sortArray(this._item.creators, orderDirection);
        }
    }

    protected handleEditionMode(value: boolean) {
        if (this._item instanceof Page || this._item instanceof Worklab) {
            this.isCreation = _.isUndefined(this._item.id);
            this.canEdit = this.serviceCreators.userCanModifyCreators(this._item);
            if (value === true && this.canEdit === true) {
                this.loadAllPotentialLinkedItems<Member>(this._item.creators, this._item);
            }
        }
    }

    addLinkedItem(creator: Member) {
        creator.isLoading = true;
        this.ctrl.reset();
        this.ctrl.disable();
        this.actionInProgress = true;

        let creators = this._item.creators;
        creators.push(creator);
        creators = this.serviceMembers.sortArray(creators, 'asc');
        this._item.creators = creators;

        if (!this.isCreation) {
            this.serviceCreators.addCreator(this._item, creator).pipe(
                finalize(() => {
                    this.ctrl.enable();
                    this.actionInProgress = false;
                    this.newLinkInput.nativeElement.focus();
                }),
                first(),
                takeUntil(this._destroyed$)
            ).subscribe(
                () => {
                    creator.isLoading = false;
                    _.remove(this.allPotentialLinkedItems, function (o: Member) { return o.id === creator.id; });

                    this._item.creators = creators;
                    if (this._item.witness) {
                        this._item.witness.creators = creators;
                    }
                    if (this.serviceSecurity.isCurrentUser(creator)) {
                        this._item.isUserCreator = true;
                        if (this._item.witness) {
                            this._item.witness.isUserCreator = true;
                        }
                    }
                    this.serviceItems.item = this._item;

                    const params = { creator: _.get(creator, 'title'), title: _.get(this._item, 'title') };
                    this.serviceToaster.success('ITEM.CREATORS.ADD', params);
                },
                (error: HttpErrorResponse) => {
                    _.remove(this._item.creators, function (o: Member) { return o.id === creator.id; });
                    this.serviceErrors.handleError('ITEM.CREATORS.ADD', error);
                }
            );
        } else {
            creator.isLoading = false;
            _.remove(this.allPotentialLinkedItems, function (o: Member) { return o.id === creator.id; });
            this.ctrl.enable();
            this.actionInProgress = false;
            this.newLinkInput.nativeElement.focus();

        }
    }

    removeLinkedItem(creator: Member) {
        creator.isLoading = true;
        this.ctrl.disable();
        this.actionInProgress = true;

        if (!this.isCreation) {
            this.serviceCreators.removeCreator(this._item, creator).pipe(
                finalize(() => {
                    this.ctrl.enable();
                    this.actionInProgress = false;
                    creator.isLoading = false;
                }),
                first(),
                takeUntil(this._destroyed$)
            ).subscribe(
                () => {
                    this.allPotentialLinkedItems.push(creator);

                    const creators = this._item.creators;
                    _.remove(creators, function (o: Page) { return o.id === creator.id; });

                    this._item.creators = creators;
                    if (this._item.witness) {
                        this._item.witness.creators = creators;
                    }
                    if (this.serviceSecurity.isCurrentUser(creator)) {
                        this._item.isUserCreator = false;
                        if (this._item.witness) {
                            this._item.witness.isUserCreator = false;
                        }
                    }
                    this.serviceItems.item = this._item;

                    const params = { creator: _.get(creator, 'title'), title: _.get(this._item, 'title') };
                    this.serviceToaster.success('ITEM.CREATORS.REMOVE', params);
                },
                (error: HttpErrorResponse) => {
                    let creators = this._item.creators;
                    creators.push(creator);
                    creators = this.serviceMembers.sortArray(creators, 'asc');

                    this._item.creators = creators;
                    if (this._item.witness) {
                        this._item.witness.creators = creators;
                    }
                    if (this.serviceSecurity.isCurrentUser(creator)) {
                        this._item.isUserCreator = true;
                        if (this._item.witness) {
                            this._item.witness.isUserCreator = true;
                        }
                    }
                    this.serviceItems.item = this._item;

                    this.serviceErrors.handleError('ITEM.CREATORS.REMOVE', error);
                }
            );
        } else {
            this.allPotentialLinkedItems.push(creator);
            _.remove(this._item.creators, function (o: Member) { return o.id === creator.id; });
            this.ctrl.enable();
            this.actionInProgress = false;
            creator.isLoading = false;
        }
    }
}
