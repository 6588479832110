import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Item } from '@models';
import { CommonService } from '@services/common.service';
import { ServiceDatastore } from './datastore.service';
import { ServiceSecurity } from './security.service';

@Injectable({ providedIn: 'root' })
export class ServicePictures extends CommonService {

    constructor(
        protected injector: Injector,
        private serviceSecurity: ServiceSecurity,
        private serviceDatastore: ServiceDatastore
    ) {
        super(injector);
    }

    userCanModifyPictureItem(item: Item): boolean {
        const isUserAdmin = this.serviceSecurity.isAdmin();
        const isCurrentUser = this.serviceSecurity.isCurrentUser(item);

        return isUserAdmin || isCurrentUser;
    }

    addPictureItem(item: Item, file: any): Observable<any> {
        if (!this.userCanModifyPictureItem(item)) {
            return this.serviceErrors.throwError('ITEM.PICTURE.ADD', { code: 403 });
        }

        const isMember = item.group === 'Member';
        const formData: FormData = new FormData();
        formData.append('picture', file, file.name);

        return (isMember) ? this._addAvatar(item, formData) : this._addCover(item, formData);
    }

    _addAvatar(member: Item, formData: FormData): Observable<any> {
        const memberUid = member.uid;
        const req = new HttpRequest('POST', this.urlApi + 'files/avatar/' + memberUid, formData);
        return this.http.request(req).pipe(
            map(
                (event: any) => {
                    if (event instanceof HttpResponse) {
                        this.serviceDatastore.updateAvatar(member.id, event.body.mid);
                    }
                    return event;
                }
            )
        );
    }

    _addCover(item: Item, formData: FormData): Observable<any> {
        const itemUid = item.uid;
        const req = new HttpRequest('POST', this.urlApi + 'files/cover/' + itemUid, formData);
        return this.http.request(req).pipe(
            map(
                (event: any) => {
                    if (event instanceof HttpResponse) {
                        this.serviceDatastore.updateCover(item.id, event.body.cover);
                    }
                    return event;
                }
            )
        );
    }

    removePictureItem(item: Item): Observable<any> {
        const isMember = item.group === 'Member';

        if (!this.userCanModifyPictureItem(item)) {
            return this.serviceErrors.throwError('ITEM.PICTURE.REMOVE', { code: 403 });
        }
        if (isMember) {
            return this.http.delete(this.urlApi + 'files/avatar/' + item.uid, {}).pipe(
                map(
                    (data: any) => {
                        this.serviceDatastore.updateAvatar(item.id, null);
                        return data;
                    }
                )
            );
        } else {
            return this.http.delete(this.urlApi + 'files/cover/' + item.uid, {}).pipe(
                map(
                    (data: any) => {
                        this.serviceDatastore.updateCover(item.id, null);
                        return data;
                    }
                )
            );

        }
    }
}
