import * as _ from 'lodash';
import { Component, Input, Output } from '@angular/core';
import { finalize, first, takeUntil } from 'rxjs/operators';

import { ServiceItems, ServiceMembers, ServiceWorklabs, ServiceTextAnalyser } from '@services';
import { Member, Worklab } from '@models';
import { LinkedItemsComponent } from '../linked-items.component';
import { Subject } from 'rxjs';

@Component({
    selector: 'followed-worklabs',
    templateUrl: './followed-worklabs.component.html',
    styleUrls: ['./followed-worklabs.component.scss']
})
export class FollowedWorklabsComponent extends LinkedItemsComponent {

    private _member: Member;
    @Input()
    set member(value: Member) {
        this._member = value;
        this.handleEditionMode(this._editionMode);
    }
    get member(): Member {
        return this._member;
    }

    constructor(
        private serviceItems: ServiceItems,
        private serviceMembers: ServiceMembers,
        private worklabsService: ServiceWorklabs,
        protected serviceTextAnalyser: ServiceTextAnalyser
    ) {
        super(worklabsService, serviceTextAnalyser);
        this.field = 'followedWorklabs';
    }

    protected handleOrder(orderDirection: 'asc' | 'desc') {
        if (this._member instanceof Member) {
            this._member.followedWorklabs = this.worklabsService.sortArray(this._member.followedWorklabs, orderDirection);
        }
    }

    protected handleEditionMode(value: boolean) {
        if (this._member instanceof Member) {
            this.isCreation = _.isUndefined(this._member.id);
            this.canEdit = this.serviceMembers.userCanModifyFollowedWorklabs(this._member);
            if (value === true && this.canEdit === true) {
                this.loadAllPotentialLinkedItems<Member>(this._member.followedWorklabs, this._member);
            }
        }
    }

    addLinkedItem(worklab: Worklab) {
        worklab.isLoading = true;
        this.ctrl.reset();
        this.ctrl.disable();
        this.actionInProgress = true;

        let followedWorklabs = this._member.followedWorklabs;
        followedWorklabs.push(worklab);
        followedWorklabs = this.worklabsService.sortArray(followedWorklabs, 'asc');
        this._member.followedWorklabs = followedWorklabs;

        if (!this.isCreation) {
            this.worklabsService.startParticipating(worklab, this._member).pipe(
                finalize(() => {
                    this.ctrl.enable();
                    this.actionInProgress = false;
                    this.newLinkInput.nativeElement.focus();
                }),
                first(),
                takeUntil(this._destroyed$)
            ).subscribe(
                () => {
                    worklab.isLoading = false;
                    _.remove(this.allPotentialLinkedItems, function(o: Worklab) { return o.id === worklab.id; });

                    this._member.followedWorklabs = followedWorklabs;
                    if (this._member.witness) {
                        this._member.witness.followedWorklabs = followedWorklabs;
                    }
                    this.serviceItems.item = this._member;
                },
                () => {
                    _.remove(this._member.followedWorklabs, function(o: Worklab) { return o.id === worklab.id; });
                });
        } else {
            worklab.isLoading = false;
            _.remove(this.allPotentialLinkedItems, function(o: Worklab) { return o.id === worklab.id; });
            this.ctrl.enable();
            this.actionInProgress = false;
            this.newLinkInput.nativeElement.focus();
        }
    }

    removeLinkedItem(worklab: Worklab) {
        worklab.isLoading = true;
        this.ctrl.disable();
        this.actionInProgress = true;

        if (!this.isCreation) {
            this.worklabsService.stopParticipating(worklab, this._member).pipe(
                finalize(() => {
                    this.ctrl.enable();
                    this.actionInProgress = false;
                    worklab.isLoading = false;
                }),
                first(),
                takeUntil(this._destroyed$)
            ).subscribe(
                () => {
                    this.allPotentialLinkedItems.push(worklab);

                    const followedWorklabs = this._member.followedWorklabs;
                    _.remove(this._member.followedWorklabs, function(o: Worklab) { return o.id === worklab.id; });

                    this._member.followedWorklabs = followedWorklabs;
                    if (this._member.witness) {
                        this._member.witness.followedWorklabs = followedWorklabs;
                    }
                    this.serviceItems.item = this._member;
                },
                () => {
                    let followedWorklabs = this._member.followedWorklabs;
                    followedWorklabs.push(worklab);
                    followedWorklabs = this.worklabsService.sortArray(followedWorklabs, 'asc');

                    this._member.followedWorklabs = followedWorklabs;
                    if (this._member.witness) {
                        this._member.witness.followedWorklabs = followedWorklabs;
                    }
                    this.serviceItems.item = this._member;
                }
            );
        } else {
            this.allPotentialLinkedItems.push(worklab);
            _.remove(this._member.followedWorklabs, function(o: Worklab) { return o.id === worklab.id; });
            this.ctrl.enable();
            this.actionInProgress = false;
            worklab.isLoading = false;
        }
    }
}
