import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import * as moment from 'moment';

import { ServiceItems, ServiceSecurity, ServiceErrors, ServiceKb, ServiceUrls } from '@services';
import { Item, ExportItemsRequest } from '@src/app/models';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'dialog-export-items',
    styleUrls: ['./dialog-export-items.scss'],
    templateUrl: './dialog-export-items.html',
})
export class DialogExportItemsComponent implements OnInit, OnDestroy {
    public onLoading = new EventEmitter();

    public loading = false;
    public exportedData: ExportItemsRequest;
    public text: string;
    public format: 'csv' | 'xls' | 'xlsx' = 'xls';
    private _destroyed$ = new Subject();

    constructor(
        private serviceItems: ServiceItems,
        private serviceSecurity: ServiceSecurity,
        @Inject(MAT_DIALOG_DATA) public data: Array<Item>,
        public dialogRef: MatDialogRef<DialogExportItemsComponent>,
        private serviceTranslate: TranslateService,
        private serviceErrors: ServiceErrors,
        private serviceUrls: ServiceUrls
    ) { }

    ngOnInit() {
        this.exportedData = _.reduce(_.get(this, 'data', []), function (result, value) {
            let group = _.get(value, 'group');
            if (!group && _.has(value, 'email')) {
                group = 'Member';
            }
            const id = _.get(value, 'oid') || _.get(value, 'id');
            if (id && group === 'Page' || group === 'Member' || group === 'Worklab') {
                if (!result[group]) {
                    result[group] = {};
                    result[group]['ids'] = [];
                }
                result[group]['ids'].push(id);
            }
            return result;
        }, new ExportItemsRequest());

        const nbPages = this.exportedData.getNumberOfPages();
        const nbMembers = this.exportedData.getNumberOfMember();
        const nbWorklabs = this.exportedData.getNumberOfWorklabs();

        if (nbPages + nbMembers + nbWorklabs === 0) {
            this.text = this.serviceTranslate.instant('EXPORT_ITEMS.MODAL.NO_ITEM');
        } else {
            let text = this.serviceTranslate.instant('EXPORT_ITEMS.MODAL.ITEMS');

            let textPages = '';
            if (nbPages === 1) {
                textPages = this.serviceTranslate.instant('EXPORT_ITEMS.MODAL.PAGE_1');
            } else if (nbPages > 1) {
                textPages = nbPages + ' ' + this.serviceTranslate.instant('EXPORT_ITEMS.MODAL.PAGE_N');
            }

            let textMembers = '';
            if (nbMembers === 1) {
                textMembers = this.serviceTranslate.instant('EXPORT_ITEMS.MODAL.MEMBER_1');
            } else if (nbMembers > 1) {
                textMembers = nbMembers + ' ' + this.serviceTranslate.instant('EXPORT_ITEMS.MODAL.MEMBER_N');
            }

            let textWorklabs = '';
            if (nbWorklabs === 1) {
                textWorklabs = this.serviceTranslate.instant('EXPORT_ITEMS.MODAL.WORKLAB_1');
            } else if (nbWorklabs > 1) {
                textWorklabs = nbWorklabs + ' ' + this.serviceTranslate.instant('EXPORT_ITEMS.MODAL.WORKLAB_N');
            }

            if (nbPages > 0 && nbMembers > 0 && nbWorklabs > 0) {
                text += textPages + ', ' + textMembers + ' ' + this.serviceTranslate.instant('EXPORT_ITEMS.MODAL.AND') + ' ' + textWorklabs;
            } else if (nbPages > 0 && nbMembers > 0) {
                text += textPages + ' ' + this.serviceTranslate.instant('EXPORT_ITEMS.MODAL.AND') + ' ' + textMembers;
            } else if (nbPages > 0 && nbWorklabs > 0) {
                text += textPages + ' ' + this.serviceTranslate.instant('EXPORT_ITEMS.MODAL.AND') + ' ' + textWorklabs;
            } else if (nbMembers > 0 && nbWorklabs > 0) {
                text += textMembers + ' ' + this.serviceTranslate.instant('EXPORT_ITEMS.MODAL.AND') + ' ' + textWorklabs;
            } else if (nbPages > 0) {
                text += textPages;
            } else if (nbMembers > 0) {
                text += textMembers;
            } else if (nbWorklabs > 0) {
                text += textWorklabs;
            }
            this.text = text;
        }
    }

    download() {
        this.loading = true;
        this.onLoading.emit(this.loading);

        const lang = this.serviceSecurity.getCurrentLanguage();
        const societySlug = this.serviceUrls.societySlug;
        const kbSlug = this.serviceUrls.kbSlug;
        const url = this.serviceUrls.api.replace('/' + kbSlug + '/', '/');

        this.serviceItems.downloadItems(this.format, lang, this.exportedData)
            .pipe(
                takeUntil(this._destroyed$),
                finalize(() => {
                    this.onLoading.emit(false);
                })
            )
            .subscribe(
                (response) => {
                    if (this.format === 'csv') {
                        const filename = 'i2kn_'
                            + societySlug + '_' + kbSlug + '_'
                            + moment().format('YYYY-MM-DD-HHmmss')
                            + '.' + this.format;
                        const fileData = new Blob([response], {
                            type: 'text/csv; charset=utf-8'
                        });
                        saveAs(fileData, filename);
                    } else {
                        const link = document.createElement('a');
                        link.href = url + response;
                        const clickEvent = new MouseEvent('click');
                        link.dispatchEvent(clickEvent);
                    }
                },
                (error: HttpErrorResponse) => {
                    this.serviceErrors.handleError('EXPORT_ITEMS', error);
                }
            );
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }
}
