import * as _ from 'lodash';
import { Component, Input, Output } from '@angular/core';
import { finalize, first, takeUntil } from 'rxjs/operators';

import { ServiceItems, ServiceMembers, ServiceFollowers, ServiceSecurity, ServiceToaster, ServiceErrors, ServiceTextAnalyser } from '@services';
import { Page, Member } from '@models';
import { LinkedItemsComponent } from '../linked-items.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'followers',
    templateUrl: './followers.component.html',
    styleUrls: ['./followers.component.scss']
})
export class FollowersComponent extends LinkedItemsComponent {

    private _item: Page | Member;
    @Input()
    set item(value: Page | Member) {
        this._item = value;
        this.handleEditionMode(this._editionMode);
    }
    get item(): Page | Member {
        return this._item;
    }

    constructor(
        private serviceItems: ServiceItems,
        private serviceErrors: ServiceErrors,
        private serviceMembers: ServiceMembers,
        private serviceToaster: ServiceToaster,
        private serviceFollowers: ServiceFollowers,
        private serviceSecurity: ServiceSecurity,
        protected serviceTextAnalyser: ServiceTextAnalyser
    ) {
        super(serviceMembers, serviceTextAnalyser);
        this.field = 'followers';
    }

    protected handleOrder(orderDirection: 'asc' | 'desc') {
        if (this._item instanceof Page || this._item instanceof Member) {
            this._item.followers = this.serviceFollowers.sortArray(this._item.followers, orderDirection);
            (<Page | Member>this._item).followers = _.orderBy(_.get(this, 'item.followers'), function (i) {
                const v = _.toLower(_.get(i, 'lastname', '') + _.get(i, 'firstname', ''));
                return (v === '') ? _.toLower(_.get(i, 'title', '')) : v;
            }, [orderDirection]);
        }
    }

    protected handleEditionMode(value: boolean) {
        if (this._item instanceof Page || this._item instanceof Member) {
            this.isCreation = _.isUndefined(this._item.id);
            this.canEdit = this.serviceFollowers.userCanModifyFollowers(this._item);
            if (value === true && this.canEdit === true) {
                this.loadAllPotentialLinkedItems<Page | Member>(this._item.followers, this._item);
            }
        }
    }

    addFollower(follower: Member) {
        follower.isLoading = true;
        this.ctrl.reset();
        this.ctrl.disable();
        this.actionInProgress = true;

        let followers = (<Member | Page>this._item).followers;
        followers.push(follower);
        followers = this.serviceFollowers.sortArray(followers, 'asc');
        (<Member | Page>this._item).followers = followers;

        const translationKey = (_.get(this, '_item.group') === 'Page') ? 'ITEM.FOLLOWERS.PAGE' : 'ITEM.FOLLOWERS.MEMBER';

        if (!this.isCreation) {
            this.serviceFollowers.startFollowing(this._item, follower).pipe(
                finalize(() => {
                    this.ctrl.enable();
                    this.actionInProgress = false;
                    this.newLinkInput.nativeElement.focus();
                }),
                first(),
                takeUntil(this._destroyed$)
            ).subscribe(
                (result: any) => {
                    follower.isLoading = false;
                    _.remove(this.allPotentialLinkedItems, function (o: Member) { return o.id === follower.id; });

                    this._item.followers = followers;
                    if (this._item.witness) {
                        this._item.witness.followers = followers;
                    }
                    if (this.serviceSecurity.isCurrentUser(follower)) {
                        this._item.isUserFollowing = true;
                        if (this._item.witness) {
                            this._item.witness.isUserFollowing = true;
                        }
                    }
                    this.serviceItems.item = this._item;

                    const params = {
                        member: _.get(result, 'itemFrom.title'),
                        title: _.get(result, 'itemTo.title')
                    };
                    this.serviceToaster.success(translationKey + '.ADD', params);
                },
                (error: HttpErrorResponse) => {
                    _.remove((<Member | Page>this._item).followers, function (o: Member) { return o.id === follower.id; });

                    this.serviceErrors.handleError(translationKey + '.ADD', error);
                });
        } else {
            follower.isLoading = false;
            _.remove(this.allPotentialLinkedItems, function (o: Member) { return o.id === follower.id; });
            this.ctrl.enable();
            this.actionInProgress = false;
            this.newLinkInput.nativeElement.focus();
        }
    }

    removeFollower(follower: Member) {
        follower.isLoading = true;
        this.ctrl.disable();
        this.actionInProgress = true;

        const translationKey = (_.get(this, '_item.group') === 'Page') ? 'ITEM.FOLLOWERS.PAGE' : 'ITEM.FOLLOWERS.MEMBER';
        if (!this.isCreation) {
            this.serviceFollowers.stopFollowing(this._item, follower).pipe(
                finalize(() => {
                    this.ctrl.enable();
                    this.actionInProgress = false;
                    follower.isLoading = false;
                }),
                first(),
                takeUntil(this._destroyed$)
            ).subscribe(
                (result: any) => {
                    this.allPotentialLinkedItems.push(follower);

                    const followers = this._item.followers;
                    _.remove(this._item.followers, function (o: Member) { return o.id === follower.id; });

                    this._item.followers = followers;
                    if (this._item.witness) {
                        this._item.witness.followers = followers;
                    }
                    if (this.serviceSecurity.isCurrentUser(follower)) {
                        this._item.isUserFollowing = true;
                        if (this._item.witness) {
                            this._item.witness.isUserFollowing = true;
                        }
                    }
                    this.serviceItems.item = this._item;

                    const params = {
                        member: _.get(result, 'itemFrom.title'),
                        title: _.get(result, 'itemTo.title')
                    };
                    this.serviceToaster.success(translationKey + '.REMOVE', params);
                },
                (error: HttpErrorResponse) => {
                    let followers = this._item.followers;
                    followers.push(follower);
                    followers = this.serviceFollowers.sortArray(followers, 'asc');

                    this._item.followers = followers;
                    if (this._item.witness) {
                        this._item.witness.followers = followers;
                    }
                    if (this.serviceSecurity.isCurrentUser(follower)) {
                        this._item.isUserFollowing = false;
                        if (this._item.witness) {
                            this._item.witness.isUserFollowing = false;
                        }
                    }
                    this.serviceItems.item = this._item;

                    this.serviceErrors.handleError(translationKey + '.REMOVE', error);
                }
            );
        } else {
            this.allPotentialLinkedItems.push(follower);
            _.remove((<Member | Page>this._item).followers, function (o: Member) { return o.id === follower.id; });
            this.ctrl.enable();
            this.actionInProgress = false;
            follower.isLoading = false;
        }
    }
}
