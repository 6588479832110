import * as _ from 'lodash';
import { Component, Input, OnDestroy } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { finalize, first, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

import { Page, Member } from '@models';
import { ServiceItems, ServiceExpertises, ServiceSecurity, ServiceMembers, ServiceErrors, ServiceToaster } from '@services';

@Component({
    selector: 'is-expert',
    templateUrl: './is-expert.component.html',
    styleUrls: ['./is-expert.component.scss']
})
export class IsExpertComponent implements OnDestroy {

    private _page: Page;
    @Input()
    set page(value: Page) {
        this._page = value;
        this._setAttributes();
    }
    get page(): Page {
        return this._page;
    }

    public tooltip: string;
    public loading = false;
    public isGranted = false;
    public isDisabled = false;
    public isUserExpert = false;

    private _isAdmin = false;
    private _destroyed$ = new Subject();

    constructor(
        private serviceItems: ServiceItems,
        private serviceErrors: ServiceErrors,
        private serviceMembers: ServiceMembers,
        private serviceToaster: ServiceToaster,
        private serviceExpertises: ServiceExpertises,
        private serviceSecurity: ServiceSecurity
    ) {
        this._isAdmin = this.serviceSecurity.isAdmin();
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }

    toogleIsExpert() {
        if (this.isGranted === true && (!this._page.islocked || this._isAdmin)) {
            this.loading = true;

            let request: Observable<any>;
            const expert = this.serviceSecurity.user;
            const wasExpert = this._page.isUserExpert;
            if (wasExpert === true) {
                request = this.serviceExpertises.removeExpert(this._page, expert);
                this._page.isUserExpert = false;
            } else {
                request = this.serviceExpertises.addExpert(this._page, expert);
                this._page.isUserExpert = true;
            }

            request.pipe(
                finalize(() => {
                    this.loading = false;
                    this._setAttributes();
                }),
                first(),
                takeUntil(this._destroyed$)
            ).subscribe(
                () => {
                    const params = { expert: expert.title, page: this._page.title };

                    let experts = this._page.experts;
                    if (this._page.isUserExpert === false) {
                        _.remove(experts, function (o: Member) { return o.id === expert.id; });
                    } else {
                        experts.push(expert);
                    }
                    experts = this.serviceMembers.sortArray(experts, 'asc');

                    this._page.isUserExpert = this._page.isUserExpert;
                    this._page.experts = experts;
                    if (this._page.witness) {
                        this._page.witness.experts = experts;
                        this._page.witness.isUserExpert = this._page.isUserExpert;
                    }
                    this.serviceItems.item = this._page;

                    if (wasExpert === true) {
                        this.serviceToaster.success('ITEM.EXPERTS.REMOVE', params);
                    } else {
                        this.serviceToaster.success('ITEM.EXPERTS.ADD', params);
                    }
                },
                (error: HttpErrorResponse) => {
                    let experts = this._page.experts;
                    if (this._page.isUserExpert === false) {
                        experts.push(expert);
                    } else {
                        _.remove(experts, function (o: Member) { return o.id === expert.id; });
                    }
                    experts = this.serviceMembers.sortArray(experts, 'asc');

                    this._page.isUserExpert = this._page.isUserExpert;
                    this._page.experts = experts;
                    if (this._page.witness) {
                        this._page.witness.experts = experts;
                        this._page.witness.isUserExpert = this._page.isUserExpert;
                    }
                    this.serviceItems.item = this._page;

                    if (wasExpert === true) {
                        this.serviceErrors.handleError('ITEM.EXPERTS.REMOVE', error);
                    } else {
                        this.serviceErrors.handleError('ITEM.EXPERTS.ADD', error);
                    }
                }
            );
        }
    }

    private _setAttributes() {
        const isLocked = this._page.islocked;
        this.isUserExpert = this._page.isUserExpert;
        this.isGranted = this.serviceExpertises.userCanModifyExperts(this._page);

        if (this.isGranted === true) {
            if (isLocked === true && !this._isAdmin) {
                this.isDisabled = true;
                this.tooltip = 'ITEM.EXPERTS.TOOLTIP.DISABLED';
            } else if (this.isUserExpert) {
                this.isDisabled = false;
                this.tooltip = 'ITEM.EXPERTS.TOOLTIP.REMOVE';
            } else if (!this.isUserExpert) {
                this.tooltip = 'ITEM.EXPERTS.TOOLTIP.ADD';
            }
        }
    }
}
