export class PreferencesListingOrder {
    constructor() {
        try {
            return DEFAULT_LISTING_ORDERS;
        } catch (error) {
            return {
                any: {
                    field: 'lastModificationDate',
                    direction: 'desc'
                },
                detail: {
                    field: 'lastModificationDate',
                    direction: 'desc'
                },
                all: {
                    field: 'lastModificationDate',
                    direction: 'desc'
                },
                page: {
                    field: 'lastModificationDate',
                    direction: 'desc'
                },
                member: {
                    field: 'title',
                    direction: 'asc'
                },
                worklab: {
                    field: 'lastModificationDate',
                    direction: 'desc'
                },
                expertises: {
                    field: 'lastModificationDate',
                    direction: 'desc'
                },
                favorites: {
                    field: 'lastModificationDate',
                    direction: 'desc'
                },
                recommendations: {
                    field: 'score',
                    direction: 'desc'
                },
                search: {
                    field: 'score',
                    direction: 'desc'
                }
            };
        }
    }
}