import * as _ from 'lodash';
import { Deserializable } from "./deserializable.model";
import { SafeHtml } from "@angular/platform-browser";

export class Topic implements Deserializable {
    id: string;
    uid: string;
    date: string;
    content: string;
    displayedContent: SafeHtml;
    creator?: TopicCreator;
    creators?: Array<TopicCreators> ;
    subtopics?: Array<Topic>;
    isUserAuthor: boolean;
    inProgress: boolean;
    newSubTopicText: string;
    isOpened: boolean;


    deserialize(input: any, currentUser: any) {
        input.uid = (input.id) ? input.id.replace('#', '').replace(':', '-') : '';

        if (input.creator) {
            input.creator = new TopicCreator().deserialize(input.creator);
        }
        
        if (input.creator) {
            input.creators = _.map(input.creators || [], function(o: any) {
                return new TopicCreators().deserialize(o);
            });
        }
        
        if (input.subtopics) {
            input.subtopics = _.map(input.subtopics || [], function(o: any) {
                return new Topic().deserialize(o, currentUser);
            });
        }

        input.isUserAuthor = _.get(input, 'creator.id') === _.get(currentUser, 'id');

        input.displayedContent = _.clone(input.content);

        Object.assign(this, input);
        return this;
    }
}

export class TopicCreator implements Deserializable {
    title: string;
    id: string;
    group: string;
    mid: string;
    url: string;

    deserialize(input: any) {
        input.group = 'Member';
        input.uid = input.id.replace('#', '').replace(':', '-')
        input.url = '/items/member/' + input.uid;

        Object.assign(this, input);
        return this;
    }
}

export class TopicCreators implements Deserializable {
    nb: number;
    creator: TopicCreator;

    deserialize(input: any) {
        input.creator = new TopicCreator().deserialize(input.creator);

        Object.assign(this, input);
        return this;
    }
}

export class TopicCreationResponse {
    topics: Topic[];
    newSubscription: boolean;
}
