export enum Role {
    DEFAUL_CREATION_ROLE = 'ROLE_READER',

    ROLE_NONE = 'ROLE_NONE',
    ROLE_PUBLIC = 'ROLE_PUBLIC',
    ROLE_READER = 'ROLE_READER',
    ROLE_CONTRIBUTOR = 'ROLE_CONTRIBUTOR',
    ROLE_KB_ADMIN = 'ROLE_KB_ADMIN',
    ROLE_SOCIETY_ADMIN = 'ROLE_SOCIETY_ADMIN'
}
