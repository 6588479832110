import * as _ from 'lodash';
import { Component, Input, OnDestroy, Attribute } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { finalize, first, takeUntil } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';

import { Item } from '@models/item.model';
import { AppSettings } from '@app/app.settings';
import { ServiceItems, ServiceErrors, ServiceToaster } from '@services';
import { DialogRemoveItemConfirmationComponent } from './dialog-remove_item-confirmation/dialog-remove_item-confirmation.component';

@Component({
    selector: 'button-remove-item',
    templateUrl: './button-remove-item.html',
    styleUrls: ['./button-remove-item.scss']
})
export class ButtonRemoveItemComponent implements OnDestroy{
    @Input() disabled = false;

    private _item: Item;
    @Input()
    set item(value: Item) {
        this._item = value;
    }
    get item(): Item {
        return this._item;
    }

    public get propagateClick(): 'true' | 'false' {
        return this._propagateClick;
    }
    public set propagateClick(value: 'true' | 'false') {
        this._propagateClick = value;
    }

    private _destroyed$ = new Subject();

    constructor(
        private router: Router,
        private _dialog: MatDialog,
        private appSettings: AppSettings,
        private serviceItems: ServiceItems,
        private serviceErrors: ServiceErrors,
        private serviceToaster: ServiceToaster,
        @Attribute('propagateClick') private _propagateClick: 'true' | 'false' = 'true'
    ) {
    }

    ngOnDestroy() {
        this._dialog.closeAll();
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }

    openDialog() {
        const item = this.item;
        const title = item.title;
        const group = item.group;

        const dialogConfig = new MatDialogConfig();
        dialogConfig.minWidth = '20%';
        const dialogRef = this._dialog.open(DialogRemoveItemConfirmationComponent, dialogConfig);
        dialogRef.afterClosed().pipe(
            first(),
            takeUntil(this._destroyed$)
        ).subscribe(
            (result: boolean) => {
                if (result === true) {
                    this.appSettings.settings.savingInsideSpinner = true;
                    this.serviceItems.removeItem(item).pipe(
                        finalize(() => {
                            this.appSettings.settings.savingInsideSpinner = false;
                        }),
                        first(),
                        takeUntil(this._destroyed$)
                    ).subscribe(
                        () => {
                            this.serviceToaster.success('REMOVE_ITEM.' + _.toUpper(group), { title: title });
                            this.router.navigate(['/']);
                        },
                        (error: HttpErrorResponse) => {
                            this.serviceErrors.handleError('REMOVE_ITEM', error);
                        }
                    );
                }
            });
    }
}
