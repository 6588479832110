import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { takeUntil, map } from 'rxjs/operators';
import * as moment from 'moment';
import { AppSettings } from '@app/app.settings';

import { VisitedItem, CookieNavigationHistory, NavigationTime } from '@models/navigation-history.model';
import { ServiceKb, ServiceNavigationHistory, ServiceUrls } from '@services';
import { combineLatest } from 'rxjs';

@Component({
    selector: 'app-history',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit, OnDestroy {
    @Output() linkClicked = new EventEmitter();

    public navigationTimes: NavigationTime[] = [];

    private _destroyed$ = new Subject();

    constructor(
        private router: Router,
        private appSettings: AppSettings,
        private serviceKb: ServiceKb,
        private serviceUrls: ServiceUrls,
        private serviceNavigationHistory: ServiceNavigationHistory
    ) {
    }

    ngOnInit() {
        const language = this.appSettings.settings.language;
        const sld = this.serviceUrls.sld;

        const kbObservable = this.serviceKb.kbObservable;
        const historyObservable = this.serviceNavigationHistory.historyObservable;

        combineLatest(kbObservable, historyObservable).pipe(
            map(
                (resultData) => {
                    const currentKb = resultData[0];
                    const navigations = resultData[1];
                    return _.reduce(navigations, function(result: NavigationTime[], navigation: CookieNavigationHistory) {
                        const dateAgo = _.upperFirst(moment(navigation.d).locale(language).fromNow());
                        const visitedItem = new VisitedItem().deserialize(navigation, {currentKb, sld});
                        let navigationTime: NavigationTime = _.find(result, { dateAgo: dateAgo });
                        if (!navigationTime) {
                            navigationTime = new NavigationTime().deserialize(
                                {
                                    dateAgo: dateAgo,
                                    visitedItems: [visitedItem]
                                }
                            );
                            result.push(navigationTime);
                        } else {
                            navigationTime.visitedItems.push(visitedItem);
                        }
                        return result;
                    }, new Array());
                }
            ),
            takeUntil(this._destroyed$)
        ).subscribe((navigationTimes: NavigationTime[]) => {
            this.navigationTimes = navigationTimes;
        });
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }

    trackDateAgo(index: number, o: NavigationTime) {
        return o ? o.dateAgo : index;
    }

    trackVisitedItem(index: number, o: VisitedItem) {
        return o ? o.date + o.kb.slug + o.kb.society.slug + o.item.id : index;
    }

    clickOnLink() {
        this.linkClicked.emit();
    }

    goToHistory() {
        this.router.navigate(['/items/history']);
        this.linkClicked.emit();
    }
}
