import * as _ from 'lodash';
import { Component, AfterViewInit, OnDestroy, ElementRef, ViewChild, HostListener, NgZone } from '@angular/core';
import { takeUntil, finalize, first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { ItemsSettings } from '@items/items.settings';
import { Item, MapNode, Member, Page, DynamicLinksOut, DynamicLinkIn } from '@models';
import { ServiceMap, ServiceListings, ServiceItems, ServiceDatastore, ServiceDynamicLinks, ServiceUrls } from '@services';

import { Network, DataSet } from 'vis';
import { Subject } from 'rxjs';

/**
 * Map for item Detail.
 * Display nodes in custom hierarchical mode.
 * vis.js doesnt handle hierarchical layout properly, so we need to manually calculate inserted nodes position
 */
@Component({
    selector: 'item-as-map',
    templateUrl: './item-as-map.component.html',
    styleUrls: ['./item-as-map.component.scss']
})
export class ItemAsMapComponent implements AfterViewInit, OnDestroy {

    @ViewChild('visjsMap', {static: false}) mapElementRef: ElementRef;

    public nodeCount = 0;

    private rootItem: Item;
    private nodesDataset: DataSet<MapNode>;
    private edgesDataset: DataSet<any>;
    private network: Network;

    private currentSocietySlug: string;
    private currentKbSlug: string;
    private showDynamicsLinks = true;

    private nodeSpacing = 200;
    private stopSimpleClick = false;

    private isInitialized = false;

    private _destroyed$ = new Subject();

    constructor(
        private ngZone: NgZone,
        private serviceListings: ServiceListings,
        private serviceItems: ServiceItems,
        private serviceUrls: ServiceUrls,
        private serviceDatastore: ServiceDatastore,
        private mapService: ServiceMap,
        private serviceTranslate: TranslateService,
        private serviceDynamicLinks: ServiceDynamicLinks,
        private router: Router
    ) {
        this.currentSocietySlug = this.serviceUrls.societySlug;
        this.currentKbSlug = this.serviceUrls.kbSlug;

        this.serviceListings.datasourceObservable.pipe(
            takeUntil(this._destroyed$)
        ).subscribe(
            (data) => {
                if (this.nodesDataset) {
                    this.network.storePositions();

                    const hiddenNodes: MapNode[] = [];
                    _.forEach(this.nodesDataset.get(), (node: MapNode) => {
                        // Get filters from serviceListing
                        let isVisible: boolean = data && _.findIndex(data.data, (o: Item) => {
                            return o.id === node.originalId;
                        }) !== -1;

                        // Concat with filters from map (dynamic link toggler)
                        isVisible = isVisible && this.isNodeVisible(node);

                        if (node.hidden !== !isVisible) {
                            this.updateNodeVisibility(node, isVisible);
                        }
                        if (!isVisible && node.id !== this.rootItem.id && node.kbSlug !== this.rootItem.kb && node.societySlug !== this.rootItem.society) {
                            // Do not hide the root node
                            hiddenNodes.push(node);
                        } else if (node.isCollapsed) {
                            hiddenNodes.push(node);
                        }
                    });

                    // Also hide all childrens of hidden nodes
                    this.hideChildrenNodes(hiddenNodes);
                }
            }
        );

        this.mapService.filterDynamicLinkChanged.pipe(
            takeUntil(this._destroyed$)
        ).subscribe(
            (showDynamicLinks) => {
                this.showDynamicsLinks = showDynamicLinks;
                if (this.nodesDataset) {
                    this.network.storePositions();
                    const nodesDynamic = this.nodesDataset.get({
                        filter: (n: MapNode) => {
                            return n.isDynamic;
                        }
                    });
                    const hiddenNodes: MapNode[] = [];
                    const visibleNodes: MapNode[] = [];

                    _.forEach(nodesDynamic, (node: MapNode) => {
                        const isVisible = this.isNodeVisible(node);
                        if (node.hidden !== !isVisible) {
                            this.updateNodeVisibility(node, isVisible);
                        }
                        if (!isVisible || node.isCollapsed) {
                            hiddenNodes.push(node);
                        } else {
                            visibleNodes.push(node);
                        }
                    });
                    // Also /showhide all childrens of visible/hidden nodes
                    this.showChildrenNodes(visibleNodes);
                    this.hideChildrenNodes(hiddenNodes);
                }
            }
        );

        this.mapService.filterClusterChanged.pipe(
            takeUntil(this._destroyed$)
        ).subscribe(
            (isClusterEnabled) => {
                if (this.nodesDataset) {
                    this.network.storePositions();
                    this.filterClustering(isClusterEnabled);
                }
            }
        );
    }

    @HostListener('window:resize')
    onWindowResize(): void {
        this.centerNetwork();
    }

    ngAfterViewInit() {
        this.initMap();
        this.rootItem = this.serviceItems.item;
        this.addItem(this.rootItem);
        this.isInitialized = true;
    }

    private initMap() {
        this.clearAll();
        this.nodesDataset = new DataSet([]);
        this.edgesDataset = new DataSet([]);
        this.network = this.ngZone.runOutsideAngular(() =>
            new Network(this.mapElementRef.nativeElement, {
                nodes: this.nodesDataset,
                edges: this.edgesDataset
            }, ItemsSettings.ITEM_MAP)
        );
        this.network.on('click', this.onNodeClick.bind(this));
        this.network.on('doubleClick', this.onNodeDoubleClick.bind(this));
    }

    private clearDataset() {
        if (this.nodesDataset) {
            this.nodesDataset.clear();
        }
        if (this.edgesDataset) {
            this.edgesDataset.clear();
        }
    }

    private clearAll() {
        this.clearDataset();
        if (this.network) {
            this.network.off('click', this.onNodeClick);
            this.network.off('doubleClick', this.onNodeDoubleClick);
            this.network.destroy();
            this.network = null;
        }
    }

    private getSameLevelNodeIds(node: MapNode) {
        // Get same level nodes
        const nodes = this.nodesDataset.get({
            filter: function (n) {
                return n.level === node.level && n.id !== node.id;
            }
        });
        return nodes.map(function (n) {
            return n.id;
        });
    }

    private getSameLevelNodesPartition(node: MapNode) {
        // Get other nodes at same level
        const nodeIds = this.getSameLevelNodeIds(node);

        // Get their position in the network
        const nodePositions = this.network.getPositions(nodeIds);

        // Get current node position
        const currentPos = this.network.getPositions(node.id)[node.id];

        // Create a partition ; seperate up nodes and down nodes from current
        const partition = {
            up: [],
            down: []
        };
        _.forEach(nodePositions, function (v, k) {
            if (v.y < currentPos.y) {
                partition.up.push(k);
            } else {
                partition.down.push(k);
            }
        });
        return partition;
    }

    private getLowestPositionFromNodeIds(nodeIds) {
        const nodePositions = this.network.getPositions(nodeIds);
        let best = { x: 0, y: 9999 };
        _.forEach(nodePositions, function (p) {
            if (p.y < best.y) {
                best = p;
            }
        });
        return best;
    }

    private getHighestPositionFromNodeIds(nodeIds) {
        const nodePositions = this.network.getPositions(nodeIds);
        let best = { x: 0, y: -9999 };
        _.forEach(nodePositions, function (p) {
            if (p.y > best.y) {
                best = p;
            }
        });
        return best;
    }

    private getDirectionFromNode(node: MapNode) {
        let direction;
        if (node.isLeft) {
            direction = 'from';
        } else if (node.isRight) {
            direction = 'to';
        }
        return direction;
    }

    private getChildNodesIds(node: MapNode, avoidCollapsed, childs?): string[] {
        childs = childs || [];
        if (avoidCollapsed !== true) { avoidCollapsed = false; }
        const connectedNodes = this.network.getConnectedNodes(node.id, this.getDirectionFromNode(node));
        _.forEach(connectedNodes, (nodeId: string) => {
            const n = this.nodesDataset.get(nodeId);
            childs.push(nodeId);
            if (!avoidCollapsed || (avoidCollapsed && !n.isCollapsed)) {
                childs = childs.concat(this.getChildNodesIds(n, avoidCollapsed, childs));
            }
        });
        return childs;
    }

    private getOptimalPosition(node: MapNode) {
        const partition = this.getSameLevelNodesPartition(node);

        const direction = this.getDirectionFromNode(node);

        // Search lowest position for all up nodes childs
        let bestUp = { x: 0, y: -9999 };
        let upFound = false;
        _.forEach(partition.up, (nodeId: string) => {
            const connectedNodes = this.network.getConnectedNodes(nodeId, direction);
            if (connectedNodes.length > 0) {
                const pos = this.getHighestPositionFromNodeIds(connectedNodes);
                if (pos.y > bestUp.y) {
                    bestUp = pos;
                    upFound = true;
                }
            }
        });

        // Search highest position for all down nodes childs
        let bestDown = { x: 0, y: 9999 };
        let downFound = false;
        _.forEach(partition.down, (nodeId: string) => {
            const connectedNodes = this.network.getConnectedNodes(nodeId, direction);
            if (connectedNodes.length > 0) {
                const pos = this.getLowestPositionFromNodeIds(connectedNodes);
                if (pos.y < bestDown.y) {
                    bestDown = pos;
                    downFound = true;
                }
            }
        });

        if (upFound && downFound) {
            return {
                x: bestUp.x,
                y: Math.floor((bestUp.y + bestDown.y) / 2)
            };
        } else if (upFound) {
            return { x: bestUp.x, y: bestUp.y + 100 };
        } else if (downFound) {
            return { x: bestDown.x, y: bestDown.y - 100 };
        }
    }

    private buildNodeIdFromEdge(n1: MapNode, n2: MapNode) {
        const kbSlug1 = n1.kbSlug || this.currentKbSlug;
        const kbSlug2 = n2.kbSlug || this.currentKbSlug;
        const societySlug1 = n1.societySlug || this.currentSocietySlug;
        const societySlug2 = n2.societySlug || this.currentSocietySlug;
        return 'From' + societySlug1 + kbSlug1 + n1.id + '-To' + societySlug2 + kbSlug2 + n2.id;
    }

    private buildNodeFromItem(item: Item) {
        const node = this.mapService.getNodeFromItem(item, this.currentSocietySlug, this.currentKbSlug);
        return node;
    }

    private setNodeLoading(node: MapNode, loading: boolean) {
        this.nodesDataset.update({
            id: node.id,
            image: loading ? '../assets/img/loading.png' : '../assets/img/' + node.group + '.png',
            isLoaded: !loading,
            isCollapsed: false
        });
    }

    private collapseNode(node: MapNode, collapse: boolean) {
        const ids = this.getChildNodesIds(node, true);
        _.forEach(ids, (i: string) => {
            const n = this.nodesDataset.get(i);
            let isVisible = !collapse && this.serviceListings.isKbVisible(n);
            this.updateNodeVisibility(this.nodesDataset.get(i), isVisible);
        });
        node.isCollapsed = collapse;
        this.nodesDataset.update(node);
    }

    private hideChildrenNodes(nodes: MapNode[]) {
        _.forEach(nodes, (node: MapNode) => {
            const children: string[] = this.getChildNodesIds(node, true);
            _.forEach(children, (childNodeId: string) => {
                this.updateNodeVisibility(this.nodesDataset.get(childNodeId), false);
            });
        });
    }

    private showChildrenNodes(nodes: MapNode[]) {
        _.forEach(nodes, (node: MapNode) => {
            const children: string[] = this.getChildNodesIds(node, true);
            _.forEach(children, (childNodeId: string) => {
                const n = this.nodesDataset.get(childNodeId);
                let shouldBeVisible = !n.isCollapsed && this.serviceListings.isKbVisible(n);
                if (n.hidden !== !shouldBeVisible) {
                    this.updateNodeVisibility(n, shouldBeVisible);
                }
            });
        });
    }

    private updateNodeVisibility(node: MapNode, isVisible: boolean, updateNetwork?: boolean) {
        if (node && node.id !== this.rootItem.id) {
            node.hidden = !isVisible;
            node.physics = !!isVisible;
            if (updateNetwork !== false) {
                this.nodesDataset.update(node);
            }
        }
    }

    private filterClustering(enableCluster) {
        if (enableCluster) {
            this.network.storePositions();
            const uniqueNodes: MapNode[] = _.cloneDeep(this.nodesDataset.get());
            const clusterNodeIds: string[] = [];

            _.uniqBy(uniqueNodes, 'originalId');
            _.forEach(uniqueNodes, (n: MapNode) => {
                this.ngZone.runOutsideAngular(() => {
                    this.network.cluster({
                        joinCondition: function (nodeOptions) {
                            return nodeOptions.societySlug + nodeOptions.kbSlug + nodeOptions.originalId === n.societySlug + n.kbSlug + n.originalId;
                        },
                        processProperties: function (clusterOptions, childNodes) {
                            clusterOptions.id = 'cluster:' + childNodes[0].societySlug + childNodes[0].kbSlug + childNodes[0].originalId;
                            clusterOptions.title = childNodes[0].title + ' (cluster)';
                            clusterOptions.label = childNodes[0].label;
                            clusterOptions.group = childNodes[0].group;
                            clusterOptions.shape = childNodes[0].shape;
                            clusterOptions.image = childNodes[0].image;
                            clusterOptions.size = 16;
                            clusterNodeIds.push(clusterOptions.id);
                            return clusterOptions;
                        }
                    });
                });
            });

            _.forEach(clusterNodeIds, (cnid) => {
                const clusterEdgeIds = this.network.getConnectedEdges(cnid);
                _.forEach(clusterEdgeIds, (ceid) => {
                    const clustering = _.get(this.network, 'clustering');
                    if (!clustering) {
                        return;
                    }
                    const nodesInThatCluster = clustering.getNodesInCluster(cnid);
                    const baseEdges = clustering.getBaseEdges(ceid);
                    let titles: string[] = [];
                    let arrowFrom = false;
                    let arrowTo = false;

                    _.forEach(baseEdges, (eid: string) => {
                        const edge = this.edgesDataset.get(eid);

                        if (!arrowFrom && _.indexOf(nodesInThatCluster, edge.from) >= 0) {
                            arrowFrom = true;
                        }
                        if (!arrowTo && _.indexOf(nodesInThatCluster, edge.to) >= 0) {
                            arrowTo = true;
                        }
                        titles.push(edge.title);
                    });

                    titles = _.uniq(titles);

                    const options = {
                        title: _.join(titles, '<hr>'),
                        arrows: {
                            from: { enabled: arrowFrom },
                            to: { enabled: arrowTo }
                        }
                    };
                    if (baseEdges.length > 1) {
                        options['color'] = { color: '#659df7' };
                    }
                    clustering.updateEdge(ceid, options);
                });
            });
        } else {
            _.forEach(this.nodesDataset.get(), (n: MapNode) => {
                const id = 'cluster:' + n.societySlug + n.kbSlug + n.originalId;
                if (this.network.isCluster(id)) {
                    this.ngZone.runOutsideAngular(() => {
                        this.network.openCluster(id);
                    });
                }
                this.ngZone.runOutsideAngular(() => {
                    this.network.moveNode(n.id, n.x, n.y);
                });
            });
        }
    }


    private addNode(node: MapNode) {
        // Add node to dataset
        const existingNode = this.nodesDataset.get(node.id);
        if (!existingNode) {

            // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            // TODO : property name normalization !!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            if (this.rootItem.kb !== node.kbSlug || this.rootItem.society !== node.societySlug) {
                // Node from another kb
                node.label += ' (' + node.societySlug + '-' + node.kbSlug + ')';
                node.borderWidth = 5;
            }

            this.nodesDataset.add(node);
            this.nodeCount++;
        }
    }

    private addEdge(edge) {
        const existingEdges = this.edgesDataset.get({
            filter: function (e) {
                return e.from === edge.from && e.to === edge.to;
            }
        });

        // Adjust edge roudness to avoid overlapping
        let smoothRoundness = 0.8;
        if (existingEdges && existingEdges.length > 0) {
            smoothRoundness = smoothRoundness * (0.5 * existingEdges.length);
            edge.smooth = { type: 'cubicBezier', roundness: smoothRoundness };
        }

        const existingEdge = _.find(existingEdges, {
            'title': _.get(edge, 'title'),
        });
        if (!existingEdge) {
            this.edgesDataset.add(edge);
        }
    }




    private addExpert(from, expert, position?) {
        expert.group = expert.group || 'Member';
        expert = this.buildNodeFromItem(expert);
        expert.id = this.buildNodeIdFromEdge(from, expert);
        expert.isRight = true;
        expert.level = from.level + 1;

        position = position || { x: expert.level * this.nodeSpacing, y: 0 };

        expert.x = position.x;
        expert.y = position.y;

        this.addNode(expert);
        this.addEdge({
            from: from.id,
            to: expert.id,
            title: this.serviceTranslate.instant('MAP.EXPERT'),
            color: {
                color: '#F90'
            }
        });
    }

    private addLinkIn(linkIn, to, position?) {
        if (_.indexOf(['Page', 'Member', 'Worklab'], linkIn.group) === -1) {
            // this node is a topic
            return;
        }
        linkIn = this.buildNodeFromItem(linkIn);
        linkIn.id = this.buildNodeIdFromEdge(linkIn, to);
        linkIn.isLeft = true;
        linkIn.level = to.level - 1;

        position = position || { x: linkIn.level * this.nodeSpacing, y: 0 };

        linkIn.x = position.x;
        linkIn.y = position.y;

        this.addNode(linkIn);

        let color = '#70DF8B';
        let title = this.serviceTranslate.instant('MAP.SUBSCRIBER');

        if (_.get(to, 'group') === 'Page' && _.get(linkIn, 'group') === 'Page') {
            title = this.serviceTranslate.instant('MAP.EXPERT');
            color = '#da4f70';
        } else if (_.get(to, 'group') === 'Worklab' && _.get(linkIn, 'group') === 'Page') {
            title = this.serviceTranslate.instant('MAP.WORKLAB_PAGE');
            color = '#ebed75';
        } else if (_.get(to, 'group') === 'Worklab' && _.get(linkIn, 'group') === 'Member') {
            title = this.serviceTranslate.instant('MAP.WORKLAB_MEMBER');
            color = '#ebed75';
        }

        this.addEdge({
            from: linkIn.id,
            to: to.id,
            title: title,
            color: {
                color: color
            }
        });
    }

    private addLinkOut(from, linkOut, position?) {
        linkOut = this.buildNodeFromItem(linkOut);
        linkOut.id = this.buildNodeIdFromEdge(from, linkOut);
        linkOut.isRight = true;
        linkOut.level = from.level + 1;

        position = position || { x: linkOut.level * this.nodeSpacing, y: 0 };

        linkOut.x = position.x;
        linkOut.y = position.y;

        this.addNode(linkOut);

        let color = '#70DF8B';
        let title = this.serviceTranslate.instant('ITEM.FOLLOWING.LABEL');

        if (_.get(from, 'group') === 'Member' && _.get(linkOut, 'group') === 'Worklab') {
            title = this.serviceTranslate.instant('MAP.WORKLAB_MEMBER');
            color = '#EBED75';
        } else if (_.get(from, 'group') === 'Page' && _.get(linkOut, 'group') === 'Page') {
            title = this.serviceTranslate.instant('MAP.LINKED_PAGE');
            color = '#da4f70';
        } else if (_.get(from, 'group') === 'Page' && _.get(linkOut, 'group') === 'Worklab') {
            title = this.serviceTranslate.instant('MAP.WORKLAB_PAGE');
            color = '#ebed75';
        }

        this.addEdge({
            from: from.id,
            to: linkOut.id,
            title: title,
            color: {
                color: color
            }
        });
    }

    private addExpertise(expertise, to, position?) {
        expertise.group = expertise.group || 'Page';
        expertise = this.buildNodeFromItem(expertise);
        expertise.id = this.buildNodeIdFromEdge(expertise, to);
        expertise.isLeft = true;
        expertise.level = to.level - 1;

        position = position || { x: expertise.level * this.nodeSpacing, y: 0 };

        expertise.x = position.x;
        expertise.y = position.y;

        this.addNode(expertise);
        this.addEdge({
            from: expertise.id,
            to: to.id,
            title: this.serviceTranslate.instant('MAP.EXPERT'),
            color: {
                color: '#F90'
            }
        });
    }

    private addDynLinkOut(from, dynLinkOut: DynamicLinksOut, position?) {

        const nodes = this.mapService.getNodesFromDynamicLinksOut(dynLinkOut);

        _.forEach(nodes, (n: MapNode) => {
            if (_.indexOf(['Page', 'Member', 'Worklab'], n.group) === -1) {
                // this node is a topic
                return;
            }

            n.id = this.buildNodeIdFromEdge(from, n);
            n.level = from.level + 1;
            position = position || { x: n.level * this.nodeSpacing, y: 0 };
            n.x = position.x;
            n.y = position.y;

            let isVisible = this.isNodeVisible(n);

            this.updateNodeVisibility(n, isVisible, false);
            this.addNode(n);

            const existingLink = _.head(this.edgesDataset.get({
                filter: function (item) {
                    return item.from === from.id
                        && item.to === n.id
                        && item.isDynamic === true;
                }
            }));
            if (existingLink) {
                // link exists, only concatenate words in label
                existingLink.title += '<br><b>- ' + dynLinkOut.word + '</b>';
                this.edgesDataset.update(existingLink);
                return;
            }
            this.addEdge({
                from: from.id,
                to: n.id,
                isDynamic: true,
                title: this.serviceTranslate.instant('MAP.ITEM_SPEAKS_ABOUT', {
                    title: from.label,
                    word: dynLinkOut.word
                }).replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&'),
                font: {
                    multi: 'html'
                },
                dashes: true
            });
        });
    }

    private addDynLinkIn(dynLinkIn: DynamicLinkIn, to, position?) {
        const node = this.mapService.getNodeFromDynamicLinkIn(dynLinkIn);
        if (_.indexOf(['Page', 'Member', 'Worklab'], node.group) === -1) {
            // this node is a topic
            return;
        }

        node.id = this.buildNodeIdFromEdge(node, to);
        node.level = to.level - 1;
        position = position || { x: node.level * this.nodeSpacing, y: 0 };
        node.x = position.x;
        node.y = position.y;

        let isVisible = this.isNodeVisible(node);

        this.updateNodeVisibility(node, isVisible, false);
        this.addNode(node);
        this.addEdge({
            from: node.id,
            to: to.id,
            isDynamic: true,
            title: this.serviceTranslate.instant('MAP.ITEM_CONTAINS_TEXT_ABOUT', {
                title1: node.label,
                title2: to.label
            }).replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&'),
            font: {
                multi: 'html'
            },
            dashes: true
        });
    }

    private addItem(item: Item, parentNode?, position?) {
        const n = parentNode || this.buildNodeFromItem(item);
        if (!parentNode) {
            n.level = 0;
            n.font = { multi: 'html', size: 20 };
            n.fixed = { x: true, y: true };
            this.addNode(n);
        }

        // Only expend left side
        if (!parentNode || parentNode.isLeft) {
            if (item instanceof Member) {
                _.forEach((item as Member).expertises, (pae: any) => {
                    // pagesAsExpert
                    this.addExpertise(pae, n, position);
                });
            }

            _.forEach(item.getLinksIn(), (li: any) => {
                this.addLinkIn(li, n, position);
            });

            _.forEach(item.dynlinksIn, (dynLinkIn: DynamicLinkIn) => {
                this.addDynLinkIn(dynLinkIn, n, position);
            });
        }

        // Only expend right side
        if (!parentNode || parentNode.isRight) {
            if (item instanceof Page) {
                _.forEach((item as Page).experts, (e: Member) => {
                    this.addExpert(n, e, position);
                });
            }

            _.forEach(item.getLinksOut(), (lo: any) => {
                this.addLinkOut(n, lo, position);
            });

            _.forEach(item.dynlinksOut, (dynlinksOut: DynamicLinksOut) => {
                this.addDynLinkOut(n, dynlinksOut, position);
            });
        }
    }

    private isNodeVisible(n: MapNode) {
        if (n.isDynamic) {
            return this.showDynamicsLinks && this.serviceListings.isKbVisible(n)
        }
        return true;
    }


    /**
     * Call this method if this.item.dynamicLinks has changed...
     */
    public buildDynamicLinksOut() {
        const mainNode = this.nodesDataset.get(this.rootItem.id);
        _.forEach(this.rootItem.dynlinksOut, (dynlinksOut: DynamicLinksOut) => {
            this.addDynLinkOut(mainNode, dynlinksOut);
        });
    }
    public buildDynamicLinksIn() {
        const mainNode = this.nodesDataset.get(this.rootItem.id);
        _.forEach(this.rootItem.dynlinksIn, (dynlinksIn: DynamicLinkIn) => {
            this.addDynLinkIn(dynlinksIn, mainNode);
        });
    }

    public centerNetwork() {
        if (this.network) {
            this.ngZone.runOutsideAngular(() => {
                this.network.redraw();
                this.network.fit();
            });
        }
    }


    private onNodeClick(p) {
        // Click happend BEFORE doubleClick in vis
        setTimeout(() => {
            const nid = _.first<string>(_.get(p, 'nodes'));
            if (nid && nid === this.rootItem.id || !this.network || this.stopSimpleClick === true) {
                return;
            } else if (nid && this.network.isCluster(nid)) {
                this.network.openCluster(nid);
            } else if (nid) {
                const node = this.nodesDataset.get(nid) as any;
                if (node.isLoaded) {
                    // Node is already loaded, expend/collapse it
                    this.collapseNode(node, !node.isCollapsed);
                    return;
                }

                // Set Loading img for clicked node
                this.setNodeLoading(node, true);

                const bestPosition = this.getOptimalPosition(node);

                // Get static links
                this.serviceItems.getItemByNode(node).pipe(
                    finalize(() => {
                        // this.settings.loadingInsideSpinner = false;
                        // this.setNodeLoading(node, false);
                    }),
                    first(),
                    takeUntil(this._destroyed$)
                )
                    .subscribe(
                        (item: Item) => {
                            // Get Dynamic links OUT
                            if (node.isRight) {
                                this.serviceDynamicLinks.getDynamicLinksOut(item.title + ' ' + item.content, item.id, item.kb, item.society).pipe(
                                    finalize(() => {
                                        // this.settings.loadingInsideSpinner = false;
                                        this.setNodeLoading(node, false);
                                    }),
                                    first(),
                                    takeUntil(this._destroyed$)
                                )
                                    .subscribe(
                                        (dynlinks: DynamicLinksOut[]) => {
                                            item.dynlinksOut = dynlinks;
                                            this.serviceListings.baseData.push(item);
                                            const allItems = this.serviceDatastore.getLinksByItems(this.serviceListings.baseData, true);
                                            this.addItem(item, node, bestPosition);
                                            this.serviceListings.setBaseData(allItems);
                                        }
                                    );
                            }

                            // Get Dynnamic links IN
                            if (node.isLeft) {
                                this.serviceDynamicLinks.getDynamicLinksIn(item).pipe(
                                    finalize(() => {
                                        // this.settings.loadingInsideSpinner = false;
                                        this.setNodeLoading(node, false);
                                    }),
                                    first(),
                                    takeUntil(this._destroyed$)
                                )
                                    .subscribe(
                                        (dynlinks: DynamicLinkIn[]) => {
                                            item.dynlinksIn = dynlinks;
                                            this.serviceListings.baseData.push(item);
                                            const allItems = this.serviceDatastore.getLinksByItems(this.serviceListings.baseData, true);
                                            this.addItem(item, node, bestPosition);
                                            this.serviceListings.setBaseData(allItems);
                                        }
                                    );
                            }
                        },
                        () => {
                        }
                    );
            }
        }, 300);
    }

    private onNodeDoubleClick(p) {
        const sld = this.serviceUrls.sld;

        this.stopSimpleClick = true;
        let nid = _.first<string>(_.get(p, 'nodes'));
        if (nid && this.network.isCluster(nid)) {
            // Double clic on cluster, Get the first child node id
            const clusteredNodeIds = this.network.getNodesInCluster(nid);
            if (Array.isArray(clusteredNodeIds) && clusteredNodeIds.length > 0) {
                nid = <string>clusteredNodeIds[0];
            }
        }
        if (nid) {
            const node = this.nodesDataset.get(nid) as any;
            let url = node.url;
            const group = _.toLower(node.group);
            const nodeSocietySlug = node.societySlug || this.currentSocietySlug;
            const nodeKbSlug = node.kbSlug || this.currentKbSlug;
            const isExternalKb = (nodeSocietySlug !== this.currentSocietySlug || nodeKbSlug !== this.currentKbSlug);
            if (!url || isExternalKb) {
                url = location.protocol + '//' + nodeKbSlug + '.' + nodeSocietySlug + '.' + sld + '/items/' + group + '/' + ServiceUrls.convertOIDtoID(node.originalId);
            }
            if (isExternalKb) {
                window.open(url, '_blank');
            } else {
                this.ngZone.run(() => {
                    this.router.navigate(['/items/' + group + '/' + ServiceUrls.convertOIDtoID(node.originalId)]);
                });
            }
        }
    }


    ngOnDestroy() {
        this.clearAll();

        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }
}
