import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

import { Kb } from '@models';
import { ServiceKb } from '@services';

@Component({
    selector: 'kb-confidentiality-agreement',
    templateUrl: './kb-confidentiality-agreement.component.html',
    styleUrls: ['./kb-confidentiality-agreement.component.scss']
})
export class KbConfidentialityAgreementComponent {

    private _kb: Kb;
    @Input()
    set kb(value: Kb) {
        this._kb = value;
        this._setAttributes();
    }
    get kb(): Kb {
        return this._kb;
    }

    private _editionMode: boolean;
    @Input()
    set editionMode(value: boolean) {
        this._editionMode = value;
    }
    get editionMode(): boolean {
        return this._editionMode;
    }

    public fc: FormControl;
    public defaultConfidentialAgreement: string;

    constructor(
        private serviceKb: ServiceKb,
        private serviceTranslate: TranslateService
    ) {
        this.defaultConfidentialAgreement = this.serviceTranslate.instant('KB.CONFIDENTIALITY.DEFAULT');
    }

    textChanged(){
        this._kb.confidentialityAgreement = this.fc.value;
        this.serviceKb.kb = this._kb;
    }

    private _setAttributes() {
        if (this.editionMode) {
            return;
        }
        const value = this._kb.confidentialityAgreement || this.defaultConfidentialAgreement;
        this.fc = new FormControl(value, []);
    }
}
