import * as _ from 'lodash';
import { Component, Input, Output } from '@angular/core';
import { finalize, first, takeUntil } from 'rxjs/operators';

import { ServiceItems, ServicePages, ServiceMembers, ServiceFollowers, ServiceErrors, ServiceToaster, ServiceTextAnalyser } from '@services';
import { Page, Member } from '@models';
import { LinkedItemsComponent } from '../linked-items.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'followed-pages',
    templateUrl: './followed-pages.component.html',
    styleUrls: ['./followed-pages.component.scss']
})
export class FollowedPagesComponent extends LinkedItemsComponent {

    private _member: Member;
    @Input()
    set member(value: Member) {
        this._member = value;
        this.handleEditionMode(this._editionMode);
    }
    get member(): Member {
        return this._member;
    }

    constructor(
        private serviceItems: ServiceItems,
        private servicePages: ServicePages,
        private serviceErrors: ServiceErrors,
        private serviceToaster: ServiceToaster,
        private serviceMembers: ServiceMembers,
        private serviceFollowers: ServiceFollowers, 
        protected serviceTextAnalyser: ServiceTextAnalyser
    ) {
        super(servicePages, serviceTextAnalyser);
        this.field = 'followedPages';
    }

    protected handleOrder(orderDirection: 'asc' | 'desc') {
        if (this._member instanceof Member) {
            this._member.followedPages = this.servicePages.sortArray(this._member.followedPages, orderDirection);
        }
    }

    protected handleEditionMode(value: boolean) {
        if (this._member instanceof Member) {
            this.isCreation = _.isUndefined(this._member.id);
            this.canEdit = this.serviceMembers.userCanModifyFollowedPages(this._member);
            if (value === true && this.canEdit === true) {
                this.loadAllPotentialLinkedItems<Page>(this._member.followedPages, this._member);
            }
        }
    }

    addLinkedItem(page: Page) {
        page.isLoading = true;
        this.ctrl.reset();
        this.ctrl.disable();
        this.actionInProgress = true;

        let followedPages = this._member.followedPages;
        followedPages.push(page);
        followedPages = this.servicePages.sortArray(followedPages, 'asc');
        this._member.followedPages = followedPages;

        if (!this.isCreation) {
            this.serviceFollowers.startFollowing(page, this._member).pipe(
                finalize(() => {
                    this.ctrl.enable();
                    this.actionInProgress = false;
                    this.newLinkInput.nativeElement.focus();
                }),
                first(),
                takeUntil(this._destroyed$)
            ).subscribe(
                (result: any) => {
                    page.isLoading = false;
                    _.remove(this.allPotentialLinkedItems, function(o: Page) { return o.id === page.id; });

                    this._member.followedPages = followedPages;
                    if (this._member.witness) {
                        this._member.witness.followedPages = followedPages;
                    }
                    this.serviceItems.item = this._member;

                    const params = {
                        member: _.get(result, 'itemFrom.title'),
                        title: _.get(result, 'itemTo.title')
                    };
                    this.serviceToaster.success('ITEM.FOLLOWERS.PAGE.ADD', params);
                },
                (error: HttpErrorResponse) => {
                    _.remove(this._member.followedPages, function(o: Page) { return o.id === page.id; });

                    this.serviceErrors.handleError('ITEM.FOLLOWERS.PAGE.ADD', error);
                });
        } else {
            page.isLoading = false;
            _.remove(this.allPotentialLinkedItems, function(o: Page) { return o.id === page.id; });
            this.ctrl.enable();
            this.actionInProgress = false;
            this.newLinkInput.nativeElement.focus();
        }
    }

    removeLinkedItem(page: Page) {
        page.isLoading = true;
        this.ctrl.disable();
        this.actionInProgress = true;

        if (!this.isCreation) {
            this.serviceFollowers.stopFollowing(page, this._member).pipe(
                finalize(() => {
                    page.isLoading = false;
                    this.ctrl.enable();
                    this.actionInProgress = false;
                }),
                first(),
                takeUntil(this._destroyed$)
            ).subscribe(
                (result: any) => {
                    this.allPotentialLinkedItems.push(page);

                    const followedPages = this._member.followedPages;
                    _.remove(followedPages, function(o: Page) { return o.id === page.id; });

                    this._member.followedPages = followedPages;
                    if (this._member.witness) {
                        this._member.witness.followedPages = followedPages;
                    }
                    this.serviceItems.item = this._member;

                    const params = {
                        member: _.get(result, 'itemFrom.title'),
                        title: _.get(result, 'itemTo.title')
                    };
                    this.serviceToaster.success('ITEM.FOLLOWERS.PAGE.REMOVE', params);
                },
                (error: HttpErrorResponse) => {
                    let followedPages = this._member.followedPages;
                    followedPages.push(page);
                    followedPages = this.servicePages.sortArray(followedPages, 'asc');

                    this._member.followedPages = followedPages;
                    if (this._member.witness) {
                        this._member.witness.followedPages = followedPages;
                    }
                    this.serviceItems.item = this._member;

                    this.serviceErrors.handleError('ITEM.FOLLOWERS.PAGE.REMOVE', error);
                }
            );
        } else {
            this.allPotentialLinkedItems.push(page);
            _.remove(this._member.followedPages, function(o: Page) { return o.id === page.id; });
            page.isLoading = false;
            this.ctrl.enable();
            this.actionInProgress = false;
        }
    }
}
