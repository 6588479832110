import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { ServicePreferences } from '@services';

@Pipe({
    name: 'i2KnHour'
})
export class I2KnHour implements PipeTransform {

    constructor(

        private servicePreferences: ServicePreferences
    ) {
    }

    transform(value: any, args?: any): any {
        const language = this.servicePreferences.getPreference('language');
        const timeFormatMoment = this.servicePreferences.getPreference('timeFormatMoment');
        const timezone = this.servicePreferences.getPreference('timezone');

        return moment(moment.tz(value, timezone)).locale(language).format(timeFormatMoment);
    }
}
