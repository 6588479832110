import * as _ from 'lodash';
import { Component, Input, ViewChild, OnInit, HostListener, ElementRef, OnDestroy, Renderer2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { Page, Member, Worklab } from '@models';
import { ServiceItems, ServiceSecurity, ServiceFroala, ServiceDynamicLinks, ServiceKekule } from '@services';

@Component({
    selector: 'item-content',
    templateUrl: './item-content.component.html',
    styleUrls: ['./item-content.component.scss']
})
export class ItemContentComponent implements OnInit, OnDestroy {

    @ViewChild('content', {static: false}) contentElementRef: ElementRef;

    private _item: Page | Member | Worklab;
    @Input()
    set item(value: Page | Member | Worklab) {
        this._item = value;
        this.displayedContent = this.sanitizer.bypassSecurityTrustHtml(this._item.content);
        this.contentMinSize = this._item.getContentMinSize();
        this._handleEditionMode(this._editionMode);
        this.buildDynamicLinks();
    }
    get item(): Page | Member | Worklab {
        return this._item;
    }

    private _editionMode: boolean;
    @Input()
    set editionMode(value: boolean) {
        this._editionMode = value;
        this._handleEditionMode(this._editionMode);
    }
    get editionMode(): boolean {
        return this._editionMode;
    }

    public froalaConfig: Object;
    public contentMinSize: number;
    public tooShort = false;
    public displayedContent: any;
    public isDisabled = false;

    private _canEdit = false;
    private _interval$;
    private _ctrlKeyIsPressed = false;

    private globalListenFunc: Function;

    constructor(
        private sanitizer: DomSanitizer,
        private serviceItems: ServiceItems,
        private serviceFroala: ServiceFroala,
        private serviceSecurity: ServiceSecurity,
        private serviceDynamicLinks: ServiceDynamicLinks,
        private serviceKekule: ServiceKekule,
        private renderer: Renderer2
    ) {
    }

    ngOnInit() {
        this.froalaConfig = this.serviceFroala.getFroalaConfig('ITEM.CONTENT.PLACEHOLDER', this.item);
        this.froalaConfig = _.extend(this.froalaConfig, {heightMin: 350});
        this.serviceFroala.setToolbarPosition('#itemContentTextarea');

        this.globalListenFunc = this.renderer.listen('document', 'keydown', e => {
            this._ctrlKeyIsPressed = (e.key === 'Control');
        });
    }

    /**
     * Recommandation Modal Event Handler
     * 
     * Event filter if CTRL key is pressed to navigate to
     * the recommandation modal
     * @param event
     * @returns {void}
     * 
     * @memberOf ItemContentComponent  
     */ 
    recommendationsOnTextSelection(event: any): void {
        /**
         * If CTRL key is pressed, we can navigate to the recommandation modal
         */
        if (this._ctrlKeyIsPressed) {
            this.serviceFroala.recommendationsOnSelection(event);
        }
        this._ctrlKeyIsPressed = false;
    }

    contentChanged(newContent) {
        this._item.content = newContent;
        this._item.errors = this._checkContent();
        this.serviceItems.item = this._item;
        setTimeout(() => {
            this.serviceFroala.setToolbarTopPosition();
        }, 750);
    }

    @HostListener('window:resize')
    onWindowResize(): void {
        this.serviceFroala.setToolbarPosition('#itemContentTextarea');
    }

    private _checkContent() {
        const errors = this._item.errors;
        this.tooShort = _.get(errors, 'content.key') === 'tooShort';
        return errors;
    }

    private _handleEditionMode(value: boolean) {
        if (this._item instanceof Page || this._item instanceof Member || this._item instanceof Worklab) {
            this._canEdit = this.serviceItems.userCanModifyContent(this._item);
            if (value === true && this._canEdit === true) {
                const isLocked = this._item.islocked;
                const isAdmin = this.serviceSecurity.isAdmin();
                if (isLocked === true && !isAdmin) {
                    this.isDisabled = true;
                } else {
                    this.serviceFroala.setToolbarPosition('#itemContentTextarea');
                }
                this._checkContent();
            }
        }
    }

    buildDynamicLinks() {
        if (this.item.dynlinksOut && this.contentElementRef && this.item.id!="#39:240") {
            this.displayedContent = this.serviceDynamicLinks.getHtmlWithMarkers(this.item.content, this.item.dynlinksOut);
            this.serviceDynamicLinks.insertDynamicLinksInMarkedElements(this.contentElementRef.nativeElement);
            this.buildKekuleViewers();
        }
    }

    buildKekuleViewers() {
        setTimeout(() => {
            this.serviceKekule.initKekuleViewers();
        }, 100);
    }

    ngOnDestroy() {
        clearInterval(this._interval$);
        this.globalListenFunc();
    }
}
