import * as _ from 'lodash';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

import { ItemsDictionary, Item, CookieNavigationHistory } from '@models';
import { CommonService } from '@services/common.service';
import { ServiceKb } from './kbs.service';
import { ServiceDatastore } from './datastore.service';
import { ServiceSecurity } from './security.service';

@Injectable({
    providedIn: 'root'
})
export class ServiceNavigationHistory extends CommonService {
    // Cookie limitation (20 items is arbitrary - cookie max is 4k)
    private MAX_HISTORY_SIZE = 20;
    public historyObservable: Observable<CookieNavigationHistory[]>;

    private _historySubject: BehaviorSubject<CookieNavigationHistory[]>;

    constructor(
        protected injector: Injector,
        private serviceSecurity: ServiceSecurity,
        private serviceKb: ServiceKb,
        private serviceDatastore: ServiceDatastore
    ) {
        super(injector);

        const history: CookieNavigationHistory[] = JSON.parse(this.serviceSecurity.getCookie('i2kn.history') || '[]')  || [];

        this._historySubject = new BehaviorSubject<CookieNavigationHistory[]>(history);
        this.historyObservable = this._historySubject.asObservable();
    }

    get history(): CookieNavigationHistory[] {
        return this._historySubject.value;
    }

    set history(value: CookieNavigationHistory[]) {
        value = _.take(value, this.MAX_HISTORY_SIZE);
        this._historySubject.next(value);
        this.serviceSecurity.setCookie('i2kn.history', JSON.stringify(value), 0);
    }

    addEntry(item: Item) {
        const currentKb = this.serviceKb.kb;
        const currentKbSlug = this.currentKbSlug;
        const currentSocietySlug = this.currentSocietySlug;

        let history = this.history;
        if (item.id && item.group && item.title) {
            history.unshift(
                new CookieNavigationHistory().deserialize({
                    d: moment().format(),
                    ss: currentSocietySlug,
                    st: currentKb.society.title,
                    ks: currentKbSlug,
                    kt: currentKb.title,
                    i: item.id,
                    t: item.title,
                    g: item.group
                })
            );
            history = _.take(history, this.MAX_HISTORY_SIZE);
            this.history = history;
        }
    }

    clean() {
        this.history = [];
    }

    getHistoryItems(): Observable<Item[]> {
        const currentKbSlug = this.currentKbSlug;
        const currentSocietySlug = this.currentSocietySlug;

        const historyItemsIds = _.map(_.filter(this.history, function(cookieNavigationHistory: CookieNavigationHistory ) {
            return (cookieNavigationHistory.ks === currentKbSlug && cookieNavigationHistory.ss === currentSocietySlug);
        }), 'i');

        return this.serviceDatastore.dataStoreObservable.pipe(
            map(
                (allItems: ItemsDictionary) => {
                    return _.filter(allItems, function(item: Item) {
                        return historyItemsIds.indexOf(item.id) !== -1;
                    });
                }
            )
        );
    }
}
