import { Component, OnInit, OnDestroy } from '@angular/core';
import { ServiceListings, ServiceMap } from '@services';

@Component({
    selector: 'filters-by-keywords-links-count',
    templateUrl: './filters-by-keywords-links-count.component.html',
    styleUrls: ['./filters-by-keywords-links-count.component.scss']
})
export class FiltersByKeywordsLinksCountComponent implements OnInit, OnDestroy {

    public linksMin: number = 2;

    constructor(
        private serviceListings: ServiceListings,
        private serviceMap: ServiceMap
    ) {
    }

    ngOnInit() {
        
    }

    onChange() {
        this.serviceMap.emitFilterByKeywordsLinksCountChanged(this.linksMin);
    }

    reset() {
        this.linksMin = 2;
    }


    ngOnDestroy() {
        //this.serviceListings.mapTypeKeywordEnabled = false;
    }
}
