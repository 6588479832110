import { Deserializable } from './deserializable.model';
import { Skin } from './skin.model';
import { Language } from './language.model';

export class Society implements Deserializable {
    slug: string;
    title: string;

    sld: string;
    ssoUrl: string;
    kbs: Kb[];

    deserialize(input: any) {
        const hostParts = window.location.host.split('.');
        input.slug = input.slug || hostParts[1];

        hostParts.shift();
        hostParts.shift();
        input.sld = input.sld || hostParts.join('.');

        input.title = input.title || input.slug;
        input.ssoUrl = '//' + PREFIXS.ssoPrefix + '.' + input.sld + '/';

        Object.assign(this, input);
        return this;
    }
}

export class Kb implements Deserializable {
    // public: boolean;


    id?: number;
    urls?: any;

    title: string;
    slug: string;
    society: Society;
    isPublic: boolean;
    confidentialityAgreement: string;
    needsConfidentialityAgreement: boolean;
    description: string;
    news: string;
    userCanCreateTags: boolean;

    visibility: string;
    needsToken: boolean;
    skins: Skin[];
    members: any[];


    languages: Language[];
    defaultDateTimeFormat: string;
    defaultDefaultListingView: string;
    defaultDefaultListingViewOnItem: string;
    defaultEmailOnTopic: boolean;
    defaultKeepFilters: boolean;
    defaultLanguage: 'fr' | 'en';
    defaultOldTitleAsAlias: boolean;
    defaultTheme: string;
    defaultSummaryFrequency: string;
    defaultTimezone: string;



    url: string;
    apiUrl: string;
    isAdmin?: boolean;
    role: string;

    isCurrentKb = false;
    isDirty = false;
    editionMode = false;

    witness: any;

    deserialize(input: any) {
        const hostParts = window.location.host.split('.');
        const currentKbSlug = hostParts[0];
        const currentSocietySlug = hostParts[1];

        input.title = input.title || input.slug;

        if (input.public === true || input.public === false) {
            input.isPublic = input.public;
        }
        delete input.public;

        input.society = new Society().deserialize(input.society);
        input.url = '//' + input.slug + '.' + input.society.slug + '.' + input.society.sld;
        input.apiUrl = '//' + PREFIXS.apiPrefix + '.' + input.society.slug + '.' + input.society.sld + '/' + input.slug + '/';

        input.languages = [
            new Language().deserialize({ id: 'fr', label: 'Français', enabled: true }),
            new Language().deserialize({ id: 'en', label: 'English', enabled: true })
        ];


        input.isCurrentKb = (currentSocietySlug === input.society.slug && currentKbSlug === input.slug);



        Object.assign(this, input);
        return this;
    }

    isKbValidForAPI() {
        const errors = [];
        return (errors.length === 0) ? true : errors;
    }
}
