import { FormGroup, FormControl } from '@angular/forms';

export function EmailValidator(control: FormControl): {[key: string]: any} {
    const emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (control.value && !emailRegexp.test(control.value)) {
        return {invalidEmail: true};
    }
}

// export function matchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
//     return (group: FormGroup) => {
//         let password= group.controls[passwordKey];
//         let passwordConfirmation= group.controls[passwordConfirmationKey];
//         if (password.value !== passwordConfirmation.value) {
//             return passwordConfirmation.setErrors({mismatchedPasswords: true})
//         }
//     }
// }
