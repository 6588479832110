import * as _ from 'lodash';
import { Component, Injector } from '@angular/core';
import { FilterByComponent } from '../abstract-filter-by.component';

@Component({
    selector: 'filters-by-forum',
    templateUrl: './filters-by-forum.component.html',
    styleUrls: ['./filters-by-forum.component.scss']
})
export class FiltersByForumComponent extends FilterByComponent {

    public selected: 'any' | 'with' | 'without';

    constructor(
        protected injector: Injector
    ) {
        super(injector);
        this.filterKey = 'forum';
    }

    initValue(listFilters: any) {
        this.selected = _.get(listFilters, this.filterKey, 'any');
    }

    onChange() {
        const value = this.selected;
        let filters = this.serviceListings.filters;
        if (!value || value === 'any') {
            delete this.serviceListings.filters[this.filterKey];
            this.isFiltersDirty.emit({ filterKey: this.filterKey, isDirty: false });
        } else {
            filters = _.extend(filters, {
                'forum': value
            });
            this.checkIsDirty(value, this.filterKey);
        }
        this.serviceListings.filters = filters;

        this.serviceListings.dataStoreChanged();
        this.saveFilter();
    }

    reset() {
        this.selected = 'any';

        const filters = this.serviceListings.filters;
        delete filters[this.filterKey];
        this.serviceListings.filters = filters;
        this.isFiltersDirty.emit({ filterKey: this.filterKey, isDirty: false });
    }
}
