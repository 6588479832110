import * as _ from 'lodash';
import {Component, EventEmitter, OnInit, OnDestroy, Output, Input, ViewChild, ElementRef} from '@angular/core';
import {Router} from '@angular/router';
import {Tag, TagFlatNode} from '@models';
import {SelectionModel} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {Observable, Subject} from 'rxjs';
import {ServiceTags, ChecklistDatabase} from '@src/app/services/tags.service';
import {takeUntil} from 'rxjs/internal/operators/takeUntil';
import {ServiceTextAnalyser} from '@src/app/services/text-analyser.service';
import {ServiceListings, ServiceItems, ServiceKb, ServiceSecurity} from '@services';
import {Page, Member, Worklab} from '@models';
import {TranslateService} from '@ngx-translate/core';
import {FormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/internal/operators';

export class NewTag {
    title: string;
    parentId: string;
    parentTitle: string;
}

export class ModifiedTag {
    id: string;
    title: string;
    parentId: string;
    parentTitle: string;
}

@Component({
    selector: 'tags-tree',
    styleUrls: ['./tags-tree.scss'],
    templateUrl: './tags-tree.html',
    providers: [ChecklistDatabase],
})
export class TagsTreeComponent implements OnInit, OnDestroy {

    @ViewChild('newTag', {static: false}) newTag: ElementRef<HTMLInputElement>;


    /** Checkbox for selection are only available on tags selection on an Item on edition mode */
    public disableCheckBoxes = false;

    /** Item of the Tags */
    private _item: Page | Member | Worklab;

    @Input()
    set item(value: Page | Member | Worklab) {
        this._item = value;
        this.disableCheckBoxes = (!value || value.editionMode === false);
        this.selectedTagsIds = _.map(_.get(value, 'tags', []), 'id');
    }

    get item(): Page | Member | Worklab {
        return this._item;
    }

    /** Tags filtered by string */
    public searchedTerm = '';

    /** Emit changes on tags selection */
    @Output() tagsSelected = new EventEmitter();

    /** Map from flat node to nested node. This helps us finding the nested node to be modified */
    public flatNodeMap = new Map<TagFlatNode, Tag>();

    /** Map from nested node to flattened node. This helps us to keep the same object for selection */
    public nestedNodeMap = new Map<Tag, TagFlatNode>();

    public treeControl: FlatTreeControl<TagFlatNode>;

    public treeFlattener: MatTreeFlattener<Tag, TagFlatNode>;

    public dataSource: MatTreeFlatDataSource<Tag, TagFlatNode>;

    public myControl = new FormControl();

    public filteredTags: Observable<TagFlatNode[]>;

    /** The selection for checklist */
    public checklistSelection = new SelectionModel<TagFlatNode>(true /* multiple */);

    /** The selected tags ids */
    public selectedTagsIds: string[] = [];

    public loading = true;

    public newTags: NewTag[];

    protected _destroyed$ = new Subject();

    private allTagsFromAPI: Map<string, Tag>;

    public tagsOnItem = false;

    public useTagsTree = true;

    public newTagAlreadyExists = true;

    public inputTagIsToggled = false;

    public disableCreateButton = true;
    
    public inputValue: string;
    
    public canUserCreateTags: boolean;
    
    private _isUsedOnPage: boolean = true;

    @Input()
    set isUsedOnPage(value: boolean) {
        this._isUsedOnPage = value;
    }

    get isUsedOnPage(): boolean {
        return this._isUsedOnPage;
    }

    constructor(
        private serviceItems: ServiceItems,
        private _router: Router,
        private _database: ChecklistDatabase,
        private _serviceTags: ServiceTags,
        private _serviceTranslate: TranslateService,
        private _serviceListings: ServiceListings,
        private _serviceTextAnalyser: ServiceTextAnalyser,
        private serviceKb: ServiceKb,
        private serviceSecurity: ServiceSecurity
    ) {
        this.newTags = [];

        this.treeFlattener = new MatTreeFlattener(
            this.transformer,
            this.getLevel,
            this.isExpandable,
            this.getChildren
        );

        this.treeControl = new FlatTreeControl<TagFlatNode>(
            this.getLevel,
            this.isExpandable
        );
        this.dataSource = new MatTreeFlatDataSource(
            this.treeControl,
            this.treeFlattener
        );

        this.loading = true;
        this._serviceTags
            .fetchAllTags()
            .pipe(takeUntil(this._destroyed$))
            .subscribe((allTagsFromAPI: Map<string, Tag>) => {
                this.allTagsFromAPI = allTagsFromAPI;
                setTimeout(() => {
                    this.loading = false;
                    this._database.initialize(Array.from(this.allTagsFromAPI.values()));
                }, 200);
            });

        // this.settings = this._appSettings.settings;

        this._database.dataChange.subscribe((result) => {

            result.sort((a, b): number => {
                const aIsToggled = this.isTagToggled(a);
                const bIsToggled = this.isTagToggled(b);

                if (aIsToggled && !bIsToggled) {
                    return -1;
                }

                if (!aIsToggled && bIsToggled) {
                    return 1;
                }

                return 0;
            });

            this.dataSource.data = result;
        });
        
        this.canUserCreateTags = serviceKb.canUserCreateTags()
    }

    ngOnInit() {
        this.useTagsTree = USE_TAGS_TREE;

        if (_.isUndefined(this._item) === true) {
            const userFilters = this._serviceListings.filters;
            this.selectedTagsIds = _.get(this, 'data.selectedTagsIds', _.get(userFilters, 'tags', []));
        }

        this.serviceItems.itemSaved.pipe(
            takeUntil(this._destroyed$)
        ).subscribe(
            (item: Page | Member | Worklab) => {
                this.selectedTagsIds = _.map(_.get(item, 'tags', []), 'id');
                this.disableCheckBoxes = !item.editionMode;
                this.loading = true;
                this._serviceTags
                    .fetchAllTags()
                    .pipe(takeUntil(this._destroyed$))
                    .subscribe((allTagsFromAPI: Map<string, Tag>) => {
                        this.allTagsFromAPI = allTagsFromAPI;
                        setTimeout(() => {
                            this.loading = false;
                            this._database.initialize(Array.from(this.allTagsFromAPI.values()));
                        }, 200);
                    });
            }
        );

        this.filteredTags = this.myControl.valueChanges
            .pipe(
                startWith(''),
                map(value => this._filter(value))
            );

        this.tagsSelected.subscribe(() => this.checkNode());
    }

    private _filter(title: string): TagFlatNode[] {
        const filterValue = title.toLowerCase();

        return this.treeControl.dataNodes.filter(tag => {
            if (tag.title) {
                return tag.title.toLowerCase().includes(filterValue);
            } else {
                return false;
            }
        });
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }

    goTo(url: string): void {
        if (url) {
            this.loading = true;
            this._router.navigate([url]);
        }
    }

    filterTree() {
        const searchedTerm = _.trim(
            this._serviceTextAnalyser
                .removeDiacritics(this.searchedTerm)
                .toLowerCase()
        );
        const dataNodes = this.treeControl.dataNodes;
        dataNodes.forEach((node: TagFlatNode) => {
            const title = _.trim(
                this._serviceTextAnalyser
                    .removeDiacritics(node.title)
                    .toLowerCase()
            );
            node.isVisible =
                title.indexOf(searchedTerm) > -1 ||
                this.checklistSelection.isSelected(node);
            this.checkAllParentsVisibility(node);
        });
    }

    checkAllParentsVisibility(node: TagFlatNode): void {
        let parent: TagFlatNode | null = this.getParentNode(node);
        const searchedTerm = _.trim(
            this._serviceTextAnalyser
                .removeDiacritics(this.searchedTerm)
                .toLowerCase()
        );
        while (parent) {
            const descendants = this.treeControl.getDescendants(parent);
            const anyChildVisible = descendants.some((child) => {
                return child.isVisible === true;
            });
            const title = _.trim(
                this._serviceTextAnalyser
                    .removeDiacritics(parent.title)
                    .toLowerCase()
            );
            parent.isVisible =
                title.indexOf(searchedTerm) > -1 || anyChildVisible;
            if (parent.isVisible) {
                this.setChildrenVisibility(parent, true);
            }
            parent = this.getParentNode(parent);
        }
    }

    setChildrenVisibility(node: TagFlatNode, visibility: boolean): void {
        const descendants = this.treeControl.getDescendants(node);
        const searchedTerm = _.trim(
            this._serviceTextAnalyser
                .removeDiacritics(this.searchedTerm)
                .toLowerCase()
        );
        descendants.forEach((child: TagFlatNode) => {
            const title = _.trim(
                this._serviceTextAnalyser
                    .removeDiacritics(child.title)
                    .toLowerCase()
            );
            child.isVisible =
                title.indexOf(searchedTerm) > -1 ||
                this.checklistSelection.isSelected(child);
            if (child.expandable) {
                this.setChildrenVisibility(child, visibility);
            }
        });
    }

    emitSelection() {
        const selectedTagsIds = (this.checklistSelection.selected || [])
            .filter((tagFlatNode: TagFlatNode) => {
                return tagFlatNode.expandable !== true && tagFlatNode.id;
            })
            .map((tagFlatNode: TagFlatNode) => {
                return tagFlatNode.id;
            });
        const selectedNewTagsTitles = (this.checklistSelection.selected || [])
            .filter((tagFlatNode: TagFlatNode) => {
                return tagFlatNode.expandable !== true && !tagFlatNode.id;
            })
            .map((tagFlatNode: TagFlatNode) => {
                return tagFlatNode.title;
            });
        this.tagsSelected.emit({
            'selectedTagsIds': selectedTagsIds,
            'selectedNewTagsTitles': selectedNewTagsTitles,
            'allTags': this.allTagsFromAPI
        });
    }

    getLevel = (node: TagFlatNode) => node.level;

    isExpandable = (node: TagFlatNode) => node.expandable;

    isVisible = (node: TagFlatNode) => node.isVisible;

    hasError = (node: TagFlatNode) => node.error;

    getChildren = (node: Tag): Tag[] => node.children;

    hasChild = (_: number, _nodeData: TagFlatNode) => _nodeData.expandable;

    /**
     * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
     */
    transformer = (node: Tag, level: number) => {
        const nodeId = node.id;
        const existingNode = this.nestedNodeMap.get(node);
        const flatNode =
            existingNode && existingNode.id === node.id
                ? existingNode
                : new TagFlatNode();
        flatNode.id = nodeId;
        flatNode.title = node.title;
        flatNode.level = level;
        flatNode.expandable = !!node.children;
        flatNode.url = node.url;
        flatNode.isVisible = true;
        this.flatNodeMap.set(flatNode, node);
        this.nestedNodeMap.set(node, flatNode);

        if (this.selectedTagsIds.indexOf(nodeId) > -1) {
            this.checklistSelection.select(flatNode);
            this.treeControl.expand(flatNode);
        }
        return flatNode;
    }

    /** Whether all the descendants of the node are selected. */
    descendantsAllSelected(node: TagFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected = descendants.every((child) =>
            this.checklistSelection.isSelected(child)
        );
        if (descAllSelected === true) {
            this.treeControl.expand(node);
        }
        return descAllSelected;
    }

    /** Whether part of the descendants are selected */
    descendantsPartiallySelected(node: TagFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const result = descendants.some((child) =>
            this.checklistSelection.isSelected(child)
        );
        
        return result && !this.descendantsAllSelected(node);
    }

    /** Toggle the tag selection. Select/deselect all the descendants node */
    tagSelectionToggle(node: TagFlatNode): void {
        this.checklistSelection.toggle(node);
        this.checkChildren(node);
        const descendants = this.treeControl.getDescendants(node);

        // Force update for the parent
        descendants.every((child) => this.checklistSelection.isSelected(child));
        this.checkAllParentsSelection(node);
        this.emitSelection();
    }

    /** Toggle a leaf selection. Check all the parents to see if they changed */
    tagGroupSelectionToggle(node: TagFlatNode): void {
        this.checklistSelection.toggle(node);
        this.checkChildren(node);
        this.checkAllParentsSelection(node);
        this.emitSelection();
    }

    checkChildren(node: TagFlatNode): void {
        const descendants = this.treeControl.getDescendants(node);
        this.checklistSelection.isSelected(node)
            ? this.checklistSelection.select(...descendants)
            : this.checklistSelection.deselect(...descendants);
    }

    /* Checks all the parents when a leaf node is selected/unselected */
    checkAllParentsSelection(node: TagFlatNode): void {
        let parent: TagFlatNode | null = this.getParentNode(node);
        while (parent) {
            this.checkRootNodeSelection(parent);
            parent = this.getParentNode(parent);
        }
    }

    /** Check root node checked state and change it accordingly */
    checkRootNodeSelection(node: TagFlatNode): void {
        const nodeSelected = this.checklistSelection.isSelected(node);
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected = descendants.every((child) =>
            this.checklistSelection.isSelected(child)
        );
        if (nodeSelected && !descAllSelected) {
            this.checklistSelection.deselect(node);
        } else if (!nodeSelected && descAllSelected) {
            this.checklistSelection.select(node);
        }
    }

    /* Get the parent node of a node */
    getParentNode(node: TagFlatNode): TagFlatNode | null {
        const currentLevel = this.getLevel(node);

        if (currentLevel < 1) {
            return null;
        }
        const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

        for (let i = startIndex; i >= 0; i--) {
            const currentNode = this.treeControl.dataNodes[i];

            if (this.getLevel(currentNode) < currentLevel) {
                return currentNode;
            }
        }
        return null;
    }

    reset() {
        const dataNodes = this.treeControl.dataNodes;
        dataNodes.forEach((node: TagFlatNode) => {
            this.checklistSelection.deselect(node);
        });

        this.treeControl.collapseAll();
        this.selectedTagsIds = [];
        this.newTags = [];
    }

    /** Select the category so we can insert the new item. */
    addNewItem(node: TagFlatNode) {
        const newNodeIsPresent = (this._database.data || []).some(child => child.title === '');
        if (!newNodeIsPresent) {
            const newTag = new Tag();
            newTag.title = '';
            this._database.insertRootTag(newTag);
            this.treeControl.expand(node);
            setTimeout(() => {
                if (this.newTag && this.newTag.nativeElement) {
                    this.newTag.nativeElement.focus();
                }
            }, 500);
        }
    }

    checkNode() {
        const title = this.inputValue;
        if (title && _.trim(title) !== '') {
            if(this.serviceSecurity.isAdmin() || this.canUserCreateTags){
                this.disableCreateButton = false;
            }

            const selectedNode = this.treeControl.dataNodes.find((node) => node.title === title);

            this.newTagAlreadyExists = !!selectedNode;
            this.inputTagIsToggled = this.checklistSelection.isSelected(selectedNode);
        }else{
            this.disableCreateButton = true;
        }
    }
    
    toggleNode() {
        const selectedNode = this.treeControl.dataNodes.find((node) => node.title == this.inputValue);
        this.tagSelectionToggle(selectedNode);
    }
    
    addNode() {
        const title = this.inputValue;
        if (title && _.trim(title) !== '') {
            const tag = new Tag();
            tag.title = title;
            const newTag = new NewTag();
            newTag.title = title;

            this._database.insertRootTag(tag);
            this.newTags.push(newTag);
    
            this.toggleNode();
        } 
    }
    
    addOrToggleNode(){
        this.checkNode();
        if(this.newTagAlreadyExists){
            this.toggleNode();
        }else{
            this.addNode();
        }
    }

    saveNode(node: TagFlatNode, title: string) {
        if (title && _.trim(title) !== '' && !node.error) {
            const nestedNode = this.flatNodeMap.get(node);
            const originalTitle = nestedNode.title;

            if (!this.newTagAlreadyExists) {
                const newTags = this.newTags;
                _.remove(this.newTags, function (existingNewTag: NewTag) {
                    return existingNewTag.title === originalTitle;
                });
                const newTag = new NewTag();
                newTag.title = title;
                // newTag.parentId = (nestedNode.parent) ? nestedNode.parent.id : null;
                // newTag.parentTitle = (nestedNode.parent) ? nestedNode.parent.title : null;
                newTags.push(newTag);

                const tag = new Tag();
                tag.title = title;
                this._database.insertRootTag(tag);
                const dataNodes = this.treeControl.dataNodes;
                dataNodes.forEach((n: TagFlatNode) => {
                    if (n.title === title) {
                        this.checklistSelection.select(n);
                    }
                });
                this._database.deleteItem(nestedNode);
                this.newTags = _.cloneDeep(newTags);
                this.emitSelection();
            }
        } else {
            delete node.error;
            this.removeNode(node);
        }
        this.checkNode();
    }

    removeNode(node: TagFlatNode) {
        const nestedNode = this.flatNodeMap.get(node);
        this._database.deleteItem(nestedNode);
        this.checklistSelection.deselect(node);
        this.emitSelection();
    }

    public isTagToggled(tag: Tag): boolean {
        let isTagToggled = false;

        if (tag.children) {
            for (const tagChild of tag.children) {
                if (this.isTagToggled(tagChild)) {
                    isTagToggled = true;
                    break;
                }
            }
        } else {
            isTagToggled = this.selectedTagsIds.some(tagId => tagId === tag.id);
        }

        return isTagToggled;
    }
}