import * as _ from 'lodash';
import { Component, Injector } from '@angular/core';
import { ServiceListings } from '@services';
import { FilterByComponent } from '../abstract-filter-by.component';

@Component({
    selector: 'filters-by-page-status',
    templateUrl: './filters-by-page-status.component.html',
    styleUrls: ['./filters-by-page-status.component.scss']
})
export class FiltersByPageStatusComponent extends FilterByComponent {

    public draft = false;
    public toValidate = false;
    public validated = false;

    constructor(
        protected injector: Injector
    ) {
        super(injector);
        this.filterKey = 'pageStatus';
    }

    initValue(listFilters: any) {
        const savedValues = _.get(listFilters, this.filterKey);
        this.draft = (_.indexOf(savedValues, 'draft') !== -1);
        this.toValidate = (_.indexOf(savedValues, 'to_validate') !== -1);
        this.validated = (_.indexOf(savedValues, 'validated') !== -1);
    }

    onChange(apply?: boolean) {
        apply !== false ? apply = true : apply = false;
        const status = [];
        if (this.draft) {
            status.push('draft');
        }
        if (this.toValidate) {
            status.push('to_validate');
        }
        if (this.validated) {
            status.push('validated');
        }

        let filters = this.serviceListings.filters;
        if ((status.length === 0 || status.length === 3)) {
            if ('pageStatus' in filters) {
                delete filters[this.filterKey];
                this.isFiltersDirty.emit({ filterKey: this.filterKey, isDirty: false });
            }
        } else {
            filters = _.extend(filters, {
                'pageStatus': status
            });
            this.checkIsDirty(status, this.filterKey);
        }
        this.serviceListings.filters = filters;

        if (apply) {
            this.serviceListings.dataStoreChanged();
            this.saveFilter();
        }
    }

    reset() {
        this.draft = false;
        this.toValidate = false;
        this.validated = false;
        this.onChange(false);
    }
}
