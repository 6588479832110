import * as _ from 'lodash';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceSecurity, ServiceErrors, ServiceToaster } from '@services';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Component({
    template: ''
})
export class LoginTokenizerComponent implements OnInit, OnDestroy {
    private _destroyed$ = new Subject();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private serviceTranslate: TranslateService,
        private serviceToaster: ServiceToaster,
        private serviceErrors: ServiceErrors,
        private serviceSecurity: ServiceSecurity
    ) {
    }

    ngOnInit() {
        this.route.queryParamMap
        .pipe(
            takeUntil(this._destroyed$)
        ).subscribe(queryParams => {
            const token = queryParams.get('token');
            const refreshToken = queryParams.get('refresh_token');
            if (!_.isEmpty(token) && !_.isEmpty(refreshToken)) {
                this.serviceSecurity.setAccessToken(token);
                this.serviceSecurity.setRefreshToken(refreshToken);
                localStorage.setItem('authBySaml', 'yes');
                this.serviceSecurity.getMe()
                .pipe(
                    first(),
                    takeUntil(this._destroyed$)
                ).subscribe(
                    () => {
                        let returnUrl = localStorage.getItem('returnUrl')
                        if (returnUrl) {
                            localStorage.removeItem('returnUrl');
                        } else {
                            returnUrl = '/';
                        }
                        this.router.navigate([returnUrl]);
                    },
                    (error: HttpErrorResponse) => {
                        const message = _.get(error, 'error.message', '');
                        if ( message.substring(0, 9) === 'Username ' && message.substring(message.length - 16, message.length) === ' does not exist.') {
                            this.serviceToaster.error(this.serviceTranslate.instant('ERRORS.UNKNOWN_USER.TEXT'), this.serviceTranslate.instant('ERRORS.UNKNOWN_USER.TITLE'));
                            this.router.navigate(['/register']);
                        } else {
                            this.serviceErrors.handleError('LOGIN', error);
                        }
                    }
                );
            } else {
                console.error('token or refresh_token is missing from query string. Cannot authenticate user')
            }
        });
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }
}
