import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ServiceSaml } from '@services/saml.service';

/**
 * Redirects any '/login-saml-test' request to SAML IDP if saml is in test mode
 */
@Injectable({
    providedIn: 'root'
})
export class GuardIsSamlLoginTestModeService implements CanActivate {
    constructor(
        private serviceSaml: ServiceSaml
    ) {}


    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any>|Promise<any>|any {
        if (this.serviceSaml.isSamlTestModeEnabled()) {
            window.location.href = this.serviceSaml.getSamlLoginUrl();
            return of(false);
        }
        return of(true);
    }
}
