import { Deserializable } from './deserializable.model';
import { Kb } from './kb.model';
import { Member } from './item.model';

export class KbRole implements Deserializable {
    role: string;
    kb: Kb;
    member: Member;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}
