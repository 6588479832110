import { Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { finalize, first, takeUntil } from 'rxjs/operators';

import { ServiceSecurity, ServiceErrors } from '@services';
import { BecomeContributorResponse } from '@src/app/models';
import { Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'dialog-become-contributor',
    styleUrls: ['./dialog-become-contributor.scss'],
    templateUrl: './dialog-become-contributor.html',
})
export class DialogBecomeContributorComponent implements OnDestroy {

    public loading = false;
    public comment: string;

    private _destroyed$ = new Subject();

    constructor(
        private serviceErrors: ServiceErrors,
        private serviceSecurity: ServiceSecurity,
        public dialogRef: MatDialogRef<DialogBecomeContributorComponent>
    ) {
    }

    confirmRequestBecomeContributor() {
        const comment = this.comment;

        this.loading = true;
        this.serviceSecurity.becomeContributor({
            comment: comment
        }).pipe(
            finalize(() => {
                this.loading = false;
            }),
            first(),
            takeUntil(this._destroyed$)
        ).subscribe(
            (response: BecomeContributorResponse) => {
                const currentUser = this.serviceSecurity.user;
                currentUser.requestContributor = response.requestContributor;
                this.serviceSecurity.user = currentUser;
                this.dialogRef.close();
            },
            (error: HttpErrorResponse) => {
                this.serviceErrors.handleError('BECOME_CONTRIBUTOR', error);
            }
        );
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }
}
