import {Component, Input} from '@angular/core';
import {Kb} from '@models';
import {ServiceKb} from '@services';

@Component({
    selector: 'kb-tags',
    templateUrl: './kb-tags.component.html',
    styleUrls: ['./kb-tags.component.scss']
})
export class KbTagsComponent {

    private _kb: Kb;
    @Input()
    set kb(value: Kb) {
        this._kb = value;
    }

    get kb(): Kb {
        return this._kb;
    }

    private _editionMode: boolean;
    @Input()
    set editionMode(value: boolean) {
        this._editionMode = value;
    }

    get editionMode(): boolean {
        return this._editionMode;
    }
    
    constructor(
        private serviceKb: ServiceKb
    ) {
    }

    onSlide($event) {
        this._kb.userCanCreateTags = $event.checked;
        this.serviceKb.kb = this._kb;
    }
}
