import * as _ from 'lodash';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ItemsDictionary, Item, Role } from '@models';
import { CommonService } from '@services/common.service';
import { ServiceDatastore } from './datastore.service';
import { ServiceSecurity } from './security.service';

@Injectable({ providedIn: 'root' })
export class ServiceAliases extends CommonService {

    private allAliasesAndTitlesSubject: BehaviorSubject<Array<string>>;
    allAliasesAndTitlesObservable: Observable<Array<string>>;

    constructor(
        protected injector: Injector,
        private serviceSecurity: ServiceSecurity,
        private serviceDatastore: ServiceDatastore
    ) {
        super(injector);

        this.allAliasesAndTitlesSubject = new BehaviorSubject<Array<string>>([]);
        this.allAliasesAndTitlesObservable = this.allAliasesAndTitlesSubject.asObservable();
    }

    get allAliasesAndTitles(): Array<string> {
        return this.allAliasesAndTitlesSubject.value;
    }

    set allAliasesAndTitles(value: Array<string>) {
        this.allAliasesAndTitlesSubject.next(value);
    }

    sortArray(unsortedArray: string[], orderDirection: 'asc' | 'desc'): string[] {
        return _.orderBy(unsortedArray, function(alias: string) {
            return _.toLower(alias);
        }, [orderDirection]);
    }

    cleanAlias(alias: string = ''): string {
        return _.trim(this.serviceTextAnalyser.removeDiacritics(alias).toLowerCase());
    }

    isNewAliasNotAlreadyUsed(newAlias: string): boolean {
        const cleanNewAlias = this.cleanAlias(newAlias);
        return (this.allAliasesAndTitles.indexOf(cleanNewAlias) === -1);
    }

    isNewAliasValid(newAlias: string): boolean {
        const cleanNewAlias = this.cleanAlias(newAlias);
        return cleanNewAlias && cleanNewAlias.length >= 2;
    }

    fetchAllAliasesAndTitles(): Observable<string[]> {
        const self = this;
        return this.serviceDatastore.dataStoreObservable.pipe(
            map(
                (allItems: ItemsDictionary) => {
                    let allPotentialAliases = [];
                    _.forEach(allItems, function(item: Item) {
                        const cleanTitle = self.cleanAlias(item.title);
                        allPotentialAliases.push(cleanTitle);
                        _.forEach(item.aliases, function(alias) {
                            const cleanAlias = self.cleanAlias(alias);
                            if (_.trim(cleanAlias) !== '') {
                                allPotentialAliases.push(cleanAlias);
                            }
                        });
                    });
                    allPotentialAliases = self.sortArray(_.uniq(allPotentialAliases), 'asc');
                    this.allAliasesAndTitles = allPotentialAliases;
                    return <string[]> allPotentialAliases;
                }
            )
        );
    }

    userCanModifyAliases(item: Item): boolean {
        const isUserAdmin = this.serviceSecurity.isAdmin();
        const isItemLocked = item.islocked;
        const isGrantedByRole = this.serviceSecurity.hasMinimumRole(Role.ROLE_CONTRIBUTOR);

        return isUserAdmin || (!isItemLocked && isGrantedByRole);
    }

    addAlias(item: Item, alias: string) {
        const allAliasesAndTitles = this.allAliasesAndTitles;
        allAliasesAndTitles.push(alias);
        this.allAliasesAndTitles = allAliasesAndTitles;

        this.serviceDatastore.addAlias(item.id, alias);
    }

    removeAlias(item: Item, alias: string) {
        const allAliasesAndTitles = this.allAliasesAndTitles;
        _.remove(allAliasesAndTitles, function(o: string) {
            return o === alias;
        });
        this.allAliasesAndTitles = allAliasesAndTitles;

        this.serviceDatastore.removeAlias(item.id, alias);
    }

    addAliasFromText(item: Item, alias: string) {
        return this.http.post(this.urlApi + 'aliases/' + item.uid, {text: alias}).pipe(
            map(
                () => {
                    this.serviceDatastore.addAlias(item.id, alias);
                }
            )
        );
    }
}
