import { Component, OnInit } from '@angular/core';
import { ServiceListings, ServiceMap } from '@services';

@Component({
    selector: 'filters-keywords-view',
    templateUrl: './filters-keywords-view.component.html',
    styleUrls: ['./filters-keywords-view.component.scss']
})
export class FiltersKeywordsViewComponent implements OnInit {

    public viewTypeKeywordsEnabled: boolean = false;

    constructor(
        private serviceListings: ServiceListings,
        private serviceMap: ServiceMap
    ) {

    }

    ngOnInit() {
        
    }

    onChange() {
        this.serviceListings.mapTypeKeywordEnabled = this.viewTypeKeywordsEnabled;
        this.serviceMap.emitFilterViewTypeKeywordsChanged(this.viewTypeKeywordsEnabled);
    }

    reset() {
        this.serviceListings.mapTypeKeywordEnabled = false;
        this.viewTypeKeywordsEnabled = false;
        this.serviceMap.emitFilterViewTypeKeywordsChanged(this.viewTypeKeywordsEnabled);
    }
}
