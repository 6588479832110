import { Component, Input } from '@angular/core';

import { Kb } from '@models';
import { ServiceKb } from '@services';

@Component({
    selector: 'kb-is-public',
    templateUrl: './kb-is-public.component.html',
    styleUrls: ['./kb-is-public.component.scss']
})
export class KbIsPublicComponent{
    @Input() showText?: boolean = false;
    @Input() showIcon?: boolean = true;

    private _kb: Kb;
    @Input()
    set kb(value: Kb) {
        this._kb = value;
        this._setAttributes();
    }
    get kb(): Kb {
        return this._kb;
    }

    private _editionMode: boolean;
    @Input()
    set editionMode(value: boolean) {
        this._editionMode = value;
    }
    get editionMode(): boolean {
        return this._editionMode;
    }

    public icon: string = 'fas fa-question';
    public class: string = '';
    public tooltip: string = 'KB.IS_PUBLIC.ICON.NO_INFO';

    constructor(
        private serviceKb: ServiceKb
    ) { }

    onSlide($event) {
        this._kb.isPublic = $event.checked;
        this.serviceKb.kb = this._kb;
    }

    private _setAttributes() {
        const value = this._kb.isPublic;
        if (value === true) {
            this.class = 'public';
            this.icon = 'fas fa-unlock';
            this.tooltip = 'KB.IS_PUBLIC.ICON.PUBLIC';
        } else if (value === false) {
            this.class = 'not-public';
            this.icon = 'fas fa-lock';
            this.tooltip = 'KB.IS_PUBLIC.ICON.NOT_PUBLIC';
        }
    }
}
