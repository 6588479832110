import { Component, Input } from '@angular/core';

import { Kb } from '@models';
import { ServiceKb } from '@services';

@Component({
    selector: 'kb-needs-confidentiality-agreement',
    templateUrl: './kb-needs-confidentiality-agreement.component.html',
    styleUrls: ['./kb-needs-confidentiality-agreement.component.scss']
})
export class KbNeedsConfidentialityAgreementComponent {
    @Input() showText?: boolean = false;
    @Input() showIcon?: boolean = true;

    private _kb: Kb;
    @Input()
    set kb(value: Kb) {
        this._kb = value;
        this._setAttributes();
    }
    get kb(): Kb {
        return this._kb;
    }

    private _disabled: boolean;
    @Input()
    set disabled(value: boolean) {
        this._disabled = value;
    }
    get disabled(): boolean {
        return this._disabled;
    }

    private _editionMode: boolean;
    @Input()
    set editionMode(value: boolean) {
        this._editionMode = value;
    }
    get editionMode(): boolean {
        return this._editionMode;
    }

    public icon: string = 'fas fa-question';
    public class: string = '';
    public tooltip: string = 'KB.NEEDS_CA.ICON.NO_INFO';

    constructor(
        private serviceKb: ServiceKb
    ) { }

    onSlide($event) {
        this._kb.needsConfidentialityAgreement = $event.checked;
        this.serviceKb.kb = this._kb;
    }

    private _setAttributes() {
        const value = this._kb.needsConfidentialityAgreement;
        if (value === true) {
            this.icon = 'fas fa-check-square';
            this.class = 'needs';
            this.tooltip = 'KB.NEEDS_CA.ICON.CA_NEEDED';
        }
        else if (value === false) {
            this.icon = 'fas fa-square';
            this.class = 'no-needs';
            this.tooltip = 'KB.NEEDS_CA.ICON.NO_CA_NEEDED';
        }
    }
}
