import * as _ from 'lodash';
import { Injectable, Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError  } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { NotificationResults, Notification } from '@models';
import { CommonService } from '@services/common.service';
import { ServiceKb } from './kbs.service';

@Injectable({
    providedIn: 'root'
})
export class ServiceNotifications extends CommonService {

    private notificationsSubject: BehaviorSubject<Notification[]>;
    public notificationsObservable: Observable<Notification[]>;

    get notifications(): Notification[] {
        return this.notificationsSubject.value;
    }

    set notifications(value: Notification[]) {
        this.notificationsSubject.next(value);
    }

    constructor(
        protected injector: Injector,
        private serviceKb: ServiceKb
    ) {
        super(injector);
        this.notificationsSubject = new BehaviorSubject<Notification[]>([]);
        this.notificationsObservable = this.notificationsSubject.asObservable();
    }

    getAllNotifications(): Observable<NotificationResults> {
        return this.http.get<NotificationResults>(this.urlSso + 'api/notifications', {}).pipe(
            map(
                (response: NotificationResults) => {
                    const notifications = [];
                    _.each(response.data, function(notification) {
                        notifications.push(new Notification().deserialize(notification));
                    });
                    this.notifications = notifications;
                    return response;
                }
            )
        );
    }

    removeNotification(notification: Notification): Observable<any> {
        return this.http.delete(this.urlSso + 'api/notifications/' + notification.id, {});
    }

    removeAllNotifications() {
        return this.http.delete(this.urlSso + 'api/notifications', {});
    }
}
