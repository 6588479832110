import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from "rxjs/Subject";
import { takeUntil } from 'rxjs/operators';

import { AppSettings } from '@app/app.settings';
import { ServiceKb } from '@services/kbs.service';
import { ServiceSecurity } from '@services/security.service';
import { User } from '@models/user.model';

@Component({
    selector: 'app-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit, OnDestroy {

    @Output() preferencesRequested = new EventEmitter();

    public urlHelp: string;
    public currentUser: User;
    public isUserAnonymous: boolean;

    private _destroyed$ = new Subject();

    constructor(
        private router: Router,
        private serviceKb: ServiceKb,
        public appSettings: AppSettings,
        private serviceSecurity: ServiceSecurity
    ) {
    }

    ngOnInit() {
        this.serviceSecurity.currentUserObservable.pipe(
            takeUntil(this._destroyed$)
        ).subscribe(
            (user) => {
                this.currentUser = _.cloneDeep(user);;
                this.isUserAnonymous = (!this.currentUser || _.get(this.currentUser, 'role', 'ROLE_PUBLIC') === 'ROLE_PUBLIC');
            }
        );

        this.serviceKb.kbObservable.pipe(
            takeUntil(this._destroyed$)
        ).subscribe(
            (kb) => {
                const helpPageId = _.get(kb, 'urls.help');
                if (helpPageId) {
                    this.urlHelp = '/items/page/' + helpPageId;
                }
            }
        );
    }

    preferencesClicked() {
        this.preferencesRequested.emit(2);
    }

    logout() {
        this.preferencesRequested.emit(-1);

        const isKbPublic = this.serviceKb.kb.isPublic;
        this.serviceSecurity.logout(isKbPublic);
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }
}
