import { Component, Input, OnChanges } from '@angular/core';

@Component({
    selector: 'items-counter',
    templateUrl: './items-counter.component.html',
    styleUrls: ['./items-counter.component.scss']
})
export class ItemsCounterComponent {

    @Input() allItems: any;
    @Input() visibleItems: any;

    constructor() {}
}
