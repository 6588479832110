import { Deserializable } from './deserializable.model';

/**
 * @class IaResponse
 * @export
 * 
 * @constructor
 * @param {string} response The response
 */
export class IaResponse implements Deserializable {
    private total?: number;
    private duration?: number;
    private docs?: Array<never>;
    private data?: Array<never>;
    private multigraph?: boolean;
    private elements?: Array<never>;

    constructor(
        private response: string
    ) {
        const json = JSON.parse(response);
        if (json.total) this.total = json.total;
        if (json.duration) this.duration = json.duration;
        if (json.docs) this.docs = json.docs;
        if (json.data) this.data = json.data;
        if (json.multigraph) this.multigraph = json.multigraph;
        if (json.elements) this.elements = json.elements;
    }

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    /**
     * Get the total of docs in this response
     * @returns {number | undefined} The total
     */
    getTotal(): number | undefined {
        return this.total;
    }

    /**
     * Get the duration of the response
     * @returns {number | undefined} The duration
     */
    getDuration(): number | undefined {
        return this.duration;
    }

    /**
     * Get the docs in this response
     * @returns {Array<never | undefined>} The docs
     */
    getDocs(): Array<never> | undefined {
        return this.docs;
    }

    /**
     * Get the data in this response
     * @returns {Array<never | undefined>} The data
     */
    getData(): Array<never> | undefined {
        return this.data;
    }

    /**
     * Get the multigraph in this response
     * @returns {boolean | undefined} The multigraph
     */
    getMultigraph(): boolean | undefined {
        return this.multigraph;
    }

    /**
     * Get the elements in this response
     * @returns {Array<never | undefined>} The elements
     */
    getElements(): Array<never> | undefined {
        return this.elements;
    }

    getResponse(): string {
        return this.response;
    }

    /**
     * Get the JSON
     * @returns {JSON} The JSON
     */
    toJSON(): JSON {
        return JSON.parse(this.response);
    }
}