import * as _ from 'lodash';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { ServicePreferences } from '@src/app/services/preferences.service';

@Pipe({
    name: 'i2KnDate'
})
export class I2KnDate implements PipeTransform {

    constructor(
        private servicePreferences: ServicePreferences
    ) {
    }

    transform(value: any): any {
        const language = this.servicePreferences.getPreference('language');
        const dateFormatMoment = this.servicePreferences.getPreference('dateFormatMoment');
        const timezone = this.servicePreferences.getPreference('timezone');

        return _.upperFirst(moment(moment.tz(value, timezone)).locale(language).format(dateFormatMoment));
    }
}
