import {TranslateService} from '@ngx-translate/core';
import {MatPaginatorIntl} from '@angular/material';
import {Injectable} from '@angular/core';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
    constructor(private translateService: TranslateService) {
        super();

        this.translateService.onLangChange.subscribe((e: Event) => {
            this.getAndInitTranslations();
        });

        this.getAndInitTranslations();
    }

    getAndInitTranslations() {
        this.itemsPerPageLabel = this.translateService.instant('LISTING.ITEMS_PER_PAGE');
        this.previousPageLabel = this.translateService.instant('LISTING.PREVIOUS');
        this.nextPageLabel = this.translateService.instant('LISTING.NEXT');
        this.firstPageLabel = this.translateService.instant('LISTING.FIRST');
        this.lastPageLabel = this.translateService.instant('LISTING.LAST');
        this.changes.next();
    }

    getRangeLabel = (page: number, pageSize: number, length: number) =>  {
        if (length === 0 || pageSize === 0) {
            return `0 / ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} / ${length}`;
    }
}
