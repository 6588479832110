import * as _ from 'lodash';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource } from '@angular/material';
import { ServiceProsim } from '@src/app/services';
import { ProsimDynamic, ResultTable } from '@src/app/models';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'dialog-prosim-listing',
    styleUrls: ['./dialog-prosim-listing.scss'],
    templateUrl: './dialog-prosim-listing.html',
})
export class DialogProsimListingComponent implements OnInit {

    public loading = false;
    public showNoItem = false;
    public error: any;

    public displayedColumns: string[] = ['key', 'value'];
    public dataSource: any;
    public keyLabel: string;
    public valueLabel: string;
    public unit: string;
    public temperatureUnit: string;

    constructor(
        private serviceProsim: ServiceProsim,
        private serviceTranslate: TranslateService,
        @Inject(MAT_DIALOG_DATA) public sentData: ProsimDynamic,
        public dialogRef: MatDialogRef<DialogProsimListingComponent>
    ) {
    }

    ngOnInit() {
        const temperatureUnit = this.sentData.temperatureUnit;
        this.temperatureUnit = temperatureUnit;

        const unit = this.sentData.unit;
        this.unit = unit;

        const upper = this.sentData.upper;
        const lower = this.sentData.lower;

        if (upper !== lower) {
            this.keyLabel =
                this.serviceTranslate.instant('ITEM.PROSIM.MODAL.TEMPERATURE_BETWEEN') + ' ' +
                lower + ' ' + temperatureUnit +
                this.serviceTranslate.instant('ITEM.PROSIM.MODAL.AND') + ' ' +
                upper + ' ' + temperatureUnit
            ;
        } else {
            this.keyLabel = this.serviceTranslate.instant('ITEM.PROSIM.MODAL.TEMPERATURE') + '(' + temperatureUnit + ')';
        }

        const dynamicProperties = this.serviceProsim.getDynamicProperties();
        const propertyLabel = _.get(_.find(dynamicProperties, {
            key: this.sentData.propertyKey
        }), 'label');
        this.valueLabel = propertyLabel + ' ' + this.serviceTranslate.instant('ITEM.PROSIM.MODAL.IN') + ' ' + unit;

        const self = this;

        this.loading = true;
        delete this.error;
        this.serviceProsim.getDynamicPropertyValues(<ProsimDynamic> this.sentData).toPromise().then(
            function (resultListing: any) {
                self.loading = false;
                self.dataSource = new MatTableDataSource<ResultTable>(resultListing.value);
                self.showNoItem = (_.size(resultListing.value) === 0);
            },
            function () {
                self.loading = false;
                self.error = 'PROSIM.ERROR';
            }
        );
    }

    onNoClick() {
        this.dialogRef.close();
    }
}
