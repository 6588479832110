import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { ServiceSecurity, ServiceKb } from '@services';

@Component({
    selector: 'preferences',
    templateUrl: './preferences.component.html',
    styleUrls: ['./preferences.component.scss']
})
export class PreferencesComponent implements OnInit, OnDestroy {

    public isUserAnonymous: boolean;
    public isUserAdmin: boolean;

    private _destroyed$ = new Subject();

    constructor(
        private serviceKb: ServiceKb,
        private serviceSecurity: ServiceSecurity
    ) {
    }

    ngOnInit() {
        this.serviceSecurity.currentUserObservable.pipe(
            takeUntil(this._destroyed$)
        ).subscribe(
            () => {
                this.isUserAnonymous = this.serviceSecurity.isCurrentUserAnonymous();
                this.isUserAdmin = this.serviceSecurity.isAdmin();
            }
        );
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }
}
