import { Deserializable } from "./deserializable.model";
import * as _ from 'lodash';

export class DynamicLinkOutDetail implements Deserializable {
    alias: string;
    class: "link-internal" | "link-external";
    group: 'Page' | 'Member' | 'Worklab';
    id: string;
    kbSlug: string;
    kbTitle: string;
    societySlug: string;
    societyTitle: string;
    title: string;
    url: string;
    mid?: string;
    cover?:string;
    imageUrl?: string;
    isExternal: boolean;

    

    deserialize(input: any, currentUser: any) {
        Object.assign(this, input);
        this.class === 'link-external' ? this.isExternal = true : this.isExternal = false;

        //TODO: update API instead of the following line (remove /# from url)
        this.url = this.url.replace(/#\//gi, 'items/');
        if (!this.isExternal) {
            //routerlink relative
            this.url = '/items/' + _.toLower(this.group) + this.url.substring(this.url.lastIndexOf('/', this.url.length-2));
        }

        const imgFolder = (this.group === 'Member') ? 'member' : 'thumbnails';
        if (this.cover) {
            this.imageUrl = '/images/' + _.toLower(this.group) + '/' + this.cover + '.png';
        } else if (this.mid) {
            this.imageUrl = '/images/' + imgFolder + '/' + this.mid + '.png';
        } else {
            this.imageUrl = '/images/thumbnails/no-thumbnail.png';
        }
        
        return this;
    }
}


export class DynamicLinksOut implements Deserializable {
    word: string;
    links: DynamicLinkOutDetail[];
    isExternal: boolean = true; // isExternal will be false if any DynamicLinkOutDetail is not external
    isMatching: boolean = false;// Specify if the dynamic link is used (short links in longer links might not be used so we should not display it in links list and map)

    deserialize(input: any, currentUser: any) {
        this.word = input.word;
        this.links = [];
        for(let kbSlug in input.kb) {
            for(let k in input.kb[kbSlug]) {
                const l = new DynamicLinkOutDetail().deserialize(input.kb[kbSlug][k], currentUser);
                this.links.push(l);
                if(this.isExternal && !l.isExternal) {
                    this.isExternal = false;
                }
            }
        }
        return this;
    }

    get isMultiple(): boolean {
        return this.links.length > 1;
    }
}
