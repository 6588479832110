import * as _ from 'lodash';
import { Component, Input, OnInit } from '@angular/core';

import { Page, Member, Worklab } from '@models';
import { ServiceKb, ServiceUrls } from '@services';

@Component({
    selector: 'item-title-inline',
    templateUrl: './item-title-inline.component.html',
    styleUrls: ['./item-title-inline.component.scss']
})
export class ItemTitleInlineComponent implements OnInit {
    @Input() item: Page | Member | Worklab;

    public isExternal: boolean;
    public url: string;

    constructor(
        private serviceKb: ServiceKb,
        private serviceUrls: ServiceUrls
    ) {
    }

    ngOnInit() {
        this.url = _.clone(this.item.url);
        if(
            this.item.kb !== this.serviceKb.kb.slug ||
            this.item.society !== this.serviceKb.kb.society.slug
        ) {
            this.isExternal = true;
            this.url = location.protocol + '//' + this.item.kb + '.' + this.item.society + '.' + this.serviceUrls.sld + this.url;
        } else {
            this.isExternal = false;
        }
    }
}
