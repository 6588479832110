import * as _ from 'lodash';
import { Component, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { AppSettings, Settings } from '@app/app.settings';
import { ServiceSecurity } from '@services/security.service';
import { ServiceKb } from '@services/kbs.service';
import { ServiceItems } from '@services/items.service';

import { first, finalize, takeUntil } from 'rxjs/operators';

import { ServiceTabs, ListingUsersService, ServiceErrors } from '@services';

import { Kb, Item, Member, FabButtonsConfiguration } from '@models';
import { ServiceListings } from '@src/app/services';
import { Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-fab-speed-dial',
    templateUrl: './fab-speed-dial.component.html',
    styleUrls: ['./fab-speed-dial.component.scss']
})
export class FabSpeedDialComponent implements OnDestroy {
    @ViewChild('buttonExportItems', {static: false}) buttonExportItems: any;
    @ViewChild('buttonImportUsers', {static: false}) buttonImportUsers: any;

    @ViewChild('buttonEditItem', {static: false}) buttonEditItem: any;
    @ViewChild('buttonDuplicateItem', {static: false}) buttonDuplicateItem: any;
    @ViewChild('buttonRemoveItem', {static: false}) buttonRemoveItem: any;
    @ViewChild('buttonGoToForum', {static: false}) buttonGoToForum: any;
    @ViewChild('buttonWorklabToPage', {static: false}) buttonWorklabToPage: any;
    @ViewChild('buttonCreateItem', {static: false}) buttonCreateItem: any;

    @Output() actionInProgress = new EventEmitter();

    public settings: Settings;
    public visibleButtons: any;
    public editionMode = false;
    public displayGranted = false;
    public isCurrentUser = false;
    public isHomePage = false;
    public isLocked = false;
    public kb: Kb;
    public item: Item;
    public items: Array<Item | Member>;
    public createItemTooltip: string;
    public editItemTooltip: string;
    public removeItemTooltip: string;
    public duplicateItemTooltip: string;
    public group: string;

    private _destroyed$ = new Subject();

    constructor(
        private appSettings: AppSettings,
        private serviceKb: ServiceKb,
        public serviceListings: ServiceListings,
        private serviceItems: ServiceItems,
        private serviceTabs: ServiceTabs,
        private serviceErrors: ServiceErrors,
        private listingUsersService: ListingUsersService,
        private serviceSecurity: ServiceSecurity
    ) {
        const isUserAdmin = this.serviceSecurity.isAdmin();

        this.settings = this.appSettings.settings;

        this.serviceItems.itemObservable.pipe(
            takeUntil(this._destroyed$)
        ).subscribe(
            (item) => {
                this.item = item;

                this.editionMode = _.get(this, 'item.editionMode');

                this.duplicateItemTooltip = 'FAB_BUTTONS.DUPLICATE_' + _.toUpper(_.get(this, 'item.group'));

                this.isHomePage = (_.get(this, 'item.ishomepage') === true);
                this.isLocked = (!isUserAdmin && _.get(this, 'item.islocked') === true);
                this.isCurrentUser = this.serviceSecurity.isCurrentUser(item);
                this.editItemTooltip = 'FAB_BUTTONS.EDIT_' + _.toUpper(_.get(this, 'item.group'));

                if (this.isCurrentUser) {
                    this.removeItemTooltip = 'FAB_BUTTONS.REMOVE_MYSELF';
                    this.editItemTooltip = 'FAB_BUTTONS.EDIT_' + _.toUpper(_.get(this, 'item.group'));
                } else if (this.isHomePage) {
                    this.removeItemTooltip = 'FAB_BUTTONS.REMOVE_HOMEPAGE';
                    this.editItemTooltip = 'FAB_BUTTONS.EDIT_' + _.toUpper(_.get(this, 'item.group'));
                } else if (this.isLocked) {
                    this.removeItemTooltip = 'FAB_BUTTONS.REMOVE_LOCKED_' + _.toUpper(_.get(this, 'item.group'));
                    this.editItemTooltip = 'FAB_BUTTONS.EDIT_LOCKED_' + _.toUpper(_.get(this, 'item.group'));
                } else {
                    this.removeItemTooltip = 'FAB_BUTTONS.REMOVE_' + _.toUpper(_.get(this, 'item.group'));
                    this.editItemTooltip = 'FAB_BUTTONS.EDIT_' + _.toUpper(_.get(this, 'item.group'));
                }
            }
        );

        this.serviceKb.kbObservable.pipe(
            takeUntil(this._destroyed$)
        ).subscribe(
            (kb: Kb) => {
                this.editionMode = kb.editionMode;
            }
        );

        this.serviceTabs.tabChangedObservable.pipe(
            takeUntil(this._destroyed$)
        ).subscribe(
            (fabButtons: FabButtonsConfiguration) => {
                this.group = fabButtons.group;
                this.createItemTooltip = 'FAB_BUTTONS.CREATE_' + _.toUpper(this.group);
                this.visibleButtons = fabButtons || {};
                this.displayGranted = _.size(_.filter(fabButtons, function (o) {
                    return o === true;
                })) > 0;
            });

        this.listingUsersService.listChanged$.pipe(
            takeUntil(this._destroyed$)
        ).subscribe(
            (users: Member[]) => {
                this.items = users;
            }
        );

        this.serviceListings.datasourceObservable.pipe(
            takeUntil(this._destroyed$)
        ).subscribe(
            (datasource) => {
                this.items = _.get(datasource, 'filteredData', []);
            }
        );
    }

    public doAction(action: string) {
        const existingKb = this.serviceKb.kb;
        switch (action) {


            case 'downloadKb':
                const lang = this.appSettings.settings.language;
                const format = 'zip';
                const kbSlug = existingKb.slug;
                const url = existingKb.apiUrl.replace('/' + kbSlug + '/', '/');

                this.settings.savingInsideSpinner = true;
                this.serviceKb.downloadKb(format, lang).pipe(
                    finalize(() => {
                        this.settings.savingInsideSpinner = false;
                    }),
                    first(),
                    takeUntil(this._destroyed$)
                ).subscribe(
                    (response: any) => {
                        const link = document.createElement('a');
                        link.href = url + response;
                        const clickEvent = new MouseEvent('click');
                        link.dispatchEvent(clickEvent);
                    },
                    (error: HttpErrorResponse) => {
                        this.serviceErrors.handleError('DOWNLOAD_KB', error);
                    });
                break;
            case 'editKb':
                existingKb.editionMode = true;
                this.serviceKb.kb = existingKb;
                this.editionMode = true;
                break;
            case 'exportItems':
                this.editionMode = false;
                this.buttonExportItems.openDialog();
                break;
            case 'importUsers':
                this.editionMode = false;
                this.buttonImportUsers.openDialog();
                break;

            case 'editItem':
                this.editionMode = true;
                this.buttonEditItem.editItem();
                break;
            case 'duplicateItem':
                this.editionMode = false;
                this.buttonDuplicateItem.openModalDuplicateOptions();
                break;
            case 'goToForum':
                this.editionMode = false;
                this.buttonGoToForum.goToForum();
                break;
            case 'worklabToPage':
                this.editionMode = false;
                this.buttonWorklabToPage.openDialog();
                break;
            case 'removeItem':
                this.editionMode = false;
                this.buttonRemoveItem.openDialog();
                break;
            case 'createItem':
                this.editionMode = false;
                this.buttonCreateItem.creatItem();
                break;
        }
    }

    reloadUsers() {
        this.listingUsersService.reloadRequested(true);
    }

    doActionInProgress($event) {
        this.actionInProgress.emit($event);
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }
}
