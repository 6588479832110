export interface PreferencesDashboardLimited {
    limit?: number;
}

export interface PreferencesDashboard extends PreferencesDashboardLimited {
    period: string;
    start: string;
    end: string;
    cumulatedValues?: boolean;
}

export class PreferencesDashboards {
    creationsPerDate: PreferencesDashboard;
    topicsPerDate: PreferencesDashboard;
    editionsPerDate: PreferencesDashboard;
    mostActiveMembers: PreferencesDashboard;
    latestCreatedPages: PreferencesDashboardLimited;
    mostFollowedPages: PreferencesDashboardLimited;

    constructor() {
        try {
            this.creationsPerDate = DASHBOARDS_CREATIONS_PER_DATE;
        } catch (error) {
            this.creationsPerDate = {
                period: 'last12Months',
                cumulatedValues: false,
                start: null,
                end: null
            };
        }

        try {
            this.topicsPerDate = DASHBOARDS_TOPICS_PER_DATE;
        } catch (error) {
            this.topicsPerDate = {
                period: 'last12Months',
                cumulatedValues: false,
                start: null,
                end: null
            };
        }

        try {
            this.editionsPerDate = DASHBOARDS_EDITIONS_PER_DATE;
        } catch (error) {
            this.editionsPerDate = {
                period: 'last12Months',
                cumulatedValues: false,
                start: null,
                end: null
            };
        }

        try {
            this.mostActiveMembers = DASHBOARDS_MOST_ACTIVE_MEMBERS;
        } catch (error) {
            this.mostActiveMembers = {
                period: 'last3Months',
                start: null,
                end: null,
                limit: 10
            };
        }

        try {
            this.latestCreatedPages = DASHBOARDS_LATEST_CREATED_PAGES;
        } catch (error) {
            this.latestCreatedPages = {
                limit: 10
            };
        }

        try {
            this.mostFollowedPages = DASHBOARDS_MOST_FOLLOWED_PAGES;
        } catch (error) {
            this.mostFollowedPages = {
                limit: 10
            };
        }
    }
}
