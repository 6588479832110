import * as _ from 'lodash';
import { Component, OnInit, Input } from '@angular/core';
import { Society } from '@models';
import { ServiceUrls } from '@src/app/services';

@Component({
    selector: 'society-logo',
    templateUrl: './society-logo.component.html',
    styleUrls: ['./society-logo.component.scss']
})
export class SocietyLogoComponent implements OnInit {
    @Input() society: Society;

    public imageSrc: string;
    public imageAlt: string;
    public defaultPicture: string;

    constructor(
        private serviceUrls: ServiceUrls
    ) {
        const urlSSo = this.serviceUrls.sso;
        this.defaultPicture = urlSSo + 'images/i2kn.png';
    }

    ngOnInit() {
        const urlSSo = this.serviceUrls.sso;
        const societySlug = _.get(this, 'society.slug', this.serviceUrls.societySlug);

        this.imageSrc = urlSSo + 'images/' + societySlug + '/logo.png';
        this.imageAlt = _.get(this, 'society.title', 'i2kn');
    }
}
