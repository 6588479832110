import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
    selector: 'dialog-confirmation-cancel',
    styleUrls: ['./dialog-confirmation-cancel.scss'],
    templateUrl: './dialog-confirmation-cancel.html',
})
export class DialogConfirmationCancelComponent {

    constructor(
        public dialogRef: MatDialogRef<DialogConfirmationCancelComponent>
    ) { }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
