import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ItemSearchPipe', pure: false })
export class ItemSearchPipe implements PipeTransform {
  transform(value, args?): Array<any> {
    let searchText = new RegExp(args, 'ig');
    if (value) {
      return value.filter(item => {
        if (item.title) {
          return item.title.search(searchText) !== -1;
        }
        else{
          return false;
        }
      });
    }
  }
}
