import * as _ from 'lodash';
import { Component, OnInit, Injector } from '@angular/core';
import { ServiceListings } from '@services';
import { FilterByComponent } from '../abstract-filter-by.component';
// import { ArrayFilter } from '@models/filters/array-filter.model'

@Component({
    selector: 'filters-by-group',
    templateUrl: './filters-by-group.component.html',
    styleUrls: ['./filters-by-group.component.scss']
})
export class FiltersByGroupComponent extends FilterByComponent {

    public displayPage = false;
    public displayMember = false;
    public displayWorklab = false;
    public isDisabled = false;

    constructor(
        protected injector: Injector
    ) {
        super(injector);
        this.filterKey = 'groups';
    }

    initValue() {
        const userFilters = this.serviceListings.filters;
        const initialValue = _.get(userFilters, this.filterKey);

        this.isDisabled = (this.serviceListings.type !== 'all' && this.serviceListings.type !== 'detail' && this.serviceListings.type !== 'search');

        const group = this.serviceListings.group;
        switch (group) {
            case 'Page':
                this.displayPage = true;
                break;
            case 'Member':
                this.displayMember = true;
                break;
            case 'Worklab':
                this.displayWorklab = true;
                break;
            default:
                // if (this.serviceListings.type !== 'all') {
                    this.displayPage = (_.indexOf(initialValue, 'Page') !== -1);
                    this.displayMember = (_.indexOf(initialValue, 'Member') !== -1);
                    this.displayWorklab = (_.indexOf(initialValue, 'Worklab') !== -1);
                // } else {
                    // this.displayPage = true;
                    // this.displayMember = true;
                    // this.displayWorklab = true;
                // }
                break;
        }

        if (group !== 'Page' && group !== 'Member' && group !== 'Worklab' &&
            (!this.displayPage || !this.displayMember || !this.displayWorklab)
        ) {
            this.isFiltersDirty.emit({ filterKey: this.filterKey, isDirty: true });
        } else {
            this.isFiltersDirty.emit({ filterKey: this.filterKey, isDirty: false });
        }
    }

    onChange(apply?: boolean) {
        apply !== false ? apply = true : apply = false;

        let filters = this.serviceListings.filters;
        const groups = [];
        if (this.displayPage) {
            groups.push('Page');
        }
        if (this.displayMember) {
            groups.push('Member');
        }
        if (this.displayWorklab) {
            groups.push('Worklab');
        }
        filters = _.extend(filters, {
            groups: groups
        });

        const group = this.serviceListings.group;
        if (group !== 'Page' && group !== 'Member' && group !== 'Worklab' &&
            (!this.displayPage || !this.displayMember || !this.displayWorklab)
        ) {
            this.isFiltersDirty.emit({ filterKey: this.filterKey, isDirty: true });
        } else {
            this.isFiltersDirty.emit({ filterKey: this.filterKey, isDirty: false });
        }

        this.serviceListings.filters = filters;
        if (apply) {
            this.serviceListings.dataStoreChanged();
        }
        this.saveFilter();
    }

    reset() {
        const filters = this.serviceListings.filters;

        const group = this.serviceListings.group;
        if (group === 'Page' || group === 'Member' || group === 'Worklab') {
            filters[this.filterKey] = [group];
        } else {
            filters[this.filterKey] = ['Page', 'Member', 'Worklab'];
        }
        this.serviceListings.filters = filters;
        this.initValue();
    }
}
