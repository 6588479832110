import * as _ from 'lodash';
import { Injectable, Injector } from '@angular/core';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Item, ResultTable } from '@models';
import { CommonService } from '@services/common.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { ServiceSecurity } from './security.service';

export class ExpertsPerPageData {
    data: number[];
    labels: string[];
}
export class PieData {
    name: string;
    value: number;
}
export class ActionsPerDateData {
    data: any[];
    valueMax: number;
    map?: any[]
}

@Injectable({
    providedIn: 'root'
})
export class ServiceDashboards extends CommonService {

    constructor(
        protected injector: Injector,
        private router: Router,
        private serviceSecurity: ServiceSecurity
    ) {
        super(injector);
    }

    setPeriodLabel(scale: 'MONTH' | 'DAY', startDateString?: string, endDateString?: string) {
        let periodLabel: string;
        if (startDateString && endDateString && startDateString !== endDateString) {
            const fromDate = startDateString;
            const toDate = endDateString;
            periodLabel = this.serviceTranslate.instant('DASHBOARD.TITLE.' + scale + '.FROM_TO', { from: fromDate, to: toDate });
        } else if (startDateString && endDateString && startDateString === endDateString) {
            const onDate = startDateString;
            periodLabel = this.serviceTranslate.instant('DASHBOARD.TITLE.' + scale + '.ON', { on: onDate });
        } else if (startDateString) {
            const sinceDate = startDateString;
            periodLabel = this.serviceTranslate.instant('DASHBOARD.TITLE.' + scale + '.SINCE', { since: sinceDate });
        } else if (endDateString) {
            const untilDate = endDateString;
            periodLabel = this.serviceTranslate.instant('DASHBOARD.TITLE.' + scale + '.UNTIL', { until: untilDate });
        } else {
            periodLabel = this.serviceTranslate.instant('DASHBOARD.TITLE.' + scale + '.WHOLE_DATABASE');
        }
        return periodLabel;
    }

    getExpertsPerPageData(): Observable<PieData[]> {
        const serviceTranslate = this.serviceTranslate;
        return this.http.post<ExpertsPerPageData>(this.urlApi + 'stats/data', {
            'id': 'expertsPerPage',
            'type': 'pie'
        }).pipe(
            map(
                (results: ExpertsPerPageData) => {
                    const labels = results.labels;
                    const values = results.data;

                    const result: PieData[] = [];
                    _.forEach(labels, function (value, key) {
                        result.push({
                            name: serviceTranslate.instant('DASHBOARD.EXPERTSPERPAGE.' + value),
                            value: values[key]
                        });
                    });
                    return result;
                }
            )
        );
    }

    getActionsPerDate(
        actionType: 'editionsPerDate' | 'creationsPerDate' | 'topicsPerDate',
        cumulatedValues = false,
        startDateString?,
        endDateString?
    ): Observable<ActionsPerDateData> {
        return this.http.post<ActionsPerDateData>(this.urlApi + 'stats/data', {
            'id': actionType,
            'type': 'pie'
        }).pipe(
            map(
                (results: any) => {
                    let valueMax = 0;
                    const resultData: any[] = [];

                    const dates = results.labels;
                    const seriesNames = results.series;
                    const data = results.data;
                    const map = _.get(results, 'map');

                    let valuePage = 0;
                    let valueMember = 0;
                    let valueWorklab = 0;

                    let indexSeriesPage = -1;
                    let indexSeriesMember = -1;
                    let indexSeriesWorklab = -1;


                    _.forEach(seriesNames, function (seriesName, indexSeriesName: number) {
                        if (seriesName === 'Page') {
                            indexSeriesPage = indexSeriesName;
                        } else if (seriesName === 'Member' && actionType !== 'creationsPerDate' && actionType !== 'topicsPerDate') {
                            indexSeriesMember = indexSeriesName;
                        } else if (seriesName === 'Worklab' && actionType !== 'creationsPerDate' && actionType !== 'topicsPerDate') {
                            indexSeriesWorklab = indexSeriesName;
                        }
                    });

                    startDateString = startDateString || _.first(dates) + '-01'; // First date moment().subtract(1, 'years').format('YYYY-MM-DD'); // 1 year ago
                    let startDate = moment(startDateString, 'YYYY-MM-DD');

                    endDateString = endDateString || moment().format('YYYY-MM-DD'); // Today
                    const endDate = moment(endDateString, 'YYYY-MM-DD').endOf('month');

                    const nbDays = endDate.diff(startDate, 'days');
                    const scale = 'month'; // (nbDays < 31) ? 'week' : 'month'; NOt used since data are only by month
                    const dateFormat = 'YYYY-MM'; // (scale === 'week') ? 'YYYY-MM-DD' : 'YYYY-MM';

                    _.forEach(dates, function (dateLabel, indexDateLabel: number) {
                        const testedDate = moment(dateLabel, dateFormat);
                        if (startDate.isAfter(testedDate)) {
                            if (cumulatedValues === true) {
                                if (indexSeriesPage !== -1) {
                                    valuePage += data[indexSeriesPage][indexDateLabel];
                                }
                                if (indexSeriesMember !== -1) {
                                    valueMember += data[indexSeriesMember][indexDateLabel];
                                }
                                if (indexSeriesWorklab !== -1) {
                                    valueWorklab += data[indexSeriesWorklab][indexDateLabel];
                                }
                            }
                        }
                    });

                    while (startDate.isBefore(endDate)) {
                        const date = startDate.format(dateFormat);
                        startDate = startDate.add(1, scale);

                        const indexDate = dates.indexOf(date);
                        if (indexDate !== -1) {
                            if (cumulatedValues === true) {
                                if (indexSeriesPage !== -1) {
                                    valuePage = valuePage + data[indexSeriesPage][indexDate];
                                }
                                if (indexSeriesMember !== -1) {
                                    valueMember = valueMember + data[indexSeriesMember][indexDate];
                                }
                                if (indexSeriesWorklab !== -1) {
                                    valueWorklab = valueWorklab + data[indexSeriesWorklab][indexDate];
                                }
                                const sumValues = valuePage + valueMember + valueWorklab;
                                valueMax = (valueMax < sumValues) ? sumValues : valueMax;
                            } else {
                                if (indexSeriesPage !== -1) {
                                    valuePage = data[indexSeriesPage][indexDate];
                                }
                                if (indexSeriesMember !== -1) {
                                    valueMember = data[indexSeriesMember][indexDate];
                                }
                                if (indexSeriesWorklab !== -1) {
                                    valueWorklab = data[indexSeriesWorklab][indexDate];
                                }
                                const sumValues = valuePage + valueMember + valueWorklab;
                                valueMax = (valueMax < sumValues) ? sumValues : valueMax;
                            }
                        } else {
                            if (cumulatedValues !== true) {
                                if (indexSeriesPage !== -1) {
                                    valuePage = 0;
                                }
                                if (indexSeriesMember !== -1) {
                                    valueMember = 0;
                                }
                                if (indexSeriesWorklab !== -1) {
                                    valueWorklab = 0;
                                }
                            }
                        }

                        const series = [];
                        const serviceTranslate = this.serviceTranslate;
                        if (indexSeriesPage !== -1) {
                            series.push({
                                name: serviceTranslate.instant('DASHBOARD.EDITIONSPERDATE.Page'),
                                value: valuePage
                            });
                        }
                        if (indexSeriesMember !== -1) {
                            series.push({
                                name: serviceTranslate.instant('DASHBOARD.EDITIONSPERDATE.Member'),
                                value: valueMember
                            });
                        }
                        if (indexSeriesWorklab !== -1) {
                            series.push({
                                name: serviceTranslate.instant('DASHBOARD.EDITIONSPERDATE.Worklab'),
                                value: valueWorklab
                            });
                        }

                        resultData.push({
                            name: date,
                            series: series
                        });
                    }
                    const strValueMax = valueMax + '';
                    return {
                        data: resultData,
                        valueMax: parseInt(_.padEnd((parseInt(strValueMax.substring(0, 1), 10) + 1) + '', strValueMax.length, '0'), 10),
                        map: map
                    } as ActionsPerDateData; // Fix top to the closest number with 0 to have space on the top and 452 -> 500
                }
            )
        );
    }


    getTopicsPerDate(
        actionType: 'editionsPerDate' | 'creationsPerDate' | 'topicsPerDate',
        cumulatedValues = false,
        startDateString?,
        endDateString?
    ): Observable<ActionsPerDateData> {
        const serviceTranslate = this.serviceTranslate;
        return this.http.post<ActionsPerDateData>(this.urlApi + 'stats/data', {
            'id': actionType,
            'type': 'pie'
        }).pipe(
            map(
                (results: any) => {
                    let valueMax = 0;
                    const resultData: any[] = [];

                    const dates = results.labels;
                    const map = _.get(results, 'map');

                    let values = 0;

                    startDateString = startDateString || _.first(dates) + '-01'; // First date moment().subtract(1, 'years').format('YYYY-MM-DD'); // 1 year ago
                    let startDate = moment(startDateString, 'YYYY-MM-DD');

                    endDateString = endDateString || moment().format('YYYY-MM-DD'); // Today
                    const endDate = moment(endDateString, 'YYYY-MM-DD').endOf('month');

                    // const nbDays = endDate.diff(startDate, 'days');
                    const scale = 'month'; // (nbDays < 31) ? 'week' : 'month'; NOt used since data are only by month
                    const dateFormat = 'YYYY-MM'; // (scale === 'week') ? 'YYYY-MM-DD' : 'YYYY-MM';

                    while (startDate.isBefore(endDate)) {
                        const date = startDate.format(dateFormat);
                        startDate = startDate.add(1, scale);
                        if (date in map) {
                            if (cumulatedValues === true) {
                                values = values + _.get(map[date], 'length', 0);
                            } else {
                                values = _.get(map[date], 'length', 0);
                            }
                            valueMax = (valueMax < values) ? values : valueMax;
                        } else if (cumulatedValues !== true) {
                            values = 0;
                        }
                        const series = [{
                            name: serviceTranslate.instant('DASHBOARD.EDITIONSPERDATE.Topic'),
                            value: values
                        }];
                        resultData.push({
                            name: date,
                            series: series
                        });
                    }
                    const strValueMax = valueMax + '';
                    return {
                        data: resultData,
                        valueMax: parseInt(_.padEnd((parseInt(strValueMax.substring(0, 1), 10) + 1) + '', strValueMax.length, '0'), 10),
                        map: map
                    } as ActionsPerDateData; // Fix top to the closest number with 0 to have space on the top and 452 -> 500
                }
            )
        );
    }

    getLatestCreatedPagesData(limit: number = 5): Observable<ResultTable[]> {
        const currentUser = this.serviceSecurity.user;
        return this.http.post(this.urlApi + 'stats/data', {
            'group': 'Page',
            'orderBy': 'creationDate',
            'orderByDirection': 'desc',
            'limit': limit,
            'type': 'table'
        }).pipe(
            map(
                (jsonResult: Object[]) => jsonResult.map(
                    (jsonData: Object) => {
                        return {
                            item: new Item(jsonData, currentUser),
                            date: jsonData['date']
                        } as ResultTable;
                    }
                )
            )
        );
    }

    getMostFollowedPagesData(limit: number = 5): Observable<ResultTable[]> {
        const currentUser = this.serviceSecurity.user;
        return this.http.post(this.urlApi + 'stats/data', {
            'group': 'Page',
            'orderBy': 'nbFollowers',
            'orderByDirection': 'desc',
            'limit': limit,
            'type': 'table'
        }).pipe(
            map(
                (jsonResult: Object[]) => jsonResult.map(
                    (jsonData: Object) => {
                        return {
                            item: new Item(jsonData, currentUser),
                            number: jsonData['nb']
                        } as ResultTable;
                    }
                )
            )
        );
    }

    getMostActiveMembersData(limit: number = 5, startDateString?: string, endDateString?: string): Observable<ResultTable[]> {
        const currentUser = this.serviceSecurity.user;
        const router = this.router;
        return this.http.post(this.urlApi + 'stats/data', {
            'group': 'Member',
            'orderBy': 'nbActivity',
            'orderByDirection': 'desc',
            'limit': limit,
            'type': 'table',
            'startDate': startDateString,
            'endDate': endDateString
        }).pipe(
            map(
                (jsonArray: Object[]) => jsonArray.map(
                    (jsonData: Object) => {
                        const item = new Item(jsonData, currentUser);
                        const creationsQueryParams = {
                            'creator': item.uid,
                            'creation-start': startDateString,
                            'creation-end': endDateString
                        };
                        const modificationsQueryParams = {
                            'modifier': item.uid,
                            'last-modification-start': startDateString,
                            'last-modification-end': endDateString
                        };
                        return {
                            item: item,
                            expertises: jsonData['expertises'],
                            creations: {
                                nb: jsonData['creations'] || 0,
                                queryParams: creationsQueryParams
                            },
                            modifications: {
                                nb: jsonData['modifications'] || 0,
                                queryParams: modificationsQueryParams
                            },
                        } as ResultTable;
                    })
            )
        );
    }
}
