import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, ElementRef } from '@angular/core';
import { ComponentPortal, DomPortalHost, PortalInjector } from '@angular/cdk/portal';

import { DynamicLinkComponent, DYNAMIC_LINK_INJECTION_TOKEN } from '@shared/components/items/dynamic-link/dynamic-link.component'
import { DynamicLinksOut } from '@src/app/models/dynamic-links-out.model'


/**
 * Used for Dynamic Links
 * ServiceDomInjector dynamically creates a DynamicLinkComponent and can insert it into the dom 
 * see reveal() method
 */
@Injectable({ providedIn: 'root' })
export class ServiceDomInjector {
  
    private dynamicLinkPortal: ComponentPortal<DynamicLinkComponent>;
    private bodyPortalHost: DomPortalHost;
  
    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector
    ) {
    }


    createInjector(dataToPass: any): PortalInjector {
        const injectorTokens = new WeakMap();
        injectorTokens.set(DYNAMIC_LINK_INJECTION_TOKEN, dataToPass);
        return new PortalInjector(this.injector, injectorTokens);
    }


    reveal(el: HTMLElement, dynLink: DynamicLinksOut) {
        this.dynamicLinkPortal = new ComponentPortal(DynamicLinkComponent);
        this.bodyPortalHost = new DomPortalHost(
            el,
            this.componentFactoryResolver,
            this.appRef,
            this.createInjector(dynLink)
        );
        this.bodyPortalHost.attach(this.dynamicLinkPortal);
    }
  
    hide() {
        this.bodyPortalHost.detach();
    }
}
