import { Component, OnInit, OnDestroy } from '@angular/core';
import { ServiceListings, ServiceMap } from '@services';

@Component({
    selector: 'filters-by-keywords-score',
    templateUrl: './filters-by-keywords-score.component.html',
    styleUrls: ['./filters-by-keywords-score.component.scss']
})
export class FiltersByKeywordsScoreComponent implements OnInit, OnDestroy {

    public scoreMin: number = 0;

    constructor(
        private serviceListings: ServiceListings,
        private serviceMap: ServiceMap
    ) {
    }

    ngOnInit() {
        
    }

    onChange() {
        this.serviceMap.emitFilterByKeywordsScoreChanged(this.scoreMin);
    }

    reset() {
        this.scoreMin = 0;
    }

    ngOnDestroy() {
        this.serviceListings.mapTypeKeywordEnabled = false;
    }
}
