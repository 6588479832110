import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
    selector: 'dialog-remove_item-confirmation',
    styleUrls: ['./dialog-remove_item-confirmation.scss'],
    templateUrl: './dialog-remove_item-confirmation.html',
})
export class DialogRemoveItemConfirmationComponent {

    constructor(
        public dialogRef: MatDialogRef<DialogRemoveItemConfirmationComponent>
    ) { }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
