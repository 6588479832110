import { Deserializable } from "./deserializable.model";

export class EditionWarning implements Deserializable {
    title: string;
    url: string;
    date: string;

    deserialize(input: any, currentUser: any) {
        Object.assign(this, {
            title: input.title,
            url: '/member/' + input.id.replace('#', '').replace(':', '-'),
            date: input.date
        });
        return this;
    }
}
