import * as _ from 'lodash';
import { Component, OnInit, AfterViewInit, ViewChild, Output, EventEmitter, OnDestroy, ChangeDetectorRef, ViewRef } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material';
import { Subject } from 'rxjs/Subject';
import { takeUntil, first } from 'rxjs/operators';
import { ServiceListings, ServicePreferences, ServiceItems, ServiceTabs, ServiceSecurity } from '@services';
import { AppSettings, Settings } from '@app/app.settings';
import { FabButtonsConfiguration } from '@src/app/models/fab-buttons-configuration.model';
import { ActivatedRoute } from '@angular/router';
import { Role } from '@src/app/models';

@Component({
    selector: 'items-toolbar',
    templateUrl: './items-toolbar.component.html',
    styleUrls: ['./items-toolbar.component.scss']
})
export class ItemsToolbarComponent implements OnInit, AfterViewInit, OnDestroy {
    @Output() filtersToggler = new EventEmitter<boolean>();
    @ViewChild('matPaginator', { read: MatPaginator, static: false}) public paginator: MatPaginator;

    public settings: Settings;
    public viewTypeValue: string;
    public currentPage: number;
    public pageSize: number;
    public noItem = false;
    public isSummarizerEnabled = false;
    public isSummarizerButtonEnabled = false;

    private _destroyed$ = new Subject();

    constructor(
        private appSettings: AppSettings,
        private activeRoute: ActivatedRoute,
        private serviceItems: ServiceItems,
        private serviceTabs: ServiceTabs,
        private servicePreferences: ServicePreferences,
        private serviceListings: ServiceListings,
        private serviceSecurity: ServiceSecurity,
        private cd: ChangeDetectorRef
    ) {
        this.settings = this.appSettings.settings;
        this.isSummarizerEnabled = (IS_SUMMARIZER_ENABLED === true) ? true : false;
    }

    ngOnInit() {
        this.isSummarizerButtonEnabled = true;

        let pageSize = this.appSettings.settings.defaultNbItems;
        const listType = this.serviceListings.type;
        const listGroup = this.serviceListings.getKey();
        if (listType === 'detail') {
            pageSize = this.servicePreferences.getPreference('NbItemsOnItem.' +  listGroup);
            if (!pageSize) {
                pageSize = this.servicePreferences.getPreference('defaultNbItemsOnItem');
            }
        } else {
            pageSize = this.servicePreferences.getPreference('NbItems.' +  listGroup);
            if (!pageSize) {
                pageSize = this.servicePreferences.getPreference('defaultNbItems');
            }
        }
        this.pageSize = pageSize;

        this.serviceListings.listingViewObservable.pipe(
            takeUntil(this._destroyed$)
        ).subscribe((value: string) => {
            this.viewTypeValue = value;
        });
        
        const listingKey = this.serviceListings.getKey();
        const cookiePageIndex = this.serviceSecurity.getCookie('index-' + listingKey) || '0';
        const cookiePageIndexInt = parseInt(cookiePageIndex, 10);
        this.currentPage = cookiePageIndexInt;
    }

    ngAfterViewInit() {
        this.serviceListings.datasourceObservable.pipe(
            takeUntil(this._destroyed$)
        ).subscribe((datasource) => {
            if (datasource) {
                const cookiePageIndexInt = this.currentPage;

                const start = cookiePageIndexInt * this.pageSize;
                const end = (cookiePageIndexInt + 1) * this.pageSize;

                datasource.paginator = this.paginator;
                this.serviceListings.visibleItems = this.serviceListings.datasource.data.slice(start, end);
                setTimeout(() => {
                    // datasource.paginator.pageIndex = cookiePageIndexInt;
                    this.noItem = (_.size(datasource.filteredData) === 0);
                }, 10);
            }
        });
    }

    changeViewType(value: string) {
        this.viewTypeValue = value;
        this.serviceListings.listingView = value;

        const keepFilters = this.servicePreferences.getPreference('keepFilters');
        if (keepFilters === true) {
            const listGroup = this.serviceListings.getKey();
            let preferenceType = '';
            if (this.serviceListings.type === 'detail') {
                preferenceType = 'ListingViewOnItem';
            } else {
                preferenceType = 'ListingView';
            }
            const preferenceTypes = this.servicePreferences.getPreference(preferenceType) || {};
            preferenceTypes[listGroup] = value;
            const newPreferenceTypes = {};
            newPreferenceTypes[preferenceType] = preferenceTypes;
            this.servicePreferences.savePreference(newPreferenceTypes).pipe(
                first(),
                takeUntil(this._destroyed$)
            ).subscribe();
        }

        const itemGroup = _.capitalize(_.get(_.first(this.activeRoute.snapshot.url), 'path', 'Page'));
        const serviceItem = this.serviceItems.getItemService(itemGroup);
        const fabButtons = new FabButtonsConfiguration();
        fabButtons.group = itemGroup;
        if (serviceItem) {
            fabButtons.createItem = serviceItem.userCanCreateItem();
        }
        if (value === 'TILES' || value === 'TABLE') {
            fabButtons.downloadItems = this.serviceSecurity.hasMinimumRole(Role.ROLE_KB_ADMIN);
        }
        this.serviceTabs.tabChanged(fabButtons);
    }

    // Event emitted when the paginator changes the page size or page index
    handlePage(pageEvent: PageEvent) {
        const pageSizeChanged = this.pageSize !== pageEvent.pageSize;
        const pageIndex = pageEvent.pageIndex;
        this.currentPage = pageIndex;
        this.pageSize = pageEvent.pageSize;

        const end = (this.currentPage + 1) * this.pageSize;
        const start = this.currentPage * this.pageSize;

        const listingKey = this.serviceListings.getKey();
        this.serviceSecurity.setCookie('index-' + listingKey, pageIndex + '', 0);
        this.serviceListings.datasource.paginator.pageIndex = pageIndex;
        this.serviceListings.visibleItems = this.serviceListings.datasource.data.slice(start, end);

        const keepFilters = this.servicePreferences.getPreference('keepFilters');
        if (pageSizeChanged && keepFilters === true) {
            const listGroup = this.serviceListings.getKey();
            let preferenceType = '';
            if (this.serviceListings.type === 'detail') {
                preferenceType = 'NbItemsOnItem';
            } else {
                preferenceType = 'NbItems';
            }
            const preferenceTypes = this.servicePreferences.getPreference(preferenceType) || {};
            preferenceTypes[listGroup] = pageEvent.pageSize;
            const newPreferenceTypes = {};
            newPreferenceTypes[preferenceType] = preferenceTypes;
            this.servicePreferences.savePreference(newPreferenceTypes).pipe(
                first(),
                takeUntil(this._destroyed$)
            ).subscribe();
        }
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }
}
