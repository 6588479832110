import * as _ from 'lodash';
import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

import { ServiceItems } from '@services';
import { UserAction, MatDialogUserActionData } from '@models';

@Component({
    selector: 'dialog-modifications',
    styleUrls: ['./dialog-modifications.scss'],
    templateUrl: './dialog-modifications.html',
})
export class DialogModificationsComponent implements OnInit {

    public versionChoosen = new EventEmitter();
    public displayedIndex: number;
    public modification: UserAction;
    public modifications: Array<UserAction>;
    public isGranted = false;
    public displayedContent: any;

    constructor(
        private sanitizer: DomSanitizer,
        private serviceItems: ServiceItems,
        @Inject(MAT_DIALOG_DATA) public data: MatDialogUserActionData,
        public dialogRef: MatDialogRef<DialogModificationsComponent>
    ) {}

    ngOnInit() {
        this.modification = this.data.userAction;

        const displayedContent = this.modification.before;
        this.displayedContent = this.sanitizer.bypassSecurityTrustHtml(displayedContent);

        this.modifications = this.data.contentModifications;

        const modification = this.modification;
        this.displayedIndex = _.findIndex(this.modifications, function(o: any) {
            return o.creation_date === modification.creation_date;
        });
        this.isGranted = this.serviceItems.userCanModifyContent(this.data.item);
    }

    displayVersion(idx: number): void {
        this.displayedIndex = idx;
        this.modification = this.modifications[idx];

        const displayedContent = this.modification.before;
        this.displayedContent = this.sanitizer.bypassSecurityTrustHtml(displayedContent);
    }

    useVersion(): void {
        const displayedIndex = this.displayedIndex;
        const modification = this.modifications[displayedIndex];
        this.versionChoosen.emit(modification.before);
    }
}
