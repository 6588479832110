import * as _ from 'lodash';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { RegistrationRequest, ForgotPasswordRequest, BecomeContributorRequest, RegistrationRequestResult } from '@models/requests.model';
import { CommonService } from '@services/common.service';
import { map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ServiceRequests extends CommonService {

    constructor(protected injector: Injector) {
        super(injector);
    }

    requestAccount(request: RegistrationRequest): Observable<any> {
        return this.http.post(this.urlApi + 'requests/register', request);
    }

    requestResetPassword(request: ForgotPasswordRequest): Observable<any> {
        return this.http.post(this.urlSso + 'reset-password', request);
    }

    getAllRequestsRegistrations(): Observable<RegistrationRequest[]> {
        return this.http.get<RegistrationRequest[]>(this.urlApi + 'requests/registrations').pipe(
            map(
                (requests: RegistrationRequest[]) => _.orderBy(requests, ['date'], ['asc'])
            )
        );
    }

    answerRequestRegistration(email: string, decision: boolean, sendEmail = true): Observable<RegistrationRequestResult> {
        return this.http.put(this.urlApi + 'requests/register/' + email, JSON.stringify({
            decision: _.toString(decision),
            sendEmail: _.toString(sendEmail)
        }));
    }

    getAllRequestsContributors(): Observable<BecomeContributorRequest[]> {
        return this.http.get<BecomeContributorRequest[]>(this.urlApi + 'requests/become-contributor').pipe(
            map(
                (requests: BecomeContributorRequest[]) => _.orderBy(requests, ['date'], ['asc'])
            )
        );
    }

    answerRequestBecomeContributor(oid: string, decision: boolean, sendEmail = true): Observable<void> {
        return this.http.put<void>(this.urlApi + 'requests/become-contributor/' + oid, JSON.stringify({
            decision: _.toString(decision),
            sendEmail: _.toString(sendEmail)
        }));
    }
}
