import { Component, EventEmitter, Output, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { AppSettings } from '@src/app/app.settings';
import { ServicePreferences, ServiceToaster, ServiceErrors } from '@src/app/services';
import { PreferencesLinkedBlocks } from '@src/app/models';
import { finalize } from 'rxjs/internal/operators/finalize';
import { first, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'preferences-detail',
    templateUrl: './preferences-detail.html',
    styleUrls: ['./preferences-detail.scss']
})
export class PreferencesDetailComponent implements OnInit, OnDestroy {
    @Output() saveConfirmed = new EventEmitter();

    @Output() blocksChanged = new EventEmitter();

    public saving = false;
    public currentUserPreferencesLinkedBlocks: PreferencesLinkedBlocks;

    private _destroyed$ = new Subject();

    constructor(
        private appSettings: AppSettings,
        private serviceErrors: ServiceErrors,
        private serviceToaster: ServiceToaster,
        private servicePreferences: ServicePreferences
    ) {
    }

    ngOnInit() {
        this.currentUserPreferencesLinkedBlocks = this.servicePreferences.getPreference('detailLinkedBlocks');
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }

    savePreferences(): void {
        this.saving = true;
        this.servicePreferences.savePreference({ 'detailLinkedBlocks': this.currentUserPreferencesLinkedBlocks }).pipe(
            finalize(() => {
                this.saving = false;
                this.saveConfirmed.emit(true);
            }),
            first(),
            takeUntil(this._destroyed$)
        ).subscribe(
            () => {
                this.appSettings.settings.detailLinkedBlocks = this.currentUserPreferencesLinkedBlocks;
                this.serviceToaster.success('PREFERENCES.LINKED_BLOCKS');
            },
            (error: HttpErrorResponse) => {
                this.serviceErrors.handleError('PREFERENCES.LINKED_BLOCKS', error);
            }
        );
    }

    propagateChanges(){
        this.blocksChanged.emit(this.currentUserPreferencesLinkedBlocks);
    }
}
