import * as _ from 'lodash';
import {
    Component,
    OnInit,
    OnDestroy,
    Input,
    HostListener,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { takeUntil, finalize, first } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

import { AppSettings, Settings } from '@app/app.settings';
import {
    Item,
    Page,
    Member,
    Worklab,
    Topic,
    TopicCreationResponse,
    User,
    DynamicLinksOut,
    TopicCreator,
} from '@models';
import {
    ServiceItems,
    ServiceToaster,
    ServiceDatastore,
    ServiceTopics,
    ServiceSecurity,
    ServiceFroala,
    ServiceDynamicLinks,
    ServiceErrors,
} from '@services';

import { MatDialogConfig } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { DialogSurveyComponent } from '@shared/components/survey';


declare var $: any;

@Component({
    selector: 'topics',
    templateUrl: './topics.component.html',
    styleUrls: ['./topics.component.scss'],
})
export class TopicsComponent implements OnInit, OnDestroy {
    @ViewChild('topicsAccordion', { static: false })
    topicsAccordionElementRef: ElementRef;

    private _item: Item;
    @Input()
    set item(item: Item) {
        this._item = item;
    }
    get item(): Item {
        return this._item;
    }

    public settings: Settings;
    public froalaConfigNewTopic: Object;
    public froalaConfigNewSubTopic: Object;

    public inProgress = false;
    public inProgressSavingNew = false;
    public loading = false;
    public topics: Array<Topic>;
    public panelOpenState = false;
    public newIsOpen = false;
    public currentUser: User;
    public isUserAnonymous = true;
    public isUserAdmin = true;
    public newTopicText: string;
    public dynamicLinksOut: DynamicLinksOut[] = [];

    private _destroyed$ = new Subject();

    constructor(
        private appSettings: AppSettings,
        private serviceDatastore: ServiceDatastore,
        private serviceFroala: ServiceFroala,
        private serviceTopics: ServiceTopics,
        private serviceItems: ServiceItems,
        private serviceErrors: ServiceErrors,
        private serviceToaster: ServiceToaster,
        private serviceSecurity: ServiceSecurity,
        private serviceDynamicLinks: ServiceDynamicLinks,
        private _dialog: MatDialog
    ) {
        this.settings = this.appSettings.settings;

        this.serviceSecurity.currentUserObservable
            .pipe(takeUntil(this._destroyed$))
            .subscribe((user) => {
                this.currentUser = user;
                this.isUserAnonymous =
                    !this.currentUser ||
                    _.get(this.currentUser, 'role', 'ROLE_PUBLIC') ===
                        'ROLE_PUBLIC';
                this.isUserAdmin = this.serviceSecurity.isAdmin();
            });
    }

    ngOnInit() {
        $('.fr-toolbar').css('display', 'none');

        this.froalaConfigNewTopic = this.serviceFroala.getFroalaConfig(
            'ITEM.TOPICS.NEW_TOPIC.PLACEHOLDER',
            this.item
        );
        this.froalaConfigNewSubTopic = this.serviceFroala.getFroalaConfig(
            'ITEM.TOPICS.NEW_SUBTOPIC.PLACEHOLDER',
            this.item
        );

        this.serviceFroala.setToolbarPosition('#newTopicTextarea');
        this.serviceFroala.setToolbarPosition('#newSubTopicTextarea');

        this.serviceDatastore.dataStoreObservable
            .pipe(takeUntil(this._destroyed$))
            .subscribe(() => {
                this._loadTopics();
            });
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }

    trackIds(index: number, o: Topic | TopicCreator) {
        return o ? o.id : index;
    }

    @HostListener('window:resize')
    public onWindowResize(): void {
        if (this.newIsOpen) {
            this.serviceFroala.setToolbarPosition('#newTopicTextarea');
        }

        const self = this;
        _.forEach(this.topics, function (topic) {
            if (topic.isOpened) {
                self.serviceFroala.setToolbarPosition(
                    '#newSubTopicTextarea-' + topic.uid
                );
            }
        });
    }

    private _loadTopics(): void {
        const mustShowSurvey = this.serviceSecurity.getCookie('mustShowSurvey');
        if (mustShowSurvey === 'true') {
            const dialogConfig = new MatDialogConfig();
            // dialogConfig.minWidth = '40%';
            // dialogConfig.minHeight = '250px';
            setTimeout(() => {
                this._dialog.open(DialogSurveyComponent, dialogConfig);
            }, 100);
            this.serviceSecurity.removeCookie('mustShowSurvey');
        }

        const mustOpenNewTopic = this.serviceSecurity.getCookie('mustOpenNewTopic');

        this.loading = true;
        this.serviceTopics
            .getAllTopics(this.item)
            .pipe(
                finalize(() => {
                    this.loading = false;
                }),
                first(),
                takeUntil(this._destroyed$)
            )
            .subscribe(
                (topics: Topic[]) => {
                    this.topics = topics;

                    if (mustOpenNewTopic === 'true' || _.size(topics) === 0) {
                        this.newIsOpen = true;
                        this.serviceFroala.setToolbarPosition(
                            '#newTopicTextarea'
                        );
                    }
                    this.serviceSecurity.removeCookie('mustShowSurvey');
                    this.getDynamicLinks();
                },
                (error: HttpErrorResponse) => {
                    this.serviceErrors.handleError('ITEM.TOPICS.LOAD', error);
                }
            );
    }

    openNewTopic() {
        $('.fr-toolbar').css('display', 'none');

        _.forEach(this.topics, function (topic) {
            topic.isOpened = false;
            $('#newSubTopicTextarea-' + topic.uid).hide();
        });

        setTimeout(() => {
            this.serviceFroala.setToolbarPosition('#newTopicTextarea');
            this.newIsOpen = !this.newIsOpen;
        }, 100);
    }

    openSubTopic(topic) {
        this.newIsOpen = false;
        $('.fr-toolbar').css('display', 'none');
        $('#newSubTopicTextarea-' + topic.uid).show();
        setTimeout(() => {
            this.serviceFroala.setToolbarPosition(
                '#newSubTopicTextarea-' + topic.uid
            );
        }, 500);
    }

    closeSubTopic(topic) {
        this.newIsOpen = false;
        $('.fr-toolbar').css('display', 'none');
        $('#newSubTopicTextarea-' + topic.uid).hide();
    }

    saveNewTopic() {
        this.inProgress = true;
        this.inProgressSavingNew = true;
        this.settings.savingInsideSpinner = true;
        this.serviceTopics
            .createTopic(this.item, this.newTopicText)
            .pipe(
                finalize(() => {
                    this.inProgress = false;
                    this.inProgressSavingNew = false;
                    this.settings.savingInsideSpinner = false;
                }),
                first(),
                takeUntil(this._destroyed$)
            )
            .subscribe(
                (topicCreationResponse: TopicCreationResponse) => {
                    this.newTopicText = '';
                    const topics = topicCreationResponse.topics;

                    const item = this.serviceItems.item;
                    item.nbTopics = _.size(topics);

                    if (
                        topicCreationResponse.newSubscription === true &&
                        (item.group === 'Page' || item.group === 'Member')
                    ) {
                        (<Page | Member>item).isUserFollowing = true;

                        const follower = this.serviceSecurity.user;
                        this.serviceDatastore.startFollowing(
                            follower.id,
                            this._item.id,
                            this.serviceSecurity.user
                        );
                        this.serviceToaster.success(
                            'ITEM.TOPICS.NEW_SUBSCRIPTION'
                        );
                    } else if (
                        topicCreationResponse.newSubscription === true &&
                        item.group === 'Worklab'
                    ) {
                        (<Worklab>item).isUserParticipant = true;

                        const follower = this.serviceSecurity.user;
                        this.serviceDatastore.startParticipating(
                            this._item.id,
                            follower.id,
                            this.serviceSecurity.user
                        );
                        this.serviceToaster.success(
                            'ITEM.TOPICS.NEW_SUBSCRIPTION'
                        );
                    }

                    this.topics = topics;
                    this.serviceItems.item = item;
                    this.getDynamicLinks();
                },
                (error: HttpErrorResponse) => {
                    this.serviceErrors.handleError('ITEM.TOPICS.ADD', error);
                }
            );
    }

    saveNewSubTopic(topic: Topic) {
        this.inProgress = true;
        topic.inProgress = true;
        this.settings.savingInsideSpinner = true;
        this.serviceTopics
            .createSubTopic(this.item, topic)
            .pipe(
                finalize(() => {
                    this.inProgress = false;
                    topic.inProgress = false;
                    this.settings.savingInsideSpinner = false;
                }),
                first(),
                takeUntil(this._destroyed$)
            )
            .subscribe(
                (topicCreationResponse: TopicCreationResponse) => {
                    const topics = topicCreationResponse.topics;

                    const item = this.serviceItems.item;
                    item.nbTopics = _.size(topics);

                    if (topicCreationResponse.newSubscription === true) {
                        (<Page | Member>item).isUserFollowing = true;

                        const follower = this.serviceSecurity.user;
                        this.serviceDatastore.startFollowing(
                            follower.id,
                            this._item.id,
                            this.serviceSecurity.user
                        );

                        this.serviceToaster.success(
                            'ITEM.TOPICS.NEW_SUBSCRIPTION'
                        );
                    }

                    const currentTopic = _.find(topics, { id: topic.id });
                    if (currentTopic) {
                        (<Topic>currentTopic).isOpened = true;
                    }
                    this.topics = topics;
                    this.serviceItems.item = item;
                    this.getDynamicLinks();
                },
                (error: HttpErrorResponse) => {
                    this.serviceErrors.handleError('ITEM.TOPICS.ADD', error);
                }
            );
    }

    removeTopic(topic: Topic) {
        this.inProgress = true;
        topic.inProgress = true;
        this.serviceTopics
            .removeTopic(this.item, topic)
            .pipe(
                finalize(() => {
                    this.inProgress = false;
                }),
                first(),
                takeUntil(this._destroyed$)
            )
            .subscribe(
                (topics: Topic[]) => {
                    const item = this.serviceItems.item;
                    item.nbTopics = _.size(topics);

                    this.topics = topics;
                    this.serviceItems.item = item;
                },
                (error: HttpErrorResponse) => {
                    this.serviceErrors.handleError('ITEM.TOPICS.REMOVE', error);
                }
            );
    }

    getDynamicLinks() {
        // Load DynamicLinks for topics
        let topicsTexts = '';
        _.forEach(this.topics, (topic: Topic) => {
            topicsTexts += ' ' + topic.content;
            _.forEach(topic.subtopics, (subtopic: Topic) => {
                topicsTexts += ' ' + subtopic.content;
            });
        });

        this.serviceDynamicLinks
            .getDynamicLinksOut(
                topicsTexts,
                '#xx:xx',
                undefined,
                undefined,
                false // Do not clear dictionnary, keep words from item details
            )
            .pipe(first(), takeUntil(this._destroyed$))
            .subscribe(
                (dynamicLinks: DynamicLinksOut[]) => {
                    this.dynamicLinksOut = dynamicLinks;

                    _.forEach(this.topics, (topic: Topic) => {
                        topic.displayedContent = this.serviceDynamicLinks.getHtmlWithMarkers(
                            topic.content,
                            dynamicLinks
                        );
                        _.forEach(topic.subtopics, (subtopic: Topic) => {
                            subtopic.displayedContent = this.serviceDynamicLinks.getHtmlWithMarkers(
                                subtopic.content,
                                dynamicLinks
                            );
                        });
                    });
                    if (this.topicsAccordionElementRef) {
                        this.serviceDynamicLinks.insertDynamicLinksInMarkedElements(
                            this.topicsAccordionElementRef.nativeElement
                        );
                    }
                },
                (error: HttpErrorResponse) => {
                    // this.serviceErrors.handleError('ITEM.TOPICS.DYNAMIC_LINKS', error);
                }
            );
    }
}
