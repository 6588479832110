import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ServiceSaml } from '@services/saml.service';

/**
 * Redirects any '/login' request to SAML IDP if saml is enabled
 */
@Injectable({
    providedIn: 'root'
})
export class GuardIsSamlLoginService implements CanActivate {
    constructor(
        private serviceSaml: ServiceSaml
    ) {}


    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any>|Promise<any>|any {
        if (this.serviceSaml.isSamlEnabled()) {
            if ('returnUrl' in route.queryParams) {
                // save returnUrl in cookie or localstorage
                const returnUrl = route.queryParams.returnUrl;
                localStorage.setItem('returnUrl', returnUrl);
            }
            window.location.href = this.serviceSaml.getSamlLoginUrl();
            return of(false);
        }
        return of(true);
    }
}
