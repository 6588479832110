import * as _ from 'lodash';
import { Component } from '@angular/core';
import { ServiceKb, ServiceSecurity } from '@services';

@Component({
    selector: 'news-popup',
    templateUrl: './news-popup.component.html',
    styleUrls: ['./news-popup.component.scss']
})
export class NewsPopupComponent {

    public hidden = true;
    public kbNews: string;

    constructor(
        private serviceKb: ServiceKb,
        private serviceSecurity: ServiceSecurity
    ) {
        this.kbNews = this.serviceKb.kb.news;
        if (!_.isEmpty(this.kbNews)) {
            this.hidden = this.serviceSecurity.isKbNewsPopupAccepted(this.kbNews, this.serviceKb.kb.slug);
        }
    }

    close() {
        this.hidden = true;
    }

    saveClose() {
        this.hidden = true;
        this.serviceSecurity.saveCloseKbNewsPopup(this.kbNews, this.serviceKb.kb.slug);
    }
}
