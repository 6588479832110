import { Component, OnInit, ViewChild } from '@angular/core';
import { ServiceListings, ServiceMap } from '@services';
import { FiltersByDynamicLinksComponent } from '@shared/components/filters/map-filters/filters-by-dynamic-links/filters-by-dynamic-links.component';
import { FiltersByClusterComponent } from '@shared/components/filters/map-filters/filters-by-cluster/filters-by-cluster.component';
import { FiltersByKeywordsScoreComponent } from '@shared/components/filters/map-filters/filters-by-keywords-score/filters-by-keywords-score.component';
import { FiltersByKeywordsLinksCountComponent } from '@shared/components/filters/map-filters/filters-by-keywords-links-count/filters-by-keywords-links-count.component';

@Component({
    selector: 'map-filters',
    templateUrl: './map-filters.component.html',
    styleUrls: ['./map-filters.component.scss']
})
export class MapFiltersComponent implements OnInit {

    @ViewChild('byDynamicLinks', {static: false}) byDynamicLinks: FiltersByDynamicLinksComponent;
    @ViewChild('byCluster', {static: false}) byCluster: FiltersByClusterComponent;
    @ViewChild('keywordsView', {static: false}) keywordsView: FiltersByClusterComponent;
    @ViewChild('byKeywordsScore', {static: false}) byKeywordsScore: FiltersByKeywordsScoreComponent;
    @ViewChild('byKeywordsLinksCount', {static: false}) byKeywordsLinksCount: FiltersByKeywordsLinksCountComponent;

    public isViewTypeDetail: boolean;
    public isMapKeywordEnabled: boolean;

    constructor(
        public serviceListings: ServiceListings,
    ) {
        this.isViewTypeDetail = this.serviceListings.type === 'detail' ? true : false;
        this.isMapKeywordEnabled = IS_MAP_KEYWORD_ENABLED;
    }

    ngOnInit() {
        
    }

    reset() {
        if (this.byDynamicLinks) {
            this.byDynamicLinks.reset();
        }
        if (this.byCluster) {
            this.byCluster.reset();
        }
        if (this.keywordsView) {
            this.keywordsView.reset();
        }
        if (this.byKeywordsScore) {
            this.byKeywordsScore.reset();
        }
        if (this.byKeywordsLinksCount) {
            this.byKeywordsLinksCount.reset();
        }
    }
}
