import * as _ from 'lodash';
import { Component, Input, OnDestroy } from '@angular/core';
import { finalize, first, takeUntil } from 'rxjs/operators';

import { Worklab, Member } from '@models';
import { ServiceItems, ServiceWorklabs, ServiceSecurity, ServiceMembers, ServiceToaster, ServiceErrors } from '@services';
import { Observable, Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'is-participant',
    templateUrl: './is-participant.component.html',
    styleUrls: ['./is-participant.component.scss']
})
export class IsParticipantComponent implements OnDestroy {

    private _worklab: Worklab;
    @Input()
    set worklab(value: Worklab) {
        this._worklab = value;
        this._setAttributes();
    }
    get worklab(): Worklab {
        return this._worklab;
    }

    public tooltip: string;
    public loading = false;
    public isGranted = false;
    public isDisabled = false;
    public isUserParticipant = false;

    private _isAdmin = false;
    private _destroyed$ = new Subject();

    constructor(
        private serviceToaster: ServiceToaster,
        private serviceErrors: ServiceErrors,
        private serviceItems: ServiceItems,
        private serviceMembers: ServiceMembers,
        private worklabsService: ServiceWorklabs,
        private serviceSecurity: ServiceSecurity
    ) {
        this._isAdmin = this.serviceSecurity.isAdmin();
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }

    toogleIsParticipant() {
        if (this.isGranted === true && (!this._worklab.isPrivate || this._isAdmin)) {
            const wasParticipant = this._worklab.isUserParticipant;

            this.loading = true;

            let request: Observable<any>;
            const participant = this.serviceSecurity.user;
            if (wasParticipant === true) {
                this._worklab.isUserParticipant = false;
                request = this.worklabsService.stopParticipating(this._worklab, participant);
            } else {
                this._worklab.isUserParticipant = true;
                request = this.worklabsService.startParticipating(this._worklab, participant);
            }

            request.pipe(
                finalize(() => {
                    this.loading = false;

                    let participants = this._worklab.participants;
                    participants = this.serviceMembers.sortArray(participants, 'asc');
                    this._worklab.participants = participants;

                    this._setAttributes();
                }),
                first(),
                takeUntil(this._destroyed$)
            ).subscribe(
                () => {
                    let participants = this._worklab.participants;
                    if (this._worklab.isUserParticipant === false) {
                        _.remove(participants, function (o: Member) { return o.id === participant.id; });
                    } else {
                        participants.push(participant);
                    }
                    participants = this.serviceMembers.sortArray(participants, 'asc');

                    this._worklab.isUserParticipant = this._worklab.isUserParticipant;
                    this._worklab.participants = participants;
                    if (this._worklab.witness) {
                        this._worklab.witness.isUserParticipant = this._worklab.isUserParticipant;
                        this._worklab.witness.participants = participants;
                    }
                    this.serviceItems.item = this._worklab;

                    const params = { member: participant.title, worklab: this._worklab.title };
                    if (wasParticipant === true) {
                        this.serviceToaster.success('ITEM.PARTICIPANTS.REMOVE', params);
                    } else {
                        this.serviceToaster.success('ITEM.PARTICIPANTS.ADD', params);
                    }
                },
                (error: HttpErrorResponse) => {
                    let participants = this._worklab.participants;
                    if (this._worklab.isUserParticipant === false) {
                        participants.push(participant);
                    } else {
                        _.remove(participants, function (o: Member) { return o.id === participant.id; });
                    }
                    participants = this.serviceMembers.sortArray(participants, 'asc');

                    this._worklab.isUserParticipant = this._worklab.isUserParticipant;
                    this._worklab.participants = participants;
                    if (this._worklab.witness) {
                        this._worklab.witness.isUserParticipant = this._worklab.isUserParticipant;
                        this._worklab.witness.participants = participants;
                    }
                    this.serviceItems.item = this._worklab;

                    if (wasParticipant === true) {
                        this.serviceErrors.handleError('ITEM.PARTICIPANTS.REMOVE', error);
                    } else {
                        this.serviceErrors.handleError('ITEM.PARTICIPANTS.ADD', error);
                    }
                }
            );
        }
    }

    private _setAttributes() {
        const isLocked = this._worklab.islocked;
        this.isUserParticipant = this._worklab.isUserParticipant;
        this.isGranted = this.worklabsService.userCanModifyParticipants(this._worklab);

        if (this.isGranted === true) {
            if (isLocked === true && !this._isAdmin) {
                this.isDisabled = true;
                this.tooltip = 'ITEM.PARTICIPANTS.TOOLTIP.DISABLED';
            } else if (this.isUserParticipant) {
                this.isDisabled = false;
                this.tooltip = 'ITEM.PARTICIPANTS.TOOLTIP.REMOVE';
            } else if (!this.isUserParticipant) {
                this.tooltip = 'ITEM.PARTICIPANTS.TOOLTIP.ADD';
            }
        }
    }
}
