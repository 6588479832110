import * as _ from 'lodash';
import { Pipe, PipeTransform } from '@angular/core';

// import { userAction } from './userAction';

@Pipe({
    name: 'userActionsFilter',
    pure: false
})
export class UserActionsFilter implements PipeTransform {
    transform(items: any[], filter: any): any[] {
        if (!items || !filter) {
            return items;
        }
        // filter items array, items which match and return true will be kept, false will be filtered out
        return items.filter((item: any) => this.applyFilter(item, filter));
    }

    /**
     * Perform the filtering.
     *
     * @param {any} userAction The userAction to compare to the filter.
     * @param {any} filter The filter to apply.
     * @return {boolean} True if userAction satisfies filters, false if not.
     */
    applyFilter(userAction: any, filter: any): boolean {
        for (let field in filter) {
            // if((field === 'add' || field === 'remove' || field === 'update') &&
            //
            // ){
            //
            // }
            if(_.get(filter, 'add') === false && userAction.classAction === 'add'){
                return false
            }
            if(_.get(filter, 'remove') === false && userAction.classAction === 'remove'){
                return false
            }
            if(_.get(filter, 'update') === false && userAction.classAction === 'update'){
                return false
            }
            // if (filter[field]) {
            //     if(field === 'add' && filter[field] === true && userAction.classAction !== field){
            //         return false;
            //     }
            //     if(field === 'remove' && filter[field] === true && userAction.classAction !== field){
            //         return false;
            //     }
            //     // if (typeof filter[field] === 'string') {
            //     //     if (userAction[field].toLowerCase().indexOf(filter[field].toLowerCase()) === -1) {
            //     //         return false;
            //     //     }
            //     // } else if (typeof filter[field] === 'number') {
            //     //     if (userAction[field] !== filter[field]) {
            //     //         return false;
            //     //     }
            //     // }
            // }
        }
        return true;
    }
}
