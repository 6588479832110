import { Injectable } from '@angular/core';
import { ToasterService, Toast, BodyOutputType } from 'angular2-toaster';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class ServiceToaster {

    constructor(
        private toaster: ToasterService,
        private serviceTranslate: TranslateService
    ) {
    }

    success(key: string, params?: any): Toast {
        const tradTitle = this.serviceTranslate.instant(key + '.TOASTER_TITLE', params);
        const title = (tradTitle === key + '.TOASTER_TITLE') ? null : tradTitle;
        const text = this.serviceTranslate.instant(key + '.SUCCESS', params);

        return this.toaster.pop('success', title, text);
    }

    error(key: string, params?: any): Toast {
        const tradTitle = this.serviceTranslate.instant(key + '.TOASTER_TITLE', params);
        const title = (tradTitle === key + '.TOASTER_TITLE') ? null : tradTitle;
        const text = this.serviceTranslate.instant(key, params);

        return this.toaster.pop('error', title, text);
    }

    warning(key: string, params?: any, timeout?: number): Toast {
        const tradTitle = this.serviceTranslate.instant(key + '.TOASTER_TITLE', params);
        const title = (tradTitle === key + '.TOASTER_TITLE') ? null : tradTitle;

        const tradText = this.serviceTranslate.instant(key + '.WARNINGS', params);
        const text = (tradText === key + '.WARNINGS') ? key : tradText;

        if (timeout) {
            const toast: Toast = {
                type: 'warning',
                title: title,
                body: text,
                timeout: timeout,
                bodyOutputType: BodyOutputType.TrustedHtml
            };

            return this.toaster.pop(toast);
        } else {
            return this.toaster.pop('warning', title, text);
        }
    }

    clear(toastId) {
        return this.toaster.clear(toastId);
    }

    clearAll() {
        return this.toaster.clear();
    }
}
