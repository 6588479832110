export class I2knLicencedUser {
    id: number;
    email: string;
    role: string;
    kb?: string;
    isExpert?: boolean;
    isExpertInKbs?: boolean;

    deserialize(input: any) {
        Object.assign(this, {
            id: input.id,
            email: input.email,
            role: input.role,
            kb: input.kb,
            isExpert: input.isExpert || false,
            isExpertInKbs: input.isExpertInKbs || []
        });
        return this;
    }
}
