import * as _ from 'lodash';
import { Component, OnInit, Input } from '@angular/core';

import { Member } from '@models';
import { ServiceItems } from '@services';

@Component({
    selector: 'password',
    templateUrl: './password.component.html',
    styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {

    private _member: Member;
    @Input()
    set member(member: Member) {
        this._member = member;
    }
    get member(): Member {
        return this._member;
    }

    public isCreation: boolean = false;
    public mismatch: boolean = false;
    public passwordIsValid: boolean;
    public passwordType: string = 'password';
    public viewPasswordIcon: any = 'fas fa-eye';
    public confirmationPasswordType: string = 'password';
    public viewConfirmationPasswordIcon: any = 'fas fa-eye';
    public passwordConfirmation: string;

    constructor(
        private serviceItems: ServiceItems
    ) {}

    ngOnInit() {
        this.isCreation = _.isUndefined(this.member.id);
    }

    changePasswordType(passwordType) {
        this.passwordType = passwordType;
        this.viewPasswordIcon = (passwordType === 'text') ? 'fas fa-eye-slash' : 'fas fa-eye';
    }

    changeConfirmationPasswordType(confirmationPasswordType) {
        this.confirmationPasswordType = confirmationPasswordType;
        this.viewConfirmationPasswordIcon = (confirmationPasswordType === 'text') ? 'fas fa-eye-slash' : 'fas fa-eye';
    }

    checkPasswords(){
        const password = this.member.password;
        if(_.trim(password) === ''){
            this.passwordConfirmation = '';
        }

        const confirmation = this.passwordConfirmation;

        if(!_.isUndefined(password)){
            var pwdValidLength = (password && password.length >= 8 ? true : false);
            var pwdHasLowercaseLetter = (password && /[a-z]/.test(password)) ? true : false;
            var pwdHasUppercaseLetter = (password && /[A-Z]/.test(password)) ? true : false;
            var pwdHasNumber = (password && /\d/.test(password)) ? true : false;

            if (pwdValidLength && pwdHasLowercaseLetter && pwdHasUppercaseLetter && pwdHasNumber) {
                this.passwordIsValid = true;
            } else if(_.trim(password) !== ''){
                this.passwordIsValid = false;
            }
            else{
                delete this.passwordIsValid;
            }
        }
        else{
            delete this.passwordIsValid;
        }

        this.mismatch = password !== confirmation;

        let errors = this._member.errors;
        if(this.mismatch){
            errors.password = { key: 'mismatch', label: 'PASSWORDS.MISMATCH' };
        }
        else if(this.passwordIsValid === false){
            errors.password = { key: 'not-valid', label: 'PASSWORDS.NOT_VALID' };
        }
        else{
            delete errors.password;
        }

        this._member.errors = errors;
        this.serviceItems.item = this._member;
    }
}
