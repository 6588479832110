import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { ServiceSecurity } from '@services/security.service';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { filter, take, switchMap, catchError  } from 'rxjs/operators';
import { throwError } from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import { ToasterService } from 'angular2-toaster';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
    private refreshTokenInProgress = false;

    // Refresh Token Subject tracks the current token, or is null if no token is currently
    // available (e.g. refresh pending).
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        public serviceSecurity: ServiceSecurity,
        private translateService: TranslateService,
        private toasterService: ToasterService
    ) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(catchError(error => {
            // We don't want to refresh token for some requests like login or refresh token itself
            // So we verify url and we throw an error if it's the case
            if (
                request.url.includes('/api/token/refresh') ||
                request.url.includes('/authentication/get-token') ||
                request.url.includes('login') || 
                request.url.includes('tokenize')
            ) {
                return throwError(error);
            }

            // If error status is different than 401 we want to skip refresh token
            // So we check that and throw the error if it's the case
            if (error.status !== 401) {
                return throwError(error);
            }

            if (this.refreshTokenInProgress) {
                // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
                // – which means the new token is ready and we can retry the request again
                return this.refreshTokenSubject.pipe(
                    filter(result => result !== null),
                    take(1),
                    switchMap(() => next.handle(this.addAuthenticationToken(request)))
                );
            } else {
                this.refreshTokenInProgress = true;

                // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
                this.refreshTokenSubject.next(null);

                // Call serviceSecurity.refreshAccessToken(this is an Observable that will be returned)
                return this.serviceSecurity
                    .refreshAccessToken().pipe(
                    switchMap((res: any) => {
                        this.serviceSecurity.setAccessToken(res.token);
                        this.serviceSecurity.setRefreshToken(res.refresh_token);

                        // When the call to refreshToken completes we reset the refreshTokenInProgress to false
                        // for the next time the token needs to be refreshed
                        this.refreshTokenInProgress = false;
                        this.refreshTokenSubject.next(res.token);
                        // if (!request.url.includes('modification-flag')) {
                            return next.handle(this.addAuthenticationToken(request));
                        // } else {
                        //     return throwError(error);
                        // }
                    }),
                    catchError((err: any) => {
                        this.refreshTokenInProgress = false;
                        localStorage.removeItem('currentUser');
                        return throwError(error);
                    })
                );
            }
        }));
    }

    addAuthenticationToken(request) {
        // Get access token from Local Storage
        const accessToken = this.serviceSecurity.getAccessToken();

        // If access token is null this means that user is not logged in
        // And we return the original request
        if (!accessToken) {
            return request;
        }

        // We clone the request, because the original request is immutable
        return request.clone({
            setHeaders: {
                'Authorization': `Bearer ${accessToken}`,
                'X-Requested-With': 'XMLHttpRequest'
            }
        });
    }
}
