import { Deserializable } from './deserializable.model';
import { Kb } from './kb.model';

export class KbSubscription extends Kb {
    subscriptionDate?: any;
    dateSubscription?: any;
    requestDate?: any;
    dateRequest?: any;
    date?: any;
    loading?: boolean;
    isAdmin?: boolean;
    token?: string;
}

export interface KbSubscriptions {
    kb: Kb;
    kbs: KbSubscription[];
}
