import * as _ from 'lodash';

export class DynamicLinkIn {
    group: 'Page' | 'Member' | 'Worklab' | 'Topic';
    id: string;
    kbSlug: string;
    societySlug: string;
    title: string;
    isExternal: boolean;
    mid?: string;
    cover?: string;
    imageUrl?: string;

    deserialize(input: any, currentSocietySlug: string, currentKbSlug: string) {
        Object.assign(this, input);
        this.kbSlug = input.kb;
        this.societySlug = input.society;

        this.isExternal = false;
        if (
            currentKbSlug !== this.kbSlug ||
            currentSocietySlug !== this.societySlug
        ) {
            this.isExternal = true;
        }

        const imgFolder = (this.group === 'Member') ? 'member' : 'thumbnails';
        if (this.cover) {
            this.imageUrl = '/images/' + _.toLower(this.group) + '/' + this.cover + '.png';
        } else if (this.mid) {
            this.imageUrl = '/images/' + imgFolder + '/' + this.mid + '.png';
        } else {
            this.imageUrl = '/images/thumbnails/no-thumbnail.png';
        }
        
        return this;
    }
}
