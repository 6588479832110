import { Component, Input, Attribute } from '@angular/core';

import { Item } from '@models';
import { ServiceTabs, ServiceSecurity } from '@services';

@Component({
    selector: 'button-go-to-forum',
    templateUrl: './button-go-to-forum.html',
    styleUrls: ['./button-go-to-forum.scss']
})
export class ButtonGoToForumComponent {
    @Input() disabled = false;

    private _item: Item;
    @Input()
    set item(value: Item) {
        this._item = value;
    }
    get item(): Item {
        return this._item;
    }

    public get propagateClick(): 'true' | 'false' {
        return this._propagateClick;
    }
    public set propagateClick(value: 'true' | 'false') {
        this._propagateClick = value;
    }

    constructor(
        private serviceSecurity: ServiceSecurity,
        private serviceTabs: ServiceTabs,
        @Attribute('propagateClick') private _propagateClick: 'true' | 'false' = 'true'
    ) {
    }

    goToForum() {
        this.serviceSecurity.setCookie('mustOpenNewTopic', 'true');
        this.serviceTabs.tabIndexChanged(2);
    }
}
