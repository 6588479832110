import * as _ from 'lodash';
import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Item, Document, Role } from '@models';
import { CommonService } from '@services/common.service';
import { ServiceDatastore } from './datastore.service';
import { ServiceSecurity } from './security.service';

@Injectable({ providedIn: 'root' })
export class ServiceDocuments extends CommonService {

    constructor(
        protected injector: Injector,
        private serviceSecurity: ServiceSecurity,
        private serviceDatastore: ServiceDatastore
    ) {
        super(injector);
    }

    sortArray(unsortedArray: Document[], orderDirection: 'asc' | 'desc'): Document[] {
        return _.orderBy(unsortedArray, function (document: Document) {
            return _.toLower(document.title);
        }, [orderDirection]);
    }

    userCanModifyDocuments(item: Item): boolean {
        const isUserAdmin = this.serviceSecurity.isAdmin();
        const isItemLocked = item.islocked;
        const isAccessGrantedByRole = this.serviceSecurity.hasMinimumRole(Role.ROLE_CONTRIBUTOR);

        return isUserAdmin || (!isItemLocked && isAccessGrantedByRole);
    }

    addDocument(item: Item, file: any): Observable<any> {
        if (!this.userCanModifyDocuments(item)) {
            return this.serviceErrors.throwError('ITEM.DOCUMENTS.ADD', { code: 403 });
        }

        const formData: FormData = new FormData();
        formData.append('document', file, file.name);
        formData.append('rid', item.id);

        const req = new HttpRequest('POST', this.urlApi + 'files/document', formData);
        return this.http.request(req).pipe(
            map(
                (event: any) => {
                    if (event instanceof HttpResponse) {
                        this.serviceDatastore.addDocument(item.id, new Document().deserialize(event.body));
                    }
                    return event;
                }
            )
        );
    }

    removeDocument(item: Item, document: any): Observable<any> {
        if (!this.userCanModifyDocuments(item)) {
            return this.serviceErrors.throwError('ITEM.DOCUMENTS.REMOVE', { code: 403 });
        }
        return this.http.delete(this.urlApi + 'files/document/' + document.key, {}).pipe(
            map(
                (data: any) => {
                    this.serviceDatastore.removeDocument(item.id, document);
                    return data;
                }
            )
        );
    }

    editDocumentTitle(item: Item, document: any): Observable<any> {
        if (!this.userCanModifyDocuments(item)) {
            return this.serviceErrors.throwError('ITEM.DOCUMENTS.EDIT_TITLE', { code: 403 });
        }
        return this.http.put(this.urlApi + 'files/document/' + document.key, {
            title: document.title
        }).pipe(
            map(
                (data: any) => {
                    this.serviceDatastore.addDocument(item.id, document);
                    return data;
                }
            )
        );
    }
}
