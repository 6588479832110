import { Component, OnInit, OnDestroy } from '@angular/core';
import { finalize, first, takeUntil } from 'rxjs/operators';
import { AppSettings } from '@app/app.settings';
import * as moment from 'moment-timezone';

import { Kb } from '@models';
import { ServicePreferences } from '@services';
import { Subject } from 'rxjs';

@Component({
    selector: 'preferences-timezone',
    templateUrl: './preferences-timezone.component.html',
    styleUrls: ['./preferences-timezone.component.scss']
})
export class PreferencesTimezoneComponent implements OnInit, OnDestroy {

    public saving = false;
    public loading = false;
    public availableTimezones: string[] = [];
    public currentUserTimezone: string;

    private _destroyed$ = new Subject();

    constructor(
        private appSettings: AppSettings,
        private servicePreferences: ServicePreferences
    ) {
    }

    ngOnInit() {
        this.availableTimezones = moment.tz.names();
        this.currentUserTimezone = this.servicePreferences.getPreference('timezone');
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }

    trackValues(index: number, o: string) {
        return o ? o : index;
    }

    changeTimezone($event) {
        this.appSettings.settings.timezone = $event.value;

        this.saving = true;
        this.servicePreferences.savePreference({'timezone': $event.value}).pipe(
            finalize(() => {
                this.saving = false;
            }),
            first(),
            takeUntil(this._destroyed$)
        ).subscribe();
    }
}
