import { Component, Injector } from '@angular/core';
import { ServiceListings, ServiceDatastore, ServiceTextAnalyser } from '@services';
import { AbstractFilterByChipComponent } from '../abstract-filters-by-chip.component';

@Component({
    selector: 'filters-by-expert',
    templateUrl: './filters-by-expert.component.html',
    styleUrls: ['./filters-by-expert.component.scss']
})
export class FiltersByExpertComponent extends AbstractFilterByChipComponent {
    constructor(
        injector: Injector,
        serviceDatastore: ServiceDatastore,
        textAnalyserService: ServiceTextAnalyser
    ) {
        super(injector, serviceDatastore, textAnalyserService, 'experts', 'experts');
    }
}
