import { Injectable } from '@angular/core';
import { Society, Kb } from '../models/kb.model';

@Injectable({ providedIn: 'root' })
export class ServiceUrls {

    public sso: string;
    public api: string;
    public sld: string;
    public kbSlug: string;
    public societySlug: string;


    constructor() {
        const hostParts = window.location.host.split('.');
        const societySlug = hostParts[1];
        const currentSociety = new Society().deserialize({
            slug: societySlug
        });
        this.societySlug = societySlug;
        this.sso = currentSociety.ssoUrl;

        const kbSlug = hostParts[0];
        const currentKb = new Kb().deserialize({
            slug: kbSlug,
            society: currentSociety
        });
        this.kbSlug = kbSlug;

        this.api = currentKb.apiUrl;

        hostParts.shift();
        hostParts.shift();
        this.sld = hostParts.join('.');
    }

    static convertOIDtoID(id: string): string {
        return (id || '').replace('#', '').replace(':', '-');
    }

    static convertIDtoOID(uid: string): string {
        return '#' + (uid || '').replace('-', ':');
    }

    static getCurrentKbSlug(): string {
        const hostParts = window.location.host.split('.');
        return hostParts[0];
    }

    static getCurrentSocietySlug(): string {
        const hostParts = window.location.host.split('.');
        return hostParts[1];
    }
}