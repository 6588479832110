import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ServiceSecurity } from '@services/security.service';

@Injectable({ providedIn: 'root' })
export class GuardMinimumRoleService implements CanActivate {

    constructor(
        private router: Router,
        private serviceSecurity: ServiceSecurity
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const routeHasMinimalRole = (_.get(route, 'data.minimumRole'));
        const isUserAnonymous = this.serviceSecurity.isCurrentUserAnonymous();

        if (routeHasMinimalRole && !this.serviceSecurity.hasMinimumRole(routeHasMinimalRole)) {
            // if (isUserAnonymous) {
                return this.router.parseUrl('/login?returnUrl=' + state.url);
            // } else {
                // return this.router.parseUrl('/not-allowed');
            // }
        }
        return true;
    }
}
