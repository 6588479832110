import { Deserializable } from "./deserializable.model";

export class Language implements Deserializable {
    id: string;
    label: string;
    enabled: boolean;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}
