import * as _ from 'lodash';
import { Injectable, Injector, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { map, first, finalize, mergeMap } from 'rxjs/operators';

import { Item, Page, Member, Worklab, MapNode, Role, ExportItemsRequest, Tag } from '@models';
import { ServicePages } from '@services/pages.service';
import { ServiceMembers } from '@services/members.service';
import { ServiceWorklabs } from '@services/worklabs.service';
import { ServiceAliases } from '@services/aliases.service';
import { CommonService } from '@services/common.service';
import { ServiceUrls } from '@services/urls.service';
import { ServiceTextAnalyser } from '@services/text-analyser.service';
import { ServiceDatastore } from './datastore.service';
import { ServiceSecurity } from './security.service';
import { ServiceTags } from './tags.service';
import { ServicePreferences } from './preferences.service';

/**
 * Service to manage items
 * @export
 * @class ServiceItems
 */
@Injectable({ providedIn: 'root' })
export class ServiceItems extends CommonService {

    private itemSubject: BehaviorSubject<Item>;
    itemObservable: Observable<Item>;

    itemSaved: EventEmitter<Item> = new EventEmitter();

    constructor(
        protected injector: Injector,
        private serviceAliases: ServiceAliases,
        private serviceDatastore: ServiceDatastore,
        private servicePages: ServicePages,
        private serviceSecurity: ServiceSecurity,
        private serviceMembers: ServiceMembers,
        private serviceWorklabs: ServiceWorklabs,
        private serviceTags: ServiceTags,
        private servicePreferences: ServicePreferences
    ) {
        super(injector);
        this.itemSubject = new BehaviorSubject<Item>(new Item());
        this.itemObservable = this.itemSubject.asObservable();
    }

    /**
     * Get the current item
     * @returns {Item}
     */
    get item(): Item {
        return this.itemSubject.value;
    }

    /**
     * Set the current item
     * @param {Item} value
     */
    set item(value: Item) {
        if (!this.item.witness || !value.witness) {
            value.witness = _.cloneDeep(value);
        } else {
            value.isDirty = !this.areEquals(value, this.item.witness);
        }
        value.errors = value.hasErrors({
            serviceAliases: this.serviceAliases,
            serviceMembers: this.serviceMembers
        });
        this.itemSubject.next(value);
    }

    /**
     * Update the item in datastore
     * @param {Item} rawitem
     * @returns {Item}
     */
    private updateItemInDatastore(rawitem: Item): Item {
        rawitem.titleWithoutAccent = this.serviceTextAnalyser.removeDiacritics(_.get(rawitem, 'title', '')).toLowerCase();
        const itemInDatastore = new Item(rawitem, this.serviceSecurity.user);
        const dataStore = this.serviceDatastore.dataStore;
        if (dataStore) {
            // create and update has the same synthax
            dataStore[itemInDatastore.id] = itemInDatastore;
            this.serviceDatastore.dataStore = dataStore;
            this.serviceDatastore.updateDocuments(itemInDatastore.id, itemInDatastore.documents);
        }

        return itemInDatastore;
    }

    /**
     * Get the item service
     * @param {string} group
     * @returns {ServicePages | ServiceMembers | ServiceWorklabs | undefined}
     */
    getItemService(group: string): ServicePages | ServiceMembers | ServiceWorklabs | undefined {
        if (group === 'Page') {
            return this.servicePages;
        } else if (group === 'Member') {
            return this.serviceMembers;
        } else if (group === 'Worklab') {
            return this.serviceWorklabs;
        }
    }

    /**
     * Get the item by id
     * @param {string} uId
     * @returns {Observable<Item>}
     */
    getItem(uId: string): Observable<Item> {
        const tagList = this.serviceTags.fetchAllTags();
        const getItem = this.http.get<Item>(this.urlApi + 'items/' + uId);

        return combineLatest([tagList, getItem]).pipe(
            mergeMap(
                (results: [Map<string, Tag>, Item])  => {
                    const allTags = results[0];
                    const item = results[1];
                    const itemTags = item.tags || [];
                    // Update tags from uptodate Tags data
                    const fullyQualifiedTags = itemTags.map((tag) => {
                        return allTags.get(tag.id) || tag;
                    });
                    item.tags = fullyQualifiedTags;

                     // If role is stored in datastore: save it since it comes from IDP
                     const role = _.get(this.serviceDatastore.find<Member>(ServiceUrls.convertIDtoOID(uId)), 'role');
                     if (role) {
                        (<Member>item).role = role;
                    }
                    return of(_.cloneDeep(this.updateItemInDatastore(item)));
                }
            )
        );
    }

    /**
     * Save the item
     * @param {*} item
     * @returns {Observable<Item>}
     */
    saveItem(item: any): Observable<Item> {
        const language = this.servicePreferences.getPreference('language');
        const sentData: any = {
            id: item.id,
            group: item.group,
            title: item.title,
            content: item.content,
            aliases: item.aliases,
            islocked: item.islocked,
            tags: item.tags,

            isPrivate: (<Worklab>item).isPrivate,

            email: (<Member>item).email,
            firstname: (<Member>item).firstname,
            lastname: (<Member>item).lastname,
            job_titles: (<Member>item).job_titles,
            adress: (<Member>item).adress,
            company: (<Member>item).company,
            facebook: (<Member>item).facebook,
            twitter: (<Member>item).twitter,
            linkedin: (<Member>item).linkedin,
            google: (<Member>item).google,
            phone: (<Member>item).phone,

            language: language
        };

        if (_.has(item, 'allProsimData')) {
            sentData.prosimData = item.allProsimData;
        }
        if (_.has(item, 'status')) {
            sentData.status = item.status;
        } else sentData.status = 'draft';
        
        const tagList = this.serviceTags.fetchAllTags();

        const isCreation = _.isUndefined(item.id);
        if (isCreation) {
            if (item.tags) {
                // TODO improve back...
                sentData.tags = JSON.stringify(_.map(
                    item.tags, function (o) {
                        return { title: o.title };
                    }));
            }

            if ((<Member>item).rights) {
                // TODO improve back...
                sentData.rights = JSON.stringify(item.rights);
            }

            if (item.experts) {
                sentData.newExperts = _.join(_.map(item.experts, 'id'), ',');
            }

            if (item.creators) {
                sentData.newCreators = _.join(_.map(item.creators, 'id'), ',');
            }

            if (item.associatedPagesOut || item.linkedWorklabs || item.followedPages || item.followedMembers || item.followedWorklabs) {
                item.associatedPagesOut = item.associatedPagesOut || [];
                item.linkedWorklabs = item.linkedWorklabs || [];
                item.followedPages = item.followedPages || [];
                item.followedMembers = item.followedMembers || [];
                item.followedWorklabs = item.followedWorklabs || [];

                sentData.newLinksOut = _.join(
                    _.map(
                        _.concat(
                            item.associatedPagesOut,
                            item.linkedWorklabs,
                            item.followedPages,
                            item.followedMembers,
                            item.followedWorklabs
                        ),
                        'id'),
                    ',');
            }

            if ((<Member>item).followers) {
                sentData.newLinksIn = _.join(_.map((<Member>item).followers, 'id'), ',');
            }

            if ((<Member>item).expertises) {
                sentData.newPagesAsExpert = _.join(_.map((<Member>item).expertises, 'id'), ',');
            }


            if ((<Worklab>item).participants || (<Worklab>item).linkedPages) {
                sentData.newLinksIn = _.join(_.map(_.concat((<Worklab>item).participants, (<Worklab>item).linkedPages), 'id'), ',');
            }

            const fd = new FormData();
            if (item.newPicture) {
                fd.append('picture', item.newPicture);
            }
            _.forEach(sentData, function (value, key) {
                if (key !== 'id' && value) {
                    fd.append(key, value);
                }
            });

            return this.http.post<Item>(this.urlApi + 'items', fd).pipe(
                first(),
                map(
                    (result: any) => {
                        const group = result.item.group;
                        const isMember = group === 'Member';

                        if (isMember && result.existingUser === false) {
                            result.item['newMember'] = true;
                        }

                        if (isMember) {
                            const role = _.get(item, 'rights.' + this.currentSocietySlug + '.' + this.currentKbSlug, Role.DEFAUL_CREATION_ROLE);
                            (<Member>result.item).role = role;
                        }
                        if(!USE_TAGS_TREE) {
                            this.serviceTags.resetAllTags();
                        }
                        const i = this.updateItemInDatastore(result.item);
                        this.itemSaved.emit(i);
                        return i;
                    }
                )
            );
        } else {
            const putItem = this.http.put<Item>(this.urlApi + 'items/' + item.uid, sentData);
            return combineLatest([tagList, putItem]).pipe(
                mergeMap(
                    (results: [Map<string,Tag>, Item])  => {
                        const allTags = results[0];
                        const resultItem = results[1]['item'];
                        const itemTags = resultItem.tags || [];

                        // Update tags from uptodate Tags data
                        const fullyQualifiedTags = itemTags.map((tag) => {
                            return allTags.get(tag.id) || tag;
                        });
                        resultItem.tags = fullyQualifiedTags;

                        // If role is stored in datastore: save it since it comes from IDP
                        const role = _.get(this.serviceDatastore.find<Member>(ServiceUrls.convertIDtoOID(item.id)), 'role');
                        if (role) {
                            (<Member>resultItem).role = role;
                        }
                        if(!USE_TAGS_TREE) {
                            this.serviceTags.resetAllTags();
                        }
                        const i = this.updateItemInDatastore(resultItem);
                        this.itemSaved.emit(i);
                        return of(_.cloneDeep(i));
                    }
                )
            );
        }
    }

    /**
     * Change the password
     * @param {string} password
     * @returns {Observable<any>}
     */
    changePassword(password: string): Observable<any> {
        return this.http.post<Item>(this.urlApi + 'users/change-my-password', { password }).pipe(
            first()
        );
    }

    /**
     * Check if the user can modify the item
     * @param {Item} item
     * @returns {boolean}
     */
    userCanModifyTitle(item: Item): boolean {
        const isUserAdmin = this.serviceSecurity.isAdmin();
        const isItemLocked = item.islocked;
        const isAccessGrantedByRole = this.serviceSecurity.hasMinimumRole(Role.ROLE_CONTRIBUTOR);

        return isUserAdmin || (!isItemLocked && isAccessGrantedByRole);
    }

    /**
     * Check if the user can modify the item
     * @param {Item} item
     * @returns {boolean}
     */
    userCanModifyContent(item: Item): boolean {
        if (item.group === 'Page') {
            return this.servicePages.userCanModifyContent(<Page>item);
        } else if (item.group === 'Member') {
            return this.serviceMembers.userCanModifyContent(<Member>item);
        } else if (item.group === 'Worklab') {
            return this.serviceWorklabs.userCanModifyContent(<Worklab>item);
        }
        return false;
    }

    /**
     * Check if items are equals
     * @param {Item} item1
     * @param {Item} item2
     * @returns {boolean}
     */
    areEquals(item1: any, item2: any): boolean {
        const serviceTextAnalyser = this.serviceTextAnalyser;

        let hasModifications = false;
        const _item1 = _.cloneDeep(item1);
        const _item2 = _.cloneDeep(item2);
        if ((_item1 && !_item2) || _item2 && !_item1) {
            return false;
        }
        const booleanFields = [
            'islocked',
            'isPrivate'
        ];
        const stringFields = [
            'title',
            'content',
            'job_titles',
            'company',
            'phone',
            'adress',
            'facebook',
            'twitter',
            'google',
            'linkedin',
            'password'
        ];
        const arrayOfStringsFields = [
            'aliases'
        ];

        _.each(booleanFields, function (field) {
            if (_item1[field] !== _item2[field]) {
                hasModifications = true;
            }
        });

        _.each(stringFields, function (field) {
            _item1[field] = _.trim(_item1[field]);
            _item2[field] = _.trim(_item2[field]);

            if (field === 'title') {
                _item1[field] = serviceTextAnalyser.removeDiacritics(_item1[field]).toLowerCase();
                _item2[field] = serviceTextAnalyser.removeDiacritics(_item2[field]).toLowerCase();
            }
            if (!_.isEqual((_item1[field]), (_item2[field]))) {
                hasModifications = true;
            }
        });
        _.each(arrayOfStringsFields, function (field) {
            _item1[field] = _item1[field] || [];
            _item1[field] = _.map(_item1[field], _.trim);

            _item2[field] = _item2[field] || [];
            _item2[field] = _.map(_item2[field], _.trim);

            if (!_.isEqual(_item1[field].sort(), _item2[field].sort())) {
                hasModifications = true;
            }
        });

        if ( _.has(item1, 'allProsimData') || _.has(item2, 'allProsimData')) {
            const allProsimData1 = JSON.stringify(_.get(item1, 'allProsimData'));
            const allProsimData2 = JSON.stringify(_.get(item2, 'allProsimData'));
            hasModifications = hasModifications || allProsimData1 !== allProsimData2;
        }

        if ( _.has(item1, 'tags') || _.has(item2, 'tags')) {
            const allTagsData1 = JSON.stringify(_.get(item1, 'tags'));
            const allTagsData2 = JSON.stringify(_.get(item2, 'tags'));
            hasModifications = hasModifications || allTagsData1 !== allTagsData2;
        }
        return !hasModifications;
    }

    /**
     * Check if items has errors
     * @param {Item} item
     * @returns {boolean}
     */
    hasErrors(item: Item): boolean {
        return true;
    }

    /**
     * Update the title
     * @param {Item} item
     * @param {string} title
     */
    updateTitle(item: Item, title: string) {
        this.serviceDatastore.updateTitle(item.id, title);
    }

    /**
     * Remove the item
     * @param {(Page | Member | Worklab)} item
     * @returns {Observable<any>}
     */
    removeItem(item: Page | Member | Worklab): Observable<any> {
        const serviceDatastore = this.serviceDatastore;
        return this.http.delete(this.urlApi + 'items/' + item.uid, {}).pipe(
            first(),
            map(
                () => {
                    serviceDatastore.remove(item.id);
                }
            )
        );
    }

    /**
     * Transform the item to page
     * @param {Item} item
     * @returns {Observable<any>}
     */
    transformToPage(item: Item): Observable<any> {
        const serviceDatastore = this.serviceDatastore;
        return this.http.put(this.urlApi + 'items/' + item.uid + '/change-class', {}).pipe(
            first(),
            map(
                (result: any) => {
                    serviceDatastore.remove(item.id);
                    return this.updateItemInDatastore(result);
                }
            )
        );
    }

    /**
     * Download items in export format
     * @param {('csv' | 'xls' | 'xlsx' | 'zip')} format
     * @param {string} lang
     * @param {ExportItemsRequest} exportedData 
     * @returns {Observable<any>}
     */
    downloadItems(format: 'csv' | 'xls' | 'xlsx' | 'zip', lang: string, exportedData: ExportItemsRequest): Observable<any> {
        const toast = this.serviceToaster.warning('EXPORT_ITEMS', null, 50000);

        if (format === 'csv') {
            return this.http.post<any>(this.urlApi + '2.1/export.' + lang + '.' + format, exportedData, {
                responseType: 'blob' as 'json'
            }).pipe(
                finalize(() => {
                    this.serviceToaster.clear(toast.toastId);
                })
            );
        } else {
            return this.http.post<any>(this.urlApi + '2.1/export.' + lang + '.' + format, exportedData).pipe(
                finalize(() => {
                    this.serviceToaster.clear(toast.toastId);
                })
            );
        }
    }

    /**
     * Get the item by node
     * @param {MapNode} node
     * @returns {Observable<Item>}
     */
    getItemByNode(node: MapNode): Observable<Item> {
        if (!node.originalId) return of(new Item());
        const id = ServiceUrls.convertOIDtoID(node.originalId);
        const url = '//' + PREFIXS.apiPrefix + '.' + node.societySlug + '.' + this.sld + '/' + node.kbSlug + '/';

        return this.http.get<Item>(url + 'items/' + id).pipe(
            map(
                (item: Item) => {
                    return new Item(item, this.serviceSecurity.user);
                }
            )
        );
    }

    /**
     * Change the page status
     * @param {Page} page
     * @param {*} status
     * @returns {Observable<any>}
     */
    changePageStatus(page: Page, status: string): Observable<any> {
        return this.http.put<Item>(this.urlApi + 'items/' + page.uid + '/status', {
            'status': status
        }).pipe(
            first(),
            map(
                (result: Item) => {
                    return this.updateItemInDatastore(result);
                }
            )
        );
    }
}
