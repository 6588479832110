import { Component, OnInit, AfterViewInit, Inject, EventEmitter, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ServiceKekule } from '@services/kekule.service';
import { Subject } from 'rxjs';
import { MatDialogKekuleData } from '@src/app/models';

@Component({
    selector: 'dialog-kekule',
    styleUrls: ['./dialog-kekule.scss'],
    templateUrl: './dialog-kekule.html',
})
export class DialogKekuleComponent implements AfterViewInit, OnDestroy {

    @ViewChild('chemComposer', {static: false}) chemComposerElementRef: ElementRef;

    public moleculeBuilt = new EventEmitter();
    public loading = false;

    private chemComposer: any;
    private molJson: string;
    private oldElement: HTMLElement;

    private _destroyed$ = new Subject();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: MatDialogKekuleData,
        public dialogRef: MatDialogRef<DialogKekuleComponent>,
        private serviceKekule: ServiceKekule
    ) {
    }

    ngAfterViewInit() {
        this.molJson = this.data.molJson;
        this.oldElement = this.data.element;
        this.chemComposer = this.serviceKekule.initComposerOnElement(this.chemComposerElementRef.nativeElement);
        if (this.molJson) {
            const chemObj = Kekule.IO.loadFormatData(this.molJson, 'Kekule-JSON');
            if (chemObj) {
                this.chemComposer.setChemObj(chemObj);
            }
        }
    }

    saveMolecule() {
        if (this.chemComposer) {
            const chemObj = this.chemComposer.getChemObj();
            const chemObjJson = Kekule.IO.saveFormatData(chemObj, 'Kekule-JSON');
            const htmlKekule = this.serviceKekule.getHtmlViewerFromData(chemObjJson);

            // Emit result
            this.moleculeBuilt.emit({
                outerHTML: htmlKekule,
                oldElement: this.oldElement
            });
        } else {
            console.error('Kekule-dialog problem: chemComposer is not initialized');
        }
        this.close();
    }

    close() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }
}
