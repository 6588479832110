import { Role } from '@models';

import { Menu } from './menu.model';

export const verticalMenuItems = () => [
    new Menu (1, 'MENU.WELCOME_PAGE', '/', null, 'fas fa-home', null, false, 0, Role.ROLE_PUBLIC),
    new Menu (2, 'MENU.DASHBOARDS', '/dashboards', null, 'fas fa-chart-line', null, false, 0, Role.ROLE_READER),

    new Menu (3, 'MENU.ALL_ITEMS', '/items', null, 'fas fa-database', null, false, 0, Role.ROLE_PUBLIC),

    new Menu (40, 'MENU.PAGES.MAIN', '/items/page', null, 'fas fa-file-alt', null, true, 0, Role.ROLE_READER),
    new Menu (41, 'MENU.PAGES.NEW', '/items/page/add', null, 'fas fa-plus', null, false, 40, Role.ROLE_CONTRIBUTOR),
    new Menu (42, 'MENU.PAGES.ALL', '/items/page', null, 'fas fa-file-alt', null, false, 40, Role.ROLE_READER),
    new Menu (43, 'MENU.PAGES.FAVORITES', '/items/page/favorites', null, 'fas fa-star', null, false, 40, Role.ROLE_READER),
    new Menu (44, 'MENU.PAGES.EXPERTISES', '/items/page/expertises', null, 'far fa-gem', null, false, 40, Role.ROLE_READER),
    new Menu (45, 'MENU.PAGES.RECOMMENDED', '/items/page/recommendations', null, 'far fa-lightbulb', null, false, 40, Role.ROLE_READER),
    new Menu (46, 'MENU.PAGES.CREATIONS', '/items/page/my-creations', null, 'fas fa-pencil-ruler', null, false, 40, Role.ROLE_CONTRIBUTOR),
    new Menu (47, 'MENU.PAGES.MODIFICATIONS', '/items/page/my-modifications', null, 'fas fa-pen', null, false, 40, Role.ROLE_CONTRIBUTOR),
    new Menu (48, 'MENU.PAGES.TOPICS', '/items/page/my-comments', null, 'fas fa-comments', null, false, 40, Role.ROLE_READER),
    new Menu (49, 'MENU.PAGES.ORPHANS', '/items/page/orphans', null, 'fas fa-puzzle-piece', null, false, 40, Role.ROLE_KB_ADMIN),

    new Menu (50, 'MENU.MEMBERS.MAIN', '/items/member', null, 'fas fa-user', null, true, 0, Role.ROLE_READER),
    new Menu (51, 'MENU.MEMBERS.NEW', '/items/member/add', null, 'fas fa-plus', null, false, 50, Role.ROLE_CONTRIBUTOR),
    new Menu (52, 'MENU.MEMBERS.ALL', '/items/member', null, 'fas fa-users', null, false, 50, Role.ROLE_READER),
    new Menu (53, 'MENU.MEMBERS.FAVORITES', '/items/member/favorites', null, 'fas fa-star', null, false, 50, Role.ROLE_READER),
    new Menu (55, 'MENU.MEMBERS.RECOMMENDED', '/items/member/recommendations', null, 'far fa-lightbulb', null, false, 50, Role.ROLE_READER),
    new Menu (58, 'MENU.PAGES.TOPICS', '/items/member/my-comments', null, 'fas fa-comments', null, false, 50, Role.ROLE_READER),

    new Menu (60, 'MENU.WORKLABS.MAIN', '/items/items/worklab', null, 'fas fa-briefcase', null, true, 0, Role.ROLE_READER),
    new Menu (61, 'MENU.WORKLABS.NEW', '/items/worklab/add', null, 'fas fa-plus', null, false, 60, Role.ROLE_CONTRIBUTOR),
    new Menu (62, 'MENU.WORKLABS.ALL', '/items/worklab', null, 'fas fa-briefcase', null, false, 60, Role.ROLE_READER),
    new Menu (63, 'MENU.WORKLABS.FAVORITES', '/items/worklab/favorites', null, 'fas fa-star', null, false, 60, Role.ROLE_READER),
    new Menu (65, 'MENU.WORKLABS.RECOMMENDED', '/items/worklab/recommendations', null, 'far fa-lightbulb', null, false, 60, Role.ROLE_READER),
    new Menu (66, 'MENU.WORKLABS.CREATIONS', '/items/worklab/my-creations', null, 'fas fa-pencil-ruler', null, false, 60, Role.ROLE_CONTRIBUTOR),
    new Menu (67, 'MENU.WORKLABS.MODIFICATIONS', '/items/worklab/my-modifications', null, 'fas fa-pen', null, false, 60, Role.ROLE_CONTRIBUTOR),
    new Menu (68, 'MENU.PAGES.TOPICS', '/items/worklab/my-comments', null, 'fas fa-comments', null, false, 60, Role.ROLE_READER),

    /* new Menu (90, 'MENU.TAGS.MANAGEMENT', '/tags/management', null, 'fas fa-tag', null, false, 0, Role.ROLE_KB_ADMIN), */

    new Menu (101, 'MENU.PAGES.MAIN', '/items/page/', null, 'fas fa-file-alt', null, false, 0, Role.ROLE_PUBLIC),
    new Menu (102, 'MENU.MEMBERS.MAIN', '/items/member/', null, 'fas fa-user', null, false, 0, Role.ROLE_READER),
    new Menu (103, 'MENU.WORKLABS.MAIN', '/items/worklab/', null, 'fas fa-briefcase', null, false, 0, Role.ROLE_PUBLIC),
];
