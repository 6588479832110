import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { Kb } from '@models';
import { ServiceKb } from '@services';

@Component({
    selector: 'kb-description',
    templateUrl: './kb-description.component.html',
    styleUrls: ['./kb-description.component.scss']
})
export class KbDescriptionComponent {

    private _kb: Kb;
    @Input()
    set kb(value: Kb) {
        this._kb = value;
        this._setAttributes();
    }
    get kb(): Kb {
        return this._kb;
    }

    private _editionMode: boolean;
    @Input()
    set editionMode(value: boolean) {
        this._editionMode = value;
    }
    get editionMode(): boolean {
        return this._editionMode;
    }

    public fc: FormControl;
    public defaultDescription: string;

    constructor(
        private serviceKb: ServiceKb
    ) {
        this.defaultDescription = '';
    }

    textChanged(){
        this._kb.description = this.fc.value;
        this.serviceKb.kb = this._kb;
    }

    private _setAttributes() {
        const value = this._kb.description || this.defaultDescription;
        this.fc = new FormControl(value, []);
    }
}
