import { Component, OnInit, OnDestroy } from '@angular/core';
import { finalize, first, takeUntil } from 'rxjs/operators';
import { AppSettings } from '@app/app.settings';

import { Kb } from '@models';
import { ServicePreferences } from '@services';
import { Subject } from 'rxjs';

@Component({
    selector: 'preferences-old-title-as-alias',
    templateUrl: './preferences-old-title-as-alias.component.html',
    styleUrls: ['./preferences-old-title-as-alias.component.scss']
})
export class PreferencesOldTitleAsAliasComponent implements OnInit, OnDestroy {

    public saving = false;
    public currentUserValue: boolean;

    private _destroyed$ = new Subject();

    constructor(
        private appSettings: AppSettings,
        private servicePreferences: ServicePreferences
    ) {
    }

    ngOnInit() {
        this.currentUserValue = this.servicePreferences.getPreference('oldTitleAsAlias');
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }

    toggleValue($event) {
        this.currentUserValue = $event.checked;
        this.appSettings.settings.oldTitleAsAlias = $event.checked;

        this.saving = true;
        this.servicePreferences.savePreference({'oldTitleAsAlias': $event.checked}).pipe(
            finalize(() => {
                this.saving = false;
            }),
            first(),
            takeUntil(this._destroyed$)
        ).subscribe();
    }
}
