import { Component, Input, Output } from '@angular/core';

import { ServiceMembers, ServiceTextAnalyser, ServiceSecurity } from '@services';
import { Role, Item, Page, Member, Worklab } from '@models';
import { LinkedItemsComponent } from '../linked-items.component';

@Component({
    selector: 'modifications',
    templateUrl: './modifications.component.html',
    styleUrls: ['./modifications.component.scss']
})
export class ModificationsComponent extends LinkedItemsComponent {
    public canView = false;
    private _item: Page | Member | Worklab;
    @Input()
    set item(value: Page | Member | Worklab) {
        this._item = value;
        this.handleEditionMode(this._editionMode);
    }
    get item(): Page | Member | Worklab {
        return this._item;
    }

    constructor(
        private serviceMembers: ServiceMembers,
        private serviceSecurity: ServiceSecurity,
        protected serviceTextAnalyser: ServiceTextAnalyser
    ) {
        super(serviceMembers, serviceTextAnalyser);
        this.field = 'lastModificationDate';
        this.canView = this.serviceSecurity.hasMinimumRole(Role.ROLE_READER);
    }

    protected handleOrder(orderDirection: 'asc' | 'desc') {
        if (this.item instanceof Page || this.item instanceof Member || this.item instanceof Worklab) {
            this.item.modifiers = this.serviceMembers.sortArray(this.item.modifiers, orderDirection);
        }
    }
}
