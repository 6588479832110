import * as _ from 'lodash';
import { Injectable, Injector } from '@angular/core'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'
import { CommonService } from '@services/common.service'
import { ServiceKb } from '@services/kbs.service'
import { DashboardsSettingsPeriods } from '../models/dashboard-settings.model'
import * as moment from 'moment'
import { ServiceSecurity } from './security.service'

/**
 * ServicePreferences
 * @export
 */
@Injectable({
  providedIn: 'root'
})
export class ServicePreferences extends CommonService {

  /**
   * Creates an instance of ServicePreferences.
   * @param {Injector} injector
   * @param {ServiceSecurity} serviceSecurity
   * @param {ServiceKb} serviceKb
   */
  constructor(
    protected injector: Injector,
    private serviceSecurity: ServiceSecurity,
    private serviceKb: ServiceKb
  ) {
    super(injector);
  }

  /**
   * This method is called when the service is injected into the app and he get the preferences of the current user.
   * @param {string} key The key of the preference
   * @returns 
   * @deprecated
   */
  getPreference(key: string): any {
    const kb = this.serviceKb.kb;
    const user = this.serviceSecurity.user;
    const preferences = this.serviceSecurity.user.preferences || {};
    const defaultPreferenceValue = _.get(kb, 'default' + _.upperFirst(key));

    let preference: any;
    if (key === 'dateTimeFormatMoment' || key === 'dateFormatMoment' || key === 'timeFormatMoment') {
      preference = preferences['dateTimeFormat'] || _.get(user, 'preferences.dateTimeFormat', this.appSettings.settings.dateTimeFormat);
      if (preference === 'dd/MM/yyyy HH:mm:ss') {
        preference = (key === 'dateTimeFormatMoment') ? 'DD/MM/YYYY HH:mm:ss' : ((key === 'dateFormatMoment') ? 'DD/MM/YYYY' : 'HH:mm:ss');
      } else if (preference === 'dd MMMM yyyy HH:mm:ss') {
        preference = (key === 'dateTimeFormatMoment') ? 'DD MMMM YYYY HH:mm:ss' : ((key === 'dateFormatMoment') ? 'DD MMMM YYYY' : 'HH:mm:ss');
      } else if (preference === 'M/d/yy h:mm:ss a') {
        preference = (key === 'dateTimeFormatMoment') ? 'M/D/YY h:mm:ss a' : ((key === 'dateFormatMoment') ? 'M/D/YY' : 'h:mm:ss a');
      } else if (preference === 'MMMM d, yyyy h:mm:ss a') {
        preference = (key === 'dateTimeFormatMoment') ? 'MMMM DD, YYYY h:mm:ss a' : ((key === 'dateFormatMoment') ? 'MMMM DD, YYYY' : 'h:mm:ss a');
      }
    } else if (key === 'dashboards.creationsPerDate' || key === 'dashboards.topicsPerDate' || key === 'dashboards.editionsPerDate') {
      preference = _.get(preferences, key) || defaultPreferenceValue || _.get(this, 'appSettings.settings.' + key);

      const period = preference.period || DashboardsSettingsPeriods.ANY;
      if (period === DashboardsSettingsPeriods.LAST_3_MONTHS) {
        preference.start = moment().subtract(2, 'months').format('YYYY-MM-01');
        preference.end = moment().format('YYYY-MM-DD');
      } else if (period === DashboardsSettingsPeriods.LAST_6_MONTHS) {
        preference.start = moment().subtract(5, 'months').format('YYYY-MM-01');
        preference.end = moment().format('YYYY-MM-DD');
      } else if (period === DashboardsSettingsPeriods.LAST_12_MONTHS) {
        preference.start = moment().subtract(11, 'months').format('YYYY-MM-01');
        preference.end = moment().format('YYYY-MM-DD');
      } else if (period === DashboardsSettingsPeriods.BEGINNING) {
        delete preference.start;
        delete preference.end;
      }
    } else if (key === 'dashboards.mostActiveMembers') {
      preference = _.get(preferences, key) || defaultPreferenceValue || _.get(this, 'appSettings.settings.' + key);
      const period = preference.period || DashboardsSettingsPeriods.ANY;
      if (period === DashboardsSettingsPeriods.LAST_3_MONTHS) {
        preference.start = moment().subtract(3, 'months').format('YYYY-MM-DD');
        preference.end = moment().format('YYYY-MM-DD');
      } else if (period === DashboardsSettingsPeriods.LAST_6_MONTHS) {
        preference.start = moment().subtract(6, 'months').format('YYYY-MM-DD');
        preference.end = moment().format('YYYY-MM-DD');
      } else if (period === DashboardsSettingsPeriods.LAST_12_MONTHS) {
        preference.start = moment().subtract(12, 'months').format('YYYY-MM-DD');
        preference.end = moment().format('YYYY-MM-DD');
      } else if (period === DashboardsSettingsPeriods.BEGINNING) {
        delete preference.start;
        delete preference.end;
      }
    } else if (key === 'keepFilters' || key === 'emailOnTopic' || key === 'allNotifications' || key === 'oldTitleAsAlias') {
      preference = _.get(preferences, key);
      if (preference !== false && preference !== true) {
        preference = defaultPreferenceValue || _.get(this, 'appSettings.settings.' + key);
      }
    } else if (key === 'language') {
      preference = _.get(preferences, key) || defaultPreferenceValue || _.get(this, 'appSettings.settings.' + key);
    } else {
      preference = _.get(preferences, key) || defaultPreferenceValue || _.get(this, 'appSettings.settings.' + key);
    }
    return preference;
  }

  /**
   * This method is called when the preferences are saved.
   * @param {any} preference { key: value }
   * @param {boolean} [protagateUser=false] If true, the user is updated with the new preference.
   * @returns {Observable<void | null>} An Observable that will emit null when the preference is saved.
   */
  savePreference(preference: any, protagateUser: boolean = false): Observable<void | null> {
    const serviceSecurity = this.serviceSecurity;
    const isUserAnonymous = this.serviceSecurity.isCurrentUserAnonymous();
    if (isUserAnonymous) {
      if (protagateUser === true) {
        const user = serviceSecurity.user;
        user.preferences = Object.assign({}, user.preferences, preference);
        if (protagateUser === true) {
          serviceSecurity.user = user;
        } else {
          serviceSecurity.setLocalStorageUser(user);
        }
      }
      return of(null);
    } else {
      return this.http.post(this.urlApi + 'users/preferences', preference).pipe(
        map(
          () => {
            const user = serviceSecurity.user;
            user.preferences = Object.assign({}, user.preferences, preference);
            if (protagateUser === true) {
              serviceSecurity.user = user;
            } else {
              serviceSecurity.setLocalStorageUser(user);
            }
          }
        )
      );
    }
  }
}
