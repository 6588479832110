import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { throwError } from 'rxjs';
import { ServiceToaster } from './toasters.service';

@Injectable({
    providedIn: 'root'
})
export class ServiceErrors {

    constructor(
        private serviceToaster: ServiceToaster,
        private serviceTranslate: TranslateService
    ) { }

    translateMessage(error, key?) {
        key = key + '.' || '';
        const code = _.get(error, 'error.code') || _.get(error, 'code') || _.get(error, 'status');
        const message = _.get(error, 'error.message') || _.get(error, 'message');
        const errorExtraValue = _.get(error, 'value') || '';

        let translatedMessage = this.serviceTranslate.instant(key + 'ERRORS.' + message, {value: errorExtraValue});
        if (translatedMessage === key + 'ERRORS.' + message) {
            if (code) {
                const translatedCodeMessage = this.serviceTranslate.instant(key + 'ERRORS.ERROR_' + code);
                if (translatedCodeMessage !== key + 'ERRORS.ERROR_' + code) {
                    translatedMessage = translatedCodeMessage;
                } else {
                    const generalTranslatedMessage = this.serviceTranslate.instant('ERRORS.' + message);
                    if (generalTranslatedMessage === 'ERRORS.' + message) {
                        if (code === 400) {
                            translatedMessage = this.serviceTranslate.instant('ERRORS.BAD_REQUEST.TEXT');
                        } else if (code === 401) {
                            translatedMessage = this.serviceTranslate.instant('ERRORS.NOT_AUTHORIZED.TEXT');
                        } else if (code === 403) {
                            translatedMessage = this.serviceTranslate.instant('ERRORS.FORBIDDEN.TEXT');
                        } else if (code === 404) {
                            translatedMessage = this.serviceTranslate.instant('ERRORS.NOT_FOUND.TEXT');
                        } else if (code === 500) {
                            translatedMessage = this.serviceTranslate.instant('ERRORS.INTERNAL_ERROR.TEXT');
                        } else {
                            translatedMessage = message || this.serviceTranslate.instant('FAILED');
                        }
                    } else {
                        translatedMessage = generalTranslatedMessage;
                    }
                }
            } else {
                translatedMessage = message || this.serviceTranslate.instant('FAILED');
            }
        }

        return translatedMessage;
    }

    handleError(key: string, error, showToaster: boolean = true) {
        if (error.status !== 0) {
            this.serviceToaster.clearAll();
            console.error(key, error);
            let translatedMessage = '';
            if (_.isArray(_.get(error, 'error'))) {
                const self = this;
                _.forEach(error.error, function(e) {
                    translatedMessage += self.translateMessage(e, key) + '<br />';
                });
            } else {
                translatedMessage = this.translateMessage(error, key);
            }
            let translatedTitle = this.serviceTranslate.instant(key + '.TITLE');
            if (translatedTitle === key + '.TITLE') {
                translatedTitle = null;
            }
            console.error(translatedMessage);
            if (showToaster === true) {
                this.serviceToaster.error(translatedMessage, translatedTitle);
            }
        }
    }

    public throwError(key: string, error, showToaster: boolean = true) {
        this.handleError(key, error, showToaster);
        return throwError(error);
    }
}
