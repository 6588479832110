import { NgModule } from '@angular/core';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FlexLayoutModule } from '@angular/flex-layout';

import { RouterModule } from '@angular/router';

import { OverlayContainer } from '@angular/cdk/overlay';

import { MatTableExporterModule } from 'mat-table-exporter';

import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    wheelPropagation: true,
    suppressScrollX: true
};

import {
    HttpClientModule,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HTTP_INTERCEPTORS
} from '@angular/common/http';

import {
    MatBadgeModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatPaginatorIntl,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    MatStepperModule
} from '@angular/material';

// Third party libs
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';

import { TranslateModule } from '@ngx-translate/core';

import { EcoFabSpeedDialModule } from '@ecodev/fab-speed-dial';

import { CookieService } from 'ngx-cookie-service';

// i2Kn components
import { BreadcrumbComponent } from '@shared/components/breadcrumb/breadcrumb.component';

import { ButtonCancelComponent, DialogConfirmationCancelComponent } from '@shared/components/button-cancel/index';

import { ButtonBecomeContributorComponent, DialogBecomeContributorComponent } from '@shared/components/button-become-contributor/index';

import { CurrentTimeComponent } from '@shared/components/current-time/current-time.component';

import { ErrorComponent } from '@shared/components/error/error.component';

import { FabSpeedDialComponent } from '@shared/components/fab-speed-dial/fab-speed-dial.component';

import { SurveyComponent, DialogSurveyComponent } from '@shared/components/survey/survey.component';

import {
    FiltersComponent,
    FiltersByGroupComponent,
    FiltersByTitleComponent,
    FiltersBySocietyComponent,
    FiltersByEmailComponent,
    FiltersByTagComponent,
    FiltersByExpertComponent,
    FiltersByIsExpertComponent,
    FiltersByIsInactiveMemberComponent,
    FiltersByRoleComponent,
    FiltersByHasInactiveExpertComponent,
    FiltersByForumComponent,
    FiltersByModifierComponent,
    FiltersByCreatorComponent,
    FiltersByExpertNumberComponent,
    FiltersByEditionDateComponent,
    FiltersByCreationDateComponent,
    FiltersByPageStatusComponent,
    FiltersByLinkTypeComponent,
    FiltersOrderByComponent,
    MapFiltersComponent,
    FiltersByDynamicLinksComponent,
    FiltersByClusterComponent,
    FiltersByKbComponent,
    FiltersKeywordsViewComponent,
    FiltersByKeywordsScoreComponent,
    FiltersByKeywordsLinksCountComponent
} from '@shared/components/filters/index';

import {
    DialogLatexComponent,
    DialogKekuleComponent,
    DialogRecommendationsOnTextSelectionComponent
} from '@shared/components/froala/index';

import { FullScreenComponent } from '@shared/components/fullscreen/fullscreen.component';

import { HistoryComponent } from '@shared/components/history/history.component';

import {
    ButtonCreateItemComponent,
    ButtonDuplicateItemComponent, DialogCopyItemComponent, 
    ButtonEditItemComponent,
    ButtonExportItemsComponent, DialogExportItemsComponent,
    ButtonGoToForumComponent,
    ButtonRemoveItemComponent, DialogRemoveItemConfirmationComponent,
    ButtonSaveItemComponent,
    ButtonSaveItemAndExitComponent,
    ButtonWorklabToPageComponent, DialogWorklabToPageConfirmationComponent,
    EditionWarningComponent,
    EmailComponent,
    GroupComponent,
    RoleInDatabaseComponent,
    ProsimComponent, DialogProsimElementComponent, DialogProsimListingComponent, DialogProsimGraphComponent, 
    IsExpertComponent,
    IsFollowingComponent,
    IsParticipantComponent,
    IsPrivateComponent,
    IsProtectedComponent,
    ItemContentComponent,
    ItemTitleComponent,
    ItemTitleInlineComponent,
    DynamicLinkComponent, DYNAMIC_LINK_INJECTION_TOKEN,
    PageStatusComponent, DialogPageStatusComponent,
    AliasesComponent,
    AssociatedPagesOutComponent,
    CreatorsComponent,
    DocumentsComponent,
    ExpertisesComponent,
    ExpertsComponent,
    FollowedMembersComponent,
    FollowedPagesComponent,
    FollowedWorklabsComponent,
    FollowersComponent,
    LinkedPagesToWorklabComponent,
    LinkedWorklabsComponent,
    ModificationsComponent, DialogModificationsComponent,
    ParticipantsComponent,
    TagsComponent, TagsTreeComponent, 
    RecommendationsComponent,
    ScoreComponent,
    TopicsComponent,
    UserActionsComponent
} from '@shared/components/items/index';

import {
    ItemsListComponent,
    ItemsAsListComponent,
    ItemsAsTableComponent,
    ItemsAsTilesComponent,
    ItemAsMapComponent,
    ItemKeywordsAsMapComponent,
    ItemsAsMapComponent,
    ItemsCounterComponent,
    ItemsToolbarComponent,
    CustomMatPaginatorIntl
} from '@shared/components/items-list/index';

import {
    KbConfidentialityAgreementComponent,
    KbDescriptionComponent,
    KbIsPublicComponent,
    KbIsVisibleComponent,
    KbNeedsConfidentialityAgreementComponent,
    KbNeedsTokenComponent,
    KbNewsPopupComponent,
    KbSkinsComponent,
    ButtonSaveKbComponent,
    KbLogoComponent,
    ShowAllSocietiesComponent,
    SocietyLogoComponent
} from '@shared/components/kbs/index';

import { VerticalMenuComponent } from '@shared/components/menu/vertical-menu/vertical-menu.component';

import { NotificationComponent } from '@shared/components/notification/notification.component';

import { NotificationsComponent } from '@shared/components/notifications/notifications.component';

import { NewsPopupComponent } from '@shared/components/news-popup/news-popup.component';


import {
    PreferencesComponent,
    PreferencesAllNotificationsComponent,
    PreferencesSkinComponent,
    PreferencesLanguageComponent,
    PreferencesDatetimeFormatComponent,
    PreferencesDefaultListingViewComponent,
    PreferencesDefaultListingViewOnItemComponent,
    PreferencesEmailOnTopicComponent,
    PreferencesKeepFiltersComponent,
    PreferencesOldTitleAsAliasComponent,
    PreferencesSummaryEmailFrequencyComponent,
    PreferencesTimezoneComponent,
    PreferencesDetailComponent,
    DialogPreferencesDetailComponent,
    PreferencesTilesComponent,
    PreferencesSummariesComponent,
    DialogPreferencesListingsComponent,
    PreferencesTableComponent
} from '@shared/components/preferences/index';

import { SearchBarComponent } from '@shared/components/search-bar/search-bar.component';

import { SidenavComponent } from '@shared/components/sidenav/sidenav.component';

import { PictureItemComponent } from '@shared/components/picture-item/picture-item.component';

import { UserMenuComponent } from '@shared/components/user-menu/user-menu.component';

import {
    AuthorizationsComponent,
    ButtonImportUsersComponent,
    DialogImportUsersComponent,
    PasswordComponent,
    RoleComponent,
    DialogNewMemberConfirmationComponent
} from '@shared/components/users/index';

import { Ng5SliderModule } from 'ng5-slider';

// Natural language processing
import {
  ResponseIaComponent
} from '@shared/components/response-ia/response-ia.component';
import {
  ResumeIaComponent
} from '@shared/components/resume-ia/resume-ia.component';
import {
  LinksResponseIaComponent
} from '@shared/components/links-response-ia/links-response-ia.component';
import {
  ItemsResponseIaComponent
} from '@shared/components/items-response-ia/items-response-ia.component';

// Directives
import { DirectivesModule } from './directives/directives.module';

// Pipes
import { PipesModule } from './pipes/pipes.module';

// Interceptors
import { JwtInterceptor } from '@interceptors/jwt.interceptor';
import { RefreshTokenInterceptor } from '@interceptors/refresh-token.interceptor';
import { ErrorInterceptor } from '@interceptors/error.interceptor';
import { KbTagsComponent } from './components/kbs/kb-tags/kb-tags.component';

@NgModule({
    imports: [
        NgbModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        RouterModule,
        PerfectScrollbarModule,
        FroalaEditorModule.forRoot(), FroalaViewModule.forRoot(),

        HttpClientModule,
        EcoFabSpeedDialModule,

        DirectivesModule,
        PipesModule,

        MatBadgeModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTableExporterModule,
        MatTabsModule,
        MatToolbarModule,
        MatTreeModule,
        MatTooltipModule,
        MatStepperModule,

        TranslateModule,
        Ng5SliderModule
    ],
    exports: [
        NgbModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        RouterModule,
        PerfectScrollbarModule,
        FroalaEditorModule, FroalaViewModule,


        EcoFabSpeedDialModule,

        BreadcrumbComponent,
        SearchBarComponent,
        FabSpeedDialComponent,
        SurveyComponent, DialogSurveyComponent,
        FullScreenComponent,
        HistoryComponent,
        NotificationComponent,
        NotificationsComponent,
        NewsPopupComponent,
        PreferencesComponent,
        PreferencesAllNotificationsComponent,
        PreferencesSkinComponent,
        PreferencesLanguageComponent,
        PreferencesDatetimeFormatComponent,
        PreferencesDefaultListingViewComponent,
        PreferencesDefaultListingViewOnItemComponent,
        PreferencesEmailOnTopicComponent,
        PreferencesKeepFiltersComponent,
        PreferencesOldTitleAsAliasComponent,
        PreferencesSummaryEmailFrequencyComponent,
        PreferencesTimezoneComponent,
        PreferencesDetailComponent,
        PreferencesTilesComponent,
        PreferencesSummariesComponent,
        PreferencesTableComponent,

        GroupComponent,
        RoleInDatabaseComponent,
        ProsimComponent,
        ScoreComponent,
        VerticalMenuComponent,
        SidenavComponent,
        UserMenuComponent,

        FiltersComponent,
        FiltersByGroupComponent,
        FiltersByTitleComponent,
        FiltersBySocietyComponent,
        FiltersByEmailComponent,
        FiltersByTagComponent,
        FiltersByExpertComponent,
        FiltersByIsExpertComponent,
        FiltersByIsInactiveMemberComponent,
        FiltersByRoleComponent,
        FiltersByHasInactiveExpertComponent,
        FiltersByForumComponent,
        FiltersByModifierComponent,
        FiltersByCreatorComponent,
        FiltersByExpertNumberComponent,
        FiltersByEditionDateComponent,
        FiltersByCreationDateComponent,
        FiltersByPageStatusComponent,
        FiltersByLinkTypeComponent,
        FiltersOrderByComponent,
        MapFiltersComponent,
        FiltersByDynamicLinksComponent,
        FiltersByClusterComponent,
        FiltersByKbComponent,
        FiltersKeywordsViewComponent,
        FiltersByKeywordsScoreComponent,
        FiltersByKeywordsLinksCountComponent,

        ButtonCancelComponent,
        ButtonBecomeContributorComponent,
        PictureItemComponent,
        CurrentTimeComponent,

        DirectivesModule,
        PipesModule,

        MatAutocompleteModule,
        MatButtonModule,
        MatBadgeModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTableExporterModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        MatStepperModule,

        // Items component
        ButtonCreateItemComponent,
        ButtonDuplicateItemComponent, DialogCopyItemComponent,
        ButtonEditItemComponent,
        ButtonExportItemsComponent, DialogExportItemsComponent,
        ButtonGoToForumComponent,
        ButtonRemoveItemComponent, DialogRemoveItemConfirmationComponent,
        ButtonSaveItemComponent,
        ButtonSaveItemAndExitComponent,
        ButtonWorklabToPageComponent, DialogWorklabToPageConfirmationComponent,
        EditionWarningComponent,
        EmailComponent,
        GroupComponent,
        RoleInDatabaseComponent,
        ProsimComponent,
        IsExpertComponent,
        IsFollowingComponent,
        IsParticipantComponent,
        IsPrivateComponent,
        IsProtectedComponent,
        ItemContentComponent,
        ItemTitleComponent,
        ItemTitleInlineComponent,
        DynamicLinkComponent,
        PageStatusComponent, DialogPageStatusComponent,
        AliasesComponent,
        AssociatedPagesOutComponent,
        CreatorsComponent,
        DocumentsComponent,
        ExpertisesComponent,
        ExpertsComponent,
        FollowedMembersComponent,
        FollowedPagesComponent,
        FollowedWorklabsComponent,
        FollowersComponent,
        LinkedPagesToWorklabComponent,
        LinkedWorklabsComponent,
        ModificationsComponent, DialogModificationsComponent,
        ParticipantsComponent,
        TagsComponent, TagsTreeComponent,
        RecommendationsComponent,
        ScoreComponent,
        TopicsComponent,
        UserActionsComponent,

        // ItemsList components
        ItemsListComponent,
        ItemsAsListComponent,
        ItemsAsTableComponent,
        ItemsAsTilesComponent,
        ItemAsMapComponent,
        ItemKeywordsAsMapComponent,
        ItemsAsMapComponent,
        ItemsCounterComponent,
        ItemsToolbarComponent,

        // Users components
        AuthorizationsComponent,
        ButtonImportUsersComponent,
        DialogImportUsersComponent,
        PasswordComponent,
        RoleComponent,
        DialogNewMemberConfirmationComponent,

        // Kb components
        KbConfidentialityAgreementComponent,
        KbDescriptionComponent,
        KbIsPublicComponent,
        KbIsVisibleComponent,
        KbNeedsConfidentialityAgreementComponent,
        KbNeedsTokenComponent,
        KbNewsPopupComponent,
        KbSkinsComponent,
        ButtonSaveKbComponent,
        KbLogoComponent,
        ShowAllSocietiesComponent,
        SocietyLogoComponent,

        // Natural language processing
        ResponseIaComponent,
        ResumeIaComponent,
        LinksResponseIaComponent,
        ItemsResponseIaComponent,

        ErrorComponent,

        TranslateModule,
        Ng5SliderModule, KbTagsComponent
    ],
    declarations: [
        BreadcrumbComponent,
        SearchBarComponent,
        FabSpeedDialComponent,
        SurveyComponent, DialogSurveyComponent,
        FullScreenComponent,
        HistoryComponent,
        NotificationComponent,
        NotificationsComponent,
        NewsPopupComponent,
        PreferencesComponent,
        PreferencesAllNotificationsComponent,
        PreferencesSkinComponent,
        PreferencesLanguageComponent,
        PreferencesDatetimeFormatComponent,
        PreferencesDefaultListingViewComponent,
        PreferencesDefaultListingViewOnItemComponent,
        PreferencesEmailOnTopicComponent,
        PreferencesKeepFiltersComponent,
        PreferencesOldTitleAsAliasComponent,
        PreferencesSummaryEmailFrequencyComponent,
        PreferencesTimezoneComponent,
        PreferencesDetailComponent,
        PreferencesTilesComponent,
        PreferencesSummariesComponent,
        PreferencesTableComponent,

        GroupComponent,
        RoleInDatabaseComponent,
        ProsimComponent, DialogProsimElementComponent, DialogProsimListingComponent, DialogProsimGraphComponent, 
        ScoreComponent,
        VerticalMenuComponent,
        SidenavComponent,
        UserMenuComponent,

        FiltersComponent,
        FiltersByGroupComponent,
        FiltersByTitleComponent,
        FiltersBySocietyComponent,
        FiltersByEmailComponent,
        FiltersByTagComponent,
        FiltersByExpertComponent,
        FiltersByIsExpertComponent,
        FiltersByIsInactiveMemberComponent,
        FiltersByRoleComponent,
        FiltersByHasInactiveExpertComponent,
        FiltersByForumComponent,
        FiltersByModifierComponent,
        FiltersByCreatorComponent,
        FiltersByExpertNumberComponent,
        FiltersByEditionDateComponent,
        FiltersByCreationDateComponent,
        FiltersByPageStatusComponent,
        FiltersByLinkTypeComponent,
        FiltersOrderByComponent,
        MapFiltersComponent,
        FiltersByDynamicLinksComponent,
        FiltersByClusterComponent,
        FiltersByKbComponent,
        FiltersKeywordsViewComponent,
        FiltersByKeywordsScoreComponent,
        FiltersByKeywordsLinksCountComponent,

        ButtonCancelComponent, DialogConfirmationCancelComponent,
        ButtonBecomeContributorComponent, DialogBecomeContributorComponent,
        PictureItemComponent,
        CurrentTimeComponent,
        DialogPreferencesDetailComponent,
        DialogPreferencesListingsComponent,

        // Items component
        ButtonCreateItemComponent,
        ButtonDuplicateItemComponent, DialogCopyItemComponent,
        ButtonEditItemComponent,
        ButtonExportItemsComponent, DialogExportItemsComponent,
        ButtonGoToForumComponent,
        ButtonRemoveItemComponent, DialogRemoveItemConfirmationComponent,
        ButtonSaveItemComponent,
        ButtonSaveItemAndExitComponent,
        ButtonWorklabToPageComponent, DialogWorklabToPageConfirmationComponent,
        EditionWarningComponent,
        EmailComponent,
        GroupComponent,
        RoleInDatabaseComponent,
        ProsimComponent,
        IsExpertComponent,
        IsFollowingComponent,
        IsParticipantComponent,
        IsPrivateComponent,
        IsProtectedComponent,
        ItemContentComponent,
        ItemTitleComponent,
        ItemTitleInlineComponent,
        DynamicLinkComponent,
        PageStatusComponent, DialogPageStatusComponent,
        AliasesComponent,
        AssociatedPagesOutComponent,
        CreatorsComponent,
        DocumentsComponent,
        ExpertisesComponent,
        ExpertsComponent,
        FollowedMembersComponent,
        FollowedPagesComponent,
        FollowedWorklabsComponent,
        FollowersComponent,
        LinkedPagesToWorklabComponent,
        LinkedWorklabsComponent,
        ModificationsComponent, DialogModificationsComponent,
        ParticipantsComponent,
        TagsComponent, TagsTreeComponent,
        RecommendationsComponent,
        ScoreComponent,
        TopicsComponent,
        UserActionsComponent,

        // ItemsList components
        ItemsListComponent,
        ItemsAsListComponent,
        ItemsAsTableComponent,
        ItemsAsTilesComponent,
        ItemAsMapComponent,
        ItemKeywordsAsMapComponent,
        ItemsAsMapComponent,
        ItemsCounterComponent,
        ItemsToolbarComponent,

        // User components
        AuthorizationsComponent,
        ButtonImportUsersComponent,
        DialogImportUsersComponent,
        PasswordComponent,
        RoleComponent,
        DialogNewMemberConfirmationComponent,

        // Kb components
        KbConfidentialityAgreementComponent,
        KbDescriptionComponent,
        KbIsPublicComponent,
        KbIsVisibleComponent,
        KbNeedsConfidentialityAgreementComponent,
        KbNeedsTokenComponent,
        KbNewsPopupComponent,
        KbSkinsComponent,
        ButtonSaveKbComponent,
        KbLogoComponent,
        ShowAllSocietiesComponent,
        SocietyLogoComponent,

        // Buttons froala
        DialogRecommendationsOnTextSelectionComponent,
        DialogLatexComponent,
        DialogKekuleComponent,

        // Natural language processing
        ResponseIaComponent,
        ResumeIaComponent,
        LinksResponseIaComponent,
        ItemsResponseIaComponent,

        ErrorComponent,
        KbTagsComponent
    ],
    providers: [
        CookieService,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
        { provide: DYNAMIC_LINK_INJECTION_TOKEN, useValue: {} },
        { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl }
    ],
    entryComponents: [
        DynamicLinkComponent,
        VerticalMenuComponent,
        DialogCopyItemComponent,
        DialogSurveyComponent,
        DialogPageStatusComponent,
        DialogModificationsComponent,
        DialogExportItemsComponent,
        DialogImportUsersComponent,
        DialogConfirmationCancelComponent,
        DialogNewMemberConfirmationComponent,
        DialogRemoveItemConfirmationComponent,
        DialogWorklabToPageConfirmationComponent,
        DialogBecomeContributorComponent,
        DialogRecommendationsOnTextSelectionComponent,
        DialogLatexComponent,
        DialogKekuleComponent,
        TagsTreeComponent,
        DialogPreferencesDetailComponent,
        DialogPreferencesListingsComponent,
        DialogProsimElementComponent, DialogProsimListingComponent, DialogProsimGraphComponent
    ]
})
export class SharedModule {
}
