import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, Input, EventEmitter, Output } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { takeUntil, finalize, first, filter } from 'rxjs/operators';
import * as moment from 'moment';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

import { Item, UserAction, UserActionsFilter, UserActionsGrouped } from '@models';
import { ServiceDatastore, ServiceUserActions, ServiceTextAnalyser } from '@services';
import { DialogModificationsComponent } from './dialog-modifications/dialog-modifications.component';

const INITIAL_USER_ACTIONS_FILTERS: UserActionsFilter = {
    add: true,
    remove: true,
    update: true
};

@Component({
    selector: 'user-actions',
    templateUrl: './user-actions.component.html',
    styleUrls: ['./user-actions.component.scss']
})
export class UserActionsComponent implements OnInit, OnDestroy {

    @Output() newVersionChoosen = new EventEmitter();

    private _item: Item;
    @Input()
    set item(item: Item) {
        this._item = item;
    }
    get item(): Item {
        return this._item;
    }

    private _sortedUserActions: Array<UserAction>;
    private _contentModifications: Array<UserAction>;
    private _destroyed$ = new Subject();

    public loading = false;
    public filtersAreDirty = false;
    public filters: UserActionsFilter = _.clone(INITIAL_USER_ACTIONS_FILTERS);
    public groupedUserActions: Array<UserActionsGrouped>;

    constructor(
        private _dialog: MatDialog,
        private serviceDatastore: ServiceDatastore,
        private serviceTextAnalyser: ServiceTextAnalyser,
        private serviceUserActions: ServiceUserActions
    ) {
    }

    ngOnInit() {
        this.groupedUserActions = [];
        this.serviceDatastore.dataStoreObservable
            .pipe(
                takeUntil(this._destroyed$)
            ).subscribe(() => {
                this._loadUserActions();
            });
    }

    ngOnDestroy() {
        this._dialog.closeAll();
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }

    trackUserActionByDate(index: number, o: UserActionsGrouped) {
        return o ? o.date : index;
    }

    trackUserActions(index: number, o: UserAction) {
        return o ? ((o.creator) ? o.action + o.creation_date + o.creator.id : o.action + o.creation_date) : index;
    }

    openDiffModal(userAction: UserAction): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.position = {
            'top': '40px'
        };
        dialogConfig.width = '66%';
        dialogConfig.data = {
            item: this.item,
            userAction: userAction,
            contentModifications: this._contentModifications
        };
        const dialogRef = this._dialog.open(DialogModificationsComponent, dialogConfig);
        dialogRef.componentInstance.versionChoosen.subscribe((choosenContent: string) => {
            this.newVersionChoosen.emit(choosenContent);
            dialogRef.close();
        });
    }

    filterUserActions(): void {
        const serviceTextAnalyser = this.serviceTextAnalyser;
        const filters = this.filters;
        const sortedUserActions = this._sortedUserActions;

        const filteredUserActions = _.filter(sortedUserActions, function(userAction) {
            if (_.get(filters, 'add') === false && userAction.classAction === 'add') {
                return false;
            }
            if (_.get(filters, 'remove') === false && userAction.classAction === 'remove') {
                return false;
            }
            if (_.get(filters, 'update') === false && userAction.classAction === 'update') {
                return false;
            }

            if (_.get(filters, 'start') !== '' && userAction.creation_date < _.get(filters, 'start')) {
                return false;
            }

            if (_.get(filters, 'end') !== '' && userAction.creation_date > _.get(filters, 'end')) {
                return false;
            }

            if (_.get(filters, 'author') !== '') {
                const query = serviceTextAnalyser.removeDiacritics(_.get(filters, 'author', '').toLowerCase());
                const authorTitle = _.get(userAction, 'creator.title', '').toLowerCase();
                const authorTitleWithoutAccent = serviceTextAnalyser.removeDiacritics(authorTitle).toLowerCase();

                if ((authorTitle.indexOf(query) === -1) && (authorTitleWithoutAccent.indexOf(query) === -1)) {
                    return false;
                }
            }
            return true;
        });
        this.filtersAreDirty = !_.isEqual(filters, INITIAL_USER_ACTIONS_FILTERS);
        this.groupedUserActions = this._groupUserActions(filteredUserActions);
    }

    addDate(type: string, event: MatDatepickerInputEvent<Date>): void {
        if (type === 'start') {
            this.filters.start = moment(event.value).format('YYYY-MM-DD');
        } else if (type === 'end') {
            this.filters.end = moment(event.value).format('YYYY-MM-DD');
        }
        this.filterUserActions();
    }

    resetDate(type: string): void {
        if (type === 'start') {
            delete this.filters.start;
        } else if (type === 'end') {
            delete this.filters.end;
        }
        this.filterUserActions();
    }

    resetFilters(): void {
        this.filters = _.clone(INITIAL_USER_ACTIONS_FILTERS);
        this.filterUserActions();
    }

    private _loadUserActions(): void {
        this.loading = true;
        this.serviceUserActions.getAllUserActions<UserAction>(this.item)
            .pipe(
                finalize(() => {
                    this.loading = false;
                }),
                first(),
                takeUntil(this._destroyed$)
            ).subscribe(
                (userActions: UserAction[]) => {
                    const _sortedUserActions = _.orderBy(userActions, ['creation_date'], ['desc']);
                    this._sortedUserActions = _sortedUserActions;

                    this._contentModifications = _.filter(_sortedUserActions, function(o) {
                        return o.field === 'content';
                    });
                    this.groupedUserActions = this._groupUserActions(_sortedUserActions);
                }
            );
    }

    private _groupUserActions(userActions): UserActionsGrouped[] {
        return _.reduce(_.groupBy(userActions, function(o: UserAction) {
            return (o.creation_date) ? o.creation_date.substring(0, 10) : '';
        }), function(result, values, key) {
            result.push({
                date: key,
                values: values
            });
            return result;
        }, []);
    }
}
