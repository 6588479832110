import { Role } from '@src/app/models';

export class Menu {
    constructor(public id: number,
                public title: string,
                public routerLink: string,
                public href: string,
                public icon: string,
                public target: string,
                public hasSubMenu: boolean,
                public parentId: number,
                public minimumRole: Role,
                public isVisible?: boolean
    ) {
        this.isVisible = (isVisible === false) ? false : true;
    }
}
