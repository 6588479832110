import * as _ from 'lodash';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ItemsDictionary, Item, Page, Member, Worklab, Role } from '@models';
import { CommonService } from '@services/common.service';
import { ServiceDatastore } from './datastore.service';
import { ServiceSecurity } from './security.service';

@Injectable({ providedIn: 'root' })
export class ServiceMembers extends CommonService {

    private allEmailsSubject: BehaviorSubject<Array<string>>;
    allEmailsObservable: Observable<Array<string>>;

    constructor(
        protected injector: Injector,
        private serviceSecurity: ServiceSecurity,
        private serviceDatastore: ServiceDatastore
    ) {
        super(injector);

        this.allEmailsSubject = new BehaviorSubject<Array<string>>([]);
        this.allEmailsObservable = this.allEmailsSubject.asObservable();
    }

    get allEmails(): Array<string> {
        return this.allEmailsSubject.value;
    }

    set allEmails(value: Array<string>) {
        this.allEmailsSubject.next(value);
    }

    sortArray(unsortedArray: Member[], orderDirection: 'asc' | 'desc'): Member[] {
        return this.serviceDatastore.sortArray<Member>(unsortedArray, orderDirection);
    }

    fetchAllPotentialLinkedItems<T>(alreadyLinkedItems: T[], currentItem: Page | Member | Worklab): Observable<Item[]> {
        const alreadyLinkedItemsIds = _.map(alreadyLinkedItems, 'id');

        return this.serviceDatastore.dataStoreObservable.pipe(
            /* filter((allItems: Item[]) => allItems !== null), */
            map(
                (allItems: ItemsDictionary) => {
                    let allPotentialItems = _.filter(allItems, function(item: Item) {
                        return item.group === 'Member' && _.indexOf(alreadyLinkedItemsIds, item.id) === -1 && item.id !== currentItem.id;
                    });
                    allPotentialItems = _.orderBy(allPotentialItems, function(potentialItem: Item) {
                        const v = _.toLower(_.get(potentialItem, 'lastname', '') + _.get(potentialItem, 'firstname', ''));
                        return (v === '') ? _.toLower(potentialItem.title) : v;
                    }, ['asc']);
                    return <Member[]> allPotentialItems;
                }
            )
        );
    }

    fetchAllEmails<T>(): Observable<string[]> {
        return this.serviceDatastore.dataStoreObservable.pipe(
            map(
                (allItems: ItemsDictionary) => {
                    const allEmails = _.map(_.filter(allItems, function(o) {
                        return !_.isEmpty((<Member> o).email);
                    }), 'email');

                    this.allEmails = allEmails;
                    return allEmails;
                }
            )
        );
    }

    userCanCreateItem(): boolean {
        const isUserAdmin = this.serviceSecurity.isAdmin();
        return isUserAdmin;
    }

    userCanRemoveItem(item: any): boolean {
        const isUserAdmin = this.serviceSecurity.isAdmin();

        return isUserAdmin;
    }

    userCanChangeItemToPage(member: any): boolean {
        return false;
    }

    userCanModifyItem<T extends Item>(member: T): boolean {
        const isCurrentUser = this.serviceSecurity.isCurrentUser(member);
        const isUserAdmin = this.serviceSecurity.isAdmin();
        const isItemLocked = member.islocked;
        const isAccessGrantedByRole = this.serviceSecurity.hasMinimumRole(Role.ROLE_READER);

        return isUserAdmin || (!isItemLocked && isCurrentUser && isAccessGrantedByRole);
    }

    userCanModifyContent(member: Member): boolean {
        const isUserAdmin = this.serviceSecurity.isAdmin();
        const isItemLocked = member.islocked;
        const isAccessGrantedByRole = this.serviceSecurity.hasMinimumRole(Role.ROLE_CONTRIBUTOR);

        return isUserAdmin || (!isItemLocked && isAccessGrantedByRole);
    }

    userCanModifyEmail(member: Member): boolean {
        const isUserAdmin = this.serviceSecurity.isAdmin();
        const isCreation = _.isUndefined(member.id);

        return isCreation && isUserAdmin;
    }

    userCanModifyFollowedMembers(member: Member): boolean {
        const isUserAdmin = this.serviceSecurity.isAdmin();
        const isItemLocked = member.islocked;
        const isAccessGrantedByRole = this.serviceSecurity.hasMinimumRole(Role.ROLE_CONTRIBUTOR);

        return isUserAdmin || (!isItemLocked && isAccessGrantedByRole);
    }

    userCanModifyFollowedPages(member: Member): boolean {
        const isUserAdmin = this.serviceSecurity.isAdmin();
        const isItemLocked = member.islocked;
        const isAccessGrantedByRole = this.serviceSecurity.hasMinimumRole(Role.ROLE_CONTRIBUTOR);

        return isUserAdmin || (!isItemLocked && isAccessGrantedByRole);
    }

    userCanModifyFollowedWorklabs(member: Member): boolean {
        const isUserAdmin = this.serviceSecurity.isAdmin();
        const isItemLocked = member.islocked;
        const isAccessGrantedByRole = this.serviceSecurity.hasMinimumRole(Role.ROLE_CONTRIBUTOR);

        return isUserAdmin || (!isItemLocked && isAccessGrantedByRole);
    }
}
