import * as _ from 'lodash';
import { Injectable, Injector } from '@angular/core';
import { of, forkJoin, throwError, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { CommonService } from '@services/common.service';
import { HttpParams } from '@angular/common/http';
import { ProsimDynamic, ProsimConstant, Mol, DynamicProperty } from '../models';

@Injectable({ providedIn: 'root' })
export class ServiceProsim extends CommonService {

    private _propertiesCache = {};

    constructor(
        protected injector: Injector
    ) {
        super(injector);
    }

    getMols(): Mol[] {
        return [{
            label: 'Acetylene',
            key: '74-86-2'
        },
        {
            label: 'Ammonia',
            key: '7664-41-7'
        },
        {
            label: 'Argon',
            key: '7440-37-1'
        },
        {
            label: 'Carbon dioxide',
            key: '124-38-9'
        },
        {
            label: 'Carbon monoxide',
            key: '630-08-0'
        },
        {
            label: 'Helium-4',
            key: '7440-59-7'
        },
        {
            label: 'Hydrogen',
            key: '1333-74-0'
        },
        {
            label: 'Krypton',
            key: '7439-90-9'
        },
        {
            label: 'Methane',
            key: '74-82-8'
        },
        {
            label: 'Methanol',
            key: '67-56-1'
        },
        {
            label: 'Neon',
            key: '7440-01-9'
        },
        {
            label: 'Nitric oxide',
            key: '10102-43-9'
        },
        {
            label: 'Nitrogen',
            key: '7727-37-9'
        },
        {
            label: 'Nitrogen dioxide',
            key: '10102-44-0'
        },
        {
            label: 'Nitrous oxide',
            key: '10024-97-2'
        },
        {
            label: 'Oxygen',
            key: '7782-44-7'
        },
        {
            label: 'Ozone',
            key: '10028-15-6'
        },
        {
            label: 'Silane',
            key: '7803-62-5'
        },
        {
            label: 'Water',
            key: '7732-18-5'
        },
        {
            label: 'Xenon',
            key: '7440-63-3'
        }
        ];
    }

    getStaticProperties() {
        return [{
            key: 'TripleDeltaHs',
            label: 'Heat of sublimation at triple point',
            property: 'Molar enthalpy'
        },
        {
            key: 'Tf',
            label: 'Normal melting point',
            property: 'Temperature'
        },
        {
            label: 'Molecular weight',
            key: 'Mw',
            property: 'Molar mass'
        },
        {
            label: 'Refraction index',
            key: 'Ir',
            property: '-'
        },
        {
            label: 'Enthalpy of fusion',
            key: 'DeltaHf',
            property: 'Molar enthalpy'
        },
        {
            label: 'Normal boiling point',
            key: 'Tb',
            property: 'Temperature'
        },
        {
            label: 'Enthalpy of vaporization',
            key: 'DeltaHb',
            property: 'Molar enthalpy'
        },
        {
            label: 'Triple point temperature',
            key: 'TripleT',
            property: 'Temperature'
        },
        {
            label: 'Triple point pressure',
            key: 'TripleP',
            property: 'Pressure'
        },
        {
            label: 'Physical state at 25°C',
            key: 'PState25',
            property: '-'
        },
        {
            label: 'Acentric factor',
            key: 'Omega',
            property: '-'
        },
        {
            label: 'Critical temperature',
            key: 'Tc',
            property: 'Temperature'
        },
        {
            label: 'Critical pressure',
            key: 'Pc',
            property: 'Pressure'
        },
        {
            label: 'Critical volume',
            key: 'Vc',
            property: 'Molar volume'
        },
        {
            label: 'Critical density',
            key: 'Rhoc',
            property: 'Density'
        },
        {
            label: 'Critical compressibility factor',
            key: 'Zc',
            property: '-'
        },
        {
            label: 'Standard state enthalpy of formation',
            key: 'DeltaH0',
            property: 'Molar enthalpy'
        },
        {
            label: 'Standard state Gibbs energy of formation',
            key: 'DeltaG0',
            property: 'Molar enthalpy'
        },
        {
            label: 'Standard state absolute entropy',
            key: 'DeltaS0',
            property: 'Molar entropy'
        }
        ];
    }

    getDynamicProperties() {
        return [{
            label: 'Vaporization enthalpy',
            key: 'VaporizationEnthalpy',
            property: 'Molar enthalpy'
        },
        {
            label: 'Solid specific heat',
            key: 'SolidSpecificHeat',
            property: 'Molar entropy'
        },
        {
            label: 'Liquid specific heat',
            key: 'LiquidSpecificHeat',
            property: 'Molar entropy'
        },
        {
            label: 'Ideal gas specific heat',
            key: 'IdealGasSpecificHeat',
            property: 'Molar entropy'
        },
        {
            label: 'Liquid specific heat at infinite dilution',
            key: 'LiquidSpecificHeatAtInfiniteDilution',
            property: 'Molar entropy'
        },
        {
            label: 'Vapor pressure',
            key: 'VaporPressure',
            property: 'Pressure'
        },
        {
            label: 'Sublimation pressure',
            key: 'SublimationPressure',
            property: 'Pressure'
        },
        {
            label: 'Melting pressure',
            key: 'MeltingPressure',
            property: 'Pressure'
        },
        {
            label: 'Solid thermal conductivity',
            key: 'SolidThermalConductivity',
            property: 'Thermal conductivity'
        },
        {
            label: 'Liquid thermal conductivity',
            key: 'LiquidThermalConductivity',
            property: 'Thermal conductivity'
        },
        {
            label: 'Gas thermal conductivity',
            key: 'GasThermalConductivity',
            property: 'Thermal conductivity'
        },
        {
            label: 'Liquid viscosity',
            key: 'LiquidViscosity',
            property: 'Dynamic viscosity'
        },
        {
            label: 'Gas viscosity',
            key: 'GasViscosity',
            property: 'Dynamic viscosity'
        },
        {
            label: 'Solid density',
            key: 'SolidDensity',
            property: 'Density'
        },
        {
            label: 'Liquid density',
            key: 'LiquidDensity',
            property: 'Density'
        },
        {
            label: 'Surface tension',
            key: 'SurfaceTension',
            property: 'Surface tension'
        },
        {
            label: 'Second Virial coefficient',
            key: '2ndVirialCoefficient',
            property: 'Molar volume'
        },
        {
            label: 'Henry\'s constant',
            key: 'HenryConstant',
            property: 'Henry constant'
        }
        ];
    }

    getTemperatureUnits(): string[]  {
        return ['K', '°C', '°F', '°R'];
    }

    getPropertyUnits(key: string, type: string) {
        const units = _.get(this._propertiesCache, key + '.units');
        if (_.size(units) > 0) {
            return of(units);
        } else {
            let propertyKey;
            if (type === 'dynamic') {
                propertyKey = _.get(_.find(this.getDynamicProperties(), {
                    key: key
                }), 'property');
            } else {
                propertyKey = _.get(_.find(this.getStaticProperties(), {
                    key: key
                }), 'property');
            }

            let params = new HttpParams();
            params = params.append('propertyKey', propertyKey);

            return this.http.get<string[]>(this.urlApi + 'prosim/units', { params }).pipe(
                first(),
                map(
                    (result: string[]) => {
                        return _.get(result, 'data', []);
                    }
                )
            );
        }
    }

    private _getDynamicPropertyParameters(molKey: string, propertyKey: string, temperaturesUnit: string): Observable<DynamicProperty> {
        let units = _.get(this._propertiesCache, propertyKey + '.units');
        let tmin = _.get(this._propertiesCache, propertyKey + '.' + temperaturesUnit + '.tmin');
        let tmax = _.get(this._propertiesCache, propertyKey + '.' + temperaturesUnit + '.tmax');
        let correlation = _.get(this._propertiesCache, propertyKey + '.correlation');
        let coefficients = _.get(this._propertiesCache, propertyKey + '.coefficients');

        if (_.size(units) > 0 && tmin && tmax && correlation && _.size(coefficients) > 0) {
            return of({
                units: units,
                tmin: tmin,
                tmax: tmax,
                correlation: correlation,
                coefficients: coefficients
            });
        } else {
            const property = _.get(_.find(this.getDynamicProperties(), {
                key: propertyKey
            }), 'property');

            let paramsUnits = new HttpParams();
            paramsUnits = paramsUnits.append('propertyKey', property);
            const getUnits = this.http.get<string[]>(this.urlApi + 'prosim/units', { params: paramsUnits });

            if (!tmin || !tmax || !correlation || !coefficients) {
                let paramsDynamicPropertiesParameters = new HttpParams();
                paramsDynamicPropertiesParameters = paramsDynamicPropertiesParameters.append('propertyKey', propertyKey);
                paramsDynamicPropertiesParameters = paramsDynamicPropertiesParameters.append('molKey', molKey);
                paramsDynamicPropertiesParameters = paramsDynamicPropertiesParameters.append('temparturesUnit', temperaturesUnit);
                const getDynamicPropertiesParameters = this.http.get<any>(this.urlApi + 'prosim/dynamic-properties-parameters', { params: paramsDynamicPropertiesParameters });

                return forkJoin([getUnits, getDynamicPropertiesParameters]).pipe(
                    map(([resultUnits, resultDynamicPropertiesParameters]) => {
                        units = _.get(resultUnits, 'data', []);
                        tmin = parseInt(_.get(resultDynamicPropertiesParameters, 'data.TMin'), 0);
                        tmax = parseInt(_.get(resultDynamicPropertiesParameters, 'data.TMax'), 0);
                        correlation = _.get(resultDynamicPropertiesParameters, 'data.Correlation');
                        coefficients = _.get(resultDynamicPropertiesParameters, 'data.Coefficients');

                        return {
                            units: units,
                            tmin: tmin,
                            tmax: tmax,
                            correlation: correlation,
                            coefficients: coefficients
                        };
                    })
                );
            } else {
                return of({
                    units: units,
                    tmin: tmin,
                    tmax: tmax,
                    correlation: correlation,
                    coefficients: coefficients
                });
            }
        }
    }

    getDynamicPropertyValues(o: ProsimDynamic) {
        const molKey = o.molKey;
        const propertyKey = o.propertyKey;
        const temperatureUnit = o.temperatureUnit;
        const unit = o.unit;
        const tmin = o.tmin;
        const tmax = o.tmax;

        const values = _.get(this._propertiesCache, propertyKey + '.' + molKey + '.' + unit + '.' + temperatureUnit + '.' + (tmin + '-' + tmax) + '.values');
        if (_.size(values) > 0) {
            return of(values);
        } else {

            let params = new HttpParams();
            params = params.append('molKey', molKey);
            params = params.append('propertyKey', propertyKey);
            params = params.append('temperatureUnit', temperatureUnit);
            params = params.append('unit', unit);
            params = params.append('tmin', tmin + '');
            params = params.append('tmax', tmax + '');

            return this.http.get<string[]>(this.urlApi + 'prosim/dynamic-property-values', { params }).pipe(
                first(),
                map(
                    (resultApi: string[]) => {
                        return of(_.reduce(_.get(resultApi, 'data', []), function (result, value, key) {
                            const k = _.first(_.keys(value));
                            if (k) {
                                result.push({
                                    key: parseFloat(k),
                                    value: parseFloat(value[k]),
                                    x: parseFloat(k),
                                    y: parseFloat(value[k])
                                });
                            }
                            return result;
                        }, []));
                    }
                )
            );
        }
    }

    getFormula(correlationKey: string) {
        const formula = _.get(this._propertiesCache, 'correlation-' + correlationKey);
        if (formula) {
            return of(formula);
        } else {
            let params = new HttpParams();
            params = params.append('correlationKey', correlationKey);
            return this.http.get<string[]>(this.urlApi + 'prosim/correlation-formulation', {
                params: params,
                responseType: 'text' as 'json'
            }).pipe(
                first(),
                map(
                    (result: any) => {
                        return of(result);
                    }
                )
            );
        }
    }

    getTemparturesUnits() {
        let params = new HttpParams();
        params = params.append('property', 'Temperature');
        return this.http.get<string[]>(this.urlApi + 'prosim/units', { params }).pipe(
            first(),
            map(
                (result: any) => {
                    return of(result);
                }
            )
        );
    }

    getStaticPropertyValue(molKey: string, propertyKey: string, unit: string) {
        const value = _.get(this._propertiesCache, propertyKey + '.' + molKey + '.' + unit);
        if (value) {
            return of(value);
        } else {
            let params = new HttpParams();
            params = params.append('molKey', molKey);
            params = params.append('propertyKey', propertyKey);
            params = params.append('unit', unit);
            return this.http.get<string[]>(this.urlApi + 'prosim/static-property-value', { params }).pipe(
                first(),
                map(
                    (result: any) => {
                        let currentValue = '-';
                        if (_.isFunction(result.plain)) {
                            result = result.plain();
                        }
                        _.forEach(_.get(result, 'data', []), function (v, k) {
                            if (_.has(v, propertyKey)) {
                                currentValue = parseFloat(_.get(v, propertyKey)).toString();
                            }
                        });
                        return of(currentValue);
                    }
                )
            );
        }
    }

    searchTemperatures(o: ProsimDynamic) {
        const temperatureUnits = this.getTemperatureUnits();
        delete o.error;
        delete o.tmin;
        delete o.lower;
        delete o.tmax;
        delete o.upper;
        delete o.correlation;
        delete o.coefficients;

        const currentMolKey = o.molKey;
        const currentPropertyKey = o.propertyKey;
        const currentUnit = o.unit;

        o.temperatureUnit = o.temperatureUnit || _.first(temperatureUnits);
        o.loadingTemperatures = true;

        this._getDynamicPropertyParameters(currentMolKey, currentPropertyKey, o.temperatureUnit).toPromise().then(
            function (resultDynamicPropertyParameters: DynamicProperty) {
                delete o.loadingTemperatures;

                if (!resultDynamicPropertyParameters || !resultDynamicPropertyParameters.units) {
                    o.error = 'PROSIM.ERROR';
                } else {
                    o.units = resultDynamicPropertyParameters.units;
                    if (_.indexOf(o.units, currentUnit) !== -1) {
                        o.unit = currentUnit;
                    } else {
                        o.unit = _.first(o.units);
                    }
                    o.tmin = resultDynamicPropertyParameters.tmin;
                    o.lower = resultDynamicPropertyParameters.tmin;
                    o.tmax = resultDynamicPropertyParameters.tmax;
                    o.upper = resultDynamicPropertyParameters.tmax;
                    o.correlation = resultDynamicPropertyParameters.correlation;
                    o.coefficients = resultDynamicPropertyParameters.coefficients;
                }
            },
            function () {
                delete o.loadingTemperatures;
                o.error = 'PROSIM.ERROR';
            }
        );
    }

    searchValue(o: ProsimConstant) {
        delete o.error;
        delete o.value;

        const currentMolKey = o.molKey;
        const currentPropertyKey = o.propertyKey;
        const currentUnit = o.unit;

        delete o.value;
        o.loadingValue = true;
        this.getStaticPropertyValue(currentMolKey, currentPropertyKey, currentUnit).toPromise().then(
            function (resultValue) {
                delete o.loadingValue;
                o.value = resultValue.value;
            },
            function () {
                delete o.loadingValue;
                o.error = 'PROSIM.ERROR';
            }
        );
    }

    searchUnits(o: ProsimConstant | ProsimDynamic) {
        const self = this;
        const currentPropertyKey = o.propertyKey;
        const currentUnit = o.unit;

        o.loadingUnits = true;

        delete o.error;
        delete o.unit;
        delete o.units;
        if (o.type === 'constant') {
            delete (<ProsimConstant> o).value;
        }

        this.getPropertyUnits(currentPropertyKey, o.type).toPromise().then(
            function (resultGetPropertyUnits) {
                delete o.loadingUnits;
                o.units = resultGetPropertyUnits;
                if (_.indexOf(o.units, currentUnit) !== -1) {
                    o.unit = currentUnit;
                } else {
                    o.unit = _.first(o.units);
                }
                if (o.type === 'constant') {
                    self.searchValue(<ProsimConstant> o);
                }
            },
            function () {
                delete o.loadingUnits;
                o.error = 'PROSIM.ERROR';
            }
        );
    }
}
