import * as _ from 'lodash';
import { Component, Injector } from '@angular/core';
import { FilterByComponent } from '../abstract-filter-by.component';

@Component({
    selector: 'filters-by-email',
    templateUrl: './filters-by-email.component.html',
    styleUrls: ['./filters-by-email.component.scss']
})
export class FiltersByEmailComponent extends FilterByComponent {
    public email = '';

    private _timer: any;

    constructor(
        protected injector: Injector
    ) {
        super(injector);
        this.filterKey = 'email';
    }

    clearTimer() {
        if (this._timer) {
            clearTimeout(this._timer);
        }
    }

    onChange() {
        this.clearTimer();
        const value = this[this.filterKey];
        if (value === '') {
            const filters = this.serviceListings.filters;
            delete filters.email;
            this.serviceListings.filters = filters;
            this.isFiltersDirty.emit({ filterKey: this.filterKey, isDirty: false });
        } else {
            this.serviceListings.filters = _.extend(this.serviceListings.filters, {
                email: value
            });
            this.checkIsDirty(value, this.filterKey);
        }
        this._timer = setTimeout(this.sendFilter.bind(this), 700);
    }

    sendFilter() {
        this.serviceListings.dataStoreChanged();
        this.saveFilter();
    }

    reset() {
        this.email = '';
        const filters = this.serviceListings.filters;
        delete filters[this.filterKey];
        this.serviceListings.filters = filters;
        this.isFiltersDirty.emit({ filterKey: this.filterKey, isDirty: false });
    }
}
