export class DuplicateMemberOptions {
    duplicateFirstname = true;
    duplicateLastname = true;
    duplicateEmail = true;

    duplicateContent = true;

    duplicateJobTitles = true;
    duplicateAdress = true;
    duplicateCompany = true;
    duplicateFacebook = true;
    duplicateTwitter = true;
    duplicateLinkedin = true;
    duplicateGoogle = true;
    duplicatePhone = true;

    duplicatePicture = true;

    constructor() {}
}
