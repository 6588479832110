import { Component, EventEmitter, Output, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { AppSettings } from '@src/app/app.settings';
import { ServicePreferences, ServiceToaster, ServiceErrors, ServiceListings } from '@src/app/services';
import { PreferencesTableColumns } from '@src/app/models';
import { finalize } from 'rxjs/internal/operators/finalize';
import { first, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'preferences-table',
    templateUrl: './preferences-table.html',
    styleUrls: ['./preferences-table.scss']
})
export class PreferencesTableComponent implements OnInit, OnDestroy {
    @Output() saveConfirmed = new EventEmitter();

    public saving = false;
    public currentUserPreferencesTableColumns: PreferencesTableColumns;

    private _destroyed$ = new Subject();

    constructor(
        private appSettings: AppSettings,
        private serviceErrors: ServiceErrors,
        private serviceToaster: ServiceToaster,
        private serviceListings: ServiceListings,
        private servicePreferences: ServicePreferences
    ) {
    }

    ngOnInit() {
        this.currentUserPreferencesTableColumns = this.servicePreferences.getPreference('tableColumns');
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }

    savePreferences(): void {
        this.saving = true;
        this.servicePreferences.savePreference({ 'tableColumns': this.currentUserPreferencesTableColumns }).pipe(
            finalize(() => {
                this.saving = false;
                this.saveConfirmed.emit(true);
            }),
            first(),
            takeUntil(this._destroyed$)
        ).subscribe(
            () => {
                this.serviceListings.columns = this.currentUserPreferencesTableColumns;
                this.appSettings.settings.tableColumns = this.currentUserPreferencesTableColumns;
                this.serviceToaster.success('PREFERENCES.TABLE_COLUMNS');
            },
            (error: HttpErrorResponse) => {
                this.serviceErrors.handleError('PREFERENCES.TABLE_COLUMNS', error);
            }
        );
    }
}
