import * as _ from 'lodash';
import { Component, OnDestroy } from '@angular/core';
import { Router, ActivatedRouteSnapshot, UrlSegment, NavigationEnd } from '@angular/router';
import { withLatestFrom, map, startWith, takeUntil } from 'rxjs/operators';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { Kb, Item } from '@models';
import { ServiceKb, ServiceItems, ServiceMatomo } from '@services';
import { AppSettings } from '@src/app/app.settings';
import { Title } from '@angular/platform-browser';
import { combineLatest, Subject } from 'rxjs';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnDestroy {

    public societyTitle: string;
    public kbTitle: string;
    public extraTitle: string;
    public extraBreadcrumb: string;
    public extraBreadcrumbGroup: any;
    public currentKb: Kb;
    private lastDocumentTitle: string;
    private _destroyed$ = new Subject();

    constructor(
        public router: Router,
        private title: Title,
        private serviceKb: ServiceKb,
        private appSettings: AppSettings,
        private serviceItems: ServiceItems,
        private serviceMatomo: ServiceMatomo,
        private ngxTranslateService: TranslateService
    ) {
        const kbObservable = this.serviceKb.kbObservable;
        const eventsObservable = this.router.events;
        const itemObservable = this.serviceItems.itemObservable;
        const langObservable = this.ngxTranslateService.onDefaultLangChange.pipe(
            startWith(null)
        );

        combineLatest(kbObservable, eventsObservable, itemObservable, langObservable).pipe(
            takeUntil(this._destroyed$),
            map(
                ([kb, event, item, langChangeEvent]) => {
                    if (event instanceof NavigationEnd || langChangeEvent || item.title) {
                        this.kbTitle = kb.title;
                        this.societyTitle = kb.society.title;

                        delete this.extraTitle;
                        delete this.extraBreadcrumb;
                        delete this.extraBreadcrumbGroup;

                        let extra: string;
                        if (item.title) {
                            extra = item.title;
                        } else {
                            this._parseRoute(this.router.routerState.snapshot.root);
                            extra = (this.extraTitle && _.trim(this.extraTitle) !== '') ? _.toUpper(this.ngxTranslateService.instant(this.extraTitle)) : '';
                        }
                        this._setHTMLTranslatedTitle(extra);
                        if (this.lastDocumentTitle !== document.title) {
                            // Track event
                            this.lastDocumentTitle = document.title;
                            this.serviceMatomo.trackPageView();
                        }
                    }
                }
            )
        ).subscribe();
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }

    closeSubMenus() {
        const menu = document.querySelector('.sidenav-menu-outer');
        if (menu) {
            for (let i = 0; i < menu.children[0].children.length; i++) {
                const child = menu.children[0].children[i];
                if (child) {
                    if (child.children[0].classList.contains('expanded')) {
                        child.children[0].classList.remove('expanded');
                        child.children[1].classList.remove('show');
                    }
                }
            }
        }
    }

    private _parseRoute(node: ActivatedRouteSnapshot) {
        if (node.data['breadcrumb']) {
            if (node.url.length) {
                if (node.data['breadcrumb'] === 'Group') {
                    this.extraTitle = 'HTML_TITLE.' + _.toUpper(_.get(node, 'params.group'));
                    this.extraBreadcrumb = 'BREADCRUMB.' + _.toUpper(_.get(node, 'params.group'));
                } else if (node.data['breadcrumb'] === 'Type') {
                    this.extraTitle = 'HTML_TITLE.' + _.toUpper(_.get(node, 'params.type'));
                    this.extraBreadcrumb = this.extraTitle;
                    this.extraBreadcrumbGroup = {};
                    this.extraBreadcrumbGroup.title = 'BREADCRUMB.' + _.toUpper(_.get(node, 'params.group'));
                    this.extraBreadcrumbGroup.url = '/items/' + _.get(node, 'params.group');
                } else if (node.data['breadcrumb'] === 'Detail' && _.get(node, 'params.id') === 'add') {
                    this.extraTitle = 'HTML_TITLE.' + _.toUpper('ADD_' + _.get(node, 'params.group'));
                    this.extraBreadcrumb = this.extraTitle;
                } else if (node.data['breadcrumb'] === 'Dashboard') {
                    this.extraTitle = 'HTML_TITLE.DASHBOARD';
                } else if (node.data['breadcrumb'] === 'Search') {
                    this.extraTitle = 'HTML_TITLE.SEARCH';
                    this.extraBreadcrumb = this.extraTitle;
                } else if (node.data['breadcrumb'] === 'Orphan') {
                    this.extraTitle = 'HTML_TITLE.ORPHANS';
                    this.extraBreadcrumb = this.extraTitle;
                } else if (node.data['breadcrumb'] === 'Manage database') {
                    this.extraTitle = 'HTML_TITLE.MANAGE_DATABASE';
                    this.extraBreadcrumb = this.extraTitle;
                } else if (node.data['breadcrumb'] === 'All databases') {
                    this.extraTitle = 'HTML_TITLE.ALL_DATABASES';
                    this.extraBreadcrumb = this.extraTitle;
                } else if (node.data['breadcrumb'] === 'Tag') {
                    this.extraTitle = 'HTML_TITLE.TAGS';
                    this.extraBreadcrumb = this.extraTitle;
                }
            }
        }
        if (node.firstChild) {
            this._parseRoute(node.firstChild);
        }
    }

    private _setHTMLTranslatedTitle(extra: string) {
        let pageTitle = this.appSettings.settings.name;
        if (extra) {
            if (this.extraBreadcrumbGroup && this.extraBreadcrumbGroup.title) {
                extra += ' (' + _.toUpper(this.ngxTranslateService.instant(this.extraBreadcrumbGroup.title)) + ')';
            }
            pageTitle += ' | ' + extra;
        }
        if (this.kbTitle && this.extraTitle !== 'HTML_TITLE.ALL_DATABASES') {
            pageTitle += ' | ' + _.toUpper(this.kbTitle);
        }
        if (this.societyTitle && this.extraTitle !== 'HTML_TITLE.ALL_DATABASES') {
            pageTitle += ' | ' + _.toUpper(this.societyTitle);
        }
        this.title.setTitle(pageTitle);
    }
}
