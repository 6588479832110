import { Component, OnInit } from '@angular/core';
import { ServiceListings, ServiceMap } from '@services';

@Component({
    selector: 'filters-by-dynamic-links',
    templateUrl: './filters-by-dynamic-links.component.html',
    styleUrls: ['./filters-by-dynamic-links.component.scss']
})
export class FiltersByDynamicLinksComponent implements OnInit {

    public displayDynamicLinks: boolean = true;

    constructor(
        private serviceListings: ServiceListings,
        private serviceMap: ServiceMap
    ) {

    }

    ngOnInit() {
        
    }

    onDisplayDynamicLinksChange() {
        this.serviceMap.emitFilterDynamicLinkChanged(this.displayDynamicLinks);
    }

    reset() {
        this.displayDynamicLinks = true;
    }
}
