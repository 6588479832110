import * as _ from 'lodash';
import { Component, Input, Output } from '@angular/core';
import { finalize, first, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';

import { ServiceItems, ServicePages, ServiceWorklabs, ServiceToaster, ServiceErrors, ServiceTextAnalyser } from '@services';
import { Page, Worklab } from '@models';
import { LinkedItemsComponent } from '../linked-items.component';
import { ServiceSecurity } from '@services/security.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'linked-worklabs',
    templateUrl: './linked-worklabs.component.html',
    styleUrls: ['./linked-worklabs.component.scss']
})
export class LinkedWorklabsComponent extends LinkedItemsComponent {
    
    @Input() showCreateWorklab = true;

    private _page: Page;
    @Input()
    set page(value: Page) {
        this._page = value;
        this.handleEditionMode(this._editionMode);
    }
    get page(): Page {
        return this._page;
    }

    constructor(
        private router: Router,
        private serviceToaster: ServiceToaster,
        private serviceErrors: ServiceErrors,
        private serviceItems: ServiceItems,
        private servicePages: ServicePages,
        private serviceWorklabs: ServiceWorklabs,
        private serviceSecurity: ServiceSecurity,
        protected serviceTextAnalyser: ServiceTextAnalyser
    ) {
        super(serviceWorklabs, serviceTextAnalyser);
        this.field = 'linkedWorklabs';
    }

    protected handleOrder(orderDirection: 'asc' | 'desc') {
        if (this._page instanceof Page) {
            this._page.linkedWorklabs = this.serviceWorklabs.sortArray(this._page.linkedWorklabs, orderDirection);
        }
    }

    protected handleEditionMode(value: boolean) {
        if (this._page instanceof Page) {
            this.isCreation = _.isUndefined(this._page.id);
            this.canEdit = this.servicePages.userCanModifyLinkedWorklabs(this._page);
            if (value === true && this.canEdit === true) {
                this.loadAllPotentialLinkedItems<Worklab>(this._page.linkedWorklabs, this._page);
            }
        }
    }

    addWorklab(worklab: Worklab) {
        worklab.isLoading = true;
        this.ctrl.reset();
        this.ctrl.disable();
        this.actionInProgress = true;

        let linkedWorklabs = this._page.linkedWorklabs;
        linkedWorklabs.push(worklab);
        linkedWorklabs = this.serviceWorklabs.sortArray(linkedWorklabs, 'asc');
        this._page.linkedWorklabs = linkedWorklabs;

        if (!this.isCreation) {
            this.servicePages.addWorklab(this._page, worklab).pipe(
                finalize(() => {
                    this.ctrl.enable();
                    this.actionInProgress = false;
                    this.newLinkInput.nativeElement.focus();
                }),
                first(),
                takeUntil(this._destroyed$)
            ).subscribe(
                () => {
                    worklab.isLoading = false;
                    _.remove(this.allPotentialLinkedItems, function (o: Worklab) { return o.id === worklab.id; });

                    this._page.linkedWorklabs = linkedWorklabs;
                    if (this._page.witness) {
                        this._page.witness.linkedWorklabs = linkedWorklabs;
                    }
                    this.serviceItems.item = this._page;

                    const params = {page: this._page.title, worklab: worklab.title};
                    this.serviceToaster.success('ITEM.LINKED_PAGES_TO_WORKLAB.ADD', params);
                },
                (error: HttpErrorResponse) => {
                    _.remove(this._page.linkedWorklabs, function (o: Worklab) { return o.id === worklab.id; });
                    this.serviceErrors.handleError('ITEM.LINKED_PAGES_TO_WORKLAB.ADD', error);
                }
            );
        } else {
            worklab.isLoading = false;
            _.remove(this.allPotentialLinkedItems, function (o: Worklab) { return o.id === worklab.id; });
            this.ctrl.enable();
            this.actionInProgress = false;
            this.newLinkInput.nativeElement.focus();
        }
    }

    removeWorklab(worklab: Worklab) {
        worklab.isLoading = true;
        this.ctrl.disable();
        this.actionInProgress = true;

        if (!this.isCreation) {
            this.servicePages.removeWorklab(this._page, worklab).pipe(
                finalize(() => {
                    this.ctrl.enable();
                    this.actionInProgress = false;
                    worklab.isLoading = false;
                }),
                first(),
                takeUntil(this._destroyed$)
            ).subscribe(
                () => {
                    this.allPotentialLinkedItems.push(worklab);

                    const linkedWorklabs = this._page.linkedWorklabs;
                    _.remove(this._page.linkedWorklabs, function (o: Worklab) { return o.id === worklab.id; });

                    this._page.linkedWorklabs = linkedWorklabs;
                    if (this._page.witness) {
                        this._page.witness.linkedWorklabs = linkedWorklabs;
                    }
                    this.serviceItems.item = this._page;

                    const params = {page: this._page.title, worklab: worklab.title};
                    this.serviceToaster.success('ITEM.LINKED_PAGES_TO_WORKLAB.ADD', params);
                },
                (error: HttpErrorResponse) => {
                    let linkedWorklabs = this._page.linkedWorklabs;
                    linkedWorklabs.push(worklab);
                    linkedWorklabs = this.serviceWorklabs.sortArray(linkedWorklabs, 'asc');

                    this._page.linkedWorklabs = linkedWorklabs;
                    if (this._page.witness) {
                        this._page.witness.linkedWorklabs = linkedWorklabs;
                    }
                    this.serviceItems.item = this._page;

                    this.serviceErrors.handleError('ITEM.LINKED_PAGES_TO_WORKLAB.REMOVE', error);
                }
            );
        } else {
            this.allPotentialLinkedItems.push(worklab);
            _.remove(this._page.linkedWorklabs, function (o: Worklab) { return o.id === worklab.id; });
            this.ctrl.enable();
            this.actionInProgress = false;
            worklab.isLoading = false;
        }
    }

    createWorklab() {
        this.serviceSecurity.setCookie('worklabFromPage', 'true');
        this.serviceSecurity.setCookie('sourceUid', _.get(this, '_page.uid'));
        this.serviceSecurity.setCookie('sourceGroup', _.get(this, '_page.group'));
        this.router.navigate(['/items/worklab/add']);
    }
}
