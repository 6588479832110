import { Component, OnInit, OnDestroy } from '@angular/core';
import { finalize, first, takeUntil } from 'rxjs/operators';
import { AppSettings } from '@app/app.settings';

import { ServicePreferences } from '@services';
import { Subject } from 'rxjs';

@Component({
    selector: 'preferences-summary-emails-frequency',
    templateUrl: './preferences-summary-emails-frequency.component.html',
    styleUrls: ['./preferences-summary-emails-frequency.component.scss']
})
export class PreferencesSummaryEmailFrequencyComponent implements OnInit, OnDestroy {

    public saving = false;
    public loading = false;

    public availableSummaryFrequencies: string[] = [
        'NEVER',
        'MONTHLY',
        'WEEKLY',
        'DAILY'
    ];
    public currentUserSummaryFrequency: string;

    private _destroyed$ = new Subject();

    constructor(
        private appSettings: AppSettings,
        private servicePreferences: ServicePreferences
    ) {
    }

    ngOnInit() {
        this.currentUserSummaryFrequency = this.servicePreferences.getPreference('summaryFrequency');
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }

    trackValues(index: number, o: string) {
        return o ? o : index;
    }

    changeSummaryFrequency($event) {
        this.appSettings.settings.summaryFrequency = $event.value;

        this.saving = true;
        this.servicePreferences.savePreference({'summaryFrequency': $event.value}).pipe(
            finalize(() => {
                this.saving = false;
            }),
            first(),
            takeUntil(this._destroyed$)
        ).subscribe();
    }
}
