import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError, first } from 'rxjs/operators';

import { Page, Member, Role, User } from '@models';
import { CommonService } from '@services/common.service';
import { ServiceDatastore } from './datastore.service';
import { ServiceSecurity } from './security.service';

@Injectable({ providedIn: 'root' })
export class ServiceExpertises extends CommonService {

    constructor(
        protected injector: Injector,
        private serviceSecurity: ServiceSecurity,
        private serviceDatastore: ServiceDatastore
    ) {
        super(injector);
    }

    userCanModifyExperts(item: Page): boolean {
        const isUserAdmin = this.serviceSecurity.isAdmin();
        const isUserExpert = item.isUserExpert;
        const itemCanHaveExperts = item.group === 'Page';
        const isUserCreatorOfThePage = item.isUserCreator;
        const isAccessGrantedByRole = this.serviceSecurity.hasMinimumRole(Role.ROLE_CONTRIBUTOR);
        return itemCanHaveExperts && (isUserAdmin ||  isUserExpert || isUserCreatorOfThePage || isAccessGrantedByRole);
    }

    userCanModifyExpertises(member: Member): boolean {
        const isUserAdmin = this.serviceSecurity.isAdmin();
        const isItemLocked = member.islocked;
        const isAccessGrantedByRole = this.serviceSecurity.hasMinimumRole(Role.ROLE_CONTRIBUTOR);

        return isUserAdmin || (!isItemLocked && isAccessGrantedByRole);
    }

    addExpert(page: Page, expert: Member | User): Observable<any> {
        if (!this.userCanModifyExperts(page)) {
            return this.serviceErrors.throwError('ITEM.EXPERTS.ADD', { code: 403 });
        }
        return this.http.post(this.urlApi + 'experts/' + page.uid + '/' + expert.uid, {}).pipe(
            map(
                () => {
                    this.serviceDatastore.addExpert(page.id, expert.id, this.serviceSecurity.user);
                }
            )
        );
    }

    removeExpert(page: Page, expert: Member | User): Observable<any> {
        if (!this.userCanModifyExperts(page)) {
            return this.serviceErrors.throwError('ITEM.EXPERTS.REMOVE', { code: 403 });
        }
        return this.http.delete(this.urlApi + 'experts/' + page.uid + '/' + expert.uid, {}).pipe(
            map(
                () => {
                    this.serviceDatastore.removeExpert(page.id, expert.id, this.serviceSecurity.user);
                }
            )
        );
    }
}
