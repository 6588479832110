import * as _ from 'lodash';
import { Directive, Renderer2, Input, ElementRef, OnInit, HostListener, OnDestroy } from '@angular/core';

import { ItemsSettings } from '@items/items.settings';
import { ServiceListings } from '@services/listings.service';
import { takeUntil, first } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ServicePreferences, ServiceSecurity } from '@services';

@Directive({
    selector: '[sortIcon]'
})
export class SortIconDirective implements OnInit, OnDestroy {

    private _iconPrefix: string;
    private _sortDirection: string;

    private _destroyed$ = new Subject();

    @Input() sortIcon: string;
    @Input() listingOrder: any;

    @HostListener('click', ['$event'])
    onClick() {
        this.serviceListings.changeListingOrder(this.sortIcon);

        const listingKey = this.serviceListings.getKey();
        this.serviceSecurity.setCookie('index-' + listingKey, '0', 0);

        document.getElementById('topTable').scrollIntoView();

        const keepFilters = this.servicePreferences.getPreference('keepFilters');
        if (keepFilters === true) {
            const sort = {
                field: this.sortIcon,
                direction: this._sortDirection
            };
            const preference = {};
            preference['orderBy'] = {};
            const listGroup = this.serviceListings.getKey();
            preference['orderBy'][listGroup] = sort;
            this.servicePreferences.savePreference(preference).pipe(
                first(),
                takeUntil(this._destroyed$)
            ).subscribe();
        }
    }

    constructor(
        private serviceListings: ServiceListings,
        private renderer: Renderer2,
        private el: ElementRef,
        private servicePreferences: ServicePreferences,
        private serviceSecurity: ServiceSecurity
    ) {
    }

    ngOnInit() {
        this._iconPrefix = _.get(ItemsSettings, 'STORING.' + this.sortIcon + '.icon', 'sort');

        this.serviceListings.listingOrderObservable.pipe(
            takeUntil(this._destroyed$)
        ).subscribe((listingOrder) => {
            this.printArrow(listingOrder);
        });
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }

    private printArrow(listingOrder: any) {
        const sortField = _.get(listingOrder, 'field');
        let sortDirection = _.get(listingOrder, 'direction');
        if (this._iconPrefix === 'date') {
            sortDirection = (sortDirection === 'asc') ? 'desc' : 'asc';
        }
        this._sortDirection = sortDirection;

        if (this.sortIcon === 'title' && sortField === 'score') {
            this.renderer.addClass(this.el.nativeElement, 'order-by-selection');
            this.renderer.removeClass(this.el.nativeElement, 'not-order-by-selection');
            this.renderer.setStyle(this.el.nativeElement, 'color', 'rgba(0, 0, 0, 1)');


            this.renderer.removeClass(this.el.nativeElement, 'alpha-up');
            this.renderer.removeClass(this.el.nativeElement, 'alpha-down');

            if (sortDirection === 'asc') {
                this.renderer.addClass(this.el.nativeElement, 'numeric-down');
                this.renderer.removeClass(this.el.nativeElement, 'numeric-up');
            } else if (sortDirection === 'desc') {
                this.renderer.addClass(this.el.nativeElement, 'numeric-up');
                this.renderer.removeClass(this.el.nativeElement, 'numeric-down');
            }
        } else if (this.sortIcon === sortField ) {
            if (this.sortIcon === 'title') {
                // Fix hack for score
                this.renderer.removeClass(this.el.nativeElement, 'numeric-up');
                this.renderer.removeClass(this.el.nativeElement, 'numeric-down');
            }

            this.renderer.addClass(this.el.nativeElement, 'order-by-selection');
            this.renderer.removeClass(this.el.nativeElement, 'not-order-by-selection');
            this.renderer.setStyle(this.el.nativeElement, 'color', 'rgba(255, 0, 0, 0.54)');

            if (sortDirection === 'asc') {
                this.renderer.addClass(this.el.nativeElement, this._iconPrefix + '-down');
                this.renderer.removeClass(this.el.nativeElement, this._iconPrefix + '-up');
            } else if (sortDirection === 'desc') {
                this.renderer.addClass(this.el.nativeElement, this._iconPrefix + '-up');
                this.renderer.removeClass(this.el.nativeElement, this._iconPrefix + '-down');
            }
        } else {
            this.renderer.removeClass(this.el.nativeElement, 'order-by-selection');
            this.renderer.addClass(this.el.nativeElement, 'not-order-by-selection');
            this.renderer.setStyle(this.el.nativeElement, 'color', 'rgba(0, 0, 0, 1)');
        }
    }
}
