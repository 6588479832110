import { Component, Input } from '@angular/core';

import { Member } from '@models';

@Component({
    selector: 'role-in-database',
    templateUrl: './role-in-database.html',
    styleUrls: ['./role-in-database.scss'],
})
export class RoleInDatabaseComponent {

    @Input() showTitle = true;
    @Input() member: Member;

    constructor() {}
}
