import { Component, Input } from '@angular/core';

import { Kb } from '@models';
import { ServiceKb } from '@services';

@Component({
    selector: 'kb-is-visible',
    templateUrl: './kb-is-visible.component.html',
    styleUrls: ['./kb-is-visible.component.scss']
})
export class KbIsVisibleComponent {
    @Input() showText = false;
    @Input() showIcon = true;

    private _kb: Kb;
    @Input()
    set kb(value: Kb) {
        this._kb = value;
        this._setAttributes();
    }
    get kb(): Kb {
        return this._kb;
    }

    private _editionMode: boolean;
    @Input()
    set editionMode(value: boolean) {
        this._editionMode = value;
    }
    get editionMode(): boolean {
        return this._editionMode;
    }

    public icon = 'fas fa-question';
    public class = '';
    public tooltip = 'KB.VISIBILITY.ICON.NO_INFO';
    public visibilities: string[];

    constructor(
        private serviceKb: ServiceKb
    ) {
        this.visibilities = [
            'NONE',
            'SOCIETY',
            'ALL'
        ];
    }

    trackValues(index: number, o: string) {
        return o ? o : index;
    }

    selectionChange($event: any) {
        this._kb.visibility = $event.value;
        this.serviceKb.kb = this._kb;
    }

    private _setAttributes() {
        const value = this._kb.visibility;
        if (value !== 'ALL' && value !== 'SOCIETY' && value !== 'NONE') {
            this.icon = 'fas fa-question';
            this.tooltip = 'KB.VISIBILITY.ICON.NO_INFO';
        } else if (value === 'ALL') {
            this.icon = 'fas fa-globe';
            this.class = 'all';
            this.tooltip = 'KB.VISIBILITY.ICON.ALL';
        } else if (value === 'SOCIETY') {
            this.icon = 'fas fa-industry';
            this.class = 'society';
            this.tooltip = 'KB.VISIBILITY.ICON.SOCIETY';
        } else if (value === 'NONE') {
            this.icon = 'fas fa-eye-slash';
            this.class = 'none';
            this.tooltip = 'KB.VISIBILITY.ICON.NONE';
        }
    }
}
