import * as _ from 'lodash';
import { Injectable, Injector } from '@angular/core';
import { Observable, forkJoin, Observer, of } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ServiceDomInjector } from '@services/dom-injector.service';
import { SubscriptionsService } from '@services/subscriptions.service';
import { Item, DynamicLinksOut, DynamicLinkIn, KbSubscription } from '../models';
import { CommonService } from './common.service';
import { ServiceSecurity } from './security.service';


const DYNAMIC_LINK_MARKER_TAG = 'dynamic-link-marker';

@Injectable({ providedIn: 'root' })
export class ServiceDynamicLinks extends CommonService {

    private dictionnary: any = {};
    private browserVersion: any = false;

    constructor(
        protected injector: Injector,
        private serviceDomInjector: ServiceDomInjector,
        private serviceSecurity: ServiceSecurity,
        private serviceSubscriptions: SubscriptionsService,
        private domSanitizer: DomSanitizer
    ) {
        super(injector);
    }

    public addWordData(dynamicLink: DynamicLinksOut) {
        // Remove not word boundary like ? ( ) at the end of the words
        // word = _.trim(word.replace(/[^\w.]$/g, ' '));
        const cleanWord = _.toLower(this.serviceTextAnalyser.removeCombiningMetaChars(dynamicLink.word));
        if (!_.has(this.dictionnary, cleanWord)) {
            this.dictionnary[cleanWord] = dynamicLink;
        }
    }

    public getWordData(word: string): DynamicLinksOut {
        const expr = new RegExp('^' + this.makeComparaisonString(word) + '$');
        for (const k in this.dictionnary) {
            if (expr.test(k)) {
                const dynLink = _.cloneDeep(this.dictionnary[k]);
                dynLink.word = word; // preserve original word
                return dynLink;
            }
        }
    }

    private makeComparaisonString(input: string): string {
        // wrap entire words only
        let o = '\\b';  // words starting with accents or special char will not match...

        if (this.doesBrowserSupportPositiveLookBehindRegex()) {
            o = '(?<=(\\W)|_|^)(?<=((?![éëêèùàöôïîç]).)|^)'; // Positive Lookbehind, works with ECMAScript2018 only
        }
        input = _.toLower(input);
        input = this.serviceTextAnalyser.removeCombiningMetaChars(input);
        input = this.serviceTextAnalyser.removeSubSupTags(input);

        const optionalSubSup = '(<(\/)?(sub|sup)>)?'; // optionnal matching pattern for sub/sup html tags
        o = o + optionalSubSup;

        input.split('').forEach(function (c) {
            switch (c) {
                case 'a':
                case 'à':
                case 'â':
                case 'å':
                case 'ä':
                    o = o + '[aàâåä]' + optionalSubSup;
                    break;
                case 'c':
                case 'ç':
                    o = o + '[cç]' + optionalSubSup;
                    break;
                case 'e':
                case 'é':
                case 'è':
                case 'ë':
                case 'ê':
                    o = o + '[eéëèê]' + optionalSubSup;
                    break;
                case 'o':
                case 'ô':
                    o = o + '[oô]' + optionalSubSup;
                    break;
                case '∅':
                case 'Ø':
                case 'ø':
                    o = o + '[∅Øø]' + optionalSubSup;
                    break;
                case 'u':
                case 'ù':
                case 'ü':
                case 'û':
                    o = o + '[uùüû]' + optionalSubSup;
                    break;
                case 'i':
                case 'ï':
                case 'î':
                    o = o + '[iïî]' + optionalSubSup;
                    break;
                case '(':
                    o = o + '\\(' + optionalSubSup;
                    break;
                case ')':
                    o = o + '\\)' + optionalSubSup;
                    break;
                case '?':
                    o = o + '\\?' + optionalSubSup;
                    break;
                case '+':
                    o = o + '\\+' + optionalSubSup;
                    break;
                default:
                    o = o + c + optionalSubSup;
                    break;
            }
        });
        // wrap entire words only, Positive Lookahead
        o = o + '(?=((?![éëêèùàöôïîç]).)|$)(?=(\\W|_|$))';
        return o;
    }


    private keepOnlyOneLinkForCurrentKb(dynLinksOut: any) {
        const currentKbSlug = this.currentKbSlug;

        _(dynLinksOut).forEach(function (link) {
            if (link.word) {
                if (_.get(link, 'kb.' + currentKbSlug + '.length', 0) > 1) {
                    let longestIndex = 0;
                    let longestLength = 0;
                    let currentIndex = 0;
                    _(_.get(link, 'kb.' + currentKbSlug)).forEach(function (l) {
                        if (_.get(l, 'alias.length', 0) > longestLength) {
                            longestLength = _.get(l, 'alias.length');
                            longestIndex = currentIndex;
                        } else if (_.get(l, 'alias.length', 0) === 0 && _.get(l, 'title.length', 0) > longestLength) {
                            longestLength = _.get(l, 'title.length');
                            longestIndex = currentIndex;
                        }
                        currentIndex++;
                    });
                    link.kb[currentKbSlug] = [link.kb[currentKbSlug][longestIndex]];
                }
            }
        });
        return dynLinksOut;
    }


    private buildMarkerInHtmlText(searchString: string, htmlString: string): string {
        let flag = 'gui';
        // IE11 hack
        if (window.navigator.userAgent.indexOf('Trident/7.0') !== -1 || window.navigator.userAgent.indexOf('Safari') !== -1) {
            flag = 'gi';
        }
        const expr = new RegExp(searchString, flag);
        //const elements = this.serviceTextAnalyser.removeCombiningMetaChars(htmlString).split(/(<[^><]+>)/);
        let elements = this.serviceTextAnalyser.removeCombiningMetaChars(htmlString).split(/(<(?!((\/)?(sub|sup)))[^><]+>)/);
        elements = _.filter(elements, (e) => {
            return e !== undefined && !_.isEmpty(e);
        });
        let openedATag = 0;
        for (let i = 0; i < elements.length; i++) {
            // Do not replace if the word is in <a> tag
            if (elements[i].match(/<a[^>]+>/)) {
                openedATag++;
            } else if (elements[i].match(/<\/a+>/) && openedATag > 0) {
                openedATag--;
            }
            if (!elements[i].match(/(<(?!((\/)?(sub|sup)))[^><]+>)/) && !elements[i].match('/<' + DYNAMIC_LINK_MARKER_TAG + '/')) {
                if (i === 0 || elements[i - 1] && openedATag === 0) {
                    elements[i] = elements[i].replace(expr, '<' + DYNAMIC_LINK_MARKER_TAG + ' word=\"$&\"></' + DYNAMIC_LINK_MARKER_TAG + '>');
                }
            }
        }
        return elements.join('');
    }

    public getHtmlWithMarkers(htmlText: any, dynamicLinks: DynamicLinksOut[]): SafeHtml {
        let htmlWithMarkers = _.clone(htmlText);
        // Replace all known terms with a popover
        _(dynamicLinks).forEach((result: DynamicLinksOut) => {
            // Replace item.word with popover, out of html tags
            // If your item.word contains special characters then we need to escape them
            const htmlWithMarkersOld = _.cloneDeep(htmlWithMarkers);

            htmlWithMarkers = this.buildMarkerInHtmlText(
                this.makeComparaisonString(result.word),
                _.toString(htmlWithMarkers)
            );

            // Specify if the dynamic link is used (short links in longer links might not be used 
            // so we should not display it in links list and map)
            if (htmlWithMarkersOld != htmlWithMarkers) {
                result.isMatching = true;
            }
        });
        return this.domSanitizer.bypassSecurityTrustHtml(htmlWithMarkers);
    }


    public insertDynamicLinksInMarkedElements(rootHtmlElement: HTMLElement) {
        const markedHtmlElements = rootHtmlElement.getElementsByTagName(DYNAMIC_LINK_MARKER_TAG);
        setTimeout(() => {
            _(markedHtmlElements).forEach((htmlElement: HTMLElement) => {
                this.serviceDomInjector.reveal(htmlElement, this.getWordData(htmlElement.getAttribute('word')));
            });
        }, 1);
    }


    public getDynamicLinksOut(fullText: string, excludedItemId?: string, kbSlug?: string, societySlug?: string, clearDictionnary?: boolean): Observable<DynamicLinksOut[]> {
        const fullTextTrimed = fullText.trim();
        if (fullTextTrimed === '') {
            return of([]);
        }
        let host = this.urlApi;
        if (!_.isEmpty(kbSlug) && !_.isEmpty(societySlug)) {
            host = host.replace(this.currentKbSlug, kbSlug);
            host = host.replace(this.currentSocietySlug, societySlug);
        }
        clearDictionnary = clearDictionnary === false ? false : true; // clearing is the default behaviour
        return this.http.post<DynamicLinksOut[]>(host + 'dynamic-links/out', {
            'fullText': fullTextTrimed,
            'excludedItemId': excludedItemId
        }).pipe(
            first(),
            map(
                (dynlinks: any) => {
                    if (clearDictionnary) {
                        // Getting DynamicLinksOut from forum must not reset the dictionnary
                        // Should be reset only be item's detail view
                        this.dictionnary = {};
                    }

                    dynlinks = this.keepOnlyOneLinkForCurrentKb(dynlinks);

                    const results: DynamicLinksOut[] = [];
                    _(dynlinks).forEach((result: any) => {
                        if (result.word && _.keys(result.kb).length > 0) {
                            // Store founded words
                            const d = new DynamicLinksOut().deserialize(result, undefined);
                            this.addWordData(d);
                            results.push(d);
                        }
                    });
                    return results;
                }
            )
        );
    }


    public getDynamicLinksInForSocietyKb(societySlug: string, kbSlug: string, item: Item): Observable<DynamicLinkIn[]> {
        const sld = this.sld;
        const currentSocietySlug = this.currentSocietySlug;
        const currentKbSlug = this.currentKbSlug;

        const url = '//' + PREFIXS.apiPrefix + '.' + societySlug + '.' + sld + '/' + kbSlug + '/dynamic-links/in';
        return this.http.post<DynamicLinkIn[]>(url, {
            'title': item.title || '',
            'aliases': item.aliases || []
        }).pipe(
            first(),
            map(
                (dynlinks: DynamicLinkIn[]) => {
                    const results: DynamicLinkIn[] = [];
                    _(dynlinks).forEach((result: DynamicLinkIn) => {
                        // Store founded words
                        results.push(new DynamicLinkIn().deserialize(result, currentSocietySlug, currentKbSlug));
                    });
                    return results;
                }
            )
        );
    }

    /**
     * Get All dynamic Links In from all subscriptions and returns merged results
     */
    public getDynamicLinksIn(item: Item): Observable<DynamicLinkIn[]> {
        const currentSocietySlug = this.currentSocietySlug;
        const currentKbSlug = this.currentKbSlug;
        const isCurrentUserAnonymous = this.serviceSecurity.isCurrentUserAnonymous();

        const stream = new Observable(
            (observer: Observer<any>) => {
                this.serviceSubscriptions.getAllSubscriptions().subscribe(
                    (subscriptions: KbSubscription[]) => {
                        if (isCurrentUserAnonymous) {
                            // keep only public remote kbs
                            subscriptions = subscriptions.filter(function (kbSubscription: KbSubscription) {
                                return kbSubscription.isPublic === true;
                            });
                        }

                        const callables: Observable<any>[] = [];
                        // add current kb as a subscriber (just for convenience)
                        callables.push(this.getDynamicLinksInForSocietyKb(
                            currentSocietySlug, currentKbSlug, item
                        ));
                        _.forEach(subscriptions, (s: any) => {
                            callables.push(this.getDynamicLinksInForSocietyKb(
                                s.society.slug, s.slug, item
                            ));
                        });

                        // do all requests and merge results
                        forkJoin(callables)
                            .subscribe(
                                (result: any) => {
                                    const res = [].concat.apply([], result);
                                    _.remove(res, (l: DynamicLinkIn) => {
                                        return (l.id === item.id && l.kbSlug === currentKbSlug && l.societySlug === currentSocietySlug);
                                    });
                                    observer.next(res);
                                    observer.complete();
                                }
                            );
                    }
                );
            });
        return stream;
    }

    /**
     * Brower support for positive look behind regex, only implemented in webkit (chrome opera chromium etc)
     * @see https://www.chromestatus.com/feature/5668726032564224
     */
    private doesBrowserSupportPositiveLookBehindRegex(): boolean {
        if (this.browserVersion) {
            return this.browserVersion > 61;
        }
        if (window.navigator.userAgent.indexOf('Chrome') !== -1 && window.navigator.userAgent.indexOf('Edge') === -1) {
            const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
            if (raw) {
                // save result as it will not change ; no need to recompute it
                this.browserVersion = parseInt(raw[2], 10);
                if (this.browserVersion > 61) {
                    return true;
                }
            }
        }
        this.browserVersion = 1;
        return false;
    }
}
