import * as _ from 'lodash';
import { Component, OnInit, OnDestroy } from '@angular/core'
import { takeUntil, finalize, first } from 'rxjs/operators'
import { Subject } from 'rxjs/Subject'
import { AppSettings } from '@app/app.settings'

import { Kb, Language } from '@models'
import { ServiceKb, ServicePreferences, ServiceSecurity } from '@services'
import { MatSelectChange } from '@angular/material'

/**
 * PreferencesLanguageComponent
 * @export
 */
@Component({
  selector: 'preferences-language',
  templateUrl: './preferences-language.component.html',
  styleUrls: ['./preferences-language.component.scss']
})
export class PreferencesLanguageComponent implements OnInit, OnDestroy {

  public loading = false
  public saving = false
  public availableLanguages: Language[] = []
  public currentUserLanguage: string

  private _destroyed$ = new Subject()

  /**
   * Creates an instance of PreferencesLanguageComponent.
   * @param {AppSettings} appSettings Settings object
   * @param {ServiceKb} serviceKb Service object
   * @param {ServiceSecurity} serviceSecurity Security object
   * @param {ServicePreferences} servicePreferences Preferences object
   */
  constructor(
    private appSettings: AppSettings,
    private serviceKb: ServiceKb,
    private serviceSecurity: ServiceSecurity,
    private servicePreferences: ServicePreferences
  ) {
  }

  /**
   * ngOnInit
   */
  ngOnInit() {
    this.serviceKb.kbObservable.pipe(
      takeUntil(this._destroyed$)
    ).subscribe(
      (kb: Kb) => {
        this.loading = (_.size(kb.languages) === 0);
        this.availableLanguages = _.filter(kb.languages || [], function (o: Language) {
          return o.enabled === true;
        });
        this.currentUserLanguage = this.servicePreferences.getPreference('language');

        const currentLanguage = this.serviceSecurity.getCurrentLanguage();
        if (currentLanguage !== this.currentUserLanguage) {
          this.saving = true;
          this.servicePreferences.savePreference({ 'language': currentLanguage }, true).pipe(
            finalize(() => {
              this.saving = false;
            }),
            first(),
            takeUntil(this._destroyed$)
          ).subscribe(
            () => {
              this.currentUserLanguage = currentLanguage;
            }
          );
        }
      });
  }

  /**
   * This method is used by the ngFor to track items in the collection by index instead of by reference.
   * @param {number} index The index of the element in the array.
   * @param {Language} o The language object.
   * @returns {(string | number)} The index of the element in the array.
   */
  trackIds(index: number, o: Language): string | number {
    return o ? o.id : index;
  }

  /**
   * This method is used for changing the language.
   * @param {MatSelectChange} $event The event object.
   * @returns {void}
   */
  changeLanguage($event: MatSelectChange): void {
    const language = $event.value;
    this.appSettings.settings.language = language;

    this.saving = true;
    this.servicePreferences.savePreference({ 'language': language }).pipe(
      finalize(() => {
        this.saving = false;
      }),
      first(),
      takeUntil(this._destroyed$)
    ).subscribe(() => {
      this.serviceSecurity.changeLanguage(language);
    });
  }

  /**
   * ngOnDestroy
   */
  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
    this._destroyed$.unsubscribe();
  }
}
