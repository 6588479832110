import * as _ from 'lodash';
import { OnDestroy, Input, Injector, OnInit, EventEmitter, Output } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { ServicePreferences, ServiceListings, ServiceSecurity } from '@src/app/services';
import { AppSettings } from '@src/app/app.settings';
import { first, takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

export abstract class FilterByComponent implements OnInit, OnDestroy {

    @Output() isFiltersDirty = new EventEmitter();

    protected appSettings: AppSettings;
    public serviceListings: ServiceListings;
    protected servicePreferences: ServicePreferences;
    protected route: ActivatedRoute;
    protected serviceSecurity: ServiceSecurity;
    protected serviceTranslate: TranslateService;
    protected filterKey: string;

    protected _destroyed$ = new Subject();
    protected _request$: any;

    constructor(
        protected injector: Injector
    ) {
        this.appSettings = injector.get(AppSettings);
        this.serviceListings = injector.get(ServiceListings);
        this.servicePreferences = injector.get(ServicePreferences);
        this.route = injector.get(ActivatedRoute);
        this.serviceSecurity =  injector.get(ServiceSecurity);
        this.serviceTranslate = injector.get(TranslateService);
    }

    ngOnInit() {
        const userFilters = this.serviceListings.filters;
        this.initValue(userFilters);
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
        this.clearTimer();
    }

    clearTimer() { }

    initValue(listFilters: any) {
        const initialValue = _.get(listFilters, this.filterKey);
        this[this.filterKey] = initialValue;
    }

    checkIsDirty(value: any, param: string): void {
        const listingKey = this.serviceListings.getKey();
        const defaultFilters = this.serviceListings.getDefaultFilters();
        const defaultValue = _.get(defaultFilters, listingKey + '.' + param);
        const filterKey = this.filterKey;
        const isDirty = (JSON.stringify(defaultValue) !== JSON.stringify(value));

        this.isFiltersDirty.emit({ filterKey: filterKey, isDirty: isDirty });
    }

    saveFilter() {
        if (this._request$) {
            this._request$.unsubscribe();
        }
        const keepFilters = this.servicePreferences.getPreference('keepFilters');
        if (keepFilters === true) {
            const listingKey = this.serviceListings.getKey();
            this.appSettings.settings.filters[listingKey] = this.serviceListings.filters;
            this._request$ = this.servicePreferences.savePreference({ 'filters': this.appSettings.settings.filters }).pipe(
                first(),
                takeUntil(this._destroyed$)
            ).subscribe();
        }
    }
}
