import { Component, OnInit, Input } from '@angular/core';
import { ServiceErrors } from '@services/errors.service';

@Component({
    selector: 'error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
    @Input() error: any;

    public code: any;
    public title: string;
    public text: string;

    constructor(
        private serviceErrors: ServiceErrors
    ) {
    }

    ngOnInit() {
        this.code = this.error.status || this.error.code;
        if (this.code === 404) {
            this.title = 'ERRORS.NOT_FOUND.TITLE';
        } else if (this.code === 403) {
            this.title = 'ERRORS.FORBIDDEN.TITLE';
        } else {
            this.title = 'ERRORS.INTERNAL_ERROR.TITLE';
        }

        this.text = this.serviceErrors.translateMessage(this.error);

    }
}
