import * as _ from 'lodash';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ItemsDictionary, Item, Page, Member, Worklab, User, Role } from '@models';
import { CommonService } from '@services/common.service';
import { ServiceDatastore } from './datastore.service';
import { ServiceSecurity } from './security.service';

@Injectable({ providedIn: 'root' })
export class ServiceWorklabs extends CommonService {

    constructor(
        protected injector: Injector,
        private serviceSecurity: ServiceSecurity,
        private serviceDatastore: ServiceDatastore
    ) {
        super(injector);
    }

    sortArray(unsortedArray: Worklab[], orderDirection: 'asc' | 'desc'): Worklab[] {
        return this.serviceDatastore.sortArray<Worklab>(unsortedArray, orderDirection);
    }

    fetchAllPotentialLinkedItems<T>(alreadyLinkedItems: T[], currentItem: Page | Member | Worklab): Observable<Item[]> {
        const alreadyLinkedItemsIds = _.map(alreadyLinkedItems, 'id');

        return this.serviceDatastore.dataStoreObservable.pipe(
            /* filter((allItems: Item[]) => allItems !== null), */
            map(
                (allItems: ItemsDictionary) => {
                    let allPotentialItems = _.filter(allItems, function(item: Item) {
                        return item.group === 'Worklab' && _.indexOf(alreadyLinkedItemsIds, item.id) === -1 && item.id !== currentItem.id;
                    });
                    allPotentialItems = _.orderBy(allPotentialItems, function(potentialItem: Item) {
                        return _.toLower(potentialItem.title);
                    }, ['asc']);
                    return <Worklab[]>allPotentialItems;
                }
            )
        );
    }

    userCanModifyItem<T extends Item>(item: T): boolean {
        const isUserAdmin = this.serviceSecurity.isAdmin();
        const isItemLocked = item.islocked;
        const itemCanHaveParticipants = item.group === 'Worklab';
        const isUserParticipant = _.get(item, 'isUserParticipant');
        const isAccessGrantedByRole = this.serviceSecurity.hasMinimumRole(Role.ROLE_CONTRIBUTOR);

        return itemCanHaveParticipants && (isUserAdmin || (!isItemLocked && isAccessGrantedByRole && isUserParticipant));
    }

    userCanCreateItem(): boolean {
        const isUserAdmin = this.serviceSecurity.isAdmin();
        const isAccessGrantedByRole = this.serviceSecurity.hasMinimumRole(Role.ROLE_CONTRIBUTOR);

        return isUserAdmin || isAccessGrantedByRole;
    }

    userCanRemoveItem(item: any): boolean {
        const isUserAdmin = this.serviceSecurity.isAdmin();

        return isUserAdmin;
    }

    userCanChangeItemToPage(item: any): boolean {
        const isUserAdmin = this.serviceSecurity.isAdmin();
        const isItemLocked = item.islocked;
        const itemCanHaveParticipants = item.group === 'Worklab';
        const isUserParticipant = item.isUserParticipant;
        const isAccessGrantedByRole = this.serviceSecurity.hasMinimumRole(Role.ROLE_CONTRIBUTOR);

        return itemCanHaveParticipants && (isUserAdmin || (!isItemLocked && isAccessGrantedByRole && isUserParticipant));
    }

    userCanModifyContent(item: Worklab): boolean {
        const isUserAdmin = this.serviceSecurity.isAdmin();
        const isItemLocked = item.islocked;
        const itemCanHaveParticipants = item.group === 'Worklab';
        const isUserParticipant = item.isUserParticipant;
        const isAccessGrantedByRole = this.serviceSecurity.hasMinimumRole(Role.ROLE_CONTRIBUTOR);

        return itemCanHaveParticipants && (isUserAdmin || (!isItemLocked && isAccessGrantedByRole && isUserParticipant));
    }

    userCanModifyParticipants(item: Worklab): boolean {
        const isUserAdmin = this.serviceSecurity.isAdmin();
        const isItemLocked = item.islocked;
        const itemCanHaveParticipants = item.group === 'Worklab';
        const isPrivate = (item.isPrivate === true);
        const isUserParticipant = item.isUserParticipant;
        const isAccessGrantedByRole = this.serviceSecurity.hasMinimumRole(Role.ROLE_READER);

        return itemCanHaveParticipants && (isUserAdmin || (!isItemLocked && isAccessGrantedByRole && ((isUserParticipant && isPrivate) || (!isPrivate))));
    }

    userCanModifyPages(item: Worklab): boolean {
        const isUserAdmin = this.serviceSecurity.isAdmin();
        const isItemLocked = item.islocked;
        const itemCanHaveParticipants = item.group === 'Worklab';
        const isUserParticipant = item.isUserParticipant;
        const isAccessGrantedByRole = this.serviceSecurity.hasMinimumRole(Role.ROLE_CONTRIBUTOR);

        return itemCanHaveParticipants && (isUserAdmin || (!isItemLocked && isAccessGrantedByRole && isUserParticipant));
    }

    startParticipating(worklab: Worklab, member: Member | User): Observable<any> {
        if (!this.userCanModifyParticipants(worklab)) {
            return this.serviceErrors.throwError('ITEM.PARTICIPANTS.ADD', { code: 403 });
        }
        return this.http.post(this.urlApi + 'links/' + member.uid + '/' + worklab.uid, {}).pipe(
            tap(
                () => {
                    this.serviceDatastore.startParticipating(worklab.id, member.id, this.serviceSecurity.user);
                }
            )
        );
    }

    stopParticipating(worklab: Worklab, member: Member | User): Observable<any> {
        if (!this.userCanModifyParticipants(worklab)) {
            return this.serviceErrors.throwError('ITEM.PARTICIPANTS.REMOVE', { code: 403 });
        }
        return this.http.delete(this.urlApi + 'links/' + member.uid + '/' + worklab.uid, {}).pipe(
            tap(
                () => {
                    this.serviceDatastore.stopParticipating(worklab.id, member.id, this.serviceSecurity.user);
                }
            )
        );
    }

    addPage(worklab: Worklab, page: Page): Observable<any> {
        if (!this.userCanModifyPages(worklab)) {
            return this.serviceErrors.throwError('ITEM.LINKED_PAGES_TO_WORKLAB.ADD', { code: 403 });
        }
        return this.http.post(this.urlApi + 'links/' + page.uid + '/' + worklab.uid, {}).pipe(
            tap(
                () => {
                    this.serviceDatastore.addPageToWorklab(page.id, worklab.id);
                }
            )
        );
    }

    removePage(worklab: Worklab, page: Page): Observable<any> {
        if (!this.userCanModifyPages(worklab)) {
            return this.serviceErrors.throwError('ITEM.LINKED_PAGES_TO_WORKLAB.REMOVE', { code: 403 });
        }
        return this.http.delete(this.urlApi + 'links/' + page.uid + '/' + worklab.uid, {}).pipe(
            tap(
                () => {
                    this.serviceDatastore.removePageFromWorklab(page.id, worklab.id);
                }
            )
        );
    }
}
