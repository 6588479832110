import * as _ from 'lodash';
import { Component, Injector } from '@angular/core';
import { ServiceItems } from '@services';
import { FilterByComponent } from '../abstract-filter-by.component';

@Component({
    selector: 'filters-by-link-type',
    templateUrl: './filters-by-link-type.component.html',
    styleUrls: ['./filters-by-link-type.component.scss']
})
export class FiltersByLinkTypeComponent extends FilterByComponent {
    public currentGroup: string;

    // Items
    public creator = false;
    public modifier = false;
    public dynlinksIn = false;
    public dynlinksOut = false;

    // Page
    public expert = false;
    public memberFollowPage = false;
    public associatedPageOut = false;
    public linkedWorklab = false;

    // Member
    public expertise = false;
    public followedPage = false;
    public followedWorklabs = false;
    public memberFollowMember = false;

    // Worklab
    public participant = false;
    public linkedPage = false;

    constructor(
        protected injector: Injector,
        protected serviceItems: ServiceItems
    ) {
        super(injector);
        this.filterKey = 'linkTypes';
        this.currentGroup = _.get(this.serviceItems.item, 'group');
    }


    initValue(listFilters: any) {
        const numbers = _.get(listFilters, this.filterKey);
        this.creator = (_.indexOf(numbers, 'creator') !== -1);
        this.modifier = (_.indexOf(numbers, 'modifier') !== -1);
        this.dynlinksIn = (_.indexOf(numbers, 'dynlinksIn') !== -1);
        this.dynlinksOut = (_.indexOf(numbers, 'dynlinksOut') !== -1);

        this.expert = (_.indexOf(numbers, 'expert') !== -1);
        this.memberFollowPage = (_.indexOf(numbers, 'memberFollowPage') !== -1);
        this.associatedPageOut = (_.indexOf(numbers, 'associatedPageOut') !== -1);
        this.linkedWorklab = (_.indexOf(numbers, 'linkedWorklab') !== -1);

        this.participant = (_.indexOf(numbers, 'participant') !== -1);
        this.linkedPage = (_.indexOf(numbers, 'linkedPage') !== -1);
    }

    onChange(apply?: boolean) {
        apply !== false ? apply = true : apply = false;
        const linkTypes = [];
        if (this.creator) {
            linkTypes.push('creator');
        }
        if (this.modifier) {
            linkTypes.push('modifier');
        }
        if (this.dynlinksIn) {
            linkTypes.push('dynlinksIn');
        }
        if (this.dynlinksOut) {
            linkTypes.push('dynlinksOut');
        }
        if (this.expert) {
            linkTypes.push('expert');
        }
        if (this.memberFollowPage) {
            linkTypes.push('memberFollowPage');
        }
        if (this.associatedPageOut) {
            linkTypes.push('associatedPageOut');
        }
        if (this.linkedWorklab) {
            linkTypes.push('linkedWorklab');
        }
        if (this.expertise) {
            linkTypes.push('expertise');
        }
        if (this.followedPage) {
            linkTypes.push('followedPage');
        }
        if (this.followedWorklabs) {
            linkTypes.push('followedWorklabs');
        }
        if (this.memberFollowMember) {
            linkTypes.push('memberFollowMember');
        }
        if (this.participant) {
            linkTypes.push('participant');
        }
        if (this.linkedPage) {
            linkTypes.push('linkedPage');
        }

        let filters = this.serviceListings.filters;
        if (linkTypes.length === 0) {
            if ('linkTypes' in this.serviceListings.filters) {
                delete filters[this.filterKey];
                this.isFiltersDirty.emit({ filterKey: this.filterKey, isDirty: false });
            }
        } else {
            filters = _.extend(filters, {
                'linkTypes': linkTypes
            });
            this.checkIsDirty(linkTypes, this.filterKey);
        }
        this.serviceListings.filters = filters;

        if (apply) {
            this.serviceListings.dataStoreChanged();
            // this.saveFilter();
        }
    }

    reset() {
        this.creator = false;
        this.modifier = false;
        this.dynlinksIn = false;
        this.dynlinksOut = false;
        this.expert = false;
        this.memberFollowPage = false;
        this.associatedPageOut = false;
        this.linkedWorklab = false;

        this.expertise = false;
        this.followedPage = false;
        this.followedWorklabs = false;
        this.memberFollowMember = false;

        this.participant = false;
        this.linkedPage = false;
        this.onChange(false);
    }
}
