import { Deserializable } from './deserializable.model';

export class Document implements Deserializable {
    id: string;
    title: string;
    key: string;
    size: string;
    extension: string;
    url: string;
    group = 'Document';

    underEdition?: boolean;
    isLoadingEdition?: boolean;
    isLoadingRemove?: boolean;

    deserialize(input: any, currentUser?: any) {
        input.url = '/attachments/' + input.key + '.' + input.extension;

        Object.assign(this, input);
        return this;
    }
}
