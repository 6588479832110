import * as _ from 'lodash';
import { Deserializable } from './deserializable.model';
import { Document } from './document.model';
import { Tag } from './tag.model';
import { DynamicLinksOut } from './dynamic-links-out.model';
import { DynamicLinkIn } from './dynamic-link-in.model';
import { ProsimConstant, ProsimDynamic } from '.';

export const FIRSTNAME_MIN_SIZE = 2;
export const LASTNAME_MIN_SIZE = 2;

export enum TITLE_MIN_SIZE {
    Item = 3,
    Page = 3,
    Member = 3,
    Worklab = 3
}

export enum CONTENT_MIN_SIZE {
    Item = 10,
    Page = 10,
    Member = 10,
    Worklab = 10
}

export class Item implements Deserializable {
    witness: any;

    id: string;
    title: string;
    group: 'Page' | 'Member' | 'Worklab';
    mid: string;
    cover: string;
    islocked = false;
    ishomepage = false;
    content: string;
    summary: string;
    aliases: string[] = [];
    tags: Tag[] = [] = [];
    creators: Member[] = [];
    modifiers: Member[] = [];
    documents: Document[] = [];
    score: any;
    isMatchingByForum = false; // Filled from search query only
    isMatchingByTag = false;   // Filled from search query only
    useIA: boolean;

    uid: string;
    url: string;
    titleWithoutAccent: string;
    removed = false;
    isDirty = false;
    isSaving = false;
    editionMode = false;
    errors: any = {};
    linkTypes: string[] = [];

    newPicture: any; // Image on creation




    topics?: any[];
    nbTopics?: number;

    society?: string;
    kb?: string;
    private _dynlinksIn?: DynamicLinkIn[];
    set dynlinksIn(value: DynamicLinkIn[]) {
        this._dynlinksIn = value;
        if (this.witness) {
            this.witness._dynlinksIn = value;
        }
    }
    get dynlinksIn(): DynamicLinkIn[] {
        return this._dynlinksIn;
    }
    private _dynlinksOut?: DynamicLinksOut[];
    set dynlinksOut(value: DynamicLinksOut[]) {
        this._dynlinksOut = value;
        if (this.witness) {
            this.witness.dynlinksOut = value;
        }
    }
    get dynlinksOut(): DynamicLinksOut[] {
        return this._dynlinksOut;
    }

    recommendations?: any;
    isLinked?: boolean;
    isLoading?: boolean;


    isUserCreator: boolean;
    isUserModifier: boolean;

    userCanModifyItem?: boolean;

    date?: any;
    counter?: number;

    constructor(input?: any, currentUser?: any) {
        if (input && input.group === 'Page') {
            return new Page().deserialize(input, currentUser);
        } else if (input && input.group === 'Member') {
            return new Member().deserialize(input, currentUser);
        } else if (input && input.group === 'Worklab') {
            return new Worklab().deserialize(input, currentUser);
        }
    }

    deserialize(input: any, currentUser: any) {
        if (input.id) {
            input.uid = (input.id + '').replace('#', '').replace(':', '-');
            input.url = '/items/' + _.toLower(input.group) + '/' + input.uid;
        } else {
            input.modifiers = [currentUser];
            input.creators = [currentUser];
        }

        input.aliases = input.aliases || [];
        input.modifiers = _.orderBy(_.map(input.modifiers || [], function (o: any) {
            return new Member().deserialize({
                id: o.id,
                title: o.title,
                firstname: o.firstname,
                lastname: o.lastname,
                email: o.email,
                mid: o.mid,
                date: o.date
            }, currentUser);
        }), ['date'], ['desc']);
        this.isUserModifier = (_.findIndex(input.modifiers, function (o: any) { return o.id === _.get(currentUser, 'id'); }) !== -1);

        // creators is the first modifier if creators is empty
        if (_.size(input.creators) === 0) {
            const firstModifier = _.first(input.modifiers);
            if (firstModifier) {
                input.creators = [firstModifier];
            }
        }
        input.creators = _.orderBy(_.map(input.creators || [], function (o: any) {
            return new Member().deserialize({
                id: o.id,
                title: o.title,
                firstname: o.firstname,
                lastname: o.lastname,
                email: o.email,
                mid: o.mid,
                date: o.date
            }, currentUser);
        }), function (i) {
            const v = _.toLower(_.get(i, 'lastname', '') + _.get(i, 'firstname', ''));
            return (v === '') ? _.toLower(_.get(i, 'title', '')) : v;
        }, ['asc']);

        this.isUserCreator = (_.findIndex(input.creators, function (o: any) { return o.id === _.get(currentUser, 'id'); }) !== -1);

        input.documents = _.orderBy(_.map(input.documents || [], function (document: Document) {
            return new Document().deserialize({
                id: document.id,
                title: document.title,
                key: document.key,
                size: document.size,
                extension: document.extension,
                url: document.url
            }, currentUser);
        }), function (i) { return _.toLower(_.get(i, 'title', '')); }, ['asc']);

        input.tags = _.orderBy(_.map(input.tags || [], function (tag: Tag) {
            return new Tag().deserialize({
                id: tag.id,
                title: tag.title,
                parent: tag.parent,
                url: tag.url,
                path: tag.path
            }, currentUser);
        }), function (i) { return _.toLower(_.get(i, 'path', '')); }, ['asc']);

        Object.assign(this, input);
        return this;
    }

    getTitleMinSize() {
        return TITLE_MIN_SIZE[this.group];
    }

    getContentMinSize() {
        return CONTENT_MIN_SIZE[this.group];
    }

    getImagePath(): string {
        let imagePath: string;
        let imageSrc: string;

        const isMember = this.group === 'Member';

        if (this.cover) {
            imagePath = '/images/' + _.toLower(this.group) + '/';
        } else {
            imagePath = (isMember) ? '/images/member/' : '/images/thumbnails/';
        }

        const defaultPicture = (isMember) ? imagePath + 'no-avatar.png' : imagePath + 'no-thumbnail.png';

        if (this.cover) {
            imageSrc = imagePath + this.cover + '.png';
        } else {
            imageSrc = (this.mid) ? imagePath + this.mid + '.png' : defaultPicture;
        }
        return imageSrc;
    }

    hasErrors(args: any = {}) {
        const errors = this.errors || {};

        const serviceAliases = args.serviceAliases;
        if (!serviceAliases || !serviceAliases) {
            return errors;
        }

        const isCreation = (!this.id);
        const title = serviceAliases.cleanAlias(this.title);
        const witnessTitle = (this.witness && !isCreation) ? serviceAliases.cleanAlias(this.witness.title) : '';
        const titleMinSize = this.getTitleMinSize();
        const titleAlreadyUsed = (isCreation && title === witnessTitle) || (title !== witnessTitle && !serviceAliases.isNewAliasNotAlreadyUsed(title));

        if (_.size(_.trim(title)) < titleMinSize) {
            errors.title = { key: 'tooShort', label: 'ITEM.TITLE.ERRORS.TOO_SHORT', min: titleMinSize };
        } else if (titleAlreadyUsed) {
            errors.title = { key: 'alreadyUsed', label: 'ITEM.TITLE.ERRORS.ALREADY' };
        } else {
            delete errors.title;
        }

        const isMember = (this.group === 'Member');
        if(!isMember) {
            const content = (this.content || '').replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ''); // Remove HTML tags and &nbsp;
            const contentMinSize = this.getContentMinSize();
            if (_.size(_.trim(content)) < contentMinSize) {
                errors.content = { key: 'tooShort', label: 'ITEM.CONTENT.ERRORS.TOO_SHORT', min: contentMinSize };
            } else {
                delete errors.content;
            }
        } else {
            delete errors.content;
        }
        return errors;
    }

    getLinksIn() {
        return [];
    }
    getLinksOut() {
        return [];
    }
}

export class Page extends Item {
    status: 'draft' | 'to_validate' | 'validated';
    experts: any[];
    isUserExpert: boolean;
    followers: any[];
    isUserFollowing: boolean;
    associatedPagesOut: any[];
    linkedWorklabs: any[];
    allProsimData: (ProsimConstant | ProsimDynamic)[];

    deserialize(input: any, currentUser: any) {
        input.group = 'Page';
        input.status = input.status || 'draft';
        input = super.deserialize(input, currentUser);
        const username = _.get(currentUser, 'username', '');
        if (!input.id &&  !username.includes('@meetsys.com')) {
            input.followers = [currentUser];
        }

        input.experts = _.orderBy(_.map(input.experts || [], function (member: any) {
            return new Member().deserialize({
                id: member.id,
                title: member.title,
                firstname: member.firstname,
                lastname: member.lastname,
                email: member.email,
                mid: member.mid
            }, currentUser);
        }), function (i) {
            const v = _.toLower(_.get(i, 'lastname', '') + _.get(i, 'firstname', ''));
            return (v === '') ? _.toLower(_.get(i, 'title', '')) : v;
        }, ['asc']);

        input.isUserExpert = (_.findIndex(input.experts, function (o: any) { return o.id === _.get(currentUser, 'id'); }) !== -1);

        input.followers = _.orderBy(_.map(_.filter(input.followers || input.linksIn || [], function (o: any) { return o.group === 'Member'; }), function (member: Member) {
            return new Member().deserialize({
                id: member.id,
                title: member.title,
                firstname: member.firstname,
                lastname: member.lastname,
                email: member.email,
                mid: member.mid
            }, currentUser);
        }), function (i) {
            const v = _.toLower(_.get(i, 'lastname', '') + _.get(i, 'firstname', ''));
            return (v === '') ? _.toLower(_.get(i, 'title', '')) : v;
        }, ['asc']);

        input.isUserFollowing = (_.findIndex(input.followers, function (o: any) { return o.id === _.get(currentUser, 'id'); }) !== -1);

        input.associatedPagesOut = _.orderBy(
            _.map(
                _.filter(input.associatedPagesOut || input.linksOut || [], function (o: any) {
                    return o.group === 'Page';
            }),
            function (page: Page) {
                return new Page().deserialize({
                    id: page.id,
                    title: page.title,
                    islocked: page.islocked,
                    cover: page.cover,
                    mid: page.mid
                }, currentUser);
            }
        ), function (i) {
            return _.toLower(_.get(i, 'title', ''));
        }, ['asc']);

        input.linkedWorklabs = _.orderBy(_.map(_.filter(input.linkedWorklabs || input.linksOut, function (o) { return o.group === 'Worklab'; }), function (worklab: Worklab) {
            return new Worklab().deserialize({
                id: worklab.id,
                title: worklab.title,
                isPrivate: worklab.isPrivate,
                cover: worklab.cover,
                mid: worklab.mid
            }, currentUser);
        }), function (i) { return _.toLower(_.get(i, 'title', '')); }, ['asc']);

        input.allProsimData = input.prosimData || [];
        _.forEach(input.allProsimData, function(value, key) {
            if (value.type === 'dynamic') {
                value.options = {
                    step: 1,
                    floor: value.tmin,
                    ceil: value.tmax,
                    noSwitching: true
                };
            }
        });

        return this;
    }

    hasErrors(args: any = {}) {
        return super.hasErrors(args);
    }

    getLinksIn() {
        return this.followers;
    }
    getLinksOut() {
        return _.concat(this.associatedPagesOut, this.linkedWorklabs);
    }
}

export class Member extends Item {
    lastname?: string;
    firstname?: string;
    email?: string;
    job_titles?: string;
    adress?: string;
    company?: string;
    facebook?: string;
    twitter?: string;
    linkedin?: string;
    google?: string;
    phone?: string;

    password?: string;
    confirmPassword?: string;

    rights?: any;
    expertises?: any[];
    followedMembers?: any[];
    followedPages?: any[];
    followedWorklabs?: any[];
    followers?: any[];
    isUserFollowing?: boolean;
    role?: string;
    lastLogin?: string;

    deserialize(input: any, currentUser: any) {
        input.group = 'Member';
        input = super.deserialize(input, currentUser);

        input.expertises = _.orderBy(_.map(input.expertises || input.pagesAsExpert || [], function (page: Page) {
            return new Page().deserialize({
                id: page.id,
                title: page.title,
                islocked: page.islocked,
                cover: page.cover,
                mid: page.mid
            }, currentUser);
        }), function (i) { return _.toLower(_.get(i, 'title', '')); }, ['asc']);

        input.followedMembers = _.orderBy(
            _.map(
                _.filter(input.followedMembers || input.linksOut || [], function (o: any) {
                    return o.group === 'Member';
                }),
                function (member: Member) {
                    return new Member().deserialize({
                        id: member.id,
                        title: member.title,
                        firstname: member.firstname,
                        lastname: member.lastname,
                        email: member.email,
                        mid: member.mid
                    }, currentUser);
                }
            ),
            function (i) {
                const v = _.toLower(_.get(i, 'lastname', '') + _.get(i, 'firstname', ''));
                return (v === '') ? _.toLower(_.get(i, 'title', '')) : v;
            }, ['asc']
        );

        input.followedPages = _.orderBy(
            _.map(
                _.filter(input.followedPages || input.linksOut || [], function (o) {
                    return o.group === 'Page';
                }),
                function (page: Page) {
                    return new Page().deserialize({
                        id: page.id,
                        title: page.title,
                        islocked: page.islocked,
                        cover: page.cover,
                        mid: page.mid
                    }, currentUser);
                }
            ), ['title'], ['asc']
        );

        input.followedWorklabs = _.orderBy(
            _.map(
                _.filter(input.followedWorklabs || input.linksOut || [], function (o) { return o.group === 'Worklab'; }), function (worklab: Worklab) {
                    return new Worklab().deserialize({
                        id: worklab.id,
                        title: worklab.title,
                        isPrivate: worklab.isPrivate,
                        cover: worklab.cover,
                        mid: worklab.mid
                    }, currentUser);
                }), ['title'], ['asc']);

        input.followers = _.orderBy(_.map(_.filter(input.followers || input.linksIn || [], function (o: any) { return o.group === 'Member'; }), function (member: Member) {
            return new Member().deserialize({
                id: member.id,
                title: member.title,
                firstname: member.firstname,
                lastname: member.lastname,
                email: member.email,
                mid: member.mid
            }, currentUser);
        }), function (i) {
            const v = _.toLower(_.get(i, 'lastname', '') + _.get(i, 'firstname', ''));
            return (v === '') ? _.toLower(_.get(i, 'title', '')) : v;
        }, ['asc']);

        input.isUserFollowing = (_.findIndex(input.followers, function (o: any) { return o.id === _.get(currentUser, 'id'); }) !== -1);

        Object.assign(this, input);
        return this;
    }

    hasErrors(args: any = {}) {
        const errors = super.hasErrors(args);

        const firstname = this.firstname;
        const lastname = this.lastname;

        if (!firstname && !lastname) {
            delete errors.title;
        }

        if (_.size(_.trim(firstname)) < FIRSTNAME_MIN_SIZE) {
            errors.firstname = { key: 'tooShort', label: 'MEMBER.ERRORS.FIRSTNAME.TOO_SHORT', min: FIRSTNAME_MIN_SIZE };
        } else {
            delete errors.firstname;
        }

        if (_.size(_.trim(lastname)) < LASTNAME_MIN_SIZE) {
            errors.lastname = { key: 'tooShort', label: 'MEMBER.ERRORS.LASTNAME.TOO_SHORT', min: LASTNAME_MIN_SIZE };
        } else {
            delete errors.lastname;
        }

        const email = this.email;
        const allEmails = args.serviceMembers.allEmails;
        const witnessEmail = (this.witness) ? this.witness.email : '';

        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const emailAlreadyUsed = allEmails && email !== witnessEmail && allEmails.indexOf(email) !== -1;
        if (_.trim(email) === '') {
            errors.email = { key: 'empty', label: 'MEMBER.ERRORS.EMAIL.EMPTY' };
        } else if (!re.test(String(email).toLowerCase())) {
            errors.email = { key: 'notValid', label: 'MEMBER.ERRORS.EMAIL.NOT_VALID' };
        } else if (emailAlreadyUsed) {
            errors.email = { key: 'alreadyUsed', label: 'MEMBER.ERRORS.EMAIL.ALREADY' };
        } else {
            delete errors.email;
        }

        return errors;
    }

    getLinksIn() {
        return this.followers;
    }
    getLinksOut() {
        return _.concat(this.followedMembers, this.followedPages, this.followedWorklabs);
    }
}

export class Worklab extends Item {
    isPrivate = false;

    participants: any[];
    isUserParticipant: boolean;

    linkedPages: any[];

    deserialize(input: any, currentUser: any) {
        input.group = 'Worklab';
        input = super.deserialize(input, currentUser);

        input.participants = _.orderBy(_.map(_.filter(input.participants || input.linksIn || [], function (o: any) { return o.group === 'Member'; }), function (member: Member) {
            return new Member().deserialize({
                id: member.id,
                title: member.title,
                firstname: member.firstname,
                lastname: member.lastname,
                email: member.email,
                mid: member.mid
            }, currentUser);
        }), function (i) {
            const v = _.toLower(_.get(i, 'lastname', '') + _.get(i, 'firstname', ''));
            return (v === '') ? _.toLower(_.get(i, 'title', '')) : v;
        }, ['asc']);

        input.isUserParticipant = (_.findIndex(input.participants, function (o: any) { return o.id === _.get(currentUser, 'id'); }) !== -1);

        input.linkedPages = _.orderBy(_.map(_.filter(input.linkedPages || input.linksIn || [], function (o: any) { return o.group === 'Page'; }), function (page: Page) {
            return new Page().deserialize({
                id: page.id,
                title: page.title,
                islocked: page.islocked,
                cover: page.cover,
                mid: page.mid
            }, currentUser);
        }), function (i) { return _.toLower(_.get(i, 'title', '')); }, ['asc']);

        Object.assign(this, input);
        return this;
    }

    hasErrors(args: any = {}) {
        return super.hasErrors(args);
    }

    getLinksIn() {
        return _.concat(this.participants, this.linkedPages);
    }
}
