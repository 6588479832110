import * as _ from 'lodash';
import { Component, Input } from '@angular/core';

import { Role } from '@models';
import { Worklab } from '@models/item.model';
import { ServiceSecurity, ServiceItems } from '@services';

@Component({
    selector: 'is-private',
    templateUrl: './is-private.component.html',
    styleUrls: ['./is-private.component.scss']
})
export class IsPrivateComponent {

    private _worklab: Worklab;
    @Input()
    set worklab(value: Worklab) {
        this._worklab = value;
        this._setAttributes();
    }
    get worklab(): Worklab {
        return this._worklab;
    }

    private _editionMode: boolean;
    @Input()
    set editionMode(value: boolean) {
        this._editionMode = value;
        this._setAttributes();
    }
    get editionMode(): boolean {
        return this._editionMode;
    }

    public class: string;
    public label: string;
    public isGranted = false;
    public isWorklabPrivate = false;

    private isAdmin = false;

    constructor(
        private serviceItems: ServiceItems,
        private serviceSecurity: ServiceSecurity
    ) {
        this.isAdmin = this.serviceSecurity.isAdmin();
    }

    private _setAttributes() {
        this.isWorklabPrivate = _.get(this, '_worklab.isPrivate', false);
        this.isGranted = this.isAdmin || (this.serviceSecurity.hasMinimumRole(Role.ROLE_CONTRIBUTOR) && this._worklab.isUserParticipant );

        if (this.isWorklabPrivate) {
            this.label = 'ITEM.PRIVACY.PRIVATE';
            this.class = 'private';
        } else if (!this.isWorklabPrivate) {
            this.label = 'ITEM.PRIVACY.PUBLIC';
            this.class = 'public';
        }
    }

    onSlide($event) {
        if (this.isGranted === true) {
            this._worklab.isPrivate = $event.checked;
            this.serviceItems.item = this._worklab;
            this._setAttributes();
        }
    }
}
