import { Component, Input } from '@angular/core';

import { Kb, Skin } from '@models';
import { ServiceKb } from '@services';

@Component({
    selector: 'kb-skins',
    templateUrl: './kb-skins.component.html',
    styleUrls: ['./kb-skins.component.scss']
})
export class KbSkinsComponent {

    private _witnessKb: Kb;

    private _kb: Kb;
    @Input()
    set kb(value: Kb) {
        this._kb = value;
    }
    get kb(): Kb {
        return this._kb;
    }

    private _editionMode: boolean;
    @Input()
    set editionMode(value: boolean) {
        this._editionMode = value;
    }
    get editionMode(): boolean {
        return this._editionMode;
    }

    constructor(
        private serviceKb: ServiceKb
    ) { }

    onSlide($event) {
        this._kb.skins = this.kb.skins;
        this.serviceKb.kb = this._kb;
    }

    trackSkins(index: number, o: Skin) {
        return o ? o.INTERNAL_ID : index;
    }
}
