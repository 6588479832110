import * as _ from 'lodash';
import { Component, Input, OnDestroy, Attribute } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import { finalize, first, takeUntil } from 'rxjs/operators';

import { Item } from '@models';
import { AppSettings } from '@src/app/app.settings';
import { ServiceItems, ServiceErrors, ServiceToaster } from '@services';

@Component({
    selector: 'button-edit-item',
    templateUrl: './button-edit-item.html',
    styleUrls: ['./button-edit-item.scss']
})
export class ButtonEditItemComponent implements OnDestroy {

    @Input() disabled = false;

    private _item: Item;
    @Input()
    set item(value: Item) {
        this._item = value;
    }
    get item(): Item {
        return this._item;
    }

    public get propagateClick(): 'true' | 'false' {
        return this._propagateClick;
    }
    public set propagateClick(value: 'true' | 'false') {
        this._propagateClick = value;
    }

    private _destroyed$ = new Subject();

    constructor(
        private appSettings: AppSettings,
        private serviceItems: ServiceItems,
        private serviceErrors: ServiceErrors,
        private serviceToaster: ServiceToaster,
        @Attribute('propagateClick') private _propagateClick: 'true' | 'false' = 'true'
    ) {
    }

    ngOnDestroy() {
        // Dont do it snce the button will be hide and the get request will be cancelled
        // this._destroyed$.next();
        // this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }

    editItem() {
        const originalItem = this.item;
        const itemId = this.item.uid;
        const isCreation = _.isUndefined(itemId);

        if (!isCreation) {
            this.appSettings.settings.savingInsideSpinner = true;

            // Feature requested: reload item to be sure to have the latest version
            this.serviceItems.getItem(itemId).pipe(
                finalize(() => {
                    this.appSettings.settings.savingInsideSpinner = false;
                }),
                first()
                // takeUntil(this._destroyed$)
            ).subscribe(
                (item: Item) => {
                    item.editionMode = true;
                    item.dynlinksIn = _.get(this.serviceItems, 'item.dynlinksIn');
                    item.dynlinksOut = _.get(this.serviceItems, 'item.dynlinksOut');
                    this.serviceItems.item = item;

                    const isModified = !this.serviceItems.areEquals(item, originalItem);
                    if (isModified === true) {
                        this.serviceToaster.warning('EDIT_ITEM.IS_MODIFIED');
                    }
                },
                (error: HttpErrorResponse) => {
                    const item = this.serviceItems.item;
                    item.editionMode = false;
                    this.serviceItems.item = item;
                    this.serviceErrors.handleError('EDIT_ITEM', error);
                }
            );
        } else {
            const item = this.serviceItems.item;
            item.editionMode = true;
            this.serviceItems.item = item;
        }
    }
}
