import * as _ from 'lodash';
import { Component, Injector } from '@angular/core';
import { FilterByComponent } from '../abstract-filter-by.component';

@Component({
    selector: 'filters-by-expert-number',
    templateUrl: './filters-by-expert-number.component.html',
    styleUrls: ['./filters-by-expert-number.component.scss']
})
export class FiltersByExpertNumberComponent extends FilterByComponent {

    public zero = false;
    public one = false;
    public two = false;
    public three = false;
    public more = false;

    constructor(
        protected injector: Injector
    ) {
        super(injector);
        this.filterKey = 'nb-experts';
    }

    initValue(listFilters: any) {
        const numbers = _.get(listFilters, this.filterKey);
        this.zero = (_.indexOf(numbers, 0) !== -1) || _.parseInt(numbers) === 0;
        this.one = (_.indexOf(numbers, 1) !== -1) || _.parseInt(numbers) === 1;
        this.two = (_.indexOf(numbers, 2) !== -1) || _.parseInt(numbers) === 2;
        this.three = (_.indexOf(numbers, 3) !== -1) || _.parseInt(numbers) === 3;
        this.more = (_.indexOf(numbers, 4) !== -1 || numbers === 'more');
    }

    onChange(apply?: boolean) {
        apply !== false ? apply = true : apply = false;
        let numbers = [];
        if (this.zero) {
            numbers.push(0);
        }
        if (this.one) {
            numbers.push(1);
        }
        if (this.two) {
            numbers.push(2);
        }
        if (this.three) {
            numbers.push(3);
        }
        if (this.more) {
            numbers = numbers.concat([4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]); // we assume no page will have more than 15 experts...
        }

        const allChecked = this.zero && this.one && this.two && this.three && this.more;

        let filters = this.serviceListings.filters;
        if ((numbers.length === 0 || allChecked)) {
            if ('nb-experts' in this.serviceListings.filters) {
                delete filters[this.filterKey];
                this.isFiltersDirty.emit({ filterKey: this.filterKey, isDirty: false });
            }
        } else {
            filters = _.extend(filters, {
                'nb-experts': numbers
            });
            this.checkIsDirty(numbers, this.filterKey);
        }
        this.serviceListings.filters = filters;

        if (apply) {
            this.serviceListings.dataStoreChanged();
            this.saveFilter();
        }
    }

    reset() {
        this.zero = false;
        this.one = false;
        this.two = false;
        this.three = false;
        this.more = false;
        this.onChange(false);
    }
}
