import { Component, ViewChild, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
    selector: 'dialog-preferences-detail.component',
    styleUrls: ['./dialog-preferences-detail.scss'],
    templateUrl: './dialog-preferences-detail.html',
})
export class DialogPreferencesDetailComponent {

    public blocksChanged = new EventEmitter();

    @ViewChild('preferencesPreferencesLinkedBlocksComponent', {static: false}) preferencesDetailComponent: any;

    constructor(
        public dialogRef: MatDialogRef<DialogPreferencesDetailComponent>
    ) { }

    savePreferences(): void {
        this.preferencesDetailComponent.savePreferences();
    }

    saveConfirmed() {
        this.dialogRef.close();
    }

    propagateChanges(currentUserPreferencesLinkedBlocks: any){
        this.blocksChanged.emit(currentUserPreferencesLinkedBlocks);
    }
}
