export class ListingSettings {
    public type: string;
    public group: string;
    public sort: any;
    public filters: any;
    public view: string;
    public items: any;

    constructor(
        listingSettings: any
    ) {
        Object.assign(this, listingSettings);
      }
}
