import * as _ from 'lodash';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { takeUntil } from 'rxjs/operators';

import { Item, Order, PreferencesLinkedBlocks } from '@models';
import { ServiceListings, ServicePreferences, RecommendationsService } from '@services';

@Component({
    selector: 'items-as-list',
    styleUrls: ['./items-as-list.component.scss'],
    templateUrl: './items-as-list.component.html',
})
export class ItemsAsListComponent implements OnInit, OnDestroy {
    @Input() options?: any;
    @Input() listOrder?: Order;

    public visibleItems: Item[];
    public displayedData: any;
    public showNoItem = false;

    public linkedBlocks: PreferencesLinkedBlocks;

    private _destroyed$ = new Subject();

    constructor(
        private servicePreferences: ServicePreferences,
        private serviceListings: ServiceListings
    ) {
        this.displayedData = {};
    }

    ngOnInit() {
        this.linkedBlocks = this.servicePreferences.getPreference('summariesLinkedBlocks');

        this.serviceListings.visibleItemsObservable.pipe(
            takeUntil(this._destroyed$)
        ).subscribe((visibleItems: Item[]) => {
            this.visibleItems = visibleItems;
            this.showNoItem = (_.size(visibleItems) === 0);
        });
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }

    trackIds(index: number, o: Item) {
        // What if from an other KB?
        return o ? o.id : index;
    }
}
