import * as _ from 'lodash';
import { Component, Input, OnDestroy } from '@angular/core';
import { finalize, first, takeUntil } from 'rxjs/operators';

import { AppSettings, Settings } from '@app/app.settings';
import { Kb } from '@models';
import { ServiceKb, ServiceErrors } from '@services';
import { Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'button-save-kb',
    templateUrl: './button-save-kb.html',
    styleUrls: ['./button-save-kb.scss']
})
export class ButtonSaveKbComponent implements OnDestroy {

    public settings: Settings;

    private _kb: Kb;
    @Input()
    set kb(value: Kb) {
        this._kb = value;
        this._setAttributes();
    }
    get kb(): Kb {
        return this._kb;
    }

    public tooltip = 'NO_CHANGES';

    private _destroyed$ = new Subject();

    constructor(
        private appSettings: AppSettings,
        private serviceErrors: ServiceErrors,
        private serviceKb: ServiceKb
    ) {
        this.settings = this.appSettings.settings;
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }

    saveKb() {
        if (!this.kb.isDirty) {
            return;
        }
        
        this.settings.savingInsideSpinner = true;
        this.serviceKb.update(this.kb).pipe(
            finalize(() => {
                this.settings.savingInsideSpinner = false;
            }),
            first(),
            takeUntil(this._destroyed$)
        ).subscribe(
            (updatedKb: Kb) => {
                const existingKb = this.serviceKb.kb;
                existingKb.isDirty = false;

                const newKb = _.extend(existingKb, updatedKb);
                delete newKb.witness;
                newKb.witness = _.cloneDeep(newKb);
                this.serviceKb.kb = newKb;
            },
            (error: HttpErrorResponse) => {
                this.serviceErrors.handleError('SAVE_KB', error);
            }
        );
    }

    private _setAttributes() {
        const value = this._kb.isDirty;
        if (!value) {
            this.tooltip = 'NO_CHANGES';
        } else {
            this.tooltip = 'SAVE';
        }
    }
}
