import * as _ from 'lodash';
import { OnInit, OnDestroy, Input, Injector } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { takeUntil } from 'rxjs/operators';
import { ServiceListings, ServiceDatastore, ServiceTextAnalyser } from '@services';
import { Item } from '@models/item.model';
import { FilterByComponent } from './abstract-filter-by.component';


/**
 * Base Filter for searchable matchip filter boxes
 */
export abstract class AbstractFilterByChipComponent extends FilterByComponent {

    @Input() sourceData: any[] = [];
    public displayedData: any[] = [];
    public searchedTerm = '';
    public selectedData = {};
    protected timer: any;
    // protected _destroyed$ = new Subject();

    constructor(
        protected injector: Injector,
        protected serviceDatastore: ServiceDatastore,
        protected textAnalyserService: ServiceTextAnalyser,
        protected _property: string,
        protected _filterProperty: string
    ) {
        super(injector);
        this.filterKey = _filterProperty;
    }

    initValue(userFilters) {
        this.serviceDatastore.dataStoreObservable.pipe(
            takeUntil(this._destroyed$)
        ).subscribe(allItems => {
            if (allItems) {
                const t = {};
                _.forEach(allItems, function (item: Item) {
                    _.forEach(item[this._property], function (m: any) {
                        t[m.id] = m;
                    }.bind(this));
                }.bind(this));

                this.sourceData = _.values(t);
                this.setDisplayedData(_.cloneDeep(this.sourceData), userFilters);
            }
        });
    }

    setDisplayedData(sourceData: any[], userFilters: any) {
        const params = this.route.snapshot.params;
        const currentUser = this.serviceSecurity.user;

        _.map(sourceData, function (m: any) {
            m['state'] = m.id in this.selectedData;
        }.bind(this));
        this.searchedTerm = '';

        const userSavedValues = userFilters[this.filterKey] || [];
        const filterKey = this.filterKey;
        _.forEach(sourceData, function (m: any) {
            m['state'] =
                _.indexOf(userSavedValues, m.id) !== -1 ||
                (params.type === 'expertises' && filterKey === 'experts' && m.id === currentUser.id) // Force current user if expertises
            ;
            m['disabled'] = (params.type === 'expertises' && filterKey === 'experts' && m.id === currentUser.id);
        });

        this.displayedData = _.orderBy(sourceData, ['disabled', 'state', 'title'], ['desc', 'desc', 'asc']);
    }

    trackId(index: number, o: any) {
        return o ? o.id : index;
    }

    onClick(item) {
        item.state = !item.state;
    }

    onSelectionChange(value, isSelected) {
        if (isSelected) {
            this.selectedData[value] = value;
        } else if (this.selectedData[value]) {
            delete this.selectedData[value];
        }

        let filters = this.serviceListings.filters;
        const extender = {};
        extender[this._filterProperty] = _.values(this.selectedData);
        filters = _.extend(filters, extender);

        // Be sure to exit onSelectionChange callback before applying filters
        setTimeout(() => {
            if (_.size(_.values(this.selectedData)) < 1) {
                delete filters[this.filterKey];
                this.isFiltersDirty.emit({ filterKey: this.filterKey, isDirty: false });
            } else {
                this.isFiltersDirty.emit({ filterKey: this.filterKey, isDirty: true });
            }

            this.serviceListings.filters = filters;
            this.serviceListings.dataStoreChanged();
            this.saveFilter();
        }, 10);
    }

    clearTimer() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    search() {
        this.clearTimer();
        this.timer = setTimeout(this.filterDisplayed.bind(this), 300);
    }

    filterDisplayed() {
        const t = [];
        for (let i = 0; i < this.sourceData.length; i++) {
            if (
                this.textAnalyserService
                    .removeDiacritics(this.sourceData[i].title.toLowerCase())
                    .includes(this.textAnalyserService.removeDiacritics(this.searchedTerm.toLowerCase()))
            ) {
                t.push(this.sourceData[i]);
            }
        }
        this.displayedData = _.cloneDeep(t);
    }

    reset() {
        this.displayedData = _.cloneDeep(this.sourceData);
        this.selectedData = {};
        this.searchedTerm = '';
        if (this._filterProperty in this.serviceListings.filters) {
            delete this.serviceListings.filters[this._filterProperty];
        }
    }
}
