import { Component, Injector } from '@angular/core';
import { ServiceListings, ServiceDatastore, ServiceTextAnalyser } from '@services';
import { AbstractFilterByChipComponent } from '../abstract-filters-by-chip.component';

@Component({
    selector: 'filters-by-modifier',
    templateUrl: './filters-by-modifier.component.html',
    styleUrls: ['./filters-by-modifier.component.scss']
})
export class FiltersByModifierComponent extends AbstractFilterByChipComponent{
    constructor(
        injector: Injector,
        serviceDatastore: ServiceDatastore,
        textAnalyserService: ServiceTextAnalyser
    ) {
        super(injector, serviceDatastore, textAnalyserService, 'modifiers', 'modifiers');
    }
}
