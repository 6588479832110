import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Item } from '@models';
import { UserAction } from '@models/user-actions.model';
import { CommonService } from '@services/common.service';
import { ServiceDatastore } from './datastore.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class ServiceUserActions extends CommonService {

    constructor(
        protected injector: Injector,
        private serviceDatastore: ServiceDatastore
    ) {
        super(injector);
    }

    getAllUserActions<T>(item: Item): Observable<UserAction[]> {
        const serviceDatastore = this.serviceDatastore;
        const serviceTranslate = this.serviceTranslate;
        return this.http.get<UserAction[]>(this.urlApi + 'user-actions/' + item.uid, {}).pipe(
            map(
                (result: UserAction[]) => {
                    return result.map((userAction: UserAction) => {
                        return new UserAction(serviceDatastore, serviceTranslate).deserialize(userAction);
                    });
                }
            )
        );
    }
}
