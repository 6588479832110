import * as _ from 'lodash';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'hasTrueValue',
    pure: false
})
export class HasTrueValuePipe implements PipeTransform {
    transform(value: any): boolean {
        return (_.findIndex(_.values(value), function(o) { return o === true; }) !== -1);
    }
}
