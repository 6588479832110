import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, ElementRef, ViewChild, HostListener, NgZone } from '@angular/core';
import { takeUntil, first } from 'rxjs/operators';
import { Router } from '@angular/router';

import { ItemsSettings } from '@items/items.settings';
import { Item, MapNode } from '@models';
import { ServiceMap, ServiceItems, ServiceDatastore, ServiceUrls, RecommendationsService } from '@services';

import { Network, DataSet } from 'vis';
import { Subject } from 'rxjs';
import { KbNewsPopupComponent } from '../../../kbs';

@Component({
    selector: 'item-keywords-as-map',
    templateUrl: './item-keywords-as-map.component.html',
    styleUrls: ['./item-keywords-as-map.component.scss']
})
export class ItemKeywordsAsMapComponent implements OnInit, OnDestroy {

    @ViewChild('visjsMap', {static: false}) mapElementRef: ElementRef;

    private rootItem: Item;
    private nodesDataset: DataSet<MapNode>;
    private baseNodes: MapNode[] = [];
    private edgesDataset: DataSet<any>;
    private baseEdges: any[] = [];
    private network: Network;

    private filterMinimumScore = 0;
    private filterMinimumLinksCount = 2;
    public minimumScore: number;
    public noData = false;
    public isLoading = true;
    public loadingProgress = 0;

    private _destroyed$ = new Subject();

    private keywordNodeColorsConfig = [
        '#da7b4f', // orange
        '#e6d847', // yellow
        '#84da4f', // green
        '#21d8de', // cyan
        '#4f8bda', // blue
        '#994fda', // purple
        '#da4fbe'  // pink
    ];

    constructor(
        private ngZone: NgZone,
        private serviceItems: ServiceItems,
        private serviceDatastore: ServiceDatastore,
        private mapService: ServiceMap,
        private serviceRecommendations: RecommendationsService,
        private router: Router
    ) {
        this.mapService.filterByKeywordsScoreChanged.pipe(
            takeUntil(this._destroyed$)
        ).subscribe(
            (scoreMin: any) => {
                this.filterMinimumScore = scoreMin;
                this.applyFilters();
            }
        );

        this.mapService.filterByKeywordsLinksCountChanged.pipe(
            takeUntil(this._destroyed$)
        ).subscribe(
            (linksCountMin: any) => {
                this.filterMinimumLinksCount = linksCountMin;
                this.applyFilters();
            }
        );
    }

    @HostListener('window:resize')
    onWindowResize(): void {
        this.centerNetwork();
    }

    ngOnInit() {
        this.rootItem = this.serviceItems.item;

        this.serviceRecommendations.getAllItemsKeywords().pipe(
            first(),
            takeUntil(this._destroyed$)
        ).subscribe(
            (data) => {
                let nodes: MapNode[] = [];
                let edges: any[] = [];

                // Add rootItem as node
                const rootItemNode = this.mapService.getNodeFromItem(this.rootItem);
                rootItemNode.font = { multi: 'html', size: 20 };
                rootItemNode.fixed = { x: false, y: false };
                nodes.push(rootItemNode);
                this.baseNodes.push(rootItemNode);

                const rootKeywordsData = _.get(data, this.rootItem.id);
                const rootKeywords = _.keys(rootKeywordsData);

                // Set one dedicated color by keyword
                const keywordColorsMap = {};
                let index = 0;
                for (const keyword in rootKeywordsData) {
                    if (index > this.keywordNodeColorsConfig.length) {
                        index = 0;
                    }
                    keywordColorsMap[keyword] = this.keywordNodeColorsConfig[index];
                    index++;
                }

                for (const keyword in rootKeywordsData) {
                    // Add all rootItem keywords as nodes
                    const keywordNode = this.mapService.getNodeFromKeyword(keyword);
                    keywordNode.color = keywordColorsMap[keyword];
                    const score = Math.round((data[this.rootItem.id][keyword] * 100));

                    const rootEdge = {
                        from: this.rootItem.id,
                        to: keywordNode.id,
                        title: 'Score: ' + score + '%',
                        score: score,
                        color: {
                            color: keywordColorsMap[keyword]
                        }
                    };

                    this.baseNodes.push(keywordNode);
                    this.baseEdges.push(rootEdge);
                    nodes.push(keywordNode);
                    edges.push(rootEdge);

                    for (const itemId in data) {
                        if (itemId !== this.rootItem.id) {
                            const currentKeywords = _.keys(data[itemId]);
                            const intersection = _.intersection(rootKeywords, currentKeywords);
                            let currentAvgScore = 0;
                            _.forEach(intersection, (keyword: string) => {
                                currentAvgScore += Math.round((data[itemId][keyword] * 100));
                            });
                            currentAvgScore = currentAvgScore / intersection.length;

                            const relatedItem = this.serviceDatastore.find(itemId);
                            if (relatedItem) {
                                const itemNode = this.mapService.getNodeFromItem(relatedItem);
                                itemNode.score = currentAvgScore;
                                itemNode.totalLinksCount = intersection.length;
                                itemNode.fixed = { x: false, y: false };
                                this.baseNodes.push(itemNode);

                                if (currentAvgScore >= this.filterMinimumScore && intersection.length > 1) {
                                    nodes.push(itemNode);
                                }

                                _.forEach(intersection, (kw: string) => {
                                    const subScore = Math.round((data[itemId][kw] * 100));
                                    const keywordEdge = {
                                        from: itemNode.id,
                                        to: kw,
                                        title: 'Score: ' + subScore + '%',
                                        score: subScore,
                                        color: {
                                            color: keywordColorsMap[kw]
                                        }
                                    };

                                    this.baseEdges.push(keywordEdge);
                                    if (currentAvgScore >= this.filterMinimumScore && intersection.length > 1) {
                                        edges.push(keywordEdge);
                                    }
                                });
                            }
                        }
                    }
                }
                this.baseNodes = _.uniqBy(this.baseNodes, function (n) {
                    return n.id;
                });
                nodes = _.uniqBy(nodes, function (n) {
                    return n.id;
                });
                this.baseEdges = _.uniqBy(this.baseEdges, function (e) {
                    return e.from + e.to;
                });
                edges = _.uniqBy(edges, function (e) {
                    return e.from + e.to;
                });

                this.initMap(nodes, edges);
            }
        );
    }

    private initMap(nodes?: Array<MapNode>, edges?: Array<any>) {
        nodes = nodes || [];
        edges = edges || [];

        this.noData = nodes.length === 0;
        this.loadingProgress = 0;
        this.isLoading = true;
        this.clearAll();
        this.nodesDataset = new DataSet(nodes);
        this.edgesDataset = new DataSet(edges);

        this.adjustKeywordNodesSize();

        this.network = this.ngZone.runOutsideAngular(() =>
            new Network(this.mapElementRef.nativeElement, {
                nodes: this.nodesDataset,
                edges: this.edgesDataset
            }, ItemsSettings.ITEM_KEYWORDS_MAP)
        );

        this.network.on('doubleClick', this.onNodeDoubleClick.bind(this));
        this.network.on('stabilizationProgress', this.onStabilizationProgress.bind(this));
        this.network.once('stabilizationIterationsDone', this.onStabilizationIterationsDone.bind(this));
    }

    private applyFilters() {
        if (!this.network) {
            return;
        }

        let nodes: MapNode[] = [];
        let edges: any[] = [];

        // Nodes
        nodes = _.filter(this.baseNodes, (n: MapNode) => {
            return (
                n.id === this.rootItem.id ||    // node is rootItem
                n.isKeyword === true ||        // node is a keyword
                (
                    n.score && n.score >= this.filterMinimumScore && // nodes having Avg(edges.score) > scoreMin
                    n.totalLinksCount && n.totalLinksCount >= this.filterMinimumLinksCount // nodes having specified links count (related keywords)
                )
            );
        });

        // Edges
        _.forEach(nodes, (aNode: MapNode) => {
            if (aNode.isKeyword !== true) {
                const edgesFromKeywordToNode = _.filter(this.baseEdges, (e: any) => {
                    return e.to === aNode.id || e.from === aNode.id;
                });
                edges = _.concat(edges, edgesFromKeywordToNode);
            }
        });

        // Unicity
        nodes = _.uniqBy(nodes, function (n) {
            return n.id;
        });
        edges = _.uniqBy(edges, function (e) {
            return e.from + e.to;
        });

        this.initMap(nodes, edges);
    }

    private adjustKeywordNodesSize() {
        const minNodeSize = 8;
        const maxNodeSize = 42;

        // Get all Keywords nodes
        const keywordNodes = this.nodesDataset.get({
            filter: (n: MapNode) => {
                return n.isKeyword === true;
            }
        });

        let maxEdgesCount = 0;
        let minEdgesCount = 0;
        const keywordEdgesCount = {};

        // Get the max edges count to limit size
        // And the (current) edge count for all keywords
        _.forEach(keywordNodes, (n: MapNode) => {
            const connectedEdges = this.edgesDataset.get({
                filter: (e: any) => {
                    return e.to === n.id;
                }
            });
            keywordEdgesCount[n.id] = connectedEdges.length;
            if (connectedEdges.length > maxEdgesCount) {
                maxEdgesCount = connectedEdges.length;
            }
            if (connectedEdges.length < minEdgesCount) {
                minEdgesCount = connectedEdges.length;
            }
        });

        // y = ax + b
        // a = (y2 - y1) / (x2 - x1)
        const coef = (maxNodeSize - minNodeSize) / (maxEdgesCount - minEdgesCount);

        // Update size in network
        _.forEach(keywordNodes, (n: MapNode) => {
            if (n.id in keywordEdgesCount) {
                n.size = coef * keywordEdgesCount[n.id] + minNodeSize;
                this.nodesDataset.update(n);
            }
        });
    }

    private clearDataset() {
        if (this.nodesDataset) {
            this.nodesDataset.clear();
        }
        if (this.edgesDataset) {
            this.edgesDataset.clear();
        }
    }

    private clearAll() {
        this.clearDataset();
        if (this.network) {
            this.network.off('stabilizationProgress', this.onStabilizationProgress);
            this.network.off('doubleClick', this.onNodeDoubleClick);
            this.network.destroy();
            this.network = null;
        }
    }

    public centerNetwork() {
        if (this.network) {
            this.ngZone.runOutsideAngular(() => {
                this.network.redraw();
                this.network.fit();
            });
        }
    }

    private onStabilizationProgress(params) {
        this.ngZone.run(() => {
            this.loadingProgress = Math.round(params.iterations / params.total * 100);
        });
    }

    private onStabilizationIterationsDone() {
        this.ngZone.run(() => {
            this.isLoading = false;
            setTimeout(() => {
                this.centerNetwork();
            }, 50);
        });
    }

    private onNodeDoubleClick(p) {
        const nid = _.first<string>(_.get(p, 'nodes'));
        if (nid) {
            const node = this.nodesDataset.get(nid) as any;
            const group = _.toLower(_.get(node, 'group'));
            if (group === 'page') {
                this.ngZone.run(() => {
                    this.router.navigate(['/items/' + group + '/' + ServiceUrls.convertOIDtoID(node.originalId)]);
                });
            }
        }
    }

    ngOnDestroy() {
        this.clearAll();

        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }
}
