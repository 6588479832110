import * as _ from 'lodash';
import { Component, Injector } from '@angular/core';
import { FilterByComponent } from '../abstract-filter-by.component';

@Component({
    selector: 'filters-by-role',
    templateUrl: './filters-by-role.component.html',
    styleUrls: ['./filters-by-role.component.scss']
})
export class FiltersByRoleComponent extends FilterByComponent {

    public ROLE_NONE = true;
    public ROLE_READER = true;
    public ROLE_CONTRIBUTOR = true;
    public ROLE_KB_ADMIN = true;
    public ROLE_SOCIETY_ADMIN = true;

    constructor(
        protected injector: Injector
    ) {
        super(injector);
        this.filterKey = 'selectedRoles';
    }

    initValue(listFilters: any) {
        const selectedValues = _.get(listFilters, this.filterKey, []);
        if (selectedValues.length > 0) {
            this.ROLE_NONE = (_.indexOf(selectedValues, 'ROLE_NONE') !== -1);
            this.ROLE_READER = (_.indexOf(selectedValues, 'ROLE_READER') !== -1);
            this.ROLE_CONTRIBUTOR = (_.indexOf(selectedValues, 'ROLE_CONTRIBUTOR') !== -1);
            this.ROLE_KB_ADMIN = (_.indexOf(selectedValues, 'ROLE_KB_ADMIN') !== -1);
            this.ROLE_SOCIETY_ADMIN = (_.indexOf(selectedValues, 'ROLE_SOCIETY_ADMIN') !== -1);
        }
    }

    onChange(apply?: boolean) {
        apply !== false ? apply = true : apply = false;
        const selectedValues = [];
        if (this.ROLE_NONE) {
            selectedValues.push('ROLE_NONE');
        }
        if (this.ROLE_READER) {
            selectedValues.push('ROLE_READER');
        }
        if (this.ROLE_CONTRIBUTOR) {
            selectedValues.push('ROLE_CONTRIBUTOR');
        }
        if (this.ROLE_KB_ADMIN) {
            selectedValues.push('ROLE_KB_ADMIN');
        }
        if (this.ROLE_SOCIETY_ADMIN) {
            selectedValues.push('ROLE_SOCIETY_ADMIN');
        }

        let filters = this.serviceListings.filters;
        if (selectedValues.length === 0) {
            if ('selectedRoles' in this.serviceListings.filters) {
                delete filters[this.filterKey];
                this.isFiltersDirty.emit({ filterKey: this.filterKey, isDirty: false });
            }
        } else {
            filters = _.extend(filters, {
                'selectedRoles': selectedValues
            });
            this.checkIsDirty(selectedValues, this.filterKey);
        }
        this.serviceListings.filters = filters;

        if (apply) {
            this.serviceListings.dataStoreChanged();
        }
    }

    reset() {
        this.ROLE_NONE = true;
        this.ROLE_READER = true;
        this.ROLE_CONTRIBUTOR = true;
        this.ROLE_KB_ADMIN = true;
        this.ROLE_SOCIETY_ADMIN = true;
        this.onChange(false);
    }
}
