import * as _ from 'lodash';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError, first, tap } from 'rxjs/operators';

import { ItemsDictionary, Item, Page, Member, Worklab, Role } from '@models';
import { CommonService } from '@services/common.service';
import { ServiceDatastore } from './datastore.service';
import { ServiceSecurity } from './security.service';

@Injectable({ providedIn: 'root' })
export class ServicePages extends CommonService {
    [x: string]: any;

    constructor(
        protected injector: Injector,
        private serviceSecurity: ServiceSecurity,
        private serviceDatastore: ServiceDatastore
    ) {
        super(injector);
    }

    sortArray(unsortedArray: Page[], orderDirection: 'asc' | 'desc'): Page[] {
        return this.serviceDatastore.sortArray<Page>(unsortedArray, orderDirection);
    }

    fetchAllPotentialLinkedItems<T>(alreadyLinkedItems: T[], currentItem: Page | Member | Worklab): Observable<Item[]> {
        const alreadyLinkedItemsIds = _.map(alreadyLinkedItems, 'id');

        return this.serviceDatastore.dataStoreObservable.pipe(
            /* filter((allItems: Item[]) => allItems !== null), */
            map(
                (allItems: ItemsDictionary) => {
                    let allPotentialItems = _.filter(allItems, function(item: Item) {
                        return item.group === 'Page' && _.indexOf(alreadyLinkedItemsIds, item.id) === -1 && item.id !== currentItem.id;
                    });
                    allPotentialItems = _.orderBy(allPotentialItems, function(potentialItem: Item) {
                        return potentialItem.title;
                    }, ['asc']);

                    return <Page[]> allPotentialItems;
                }
            )
        );
    }

    userCanModifyItem<T extends Item>(page: T): boolean {
        const isUserAdmin = this.serviceSecurity.isAdmin();
        const isItemLocked = page.islocked;
        const isAccessGrantedByRole = this.serviceSecurity.hasMinimumRole(Role.ROLE_CONTRIBUTOR);

        return isUserAdmin || (!isItemLocked && isAccessGrantedByRole);
    }

    userCanCreateItem(): boolean {
        const isUserAdmin = this.serviceSecurity.isAdmin();
        const isAccessGrantedByRole = this.serviceSecurity.hasMinimumRole(Role.ROLE_CONTRIBUTOR);

        return isUserAdmin || isAccessGrantedByRole;
    }

    userCanModifyProsimData(page: Page): boolean {
        const isAccessGrantedByRole = this.serviceSecurity.hasMinimumRole(Role.ROLE_CONTRIBUTOR);

        return isAccessGrantedByRole;
    }

    userCanRemoveItem(item: any): boolean {
        const isUserAdmin = this.serviceSecurity.isAdmin();

        return isUserAdmin;
    }

    userCanChangeItemToPage(page: any): boolean {
        return false;
    }

    userCanModifyContent(page: Page): boolean {
        const isUserAdmin = this.serviceSecurity.isAdmin();
        const isItemLocked = page.islocked;
        const isAccessGrantedByRole = this.serviceSecurity.hasMinimumRole(Role.ROLE_CONTRIBUTOR);

        return isUserAdmin || (!isItemLocked && isAccessGrantedByRole);
    }

    userCanModifyLinkedWorklabs(page: Page): boolean {
        const isUserAdmin = this.serviceSecurity.isAdmin();
        const isItemLocked = page.islocked;
        const isAccessGrantedByRole = this.serviceSecurity.hasMinimumRole(Role.ROLE_CONTRIBUTOR);

        return isUserAdmin || (!isItemLocked && isAccessGrantedByRole);
    }

    userCanModifyAssociatedPages(page: Page): boolean {
        const isUserAdmin = this.serviceSecurity.isAdmin();
        const isItemLocked = page.islocked;
        const isAccessGrantedByRole = this.serviceSecurity.hasMinimumRole(Role.ROLE_CONTRIBUTOR);

        return isUserAdmin || (!isItemLocked && isAccessGrantedByRole);
    }

    addWorklab(page: Page, worklab: Worklab): Observable<any> {
        if (!this.userCanModifyLinkedWorklabs(page)) {
            return this.serviceErrors.throwError('ITEM.LINKED_PAGES_TO_WORKLAB.REMOVE', { code: 403 });
        }
        return this.http.post(this.urlApi + 'links/' + page.uid + '/' + worklab.uid, {}).pipe(
            tap(
                () => {
                    this.serviceDatastore.addPageToWorklab(page.id, worklab.id);
                }
            )
        );
    }

    removeWorklab(page: Page, worklab: Worklab): Observable<any> {
        if (!this.userCanModifyLinkedWorklabs(page)) {
            return this.serviceErrors.throwError('ITEM.LINKED_PAGES_TO_WORKLAB.REMOVE', { code: 403 });
        }
        return this.http.delete(this.urlApi + 'links/' + page.uid + '/' + worklab.uid, {}).pipe(
            tap(
                () => {
                    this.serviceDatastore.removePageFromWorklab(page.id, worklab.id);
                }
            )
        );
    }

    linkPageAToPageB(pageA: Page, pageB: Page): Observable<any> {
        if (!this.userCanModifyAssociatedPages(pageA) || !this.userCanModifyAssociatedPages(pageB)) {
            return this.serviceErrors.throwError('ITEM.LINKED_PAGES_OUT.ADD', { code: 403 });
        }
        return this.http.post(this.urlApi + 'links/' + pageA.uid + '/' + pageB.uid, {}).pipe(
            tap(
                () => {
                    this.serviceDatastore.linkPageAToPageB(pageA.id, pageB.id);
                }
            )
        );
    }

    unlinkPageAToPageB(pageA: Page, pageB: Page): Observable<any> {
        if (!this.userCanModifyAssociatedPages(pageA) || !this.userCanModifyAssociatedPages(pageB)) {
            return this.serviceErrors.throwError('ITEM.LINKED_PAGES_OUT.REMOVE', { code: 403 });
        }
        return this.http.delete(this.urlApi + 'links/' + pageA.uid + '/' + pageB.uid, {}).pipe(
            tap(
                () => {
                    this.serviceDatastore.unlinkPageAToPageB(pageA.id, pageB.id);
                }
            )
        );
    }
}
