import * as _ from 'lodash';
import { Component, Input, OnDestroy } from '@angular/core';
import { finalize, first, takeUntil } from 'rxjs/operators';

import { Page, Member } from '@models';
import { ServiceItems, ServiceFollowers, ServiceSecurity, ServiceMembers, ServiceToaster, ServiceErrors } from '@services';
import { HttpErrorResponse } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';

@Component({
    selector: 'is-following',
    templateUrl: './is-following.component.html',
    styleUrls: ['./is-following.component.scss']
})
export class IsFollowingComponent implements OnDestroy {

    private _item: Page | Member;
    @Input()
    set item(value: Page | Member) {
        this._item = value;
        this._setAttributes();
    }
    get item(): Page | Member {
        return this._item;
    }

    private _isAdmin = false;

    public tooltip: string;
    public loading = false;
    public isGranted = false;
    public isDisabled = false;
    public isUserFollowing = false;

    private _destroyed$ = new Subject();

    constructor(
        private serviceItems: ServiceItems,
        private serviceMembers: ServiceMembers,
        private serviceToaster: ServiceToaster,
        private serviceErrors: ServiceErrors,
        private serviceFollowers: ServiceFollowers,
        private serviceSecurity: ServiceSecurity
    ) {
        this._isAdmin = this.serviceSecurity.isAdmin();
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }

    toogleIsFollowed() {
        if (this.isGranted === true) {
            this.loading = true;

            const wasFollowing = this._item.isUserFollowing;
            let translationKey = (_.get(this, '_item.group') === 'Page') ? 'ITEM.FOLLOWERS.PAGE' : 'ITEM.FOLLOWERS.MEMBER';

            let request: Observable<any>;
            const follower = this.serviceSecurity.user;
            if (wasFollowing === true) {
                this._item.isUserFollowing = false;
                request = this.serviceFollowers.stopFollowing(this._item, follower);
                translationKey = translationKey + '.REMOVE';
            } else {
                this._item.isUserFollowing = true;
                request = this.serviceFollowers.startFollowing(this._item, follower);
                translationKey = translationKey + '.ADD';
            }

            request.pipe(
                finalize(() => {
                    this.loading = false;
                    this._setAttributes();
                }),
                first(),
                takeUntil(this._destroyed$)
            ).subscribe(
                (result: any) => {
                    let followers = this._item.followers;
                    if (this._item.isUserFollowing === false) {
                        _.remove(followers, function (o: Member) { return o.id === follower.id; });
                    } else {
                        followers.push(follower);
                    }
                    followers = this.serviceMembers.sortArray(followers, 'asc');

                    this._item.isUserFollowing = this._item.isUserFollowing;
                    this._item.followers = followers;
                    if (this._item.witness) {
                        this._item.witness.isUserFollowing = this._item.isUserFollowing;
                        this._item.witness.followers = followers;
                    }
                    this.serviceItems.item = this._item;

                    const params = {
                        member: _.get(result, 'itemFrom.title'),
                        title: _.get(result, 'itemTo.title')
                    };
                    this.serviceToaster.success(translationKey, params);
                },
                (error: HttpErrorResponse) => {
                    let followers = this._item.followers;
                    if (this._item.isUserFollowing === false) {
                        followers.push(follower);
                    } else {
                        _.remove(followers, function (o: Member) { return o.id === follower.id; });
                    }
                    followers = this.serviceMembers.sortArray(followers, 'asc');

                    this._item.isUserFollowing = this._item.isUserFollowing;
                    this._item.followers = followers;
                    if (this._item.witness) {
                        this._item.witness.isUserFollowing = this._item.isUserFollowing;
                        this._item.witness.followers = followers;
                    }
                    this.serviceItems.item = this._item;

                    this.serviceErrors.handleError(translationKey, error);
                }
            );
        }
    }

    private _setAttributes() {
        const translationKey = (this._item.group === 'Page') ? 'ITEM.FOLLOWERS.PAGE' : 'ITEM.FOLLOWERS.MEMBER';

        const isLocked = this._item.islocked;
        this.isUserFollowing = this._item.isUserFollowing;
        this.isGranted = this.serviceFollowers.userCanModifyFollowers(this.item);

        if (this.isGranted === true) {
            if (isLocked === true && !this._isAdmin) {
                this.isDisabled = true;
                this.tooltip = translationKey + '.TOOLTIP.DISABLED';
            } else if (this.isUserFollowing) {
                this.isDisabled = false;
                this.tooltip = translationKey + '.TOOLTIP.REMOVE';
            } else if (!this.isUserFollowing) {
                this.tooltip = translationKey + '.TOOLTIP.ADD';
            }
        }
    }
}
