import * as _ from 'lodash';
import { Injectable, Injector } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { map, first, finalize, catchError, timeout } from 'rxjs/operators';

import { Item, Tag } from '@models';
import { CommonService } from '@services/common.service';
import { ServiceTextAnalyser } from '@services/text-analyser.service';
import { ServiceDatastore } from './datastore.service';
import { ServiceSecurity } from './security.service';
import { Toast } from 'angular2-toaster';
// import debug from 'debug';
// const searchDebug = debug('i2kn:search')
@Injectable({
    providedIn: 'root'
})
export class SearchService extends CommonService {
    public lastSearch: string;

    constructor(
        protected injector: Injector,
        private serviceSecurity: ServiceSecurity,
        private serviceDatastore: ServiceDatastore
    ) {
        super(injector);
    }

    getItemsByWords(searchedString: string, kbs: string[]): Observable<Item[]> {
        const callables: Observable<Item[]>[] = [];
        _.forEach(kbs, (kbSlug: string) => {
            callables.push(this.getSearchRequest(searchedString, kbSlug));
        });
        return forkJoin(callables)
        .pipe(
            catchError((error) => {
                console.error('Error on getItemsByWords: ', error);
                return of([]);
            }),
            first(),
            map(
                (results: Item[][]) => {
                    let r: Item[] = [];
                    _.forEach(results, (items: Item[]) => {
                        r = r.concat(items);
                    });
                    return r;
                }
            )
        );
    }

    private getSearchRequest(searchedString: string, kbSlug: string): Observable<Item[]> {
        const url = '//' + PREFIXS.apiPrefix + '.' + this.currentSocietySlug + '.' + this.sld + '/' + kbSlug + '/items/search';
        const getSearch = this.http.post<Item[]>(url, { q: searchedString });
        const getTags = this.serviceDatastore.fetchAllTags();
        return forkJoin([getSearch, getTags]).pipe(
            first(),
            map(
                ([result, resTags]) => {
                    return result.map((item: Item) => {
                        item.titleWithoutAccent = this.serviceTextAnalyser.removeDiacritics(_.get(item, 'title', '')).toLowerCase();
                        const tags = item.tags || [];
                        const newTagList = tags.map(tag => {
                            const foundTag = resTags.get(tag.id)
                            if(foundTag){
                                tag.path = foundTag.path || tag.title; 
                            }
                            return tag;
                        })
                        item.tags = newTagList;
                        return new Item(item, this.serviceSecurity.user);
                    });
                }
            ));
    }

    getOrphans<T extends Item>(group: string): Observable<T[]> {
        let toast: Toast;
        return this.http.get(this.urlApi + 'items/orphans-pages').pipe(
            timeout(5000),
            catchError( e => {
                toast = this.serviceToaster.warning('ORPHANS', null, 60000);
                return of(null);
            }),
            finalize(() => {
                if (toast) {
                    this.serviceToaster.clear(toast.toastId);
                }
            }),
            first(),
            map(
                (result: string[]) => {
                    this.serviceToaster.clearAll();

                    return result.map((itemOid: string) => {
                        const item = this.serviceDatastore.find<T>(itemOid);
                        if (item) {
                            return item;
                        }
                    });
                }
            ));
    }
}
