import * as _ from 'lodash';
import { Component, Input, Output, OnDestroy, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { finalize, first, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

import { Tag } from '@models';
import { ServiceErrors, ServiceToaster, ServiceTags } from '@services';

@Component({
    selector: 'button-save-tags',
    templateUrl: './button-save-tags.html',
    styleUrls: ['./button-save-tags.scss']
})
export class ButtonSaveTagsComponent implements OnDestroy {

    @Output() savingInProgress = new EventEmitter();

    public saving: boolean;
    public disabled: boolean;
    public hasErrors: boolean;

    private _modifiedTags: Tag[];
    @Input()
    set modifiedTags(value: Tag[]) {
        this._modifiedTags = value;
        this._checkIsDisabled();
    }
    get modifiedTags(): Tag[] {
        return this._modifiedTags;
    }

    private _newTags: Tag[];
    @Input()
    set newTags(value: Tag[]) {
        this._newTags = value;
        this._checkIsDisabled();
    }
    get newTags(): Tag[] {
        return this._newTags;
    }

    private _removedTags: Tag[];
    @Input()
    set removedTags(value: Tag[]) {
        this._removedTags = value;
        this._checkIsDisabled();
    }
    get removedTags(): Tag[] {
        return this._removedTags;
    }

    private _destroyed$ = new Subject();

    private _checkIsDisabled() {
        this.disabled = !((_.size(this._modifiedTags) > 0) || (_.size(this._newTags) > 0) || (_.size(this._removedTags) > 0));
    }

    constructor(
        private serviceToaster: ServiceToaster,
        private serviceErrors: ServiceErrors,
        private serviceTags: ServiceTags
    ) {
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }

    saveTags() {
        if (this.disabled) {
            return;
        }

        const newTags = this._newTags;
        const removedTags = this._removedTags;
        const modifiedTags = this._modifiedTags;

        this.saving = true;
        this.savingInProgress.emit({inProgress: true});
        this.serviceTags.saveTags(newTags, removedTags, modifiedTags).pipe(
            finalize(() => {
                this.saving = false;
            }),
            first(),
            takeUntil(this._destroyed$)
        ).subscribe(
            (result: any) => {
                this.savingInProgress.emit({inProgress: false, tags: result});
                this.serviceToaster.success('TAGS.MANAGEMENT.SAVE');
            },
            (error: HttpErrorResponse) => {
                this.savingInProgress.emit({inProgress: false});
                this.serviceErrors.handleError('TAGS.MANAGEMENT.SAVE', error);
            }
        );
    }
}
