import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'toByte'
})
export class ToBytePipe implements PipeTransform {
    transform(value: any, args?): string {
        let precision = args > 0 ? parseInt(args) : 1;

        if (isNaN(parseFloat(value)) || !isFinite(value)) {
            return '-';
        }
        var units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];
        var number = Math.floor(Math.log(value) / Math.log(1024));
        return (value / Math.pow(1024, Math.floor(number))).toFixed(precision) + ' ' + units[number];

    }
}
