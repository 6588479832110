import * as _ from 'lodash';
import { Component, OnInit, Input, Renderer, ElementRef, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { finalize, first, takeUntil } from 'rxjs/operators';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';

import { Page, Member, Worklab, Document } from '@models';
import { ServiceItems, ServiceDocuments, ServiceSecurity, ServiceErrors, ServiceToaster, ServiceTextAnalyser } from '@services';
import { MultiAttributesComponent } from '../multi-attributes.component';

@Component({
    selector: 'documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent extends MultiAttributesComponent implements OnInit {

    @ViewChild('fileInput', {static: false}) fileInput: ElementRef;

    private _item: Page | Member | Worklab;
    @Input()
    set item(value: Page | Member | Worklab) {
        this._item = value;
        this.handleEditionMode(this._editionMode);
    }
    get item(): Page | Member | Worklab {
        return this._item;
    }

    private _file: File;

    public uploadForm: FormGroup;

    public isSavingNewDocument = false;

    public isCreation = false;

    public useIA: boolean = false;

    public isUserAnonymous: boolean = true;

    constructor(
        private renderer: Renderer,
        private router: Router,
        private formBuilder: FormBuilder,
        private serviceErrors: ServiceErrors,
        private serviceToaster: ServiceToaster,
        private serviceItems: ServiceItems,
        private serviceDocuments: ServiceDocuments,
        private serviceSecurity: ServiceSecurity,
        protected serviceTextAnalyser: ServiceTextAnalyser
    ) {
        super(serviceDocuments, serviceTextAnalyser);
        this.field = 'documents';
        this.useIA = this.serviceSecurity.user.useIA;
        this.isUserAnonymous = this.serviceSecurity.isCurrentUserAnonymous();
    }

    protected handleOrder(orderDirection: 'asc' | 'desc') {
        if (this._item instanceof Page || this._item instanceof Member || this._item instanceof Worklab) {
            this._item.documents = this.serviceDocuments.sortArray(this._item.documents, orderDirection);
        }
    }

    protected handleEditionMode(value: boolean) {
        if (this._item instanceof Page || this._item instanceof Member || this._item instanceof Worklab) {
            this.isCreation = _.isUndefined(this._item.id);
            this.canEdit = this.serviceDocuments.userCanModifyDocuments(this._item);
        }
    }

    ngOnInit() {
        this.uploadForm = this.formBuilder.group({ filename: '' });
    }

    selectFile(): void {
        this.fileInput.nativeElement.click();
    }

    addDocument(event: any) {
        if (!this.isCreation && this.canEdit && event.target.files && event.target.files.length > 0) {
            this._file = event.target.files[0];
            this.uploadForm.get('filename').setValue(this._file.name);

            this.actionInProgress = true;
            this.isSavingNewDocument = true;

            this.serviceDocuments.addDocument(this._item, this._file).pipe(
                finalize(() => {
                    this.actionInProgress = false;
                    this.isSavingNewDocument = false;
                    this.uploadForm = this.formBuilder.group({ filename: '' });
                }),
                takeUntil(this._destroyed$)
            ).subscribe(
                (result: any) => {
                    if (_.get(result, 'type') !== 0) {
                        const currentUser = this.serviceSecurity.user;

                        let documents = this._item.documents;
                        documents.push(new Document().deserialize(result.body, currentUser));
                        documents = this.serviceDocuments.sortArray(documents, 'asc');

                        this._item.documents = _.cloneDeep(documents);
                        if (this._item.witness) {
                            this._item.witness.documents = _.cloneDeep(documents);
                        }
                        this.serviceItems.item = this._item;

                        this.serviceToaster.success('ITEM.DOCUMENTS.ADD');
                    }
                },
                (error: HttpErrorResponse) => {
                    this.serviceErrors.handleError('ITEM.DOCUMENTS.ADD', error);
                }
            );
        }
    }

    removeDocument(document: Document) {
        if (!this.isCreation && this.canEdit) {
            this.actionInProgress = true;
            document.isLoadingRemove = true;

            this.serviceDocuments.removeDocument(this._item, document).pipe(
                finalize(() => {
                    this.actionInProgress = false;
                }),
                first(),
                takeUntil(this._destroyed$)
            ).subscribe(
                () => {
                    _.remove(this._item.documents, function (o) { return o.id === document.id; });

                    const documents = this._item.documents;
                    this._item.documents = _.cloneDeep(documents);
                        if (this._item.witness) {
                            this._item.witness.documents = _.cloneDeep(documents);
                        }
                    this.serviceItems.item = this._item;

                    this.serviceToaster.success('ITEM.DOCUMENTS.REMOVE');
                },
                (error: HttpErrorResponse) => {
                    this.serviceErrors.handleError('ITEM.DOCUMENTS.REMOVE', error);
                }
            );
        }
    }

    toogleEdition(document: Document, index: number) {
        if (!this.isCreation && this.canEdit) {
            setTimeout(() => {
                this.renderer.selectRootElement('#input-' + index).focus();
            }, 0);

            document.underEdition = !document.underEdition;
            this.actionInProgress = document.underEdition;
        }
    }

    openResume(document: Document) {
        this.router.navigate(['/resume'], {queryParams: {docurl: document.url, docname: document.title, mode: 'RESUME'}});
    }

    editTitle(document: Document) {
        if (!this.isCreation && this.canEdit) {
            const newTitle = _.trim(_.get(document, 'title', ''));
            const previousTitle = _.trim(_.get(_.find(this._item.witness.documents, function (o) { return o.id === document.id; }), 'title'));
            if (newTitle !== '' && newTitle !== previousTitle) {
                document.isLoadingEdition = true;
                this.serviceDocuments.editDocumentTitle(this._item, document).pipe(
                    finalize(() => {
                        this.actionInProgress = false;
                    }),
                    first(),
                    takeUntil(this._destroyed$)
                ).subscribe(
                    () => {
                        document.underEdition = false;
                        document.isLoadingEdition = false;

                        let documents = this._item.documents;
                        documents = this.serviceDocuments.sortArray(documents, 'asc');

                        this._item.documents = _.cloneDeep(documents);
                        if (this._item.witness) {
                            this._item.witness.documents = _.cloneDeep(documents);
                        }
                        this.serviceItems.item = this._item;
                    },
                    (error: HttpErrorResponse) => {
                        document.title = previousTitle;
                        document.underEdition = false;
                        document.isLoadingEdition = false;
                        this.serviceErrors.handleError('ITEM.DOCUMENTS.EDIT_TITLE', error);
                    }
                );
            } else {
                document.title = previousTitle;
                document.underEdition = false;
                document.isLoadingEdition = false;
                this.actionInProgress = false;
            }
        }
    }
}
