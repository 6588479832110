import { Component, OnInit, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AppSettings, Settings } from '@app/app.settings';
/**
 * This is the component that displays the results of a natural search.
 *
 * @class ResponseIaComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'app-response-ia',
    templateUrl: './response-ia.component.html',
    styleUrls: ['./response-ia.component.scss']
})
export class ResponseIaComponent implements OnInit {

    public settings: Settings;

    @Input() responseText: string | undefined;
    @Input() responseLength: string | undefined;
    @Input() responseLanguage: string | undefined;
    @Input() regenerate: Function;
    public error: HttpErrorResponse;
    public response : string | undefined;
    public saving: boolean = false;

    public loading = false;

    /**
     * Creates an instance of ResponseIaComponent.
     * @memberof ResponseIaComponent
     */
    constructor(
        private appSettings: AppSettings,
    ) {
        this.settings = this.appSettings.settings;
    }

    /**
     * This method is called when the component is initialized.
     * @method ngOnInit
     */
    ngOnInit(): void {
        // \\n is replaced by <br/> to display the response correctly and remove "" use new Regex
        this.response = this.responseText.replace(/\\n/g, '<br/>');
    }
  
  /**
   * This method is called when the response is changed.
   * @method onResponseChange
   * @param {any} event - The event object.
   * @returns {void}
   */
  onResponseChange(event: any): void {
    this.responseLength = event.value;
  }

  /**
   * This method is called when the response language is changed.
   * @method onResponseLanguagesChanged
   * @param {any} event - The event object.
   * @returns {void}
   */
  onResponseLanguagesChanged(event: any): void {
    this.responseLanguage = event.value;
  }

  /**
   * This method is called when the regenerate button is clicked.
   * @method onRegenerate
   * @param {any} event - The event object.
   * @returns {void}
   */
  onRegenerate(event: any): void {
    this.regenerate(this.responseLength, this.responseLanguage);
  }
}