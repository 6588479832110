import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ServiceSecurity, ServiceKb, ServiceDatastore, ServiceErrors, ServiceToaster } from '@services';
import { ItemsDictionary } from '../models';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class GuardIsIdentifiedService implements CanActivate {

    constructor(
        private router: Router,
        private serviceSecurity: ServiceSecurity,
        private serviceDatastore: ServiceDatastore,
        private serviceTranslate: TranslateService,
        private serviceToaster: ServiceToaster,
        private serviceErrors: ServiceErrors,
        private serviceKb: ServiceKb
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        const helper = new JwtHelperService();
        const token = this.serviceSecurity.getAccessToken();

        const potentialUser = JSON.parse(localStorage.getItem('currentUser'));
        let isUserAnonymous = this.serviceSecurity.isCurrentUserAnonymous();

        // Token exist
        if (token) {
            const tokenData = helper.decodeToken(token);

            // Not same username in token and storage
            if ((potentialUser && potentialUser.username !== tokenData.username) || !potentialUser) {
                if (!this.serviceKb.kb) {
                    return new Promise<boolean | UrlTree>((resolve) => {
                        Promise.all([this.serviceKb.loadPublicKbInfos(), this.serviceSecurity.getMe().toPromise()]).then(() => {
                            isUserAnonymous = this.serviceSecurity.isCurrentUserAnonymous();

                            const isKbPublic = this.serviceKb.isCurrentKbPublic();
                            const needsConfidentialityAgreement = this.serviceKb.isCurrentKbNeedsConfidentialityAgreement();
                            const userHasAgreements = this.serviceSecurity.hasAgreements() || (isKbPublic && this.serviceSecurity.getCookie('agreements'));

                            if (
                                state.url !== '/not-allowed' &&
                                state.url !== '/login' &&
                                !state.url.includes('/agreements') &&
                                !state.url.includes('/tokenize')
                            ) {
                                if (
                                    !isKbPublic &&
                                    isUserAnonymous
                                ) {
                                    // User not identified on not public database
                                    resolve(this.router.parseUrl('/login?returnUrl=' + state.url));
                                } else if (needsConfidentialityAgreement === true && !userHasAgreements) { // No agreements found on the current user
                                    resolve(this.router.parseUrl('/agreements?returnUrl=' + state.url));
                                } else {
                                    this.serviceDatastore.fetchAllItems(this.serviceSecurity.user).toPromise().then(
                                        (datastore: ItemsDictionary) => {
                                            this.serviceDatastore.dataStore = datastore;
                                            resolve(true);
                                        }
                                    );
                                }
                            } else {
                                this.serviceDatastore.fetchAllItems(this.serviceSecurity.user).toPromise().then(
                                    (datastore: ItemsDictionary) => {
                                        this.serviceDatastore.dataStore = datastore;
                                        resolve(true);
                                    }
                                );
                            }
                        }, (error: HttpErrorResponse) => {
                            this.serviceDatastore.dataStore = {};
                            const message = _.get(error, 'error.message', '');
                            if ( message.substring(0, 9) === 'Username ' && message.substring(message.length - 16, message.length) === ' does not exist.') {
                                this.serviceToaster.error(this.serviceTranslate.instant('ERRORS.UNKNOWN_USER.TEXT'), this.serviceTranslate.instant('ERRORS.UNKNOWN_USER.TITLE'));
                                this.router.navigate(['/register']);
                            } else {
                                this.serviceErrors.handleError('LOGIN', error);
                            }
                            resolve(false);
                        });
                    });
                } else {
                    return new Promise<boolean | UrlTree>((resolve) => {
                        this.serviceSecurity.getMe().toPromise().then(() => {
                            isUserAnonymous = this.serviceSecurity.isCurrentUserAnonymous();

                            const isKbPublic = this.serviceKb.isCurrentKbPublic();
                            const needsConfidentialityAgreement = this.serviceKb.isCurrentKbNeedsConfidentialityAgreement();
                            const userHasAgreements = this.serviceSecurity.hasAgreements() || (isKbPublic && this.serviceSecurity.getCookie('agreements'));

                            if (
                                state.url !== '/not-allowed' &&
                                state.url !== '/login' &&
                                !state.url.includes('/agreements') &&
                                !state.url.includes('/tokenize')
                            ) {
                                if (
                                    !isKbPublic &&
                                    isUserAnonymous
                                ) {
                                    // User not identified on not public database
                                    resolve(this.router.parseUrl('/login?returnUrl=' + state.url));
                                } else if (needsConfidentialityAgreement === true && !userHasAgreements) { // No agreements found on the current user
                                    resolve(this.router.parseUrl('/agreements?returnUrl=' + state.url));
                                } else {
                                    this.serviceDatastore.fetchAllItems(this.serviceSecurity.user).toPromise().then(
                                        (datastore: ItemsDictionary) => {
                                            this.serviceDatastore.dataStore = datastore;
                                            resolve(true);
                                        }
                                    );
                                }
                            } else {
                                this.serviceDatastore.fetchAllItems(this.serviceSecurity.user).toPromise().then(
                                    (datastore: ItemsDictionary) => {
                                        this.serviceDatastore.dataStore = datastore;
                                        resolve(true);
                                    }
                                );
                            }
                        });
                    });
                }
            } else {
                // Same username in token and storage
                if (!this.serviceKb.kb) {
                    return new Promise<boolean | UrlTree>((resolve) => {
                        this.serviceKb.loadPublicKbInfos()
                            .then(() => {
                                const isKbPublic = this.serviceKb.isCurrentKbPublic();
                                const needsConfidentialityAgreement = this.serviceKb.isCurrentKbNeedsConfidentialityAgreement();
                                const userHasAgreements = this.serviceSecurity.hasAgreements() || (isKbPublic && this.serviceSecurity.getCookie('agreements'));

                                if (
                                    state.url !== '/not-allowed' &&
                                    state.url !== '/login' &&
                                    !state.url.includes('/agreements') &&
                                    !state.url.includes('/tokenize')
                                ) {
                                    if (
                                        !isKbPublic &&
                                        isUserAnonymous
                                    ) {
                                        // User not identified on not public database
                                        resolve(this.router.parseUrl('/login?returnUrl=' + state.url));
                                    } else if (needsConfidentialityAgreement === true && !userHasAgreements) { // No agreements found on the current user
                                        resolve(this.router.parseUrl('/agreements?returnUrl=' + state.url));
                                    } else {
                                        this.serviceDatastore.fetchAllItems(this.serviceSecurity.user).toPromise().then(
                                            (datastore: ItemsDictionary) => {
                                                this.serviceDatastore.dataStore = datastore;
                                                resolve(true);
                                            }
                                        );
                                    }
                                } else {
                                    resolve(true);
                                }
                            });
                    });
                } else {
                    const isKbPublic = this.serviceKb.isCurrentKbPublic();
                    const needsConfidentialityAgreement = this.serviceKb.isCurrentKbNeedsConfidentialityAgreement();
                    const userHasAgreements = this.serviceSecurity.hasAgreements() || (isKbPublic && this.serviceSecurity.getCookie('agreements'));

                    if (
                        state.url !== '/not-allowed' &&
                        state.url !== '/login' &&
                        !state.url.includes('/agreements') &&
                        !state.url.includes('/tokenize')
                    ) {
                        if (
                            !isKbPublic &&
                            isUserAnonymous
                        ) {
                            // User not identified on not public database
                            return this.router.parseUrl('/login?returnUrl=' + state.url);
                        } else if (
                            needsConfidentialityAgreement === true &&
                            !userHasAgreements
                        ) {
                            // No agreements found on the current user
                            return this.router.parseUrl('/agreements?returnUrl=' + state.url);
                        }
                        return this.serviceDatastore.fetchAllItems(this.serviceSecurity.user).toPromise().then(
                            (datastore: ItemsDictionary) => {
                                this.serviceDatastore.dataStore = datastore;
                                return true;
                            }, () => {
                                this.serviceDatastore.dataStore = {};
                                return false;
                            }
                        );
                    } else {
                        return true;
                    }
                }
            }
        } else if (!this.serviceKb.kb) {
            return new Promise<boolean | UrlTree>((resolve) => {
                this.serviceKb.loadPublicKbInfos()
                    .then(() => {
                        const isKbPublic = this.serviceKb.isCurrentKbPublic();
                        const needsConfidentialityAgreement = this.serviceKb.isCurrentKbNeedsConfidentialityAgreement();
                        const userHasAgreements = this.serviceSecurity.hasAgreements() || (isKbPublic && this.serviceSecurity.getCookie('agreements'));

                        if (
                            state.url !== '/not-allowed' &&
                            state.url !== '/login' &&
                            !state.url.includes('/agreements') &&
                            !state.url.includes('/tokenize')
                        ) {
                            if (
                                !isKbPublic &&
                                isUserAnonymous
                            ) {
                                // User not identified on not public database
                                resolve(this.router.parseUrl('/login?returnUrl=' + state.url));
                            } else if (needsConfidentialityAgreement === true && !userHasAgreements) { // No agreements found on the current user
                                resolve(this.router.parseUrl('/agreements?returnUrl=' + state.url));
                            } else {
                                this.serviceDatastore.fetchAllItems(this.serviceSecurity.user).toPromise().then(
                                    (datastore: ItemsDictionary) => {
                                        this.serviceDatastore.dataStore = datastore;
                                        resolve(true);
                                    }
                                );
                            }
                        } else {
                            this.serviceDatastore.fetchAllItems(this.serviceSecurity.user).toPromise().then(
                                (datastore: ItemsDictionary) => {
                                    this.serviceDatastore.dataStore = datastore;
                                    resolve(true);
                                }
                            );
                        }
                    });
            });
        } else {
            const isKbPublic = this.serviceKb.isCurrentKbPublic();
            const needsConfidentialityAgreement = this.serviceKb.isCurrentKbNeedsConfidentialityAgreement();
            const userHasAgreements = this.serviceSecurity.hasAgreements() || (isKbPublic && this.serviceSecurity.getCookie('agreements'));

            if (
                state.url !== '/not-allowed' &&
                state.url !== '/login' &&
                !state.url.includes('/agreements') &&
                !state.url.includes('/tokenize')
            ) {
                if (
                    !isKbPublic &&
                    isUserAnonymous
                ) {
                    // User not identified on not public database
                    return this.router.parseUrl('/login?returnUrl=' + state.url);
                } else if (needsConfidentialityAgreement === true && !userHasAgreements) { // No agreements found on the current user
                    return this.router.parseUrl('/agreements?returnUrl=' + state.url);
                } else {
                    return this.serviceDatastore.fetchAllItems(this.serviceSecurity.user).toPromise().then(
                        (datastore: ItemsDictionary) => {
                            this.serviceDatastore.dataStore = datastore;
                            return true;
                        }, () => {
                            this.serviceDatastore.dataStore = {};
                            return false;
                        }
                    );
                }
            } else {
                return this.serviceDatastore.fetchAllItems(this.serviceSecurity.user).toPromise().then(
                    (datastore: ItemsDictionary) => {
                        this.serviceDatastore.dataStore = datastore;
                        return true;
                    }
                );
            }
        }
    }
}