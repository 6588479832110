import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DetectScrollDirective } from './detect-scroll/detect-scroll.directive';
import { SortIconDirective } from './sort-icon/sort-icon.directive';
import { DefaultImageDirective } from './default-image/default-image.directive';
import { ViewPasswordDirective } from './view-password/view-password.directive';
import { TextSelectionDirective } from './text-selection/text-selection.directive';


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    DetectScrollDirective,
    SortIconDirective,
    TextSelectionDirective,
    DefaultImageDirective,
    ViewPasswordDirective
  ],
  exports: [
    DetectScrollDirective,
    SortIconDirective,
    TextSelectionDirective,
    DefaultImageDirective,
    ViewPasswordDirective
  ]
})
export class DirectivesModule { }
