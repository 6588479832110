import * as _ from 'lodash';
import { Component, Injector } from '@angular/core';
import { FilterByComponent } from '../abstract-filter-by.component';

@Component({
    selector: 'filters-by-is-inactive-member',
    templateUrl: './filters-by-is-inactive-member.component.html',
    styleUrls: ['./filters-by-is-inactive-member.component.scss']
})
export class FiltersByIsInactiveMemberComponent extends FilterByComponent {

    public isInactiveMemberEnabled: boolean = false;

    constructor(
        protected injector: Injector
    ) {
        super(injector);
        this.filterKey = 'is-inactive-member';
    }

    initValue(listFilters: any) {
        if (this.filterKey in listFilters) {
            this.isInactiveMemberEnabled = listFilters[this.filterKey];
        }
    }

    onChange() {
        if (!this.isInactiveMemberEnabled) {
            const filters = this.serviceListings.filters;
            if (this.filterKey in filters) {
                delete filters['is-inactive-member'];
                this.serviceListings.filters = filters;
            }
            this.isFiltersDirty.emit({ filterKey: this.filterKey, isDirty: false });
        } else {
            this.serviceListings.filters = _.extend(this.serviceListings.filters, {
                'is-inactive-member': this.isInactiveMemberEnabled
            });
            this.checkIsDirty(this.isInactiveMemberEnabled, this.filterKey);
        }
        this.serviceListings.dataStoreChanged();
        this.saveFilter();
    }

    reset() {
        this.isInactiveMemberEnabled = false;
        const filters = this.serviceListings.filters;
        delete filters[this.filterKey];
        this.serviceListings.filters = filters;
        this.isFiltersDirty.emit({ filterKey: this.filterKey, isDirty: false });
    }
}
