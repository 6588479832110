import { Deserializable } from './deserializable.model';
import { Item } from './item.model';

export class DashboardsSettings implements Deserializable {
    period?: DashboardsSettingsPeriods;
    cumulatedValues?: boolean;
    start?: string;
    end?: string;
    limit?: number;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}

export interface DashboardsSettingsParam {
    type: string;
    params: string[];
}

export interface DashboardsSettingsSelect {
    value: string | number;
    viewValue: string;
}

export enum DashboardsSettingsPeriods {
    ANY = 'any',
    LAST_3_MONTHS = 'last3Months',
    LAST_6_MONTHS = 'last6Months',
    LAST_12_MONTHS = 'last12Months',
    BEGINNING = 'beginning'
}

export const DashboardsColors = [
    '#4285F4',    // blue
    '#DB4437',    // red
    '#F4B400',    // yellow
    '#0F9D58',    // green
    '#AB47BC',    // purple
    '#00ACC1',    // light blue
    '#FF7043',    // orange
    '#9E9D24',    // browny yellow
    '#5C6BC0'     // dark blue
];

export interface ResultTable {
    item: Item;
    date?: string;
    number?: number;
    expertises?: number;
    creations?: {
        nb: number,
        queryParams: any
    };
    modifications?: {
        nb: number,
        queryParams: any
    };
}

export interface ResultCounter {
    item: Item;
    date?: string;
    number?: number;
    expertises?: number;
    creations?: {
        nb: number,
        queryParams: any
    };
    modifications?: {
        nb: number,
        queryParams: any
    };
}
