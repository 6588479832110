import { Component, OnInit, Input, Output, ViewEncapsulation, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AppSettings, Settings } from '@app/app.settings';
import { ServiceItems } from '@services/items.service';
import { MenuService } from '../menu.service';
import { ServiceSecurity } from '@services/security.service';
import { User } from '@src/app/models';
import { Menu } from '../menu.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-vertical-menu',
    templateUrl: './vertical-menu.component.html',
    styleUrls: ['./vertical-menu.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [MenuService]
})
export class VerticalMenuComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() menuItems: Menu[];

    @Input() menuParentId: number;

    @Output() clickMenuItem: EventEmitter<any> = new EventEmitter<any>();

    public parentMenu: Array<any>;
    public settings: Settings;
    public currentUser: User;

    private _destroyed$ = new Subject();

    constructor(
        public appSettings: AppSettings,
        private serviceItems: ServiceItems,
        public menuService: MenuService,
        public router: Router,
        private serviceSecurity: ServiceSecurity
    ) {
        this.settings = this.appSettings.settings;
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }

    trackMenus(index: number, o: any) {
        return o ? o.id : index;
    }

    private _configureMenu() {
        this.menuItems.forEach(
            (menu: Menu) => {
                const isCurrentUserAnonymous = this.serviceSecurity.isCurrentUserAnonymous();
                let isAccessGrantedByRole: boolean;

                if (menu.id === 41) {
                    isAccessGrantedByRole = this.serviceItems.getItemService('Page').userCanCreateItem();
                } else if (menu.id === 51) {
                    isAccessGrantedByRole = this.serviceItems.getItemService('Member').userCanCreateItem();
                } else if (menu.id === 61) {
                    isAccessGrantedByRole = this.serviceItems.getItemService('Worklab').userCanCreateItem();
                } else {
                    isAccessGrantedByRole = this.serviceSecurity.hasMinimumRole(menu.minimumRole);
                }
                menu.isVisible = menu.isVisible && ((!isCurrentUserAnonymous && isAccessGrantedByRole && menu.id < 100) || (isCurrentUserAnonymous && isAccessGrantedByRole));
            });
    }

    ngOnInit() {
        this.currentUser = this.serviceSecurity.user;
        this.parentMenu = this.menuItems.filter((item: Menu) => item.parentId === this.menuParentId);

        this._configureMenu();

        // Check current user rights
        this.serviceSecurity.currentUserObservable.pipe(
            takeUntil(this._destroyed$)
        ).subscribe(
            (user: User) => {
                if (user.username !== this.currentUser.username) {
                    this.currentUser = user;
                    this._configureMenu();
                }
            }
        );
    }

    ngAfterViewInit() {
        this.router.events.pipe(
            takeUntil(this._destroyed$)
        ).subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (this.settings.fixedHeader) {
                    const mainContent = document.getElementById('main-content');
                    if (mainContent) {
                        mainContent.scrollTop = 0;
                    }
                } else {
                    document.getElementsByClassName('mat-drawer-content')[0].scrollTop = 0;
                }
            }
        });
    }

    onClick(menuId: number) {
        this.menuService.toggleMenuItem(menuId);
        this.menuService.closeOtherSubMenus(this.menuItems, menuId);
        this.clickMenuItem.emit(menuId);
    }
}
