import * as _ from 'lodash';
import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, forkJoin, concat } from 'rxjs';
import {
    finalize,
    map,
    startWith,
    debounceTime,
    takeUntil,
    first
} from 'rxjs/operators';

import { Page, Member, Worklab, Tag, Item, ItemsDictionary } from '@models';
import {
    ServiceItems,
    ServiceTags,
    ServiceToaster,
    ServiceErrors,
    ServiceTextAnalyser,
    ServiceDatastore
} from '@services';
import { MultiAttributesComponent } from '../multi-attributes.component';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { TagsTreeComponent } from './tags-tree/tags-tree.component';

@Component({
    selector: 'tags',
    templateUrl: './tags.component.html',
    styleUrls: ['./tags.component.scss']
})
export class TagsComponent extends MultiAttributesComponent {
    readonly visible = true;
    readonly removable = true;
    readonly addOnBlur = false;
    readonly selectable = true;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];

    @ViewChild('auto', { static: false }) auto;
    @ViewChild('chipList', { static: false }) chipList;
    @ViewChild('tagInput', { static: false }) tagInput: ElementRef<
        HTMLInputElement
    >;

    @Input() showTitle = true;

    private _item: Page | Member | Worklab;

    @Input()
    set item(value: Page | Member | Worklab) {
        this._item = value;
        this.handleEditionMode(this._editionMode);
    }
    get item(): Page | Member | Worklab {
        return this._item;
    }

    private _allTags: Tag[] = [];
    private _allTagsLoaded = false;
    private _allPotentialTags: Tag[] = [];

    public filteredTags: Observable<Tag[]>;
    public newTagIsNotValid = false;
    public tagSaving = false;
    public useTagsTree = true;

    constructor(
        private _dialog: MatDialog,
        private serviceToaster: ServiceToaster,
        private serviceErrors: ServiceErrors,
        private serviceTags: ServiceTags,
        private serviceItems: ServiceItems,
        private serviceDatastore: ServiceDatastore,
        protected serviceTextAnalyser: ServiceTextAnalyser
    ) {
        super(serviceTags, serviceTextAnalyser);
        this.field = 'tags';

        this.useTagsTree = USE_TAGS_TREE;

        this.filteredTags = this.ctrl.valueChanges.pipe(
            startWith(<string>null),
            debounceTime(200),
            map((tag: string) => {
                return tag ? this._filterTags(tag) : [];
            })
        );
    }

    protected handleOrder(orderDirection: 'asc' | 'desc') {
        if (
            this.item instanceof Page ||
            this.item instanceof Member ||
            this.item instanceof Worklab
        ) {
            this.item.tags = this.serviceTags.sortArray(
                this.item.tags,
                orderDirection
            );
        }
    }

    protected handleEditionMode(value: boolean) {
        if (
            this.item instanceof Page ||
            this.item instanceof Member ||
            this.item instanceof Worklab
        ) {
            this.isCreation = _.isUndefined(this.item.id);
            this.canEdit = this.serviceTags.userCanModifyTags(this.item);
            if (value === true && this.canEdit === true) {
                this._loadPotentialPotentialTags();
            } else {
                this._destroyed$.next();
                this._destroyed$.complete();
                // this._destroyed$.unsubscribe();
            }
        }
    }

    private _loadPotentialPotentialTags() {
        // if (!this.useTagsTree || !this._allTagsLoaded) {
        //     this.loading = true;

        //     // If use tree: use all the tags in the database else use only the tags of the items
        //     const fetchedTagList = this.serviceDatastore.fetchAllTags(false);
        //     fetchedTagList.subscribe({
        //         complete: () => {
        //             this.loading = false;
        //             this._allTagsLoaded = true;
        //         },
        //         next: fetchedTagMap => {
        //             const tagList = Array.from(fetchedTagMap.values());

        //             if (!this.useTagsTree) {
        //                 const itemTagsIds = _.map(this.item.tags, 'id');
        //                 const orderedTags = tagList.filter(t => {
        //                     return itemTagsIds.indexOf(t.id) === -1;
        //                 }).sort((a, b) =>
        //                     a.title.localeCompare(b.title)
        //                 );
        //                 this._allPotentialTags = orderedTags;
        //                 this._allTags = orderedTags;
        //             } else {
        //                 const orderedTags = tagList.sort((a, b) =>
        //                     a.title.localeCompare(b.title)
        //                 );
        //                 this._allPotentialTags = orderedTags;
        //                 this._allTags = orderedTags;
        //             }
        //         }
        //     });
        // }
    }

    private _filterTags(query: string): Tag[] {
        const queryLowerUnaccent = this.serviceTextAnalyser
            .removeDiacritics(query)
            .toLowerCase();
        return this._allPotentialTags.filter((tag: Tag) => {
            const title = this.serviceTextAnalyser
                .removeDiacritics(tag.title)
                .toLowerCase();
            return title.indexOf(queryLowerUnaccent) !== -1;
        });
    }

    addTag(tag: Tag): void {
        this.ctrl.reset();
        this.chipList.errorState = false;
        this.tagInput.nativeElement.value = '';
        this.ctrl.disable();
        this.tagSaving = true;

        this.item.tags = this.item.tags || [];
        this.item.tags.push(tag);

        if (!this.isCreation) {
            this.serviceTags
                .addTag(this.item, tag)
                .pipe(
                    finalize(() => {
                        this.ctrl.enable();
                        this.tagSaving = false;
                        this.tagInput.nativeElement.focus();
                    }),
                    first(),
                    takeUntil(this._destroyed$)
                )
                .subscribe(
                    (res: any) => {
                        const item = this.serviceItems.item;
                        if (_.has(res, 'newTag')) {
                            _.remove(item.tags, function(o: Tag) {
                                return o.id === tag.id;
                            });
                            const newTag = new Tag().deserialize(res.newTag);
                            item.tags.push(newTag);

                            _.remove(this._allPotentialTags, function(o: Tag) {
                                return o.id === newTag.id;
                            });
                        }

                        _.remove(this._allPotentialTags, function(o: Tag) {
                            return o.id === tag.id;
                        });

                        if (item.witness) {
                            item.witness.tags = item.tags;
                        }
                        this.serviceItems.item = item;

                        const params = {
                            tag: tag.title,
                            title: item.title
                        };
                        this.serviceToaster.success('ITEM.TAGS.ADD', params);
                    },
                    (error: HttpErrorResponse) => {
                        this.serviceErrors.handleError('ITEM.TAGS.ADD', error);
                    }
                );
        } else {
            _.remove(this._allPotentialTags, function(o: Tag) {
                return o.title === tag.title;
            });
            this.ctrl.enable();
            this.tagSaving = false;
            this.tagInput.nativeElement.focus();
        }
    }

    removeTag(tag: Tag): void {
        this.ctrl.reset();
        this.chipList.errorState = false;
        this.tagInput.nativeElement.value = '';
        this.ctrl.disable();
        this.tagSaving = true;

        this.item.tags = this.item.tags || [];
        _.remove(this.item.tags, function(o: any) {
            return o.title === tag.title || o.id === tag.id;
        });

        if (!this.isCreation) {
            this.serviceTags.removeTag(this.item, tag).pipe(
                    finalize(() => {
                        this.ctrl.enable();
                        this.tagSaving = false;
                        this.tagInput.nativeElement.focus();
                    }),
                    first(),
                    takeUntil(this._destroyed$)
                )
                .subscribe(
                    () => {
                        const tags = this.item.tags;
                        this._item.tags = tags;
                        if (this._item.witness) {
                            this._item.witness.tags = tags;
                        }
                        this.serviceItems.item = this._item;

                        const params = {
                            tag: tag.title,
                            title: this.item.title
                        };

                        // if (!this.useTagsTree) {
                            this.serviceTags.removeOphansTag().subscribe(
                                () => {
                                    this._loadPotentialPotentialTags();
                                    this.serviceToaster.success('ITEM.TAGS.REMOVE', params);
                                }
                            );
                        // } else {
                        //     this._loadPotentialPotentialTags();
                        // }
                    },
                    (error: HttpErrorResponse) => {
                        this.item.tags.push(tag);
                        this.serviceErrors.handleError(
                            'ITEM.TAGS.REMOVE',
                            error
                        );
                    }
                );
        } else {
            this.ctrl.enable();
            this.tagSaving = false;
            this.tagInput.nativeElement.focus();
        }
    }

    newTagBlured(): void {
        this.ctrl.reset();
        this.chipList.errorState = false;
        this.tagInput.nativeElement.value = '';
    }

    newTagChanged(): void {
        this.newTagIsNotValid = this.item.tags.some(
            (o: Tag) =>
                _.trim(o.title.toLowerCase()) ===
                _.trim(this.tagInput.nativeElement.value.toLowerCase())
        );
        this.chipList.errorState = this.newTagIsNotValid;
    }

    newTagEntered(event): void {
        const value = _.trim(event.value);
        this.newTagIsNotValid = this.item.tags.some(
            (o: Tag) => _.trim(o.title.toLowerCase()) === value.toLowerCase()
        );
        this.chipList.errorState = this.newTagIsNotValid;
        if (!this.newTagIsNotValid) {
            this.item.tags = this.item.tags || [];
            if ((value || '').trim()) {
                this.addTag(new Tag().deserialize({ title: value.trim() }));
            }
        }
    }
}
