export interface PreferencesTableColumnsForPage {
    isUserFollowing: boolean;
    group: boolean;
    pageStatus: boolean;
    title: boolean;
    nbTopics: boolean;
    aliases: boolean;
    tags: boolean;
    creators: boolean;
    modifiers: boolean;
    experts: boolean;
    followers: boolean;
    associatedPagesOut: boolean;
    linkedWorklabs: boolean;
}

export interface PreferencesTableColumnsForMember {
    isUserFollowing: boolean;
    group: boolean;
    title: boolean;
    nbTopics: boolean;
    tags: boolean;
    followers: boolean;
    expertises: boolean;
    followedPages: boolean;
    followedMembers: boolean;
    followedWorklabs: boolean;
    roleInDatabase: boolean;
}

export interface PreferencesTableColumnsForWorklab {
    isUserFollowing: boolean;
    group: boolean;
    title: boolean;
    nbTopics: boolean;
    aliases: boolean;
    tags: boolean;
    creators: boolean;
    modifiers: boolean;
    participants: boolean;
    linkedPages: boolean;
}

export class PreferencesTableColumns {
    Page: PreferencesTableColumnsForPage;
    Member: PreferencesTableColumnsForMember;
    Worklab: PreferencesTableColumnsForWorklab;

    constructor() {
        try {
            this.Page = COLUMNS_PAGES;
        } catch (error) {
            this.Page = {
                isUserFollowing: true,
                group: true,
                pageStatus: true,
                title: true,
                nbTopics: true,
                aliases: false,
                modifiers: true,
                experts: true,
                tags: true,
                creators: false,
                followers: false,
                associatedPagesOut: false,
                linkedWorklabs: false
            };
        }

        try {
            this.Member = COLUMNS_MEMBERS;
        } catch (error) {
            this.Member = {
                isUserFollowing: true,
                group: true,
                title: true,
                nbTopics: false,
                followers: false,
                expertises: true,
                tags: true,
                followedPages: true,
                followedMembers: false,
                followedWorklabs: false,
                roleInDatabase: false
            };
        }

        try {
            this.Worklab = COLUMNS_WORKLABS;
        } catch (error) {
            this.Worklab = {
                isUserFollowing: true,
                group: true,
                title: true,
                nbTopics: true,
                aliases: false,
                creators: false,
                participants: true,
                linkedPages: false,
                modifiers: true,
                tags: true
            };
        }
    }
}
