import * as _ from 'lodash';
import { Component, OnDestroy, NgZone, ChangeDetectorRef, ViewChild, Injectable, ElementRef } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Tag } from '@models';
import { BehaviorSubject, Subject } from 'rxjs';
import { ServiceTags, ChecklistDatabase } from '@src/app/services/tags.service';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { TranslateService } from '@ngx-translate/core';
// import debug from 'debug';
// const tagMdebug = debug('i2kn:tag-management')
export class EditableTagFlatNode {
    id: string;
    title: string;
    level: number;
    expandable: boolean;
    edition: boolean;
    error: string;
}

export class NewTag {
    title: string;
    parentId: string;
    parentTitle: string;
}

export class ModifiedTag {
    id: string;
    title: string;
    parentId: string;
    parentTitle: string;
}



@Component({
    selector: 'tags-management',
    templateUrl: './tags-management.component.html',
    styleUrls: ['./tags-management.component.scss'],
    providers: [ChecklistDatabase]
})
export class TagsManagementComponent implements OnDestroy {

    @ViewChild('emptyItem', { static: false }) emptyItem: ElementRef;

    /** Map from flat node to nested node. This helps us finding the nested node to be modified */
    flatNodeMap = new Map<EditableTagFlatNode, Tag>();

    /** Map from nested node to flattened node. This helps us to keep the same object for selection */
    nestedNodeMap = new Map<Tag, EditableTagFlatNode>();

    /** The new item's name */
    newItemName = '';

    treeControl: FlatTreeControl<EditableTagFlatNode>;

    treeFlattener: MatTreeFlattener<Tag, EditableTagFlatNode>;

    dataSource: MatTreeFlatDataSource<Tag, EditableTagFlatNode>;

    /* Drag and drop */
    dragNode: EditableTagFlatNode;
    dragNodeExpandOverWaitTimeMs = 300;
    dragNodeExpandOverNode: EditableTagFlatNode;
    dragNodeExpandOverTime: number;
    dragNodeExpandOverArea: string;

    loading = false;
    saving = false;

    newTags: NewTag[];
    modifiedTags: ModifiedTag[];
    removedTags: string[];

    public inputValue: string;

    public newTagAlreadyExists = true;

    private _originalAllTags: Map<string,Tag>;

    private _destroyed$ = new Subject();

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _ngZone: NgZone,
        private _serviceTranslate: TranslateService,
        private _database: ChecklistDatabase,
        private _serviceTags: ServiceTags,
    ) {
        this.newTags = [];
        this.modifiedTags = [];
        this.removedTags = [];

        this.treeFlattener = new MatTreeFlattener(
            this.transformer,
            this.getLevel,
            this.isExpandable,
            this.getChildren
        );
        this.treeControl = new FlatTreeControl<EditableTagFlatNode>(this.getLevel, this.isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

        this.loading = true;
        this._serviceTags.fetchAllTags().pipe(
            takeUntil(this._destroyed$)
        ).subscribe(
            (allTags: Map<string,Tag>) => {
                this.loading = false;
                this._originalAllTags = _.cloneDeep(allTags);
                this._database.initialize(Array.from(allTags.values()));
                this.treeControl.expandAll();
            }
        );

        this._database.dataChange.subscribe(data => {
            this.dataSource.data = data;
        });
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }

    getLevel = (node: EditableTagFlatNode) => node.level;

    isExpandable = (node: EditableTagFlatNode) => node.expandable;

    isEdited = (node: EditableTagFlatNode) => node.edition;

    hasError = (node: EditableTagFlatNode) => node.error;

    getChildren = (node: Tag): Tag[] => node.children;

    hasChild = (_: number, _nodeData: EditableTagFlatNode) => _nodeData.expandable;

    hasNoContent = (_: number, _nodeData: EditableTagFlatNode) => _nodeData.title === '';

    areTagsDirty = () => (_.size(this.newTags) > 0 || _.size(this.modifiedTags) > 0 || _.size(this.removedTags) > 0);

    /**
     * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
     */
    transformer = (node: Tag, level: number) => {
        const existingNode = this.nestedNodeMap.get(node);
        const flatNode = existingNode && existingNode.id === node.id
            ? existingNode
            : new EditableTagFlatNode();
        flatNode.id = node.id;
        flatNode.title = node.title;
        flatNode.level = level;
        flatNode.expandable = (node.children && node.children.length > 0);
        this.flatNodeMap.set(flatNode, node);
        this.nestedNodeMap.set(node, flatNode);
        return flatNode;
    }

    /** Select the category so we can insert the new item. */
    addNewItem(node: EditableTagFlatNode) {
        this.treeControl.dataNodes.forEach(dataNode => {
            dataNode.edition = false;
        });
        if (node) {
            const parentNode = this.flatNodeMap.get(node);
            const newNodeIsPresent = (parentNode.children || []).some(child => child.title === '');
            if (!newNodeIsPresent) {
                const newTag = new Tag();
                newTag.title = '';
                newTag.parent = new Tag().deserialize({id: parentNode.id, title: parentNode.title});

                this._database.insertTag(parentNode, newTag);
                this.treeControl.expand(node);
            }
        } else {
            const newNodeIsPresent = (this._database.data || []).some(child => child.title === '');
            if (!newNodeIsPresent) {
                const newTag = new Tag();
                newTag.title = '';
                this._database.insertRootTag(newTag);
                this.treeControl.expand(node);
            }
        }
    }

    refreshCreateButton() {
        const title = this.inputValue;
        if (title && _.trim(title) !== '') {
            const selectedNode = this.treeControl.dataNodes.find((node) => node.title === title);

            this.newTagAlreadyExists = !!selectedNode;
        }else{
            this.newTagAlreadyExists = true;
        }
    }

    checkNode(node: EditableTagFlatNode, title: string) {
        if (title && _.trim(title) !== '') {
            const titleIsPresent = (node.id && this.treeControl.dataNodes.some(
                (dataNode: EditableTagFlatNode) => {
                    return (dataNode.title === title && node.id !== dataNode.id);
                }
            )) || (!node.id && this.newTags.some(
                (newtag: NewTag) => {
                    return (newtag.title === title && node.title !== title);
                }
            ));
            if (!titleIsPresent) {
                delete node.error;
            } else {
                node.error = this._serviceTranslate.instant('TAGS.MANAGEMENT.ALREADY');
            }
        }
    }
    
    createNode() {
        const title = this.inputValue;
        if (!this.newTagAlreadyExists) {
            const tag = new Tag();
            tag.title = title;
            const newTag = new NewTag();
            newTag.title = title;

            this._database.insertRootTag(tag);
            
            const newTags = this.newTags;
            newTags.push(newTag);
            this.newTags = _.cloneDeep(newTags);
        }
        
        this.refreshCreateButton()
    }

    /** Save the node to database */
    saveNode(node: EditableTagFlatNode, title: string) {
        if (title && _.trim(title) !== '' && !node.error) {
            const titleIsPresent = (node.id && this.treeControl.dataNodes.some(
                (dataNode: EditableTagFlatNode) => {
                    return (dataNode.title === title && node.id !== dataNode.id);
                }
            )) || (!node.id && this.newTags.some(
                (newtag: NewTag) => {
                    return (newtag.title === title && node.title !== title);
                }
            )) || (!node.id && this.treeControl.dataNodes.some(
                (dataNode: EditableTagFlatNode) => {
                    return (dataNode.title === title);
                }
            ));
            const nestedNode = this.flatNodeMap.get(node);
            const originalTitle = nestedNode.title;

            if (!titleIsPresent) {
                node.edition = false;
                delete node.error;
                this._database.updateItem(nestedNode, title);

                const newTags = this.newTags;
                if (node.id) {
                    const tag = this.flatNodeMap.get(node);
                    this._storeModifiedTag(node, tag);
                } else {
                    _.remove(this.newTags, function(existingNewTag: NewTag) {
                        return existingNewTag.title === originalTitle;
                    });
                    const newTag = new NewTag();
                    newTag.title = title;
                    newTag.parentId = (nestedNode.parent) ? nestedNode.parent.id : null;
                    newTag.parentTitle = (nestedNode.parent) ? nestedNode.parent.title : null;
                    newTags.push(newTag);

                    const tag = new Tag();
                    tag.title = title;
                    if(nestedNode.parent) {
                        const parentNode = this._database.getParentFromNodes(nestedNode);
                        this._database.insertTag(parentNode, tag);
                    } else {
                        this._database.insertRootTag(tag);
                    }
                    this._database.deleteItem(nestedNode);
                }
                newTags.forEach((dataNode: ModifiedTag) => {
                    if (dataNode.parentId && dataNode.parentId === nestedNode.id) {
                        dataNode.parentTitle = title;
                    } else if (!dataNode.parentId && dataNode.parentTitle === originalTitle) {
                        dataNode.parentTitle = title;
                    }
                });
                this.newTags = _.cloneDeep(newTags);

                const modifiedTags = this.modifiedTags;
                modifiedTags.forEach((dataNode: ModifiedTag) => {
                    if (dataNode.parentId && dataNode.parentId === nestedNode.id) {
                        dataNode.parentTitle = title;
                    } else if (!dataNode.parentId && dataNode.parentTitle === originalTitle) {
                        dataNode.parentTitle = title;
                    }
                });
                this.modifiedTags = _.cloneDeep(modifiedTags);
                this.removedTags = _.cloneDeep(this.removedTags);
            } else {
                node.title = nestedNode.title;
                node.edition = false;
                node.error = this._serviceTranslate.instant('TAGS.MANAGEMENT.ALREADY');
            }
        } else {
            delete node.error;
            this.removeNode(node);
        }

        this.refreshCreateButton()
    }

    /** Remove the node to database */
    removeNode(node: EditableTagFlatNode) {
        const nestedNode = this.flatNodeMap.get(node);
        this._storeRemovedTag(node);
        this._database.deleteItem(nestedNode);

        const newTags = this.newTags;
        this.newTags = _.cloneDeep(newTags);

        const modifiedTags = this.modifiedTags;
        this.modifiedTags = _.cloneDeep(modifiedTags);

        const removedTags = this.removedTags;
        this.removedTags = _.cloneDeep(removedTags);

        this.refreshCreateButton()
    }

    private _storeRemovedTag(node: EditableTagFlatNode) {
        if (node.id) {
            const removedTags = this.removedTags;
            if (removedTags.indexOf(node.id) === -1) {
                removedTags.push(node.id);
            }
            this.removedTags = removedTags;

            _.remove(this.newTags, function(newTag: NewTag) {
                return node.id === newTag.parentId;
            });

            _.remove(this.modifiedTags, function(modifiedTag: ModifiedTag) {
                return node.id === modifiedTag.id || node.id === modifiedTag.parentId;
            });
        } else {
            _.remove(this.newTags, function(newTag: NewTag) {
                return node.title === newTag.title || node.title === newTag.parentTitle;
            });

            _.remove(this.modifiedTags, function(modifiedTag: ModifiedTag) {
                return node.title === modifiedTag.title || node.title === modifiedTag.parentTitle;
            });
        }

        const descendants = this.treeControl.getDescendants(node);
        descendants.forEach(dataNode => {
            this._storeRemovedTag(dataNode);
        });
    }

    private _storeModifiedTag(node: EditableTagFlatNode, tag: Tag) {
        const modifiedTags = this.modifiedTags;
        let existingModifiedTag: ModifiedTag;
        if (tag.id) {
            existingModifiedTag = _.find(modifiedTags, {id: tag.id});
        } else {
            existingModifiedTag = _.find(modifiedTags, {title: tag.title});
        }

        if (!existingModifiedTag) {
            existingModifiedTag = new ModifiedTag();
            existingModifiedTag.id = tag.id;
        } else if (tag.id) {
            _.remove(modifiedTags, function(modifiedTag: ModifiedTag) {
                return modifiedTag.id === tag.id;
            });
        } else {
            _.remove(modifiedTags, function(modifiedTag: ModifiedTag) {
                return modifiedTag.title === tag.title;
            });
        }
        existingModifiedTag.title = tag.title;

        const parentNode = this._database.getParentFromNodes(tag);
        existingModifiedTag.parentId = (parentNode) ? parentNode.id : null;
        existingModifiedTag.parentTitle = (parentNode) ? parentNode.title : null;
        modifiedTags.push(existingModifiedTag);
        this.modifiedTags = _.cloneDeep(modifiedTags);
    }

    editNode(node: EditableTagFlatNode) {
        const originalState = node.edition;
        this.treeControl.dataNodes.forEach(dataNode => {
            dataNode.edition = false;
            delete dataNode.error;
        });
        node.edition = !originalState;
    }

    dblclickNode(node: EditableTagFlatNode) {
        this.treeControl.dataNodes.forEach(dataNode => {
            dataNode.edition = false;
            delete dataNode.error;
        });
        node.edition = true;
    }

    handleDragStart(event, node: EditableTagFlatNode) {
        // Required by Firefox (https://stackoverflow.com/questions/19055264/why-doesnt-html5-drag-and-drop-work-in-firefox)
        event.dataTransfer.setData('foo', 'bar');
        event.dataTransfer.setDragImage(this.emptyItem.nativeElement, 0, 0);
        this.dragNode = node;
        this.treeControl.collapse(node);
        this._changeDetectorRef.detach();
    }

    handleDragOver(event, node: EditableTagFlatNode) {
        event.preventDefault();

        // Handle node expand
        if (node === this.dragNodeExpandOverNode) {
            if (this.dragNode !== node && !this.treeControl.isExpanded(node)) {
                if ((new Date().getTime() - this.dragNodeExpandOverTime) > this.dragNodeExpandOverWaitTimeMs) {
                    this._ngZone.runOutsideAngular(() => {
                        this.treeControl.expand(node);
                    });
                }
            }
        } else {
            this.dragNodeExpandOverNode = node;
            this.dragNodeExpandOverTime = new Date().getTime();
        }

        const percentageY = event.offsetY / event.target.clientHeight;
        const oldState = _.clone(this.dragNodeExpandOverArea);
        if (percentageY < 0.25) {
            this.dragNodeExpandOverArea = 'above';
        } else if (percentageY > 0.75) {
            this.dragNodeExpandOverArea = 'below';
        } else {
            this.dragNodeExpandOverArea = 'center';
        }

        if (this.dragNodeExpandOverArea !== oldState) {
            this._changeDetectorRef.detectChanges();
        }
    }

    handleDrop(event, node: EditableTagFlatNode) {
        this._changeDetectorRef.reattach();

        event.preventDefault();
        if (node !== this.dragNode) {
            let newItem: Tag;
            if (this.dragNodeExpandOverArea === 'above') {
                newItem = this._database.copyPasteItemAbove(this.flatNodeMap.get(this.dragNode), this.flatNodeMap.get(node));
            } else if (this.dragNodeExpandOverArea === 'below') {
                newItem = this._database.copyPasteItemBelow(this.flatNodeMap.get(this.dragNode), this.flatNodeMap.get(node));
            } else {
                newItem = this._database.copyPasteItem(this.flatNodeMap.get(this.dragNode), this.flatNodeMap.get(node));
            }
            this._database.deleteItem(this.flatNodeMap.get(this.dragNode));
            this.treeControl.expandDescendants(this.nestedNodeMap.get(newItem));
            if (newItem.id) {
                this._storeModifiedTag(node, newItem);
            } else {
                const existingNewTag = _.find(this.newTags, {title: newItem.title});
                _.remove(this.newTags, function(_newTag: NewTag) {
                    return _newTag.title === existingNewTag.title;
                });
                const newTag = new NewTag();
                newTag.title = existingNewTag.title;
                newTag.parentId = (node.id) ? node.id : null;
                newTag.parentTitle = (node.title) ? node.title : null;
                this.newTags.push(newTag);
            }
        }
        this.dragNode = null;
        this.dragNodeExpandOverNode = null;
        this.dragNodeExpandOverTime = 0;
    }

    handleDragEnd() {
        this.dragNode = null;
        this.dragNodeExpandOverNode = null;
        this.dragNodeExpandOverTime = 0;
    }

    savingInProgress($event) {
        this.saving = $event.inProgress;
        if (_.isArray($event.tags)) {
            const allTags = $event.tags;
            this._database.initialize(allTags);
            this.treeControl.expandAll();

            this.newTags = [];
            this.modifiedTags = [];
            this.removedTags = [];
        }
    }

    resetTags() {
        this._database.initialize(Array.from(this._originalAllTags.values()));
        this.treeControl.expandAll();

        this.newTags = [];
        this.modifiedTags = [];
        this.removedTags = [];
    }
}
