import * as _ from 'lodash';
import { Component } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { ToasterConfig, BodyOutputType } from 'angular2-toaster';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import localeFr from '@angular/common/locales/fr';

import { AppSettings, Settings } from '@app/app.settings';
import { ServiceKb, ServiceSecurity, ServicePreferences, ServiceMatomo } from '@services';
import { MatomoInjector } from 'ngx-matomo';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    public settings: Settings;
    public toasterConfig: ToasterConfig;
    private isMatomoInitialised = false;

    constructor(
        public appSettings: AppSettings,
        private serviceKb: ServiceKb,
        private servicePreferences: ServicePreferences,
        private serviceSecurity: ServiceSecurity,
        private matomoInjector: MatomoInjector,
        private serviceMatomo: ServiceMatomo,
        ngxTranslateService: TranslateService
    ) {
        // Add extra locale data for french
        registerLocaleData(localeFr, 'fr');

        this.settings = this.appSettings.settings;

        this.toasterConfig = new ToasterConfig({
            limit: 5,
            tapToDismiss: true,
            mouseoverTimerStop: false,
            showCloseButton: true,
            timeout: {
                error: 0,
                success: 5000,
                warning: 15000
            },
            bodyOutputType: BodyOutputType.TrustedHtml
        });

        // Set skin
        let userTheme = this.servicePreferences.getPreference('theme');
        this.appSettings.settings.theme = userTheme;

        // Set language
        let userLanguage = this.servicePreferences.getPreference('language');
        ngxTranslateService.setDefaultLang(userLanguage);
        ngxTranslateService.use(userLanguage);

        const currentKbObservable = this.serviceKb.kbObservable;
        const currentUserObservable = this.serviceSecurity.currentUserObservable;

        combineLatest([currentUserObservable, currentKbObservable]).pipe(
            map(
                ([resUser, resKb]) => {
                    userTheme = this.servicePreferences.getPreference('theme');
                    this.appSettings.settings.theme = userTheme;

                    userLanguage = _.get(resUser, 'preferences.language', this.appSettings.settings.language);
                    this.appSettings.settings.language = userLanguage || resKb.defaultLanguage || this.appSettings.settings.language;
                    ngxTranslateService.setDefaultLang(userLanguage);
                    ngxTranslateService.use(userLanguage);

                    // Setup Matomo Piwik
                    if (resKb && !this.isMatomoInitialised) {
                        if (_.get(MATOMO_CONSTANTS, 'enabled') === true && resKb.slug in _.get(MATOMO_CONSTANTS, 'siteIds', {})) {
                            const matomoUrl = _.get(MATOMO_CONSTANTS, 'url', '');
                            const matomoSiteId = _.get(MATOMO_CONSTANTS, 'siteIds.' + resKb.slug);
                            this.matomoInjector.init(matomoUrl, matomoSiteId);
                            this.serviceMatomo.init();
                        }
                        this.isMatomoInitialised = true;
                    }
                }
            )
        ).subscribe();
    }
}
