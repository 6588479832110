import { Component, OnInit, OnDestroy, ViewChild, ElementRef, NgZone, Renderer2 } from '@angular/core';
import { ServicePreferences } from '@services';
import * as moment from 'moment';

@Component({
    selector: 'current-time',
    templateUrl: './current-time.html',
    styleUrls: ['./current-time.scss']
})
export class CurrentTimeComponent implements OnInit, OnDestroy  {
    @ViewChild('currentTime', {static: false})
    public currentTime: ElementRef;
    private interval$: any;

    constructor(
        private zone: NgZone,
        private renderer: Renderer2,
        private servicePreferences: ServicePreferences
    ) {
    }

    ngOnInit() {
        const language = this.servicePreferences.getPreference('language');
        const momentDateTimeFormat = this.servicePreferences.getPreference('dateTimeFormatMoment');

        this.zone.runOutsideAngular(() => {
            this.interval$ = setInterval(() => {
                if (this.currentTime) {
                    this.renderer.setProperty(this.currentTime.nativeElement, 'textContent', moment().locale(language).format(momentDateTimeFormat));
                }
            }, 1000);
        });
    }

    ngOnDestroy() {
        clearInterval(this.interval$);
    }
}
