import * as _ from 'lodash';
import { Component, Injector, ViewChild, ElementRef } from '@angular/core';
import { TagsTreeComponent } from '../../items/multi-attributes/tags/tags-tree/tags-tree.component';
import { FilterByComponent } from '../abstract-filter-by.component';

@Component({
    selector: 'filters-by-tag',
    templateUrl: './filters-by-tag.component.html',
    styleUrls: ['./filters-by-tag.component.scss'],
})
export class FiltersByTagComponent extends FilterByComponent {

    @ViewChild('tagTree', { static: false }) tagTreeElement: TagsTreeComponent;

    public searchedTerm = '';

    public operatorAndEnabled = true;

    constructor(injector: Injector) {
        super(injector);
    }

    initValue() {
        const userFilters = this.serviceListings.filters;
        this.operatorAndEnabled = _.get(userFilters, 'tagOp', 'and') === 'and';
    }

    reset() {
        this.searchedTerm = '';
        this.tagTreeElement.searchedTerm = '';
        this.tagTreeElement.filterTree();
        this.tagTreeElement.reset();

        const filters = this.serviceListings.filters;
        filters['tagOp'] = 'and';
        if ('tags' in filters) {
            delete filters['tags'];
        }
        this.serviceListings.filters = filters;
        this.serviceListings.dataStoreChanged();
    }

    filterTag() {
        this.tagTreeElement.searchedTerm = this.searchedTerm;
        this.tagTreeElement.filterTree();
    }

    onOperatorChanged() {
        const filters = this.serviceListings.filters;
        filters['tagOp'] = this.operatorAndEnabled ? 'and' : 'or';
        this.serviceListings.filters = filters;
        this.serviceListings.dataStoreChanged();
        this.saveFilter();
    }

    onSelectedTagsChanged(event) {
        const selectedTagsIds = _.get(event, 'selectedTagsIds', []);
        const userFilters = this.serviceListings.filters;
        userFilters.tags = selectedTagsIds;
        this.serviceListings.filters = userFilters;
        this.serviceListings.dataStoreChanged();
        this.saveFilter();
    }
}
