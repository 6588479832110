import * as _ from 'lodash';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, first, finalize } from 'rxjs/operators';
import { Kb, Society, Skin } from '@models';
import { Role } from '@models';
import { ServiceUrls } from '@services/urls.service';
import { CommonService } from './common.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class ServiceKb extends CommonService {

    public kbObservable: Observable<Kb>;
    private _kbSubject: BehaviorSubject<Kb>;
    private _allSocieties: Society[];
    private _allSkins: Skin[];

    constructor(
        protected injector: Injector,
        private router: Router
    ) {
        super(injector);

        this._kbSubject = new BehaviorSubject<Kb>(null);
        this.kbObservable = this._kbSubject.asObservable();
    }

    get kb(): Kb {
        return this._kbSubject.value;
    }

    set kb(value: Kb) {
        if (this.kb) {
            if (!this.kb.witness || !value.witness) {
                value.witness = _.cloneDeep(value);
            } else {
                value.isDirty = !this.areEquals(value, this.kb.witness);
            }
        }
        this._kbSubject.next(value);
    }

    isCurrentKbPublic(): boolean {
        return _.get(this, 'kb.isPublic', false);
    }

    isCurrentKbNeedsConfidentialityAgreement(): boolean {
        return _.get(this, 'kb.needsConfidentialityAgreement', false);
    }

    getConfidentialityAgreement(): string {
        return _.get(this, 'kb.confidentialityAgreement', '');
    }

    isCurrentKb(kb: Kb): boolean {
        return kb.slug === this.currentKbSlug && kb.society.slug === this.currentSocietySlug;
    }
    
    canUserCreateTags(): boolean {
        return _.get(this, 'kb.userCanCreateTags', true);
    }

    becomeContributorRequests(): Observable<any> {
        const host = this.urlApi;
        return this.http.get(host + 'requests/become-contributor', {});
    }

    downloadKb(format: 'zip', lang: 'fr' | 'en'): Observable<any> {
        const toast = this.serviceToaster.warning('DOWNLOAD_KB', null, 50000);
        const host = this.urlApi;
        return this.http.post<any>(host + '2.1/export.' + lang + '.' + format, {}).pipe(
            finalize(() => {
                this.serviceToaster.clear(toast.toastId);
            })
        );
    }

    update(kb: Kb): Observable<any> {
        const sentData = {
            description: kb.description,
            isPublic: kb.isPublic,
            visibility: kb.visibility,
            skins: kb.skins,
            needsToken: kb.needsToken,
            needsConfidentialityAgreement: kb.needsConfidentialityAgreement,
            confidentialityAgreement: kb.confidentialityAgreement,
            news: kb.news,
            userCanCreateTags: kb.userCanCreateTags
        };
        const host = '//' + PREFIXS.apiPrefix + '.' + _.get(kb, 'society.slug') + '.' + this.sld;
        const url = host + '/kbs/' + _.get(kb, 'society.slug') + '/' + _.get(kb, 'slug');
        return this.http.put<any>(url, sentData);
    }

    getAvailableSkins(): Observable<Skin[]> {
        const host = this.urlSso;
        const societySlug = this.currentSocietySlug;
        const kbSlug = this.currentKbSlug;
        if (!this._allSkins) {
            return this.http.get<Skin[]>(host + 'api/kbs/' + societySlug + '/' + kbSlug + '/skins').pipe(
                map(
                    (result: any) => {
                        const allSkins = this.appSettings.settings.allowedSkins || [];
                        const apiSkinRights = _.get(result, 'skins') || [];
                        _.forEach(allSkins, (s: Skin) => {
                            const apiSkinRight = _.find<Skin>(apiSkinRights, function (o) { return o.name === s.name; });
                            if (apiSkinRight) {
                                s.enabled = apiSkinRight.enabled;
                            }
                        });
                        this._allSkins = allSkins;
                        return allSkins;
                    }
                )
            );
        } else {
            return of(this._allSkins);
        }
    }

    loadPublicKbInfos(): Promise<void> {
        const host = this.urlApi;
        const kbSlug = this.currentKbSlug;

        return this.http.get(host + 'info')
            .toPromise()
            .then(
                (result) => {
                    const societySlug = result['society'];
                    if (societySlug !== '') {
                        result['slug'] = kbSlug;
                        result['urls'] = {
                            homepage: ServiceUrls.convertOIDtoID(_.get(result, 'homepage', '')),
                            help: ServiceUrls.convertOIDtoID(_.get(result, 'help', ''))
                        };
                        const kb = new Kb().deserialize(result);
                        this.kb = kb;
                    } else {
                        this.kb = new Kb();
                        this.router.navigate(['/sso-not-accessible'], { skipLocationChange: true, replaceUrl: false });
                    }
                }
            );
    }

    getPrivateKbInfos(): Observable<any> {
        const host = this.urlSso;
        const societySlug = this.currentSocietySlug;
        const kbSlug = this.currentKbSlug;

        return this.http.get(host + 'api/kbs/' + societySlug + '/' + kbSlug);
    }

    fetchAllSocieties(): Observable<Society[]> {
        const host = this.urlApi;
        if (!this._allSocieties) {
            return this.http.get<Society[]>(host + 'users/kbs-with-roles').pipe(
                first(),
                map(
                    (data: any) => {
                        const d = _.map(data, function (society) {
                            society.kbs = _.map(_.values(society.kbs) || [], function (kb) {
                                kb.role = kb.role || Role.ROLE_NONE;
                                kb.isAdmin = (kb.role === Role.ROLE_SOCIETY_ADMIN || kb.role === Role.ROLE_KB_ADMIN);
                                return new Kb().deserialize(kb);
                            });
                            return new Society().deserialize(society);
                        });
                        this._allSocieties = d;
                        return d;
                    })
            );
        } else {
            return of(this._allSocieties);
        }
    }

    cleanKbSessionData() {
        delete this._allSocieties;
        delete this._allSkins;
    }

    areEquals(kb1, kb2): boolean {
        const _kb1 = _.cloneDeep(kb1);
        const _kb2 = _.cloneDeep(kb2);
        if ((_kb1 && !_kb2) || _kb2 && !_kb1) {
            return false;
        }
        const booleanFields = [
            'isPublic',
            'needsToken',
            'needsConfidentialityAgreement',
            'duplicateMembers',
            'keepRoles',
            'userCanCreateTags'
        ];
        const stringFields = [
            'title',
            'description',
            'confidentialityAgreement',
            'visibility',
            'defaultRole',
            'news'
        ];
        const arrayOfStringsFields = [

        ];
        const arrayOfEnabledFields = [
            'skins'
        ];

        let hasModifications = false;

        _.each(booleanFields, function (field) {
            if (_kb1[field] !== _kb2[field]) {
                hasModifications = true;
            }
        });

        _.each(stringFields, function (field) {
            _kb1[field] = _.trim(_kb1[field]);
            _kb2[field] = _.trim(_kb2[field]);

            if (!_.isEqual((_kb1[field]), (_kb2[field]))) {
                hasModifications = true;
            }
        });
        _.each(arrayOfStringsFields, function (field) {
            _kb1[field] = _kb1[field] || [];
            _kb1[field] = _.map(_kb1[field], _.trim);

            _kb2[field] = _kb2[field] || [];
            _kb2[field] = _.map(_kb2[field], _.trim);

            if (!_.isEqual(_kb1[field].sort(), _kb2[field].sort())) {
                hasModifications = true;

            }
        });

        _.each(arrayOfEnabledFields, function (field) {
            const list2 = _kb2[field];
            _.each(_kb1[field], function (d) {
                const v = _.find(list2, { name: d['name'] });
                if (v && d['enabled'] !== v['enabled']) {
                    hasModifications = true;
                }
            });
        });

        return !hasModifications;
    }
}
