import * as _ from 'lodash';
import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Role } from '@models';
import { Kb, KbSubscription } from '@models';
import { CommonService } from '@services/common.service';
import { ServiceSecurity } from './security.service';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionsService extends CommonService {
    private _allSubscriptions: KbSubscription[];

    constructor(
        protected injector: Injector,
        private serviceSecurity: ServiceSecurity
    ) {
        super(injector);
    }

    getAllSubscriptions(): Observable<KbSubscription[]> {
        if (!this._allSubscriptions) {
            return this.http.get<KbSubscription[]>(this.urlApi + 'subscriptions/', {}).pipe(
                map(
                    (result: KbSubscription[]) => {
                        const s = result.map((subscription: KbSubscription) => {
                            const role = this.serviceSecurity.getRoleByKb(subscription);
                            subscription.isAdmin = (role === Role.ROLE_SOCIETY_ADMIN || role === Role.ROLE_KB_ADMIN);
                            subscription.dateSubscription = (_.get(subscription, 'subscriptionDate.date')) ? new Date(subscription.subscriptionDate.date).getTime() : null;
                            return subscription;
                        });
                        this._allSubscriptions = s;
                        return s;
                    }
                )
            );
        } else {
            return of(this._allSubscriptions);
        }
    }

    getSubscribers(): Observable<KbSubscription[]> {
        return this.http.get<KbSubscription[]>(this.urlApi + 'subscriptions/subscribers', {}).pipe(
            map(
                (result: KbSubscription[]) => {
                    return result.map((subscription: KbSubscription) => {
                        const role = this.serviceSecurity.getRoleByKb(subscription);
                        subscription.isAdmin = (role === Role.ROLE_SOCIETY_ADMIN || role === Role.ROLE_KB_ADMIN);
                        subscription.dateSubscription = (_.get(subscription, 'subscriptionDate.date')) ? new Date(subscription.subscriptionDate.date).getTime() : null;
                        subscription.dateRequest = (_.get(subscription, 'requestDate.date')) ? new Date(subscription.requestDate.date).getTime() : null;
                        return subscription;
                    });
                }
            )
        );
    }

    getKbsForSubscriptions(): Observable<Kb[]> {
        return this.http.get<Kb[]>(this.urlApi + 'subscriptions/kbs-available-for-subscription', {}).pipe(
            map(
                (result: Kb[]) => {
                    return result.map((kb: Kb) => {
                        const role = this.serviceSecurity.getRoleByKb(kb);
                        kb.isAdmin = (role === Role.ROLE_SOCIETY_ADMIN || role === Role.ROLE_KB_ADMIN);
                        return kb;
                    });
                }
            )
        );
    }

    unsubscribe(subscriberKb: Kb, subscribedKb: KbSubscription): Observable<any> {
        const url =
            '//' + PREFIXS.apiPrefix + '.' + _.get(subscriberKb, 'society.slug') + '.' + this.sld + '/'
            + _.get(subscriberKb, 'slug') + '/subscriptions'
            + '?targetSocietySlug=' + _.get(subscribedKb, 'society.slug')
            + '&targetKbSlug=' + _.get(subscribedKb, 'slug');
        this._allSubscriptions = undefined;
        return this.http.delete<any>(url);
    }

    subscribe(kbSubscribed: KbSubscription, kbSubscriber: KbSubscription, token: string): Observable<any> {
        const sentData = {
            societySlug: _.get(kbSubscribed, 'society.slug'),
            kbSlug: _.get(kbSubscribed, 'slug'),
            token: token
        };
        const url = '//' + PREFIXS.apiPrefix + '.' + _.get(kbSubscriber, 'society.slug') + '.' + this.sld + '/'
            + _.get(kbSubscriber, 'slug') + '/subscriptions';
        this._allSubscriptions = undefined;
        return this.http.put(url, sentData).pipe(
            map(
                (subscription: KbSubscription) => {
                    const role = this.serviceSecurity.getRoleByKb(subscription);

                    subscription.isAdmin = (role === Role.ROLE_SOCIETY_ADMIN || role === Role.ROLE_KB_ADMIN);
                    subscription.dateSubscription = (_.get(subscription, 'subscriptionDate.date')) ? new Date(subscription.subscriptionDate.date).getTime() : null;

                    return subscription;
                }
            )
        );
    }

    createSubscriber(kbSubscribed: KbSubscription, kbSubscriber: Kb): Observable<any> {
        const sentData = {
            societySlug: kbSubscriber.society.slug,
            kbSlug: kbSubscriber.slug
        };
        const url = '//' + PREFIXS.apiPrefix + '.' + _.get(kbSubscribed, 'society.slug') + '.' + this.sld + '/'
            + _.get(kbSubscribed, 'slug') + '/subscriptions/subscriber';
        this._allSubscriptions = undefined;
        return this.http.post(url, sentData).pipe(
            map(
                (subscription: KbSubscription) => {
                    const role = this.serviceSecurity.getRoleByKb(subscription);
                    subscription.isAdmin = (role === Role.ROLE_SOCIETY_ADMIN || role === Role.ROLE_KB_ADMIN);
                    subscription.dateSubscription = (_.get(subscription, 'subscriptionDate.date')) ? new Date(subscription.subscriptionDate.date).getTime() : null;

                    return subscription;
                }
            )
        );
    }

    resendToken(kbSubscribed: KbSubscription, kbSubscriber: Kb): Observable<any> {
        const sentData = {
            societySlug: kbSubscriber.society.slug,
            kbSlug: kbSubscriber.slug
        };
        const url = '//' + PREFIXS.apiPrefix + '.' + _.get(kbSubscribed, 'society.slug') + '.' + this.sld + '/'
            + _.get(kbSubscribed, 'slug') + '/subscriptions/resend';

        return this.http.put(url, sentData);
    }
}
