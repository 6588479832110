import * as _ from 'lodash';
import { Component, Inject, EventEmitter, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Member, Worklab, Page } from '@models';
import { DuplicateOptions } from '@src/app/models/duplicate-options.model';
import { ServiceSecurity } from '@src/app/services';

@Component({
    selector: 'dialog-copy-item',
    styleUrls: ['./dialog-copy-item.scss'],
    templateUrl: './dialog-copy-item.html',
})
export class DialogCopyItemComponent implements OnInit {

    public optionsChoosen = new EventEmitter();

    public options: DuplicateOptions;

    public showTags = false;
    public showIsLockedOption = false;

    public showExperts = false;
    public showLinkedPages = false;
    public showLinkedWorklabs = false;

    public showExpertises = false;
    public showFollowedPages = false;
    public showFollowedWorklabs = false;

    public showWorklabPages = false;
    public showParticipants = false;
    public showIsPrivateOption = false;

    constructor(
        private serviceSecurity: ServiceSecurity,
        @Inject(MAT_DIALOG_DATA) public data: Page | Member | Worklab,
        public dialogRef: MatDialogRef<DialogCopyItemComponent>
    ) { }

    ngOnInit() {
        const isUserAdmin = this.serviceSecurity.isAdmin();

        this.options = new DuplicateOptions(this.data['item']);

        const isPage = (_.get(this, 'data.item.group') === 'Page');
        const isMember = (_.get(this, 'data.item.group') === 'Member');
        const isWorklab = (_.get(this, 'data.item.group') === 'Worklab');

        this.showTags = (isPage || isMember || isWorklab);
        this.showIsLockedOption = (isUserAdmin && (isPage || isMember || isWorklab));

        this.showExperts = (isPage);
        this.showLinkedPages = (isPage);
        this.showLinkedWorklabs = (isPage);

        this.showExpertises = (isMember);
        this.showFollowedPages = (isMember);
        this.showFollowedWorklabs = (isMember);

        this.showWorklabPages = (isWorklab);
        this.showParticipants = (isWorklab);
        this.showIsPrivateOption = (isWorklab);
    }

    duplicateItem(options: DuplicateOptions) {
        this.optionsChoosen.emit(options);
        this.dialogRef.close();
    }
}
