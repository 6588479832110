import { Component } from '@angular/core';
import { ServiceTabs, ServiceSecurity } from '@services';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'survey',
    templateUrl: './survey.component.html',
    styleUrls: ['./survey.component.scss'],
})
export class SurveyComponent {
    constructor(
        private serviceSecurity: ServiceSecurity,
        private serviceTabs: ServiceTabs
    ) {}

    goToForum() {
        this.serviceSecurity.setCookie('mustOpenNewTopic', 'true');
        this.serviceSecurity.setCookie('mustShowSurvey', 'true');
        this.serviceTabs.tabIndexChanged(2);
    }
}

@Component({
    selector: 'survey-dialog',
    templateUrl: './survey-dialog.html',
    styleUrls: ['./survey-dialog.scss'],
})
export class DialogSurveyComponent {
    constructor(public dialogRef: MatDialogRef<DialogSurveyComponent>) {}

    cancel(): void {
        this.dialogRef.close();
    }
}
