export interface PreferencesLinkedBlocksOnPage {
    recommendations: boolean;
    experts: boolean;
    associatedPagesOut: boolean;
    linkedWorklabs: boolean;
    followers: boolean;
    creators: boolean;
    modifications: boolean;
    tags: boolean;
    documents: boolean;
    pictureItem: boolean;
};

export interface PreferencesLinkedBlocksOnMember {
    recommendations: boolean;
    expertises: boolean;
    followedPages: boolean;
    followedMembers: boolean;
    followedWorklabs: boolean;
    followers: boolean;
    tags: boolean;
    documents: boolean;
    roleInDatabase: boolean;
};

export interface PreferencesLinkedBlocksOnWorklab {
    recommendations: boolean;
    linkedPagesToWorklab: boolean;
    participants: boolean;
    creators: boolean;
    modifications: boolean;
    tags: boolean;
    documents: boolean;
    pictureItem: boolean;
};

export class PreferencesLinkedBlocks {
    Page: PreferencesLinkedBlocksOnPage;
    Member: PreferencesLinkedBlocksOnMember;
    Worklab: PreferencesLinkedBlocksOnWorklab;

    constructor(type: 'detail' | 'tiles' | 'summaries') {
        if (type === 'detail') {
            try {
                this.Page = DETAIL_BLOCKS_PAGE;
            } catch (error) {
                this.Page = {
                    recommendations: true,
                    experts: true,
                    associatedPagesOut: true,
                    linkedWorklabs: true,
                    followers: false,
                    creators: false,
                    modifications: true,
                    tags: true,
                    documents: true,
                    pictureItem: false
                };
            }
            try {
                this.Member = DETAIL_BLOCKS_MEMBER;
            } catch (error) {
                this.Member = {
                    recommendations: true,
                    expertises: true,
                    followedPages: true,
                    followedMembers: false,
                    followedWorklabs: true,
                    followers: false,
                    tags: true,
                    documents: true,
                    roleInDatabase: false
                };
            }

            try {
                this.Worklab = DETAIL_BLOCKS_WORKLAB;
            } catch (error) {
                this.Worklab = {
                    recommendations: true,
                    linkedPagesToWorklab: true,
                    participants: true,
                    creators: false,
                    modifications: true,
                    tags: true,
                    documents: true,
                    pictureItem: false
                };
            }
        } else if (type === 'tiles') {
            try {
                this.Page = TILES_BLOCKS_PAGE;
            } catch (error) {
                this.Page = {
                    recommendations: false,
                    experts: true,
                    associatedPagesOut: false,
                    linkedWorklabs: false,
                    followers: false,
                    creators: true,
                    modifications: false,
                    tags: false,
                    documents: false,
                    pictureItem: false
                };
            }
            try {
                this.Member = TILES_BLOCKS_MEMBER;
            } catch (error) {
                this.Member = {
                    recommendations: false,
                    expertises: true,
                    followedPages: false,
                    followedMembers: false,
                    followedWorklabs: false,
                    followers: false,
                    tags: false,
                    documents: false,
                    roleInDatabase: false
                };
            }

            try {
                this.Worklab = TILES_BLOCKS_WORKLAB;
            } catch (error) {
                this.Worklab = {
                    recommendations: false,
                    linkedPagesToWorklab: false,
                    participants: false,
                    creators: true,
                    modifications: false,
                    tags: false,
                    documents: false,
                    pictureItem: false
                };
            }
        } else if (type === 'summaries') {
            try {
                this.Page = SUMMARIES_BLOCKS_PAGE;
            } catch (error) {
                this.Page = {
                    recommendations: false,
                    experts: true,
                    associatedPagesOut: true,
                    linkedWorklabs: true,
                    followers: false,
                    creators: false,
                    modifications: false,
                    tags: false,
                    documents: false,
                    pictureItem: false
                };
            }
            try {
                this.Member = SUMMARIES_BLOCKS_MEMBER;
            } catch (error) {
                this.Member = {
                    recommendations: false,
                    expertises: true,
                    followedPages: false,
                    followedMembers: false,
                    followedWorklabs: true,
                    followers: false,
                    tags: false,
                    documents: false,
                    roleInDatabase: false
                };
            }

            try {
                this.Worklab = SUMMARIES_BLOCKS_WORKLAB;
            } catch (error) {
                this.Worklab = {
                    recommendations: false,
                    linkedPagesToWorklab: false,
                    participants: false,
                    creators: false,
                    modifications: false,
                    tags: false,
                    documents: false,
                    pictureItem: false
                };
            }
        }
    }
}
