import { Injectable, Injector, NgZone } from '@angular/core';
import { CommonService } from '@services/common.service';

declare var $: any;

@Injectable({ providedIn: 'root' })
export class ServiceKekule extends CommonService {

    constructor(
        protected injector: Injector,
        private ngZone: NgZone
    ) {
        super(injector);
    }


    public initViewerOnElement(el: HTMLElement, chemObj: any, isEdition: boolean): any {
        return this.ngZone.runOutsideAngular(() => {
            const viewer = new Kekule.ChemWidget.Viewer(el, null, Kekule.Render.RendererType.R2D);
            viewer.setAutofit(true);
            if (isEdition) {
                viewer.setPredefinedSetting('static');
            } else {
                viewer.setPredefinedSetting('basic');
            }
            viewer.setChemObj(chemObj);
            return viewer
        });
    }

    public initComposerOnElement(el: HTMLElement): any {
        const size = this.getSize(el);
        return this.ngZone.runOutsideAngular(() => {
            const chemEditor = new Kekule.Editor.ChemSpaceEditor(document, null, Kekule.Render.RendererType.R2D);
            const chemComposer = new Kekule.Editor.Composer(el, chemEditor);
            chemComposer.setWidth(size.width).setHeight(size.height);
            return chemComposer;
        });
    }

    public getSize(el: any): any {
        var dim = Kekule.HtmlElementUtils.getViewportDimension(el);
        var h = Math.max(dim.height * 0.7, 500);
        var w = 1000;
        return {
            width: w - 40 + 'px',
            height: h - 150 + 'px'
        };
    }

    public getHtmlViewerFromData(molJson: string): string {
        const htmlDiv = '<div class="kekule-mol fr-draggable fr-deletable" style="width:350px;height:250px;" contenteditable="false" json-data-chem-obj="' + encodeURI(molJson) + '"></div><br>';
        return htmlDiv;
    }

    public initKekuleViewers(isEdition?: boolean): void {
        (isEdition === true) ? isEdition = true : isEdition = false;
        const kekuleClass = isEdition ? '.div-froala .kekule-mol' : '.kekule-mol';
        const allKekuleViewers = $(kekuleClass);
        const self = this;
        allKekuleViewers.each(function() {
            const chemJson = $(this).attr('json-data-chem-obj');
            const chemObj = Kekule.IO.loadFormatData(decodeURI(chemJson), 'Kekule-JSON');
            self.initViewerOnElement(this, chemObj, isEdition);
        });
    }
}
