import { Component, OnInit, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AppSettings, Settings } from '@app/app.settings';

interface RecommendationIA {
  id: string;
  link: string;
  title: string;
  type: string;
}

interface ResponseIA {
  id: string;
  base: string;
  payload: string;
  title: string;
  uid: string;
  vector_score: string;
}

/**
 * This is the component that displays the links map of the results of a natural search.
 * 
 * @class ResponseIaComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-links-ia',
  templateUrl: './links-response-ia.component.html',
  styleUrls: ['./links-response-ia.component.scss']
})
export class LinksResponseIaComponent implements OnInit {

  public settings: Settings;

  @Input() responseLinks: ResponseIA[] | undefined;
  @Input() regenerate: Function;
  public error: HttpErrorResponse;
  public limit: 5;
  public recommendations: RecommendationIA[] = []; 

  public loading = false;

  /**
   * Creates an instance of LinksResponseIaComponent.
   * @memberof LinksResponseIaComponent
   */
  constructor(
    private appSettings: AppSettings,
  ) {
    this.settings = this.appSettings.settings;
  }

  /**
   * This method is called when the component is initialized.
   * @method ngOnInit
   */
  ngOnInit(): void {
    this.getRecommendation();
  }

  /**
   * This method formats the uid to id.
   * @transform #XX:Y to XX-Y
   * @method formatUidToId
   * @param uid - The uid.
   * @returns The id.
   */
  formatUidToId(uid: string): string {
    return uid.replace(/#([0-9]+):([0-9]+)/, '$1-$2');
  }

  /**
   * This method gets the recommendations.
   * @method getRecommendation
   */
  getRecommendation(): void {
    if (!this.responseLinks) return;
    this.responseLinks.forEach((link) => {
      this.recommendations.push({
        id: link.id,
        link: '/page/' + this.formatUidToId(link.uid),
        title: link.title,
        type: link.id.split(':')[0],
      });
    })
  }

  /**
   * This method is called when the response is changed.
   * @method onResponseChange
   * @param event - The event object.
   */
  onResponseChange() {
    this.regenerate();
  }

}