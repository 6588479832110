import * as _ from 'lodash';
import { Component, Injector } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FilterByComponent } from '../abstract-filter-by.component';
import { ServiceListings, ServiceUrls } from '@services';
import { Item } from '@models';

@Component({
    selector: 'filters-by-kb',
    templateUrl: './filters-by-kb.component.html',
    styleUrls: ['./filters-by-kb.component.scss']
})
export class FiltersByKbComponent extends FilterByComponent {

    public societies: any[] = [];
    private availableKbsCount = 1;

    constructor(
            protected injector: Injector,
            public serviceListings: ServiceListings,
            protected serviceUrls: ServiceUrls
    ) {
        super(injector);
        this.filterKey = 'kbs';

        // Add current kb as an enabled one
        this.societies.push({
            society: this.serviceUrls.societySlug,
            kbs: [
                {
                    kb: this.serviceUrls.kbSlug,
                    enabled: true
                }
            ]
        });

        this.serviceListings.datasourceObservable.pipe(
            takeUntil(this._destroyed$)
        ).subscribe(
            (data) => {
                let updateRequired = false;
                _.forEach(this.serviceListings.baseData, (item: Item) => {
                    let societyIndex = _.findIndex(this.societies, (s: any) => {
                        return s.society === item.society;
                    });
                    if (societyIndex === -1) {
                        this.societies.push({
                            society: item.society,
                            kbs: []
                        });
                        societyIndex = this.societies.length - 1;
                        updateRequired = true;
                    }

                    const kbIndex = _.findIndex(this.societies[societyIndex]['kbs'], (k: any) => k.kb === item.kb);
                    if (kbIndex === -1) {
                        this.societies[societyIndex]['kbs'].push({
                            kb: item.kb,
                            enabled: (this.serviceListings.type === 'search')
                        });
                        updateRequired = true;
                        this.availableKbsCount++;
                    }
                });
                if (updateRequired) {
                    this.onChange();
                }
            }
        );
    }

    ngOnInit() {
        if (this.serviceListings.type !== 'search') {
            this.onChange();
        }
    }


    onChange(apply?:boolean) {
        apply !== false ? apply = true : apply = false;
        const enabledKbs: string[] = [];
        _.forEach(this.societies, (s: any) => {
            _.forEach(s.kbs, (k: any) => {
                if (k.enabled) {
                    enabledKbs.push(s.society + '-' + k.kb);
                }
            });
        });
        this.serviceListings.filters = _.extend(this.serviceListings.filters, {
            enabledKbs: enabledKbs
        });

        let isDirty = false;
        if (enabledKbs.length !== this.availableKbsCount) {
            this.serviceListings.filters = _.extend(this.serviceListings.filters, {
                enabledKbs: enabledKbs
            });
            isDirty = true;
        } else if ('enabledKbs' in this.serviceListings.filters) {
            delete this.serviceListings.filters['enabledKbs'];
        }
        setTimeout(() => {
            this.isFiltersDirty.emit({ filterKey: 'enabledKbs', isDirty: isDirty });
            if (apply) {
                this.serviceListings.dataStoreChanged();
            }
        }, 10);
    }

    reset() {

    }
}
