import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToasterModule } from 'angular2-toaster';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { SharedModule } from './shared';
import { PagesComponent } from './pages/pages.component';
import { LoginComponent } from '@pages/login/login.component';
import { LoginTokenizerComponent } from '@pages/login/login-tokenizer.component';
import { TagsManagementModule } from '@pages/tags-management';

import { AppRouting } from './app.routing';
import { AppSettings } from './app.settings';
import { AppComponent } from './app.component';

import { PreventUnsavedChangesGuard } from '@guards/prevent-unsaved-changes.guard';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material';
import { PortalModule } from '@angular/cdk/portal';
import { MatomoModule } from 'ngx-matomo';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new MultiTranslateHttpLoader(http, [
        {prefix: './assets/translations/', suffix: '.json'},
        {prefix: './assets/custom/translations/', suffix: '.json'},
    ]);
}

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ToasterModule.forRoot(),
        SharedModule,
        AppRouting,
        PortalModule,
        MatomoModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        TagsManagementModule
    ],
    declarations: [
        AppComponent,
        LoginComponent,
        LoginTokenizerComponent,
        PagesComponent
    ],
    providers: [
        AppSettings,
        PreventUnsavedChangesGuard,
        { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { minWidth: '33%' } },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
