import * as _ from 'lodash';
import { OnDestroy, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs/Subject';

import { ServicePages, ServiceMembers, ServiceWorklabs, ServiceAliases, ServiceDocuments, ServiceTags, ServiceTextAnalyser } from '@services';
import { Order, Item, Tag } from '@models';

export abstract class MultiAttributesComponent implements OnDestroy {

    @Input() display = 'ul';

    @Input() showTitle = true;

    protected _editionMode: boolean;
    @Input()
    set editionMode(value: boolean) {
        this._editionMode = value;
        this.limit = (value) ? 50 : 5;
        this.handleEditionMode(value);
    }
    get editionMode(): boolean {
        return this._editionMode;
    }

    protected _order: Order;
    @Input()
    set order(value: Order) {
        this._order = value;

        const orderField = _.get(value, 'field');
        let orderDirection = _.get(value, 'direction');
        if (orderField !== this.field) {
            orderDirection = 'asc';
        }
        this.handleOrder(orderDirection);
    }
    get order(): Order {
        return this._order;
    }

    public ctrl = new FormControl();
    public field: string;
    public limit = 5;
    public canEdit = false;
    public loading = false;
    public isCreation = false;
    public actionInProgress = false;

    protected _destroyed$ = new Subject();

    constructor(
        protected serviceTypeItem: ServicePages | ServiceMembers | ServiceWorklabs | ServiceAliases | ServiceDocuments | ServiceTags,
        protected serviceTextAnalyser: ServiceTextAnalyser
    ) { }

    protected handleOrder(orderDirection: 'asc' | 'desc') {}

    protected handleEditionMode(value: boolean) {}

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }

    trackIds(index: number, o: Item | Tag) {
        return o ? o.id : index;
    }

    trackUrls(index: number, o: Item) {
        return o ? o.url : index;
    }
}
