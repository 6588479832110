import * as _ from 'lodash';
import { Component, ViewEncapsulation, ViewChild, ElementRef, HostListener } from '@angular/core';
import { OnDestroy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { Order, Role, Page, Member, Worklab, Item, FabButtonsConfiguration } from '@models';
import { ServiceListings } from '@services/listings.service';
import { ServiceDatastore, ServiceNavigationHistory, ServiceTabs, ServiceUrls, ServiceItems, ServiceTopics, ServiceMatomo, ServiceMap, ServiceToaster } from '@services';
import { RecommendationsService } from '@services/recommendations.service';
import { SearchService } from '@services/search.service';
import { combineLatest, Subject } from 'rxjs';
import { mergeMap, takeUntil, first } from 'rxjs/operators';
import { ServiceSecurity } from '@services/security.service';
import { ServicePreferences } from '@services/preferences.service';
import { AppSettings, Settings } from '@src/app/app.settings';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { DialogPreferencesDetailComponent, DialogPreferencesListingsComponent } from '../preferences';

@Component({
    selector: 'app-items-list',
    templateUrl: './items-list.component.html',
    styleUrls: ['./items-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ItemsListComponent implements OnDestroy {

    public settings: Settings;
    public loading = true;
    public listViewType: string;
    public listOrder: Order;
    public allItems;
    public listingOptions: any = {};
    public isViewTypeDetail: boolean;
    public isViewTypeMapKeywords = false;
    public isSummarizerEnabled = false;

    public listViewTypeTilesLoaded = false;
    public listViewTypeTableLoaded = false;
    public listViewTypeMapLoaded = false;
    public listViewTypeListLoaded = false;

    private _destroyed$ = new Subject();
    private _dialogRef: MatDialogRef<any>;

    @HostListener('document:keydown.control.shift.q', ['$event']) showSettings(event: KeyboardEvent) {
        const isUserAnonymous = this.serviceSecurity.isCurrentUserAnonymous();
        if (!isUserAnonymous) {
            event.stopPropagation();
            if (!this._dialogRef) {
                const dialogConfig = new MatDialogConfig();
                const listView = this.serviceListings.listingView;
                dialogConfig.data = listView;
                this._dialogRef = this._dialog.open(DialogPreferencesListingsComponent, dialogConfig);
                this._dialogRef.afterClosed().subscribe(
                    () => {
                        delete this._dialogRef;
                    }
                );
            }
        } else {
            this.serviceToaster.error(this.ngxTranslateService.instant('PREFERENCES.LINKED_BLOCKS.ONLY_USERS'));
        }
    }

    constructor(
        private appSettings: AppSettings,
        private activeRoute: ActivatedRoute,
        private serviceTabs: ServiceTabs,
        private serviceItems: ServiceItems,
        private serviceListings: ServiceListings,
        private serviceDatastore: ServiceDatastore,
        private serviceNavigationHistory: ServiceNavigationHistory,
        private topicsService: ServiceTopics,
        private recommendationsService: RecommendationsService,
        private serviceSecurity: ServiceSecurity,
        private serviceToaster: ServiceToaster,
        private ngxTranslateService: TranslateService,
        private servicePreferences: ServicePreferences,
        private searchService: SearchService,
        private serviceMap: ServiceMap,
        private serviceMatomo: ServiceMatomo,
        private _dialog: MatDialog
    ) {
        this.settings = this.appSettings.settings;
        this.isSummarizerEnabled = (IS_SUMMARIZER_ENABLED === true) ? true : false;

        this.serviceMap.filterViewTypeKeywordsChanged.pipe(
            takeUntil(this._destroyed$)
        ).subscribe(
            (isViewTypeMapKeywords) => {
                this.isViewTypeMapKeywords = isViewTypeMapKeywords;
            }
        );

        this.serviceListings.listingViewObservable.pipe(
            takeUntil(this._destroyed$)
        ).subscribe(
            (listViewType) => {
                this.listViewType = listViewType;
                if (this.listViewType === 'TILES') {
                    this.listViewTypeTilesLoaded = true;
                } else if (this.listViewType === 'TABLE') {
                    this.listViewTypeTableLoaded = true;
                } else if (this.listViewType === 'MAP') {
                    this.listViewTypeMapLoaded = true;
                }  else if (this.listViewType === 'SUMMARY') {
                    this.listViewTypeListLoaded = true;
                }
            }
        );

        this.serviceListings.listingOrderObservable.pipe(
            takeUntil(this._destroyed$)
        ).subscribe(
            (listOrder) => {
                this.listOrder = listOrder;
            }
        );

        this.loading = true;
        this.settings.loadingInsideSpinner = true;
        const activeRouteParams = this.activeRoute.params;
        const activeRouteQueryParams = this.activeRoute.queryParams;
        combineLatest([activeRouteParams, activeRouteQueryParams])
            .pipe(
                mergeMap((results) => {
                    const params: Params = results[0];
                    const queryParams: Params = results[1];
                    const isHomepage = this.activeRoute.routeConfig.path === '';
                    this.loading = true;
                    this.settings.loadingInsideSpinner = true;

                    const previousType = this.serviceListings.type;
                    this.serviceListings.setDefaultSettings(params, isHomepage);

                    const type = this.serviceListings.type;
                    const listGroup = this.serviceListings.getKey();

                    let listingView = 'TILES';
                    if (type === 'detail') {
                        listingView = this.servicePreferences.getPreference('ListingViewOnItem.' +  listGroup);
                        if (!listingView) {
                            listingView = this.servicePreferences.getPreference('defaultListingViewOnItem');
                        }
                    } else {
                        listingView = this.servicePreferences.getPreference('ListingView.' +  listGroup);
                        if (!listingView) {
                            listingView = this.servicePreferences.getPreference('defaultListingView');
                        }
                    }

                    // const wasViewTypeDetail = (previousType === 'detail');
                    const isViewTypeDetail = (type === 'detail');
                    // const listingView = this.serviceListings.listingView;
                    // this.serviceListings.listingView = (isViewTypeDetail === true && isViewTypeDetail !== wasViewTypeDetail)
                    //     ? listingView
                    //     : this.listViewType || listingView;
                    this.serviceListings.listingView = listingView;
                    this.isViewTypeDetail = isViewTypeDetail;

                    // Saved filters
                    const keepFilters = this.servicePreferences.getPreference('keepFilters');
                    if (keepFilters === true) {
                        const userFilters = this.servicePreferences.getPreference('filters');
                        const listUserFilters = _.get(userFilters, this.serviceListings.getKey()) || _.get(userFilters, 'any') || {};
                        this.serviceListings.filters = _.extend(this.serviceListings.filters, listUserFilters);

                        const userOrderBy = this.servicePreferences.getPreference('orderBy');
                        const listUserOrderBy = _.get(userOrderBy, this.serviceListings.getKey()) || _.get(userOrderBy, 'any') || {};
                        this.serviceListings.listingOrder = _.extend(this.serviceListings.listingOrder, listUserOrderBy);
                    }

                    if (this.serviceListings.type === 'tags') {
                        this.serviceListings.filters = _.extend(this.serviceListings.filters, {
                            tags: _.map(_.split(_.get(params, 'tags'), ','), function (id) { return '#' + id.replace('-', ':'); })
                        });
                    }

                    if (_.get(params, 'is-expert') === 'true') {
                        this.serviceListings.filters = _.extend(this.serviceListings.filters, {
                            'is-expert': true
                        });
                    }

                    if (_.has(params, 'nb-experts')) {
                        this.serviceListings.filters = _.extend(this.serviceListings.filters, {
                            'nb-experts': _.get(params, 'nb-experts')
                        });
                    }

                    if (_.has(params, 'last-modification')) {
                        this.serviceListings.filters = _.extend(this.serviceListings.filters, {
                            'last-modification-date': {
                                start: _.get(params, 'last-modification') + '-01',
                                end: _.get(params, 'last-modification') + '-31'
                            }
                        });
                    }

                    if (_.has(params, 'last-modification-start') || _.has(params, 'last-modification-end')) {
                        this.serviceListings.filters = _.extend(this.serviceListings.filters, {
                            'last-modification-date': {
                                start: _.get(params, 'last-modification-start'),
                                end: _.get(params, 'last-modification-end')
                            }
                        });
                    }

                    if (_.has(params, 'modifier')) {
                        this.serviceListings.filters = _.extend(this.serviceListings.filters, {
                            'modifiers': [ServiceUrls.convertIDtoOID(_.get(params, 'modifier'))]
                        });
                    }

                    if (_.has(params, 'creation')) {
                        this.serviceListings.filters = _.extend(this.serviceListings.filters, {
                            'first-creation-date': {
                                start: _.get(params, 'creation') + '-01',
                                end: _.get(params, 'creation') + '-31'
                            }
                        });
                    }

                    if (_.has(params, 'creation-start') || _.has(params, 'creation-end')) {
                        this.serviceListings.filters = _.extend(this.serviceListings.filters, {
                            'first-creation-date': {
                                start: _.get(params, 'creation-start'),
                                end: _.get(params, 'creation-end')
                            }
                        });
                    }

                    if (_.has(params, 'creator')) {
                        this.serviceListings.filters = _.extend(this.serviceListings.filters, {
                            'creators': [ServiceUrls.convertIDtoOID(_.get(params, 'creator'))]
                        });
                    }

                    if (_.has(params, 'itemIds')) {
                        const filters = {};
                        filters['groups'] = this.serviceListings.filters.groups;
                        filters['itemIds'] = _.split(_.get(params, 'itemIds'), ',');
                        this.serviceListings.filters = filters;
                    } else if (_.has(this.serviceListings.filters, 'itemIds')) {
                        delete this.serviceListings.filters['itemIds'];
                    }

                    if (_.has(params, 'group')) {
                        const groupWithS = _.capitalize(_.get(params, 'group'));
                        if (groupWithS.slice(-1) === 's') {
                            this.serviceListings.filters = _.extend(this.serviceListings.filters, {
                                'groups': [groupWithS.substring(0, groupWithS.length - 1)]
                            });
                        }
                    }

                    this.listingOptions.showScore = false;
                    let result;
                    if (this.serviceListings.type === 'recommendations' && this.serviceListings.group) {
                        this.listingOptions.showScore = true;
                        result = this.recommendationsService.getRecommendedItems(this.serviceListings.group);
                    } else if (this.serviceListings.type === 'detail') {
                        result = this.serviceItems.itemObservable;
                    } else if (this.serviceListings.type === 'my-comments' && this.serviceListings.group) {
                        result = this.topicsService.getMyComments(this.serviceListings.group);
                    } else if (this.serviceListings.type === 'search' && _.get(queryParams, 'q')) {
                        this.listingOptions.showScore = true;
                        let kbs = _.get(queryParams, 'kbs', []);
                        if (typeof kbs === 'string' || kbs instanceof String) {
                            kbs = [kbs];
                        }
                        result = this.searchService.getItemsByWords(_.get(queryParams, 'q'), kbs);
                    } else if (this.serviceListings.type === 'orphans') {
                        result = this.searchService.getOrphans<Page>(this.serviceListings.group);
                    } else if (this.serviceListings.type === 'history') {
                        result = this.serviceNavigationHistory.getHistoryItems();
                    } else {
                        result = this.serviceDatastore.dataStoreObservable;
                    }

                    // const listingKey = this.serviceListings.getKey();
                    // const userFilters = _.get(this, 'appSettings.settings.filters');
                    // const listUserFilters = _.get(userFilters, listingKey) || _.get(userFilters, 'any') || this.serviceListings.getDefaultFilters();
                    // const keepFilters = _.get(this, 'appSettings.settings.keepFilters');
                    // if (keepFilters === true) {
                    //     userFilters[listingKey] = listUserFilters;
                    //     this.servicePreferences.savePreference({ 'filters': userFilters }).pipe(
                    //         first(),
                    //         takeUntil(this._destroyed$)
                    //     ).subscribe(
                    //         () => {
                    //         }
                    //     );
                    // }

                    this.configureFabButtons();

                    return result;
                }),
                takeUntil(this._destroyed$)
            ).subscribe(
                (listingDataStore) => {
                    if (listingDataStore) {
                        let items: Item[] = [];
                        if (this.serviceListings.type === 'detail') {
                            const baseItems: Array<Item> = Array.isArray(listingDataStore) ? listingDataStore : [listingDataStore];

                            // In view Detail, use all related items (creators, experts, ...) as dataset
                            items = this.serviceDatastore.getLinksByItems(baseItems, true);
                        } else {
                            items = this.serviceDatastore.getLinksByItems(<Item[]>listingDataStore);
                        }

                        this.serviceListings.setBaseData(items);

                        if (this.serviceListings.type === 'search' && this.searchService.lastSearch) {
                            this.serviceMatomo.trackSiteSearch(this.searchService.lastSearch, _.get(listingDataStore, 'length', 0));
                        }
                    }

                    setTimeout(() => {
                        this.loading = false;
                        this.settings.loadingInsideSpinner = false;
                    }, 300);
                }
            );
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();

        this.serviceTabs.tabChanged(new FabButtonsConfiguration());
    }

    // Broadcast event to configure FabButtons
    private configureFabButtons() {
        const group = _.capitalize(_.get(this.activeRoute.snapshot.params, 'group', 'Page'));
        const serviceItem = this.serviceItems.getItemService(group);

        const fabButtons = new FabButtonsConfiguration();
        if (group && serviceItem) {
            fabButtons.group = group;
            if (this.listViewType === 'TILES' || this.listViewType === 'TABLE') {
                fabButtons.downloadItems = this.serviceSecurity.hasMinimumRole(Role.ROLE_KB_ADMIN);
            }
            fabButtons.createItem = serviceItem.userCanCreateItem();
        } else {
            fabButtons.downloadItems = this.serviceSecurity.hasMinimumRole(Role.ROLE_KB_ADMIN);
        }
        this.serviceTabs.tabChanged(fabButtons);
    }
}
