import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient, HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';

import { ServiceErrors } from '@services/errors.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ServiceUrls } from '@src/app/services';

@Component({
    selector: 'dialog-import-users',
    styleUrls: ['./dialog-import-users.scss'],
    templateUrl: './dialog-import-users.html',
})
export class DialogImportUsersComponent implements OnInit, OnDestroy {
    public onLoading = new EventEmitter();
    public onUploadDone = new EventEmitter();

    public loading = false;
    public done = false;
    public exportedData: any = [];
    public text: string;
    public format = 'xls';
    public receivedData: SafeHtml;
    public error: string;
    public uploadForm: FormGroup;
    public file: File;
    public sendEmail = false;

    private _destroyed$ = new Subject();

    @ViewChild('fileInput', {static: false})
    fileInput: ElementRef;

    constructor(
        private httpClient: HttpClient,
        private formBuilder: FormBuilder,
        private domSanitizer: DomSanitizer,
        private serviceTranslate: TranslateService,
        private serviceErrors: ServiceErrors,
        private serviceUrls: ServiceUrls
    ) { }

    ngOnInit() {
        this.uploadForm = this.formBuilder.group({
            filename: '',
            userFile: null
        });
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }

    onSelectFile(event) {
        if (event.target.files && event.target.files.length > 0) {
            this.file = event.target.files[0];
            this.uploadForm.get('filename').setValue(this.file.name);
        }
    }

    selectFile(): void {
        this.fileInput.nativeElement.click();
    }

    sendFile() {
        const url = this.serviceUrls.api + 'imports/users';
        const sendEmailToUsers = String(this.sendEmail);

        this.loading = true;
        this.onLoading.emit(true);

        const formData: FormData = new FormData();
        formData.append('sendEmail', sendEmailToUsers);
        formData.append('users', this.file, this.file.name);
        formData.append('language', 'fr');

        const options = { responseType: 'text' as 'text', withCredentials: true, reportProgress: true };
        const req = new HttpRequest('POST', url, formData, options);
        this.httpClient.request(req).pipe(
            takeUntil(this._destroyed$)
        ).subscribe(
            (event) => {
                if (event.type === HttpEventType.DownloadProgress) {
                    this.receivedData = this.domSanitizer.bypassSecurityTrustHtml(_.get(event, 'partialText'));
                } else if (event instanceof HttpResponse) {
                    this.loading = false;
                    this.done = true;
                    this.onLoading.emit(false);
                    this.onUploadDone.emit(true);
                }
            },
            (error) => {
                this.loading = false;
                if (error.error) {
                    const err = JSON.parse(error.error);
                    const message = err.message;
                    const translatedMessage = this.serviceTranslate.instant('IMPORT_USERS.ERRORS.' + message);
                    this.error = (translatedMessage !== 'IMPORT_USERS.ERRORS.' + message) ? translatedMessage : message;
                }
                this.serviceErrors.handleError('IMPORT_USERS', error);
            }
        );
    }
}
