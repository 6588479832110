import * as _ from 'lodash';
import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { finalize, first, takeUntil, shareReplay, retry, retryWhen, take , delay } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';

import { AppSettings, Settings } from '@app/app.settings';
import { Item, Member } from '@models';
import { ServiceItems, ServiceSecurity, ServiceErrors, ServiceToaster } from '@services';

@Component({
    selector: 'button-save-item',
    templateUrl: './button-save-item.html',
    styleUrls: ['./button-save-item.scss']
})
export class ButtonSaveItemComponent implements OnDestroy {

    private _item: Item;
    @Input()
    set item(value: Item) {
        const serviceTranslate = this.serviceTranslate;
        this.hasErrors = (_.size(_.keys(value.errors)) > 0);
        if (this.hasErrors) {
            let tooltip = this.serviceTranslate.instant('SAVE_ITEM.BUTTON.TOOLTIP.ERRORS');
            _.forEach(value.errors, function (error) {
                if (error.label) {
                    tooltip += '\n - ' + serviceTranslate.instant(error.label, error);
                }
            });
            this.tooltip = tooltip;
        } else if (!value.isDirty) {
            this.tooltip = this.serviceTranslate.instant('SAVE_ITEM.BUTTON.TOOLTIP.NO_CHANGES');
        } else {
            this.tooltip = this.serviceTranslate.instant('SAVE_ITEM.BUTTON.TOOLTIP.SAVE');
        }

        this.disabled = (this.hasErrors || value.isDirty !== true);

        this._item = value;
    }
    get item(): Item {
        return this._item;
    }

    public settings: Settings;
    public disabled: boolean;
    public tooltip: string;
    public hasErrors: boolean;

    private _destroyed$ = new Subject();

    constructor(
        private router: Router,
        private appSettings: AppSettings,
        private serviceToaster: ServiceToaster,
        private serviceItems: ServiceItems,
        private serviceErrors: ServiceErrors,
        private serviceTranslate: TranslateService,
        private serviceSecurity: ServiceSecurity
    ) {
        this.tooltip = this.serviceTranslate.instant('SAVE_ITEM.BUTTON.TOOLTIP.NO_CHANGES');
        this.settings = this.appSettings.settings;
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }

    saveItem() {
        if (this.disabled) {
            return;
        }
        this.serviceToaster.clearAll();
        this.settings.savingInsideSpinner = true;

        const item = this.item;
        const isCreation = _.isUndefined(item.id);
        const isCurrentUser = this.serviceSecurity.isCurrentUser(item);
        const password = (<Member>item).password;

        if (isCurrentUser && !_.isEmpty(password)) {
            this.serviceItems.changePassword(password).pipe(
                finalize(() => {
                    this.settings.savingInsideSpinner = false;
                }),
                first(),
                takeUntil(this._destroyed$)
            ).subscribe(
                () => {
                    this.serviceToaster.success('SAVE_ITEM.PASSWORD');
                },
                () => {
                    this.serviceToaster.error('SAVE_ITEM.PASSWORD.ERROR');
                }
            );
        } else {
            this.serviceItems.item.isSaving = true;
            this.serviceItems.saveItem(item).pipe(
                finalize(() => {
                    this.settings.savingInsideSpinner = false;
                    this.serviceItems.item.isSaving = false;
                    this.serviceItems.item.witness.isSaving = false;
                }),
                retryWhen(errors => errors.pipe(delay(1000), take(10))),
                takeUntil(this._destroyed$)
            ).subscribe(
                (updatedItem: Item) => {
                    // Broadcast item changes
                    const existingItem = this.serviceItems.item;
                    existingItem.isDirty = false;

                    const newItem = _.extend(existingItem, updatedItem);
                    const params = { title: newItem.title };

                    delete newItem.witness;
                    newItem.witness = _.cloneDeep(newItem);
                    newItem.editionMode = true;
                    this.serviceItems.item = newItem;

                    // Broadcast current user changes changes
                    if (isCurrentUser) {
                        this.serviceSecurity.user.title = newItem.title;
                        this.serviceSecurity.user.mid = newItem.mid;
                        this.serviceSecurity.user.firstName = (<Member>newItem).firstname;
                        this.serviceSecurity.user.lastName = (<Member>newItem).lastname;
                    }

                    // Clean eventual "copy item" cookies
                    this.serviceSecurity.removeCookie('duplicateItem');
                    this.serviceSecurity.removeCookie('worklabFromPage');
                    this.serviceSecurity.removeCookie('newTitleFromTextSelection');

                    this.serviceSecurity.removeCookie('sourceGroup');
                    this.serviceSecurity.removeCookie('sourceUid');

                    // Tooltip
                    const group = _.toUpper(newItem.group);
                    if (isCreation) {
                        const label = (group === 'MEMBER') ? ((updatedItem['newMember'] === true) ? 'MEMBER_NEW_IN_IDP' : 'MEMBER_EXISTING_IN_IDP') : group;

                        this.serviceToaster.success('SAVE_ITEM.CREATE.' + label, params);
                        this.serviceSecurity.setCookie('open-edition-mode', newItem.id);
                        this.router.navigate([newItem.url]);
                    } else {
                        this.serviceToaster.success('SAVE_ITEM.UPDATE.' + group, params);
                    }
                },
                (error: HttpErrorResponse) => {
                    if (isCreation) {
                        this.serviceErrors.handleError('SAVE_ITEM.CREATE', error);
                    } else {
                        this.serviceErrors.handleError('SAVE_ITEM.UPDATE', error);
                    }
                }
            );
        }
    }
}
