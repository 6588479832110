import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
    selector: 'dialog-worklab-to-page-confirmation',
    styleUrls: ['./dialog-worklab-to-page-confirmation.scss'],
    templateUrl: './dialog-worklab-to-page-confirmation.html',
})
export class DialogWorklabToPageConfirmationComponent {

    constructor(
        public dialogRef: MatDialogRef<DialogWorklabToPageConfirmationComponent>
    ) { }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
