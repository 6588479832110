import { Directive, Renderer2, Input, Output, ElementRef, HostListener, EventEmitter } from '@angular/core';

@Directive({
    selector: '[changePasswordType]'
})
export class ViewPasswordDirective {
    @Input()  changePasswordType: string;;
    @Output() passwordTypeChanged = new EventEmitter();

    @HostListener('click', ['$event'])
    onClick() {
        let currentPasswordType = this.changePasswordType;
        if(currentPasswordType === 'password'){
            this.passwordTypeChanged.emit('text');
        }
        else {
            this.passwordTypeChanged.emit('password');
        }
    }

    constructor(private renderer: Renderer2, private el: ElementRef) {
        this.renderer.setStyle(this.el.nativeElement, 'cursor', 'help');
    }
}
