import * as _ from 'lodash';
import { Component, Input } from '@angular/core';

import { Role } from '@models';
import { Item } from '@models/item.model';
import { ServiceSecurity, ServiceItems } from '@services';

@Component({
    selector: 'is-protected',
    templateUrl: './is-protected.component.html',
    styleUrls: ['./is-protected.component.scss']
})
export class IsProtectedComponent {

    private _item: any;
    @Input()
    set item(value: Item) {
        this._item = value;
        this._setAttributes();
    }
    get item(): Item {
        return this._item;
    }

    private _editionMode: boolean;
    @Input()
    set editionMode(value: boolean) {
        this._editionMode = value;
        this._setAttributes();
    }
    get editionMode(): boolean {
        return this._editionMode;
    }

    public tooltip: string;
    public isDisabled = false;
    public isGranted = false;
    public isItemProtected = false;

    private isAdmin = false;

    constructor(
        private serviceItems: ServiceItems,
        private serviceSecurity: ServiceSecurity
    ) {
        this.isAdmin = this.serviceSecurity.isAdmin();
    }

    toogleIsProtected() {
        if (this.isGranted === true) {
            this._item.islocked = !this._item.islocked;
            this.serviceItems.item = this._item;
            this._setAttributes();
        }
    }

    private _setAttributes() {
        this.isItemProtected = _.get(this, '_item.islocked');
        if (this._editionMode === true) {
            this.isGranted = this.isAdmin /* || this.serviceSecurity.hasMinimumRole(Role.ROLE_CONTRIBUTOR) */;

            if (this.isGranted === true && !this.isAdmin) {
                this.isDisabled = true;
                this.tooltip = 'ITEM.PROTECTION.TOOLTIP.DISABLED';
            } else if (this.isGranted === true && this.isItemProtected) {
                this.isDisabled = false;
                this.tooltip = 'ITEM.PROTECTION.TOOLTIP.UNLOCK';
            } else if (this.isGranted === true && !this.isItemProtected) {
                this.tooltip = 'ITEM.PROTECTION.TOOLTIP.LOCK';
            }
        } else {
            this.isGranted = false;
            if (this.isItemProtected) {
                this.isDisabled = false;
                this.tooltip = 'ITEM.PROTECTION.TOOLTIP.PROTECTED';
            } else if (!this.isItemProtected) {
                this.tooltip = 'ITEM.PROTECTION.TOOLTIP.NOT_PROTECTED';
            }
        }
    }
}
