import { Component, Input, OnDestroy, Attribute } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DialogExportItemsComponent } from './dialog-export-items/dialog-export-items.component';
import { Item } from '@src/app/models';

@Component({
    selector: 'button-export-items',
    templateUrl: './button-export-items.html',
    styleUrls: ['./button-export-items.scss']
})
export class ButtonExportItemsComponent implements OnDestroy {

    @Input() items: Array<Item>;
    @Input() disabled = false;

    public loading = false;

    public get propagateClick(): 'true' | 'false' {
        return this._propagateClick;
    }
    public set propagateClick(value: 'true' | 'false') {
        this._propagateClick = value;
    }

    private _destroyed$ = new Subject();

    constructor(
        private _dialog: MatDialog,
        @Attribute('propagateClick') private _propagateClick: 'true' | 'false' = 'true'
    ) {
    }

    ngOnDestroy() {
        this._dialog.closeAll();
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }

    openDialog() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = this.items;

        const dialogRef = this._dialog.open(DialogExportItemsComponent, dialogConfig);
        dialogRef.componentInstance.onLoading.pipe(
            takeUntil(this._destroyed$)
        ).subscribe(
            (loading: boolean) => {
                this.loading = loading;
                if (loading === false) {
                    dialogRef.close();
                }
            }
        );
    }
}
