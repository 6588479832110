import * as _ from 'lodash';

export class ExportItemsRequest {
    Page: {
        ids: string[]
    };
    Member: {
        ids: string[]
    };
    Worklab: {
        ids: string[]
    };

    getNumberOfPages() {
        return _.size(_.get(this, 'Page.ids', []));
    }

    getNumberOfMember() {
        return _.size(_.get(this, 'Member.ids', []));
    }

    getNumberOfWorklabs() {
        return _.size(_.get(this, 'Worklab.ids', []));
    }
}
