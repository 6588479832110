import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared';
import { TagsManagementComponent } from './tags-management.component';
import { ButtonSaveTagsComponent } from './button-save-tags/button-save-tags.component';
import { RouterModule } from '@angular/router';

export const routesRegister = [
    { path: '', component: TagsManagementComponent, pathMatch: 'full' }
  ];

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        RouterModule.forChild(routesRegister)
    ],
    exports: [
        ButtonSaveTagsComponent,
        TagsManagementComponent
    ],
    declarations: [
        ButtonSaveTagsComponent,
        TagsManagementComponent
    ]
})
export class TagsManagementModule { }
