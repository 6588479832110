import { Component, OnInit } from '@angular/core';
import { ServiceListings, ServiceMap } from '@services';

@Component({
    selector: 'filters-by-cluster',
    templateUrl: './filters-by-cluster.component.html',
    styleUrls: ['./filters-by-cluster.component.scss']
})
export class FiltersByClusterComponent implements OnInit {

    public isClusterEnabled: boolean = false;

    constructor(
        private serviceListings: ServiceListings,
        private serviceMap: ServiceMap
    ) {

    }

    ngOnInit() {
        
    }

    onChange() {
        this.serviceMap.emitFilterClusterChanged(this.isClusterEnabled);
    }

    reset() {
        this.isClusterEnabled = false;
    }
}
