import * as _ from 'lodash';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { ServicePreferences } from '@services';

@Pipe({
    name: 'i2KnFromNow'
})
export class I2KnFromNow implements PipeTransform {

    constructor(
        private servicePreferences: ServicePreferences
    ) {
    }

    transform(value: any, args?: any): any {
        const language = this.servicePreferences.getPreference('language');
        const timezone = this.servicePreferences.getPreference('timezone');

        return _.upperFirst(moment(moment.tz(value, timezone)).locale(language).fromNow());
    }
}
