import * as _ from 'lodash';
import { Component, OnInit, ViewEncapsulation, ViewChild, OnDestroy } from '@angular/core';
import { AppSettings, Settings } from '@app/app.settings';

import { MenuService } from '../menu/menu.service';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { ServiceSecurity } from '@services/security.service';
import { ServiceKb } from '@services/kbs.service';
import { ServiceListings } from '@services/listings.service';
import { Router } from '@angular/router';
import { User } from '@models/user.model';
import { first, takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CookieNavigationHistory, FabButtonsConfiguration, Item, Kb } from '@models';
import { ActivatedRoute, Params } from '@angular/router';
import { combineLatest, Subject, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ServiceNavigationHistory } from '@src/app/services/navigation-history.service';
import { ServiceTabs } from '@src/app/services/tabs.service';
@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [MenuService]
})
export class SidenavComponent implements OnInit, OnDestroy {
    @ViewChild('sidenavPS', {static: false}) sidenavPS: PerfectScrollbarComponent;
    public userImage = '../assets/img/users/user.jpg';
    public menuItems: Array<any>;
    public settings: Settings;
    private currentUser: User;
    public isUserAnonymous: boolean;
    public currentKb: Kb;
    public filtersAreDirty: boolean;
    public selectedTabIndex = 0;
    public showFilters: boolean;
    public listViewType: string;

    private _destroyed$ = new Subject();

    constructor(
        private http: HttpClient,
        public appSettings: AppSettings,
        public menuService: MenuService,
        private router: Router,
        private serviceSecurity: ServiceSecurity,
        private serviceKb: ServiceKb,
        private serviceListings: ServiceListings,
        private activeRoute: ActivatedRoute,
    ) {
        this.settings = this.appSettings.settings;

        this.serviceSecurity.currentUserObservable.pipe(
            takeUntil(this._destroyed$)
        ).subscribe(
            (user) => {
                this.currentUser = user;
                this.isUserAnonymous = (!this.currentUser || _.get(this.currentUser, 'role', 'ROLE_PUBLIC') === 'ROLE_PUBLIC');
            }
        );

        this.serviceKb.kbObservable.pipe(
            takeUntil(this._destroyed$)
        ).subscribe(
            (kb) => {
                this.currentKb = kb;
            }
        );

        
        this.serviceListings.showFiltersObservable
        .pipe(
            takeUntil(this._destroyed$)
        ).subscribe((res) => {
            this.showFilters =  res ;

        });

        this.serviceListings.listingViewObservable.pipe(
            takeUntil(this._destroyed$)
        ).subscribe(
            (listViewType) => {
                this.showFilters = true;
                const isHomepage = this.activeRoute.routeConfig.path === '';
                this.listViewType = listViewType;  
            }
        );

        const activeRouteParams = this.activeRoute.params;
        const activeRouteQueryParams = this.activeRoute.queryParams;
        combineLatest([activeRouteParams, activeRouteQueryParams])
            .pipe(
                mergeMap((_) => {   
                    const path = window.location.pathname;

                    switch (path) {
                        case '/search':
                            this.selectedTabIndex = 1;
                       
                        default:  
                    }                 
                    this.showFilters = this.showFiltersOnRouteChange();
                    return of();                
            }),
            takeUntil(this._destroyed$)
        ).subscribe();

        this.serviceListings.listingFiltersObservable.pipe(
            takeUntil(this._destroyed$)
        ).subscribe((filters: any) => {
            const defaultFilters = this.serviceListings.getDefaultFilters();
            
            setTimeout(() => {
                this.filtersAreDirty = JSON.stringify(defaultFilters) !== JSON.stringify(filters);
            });
        });
       
    }

    private showFiltersOnRouteChange(): boolean {
       const path = window.location.pathname;

       switch (path) {
        case '/':
        case '/dashboards':
        case '/items/member/add':
        case '/items/worklab/add':
        case '/items/page/add':
            return false;

        case '/items/member':
        case '/items/worklab':
        case '/items/page':
            return true;

        case '/items/member/favorites':
        case '/items/member/recommendations':
        case '/items/member/my-comments':
            return true;
       
        default: {
           const isItemsMembers = window.location.pathname.startsWith('/items/member/');

           if (isItemsMembers) {
            return false;
           }
           break;
        }
       }

       return true;
    }

    ngOnInit() {
        this.menuItems = this.menuService.getVerticalMenuItems();    
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }

    closeSubMenus() {
        const menu = document.querySelector('.sidenav-menu-outer');
        if (menu) {
            for (let i = 0; i < menu.children[0].children.length; i++) {
                const child = menu.children[0].children[i];
                if (child) {
                    if (child.children[0].classList.contains('expanded')) {
                        child.children[0].classList.remove('expanded');
                        child.children[1].classList.remove('show');
                    }
                }
            }
        }
    }

    public updatePS() {
        this.sidenavPS.directiveRef.update();
    }

    login() {
        this.router.navigate(['/login']);
    }
}
