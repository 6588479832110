import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, first, finalize } from 'rxjs/operators';
import { CommonService } from './common.service';

@Injectable({ providedIn: 'root' })
export class ServiceSocieties extends CommonService {

    private _licences: any;

    constructor(
        protected injector: Injector
    ) {
        super(injector);
    }

    getLicenceCount(refresh?:boolean): Observable<any> {
        //if (refresh === true || !this._licences) {
            const url = this.urlApi.replace(this.currentKbSlug + '/', 'societies/licences')
            return this.http.get(url).pipe(
                first(),
                map((data: any) => {
                    this._licences = data;
                    return this._licences;
                })
            );
        //} else {
        //    return of(this._licences);
        //}
    }
}
