import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { takeUntil, finalize, first } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { KeyValue } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

import { Notification, NotificationTypes, Society, Kb } from '@models';
import { ServiceNotifications, ServiceSecurity, ServicePreferences, ServiceErrors, ServiceToaster, ServiceUrls } from '@services';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {
    @Output() elementClicked = new EventEmitter();

    public loading = false;
    public removing = false;
    public saving = false;
    public hasFilters = false;
    public matTooltipHasFilters: string;
    public isFilterPanelExpended = false;
    public isAdmin = false;

    public allNotifications: Notification[] = [];
    public notifications: Notification[] = [];

    public shownTypes: string[];
    private defaultShownTypes: string[];
    public shownKbs: string[];
    private defaultShownKbs: string[];

    public notificationKbs;
    public allSocieties: any[] = [];
    private nbKbs = 1;
    public allNotificationTypes = NotificationTypes;

    private _destroyed$ = new Subject();

    constructor(
        private serviceUrls: ServiceUrls,
        private serviceErrors: ServiceErrors,
        private serviceToaster: ServiceToaster,
        private serviceSecurity: ServiceSecurity,
        private serviceNotifications: ServiceNotifications,
        private servicePreferences: ServicePreferences,
        private serviceTranslate: TranslateService
    ) {
        this.isAdmin = this.serviceSecurity.isAdmin();
    }

    ngOnInit() {
        const notificationsFilters = this.servicePreferences.getPreference('nf') || {};
        const currentSocietySlug = this.serviceUrls.societySlug;
        const currentKbSlug = this.serviceUrls.kbSlug;

        this.defaultShownTypes = [];
        this.shownTypes = notificationsFilters.types || [...this.defaultShownTypes];

        this.defaultShownKbs = [currentSocietySlug + '-' + currentKbSlug];
        this.shownKbs = notificationsFilters.kbs || [...this.defaultShownKbs];

        const currentUser = this.serviceSecurity.user;
        const getAllSocieties = this.serviceSecurity.getAllAccessibleKbs(currentUser.username);
        const getNotifications = this.serviceNotifications.notificationsObservable;

        this.loading = true;
        combineLatest([getAllSocieties, getNotifications]).pipe(
            takeUntil(this._destroyed$)
        ).subscribe(([allSocieties, notifications]) => {
            this.allSocieties = _.orderBy(allSocieties, ['title'], ['asc']);
            this.nbKbs = _.reduce(allSocieties, function (sum, society) {
                return sum + _.size(society.kbs);
            }, 0);
            this.allNotifications = notifications;
            this.filterNotifications();
            this.loading = false;
        });
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }

    trackSocieties(index: number, o: Society) {
        return o ? o.slug : index;
    }

    trackKbs(index: number, o: any) {
        return o ? o.value.society.slug + o.value.slug : index;
    }

    trackIds(index: number, o: Notification) {
        return o ? o.id : index;
    }

    click() {
        this.elementClicked.emit();
    }

    private filterNotifications() {
        let notifications = this.allNotifications;

        const shownTypes = this.shownTypes || this.defaultShownTypes;
        if (_.size(shownTypes) > 0) {
            notifications = _.filter(notifications, function (o: Notification) {
                return shownTypes.indexOf(o.groupType) !== -1;
            });
        }

        const shownKbs = this.shownKbs || this.defaultShownKbs;
        if (_.size(shownKbs) > 0) {
            notifications = _.filter(notifications, function (o: Notification) {
                return shownKbs.indexOf(o.fullSlug) !== -1;
            });
        }

        this.notifications = notifications;
        this.hasFilters = (_.size(this.shownTypes) !== 0) || (this.nbKbs !== _.size(this.shownKbs) && _.size(this.shownKbs) !== 0);
        this.matTooltipHasFilters = (this.hasFilters) ?
            this.serviceTranslate.instant('NOTIFICATIONS.FILTERS.HAS_FILTERS') :
            this.serviceTranslate.instant('NOTIFICATIONS.FILTERS.HAS_NO_FILTER');
    }

    resetFilters() {
        this.shownTypes = this.defaultShownTypes;
        this.shownKbs = [];
        this.filterNotifications();
    }

    filterByType(types: string[]) {
        this.shownTypes = types;
        this.filterNotifications();
    }

    filterByKbs(kbs: any[]) {
        this.shownKbs = kbs;
        this.filterNotifications();
    }

    sortNotificationTypes = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
        const aLabel = this.serviceTranslate.instant(a.value);
        const bLabel = this.serviceTranslate.instant(b.value);
        return aLabel.localeCompare(bLabel);
    }

    notificationRemoved(notificationId: number) {
        _.remove(this.allNotifications, function (o: Notification) { return o.id === notificationId; });
        this.serviceNotifications.notifications = this.allNotifications;
    }

    removeAllNotifications() {
        this.removing = true;
        this.serviceNotifications.removeAllNotifications().pipe(
            finalize(() => {
                this.removing = false;
            }),
            first(),
            takeUntil(this._destroyed$)
        ).subscribe(
            () => {
                this.allNotifications = [];
                this.serviceNotifications.notifications = this.allNotifications;
                this.filterNotifications();

                this.serviceToaster.success('NOTIFICATIONS.REMOVE_ALL');
            },
            (error: HttpErrorResponse) => {
                this.serviceErrors.handleError('NOTIFICATIONS.REMOVE_ALL', error);
            }
        );
    }

    saveFilters() {
        const filters = {
            kbs: this.shownKbs,
            types: this.shownTypes
        };
        this.saving = true;
        this.servicePreferences.savePreference({ 'nf': filters }).pipe(
            finalize(() => {
                this.saving = false;
            }),
            first(),
            takeUntil(this._destroyed$)
        ).subscribe();
    }
}
