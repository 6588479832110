import { Deserializable } from "./deserializable.model";
import { Society } from "./kb.model";
import { KbRole } from "./kb-role.model";

export class Authorization implements Deserializable {
    society: Society;
    kbRoles: KbRole[];

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}
