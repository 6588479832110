import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PaginationPipe} from './pagination/pagination.pipe';
import { ProfilePicturePipe } from './profilePicture/profilePicture.pipe';
import { ChatPersonSearchPipe } from './search/chat-person-search.pipe';
import { UserSearchPipe } from './search/user-search.pipe';
import { TruncatePipe } from './truncate/truncate.pipe';
import { MailSearchPipe } from './search/mail-search.pipe';
import { ItemSearchPipe } from './search/item-search.pipe';

import { ToBytePipe } from './toByte/toByte.pipe';
import { HasTrueValuePipe } from './hasTrueValue/hasTrueValue.pipe';

import { I2KnDate } from './i2KnDate/i2KnDate.pipe';
import { I2KnHour } from './i2KnHour/i2KnHour.pipe';
import { I2KnDateHour } from './i2KnDateHour/i2KnDateHour.pipe';
import { UserActionsFilter } from './userActionsFilter/userActionsFilter.pipe';
import { I2KnFromNow } from './i2KnFromNow/i2KnFromNow.pipe';
import { I2KnMonth } from './i2KnMonth/i2KnMonth.pipe';
import { I2KnFromToSince } from './i2KnFromToSince/i2KnFromToSince.pipe';
import { HtmlNoSanitizePipe } from './htmlNoSanitize/htmlNoSanitize.pipe';
import { HighlightSearch } from './highlightSearch/highlightSearch.pipe';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        PaginationPipe,
        ProfilePicturePipe,
        ChatPersonSearchPipe,
        UserSearchPipe,
        TruncatePipe,
        MailSearchPipe,
        ItemSearchPipe,
        ToBytePipe,
        HasTrueValuePipe,
        I2KnDate,
        I2KnHour,
        I2KnDateHour,
        I2KnFromNow,
        I2KnMonth,
        UserActionsFilter,
        I2KnFromToSince,
        HtmlNoSanitizePipe,
        HighlightSearch
    ],
    exports: [
        PaginationPipe,
        ProfilePicturePipe,
        ChatPersonSearchPipe,
        UserSearchPipe,
        TruncatePipe,
        MailSearchPipe,
        ItemSearchPipe,
        ToBytePipe,
        HasTrueValuePipe,
        I2KnDate,
        I2KnHour,
        I2KnDateHour,
        I2KnFromNow,
        I2KnMonth,
        UserActionsFilter,
        I2KnFromToSince,
        HtmlNoSanitizePipe,
        HighlightSearch
    ]
})
export class PipesModule { }
