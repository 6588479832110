import { Component, Input, OnDestroy, Attribute } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Subject } from 'rxjs';
import { finalize, first, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import { AppSettings, Settings } from '@app/app.settings';
import { Page, Worklab } from '@models';
import { ServiceItems, ServiceErrors, ServiceToaster } from '@services';
import { DialogWorklabToPageConfirmationComponent } from './dialog-worklab-to-page-confirmation/dialog-worklab-to-page-confirmation.component';

@Component({
    selector: 'button-worklab-to-page',
    templateUrl: './button-worklab-to-page.html',
    styleUrls: ['./button-worklab-to-page.scss']
})
export class ButtonWorklabToPageComponent implements OnDestroy {
    @Input() disabled = false;


    private _worklab: Worklab;
    @Input()
    set worklab(value: Worklab) {
        this._worklab = value;
    }
    get worklab(): Worklab {
        return this._worklab;
    }

    public get propagateClick(): 'true' | 'false' {
        return this._propagateClick;
    }
    public set propagateClick(value: 'true' | 'false') {
        this._propagateClick = value;
    }

    public settings: Settings;
    private _destroyed$ = new Subject();

    constructor(
        private router: Router,
        private _dialog: MatDialog,
        private appSettings: AppSettings,
        private serviceItems: ServiceItems,
        private serviceErrors: ServiceErrors,
        private serviceToaster: ServiceToaster,
        @Attribute('propagateClick') private _propagateClick: 'true' | 'false' = 'true'
    ) {
        this.settings = this.appSettings.settings;
    }

    ngOnDestroy() {
        this._dialog.closeAll();
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }

    openDialog() {
        const worklab = this.worklab;
        const title = worklab.title;

        const dialogConfig = new MatDialogConfig();
        dialogConfig.minWidth = '20%';
        const dialogRef = this._dialog.open(DialogWorklabToPageConfirmationComponent, dialogConfig);
        dialogRef.afterClosed().pipe(
            first(),
            takeUntil(this._destroyed$)
        ).subscribe(
            (result: boolean) => {
                if (result === true) {
                    this.settings.savingInsideSpinner = true;
                    this.serviceItems.transformToPage(worklab).pipe(
                        finalize(() => {
                            this.settings.savingInsideSpinner = false;
                        }),
                        first(),
                        takeUntil(this._destroyed$)
                    ).subscribe(
                        (page: Page) => {
                            this.serviceToaster.success('WORKLAB_TO_PAGE', { title: title });
                            this.router.navigate([page.url]);
                        },
                        (error: HttpErrorResponse) => {
                            this.serviceErrors.handleError('WORKLAB_TO_PAGE', error);
                        }
                    );
                }
            });
    }
}
