import { Injectable, Injector } from '@angular/core';
import { map } from 'rxjs/operators';

import { Observable } from 'rxjs';
import { Page, Member, Worklab, Role } from '@models';
import { CommonService } from '@services/common.service';
import { ServiceDatastore } from './datastore.service';
import { ServiceSecurity } from './security.service';

@Injectable({ providedIn: 'root' })
export class ServiceCreators extends CommonService {

    constructor(
        protected injector: Injector,
        private serviceSecurity: ServiceSecurity,
        private serviceDatastore: ServiceDatastore
    ) {
        super(injector);
    }

    userCanModifyCreators(item: Page | Member | Worklab): boolean {
        const isUserAdmin = this.serviceSecurity.isAdmin();
        const isItemLocked = item.islocked;
        const isAccessGrantedByRole = this.serviceSecurity.hasMinimumRole(Role.ROLE_KB_ADMIN);

        return isUserAdmin || (!isItemLocked && isAccessGrantedByRole);
    }

    addCreator(item: Page | Member | Worklab, creator: Member): Observable<any> {
        return this.http.post(this.urlApi + 'creators/' + item.uid + '/' + creator.uid, {}).pipe(
            map(
                () => {
                    this.serviceDatastore.addCreator(item.id, creator.id, this.serviceSecurity.user);
                }
            )
        );
    }

    removeCreator(item: Page | Member | Worklab, creator: Member): Observable<any> {
        return this.http.delete(this.urlApi + 'creators/' + item.uid + '/' + creator.uid, {}).pipe(
            map(
                () => {
                    this.serviceDatastore.removeCreator(item.id, creator.id, this.serviceSecurity.user);
                }
            )
        );
    }
}
