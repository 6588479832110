import * as _ from 'lodash';
import { Component, Input, OnInit, OnDestroy, Inject } from '@angular/core';
import { takeUntil, first, finalize } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogConfig } from '@angular/material';

import { Item, Page } from '@models';
import { ServiceItems, ServiceErrors, ServiceToaster } from '@services';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
    selector: 'page-status',
    templateUrl: './page-status.component.html',
    styleUrls: ['./page-status.component.scss']
})
export class PageStatusComponent implements OnInit, OnDestroy {

    @Input() page: Page;
    @Input() editionMode: boolean;

    public isLoading = false;
    public isEditionGranted = false;
    private _destroyed$ = new Subject();

    constructor(
        private serviceItems: ServiceItems,
        private serviceErrors: ServiceErrors,
        private serviceToaster: ServiceToaster,
        private _dialog: MatDialog
    ) {
    }

    ngOnInit() {
        const serviceItem = this.serviceItems.getItemService(this.page.group);
        if (serviceItem.userCanModifyItem<Item>(this.page) || this.page.isUserExpert) {
            this.isEditionGranted = true;
        }
    }

    showPopup(): void {
        if (!this.isEditionGranted) {
            return;
        }
        const dialogConfig = new MatDialogConfig();
        dialogConfig.minWidth = '40%';
        dialogConfig.minHeight = '250px';
        dialogConfig.data = {
            selectedStatus: this.page.status
        };
        const dialogRef = this._dialog.open(DialogPageStatusComponent, dialogConfig);

        dialogRef.afterClosed().pipe(
            first(),
            takeUntil(this._destroyed$)
        ).subscribe(result => {
            const isCreation = _.isUndefined(this.page.id);
            if ( isCreation === true) {
                this.page.status = result;
            } else if (result && this.page.status !== result) {
                this.isLoading = true;
                this.serviceItems.changePageStatus(this.page, result).pipe(
                    finalize(() => {
                        this.isLoading = false;
                    }),
                    first(),
                    takeUntil(this._destroyed$)
                ).subscribe(
                    () => {
                        this.page.status = result;
                        this.serviceToaster.success('ITEM.PAGE_STATUS');
                    },
                    (error: HttpErrorResponse) => {
                        this.serviceErrors.handleError('ITEM.PAGE_STATUS', error);
                    }
                );
            }
        });
    }

    ngOnDestroy() {
        this._dialog.closeAll();
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }
}


export interface DialogSelectedStatusData {
    selectedStatus: 'draft' | 'to_validate' | 'validated';
}

@Component({
    selector: 'page-status-dialog',
    templateUrl: './page-status-dialog.html',
    styleUrls: ['./page-status.component.scss']
})
export class DialogPageStatusComponent {

    constructor(
        public dialogRef: MatDialogRef<DialogPageStatusComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogSelectedStatusData
    ) {
    }

    cancel(): void {
      this.dialogRef.close();
    }

}
