import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, ViewChild, Inject, InjectionToken} from '@angular/core';
import { Router } from '@angular/router';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { ServiceUrls } from '@services/urls.service';
import { DynamicLinksOut } from '@models/dynamic-links-out.model';
import { DynamicLinkOutDetail } from '@models/dynamic-links-out.model';

export const DYNAMIC_LINK_INJECTION_TOKEN = new InjectionToken<any>('DYNAMIC_LINK_INJECTION_TOKEN');

@Component({
    selector: 'dynamic-link',
    templateUrl: './dynamic-link.component.html',
    styleUrls: ['./dynamic-link.component.scss']
})
export class DynamicLinkComponent implements OnInit, OnDestroy {

    // We need to manually trigger the open/close state of the popup to ensure good hover interaction
    @ViewChild('popup', {static: false}) popup: NgbPopover;

    public orderedLinks: any = [];
    public selectedImageUrl: string;
    public imageHidden = false;

    constructor(
        @Inject(DYNAMIC_LINK_INJECTION_TOKEN) public dynamicLinks: DynamicLinksOut,
        private serviceUrls: ServiceUrls,
        private router: Router
    ) {

    }


    ngOnInit() {
        let currentKey = this.serviceUrls.kbSlug + ' ( ' + this.serviceUrls.societySlug + ' )';
        _.forEach(this.dynamicLinks.links, (d: DynamicLinkOutDetail) => {
            let key = d.kbTitle + ' ( ' + d.societyTitle + ' )';
            if (!(key in this.orderedLinks)) {
                this.orderedLinks[key] = {
                    key: key,
                    data: []
                };
            }
            const copy = _.clone(d);
            if (copy.isExternal && copy.imageUrl) {
                copy.imageUrl = '//' + copy.kbSlug + '.' + copy.societySlug + '.' + this.serviceUrls.sld + copy.imageUrl
            }
            if (!this.selectedImageUrl) {
                this.selectedImageUrl = copy.imageUrl;
            }
            this.orderedLinks[key].data.push(copy);
        });

        // Current society.kb should be the first link displayed
        this.orderedLinks = _.sortBy(_.values(this.orderedLinks), [function(l) {
            return l.key !== currentKey;
        }]);
    }

    /**
     * Event thrown when hovering <a> link in the popup
     * Used to update the displayed img
     * 
     * @param e DynamicLinkOutDetail
     */
    mouseEnter(e) {
        this.selectedImageUrl = e.imageUrl;
    }

    hide() {
        this.popup.close();
    }

    goToUrl(url: string) {
        this.popup.close();
        this.router.navigate([url]);
    }


    ngOnDestroy() {
        this.hide();
    }

    trackDynLinks(index: number, o: any) {
        // What if from an other KB?
        return o ? o.url : index;
    }

    trackOrderedLinks(index: number, o: any) {
        // What if from an other KB?
        return o ? o.key : index;
    }
}
