import * as _ from 'lodash';
import { Injectable, Injector } from '@angular/core';
import { tap, map } from 'rxjs/operators';
import { Tag, Page, Member, Worklab, Role } from '@models';
import { Observable, of, concat } from 'rxjs';
import { CommonService } from '@services/common.service';
import { ServiceDatastore } from './datastore.service';
import { ServiceSecurity } from './security.service';
import { BehaviorSubject, Subject } from 'rxjs';

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable()
export class ChecklistDatabase {
    dataChange = new BehaviorSubject<Tag[]>([]);

    get data(): Tag[] { return this.dataChange.value; }

    constructor() { }

    initialize(jsonFlatTree: Tag[]) {
        const sortedJsonFlatTree = jsonFlatTree.sort(function (tag1: Tag, tag2: Tag) {
            if (tag1.title < tag2.title) {
                return -1;
            } else if (tag1.title > tag2.title) {
                return 1;
            } else {
                return 0;
            }
        });
        const data = this._getNestedChildren(sortedJsonFlatTree, null);
        this.dataChange.next(data);
    }

    /**
     * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
     * The return value is the list of `Tag`.
     */
    private _getNestedChildren(arr, parent) {
        const out = [];
        for (const i in arr) {
            if ((arr[i].parent && arr[i].parent.id === parent) || (!arr[i].parent && !parent)) {
                const children = this._getNestedChildren(arr, arr[i].id);

                if (children.length) {
                    arr[i].children = children;
                }
                out.push(arr[i]);
            }
        }
        return out;
    }

    /** Add an item to to-do list */
    insertTag(parent: Tag, tag: Tag): Tag {
        if (!parent.children) {
            parent.children = [];
        }
        const newItem = _.cloneDeep(tag);
        parent.children.push(newItem);
        this.dataChange.next(this.data);
        return newItem;
    }

    insertRootTag(tag: Tag): Tag {
        const newItem = _.cloneDeep(tag);

        this.data.splice(0, 0, newItem);
        this.dataChange.next(this.data);
        return newItem;
    }

    insertTagAbove(node: Tag, tag: Tag): Tag {
        const parentNode = this.getParentFromNodes(node);
        const newItem = _.cloneDeep(tag);

        if (parentNode != null) {
            parentNode.children.splice(parentNode.children.indexOf(node), 0, newItem);
        } else {
            this.data.splice(this.data.indexOf(node), 0, newItem);
        }
        this.dataChange.next(this.data);
        return newItem;
    }

    insertTagBelow(node: Tag, tag: Tag): Tag {
        const parentNode = this.getParentFromNodes(node);
        const newItem = _.cloneDeep(tag);

        if (parentNode != null) {
            parentNode.children.splice(parentNode.children.indexOf(node) + 1, 0, newItem);
        } else {
            this.data.splice(this.data.indexOf(node) + 1, 0, newItem);
        }
        this.dataChange.next(this.data);
        return newItem;
    }

    getParentFromNodes(node: Tag): Tag {
        for (let i = 0; i < this.data.length; ++i) {
            const currentRoot = this.data[i];
            const parent = this.getParent(currentRoot, node);
            if (parent != null) {
                return parent;
            }
        }
        return null;
    }

    getParent(currentRoot: Tag, node: Tag): Tag {
        if (currentRoot.children && currentRoot.children.length > 0) {
            for (let i = 0; i < currentRoot.children.length; ++i) {
                const child = currentRoot.children[i];
                if (child === node) {
                    return currentRoot;
                } else if (child.children && child.children.length > 0) {
                    const parent = this.getParent(child, node);
                    if (parent != null) {
                        return parent;
                    }
                }
            }
        }
        return null;
    }

    updateItem(node: Tag, name: string) {
        node.title = name;
        this.dataChange.next(this.data);
    }

    deleteItem(node: Tag) {
        this.deleteNode(this.data, node);
        this.dataChange.next(this.data);
    }

    copyPasteItem(from: Tag, to: Tag): Tag {
        const newItem = this.insertTag(to, from);
        if (from.children) {
            from.children.forEach(child => {
                this.copyPasteItem(child, newItem);
            });
        }
        return newItem;
    }

    copyPasteItemAbove(from: Tag, to: Tag): Tag {
        const newItem = this.insertTagAbove(to, from);
        if (from.children) {
            from.children.forEach(child => {
                this.copyPasteItem(child, newItem);
            });
        }
        return newItem;
    }

    copyPasteItemBelow(from: Tag, to: Tag): Tag {
        const newItem = this.insertTagBelow(to, from);
        if (from.children) {
            from.children.forEach(child => {
                this.copyPasteItem(child, newItem);
            });
        }
        return newItem;
    }

    deleteNode(nodes: Tag[], nodeToDelete: Tag) {
        const index = nodes.indexOf(nodeToDelete, 0);
        if (index > -1) {
            nodes.splice(index, 1);
        } else {
            nodes.forEach(node => {
                if (node.children && node.children.length > 0) {
                    this.deleteNode(node.children, nodeToDelete);
                }
            });
        }
    }
}

@Injectable({ providedIn: 'root' })
export class ServiceTags extends CommonService {
    private _allTags: Observable<Map<string,Tag>>;

    constructor(
        protected injector: Injector,
        private serviceSecurity: ServiceSecurity,
        private serviceDatastore: ServiceDatastore
    ) {
        super(injector);
    }

    sortArray(unsortedArray: Tag[], orderDirection: 'asc' | 'desc'): Tag[] {
        return _.orderBy(unsortedArray, function(tag: Tag) {
            return _.toLower(tag.path);
        }, [orderDirection]);
    }

    fetchAllTags(): Observable<Map<string,Tag>> {
        const useCache = !(!this._allTags);
        if (!this._allTags) {
            const fetchedTags = this.serviceDatastore.fetchAllTags(useCache);
            this._allTags = fetchedTags;
            return fetchedTags;
        } else {
            return this._allTags;
        }
    }

    userCanModifyTags(item: Page | Member | Worklab): boolean {
        const isUserAdmin = this.serviceSecurity.isAdmin();
        const isItemLocked = item.islocked;
        const isAccessGrantedByRole = this.serviceSecurity.hasMinimumRole(Role.ROLE_CONTRIBUTOR);

        return isUserAdmin || (!isItemLocked && isAccessGrantedByRole);
    }

    addTag(item: Page | Member | Worklab, tag: Tag): Observable<any> {
        return this.http.post(this.urlApi + 'tags/' + item.uid, tag).pipe(
            tap(
                (resultAllTags) => {
                    this._allTags = undefined;
                    const foundedTag = _.find(resultAllTags.item.tags, {title: tag.title});
                    if (foundedTag) {
                        const newTag = new Tag().deserialize(foundedTag);
                        this.serviceDatastore.addTag(item.id, newTag);
                    }
                }
            )
        );
    }

    removeTag(item: Page | Member | Worklab, tag: Tag): Observable<any> {
        return this.http.delete(this.urlApi + 'tags/' + item.uid + '/' + tag.uid, {}).pipe(
                tap(
                    () => {
                        this._allTags = undefined;
                        this.serviceDatastore.removeTag(item.id, tag);
                    }
                )
            );
    }

    removeOphansTag(): Observable<any> {
        return this.http.delete(this.urlApi + 'tags/orphans', {});
    }

    saveTags(newTags: any, removedTags: any, modifiedTags: any): Observable<any> {
        const sentData: any = {
            newTags: newTags,
            removedTags: removedTags,
            modifiedTags: modifiedTags
        };
        this._allTags = undefined;
        return this.http.post(this.urlApi + 'tags/', sentData);
    }

    resetAllTags() {
        this._allTags = undefined;
    }
}
