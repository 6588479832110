import { Component, EventEmitter, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DuplicateMemberOptions } from '@src/app/models/duplicate-member-options.model';

@Component({
    selector: 'dialog-new-member-confirmation',
    styleUrls: ['./dialog-new-member-confirmation.scss'],
    templateUrl: './dialog-new-member-confirmation.html',
})
export class DialogNewMemberConfirmationComponent implements OnInit {

    public optionsChoosen = new EventEmitter();

    public options: DuplicateMemberOptions;

    constructor(
        public dialogRef: MatDialogRef<DialogNewMemberConfirmationComponent>
    ) { }

    ngOnInit() {
        this.options = new DuplicateMemberOptions();
    }

    click(options: DuplicateMemberOptions) {
        this.optionsChoosen.emit(options);
        this.dialogRef.close();
    }
}
