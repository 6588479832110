import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CommonService } from './common.service';

import { IaResponse } from '@models';
import { ServiceUrls } from '@services/urls.service';

interface Docs {
  id: string;
  base: string;
  payload: any;
  title: string;
  uid: string;
  vector_score: string;
}

/**
 * Service to manage the IA
 * @export
 * @class ServiceIA
 */
@Injectable({ providedIn: 'root' })
export class ServiceIA extends CommonService {
  iaResponseObservable: Observable<IaResponse>;

  private kbSlug: string = ServiceUrls.getCurrentKbSlug();
  private societySlug: string = ServiceUrls.getCurrentSocietySlug();

  /**
   * Creates an instance of ServiceIA.
   * @param {Injector} injector The injector
   */
  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  /**
   * Get the closest document
   * @param {string} input The input
   * @returns {string} The IA response
   */
  closestDoc(input: string): Observable<string> {
    const paramsUnits = {
      kbslug: this.kbSlug,
      text: input,
      topk: '5'
    };
    return this.http.post<string>(this.urlApi + 'recommendations/closest_doc', paramsUnits);
  }

  /**
   * Get the response from the IA
   * @param {string} input The input
   * @param {string[]} documents The documents
   * @returns {string} The IA response
   */
  generateAnswer(input: string, documents: never[], size: string, language: string): Observable<string> {
    const docIds = documents.map((doc: Docs) => doc.uid);
    const paramsUnits = {
      kbslug: this.kbSlug,
      societyslug: this.societySlug,
      text: input,
      language,
      docIds,
      size
    };
    return this.http.post<string>(this.urlApi + 'recommendations/generate_answer', paramsUnits);
  }

  /**
   * Get the graph from the IA
   * @param {string[]} documents The documents
   * @returns {string} The IA response
   */
  buildGraph(documents: never[]): Observable<string> {
    const docIds = documents.map((doc: Docs) => doc.uid);
    const paramsUnits = {
      kbslug: this.kbSlug,
      societyslug: this.societySlug,
      docIds
    };
    return this.http.post<string>(this.urlApi + 'recommendations/build_graph', paramsUnits);
  }

  /**
   * Get the resume from the IA
   * @param {string} filename The file
   * @returns {string} The IA response
   */
  resumeFile(filename: string, size: string, language: string): Observable<string> {
    const paramsUnits = {
        kbslug: this.kbSlug,
        societyslug: this.societySlug,
        filename: filename,
        size,
        language
      };
    return this.http.post<string>(this.urlApi + 'recommendations/resume_file', paramsUnits);
  }
}