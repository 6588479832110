import { Component, OnInit, OnDestroy } from '@angular/core';
import { finalize, first, takeUntil } from 'rxjs/operators';
import { AppSettings } from '@app/app.settings';

import { ServicePreferences } from '@services';
import { Subject } from 'rxjs';

@Component({
    selector: 'preferences-datetime-format',
    templateUrl: './preferences-datetime-format.component.html',
    styleUrls: ['./preferences-datetime-format.component.scss']
})
export class PreferencesDatetimeFormatComponent implements OnInit, OnDestroy {

    public saving = false;
    public availableDatetimeFormats: string[] = [
        'dd/MM/yyyy HH:mm:ss',
        'dd MMMM yyyy HH:mm:ss',
        'M/d/yy h:mm:ss a',
        'MMMM d, yyyy h:mm:ss a'
    ];
    public currentUserDatetimeFormat: string;

    private _destroyed$ = new Subject();

    constructor(
        private appSettings: AppSettings,
        private servicePreferences: ServicePreferences
    ) {
    }

    ngOnInit() {
        this.currentUserDatetimeFormat = this.servicePreferences.getPreference('dateTimeFormat');
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }

    trackValues(index: number, o: string) {
        return o ? o : index;
    }

    changeDateTimeFormat($event) {
        this.appSettings.settings.dateTimeFormat = $event.value;

        this.saving = true;
        this.servicePreferences.savePreference({ 'dateTimeFormat': $event.value }).pipe(
            finalize(() => {
                this.saving = false;
            }),
            first(),
            takeUntil(this._destroyed$)
        ).subscribe();
    }
}
