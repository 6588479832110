import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ListingUsersService {

    private listChangedSource = new Subject<any>();
    listChanged$ = this.listChangedSource.asObservable();

    private reloadRequestedSource = new Subject<any>();
    reloadRequested$ = this.reloadRequestedSource.asObservable();

    listChanged(list: any) {
        this.listChangedSource.next(list);
    }

    reloadRequested(value: boolean) {
        this.reloadRequestedSource.next(value);
    }
}
