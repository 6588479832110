import { Component, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DialogBecomeContributorComponent } from './dialog-become-contributor/dialog-become-contributor.component';

@Component({
    selector: 'button-become-contributor',
    templateUrl: './button-become-contributor.html',
    styleUrls: ['./button-become-contributor.scss']
})
export class ButtonBecomeContributorComponent implements OnDestroy {

    constructor(private _dialog: MatDialog) { }

    becomeContributor() {
        this._dialog.open(DialogBecomeContributorComponent, new MatDialogConfig());
    }

    ngOnDestroy() {
        this._dialog.closeAll();
    }
}
