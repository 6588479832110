import * as _ from 'lodash';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Item, Page, Member, User, Role } from '@models';
import { CommonService } from '@services/common.service';
import { ServiceDatastore } from './datastore.service';
import { ServiceSecurity } from './security.service';

@Injectable({ providedIn: 'root' })
export class ServiceFollowers extends CommonService {

    constructor(
        protected injector: Injector,
        private serviceSecurity: ServiceSecurity,
        private serviceDatastore: ServiceDatastore
    ) {
        super(injector);
    }

    sortArray(unsortedArray: any[], orderDirection: 'asc' | 'desc'): any[] {
        return _.orderBy(unsortedArray, function (item: Member | Page) {
            let v = _.toLower(item.title);
            if (item.group === 'Member') {
                v = (_.toLower(_.get(item, 'lastname', '') + _.get(item, 'firstname', '')) === '')
                    ? _.toLower(item.title)
                    : _.toLower(_.get(item, 'lastname', '') + _.get(item, 'firstname', ''));
            }
            return v;
        }, [orderDirection]);
    }

    userCanModifyFollowers(item: Item): boolean {
        const isItemLocked = item.islocked;
        const itemCanHaveFollowers = (item.group === 'Page' || item.group === 'Member');
        const isUserAdmin = this.serviceSecurity.isAdmin();
        const isAccessGrantedByRole = this.serviceSecurity.hasMinimumRole(Role.ROLE_READER);

        return itemCanHaveFollowers && (isUserAdmin || (!isItemLocked && isAccessGrantedByRole));
    }

    startFollowing(item: Item, member: Member | User): Observable<any> {
        return this.http.post<any>(this.urlApi + 'links/' + member.uid + '/' + item.uid, {}).pipe(
            tap(
                () => {
                    this.serviceDatastore.startFollowing(member.id, item.id, this.serviceSecurity.user);
                }
            )
        );
    }

    stopFollowing(item: Item, member: Member | User): Observable<any> {
        return this.http.delete<any>(this.urlApi + 'links/' + member.uid + '/' + item.uid, {}).pipe(
            tap(
                () => {
                    this.serviceDatastore.stopFollowing(member.id, item.id, this.serviceSecurity.user);
                }
            )
        );
    }

    memberAStartsToFollowMemberB(follower: Member, followedMember: Member): Observable<any> {
        return this.http.post<any>(this.urlApi + 'links/' + follower.uid + '/' + followedMember.uid, {}).pipe(
            tap(
                () => {
                    this.serviceDatastore.memberAStartsToFollowMemberB(follower.id, followedMember.id, this.serviceSecurity.user);
                }
            )
        );
    }

    memberAStopToFollowMemberB(follower: Member, followedMember: Member): Observable<any> {
        return this.http.delete<any>(this.urlApi + 'links/' + follower.uid + '/' + followedMember.uid, {}).pipe(
            tap(
                () => {
                    this.serviceDatastore.memberAStopToFollowMemberB(follower.id, followedMember.id, this.serviceSecurity.user);
                }
            )
        );
    }
}
