import * as _ from 'lodash';
import { Page, Member, Worklab } from '.';

export class DuplicateOptions {
    duplicateTitle = true;
    duplicateContent = true;
    duplicateIsLockedOption = false;
    duplicateTags = false;

    duplicateExperts = false;
    duplicateLinkedPages = false;
    duplicateLinkedWorklabs = false;

    duplicateExpertises = false;
    duplicateFollowedPages = false;
    duplicateFollowedWorklabs = false;

    duplicateWorklabPages = false;
    duplicateParticipants = false;
    duplicateIsPrivateOption = false;

    constructor(item: Page | Member | Worklab) {
        const isPage = (_.get(item, 'group') === 'Page');
        const isMember = (_.get(item, 'group') === 'Member');
        const isWorklab = (_.get(item, 'group') === 'Worklab');

        this.duplicateIsLockedOption = (isPage || isMember || isWorklab);
        this.duplicateTags = (isPage || isMember || isWorklab);

        this.duplicateExperts = (isPage);
        this.duplicateLinkedPages = (isPage);
        this.duplicateLinkedWorklabs = (isPage);

        this.duplicateExpertises = (isMember);
        this.duplicateFollowedPages = (isMember);
        this.duplicateFollowedWorklabs = (isMember);

        this.duplicateWorklabPages = (isWorklab);
        this.duplicateParticipants = (isWorklab);
        this.duplicateIsPrivateOption = (isWorklab);
    }
}
