import * as _ from 'lodash';
import { Component, Input } from '@angular/core';
import { finalize, first, takeUntil } from 'rxjs/operators';

import { ServiceItems, ServicePages, ServiceToaster, ServiceErrors, ServiceTextAnalyser } from '@services';
import { Page } from '@models';
import { LinkedItemsComponent } from '../linked-items.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'associated-pages-out',
    templateUrl: './associated-pages-out.component.html',
    styleUrls: ['./associated-pages-out.component.scss']
})
export class AssociatedPagesOutComponent extends LinkedItemsComponent {

    private _page: Page;
    @Input()
    set page(value: Page) {
        this._page = value;
        this.handleEditionMode(this._editionMode);
    }
    get page(): Page {
        return this._page;
    }

    constructor(
        private serviceToaster: ServiceToaster,
        private serviceErrors: ServiceErrors,
        private serviceItems: ServiceItems,
        private servicePages: ServicePages,
        protected serviceTextAnalyser: ServiceTextAnalyser
    ) {
        super(servicePages, serviceTextAnalyser);
        this.field = 'associatedPagesOut';
    }

    protected handleOrder(orderDirection: 'asc' | 'desc') {
        if (this._page instanceof Page) {
            this._page.associatedPagesOut = this.servicePages.sortArray(this._page.associatedPagesOut, orderDirection);
        }
    }

    protected handleEditionMode(value: boolean) {
        if (this._page instanceof Page) {
            this.isCreation = _.isUndefined(this._page.id);
            this.canEdit = this.servicePages.userCanModifyAssociatedPages(this._page);
            if (value === true && this.canEdit === true) {
                this.loadAllPotentialLinkedItems<Page>(this._page.associatedPagesOut, this._page);
            }
        }
    }

    addLinkedItem(page: Page) {
        page.isLoading = true;
        this.ctrl.reset();
        this.ctrl.disable();
        this.actionInProgress = true;

        let associatedPagesOut = this._page.associatedPagesOut;
        associatedPagesOut.push(page);
        associatedPagesOut = this.servicePages.sortArray(associatedPagesOut, 'asc');
        this._page.associatedPagesOut = associatedPagesOut;

        if (!this.isCreation) {
            this.servicePages.linkPageAToPageB(this._page, page).pipe(
                finalize(() => {
                    this.ctrl.enable();
                    this.actionInProgress = false;
                    this.newLinkInput.nativeElement.focus();
                }),
                first(),
                takeUntil(this._destroyed$)
            ).subscribe(
                () => {
                    page.isLoading = false;
                    _.remove(this.allPotentialLinkedItems, function(o: Page) { return o.id === page.id; });

                    this._page.associatedPagesOut = associatedPagesOut;
                    if (this._page.witness) {
                        this._page.witness.associatedPagesOut = associatedPagesOut;
                    }
                    this.serviceItems.item = this._page;

                    const params = {pageA: this._page.title, pageB: page.title};
                    this.serviceToaster.success('ITEM.LINKED_PAGES_OUT.ADD', params);
                },
                (error: HttpErrorResponse) => {
                    _.remove(this._page.associatedPagesOut, function(o: Page) { return o.id === page.id; });
                    this.serviceErrors.handleError('ITEM.LINKED_PAGES_OUT.ADD', error);
                }
            );
        } else {
            page.isLoading = false;
            _.remove(this.allPotentialLinkedItems, function(o: Page) { return o.id === page.id; });
            this.ctrl.enable();
            this.actionInProgress = false;
            this.newLinkInput.nativeElement.focus();
        }
    }

    removeLinkedItem(page: Page) {
        page.isLoading = true;
        this.ctrl.disable();
        this.actionInProgress = true;

        if (!this.isCreation) {
            this.servicePages.unlinkPageAToPageB(this._page, page).pipe(
                finalize(() => {
                    page.isLoading = false;
                    this.ctrl.enable();
                    this.actionInProgress = false;
                }),
                first(),
                takeUntil(this._destroyed$)
            ).subscribe(
                () => {
                    this.allPotentialLinkedItems.push(page);

                    const associatedPagesOut = this._page.associatedPagesOut;
                    _.remove(associatedPagesOut, function(o: Page) { return o.id === page.id; });

                    this._page.associatedPagesOut = associatedPagesOut;
                    if (this._page.witness) {
                        this._page.witness.associatedPagesOut = associatedPagesOut;
                    }
                    this.serviceItems.item = this._page;

                    const params = {pageA: this._page.title, pageB: page.title};
                    this.serviceToaster.success('ITEM.LINKED_PAGES_OUT.REMOVE', params);
                },
                (error: HttpErrorResponse) => {
                    let associatedPagesOut = this._page.associatedPagesOut;
                    associatedPagesOut.push(page);
                    associatedPagesOut = this.servicePages.sortArray(associatedPagesOut, 'asc');

                    this._page.associatedPagesOut = associatedPagesOut;
                    if (this._page.witness){
                        this._page.witness.associatedPagesOut = associatedPagesOut;
                    }
                    this.serviceItems.item = this._page;

                    this.serviceErrors.handleError('ITEM.LINKED_PAGES_OUT.REMOVE', error);
                }
            );
        } else {
            this.allPotentialLinkedItems.push(page);
            _.remove(this._page.associatedPagesOut, function(o: Page) { return o.id === page.id; });
            page.isLoading = false;
            this.ctrl.enable();
            this.actionInProgress = false;
        }
    }
}
