
export class ProsimConstant {
    type = 'constant';
    id: string;
    error: string;
    molKey: string;
    propertyKey: string;
    value: any;
    loadingUnits: boolean;
    unit: string;
    units: string[];
    loadingValue: boolean;
}
export class ProsimDynamic {
    type = 'dynamic';
    id: string;
    error: string;
    molKey: string;
    propertyKey: string;
    loadingUnits: boolean;
    unit: string;
    units: string[];
    temperatureUnit: any;
    tmin: number;
    lower: number;
    tmax: number;
    upper: number;
    correlation: any;
    coefficients: any;
    loadingTemperatures: boolean;
    options: any;
}

export class DynamicProperty {
    units: string[];
    tmin: number;
    tmax: number;
    correlation: any;
    coefficients: any;
}

export class Mol {
    label: string;
    key: string;
}

export class ProsimProperty {
    label: string;
    key: string;
    property: string;
}

