import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { takeUntil } from 'rxjs/operators';

import { Member, Authorization, Kb, KbRole } from '@models';
import { ServiceSecurity, ServiceItems } from '@services';
import { ServiceUrls } from '@src/app/services/urls.service';

@Component({
    selector: 'authorizations',
    templateUrl: './authorizations.component.html',
    styleUrls: ['./authorizations.component.scss']
})
export class AuthorizationsComponent implements OnInit, OnDestroy {

    private _member: Member;
    @Input()
    set member(member: Member) {
        this._member = member;
    }
    get member(): Member {
        return this._member;
    }

    public loading = false;
    public description: string;
    public authorizations: Array<Authorization>;
    public showAllSocieties = false;
    public currentSocietySlug: string;

    private _destroyed$ = new Subject();

    constructor(
        private serviceItems: ServiceItems,
        private serviceUrls: ServiceUrls,
        private serviceSecurity: ServiceSecurity
    ) {
        this.currentSocietySlug = this.serviceUrls.societySlug;
    }

    ngOnInit() {
        const isCreation = _.isUndefined(this.member.id);
        const isCurrentUser = this.serviceSecurity.isCurrentUser(this.member);

        if (isCreation) {
            this.description = 'AUTHORIZATIONS.DESCRIPTION_NEW';
        } else if (isCurrentUser) {
            this.description = 'AUTHORIZATIONS.DESCRIPTION_OWN';
        } else {
            this.description = 'AUTHORIZATIONS.DESCRIPTION_OTHER';
        }

        this._loadMemberAuthorizations();
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }

    trackSocieties(index: number, o: Authorization) {
        return o ? o.society.slug : index;
    }

    trackKbRoles(index: number, o: KbRole) {
        return o ? o.kb.slug + o.role : index;
    }

    roleChanged($event: KbRole) {
        const showAllSocieties = this.showAllSocieties;
        const isCreation = _.isUndefined($event.member.id);
        const kb: Kb = $event.kb;
        const role = $event.role;
        if (isCreation) {
            const authorizations = {};
            authorizations[$event.kb.society.slug] = {};
            authorizations[$event.kb.society.slug][$event.kb.slug] = $event.role;

            this._member.rights = authorizations;
            this.serviceItems.item = this._member;
        } else {
            const authorizations = this.authorizations;
            _.each(authorizations, function (authorization: Authorization) {
                if (authorization.society.slug === kb.society.slug) {
                    _.each(authorization.kbRoles, function (kbRole: KbRole) {
                        if (kbRole.kb.slug === kb.slug) {
                            kbRole.role = role;
                        }
                    });
                }
            });
            const member = this.serviceItems.item;
            (<Member>member).role = role;
            this.serviceItems.item = member;
        }
        this.showAllSocieties = showAllSocieties;
    }

    changeShowAllSocieties(showAllSocieties) {
        this.showAllSocieties = showAllSocieties;
    }

    private _loadMemberAuthorizations(): void {
        this.loading = true;
        this.serviceSecurity.getAllAuthorizations(this.member).pipe(
            takeUntil(this._destroyed$)
        ).subscribe(
            (authorizations: Authorization[]) => {
                this.loading = false;
                this.authorizations = _.sortBy(authorizations, [function(a) { return a.society.title; }]);
            }
        );
    }
}
