import * as _ from 'lodash';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError, first  } from 'rxjs/operators';

import { Item, Topic, TopicCreationResponse } from '@models';
import { CommonService } from '@services/common.service';
import { ServiceDatastore } from './datastore.service';
import { ServiceSecurity } from './security.service';

@Injectable({
    providedIn: 'root'
})
export class ServiceTopics extends CommonService {

    constructor(
        protected injector: Injector,
        private serviceSecurity: ServiceSecurity,
        private serviceDatastore: ServiceDatastore
    ) {
        super(injector);
    }

    getAllTopics(item: Item): Observable<Topic[]> {
        const currentUser = this.serviceSecurity.user;
        return this.http.get<Topic[]>(this.urlApi + 'topics/' + item.uid, {}).pipe(
            map(
                (response: Topic[]) => {
                    return response.map((topic: Topic) => {
                        return new Topic().deserialize(topic, currentUser);
                    });
                }
            )
        );
    }

    createTopic(item: Item, text: string): Observable<TopicCreationResponse> {
        const currentUser = this.serviceSecurity.user;
        return this.http.post<any>(this.urlApi + 'topics/' + item.uid, {
            content: text
        }).pipe(
            map(
                (response: any) => {
                    response.topics = _.get(response, 'topics', []).map((topic: Topic) => {
                        return new Topic().deserialize(topic, currentUser);
                    });
                    return response;
                }
            )
        );
    }

    createSubTopic(item: Item, topic: Topic): Observable<TopicCreationResponse> {
        const currentUser = this.serviceSecurity.user;
        return this.http.post<TopicCreationResponse>(this.urlApi + 'topics/' + item.uid, {
            content: topic.newSubTopicText,
            parent: topic.id
        }).pipe(
            map(
                (response: any) => {
                    response.topics = _.get(response, 'topics', []).map((t: Topic) => {
                        return new Topic().deserialize(t, currentUser);
                    });
                    return response;
                }
            ),
            catchError( error => {
                return this.serviceErrors.throwError('ITEM.TOPICS.ADD', error);
            })
        );
    }

    removeTopic(item: Item, topic: Topic): Observable<Topic[]> {
        const currentUser = this.serviceSecurity.user;
        return this.http.delete<Topic[]>(this.urlApi + 'topics/' + item.uid + '/' + topic.uid, {}).pipe(
            map(
                (response: any) => {
                    return response.map((t: Topic) => {
                        return new Topic().deserialize(t, currentUser);
                    });
                }
            ),
            catchError( error => {
                return this.serviceErrors.throwError('ITEM.TOPICS.REMOVE', error);
            })
        );
    }

    getMyComments(group: string): Observable<Item[]> {
        const serviceDatastore = this.serviceDatastore;

        return this.http.get(this.urlApi + 'items/my-topics?group=' + _.capitalize(group)).pipe(
            first(),
            map(
                (result: Item[]) => {
                    return result.map((item: Item) => {
                        return serviceDatastore.find(item.id);
                    });
                }
            )
        );
    }
}
