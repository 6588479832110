import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map  } from 'rxjs/operators';

import { Page, Member, Worklab, EditionWarning } from '@models';
import { CommonService } from '@services/common.service';
import { ServiceSecurity } from './security.service';

@Injectable({
    providedIn: 'root'
})
export class ServiceEditionWarning extends CommonService {

    constructor(
        protected injector: Injector,
        private serviceSecurity: ServiceSecurity
    ) {
        super(injector);
    }

    addWarningFlag(item: Page | Member | Worklab): Observable<EditionWarning[]> {
        const currentUser = this.serviceSecurity.user;
        return this.http.put<EditionWarning[]>(this.urlApi + 'items/' + item.uid + '/modification-flag', {}).pipe(
            map(
                (warnings: EditionWarning[]) => {
                    let result = warnings.map((warning: EditionWarning) => {
                        return new EditionWarning().deserialize(warning, currentUser);
                    });
                    return result;
                }
            )
        );
    }

    removeWarningFlag(item: Page | Member | Worklab): Observable<any> {
        return this.http.delete<EditionWarning[]>(this.urlApi + 'items/' + item.uid + '/modification-flag', {});
    }
}
