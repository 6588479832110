import * as _ from 'lodash';
import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';

import { Member } from '@models';
import { ServiceMembers, ServiceSecurity, ServiceItems } from '@services';

@Component({
    selector: 'email',
    templateUrl: './email.component.html',
    styleUrls: ['./email.component.scss']
})
export class EmailComponent {
    @Input()
    set member(value: Member) {
        this._member = value;
        this.handleEditionMode(this._editionMode);
    }
    get member(): Member {
        return this._member;
    }
    @Input()
    set editionMode(value: boolean) {
        if(value){
            this.type = "text"
        }else{
            this.type = "password"
        }

        this._editionMode = value;
        this.handleEditionMode(this._editionMode);
    }
    get editionMode(): boolean {
        return this._editionMode;
    }

    constructor(
        private serviceItems: ServiceItems,
        private serviceMembers: ServiceMembers,
        private serviceSecurity: ServiceSecurity
    ) {
        this._isAdmin = this.serviceSecurity.isAdmin();
    }

    private _member: Member;

    private _editionMode: boolean;

    public isGranted = false;
    public isDisabled = false;
    public tooltip: string;
    public placeholder: string;
    public emailAlreadyUsed = false;
    public type = "password";

    private _isAdmin = false;
    private _canEdit = false;
    private _isCreation = false;
    private _destroyed$ = new Subject();

    private _loadExistingEmails() {
        this.serviceMembers.fetchAllEmails().pipe(
            takeUntil(this._destroyed$)
        ).subscribe();
    }

    protected handleEditionMode(value: boolean) {
        this._setAttributes();
        if (this.member instanceof Member) {
            this._isCreation = _.isUndefined(this.member.id);
            this._canEdit = this.serviceMembers.userCanModifyEmail(this.member);
            if (value === true && this._canEdit === true) {
                this._loadExistingEmails();
                this.emailAlreadyUsed = _.get(this, '_item.errors.email.key') === 'alreadyUsed';
            } else {
                this._destroyed$.next();
                this._destroyed$.complete();
                // this._destroyed$.unsubscribe();
            }
        }
    }

    private _setAttributes() {
        if (this.member instanceof Member) {
            this.isGranted = this.serviceMembers.userCanModifyEmail(this._member);

            this.tooltip = 'MEMBER.PLACEHOLDERS.EMAIL';
            this.placeholder = 'MEMBER.PLACEHOLDERS.EMAIL';
        }
    }

    checkNewEmail(): void {
        const email = this.member.email;
        this._member.email = email;
        this.serviceItems.item = this._member;
    }
}
