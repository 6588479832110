import * as _ from 'lodash';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { first, finalize, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs/Subject';

import { Role } from '@models';
import { Item, Page, Member, Worklab } from '@models/item.model';
import { RecommendationsService } from '@services/recommendations.service';
import { ServiceItems, ServiceWorklabs, ServiceFollowers, ServicePages, ServiceSecurity, ServiceErrors, ServiceToaster } from '@services';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'recommendations',
    templateUrl: './recommendations.component.html',
    styleUrls: ['./recommendations.component.scss']
})
export class RecommendationsComponent implements OnInit, OnDestroy {
    @Input() display = 'ul';

    @Input() showTitle = true;

    public title: string;
    public tooltipTitle: string;
    public tooltipPages: string;
    public tooltipMembers: string;
    public tooltipWorklabs: string;
    public tooltipLinkItem: string;
    public limit = 5;
    public group: string;
    public loading: boolean;
    public canView = false;
    public canUsePlus = false;
    public currentUserId: string;
    public recommendations: any = [];

    private recommendationsCache: any = {};
    private recommendationsSource = new Subject<any>();
    private _destroyed$ = new Subject();

    private _item: Item;
    @Input()
    set item(item: Item) {
        this._item = item;
        if (this.recommendations) {
            const recommendationsService = this.recommendationsService;
            _.forEach(this.recommendationsCache, function (value, key) {
                _.forEach(value, function (recommendedItem) {
                    recommendedItem.isLinked = recommendationsService.isItemLinked(item, recommendedItem, key);
                });
            });
            this.recommendationsSource.next(this.recommendationsCache[this.group]);
        }
    }
    get item(): Item {
        return this._item;
    }

    constructor(
        private route: ActivatedRoute,
        private serviceItems: ServiceItems,
        private servicePages: ServicePages,
        private worklabsService: ServiceWorklabs,
        private serviceToaster: ServiceToaster,
        private serviceErrors: ServiceErrors,
        private serviceFollowers: ServiceFollowers,
        private recommendationsService: RecommendationsService,
        private ngxTranslateService: TranslateService,
        private serviceSecurity: ServiceSecurity
    ) { }

    ngOnInit() {
        this.recommendationsCache = {};
        this.currentUserId = _.get(this, 'serviceSecurity.user.id');
        this.canView = this.serviceSecurity.hasMinimumRole(Role.ROLE_READER);
        this.canUsePlus = this.serviceSecurity.hasMinimumRole(Role.ROLE_CONTRIBUTOR);

        this.route.params.pipe(
            takeUntil(this._destroyed$)
        ).subscribe(() => {
            if (this.canView) {
                this.changeGroup();
            } else {
                this.title = 'ITEM.RECOMMENDATIONS.PAGES.TITLE';
            }
        });

        this.recommendations = [];
        this.recommendationsSource.asObservable().pipe(
            takeUntil(this._destroyed$)
        ).subscribe(
            reco => {
                this.recommendations = reco;
            }
        );

        this.ngxTranslateService.onDefaultLangChange.pipe(
            takeUntil(this._destroyed$)
        ).subscribe(
            () => {
                this.changeGroup(this.group);
            }
        );
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }

    trackIds(index: number, o: Item) {
        return o ? o.id : index;
    }

    changeGroup(group?: string) {
        this.group = group || 'Page';
        this.title = 'ITEM.RECOMMENDATIONS.' + _.toUpper(this.group) + 'S.TITLE';
        this.tooltipTitle = 'ITEM.RECOMMENDATIONS.' + _.toUpper(this.group) + 'S.TOOLTIP';
        if (!this.canView) {
            return;
        }

        this.tooltipPages = 'ITEM.RECOMMENDATIONS.PAGES.TITLE';
        this.tooltipMembers = 'ITEM.RECOMMENDATIONS.MEMBERS.TITLE';
        this.tooltipWorklabs = 'ITEM.RECOMMENDATIONS.WORKLABS.TITLE';

        if (this.group === 'Page') {
            if (this.item.group === 'Page') {
                this.tooltipLinkItem = 'ITEM.RECOMMENDATIONS.TOOLTIPS.ADD.PAGE_ITEM.TO_PAGE';
            } else if (this.item.group === 'Member') {
                this.tooltipLinkItem = 'ITEM.RECOMMENDATIONS.TOOLTIPS.ADD.PAGE_ITEM.TO_MEMBER';
            } else if (this.item.group === 'Worklab') {
                this.tooltipLinkItem = 'ITEM.RECOMMENDATIONS.TOOLTIPS.ADD.PAGE_ITEM.TO_WORKLAB';
            }
        } else if (this.group === 'Member') {
            if (this.item.group === 'Page') {
                this.tooltipLinkItem = 'ITEM.RECOMMENDATIONS.TOOLTIPS.ADD.MEMBER_ITEM.TO_PAGE';
            } else if (this.item.group === 'Member') {
                this.tooltipLinkItem = 'ITEM.RECOMMENDATIONS.TOOLTIPS.ADD.MEMBER_ITEM.TO_MEMBER';
            } else if (this.item.group === 'Worklab') {
                this.tooltipLinkItem = 'ITEM.RECOMMENDATIONS.TOOLTIPS.ADD.MEMBER_ITEM.TO_WORKLAB';
            }
        } else if (this.group === 'Worklab') {

            if (this.item.group === 'Page') {
                this.tooltipLinkItem = 'ITEM.RECOMMENDATIONS.TOOLTIPS.ADD.WORKLAB_ITEM.TO_PAGE';
            } else if (this.item.group === 'Member') {
                this.tooltipLinkItem = 'ITEM.RECOMMENDATIONS.TOOLTIPS.ADD.WORKLAB_ITEM.TO_MEMBER';
            } else if (this.item.group === 'Worklab') {
                this.tooltipLinkItem = 'ITEM.RECOMMENDATIONS.TOOLTIPS.ADD.WORKLAB_ITEM.TO_WORKLAB';
            }
        }

        if (_.size(_.get(this, 'recommendationsCache.' + this.group, [])) === 0) {
            this.loading = true;
            this.recommendationsService.getRecommendedItems(this.group, this.item)
                .pipe(
                    finalize(() => {
                        this.loading = false;
                    }),
                    first(),
                    takeUntil(this._destroyed$)
                )
                .subscribe(
                    (data: any) => {
                        this.recommendationsCache[this.group] = _.orderBy(data, ['score'], ['desc']);
                        this.recommendationsSource.next(this.recommendationsCache[this.group]);
                    },
                    (error: HttpErrorResponse) => {
                        this.serviceErrors.handleError('ITEM.RECOMMENDATIONS', error);
                    }
                );
        } else {
            this.recommendationsSource.next(this.recommendationsCache[this.group]);
        }
    }

    linkItem(linkedItem: Item) {
        linkedItem.isLoading = true;
        this._cancelAction(linkedItem, true);

        const translationKey = (_.get(this, 'item.group') === 'Page') ? 'ITEM.FOLLOWERS.PAGE' : 'ITEM.FOLLOWERS.MEMBER';

        if (_.get(this, 'item.group') === 'Page') {
            if (_.get(linkedItem, 'group') === 'Member') {
                this.serviceFollowers.startFollowing(this.item, linkedItem).pipe(
                    finalize(() => {
                        linkedItem.isLoading = false;
                    }),
                    first(),
                    takeUntil(this._destroyed$)
                ).subscribe(
                    (result: any) => {
                        const item = this.serviceItems.item;
                        if (this.serviceSecurity.isCurrentUser(linkedItem)) {
                            (<Page>item).isUserFollowing = true;
                        } else {
                            let followers = (<Page>item).followers;
                            followers.push(linkedItem);
                            followers = _.orderBy(followers, function (i) {
                                const v = _.toLower(_.get(i, 'lastname', '') + _.get(i, 'firstname', ''));
                                return (v === '') ? _.toLower(_.get(i, 'title', '')) : v;
                            }, ['asc']);
                            (<Page>item).followers = followers;
                        }
                        this.serviceItems.item = item;

                        const params = { member: _.get(result, 'itemFrom.title'), title: _.get(result, 'itemTo.title') };
                        this.serviceToaster.success(translationKey + '.ADD', params);
                    },
                    (error: HttpErrorResponse) => {
                        this._cancelAction(linkedItem);
                        this.serviceErrors.handleError(translationKey + '.ADD', error);
                    });
            } else if (_.get(linkedItem, 'group') === 'Worklab') {
                const worklab = <Worklab>linkedItem;
                const page = <Page>this.item;
                this.worklabsService.addPage(worklab, page).pipe(
                    finalize(() => {
                        linkedItem.isLoading = false;
                    }),
                    first(),
                    takeUntil(this._destroyed$)
                ).subscribe(
                    () => {
                        const item = this.serviceItems.item;

                        let linkedWorklabs = (<Page>item).linkedWorklabs;
                        linkedWorklabs.push(linkedItem);
                        linkedWorklabs = _.orderBy(linkedWorklabs, ['title'], ['asc']);
                        (<Page>item).linkedWorklabs = linkedWorklabs;

                        this.serviceItems.item = item;

                        const params = { page: page.title, worklab: worklab.title };
                        this.serviceToaster.success('ITEM.LINKED_PAGES_TO_WORKLAB.ADD', params);
                    },
                    (error: HttpErrorResponse) => {
                        this._cancelAction(linkedItem);
                        this.serviceErrors.handleError('ITEM.LINKED_PAGES_TO_WORKLAB.ADD', error);
                    });
            } else if (_.get(linkedItem, 'group') === 'Page') {
                const pageA = <Page>this.item;
                const pageB = <Page>linkedItem;

                this.servicePages.linkPageAToPageB(pageA, pageB).pipe(
                    finalize(() => {
                        linkedItem.isLoading = false;
                    }),
                    first(),
                    takeUntil(this._destroyed$)
                ).subscribe(
                    () => {
                        const item = this.serviceItems.item;

                        let associatedPagesOut = (<Page>item).associatedPagesOut;
                        associatedPagesOut.push(linkedItem);
                        associatedPagesOut = _.orderBy(associatedPagesOut, ['title'], ['asc']);
                        (<Page>item).associatedPagesOut = associatedPagesOut;

                        this.serviceItems.item = item;

                        const params = { pageA: pageA.title, pageB: pageB.title };
                        this.serviceToaster.success('ITEM.LINKED_PAGES_OUT.ADD', params);
                    },
                    (error: HttpErrorResponse) => {
                        this._cancelAction(linkedItem);
                        this.serviceErrors.handleError('ITEM.LINKED_PAGES_OUT.ADD', error);
                    });
            }
        } else if (_.get(this, 'item.group') === 'Member') {

            if (_.get(linkedItem, 'group') === 'Member') {
                this.serviceFollowers.startFollowing(linkedItem, <Member>this.item).pipe(
                    finalize(() => {
                        linkedItem.isLoading = false;
                    }),
                    first(),
                    takeUntil(this._destroyed$)
                ).subscribe(
                    (result: any) => {
                        const item = this.serviceItems.item;
                        if (this.serviceSecurity.isCurrentUser(linkedItem)) {
                            (<Member>item).isUserFollowing = true;
                        }

                        let followedMembers = (<Member>item).followedMembers;
                        followedMembers.push(linkedItem);
                        followedMembers = _.orderBy(followedMembers, function (i) {
                            const v = _.toLower(_.get(i, 'lastname', '') + _.get(i, 'firstname', ''));
                            return (v === '') ? _.toLower(_.get(i, 'title', '')) : v;
                        }, ['asc']);
                        (<Member>item).followedMembers = followedMembers;

                        this.serviceItems.item = item;

                        const params = { member: _.get(result, 'itemFrom.title'), title: _.get(result, 'itemTo.title') };
                        this.serviceToaster.success('ITEM.FOLLOWERS.MEMBER.ADD', params);
                    },
                    (error: HttpErrorResponse) => {
                        this._cancelAction(linkedItem);
                        this.serviceErrors.handleError('ITEM.FOLLOWERS.MEMBER.ADD', error);
                    });
            } else if (_.get(linkedItem, 'group') === 'Worklab') {
                const worklab = <Worklab>linkedItem;
                const member = <Member>this.item;

                this.worklabsService.startParticipating(worklab, member).pipe(
                    finalize(() => {
                        linkedItem.isLoading = false;
                    }),
                    first(),
                    takeUntil(this._destroyed$)
                ).subscribe(
                    () => {
                        const item = this.serviceItems.item;

                        let followedWorklabs = (<Member>item).followedWorklabs;
                        followedWorklabs.push(linkedItem);
                        followedWorklabs = _.orderBy(followedWorklabs, ['title'], ['asc']);
                        (<Member>item).followedWorklabs = followedWorklabs;

                        this.serviceItems.item = item;

                        const params = { member: member.title, worklab: worklab.title };
                        this.serviceToaster.success('ITEM.PARTICIPANTS.ADD', params);
                    },
                    (error: HttpErrorResponse) => {
                        this._cancelAction(linkedItem);
                        this.serviceErrors.handleError('ITEM.PARTICIPANTS.ADD', error);
                    });
            } else if (_.get(linkedItem, 'group') === 'Page') {
                const page = <Page>linkedItem;
                const member = <Member>this.item;

                this.serviceFollowers.startFollowing(page, member).pipe(
                    finalize(() => {
                        linkedItem.isLoading = false;
                    }),
                    first(),
                    takeUntil(this._destroyed$)
                ).subscribe(
                    () => {
                        const item = this.serviceItems.item;

                        let followedPages = (<Member>item).followedPages;
                        followedPages.push(linkedItem);
                        followedPages = _.orderBy(followedPages, ['title'], ['asc']);
                        (<Member>item).followedPages = followedPages;

                        this.serviceItems.item = item;

                        const params = { member: member.title, title: page.title };
                        this.serviceToaster.success('ITEM.FOLLOWERS.PAGE.ADD', params);
                    },
                    (error: HttpErrorResponse) => {
                        this._cancelAction(linkedItem);
                        this.serviceErrors.handleError('ITEM.FOLLOWERS.WORKLAB.ADD', error);
                    });
            }
        } else if (_.get(this, 'item.group') === 'Worklab') {
            if (_.get(linkedItem, 'group') === 'Member') {
                const worklab = <Worklab>this.item;
                const member = <Member>linkedItem;

                this.worklabsService.startParticipating(worklab, member).pipe(
                    finalize(() => {
                        linkedItem.isLoading = false;
                    }),
                    first(),
                    takeUntil(this._destroyed$)
                ).subscribe(
                    () => {
                        const item = this.serviceItems.item;
                        if (this.serviceSecurity.isCurrentUser(linkedItem)) {
                            (<Worklab>item).isUserParticipant = true;
                        }

                        let participants = (<Worklab>item).participants;
                        participants.push(linkedItem);
                        participants = _.orderBy(participants, function (i) {
                            const v = _.toLower(_.get(i, 'lastname', '') + _.get(i, 'firstname', ''));
                            return (v === '') ? _.toLower(_.get(i, 'title', '')) : v;
                        }, ['asc']);
                        (<Worklab>item).participants = participants;

                        this.serviceItems.item = item;

                        const params = { member: member.title, worklab: worklab.title };
                        this.serviceToaster.success('ITEM.PARTICIPANTS.ADD', params);
                    },
                    (error: HttpErrorResponse) => {
                        this._cancelAction(linkedItem);
                        this.serviceErrors.handleError('ITEM.PARTICIPANTS.ADD', error);
                    });
            } else if (_.get(linkedItem, 'group') === 'Page') {
                this.worklabsService.addPage(<Worklab>this.item, <Page>linkedItem).pipe(
                    finalize(() => {
                        linkedItem.isLoading = false;
                    }),
                    first(),
                    takeUntil(this._destroyed$)
                ).subscribe(
                    () => {
                        const item = this.serviceItems.item;

                        let linkedPages = (<Worklab>item).linkedPages;
                        linkedPages.push(linkedItem);
                        linkedPages = _.orderBy(linkedPages, ['title'], ['asc']);
                        (<Worklab>item).linkedPages = linkedPages;

                        const worklab = <Worklab>this.item;
                        const page = <Page>linkedItem;

                        const params = { page: page.title, worklab: worklab.title };
                        this.serviceToaster.success('ITEM.LINKED_PAGES_TO_WORKLAB.ADD', params);

                        this.serviceItems.item = item;
                    },
                    (error: HttpErrorResponse) => {
                        this._cancelAction(linkedItem);
                        this.serviceErrors.handleError('ITEM.LINKED_PAGES_TO_WORKLAB.ADD', error);
                    });
            }
        }
    }

    private _cancelAction(linkedItem, isLinked = false) {
        const recommendations = this.recommendations;
        const item = _.find(recommendations, { id: linkedItem.id });
        if (item) {
            (<Item>item).isLinked = isLinked;
            this.recommendationsSource.next(recommendations);
        }
    }
}
