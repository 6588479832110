import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FabButtonsConfiguration } from '@models';

@Injectable({ providedIn: 'root' })
export class ServiceTabs {

    private tabChangedSource = new Subject<FabButtonsConfiguration>();
    tabChangedObservable = this.tabChangedSource.asObservable();

    private tabIndexChangedSource = new Subject<number>();
    tabIndexObservable = this.tabIndexChangedSource.asObservable();

    private tabLabelSource = new Subject<string>();
    tabLabelObservable = this.tabLabelSource.asObservable();

    tabChanged(tab: FabButtonsConfiguration, tabLabel?: string) {
        this.tabChangedSource.next(tab);
        if (tabLabel) {
            this.tabLabelSource.next(tabLabel);
        }
    }

    tabIndexChanged(tabIndex: number) {
        this.tabIndexChangedSource.next(tabIndex);
    }
}
