import * as _ from 'lodash';
import { Component, OnInit, Inject, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatInput } from '@angular/material/input';
import { ServiceLatex } from '@services/latex.service';
import { Subject } from 'rxjs';
import { finalize, first, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ServiceErrors } from '@src/app/services/errors.service';
import { MatDialogLatexData } from '@src/app/models';

@Component({
    selector: 'dialog-latex',
    styleUrls: ['./dialog-latex.scss'],
    templateUrl: './dialog-latex.html',
})
export class DialogLatexComponent implements OnInit, OnDestroy {

    public imageBuilt = new EventEmitter();

    public loading = false;
    public imageSrc: string;
    public formula: string;

    private _request$: any;
    private _destroyed$ = new Subject();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: MatDialogLatexData,
        private serviceLatex: ServiceLatex,
        private serviceErrors: ServiceErrors,
        public dialogRef: MatDialogRef<DialogLatexComponent>
    ) {
    }

    ngOnInit() {
        const imageSrc = _.get(this, 'data.imageSrc', '');
        const formula = _.get(this, 'data.formula', '');
        const isNewPicture = (formula === 'latex' && imageSrc === '');
        if (!isNewPicture) {
            this.imageSrc = this.data.picture;
            this.formula = this.data.formula;
        }
    }

    getPicture(): void {
        if (this._request$){
            this._request$.unsubscribe();
        }
        this.loading = true;

        this._request$ = this.serviceLatex.getPicture(this.formula).pipe(
            finalize(() => {
                this.loading = false;
            }),
            first(),
            takeUntil(this._destroyed$)
        ).subscribe(
            (imagePath: string) => {
                this.imageSrc = 'data:image/png;base64,' + imagePath;
            },
            (error: HttpErrorResponse) => {
                this.serviceErrors.handleError('LATEX', error);
            }
        );
    }

    savePicture() {
        this.imageBuilt.emit({
            formula: this.formula,
            url: this.imageSrc
        });
        this.close();
    }

    close() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        if (this._request$){
            this._request$.unsubscribe();
        }
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }
}
