import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { ServiceUrls } from '@services/urls.service';

@Injectable({ providedIn: 'root' })
export class ServiceSaml {

    private samlLoginUrlSuffix: string;
    private samlLogoutUrlSuffix: string;

    constructor(private serviceUrls: ServiceUrls) {
        
    }

    public isSamlEnabled(): boolean {
        const isSamlEnabled = _.get(SAML_CONFIG, 'enabled', false);
        this.samlLoginUrlSuffix = _.get(SAML_CONFIG, 'LOGIN_URL_SUFFIX', '');
        this.samlLogoutUrlSuffix = _.get(SAML_CONFIG, 'LOGOUT_URL_SUFFIX', '');
        if (isSamlEnabled) {
            if (
                !_.isEmpty(this.samlLoginUrlSuffix) &&
                !_.isEmpty(this.samlLogoutUrlSuffix)
            ) {
                return true;
            } else {
                console.error("SAML is enabled but some config is missing.");
            }
        }
        return false;
    }

    public isSamlTestModeEnabled(): boolean {
        const isEnabled = _.get(SAML_CONFIG, 'TEST_MODE', false);
        return isEnabled;
    }

    public getSamlLoginUrl(): string {
        if (this.isSamlEnabled() || this.isSamlTestModeEnabled()) {
            return this.serviceUrls.sso + this.samlLoginUrlSuffix;
        }
    }

    public getSamlLogoutUrl(): string {
        if (this.isSamlEnabled() || this.isSamlTestModeEnabled()) {
            return this.serviceUrls.sso + this.samlLogoutUrlSuffix;
        }
    }
}
