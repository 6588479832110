import * as _ from 'lodash';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { forkJoin, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
// import debug from 'debug'
// const storeDebug = debug('i2kn:datastore')

import {
    ItemsDictionary,
    Item,
    Page,
    Member,
    DynamicLinkIn,
    DynamicLinksOut,
    DynamicLinkOutDetail,
    Worklab,
    Tag,
    Document,
    Role,
    User
} from '@models';
import { CommonService } from './common.service';

// FIXME : The datastore should aggregate all the other stores or shouldn't exists.
@Injectable({ providedIn: 'root' })
export class ServiceDatastore extends CommonService {
    public dataStoreObservable: Observable<ItemsDictionary>;
    private _dataStoreSubject: BehaviorSubject<ItemsDictionary>;

    private allTags: Map<string, Tag> = new Map<string, Tag>();

    get dataStore(): ItemsDictionary {
        return this._dataStoreSubject.value;
    }

    set dataStore(value: ItemsDictionary) {
        this._dataStoreSubject.next(value);
    }

    constructor(protected injector: Injector) {
        super(injector);

        this._dataStoreSubject = new BehaviorSubject<ItemsDictionary>(null);
        this.dataStoreObservable = this._dataStoreSubject.asObservable();
    }

    reset(isKbPublic: boolean) {
        if (isKbPublic !== true) {
            this.dataStore = {};
        }
    }

    // Cache results
    fetchAllTags(useCache: boolean = true): Observable<Map<string, Tag>> {
        if (useCache && this.allTags.size > 0) {
            return of(this.allTags);
        } else {
            return this.http.get(this.urlApi + 'tags').pipe(
                map((fetchedTags: any[]) => {
                    const allTags = new Map<string, Tag>();
                    fetchedTags.forEach((tag: any) => {
                        if(tag.id && tag.title && _.trim(tag.title) !== '') {
                            const newTag = new Tag().deserialize(tag);
                            allTags.set(newTag.id, newTag);
                        }
                    });
                    this.allTags = allTags;
                    return allTags;
                })
            );
        }
    }

    fetchAllItems(currentUser: User): Observable<ItemsDictionary> {
        const itemDictionnary = {};

        const getItems = this.http.get<Item[]>(this.urlApi + 'items/').pipe(
            catchError(() => {
                return of([]);
            })
        );

        const getTags = this.fetchAllTags();

        const isSummarizerEnabled =
            IS_SUMMARIZER_ENABLED === true ? true : false;
        const getSummaries = isSummarizerEnabled
            ? this.http.get(this.urlApi + 'recommendations/summaries').pipe(
                  catchError(() => {
                      return of([]);
                  })
              )
            : of([]);

        const getKbData = this.http.get(
            this.urlSso +
                'api/kbs/' +
                this.currentSocietySlug +
                '/' +
                this.currentKbSlug
        );

        return forkJoin([getItems, getKbData, getTags, getSummaries]).pipe(
            map(([resItems, resKbData, resTags, resSummaries]) => {
                const members = resKbData['members'];
                resItems.forEach((item: Item) => {
                    item.titleWithoutAccent = this.serviceTextAnalyser
                        .removeDiacritics(_.get(item, 'title', ''))
                        .toLowerCase();
                    if (item.group === 'Member') {
                        (<Member>item).role = _.get(
                            _.find(members, { email: item['email'] }),
                            'role',
                            Role['ROLE_NONE']
                        );
                    } else {
                        const tags = item.tags || [];
                        tags.forEach((tag: Tag) => {
                            tag.path = _.get(
                                resTags.get(tag.id),
                                'path',
                                tag.title
                            );
                        });
                    }
                    if (item.id in resSummaries) {
                        //item.summary = _.get(resSummaries, item.id, '').replace(/(<img[^>]+>)/gim, ' ');
                        item.summary = _.get(resSummaries, item.id, '').replace(
                            /(<([^>]+)>)/gim,
                            ''
                        );
                    }
                    itemDictionnary[item.id] = new Item(item, currentUser);
                });
                return itemDictionnary;
            })
        );
    }

    find<T extends Item>(itemId: string): T {
        if (this.dataStore && itemId in this.dataStore) {
            return this.dataStore[itemId] as T;
        }
    }

    findByTitle<T extends Item>(itemTitle: string): T {
        const item = _.find(this.dataStore, { title: itemTitle });
        return item as T;
    }

    sortArray<T extends Item>(
        unsortedArray: T[],
        orderDirection: 'asc' | 'desc'
    ): T[] {
        return _.orderBy(
            unsortedArray,
            function(item: T) {
                const v = _.toLower(
                    _.get(item, 'lastname', '') + _.get(item, 'firstname', '')
                );
                return v === '' ? _.toLower(item.title) : v;
            },
            [orderDirection]
        );
    }

    remove<T extends Item>(itemId: string) {
        const self = this;
        const item = this.find(itemId);

        if (item.group === 'Page') {
            _.each((<Page>item).experts, function(o: Member) {
                const member = self.find<Member>(o.id);
                if (member) {
                    _.remove(member.expertises, function(o: Page) {
                        return o.id === itemId;
                    });
                }
            });

            _.each((<Page>item).followers, function(o: Member) {
                const member = self.find<Member>(o.id);
                if (member) {
                    _.remove(member.followedPages, function(o: Page) {
                        return o.id === itemId;
                    });
                }
            });

            _.each((<Page>item).linkedWorklabs, function(o: Worklab) {
                const worklab = self.find<Worklab>(o.id);
                if (worklab) {
                    _.remove(worklab.linkedPages, function(o: Page) {
                        return o.id === itemId;
                    });
                }
            });
        } else if (item.group === 'Member') {
            _.each((<Member>item).expertises, function(o: Page) {
                const page = self.find<Page>(o.id);
                if (page) {
                    _.remove(page.experts, function(o: Member) {
                        return o.id === itemId;
                    });
                }
            });

            _.each((<Member>item).followedMembers, function(o: Member) {
                const member = self.find<Member>(o.id);
                if (member) {
                    _.remove(member.followers, function(o: Member) {
                        return o.id === itemId;
                    });
                }
            });

            _.each((<Member>item).followedPages, function(o: Page) {
                const page = self.find<Page>(o.id);
                if (page) {
                    _.remove(page.followers, function(o: Member) {
                        return o.id === itemId;
                    });
                }
            });

            _.each((<Member>item).followedWorklabs, function(o: Worklab) {
                const worklab = self.find<Worklab>(o.id);
                if (worklab) {
                    _.remove(worklab.participants, function(o: Member) {
                        return o.id === itemId;
                    });
                }
            });

            _.each((<Member>item).followers, function(o: Member) {
                const follower = self.find<Member>(o.id);
                if (follower) {
                    _.remove(follower.followedPages, function(o: Member) {
                        return o.id === itemId;
                    });
                    _.remove(follower.followedMembers, function(o: Member) {
                        return o.id === itemId;
                    });
                }
            });
        } else if (item.group === 'Worklab') {
            _.each((<Worklab>item).participants, function(o: Member) {
                const member = self.find<Member>(o.id);
                if (member) {
                    _.remove(member.followedWorklabs, function(o: Worklab) {
                        return o.id === itemId;
                    });
                }
            });

            _.each((<Worklab>item).linkedPages, function(o: Page) {
                const page = self.find<Page>(o.id);
                if (page) {
                    _.remove(page.linkedWorklabs, function(o: Worklab) {
                        return o.id === itemId;
                    });
                }
            });
        }

        if (itemId in this.dataStore) {
            delete this.dataStore[itemId];
        }
    }

    linkPageAToPageB(pageAId, pageBId) {
        const pageA = this.find<Page>(pageAId);
        const pageB = this.find<Page>(pageBId);

        if (pageA && pageB) {
            let associatedPagesOut = pageA.associatedPagesOut || [];
            associatedPagesOut.push(pageB);
            associatedPagesOut = this.sortArray<Page>(
                associatedPagesOut,
                'asc'
            );
            pageA.associatedPagesOut = associatedPagesOut;
        }
    }

    unlinkPageAToPageB(pageAId, pageBId) {
        const pageA = this.find<Page>(pageAId);
        const pageB = this.find<Page>(pageBId);
        if (pageA && pageB) {
            _.remove(pageA.associatedPagesOut, function(o: Page) {
                return o.id === pageBId;
            });
        }
    }

    addCreator(itemId, creatorId, currentUser: User) {
        const item = this.find<Item>(itemId);
        const creator = this.find<Member>(creatorId);

        if (item && creator) {
            let creators = item.creators || [];
            creators.push(creator);
            creators = this.sortArray<Member>(creators, 'asc');
            item.creators = creators;

            if (currentUser.id === creator.id) {
                item.isUserCreator = true;
            }
        }
    }

    removeCreator(itemId, creatorId, currentUser: User) {
        const item = this.find<Item>(itemId);
        const creator = this.find<Member>(creatorId);
        if (item && creator) {
            _.remove(item.creators, function(o: Member) {
                return o.id === creatorId;
            });

            if (currentUser.id === creator.id) {
                item.isUserCreator = false;
            }
        }
    }

    addExpert(pageId: string, expertId: string, currentUser: User) {
        const page = this.find<Page>(pageId);
        const expert = this.find<Member>(expertId);

        if (page && expert) {
            let experts = page.experts || [];
            experts.push(expert);
            experts = this.sortArray<Member>(experts, 'asc');
            page.experts = experts;

            let expertises = expert.expertises || [];
            expertises.push(page);
            expertises = this.sortArray<Page>(expertises, 'asc');
            expert.expertises = expertises;

            if (currentUser.id === expert.id) {
                page.isUserExpert = true;
            }
        }
    }

    removeExpert(pageId: string, expertId: string, currentUser: User) {
        const page = this.find<Page>(pageId);
        const expert = this.find<Member>(expertId);

        if (page && expert) {
            _.remove(page.experts, function(o: Member) {
                return o.id === expertId;
            });
            _.remove(expert.expertises, function(o: Page) {
                return o.id === pageId;
            });

            if (currentUser.id === expert.id) {
                page.isUserExpert = false;
            }
        }
    }

    memberAStartsToFollowMemberB(memberAId, memberBId, currentUser: User) {
        const memberA = this.find<Member>(memberAId);
        const memberB = this.find<Member>(memberBId);

        if (memberA && memberB) {
            let followedMembers = memberA.followedMembers || [];
            followedMembers.push(memberB);
            followedMembers = this.sortArray<Member>(followedMembers, 'asc');
            memberA.followedMembers = followedMembers;

            let followers = memberB.followers || [];
            followers.push(memberA);
            followers = this.sortArray<Member>(followers, 'asc');
            memberB.followers = followers;

            if (currentUser.id === memberA.id) {
                memberB.isUserFollowing = true;
            }
        }
    }

    memberAStopToFollowMemberB(memberAId, memberBId, currentUser: User) {
        const memberA = this.find<Member>(memberAId);
        const memberB = this.find<Member>(memberBId);

        if (memberA && memberB) {
            _.remove(memberA.followedMembers, function(o: Member) {
                return o.id === memberBId;
            });
            _.remove(memberB.followers, function(o: Member) {
                return o.id === memberAId;
            });

            if (currentUser.id === memberA.id) {
                memberB.isUserFollowing = false;
            }
        }
    }

    startParticipating(worklabId, memberId, currentUser: User) {
        const worklab = this.find<Worklab>(worklabId);
        const member = this.find<Member>(memberId);

        if (worklab && member) {
            let participants = worklab.participants || [];
            participants.push(member);
            participants = this.sortArray<Member>(participants, 'asc');
            worklab.participants = participants;

            let followedWorklabs = member.followedWorklabs || [];
            followedWorklabs.push(worklab);
            followedWorklabs = this.sortArray<Worklab>(followedWorklabs, 'asc');
            member.followedWorklabs = followedWorklabs;

            if (currentUser.id === member.id) {
                worklab.isUserParticipant = true;
            }
        }
    }

    stopParticipating(worklabId, memberId, currentUser: User) {
        const worklab = this.find<Worklab>(worklabId);
        const member = this.find<Member>(memberId);

        if (worklab && member) {
            _.remove(worklab.participants, function(o: Member) {
                return o.id === memberId;
            });
            _.remove(member.followedWorklabs, function(o: Worklab) {
                return o.id === worklabId;
            });

            if (currentUser.id === member.id) {
                worklab.isUserParticipant = false;
            }
        }
    }

    startFollowing(memberId, pageId, currentUser: User) {
        const member = this.find<Member>(memberId);
        const page = this.find<Page>(pageId);
        if (page && member) {
            let followers = page.followers || [];
            followers.push(member);
            followers = this.sortArray<Member>(followers, 'asc');
            page.followers = followers;

            let followedPages = member.followedPages || [];
            followedPages.push(page);
            followedPages = this.sortArray<Page>(followedPages, 'asc');
            member.followedPages = followedPages;

            if (currentUser.id === member.id) {
                page.isUserFollowing = true;
            }
        }
    }

    stopFollowing(memberId, pageId, currentUser: User) {
        const member = this.find<Member>(memberId);
        const page = this.find<Page>(pageId);

        if (page && member) {
            _.remove(page.followers, function(o: Member) {
                return o.id === memberId;
            });
            _.remove(member.followedPages, function(o: Page) {
                return o.id === pageId;
            });

            if (currentUser.id === member.id) {
                page.isUserFollowing = false;
            }
        }
    }

    addPageToWorklab(pageId, worklabId) {
        const page = this.find<Page>(pageId);
        const worklab = this.find<Worklab>(worklabId);

        if (worklab && page) {
            let linkedPages = worklab.linkedPages || [];
            linkedPages.push(page);
            linkedPages = this.sortArray<Page>(linkedPages, 'asc');
            worklab.linkedPages = linkedPages;

            let linkedWorklabs = page.linkedWorklabs || [];
            linkedWorklabs.push(worklab);
            linkedWorklabs = this.sortArray<Page>(linkedWorklabs, 'asc');
            page.linkedWorklabs = linkedWorklabs;
        }
    }

    removePageFromWorklab(pageId, worklabId) {
        const page = this.find<Page>(pageId);
        const worklab = this.find<Worklab>(worklabId);

        if (worklab && page) {
            _.remove(worklab.linkedPages, function(o: Page) {
                return o.id === pageId;
            });
            _.remove(page.linkedWorklabs, function(o: Worklab) {
                return o.id === worklabId;
            });
        }
    }

    updateDocuments(itemId, documents: Document[]) {
        const item = this.find<Item>(itemId);
        if (item) {
            item.documents = _.orderBy(
                documents,
                function(d: Document) {
                    return _.toLower(d.title);
                },
                ['asc']
            );
        }
    }

    addDocument(itemId, document: Document) {
        const item = this.find<Item>(itemId);
        if (item) {
            _.remove(item.documents, function(o: Document) {
                return o.id === document.id;
            });

            let documents = item.documents || [];
            documents.push(document);
            documents = _.orderBy(
                documents,
                function(o: Document) {
                    return _.toLower(o.title);
                },
                ['asc']
            );
            item.documents = documents;
        }
    }

    removeDocument(itemId, document: Document) {
        const item = this.find<Item>(itemId);
        if (item) {
            _.remove(item.documents, function(o: Document) {
                return o.id === document.id;
            });
        }
    }

    addTag(itemId, tag: Tag) {
        const item = this.find<Item>(itemId);
        if (item) {
            let tags = item.tags || [];
            tags.push(tag);
            tags = _.orderBy(
                tags,
                function(o: Tag) {
                    return _.toLower(o.title);
                },
                ['asc']
            );
            item.tags = tags;
        }
    }

    removeTag(itemId, tag: Tag) {
        const item = this.find<Item>(itemId);
        if (item) {
            _.remove(item.tags, function(o: Tag) {
                return o.id === tag.id;
            });
        }
    }

    addAlias(itemId: string, alias: string) {
        const item = this.find<Item>(itemId);
        if (item) {
            _.remove(item.aliases, function(o: string) {
                return o === alias;
            });
            item.aliases = item.aliases || [];
            item.aliases.push(alias);
        }
    }

    removeAlias(itemId: string, alias: string) {
        const item = this.find<Item>(itemId);
        if (item) {
            _.remove(item.aliases, function(o: string) {
                return o === alias;
            });
        }
    }

    updateTitle(itemId: string, title: string) {
        const item = this.find<Item>(itemId);
        if (item) {
            item.title = title;
        }
    }

    // mid is the uploaded image for Member and the thumbnail for Page and Worklab
    updateAvatar(itemId: string, mid: string) {
        const item = this.find<Page | Worklab | Member>(itemId);
        if (item) {
            item.mid = mid;
        }
    }

    // cover is the uploaded image for Page and Worklab
    updateCover(itemId: string, cover: string) {
        const item = this.find<Page | Worklab>(itemId);
        if (item) {
            item.cover = cover;
        }
    }

    updateMemberRole(email: string, role: string) {
        const member = _.find(_.values(this.dataStore), {
            email: email
        }) as Member;
        if (member) {
            member.role = role;
        }
    }

    getLinksByItems(baseItems: Item[], appendChilds?: boolean): Item[] {
        appendChilds = appendChilds === true ? true : false;

        // Link types depends on current view dataset (especially for ItemDetail view)
        this.resetLinkTypes();

        // Create hashmap for perfs
        const resultDictionary = {};
        _.forEach(baseItems || [], (i: Item) => {
            resultDictionary[i.id + i.society + i.kb] = i;
        });

        _.forEach(baseItems, (item: Item) => {
            _.forEach(item.creators, (c: Member) => {
                const tmp = /* _.cloneDeep */ this.find(c.id);
                if (tmp) {
                    tmp.linkTypes.push('creator');
                    this.mergeData(resultDictionary, tmp, appendChilds);
                }
            });
            _.forEach(item.modifiers, (c: Member) => {
                const tmp = /* _.cloneDeep */ this.find(c.id);
                if (tmp) {
                    tmp.linkTypes.push('modifier');
                    this.mergeData(resultDictionary, tmp, appendChilds);
                }
            });

            _.forEach(item.dynlinksIn, (c: DynamicLinkIn) => {
                if (c.group !== 'Topic') {
                    let tmp;
                    if (!c.isExternal) {
                        tmp = /* _.cloneDeep */ this.find(c.id);
                    } else {
                        //
                        //
                        // TODO: rename societySlug & societyTitle to society in dynlinks.....
                        //
                        //
                        tmp = new Item(c);
                        tmp.society = c.societySlug;
                        tmp.kb = c.kbSlug;
                    }
                    if (tmp) {
                        tmp.linkTypes.push('dynlinksIn');
                        this.mergeData(resultDictionary, tmp, appendChilds);
                    }
                }
            });
            _.forEach(item.dynlinksOut, (c: DynamicLinksOut) => {
                _.forEach(c.links, (l: DynamicLinkOutDetail) => {
                    let tmp;
                    if (!l.isExternal) {
                        tmp = /* _.cloneDeep */ this.find(l.id);
                    } else {
                        tmp = new Item(l);
                        tmp.society = l.societySlug;
                        tmp.kb = l.kbSlug;
                    }
                    if (tmp) {
                        tmp.linkTypes.push('dynlinksOut');
                        this.mergeData(resultDictionary, tmp, appendChilds);
                    }
                });
            });

            if (item instanceof Page) {
                _.forEach(item.experts, (c: Member) => {
                    const tmp = /* _.cloneDeep */ this.find(c.id);
                    if (tmp) {
                        tmp.linkTypes.push('expert');
                        this.mergeData(resultDictionary, tmp, appendChilds);
                    }
                });
                _.forEach(item.followers, (c: Member) => {
                    const tmp = /* _.cloneDeep */ this.find(c.id);
                    if (tmp) {
                        tmp.linkTypes.push('memberFollowPage');
                        this.mergeData(resultDictionary, tmp, appendChilds);
                    }
                });
                _.forEach(item.associatedPagesOut, (c: Page) => {
                    const tmp = /* _.cloneDeep */ this.find(c.id);
                    if (tmp) {
                        tmp.linkTypes.push('associatedPageOut');
                        this.mergeData(resultDictionary, tmp, appendChilds);
                    }
                });
                _.forEach(item.linkedWorklabs, (c: Worklab) => {
                    const tmp = /* _.cloneDeep */ this.find(c.id);
                    if (tmp) {
                        tmp.linkTypes.push('linkedWorklab');
                        this.mergeData(resultDictionary, tmp, appendChilds);
                    }
                });
            }

            if (item instanceof Member) {
                _.forEach((item as Member).expertises, (c: Page) => {
                    const tmp = /* _.cloneDeep */ this.find(c.id);
                    if (tmp) {
                        tmp.linkTypes.push('expertise');
                        this.mergeData(resultDictionary, tmp, appendChilds);
                    }
                });
                _.forEach((item as Member).followedPages, (c: Page) => {
                    const tmp = /* _.cloneDeep */ this.find(c.id);
                    if (tmp) {
                        tmp.linkTypes.push('followedPage');
                        this.mergeData(resultDictionary, tmp, appendChilds);
                    }
                });
                _.forEach((item as Member).followedWorklabs, (c: Worklab) => {
                    const tmp = /* _.cloneDeep */ this.find(c.id);
                    if (tmp) {
                        tmp.linkTypes.push('followedWorklabs');
                        this.mergeData(resultDictionary, tmp, appendChilds);
                    }
                });
                _.forEach((item as Member).followers, (c: Member) => {
                    const tmp = /* _.cloneDeep */ this.find(c.id);
                    if (tmp) {
                        tmp.linkTypes.push('memberFollowMember');
                        this.mergeData(resultDictionary, tmp, appendChilds);
                    }
                });
            }

            if (item instanceof Worklab) {
                _.forEach(item.participants, (c: Member) => {
                    const tmp = /* _.cloneDeep */ this.find(c.id);
                    if (tmp) {
                        tmp.linkTypes.push('participant');
                        this.mergeData(resultDictionary, tmp, appendChilds);
                    }
                });
                _.forEach(item.linkedPages, (c: Page) => {
                    const tmp = /* _.cloneDeep */ this.find(c.id);
                    if (tmp) {
                        tmp.linkTypes.push('linkedPage');
                        this.mergeData(resultDictionary, tmp, appendChilds);
                    }
                });
            }
        });

        return _.values<Item>(resultDictionary);
    }

    private mergeData(
        itemsDictionary: ItemsDictionary,
        item: Item,
        appendChilds: boolean
    ): ItemsDictionary {
        if (item.id + item.society + item.kb in itemsDictionary) {
            itemsDictionary[
                item.id + item.society + item.kb
            ].linkTypes = _.uniq(
                itemsDictionary[
                    item.id + item.society + item.kb
                ].linkTypes.concat(item.linkTypes)
            );
        } else if (appendChilds) {
            itemsDictionary[item.id + item.society + item.kb] = item;
        }
        return itemsDictionary;
    }

    private resetLinkTypes() {
        _.forEach(this.dataStore, (item: Item) => {
            item.linkTypes = [];
        });
    }
}
