import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { ServiceToaster, ServiceKb, ServiceSecurity } from '../services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private serviceToaster: ServiceToaster,
        private serviceSecurity: ServiceSecurity,
        private serviceTranslate: TranslateService,
        private serviceKb: ServiceKb
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            const url = request.url;
            const isSSOError = (url.indexOf('//' + PREFIXS.ssoPrefix + '.') !== -1);
            const isAPIError = (url.indexOf('//' + PREFIXS.apiPrefix + '.') !== -1);

            // Servers down
            if (err.status === 0 && isSSOError) {
                this.router.navigate(['/sso-not-accessible'], { skipLocationChange: true, replaceUrl: false });
            } else if (err.status === 0 && isAPIError) {
                this.router.navigate(['/api-not-accessible'], { skipLocationChange: true, replaceUrl: false });
            } else if (err.status === 412) {
                if (request.url.indexOf('/dynamic-links/in') === -1 && request.url.indexOf('/dynamic-links/out') === -1) {
                    this.router.navigate(['/unknown-database'], { skipLocationChange: true, replaceUrl: false });
                }
            } else if (err.status === 403) {
                if (
                    request.url.indexOf('/dynamic-links/in') === -1 && 
                    request.url.indexOf('/dynamic-links/out') === -1 
                ) {
                    this.router.navigate(['/not-allowed']);
                }
            } else if (err.status === 401) {
                const message = _.get(err, 'error.message', '');
                if (request.url.indexOf('/api/token/refresh') !== -1) {
                    this.serviceToaster.warning(this.serviceTranslate.instant('ERRORS.SESSION_EXPIRED'));
                    const isKbPublic = _.get(this.serviceKb, 'kb.isPublic', false);
                    this.serviceSecurity.logout(isKbPublic);
                    this.router.navigate(['/login']);
                } else if ( message.substring(0, 9) === 'Username ' && message.substring(message.length - 16, message.length) === ' does not exist.') {
                    this.serviceToaster.error(this.serviceTranslate.instant('ERRORS.UNKNOWN_USER.TEXT'), this.serviceTranslate.instant('ERRORS.UNKNOWN_USER.TITLE'));
                    this.router.navigate(['/register']);
                }
            }
            return throwError(err);
        }));
    }
}
