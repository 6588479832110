import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

import { Kb } from '@models';
import { ServiceKb } from '@services';

@Component({
    selector: 'kb-news-popup',
    templateUrl: './kb-news-popup.component.html',
    styleUrls: ['./kb-news-popup.component.scss']
})
export class KbNewsPopupComponent {

    private _kb: Kb;
    @Input()
    set kb(value: Kb) {
        this._kb = value;
        this._setAttributes();
    }
    get kb(): Kb {
        return this._kb;
    }

    private _editionMode: boolean;
    @Input()
    set editionMode(value: boolean) {
        this._editionMode = value;
    }
    get editionMode(): boolean {
        return this._editionMode;
    }

    public fc: FormControl;
    public defaultNews: string;

    constructor(
        private serviceKb: ServiceKb,
        private serviceTranslate: TranslateService
    ) {
        this.defaultNews = '';//this.serviceTranslate.instant('NEWS_POPUP.NO_NEWS');
    }

    textChanged(){
        this.kb.news = this.fc.value;
        this.serviceKb.kb = this.kb;
    }

    private _setAttributes() {
        const value = this.kb.news || this.defaultNews;
        this.fc = new FormControl(value, []);
    }
}
