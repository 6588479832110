import * as _ from 'lodash';
import { Component, Input } from '@angular/core';
import { finalize, first, takeUntil } from 'rxjs/operators';

import { ServiceItems, ServicePages, ServiceWorklabs, ServiceToaster, ServiceErrors, ServiceTextAnalyser } from '@services';
import { Page, Worklab } from '@models';
import { LinkedItemsComponent } from '../linked-items.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'linked-pages-to-worklab',
    templateUrl: './linked-pages-to-worklab.component.html',
    styleUrls: ['./linked-pages-to-worklab.component.scss']
})
export class LinkedPagesToWorklabComponent extends LinkedItemsComponent {

    private _worklab: Worklab;
    @Input()
    set worklab(value: Worklab) {
        this._worklab = value;
        this.handleEditionMode(this._editionMode);
    }
    get worklab(): Worklab {
        return this._worklab;
    }

    constructor(
        private serviceToaster: ServiceToaster,
        private serviceErrors: ServiceErrors,
        private serviceItems: ServiceItems,
        private servicePages: ServicePages,
        private worklabsService: ServiceWorklabs,
        protected serviceTextAnalyser: ServiceTextAnalyser
    ) {
        super(servicePages, serviceTextAnalyser);
        this.field = 'linkedPages';
    }

    protected handleOrder(orderDirection: 'asc' | 'desc') {
        if (this._worklab instanceof Worklab) {
            this._worklab.linkedPages = this.servicePages.sortArray(this._worklab.linkedPages, orderDirection);
        }
    }

    protected handleEditionMode(value: boolean) {
        if (this._worklab instanceof Worklab) {
            this.isCreation = _.isUndefined(this._worklab.id);
            this.canEdit = this.worklabsService.userCanModifyPages(this._worklab);
            if (value === true && this.canEdit === true) {
                this.loadAllPotentialLinkedItems<Worklab>(this._worklab.linkedPages, this._worklab);
            }
        }
    }

    addPage(page: Page) {
        page.isLoading = true;
        this.ctrl.reset();
        this.ctrl.disable();
        this.actionInProgress = true;

        let linkedPages = this._worklab.linkedPages;
        linkedPages.push(page);
        linkedPages = this.servicePages.sortArray(linkedPages, 'asc');
        this._worklab.linkedPages = linkedPages;

        if (!this.isCreation) {
            this.worklabsService.addPage(this._worklab, page).pipe(
                finalize(() => {
                    this.ctrl.enable();
                    this.actionInProgress = false;
                    this.newLinkInput.nativeElement.focus();
                }),
                first(),
                takeUntil(this._destroyed$)
            ).subscribe(
                () => {
                    page.isLoading = false;
                    _.remove(this.allPotentialLinkedItems, function(o: Page) { return o.id === page.id; });

                    this._worklab.linkedPages = linkedPages;
                    if (this._worklab.witness) {
                        this._worklab.witness.linkedPages = linkedPages;
                    }
                    this.serviceItems.item = this._worklab;

                    const params = { page: page.title, worklab: this._worklab.title };
                    this.serviceToaster.success('ITEM.LINKED_PAGES_TO_WORKLAB.ADD', params);
                },
                (error: HttpErrorResponse) => {
                    _.remove(this._worklab.linkedPages, function(o: Page) { return o.id === page.id; });
                    this.serviceErrors.handleError('ITEM.LINKED_PAGES_TO_WORKLAB.ADD', error);
                });
        } else {
            page.isLoading = false;
            _.remove(this.allPotentialLinkedItems, function(o: Page) { return o.id === page.id; });
            this.ctrl.enable();
            this.actionInProgress = false;
            this.newLinkInput.nativeElement.focus();
        }
    }

    removePage(page: Page) {
        page.isLoading = true;
        this.ctrl.disable();
        this.actionInProgress = true;

        if (!this.isCreation) {
            this.worklabsService.removePage(this._worklab, page).pipe(
                finalize(() => {
                    this.ctrl.enable();
                    this.actionInProgress = false;
                    page.isLoading = false;
                }),
                first(),
                takeUntil(this._destroyed$)
            ).subscribe(
                () => {
                    this.allPotentialLinkedItems.push(page);

                    const linkedPages = this._worklab.linkedPages;
                    _.remove(linkedPages, function(o: Page) { return o.id === page.id; });

                    this._worklab.linkedPages = linkedPages;
                    if (this._worklab.witness) {
                        this._worklab.witness.linkedPages = linkedPages;
                    }
                    this.serviceItems.item = this._worklab;

                    const params = { page: page.title, worklab: this._worklab.title };
                    this.serviceToaster.success('ITEM.LINKED_PAGES_TO_WORKLAB.REMOVE', params);
                },
                (error: HttpErrorResponse) => {
                    let linkedPages = this._worklab.linkedPages;
                    linkedPages.push(page);
                    linkedPages = this.servicePages.sortArray(linkedPages, 'asc');

                    this._worklab.linkedPages = linkedPages;
                    if (this._worklab.witness) {
                        this._worklab.witness.linkedPages = linkedPages;
                    }
                    this.serviceItems.item = this._worklab;

                    this.serviceErrors.handleError('ITEM.LINKED_PAGES_TO_WORKLAB.REMOVE', error);
                }
            );
        } else {
            page.isLoading = false;
            this.allPotentialLinkedItems.push(page);
            _.remove(this._worklab.linkedPages, function(o: Page) { return o.id === page.id; });
            this.ctrl.enable();
            this.actionInProgress = false;
            page.isLoading = false;
        }
    }
}
