import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { MatomoTracker } from 'ngx-matomo';
import { ServiceKb } from '@services/kbs.service';

@Injectable({ providedIn: 'root' })
export class ServiceMatomo {

    private isInitialised: boolean = false;
    private isEnabled: boolean = false;
    private previousUrl: string;

    constructor(
        private serviceKb: ServiceKb,
        private matomoTracker: MatomoTracker
    ) {
    }

    public init() {
        if (this.isInitialised) {
            return;
        }
        if (_.get(MATOMO_CONSTANTS, 'enabled') === true && this.serviceKb.kb.slug in _.get(MATOMO_CONSTANTS, 'siteIds', {})) {
            this.isEnabled = true;
            //this.matomoTracker.enableCrossDomainLinking();
            //this.matomoTracker.enableLinkTracking(true);
            this.matomoTracker.setDoNotTrack(false);
        }
        this.isInitialised = true;
    }

    public trackSiteSearch(q: string, resultCount: number) {
        if (!this.isEnabled) {
            return;
        }
        this.matomoTracker.trackSiteSearch(q, undefined, resultCount);
    }

    public trackPageView(title?: string) {
        if (!this.isEnabled) {
            return;
        }

        // Init the previousUrl
        if (!this.previousUrl) {
            this.matomoTracker.getCurrentUrl()
                .then(url => {
                    this.previousUrl = url;
                    this.trackNow(title);
                })
                .catch(error => {
                    console.error(error)
                });
        } else {
            this.trackNow(title);
        }
    }

    private trackNow(title?: string) {
        this.matomoTracker.setReferrerUrl(this.previousUrl);
        this.matomoTracker.setCustomUrl(window.location.href);
        this.matomoTracker.trackPageView(title || document.title);
        this.previousUrl = window.location.href;
    }
}
