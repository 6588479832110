import { Deserializable } from "./deserializable.model";

export class Skin implements Deserializable {
    name: string;
    INTERNAL_ID: string;
    enabled: boolean;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}
