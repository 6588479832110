import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { ServicePreferences } from '@services';

@Pipe({
    name: 'i2KnFromToSince'
})
export class I2KnFromToSince implements PipeTransform {

    constructor(
        private servicePreferences: ServicePreferences
    ) {
    }

    transform(value: any, args?: any): any {
        const language = this.servicePreferences.getPreference('language');
        if (language === 'fr') {
            return value.replace('Il y a', 'Depuis');
        } else if (language === 'en') {
            return 'Since ' + value.replace('ago', '');
        }
        return value;
    }
}
