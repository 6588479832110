import * as _ from 'lodash';
import { Component, Input, Attribute } from '@angular/core';
import { Router } from '@angular/router';

import { Item } from '@models';
import { ServiceSecurity } from '@services/security.service';

@Component({
    selector: 'button-create-item',
    templateUrl: './button-create-item.html',
    styleUrls: ['./button-create-item.scss']
})
export class ButtonCreateItemComponent {

    // Needed to know the which kind of item we want to create
    @Input() group: string;

    // Needed to know the origin of the action in case of failure: to go back to this item
    @Input() item: Item;

    public get propagateClick(): 'true' | 'false' {
        return this._propagateClick;
    }
    public set propagateClick(value: 'true' | 'false') {
        this._propagateClick = value;
    }

    constructor(
        private router: Router,
        private serviceSecurity: ServiceSecurity,
        @Attribute('propagateClick') private _propagateClick: 'true' | 'false' = 'true'
    ) {
    }

    creatItem() {
        if (this.item) {
            this.serviceSecurity.setCookie('sourceUid', this.item.uid);
        }

        this.serviceSecurity.setCookie('sourceGroup', this.group);
        this.router.navigate(['/items/' + _.toLower(this.group) + '/add']);
    }
}
