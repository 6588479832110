export class FabButtonsConfiguration {
    group: string;
    downloadKb: boolean;
    editKb: boolean;

    downloadItems: boolean;
    importItems: boolean;
    createItem: boolean;
    editItem: boolean;
    duplicateItem: boolean;
    goToForum: boolean;
    removeItem: boolean;
    worklabToPage: boolean;
}