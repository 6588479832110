import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, OnInit, Input, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { AppSettings, Settings } from '@app/app.settings';
import {
  ServiceToaster,
  ServiceSecurity,
  ServiceItems,
} from '@services';
import { Item } from '@src/app/models';

/**
 * This is the component that displays the results of a natural search.
 *
 * @class ResumeIaComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-resume-ia',
  templateUrl: './resume-ia.component.html',
  styleUrls: ['./resume-ia.component.scss']
})
export class ResumeIaComponent implements OnInit, OnDestroy {

  @ViewChild('itemTitle', { static: false }) itemTitleElementRef: any;
  @ViewChild('itemContent', { static: false }) itemContentElementRef: any;

  @Input() responseText: string | undefined;
  @Input() responseTitle: string | undefined;
  @Input() responseLength: string | undefined;
  @Input() responseLanguage: string | undefined;
  @Input() regenerate: Function;

  public settings: Settings;

  public error: HttpErrorResponse;
  public response: string | undefined;
  public saving: boolean = false;
  public item: Item;

  public isCreation = false;
  public loading = false;

  private _destroyed$ = new Subject();

  /**
   * Creates an instance of ResumeIaComponent.
   * @memberof ResumeIaComponent
   */
  constructor(
    private appSettings: AppSettings,
    private router: Router,
    private serviceToaster: ServiceToaster,
    private serviceItems: ServiceItems,
    private serviceSecurity: ServiceSecurity,
  ) {
    this.settings = this.appSettings.settings;
  }

  /**
   * This method is called when the component is initialized.
   * @method ngOnInit
   */
  ngOnInit(): void {
    this.response = this.responseText.replace(/\\n/g, '<br/>');
  }

  /**
   * This method is called when the response is changed.
   * @method onResponseChange
   * @param {string} event.value - The value of the event.
   * @returns {void}
   */
  onResponseChange(event: any): void {
    this.responseLength = event.value;
  }

  /**
   * This method is called when the response language is changed.
   * @method onResponseLanguagesChanged
   * @param {string} event - The event object.
   * @returns {void}
   */
  onResponseLanguagesChanged(event: any): void {
    this.responseLanguage = event.value;
  }

  /**
   * This method is called when the regenerate button is clicked.
   * @method onRegenerate
   * @param {any} event - The event object.
   * @returns {void}
   */
  onRegenerate(event: any): void {
    this.regenerate(this.responseLength, this.responseLanguage);
  }

  /**
   * This method is called to copy text. 
   * @method onCopy
   * @param event - The event object.
   */
  onCopy(event: any) {
    if (!this.responseText) return;

    navigator.clipboard.writeText(this.response).then(() => {
      this.serviceToaster.success('NATURAL.RESUME.COPIED');
    });
  }

  /**
   * This method is called to create a new page with content.
   * @method onCreatePage
   * @param event - The event object.
   */
  onCreatePage(event: any) {

    const token = this.serviceSecurity.getAccessToken();

    const response = this.responseText.replace(/\\n/g, '<br/>');

    this.serviceItems.itemObservable.pipe(
      takeUntil(this._destroyed$)
    ).subscribe(
      (item: Item) => {
        if (item.title) return;
        this.item = item;
        item.title = this.responseTitle;
        item.content = response;
        item.editionMode = true;
        this.serviceItems.item = item;
      });

    this.serviceSecurity.setCookie('sourceGroup', 'page');

    this.router.navigate(['/items/' + _.toLower('page') + '/add']);
  }

  /**
   * This method is called when the component is destroyed.
   * @method ngOnDestroy
   */
  ngOnDestroy() {
    this._destroyed$.complete();
    this._destroyed$.unsubscribe();
  }
}