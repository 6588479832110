import * as _ from 'lodash';
import { Component, OnInit, Input } from '@angular/core';

import { Item } from '@models';

@Component({
    selector: 'group',
    templateUrl: './group.component.html',
    styleUrls: ['./group.component.scss']
})
export class GroupComponent implements OnInit {

    public url: string;
    public tooltip: string;

    @Input() item: Item;

    constructor() { }

    ngOnInit() {
        switch (this.item.group) {
            case 'Page':
                this.url = '/items/page';
                break;
            case 'Member':
                this.url = '/items/member';
                break;
            case 'Worklab':
                this.url = '/items/worklab';
                break;
        }
        this.tooltip = 'ITEM.GROUP.' + _.toUpper(this.item.group);
    }
}
