import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'show-all-societies',
    templateUrl: './show-all-societies.component.html',
    styleUrls: ['./show-all-societies.component.scss']
})
export class ShowAllSocietiesComponent {
    public showAllSocietiesIcon: string;
    public showAllSocieties = false;
    public showAllSocietiesTooltip: string;

    @Output() showAllSocietiesChanged = new EventEmitter();

    constructor() {
        this.showAllSocieties = false;
        this.showAllSocietiesIcon = 'fas fa-eye';
        this.showAllSocietiesTooltip = 'SOCIETY_VISIBILITY.SHOW_ALL';
    }

    toogleShowAllSocieties() {
        this.showAllSocieties = !this.showAllSocieties;
        this.showAllSocietiesChanged.emit(this.showAllSocieties);

        if (this.showAllSocieties === true) {
            this.showAllSocietiesIcon = 'fas fa-eye-slash';
            this.showAllSocietiesTooltip = 'SOCIETY_VISIBILITY.ONLY_CURRENT_SOCIETY';
        } else {
            this.showAllSocietiesIcon = 'fas fa-eye';
            this.showAllSocietiesTooltip = 'SOCIETY_VISIBILITY.SHOW_ALL';
        }
    }
}
