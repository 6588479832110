import * as _ from 'lodash';
import { Injectable, Injector, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Item, DynamicLinksOut, DynamicLinkOutDetail, DynamicLinkIn, MapNode } from '@models';
import { CommonService } from '@services/common.service';

@Injectable({ providedIn: 'root' })
export class ServiceMap extends CommonService {

    public filterDynamicLinkChanged: EventEmitter<boolean> = new EventEmitter();
    public filterClusterChanged: EventEmitter<boolean> = new EventEmitter();
    public filterViewTypeKeywordsChanged: EventEmitter<boolean> = new EventEmitter();
    public filterByKeywordsScoreChanged: EventEmitter<number> = new EventEmitter();
    public filterByKeywordsLinksCountChanged: EventEmitter<number> = new EventEmitter();

    constructor(protected injector: Injector) {
        super(injector);
    }

    public emitFilterDynamicLinkChanged(show: boolean) {
        this.filterDynamicLinkChanged.emit(show);
    }
    
    public emitFilterClusterChanged(isEnabled: boolean) {
        this.filterClusterChanged.emit(isEnabled);
    }

    public emitFilterViewTypeKeywordsChanged(isEnabled: boolean) {
        this.filterViewTypeKeywordsChanged.emit(isEnabled);
    }

    public emitFilterByKeywordsScoreChanged(scoreMin: number) {
        this.filterByKeywordsScoreChanged.emit(scoreMin);
    }

    public emitFilterByKeywordsLinksCountChanged(linksCountMin: number) {
        this.filterByKeywordsLinksCountChanged.emit(linksCountMin);
    }

    public getNodeFromItem(item: Item, societySlug?: string, kbSlug?: string): MapNode {
        const imgDiv = (item.group === 'Member') ? '' : '<br><div class="embedded-node-img"><img src="' + item.getImagePath() + '"></div>';
        const n: MapNode = {
            id: item.id,
            originalId: item.id,
            group: item.group,
            societySlug: item.society || societySlug,
            kbSlug: item.kb || kbSlug,
            title: item.title + imgDiv,
            label: item.title,
            shape: 'circularImage',
            image: '../assets/img/' + item.group + '.png',
            fixed: { x: true, y: false },
            isLoaded: false,
            isCollapsed: false,
            hidden: false
        };
        return n;
    }

    public getNodesFromDynamicLinksOut(dynlinksOut: DynamicLinksOut): MapNode[] {
        const nodes = [];
        _.forEach(dynlinksOut.links, (l: DynamicLinkOutDetail) => {
            let imgSrc = l.imageUrl;
            if (l.isExternal && l.imageUrl) {
                imgSrc = '//' + l.kbSlug + '.' + l.societySlug + '.' + this.serviceUrls.sld + l.imageUrl
            }
            const imgDiv = (l.group === 'Member') ? '' : '<br><div class="embedded-node-img"><img src="' + imgSrc + '"></div>';
            const n: MapNode = {
                id: l.id,
                originalId: l.id,
                group: l.group,
                societySlug: l.societySlug,
                kbSlug: l.kbSlug,
                title: dynlinksOut.word + imgDiv,
                label: l.title,
                shape: 'circularImage',
                image: '../assets/img/' + l.group + '.png',
                fixed: { x: true, y: false },
                isLoaded: false,
                isCollapsed: false,
                hidden: false,
                isDynamic: true,
                isRight: true,
                url: l.url
            };
            nodes.push(n);
        });
        return nodes;
    }

    public getNodeFromDynamicLinkIn(l: DynamicLinkIn): MapNode {
        let imgSrc = l.imageUrl;
        if (l.isExternal && l.imageUrl) {
            imgSrc = '//' + l.kbSlug + '.' + l.societySlug + '.' + this.serviceUrls.sld + l.imageUrl
        }
        const imgDiv = (l.group === 'Member') ? '' : '<br><div class="embedded-node-img"><img src="' + imgSrc + '"></div>';
        const n: MapNode = {
            id: l.id,
            originalId: l.id,
            group: l.group,
            societySlug: l.societySlug,
            kbSlug: l.kbSlug,
            title: l.title + imgDiv,
            label: l.title,
            shape: 'circularImage',
            image: '../assets/img/' + l.group + '.png',
            fixed: { x: true, y: false },
            isLoaded: false,
            isCollapsed: false,
            hidden: false,
            isDynamic: true,
            isLeft: true,
            // url: l.url
        };
        return n;
    }

    public getNodeFromKeyword(keyword: any): MapNode {
        const n: MapNode = {
            id: keyword,
            originalId: keyword,
            isKeyword: true,
            title: this.serviceTranslate.instant('FILTERS.MAP.KEYWORDS_VIEW.KEYWORD_LABEL') + ': ' + keyword,
            label: keyword,
            //shape: 'box',
            shape: 'dot',
            mass: 2,
            borderWidth: 1,
            size: 12,
            font: { size: 24 },
            fixed: { x: false, y: false },
            hidden: false
        };
        return n;
    }

    public getNetwork(): Observable<any> {
        return this.http.get(this.urlApi + 'items/map-network');
    }

    public saveNetwork(nodes: Array<MapNode>, edges): Observable<any> {
        return this.http.post(this.urlApi + 'items/map-network', {network: {nodes: nodes, edges: edges}});
    }
}
