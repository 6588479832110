import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService} from '@ngx-translate/core';

import { AppSettings } from '@app/app.settings';
import { ServiceUrls } from '@services/urls.service';
import { ServiceErrors } from '@services/errors.service';
import { ServiceToaster } from '@services/toasters.service';
import { ServiceTextAnalyser } from '@services/text-analyser.service';

@Injectable({ providedIn: 'root' })
export class CommonService {

    protected http: HttpClient;
    protected appSettings: AppSettings;
    protected serviceTranslate: TranslateService;
    protected serviceToaster: ServiceToaster;
    protected serviceUrls: ServiceUrls;
    protected serviceErrors: ServiceErrors;
    protected serviceTextAnalyser: ServiceTextAnalyser;

    public sld: string;
    public urlApi: string;
    public urlSso: string;
    public currentKbSlug: string;
    public currentSocietySlug: string;

    constructor(
        protected injector: Injector
    ) {
        this.http = injector.get(HttpClient);
        this.appSettings = injector.get(AppSettings);
        this.serviceToaster = injector.get(ServiceToaster);
        this.serviceUrls = injector.get(ServiceUrls);
        this.serviceErrors = injector.get(ServiceErrors);
        this.serviceTranslate = injector.get(TranslateService);
        this.serviceTextAnalyser = injector.get(ServiceTextAnalyser);

        this.sld = this.serviceUrls.sld;
        this.urlApi = this.serviceUrls.api;
        this.urlSso = this.serviceUrls.sso;
        this.currentKbSlug = this.serviceUrls.kbSlug;
        this.currentSocietySlug = this.serviceUrls.societySlug;
    }
}
