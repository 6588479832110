import { Injectable, Injector } from '@angular/core';
import { map, catchError, first } from 'rxjs/operators';
import { ItemsDictionary, Tag, Item, Page, Member, Worklab, Role } from '@models';
import { Observable } from 'rxjs';
import { CommonService } from '@services/common.service';

@Injectable({ providedIn: 'root' })
export class ServiceLatex extends CommonService {

    constructor(protected injector: Injector) {
        super(injector);
    }

    getPicture(forumla: string): Observable<string> {
        return this.http.post<string>(this.urlApi + 'latex', {'formula': forumla});
    }
}
