import * as _ from 'lodash';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Page, ProsimDynamic, ProsimConstant, Mol, ProsimProperty } from '@models';
import { ServiceProsim, ServicePages, ServiceItems } from '@src/app/services';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DialogProsimElementComponent } from './dialog-prosim/dialog-prosim-element.component';
import { DialogProsimListingComponent } from './dialog-prosim/dialog-prosim-listing.component';
import { DialogProsimGraphComponent } from './dialog-prosim/dialog-prosim-graph.component';
import { Options } from 'ng5-slider';

@Component({
    selector: 'prosim',
    templateUrl: './prosim.component.html',
    styleUrls: ['./prosim.component.scss']
})
export class ProsimComponent implements OnInit, OnDestroy {

    @Input() display = 'card';

    @Input() showTitle = true;

    protected _editionMode: boolean;
    @Input()
    set editionMode(value: boolean) {
        this._editionMode = value;
        this.limit = (value) ? 50 : 20;
        this.handleEditionMode(value);
    }
    get editionMode(): boolean {
        return this._editionMode;
    }

    public limit = 20;
    public canEdit = false;
    public actionInProgress = false;

    public mols: Mol[];
    public staticProperties: ProsimProperty[];
    public dynamicProperties: ProsimProperty[];
    public temperatureUnits: string[];

    private _page: Page;
    @Input()
    set page(value: Page) {
        this._page = value;
        this.handleEditionMode(this._editionMode);
    }
    get page(): Page {
        return this._page;
    }

    private _destroyed$ = new Subject();

    public value = 100;
    public options: Options = {
        floor: 0,
        ceil: 200
    };

    constructor(
        private _dialog: MatDialog,
        private serviceItems: ServiceItems,
        private servicePages: ServicePages,
        private serviceProsim: ServiceProsim
    ) { }

    ngOnInit() {
        this.mols = this.serviceProsim.getMols();
        this.staticProperties = this.serviceProsim.getStaticProperties();
        this.dynamicProperties = this.serviceProsim.getDynamicProperties();
        this.temperatureUnits = this.serviceProsim.getTemperatureUnits();
    }

    protected handleEditionMode(value: boolean) {
        if (this._page instanceof Page) {
            this.canEdit = this.servicePages.userCanModifyProsimData(this._page);
        }
    }

    trackIds(index: number, o: ProsimConstant | ProsimDynamic) {
        return o ? o.id : index;
    }

    removeProsim(prosimData: ProsimConstant | ProsimDynamic) {
        _.remove(this._page.allProsimData, function (o) {
            return o.id === prosimData.id;
        });
    }

    editProsim(prosimData: ProsimConstant | ProsimDynamic) {
        this.openModalProsim(prosimData);
    }

    openListing(prosimData: ProsimDynamic) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = prosimData || {};
        this._dialog.open(DialogProsimListingComponent, dialogConfig);
    }

    openGraph(prosimData: ProsimDynamic) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = prosimData || {};
        this._dialog.open(DialogProsimGraphComponent, dialogConfig);
    }

    changeTemperatureUnit(o: ProsimDynamic) {
        this.serviceProsim.searchTemperatures(o);
    }

    changeUnit(o: ProsimConstant) {
        this.serviceProsim.searchValue(o);
    }

    openModalProsim(prosimData: ProsimConstant | ProsimDynamic) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = prosimData || {};

        const dialogRef = this._dialog.open(DialogProsimElementComponent, dialogConfig);
        dialogRef.componentInstance.onAddProsimData.pipe(
            takeUntil(this._destroyed$)
        ).subscribe((data: ProsimConstant | ProsimDynamic) => {
            this.page.allProsimData = this.page.allProsimData || [];

            const prosimDataId = _.get(data, 'id');
            if (!prosimDataId) {
                data.id = Math.random().toString(36).substring(2, 15);
                this.page.allProsimData.push(data);
            } else {
                const line = _.find(this.page.allProsimData, {
                    id: prosimDataId
                });
                Object.assign(line, data);
            }
            const item = this.serviceItems.item;
            (<Page> item).allProsimData = this.page.allProsimData;
            this.serviceItems.item = item;
        });
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }
}
