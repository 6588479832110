import { NgModule, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { GuardMinimumRoleService } from '@guards/minimum-role.guard';
import { GuardIsIdentifiedService } from '@guards/is-identified.guard';
import { GuardIsSamlLoginService } from '@guards/is-saml-login.guard'
import { GuardIsSamlLoginTestModeService } from '@guards/is-saml-login-test-mode.guard'

import { PagesComponent } from '@pages/pages.component';
import { LoginComponent } from '@pages/login/login.component';
import { LoginTokenizerComponent } from '@pages/login/login-tokenizer.component';
import { TagsManagementComponent } from '@pages/tags-management/tags-management.component';
import { Role } from '@models/roles.enums';

export const routes: Routes = [
    {
        path: '',
        component: PagesComponent,
        canActivate: [GuardIsIdentifiedService],
        children: [
            { path: 'dashboards', loadChildren: './pages/dashboard/dashboard.module#DashboardModule', data: { breadcrumb: 'Dashboard' } },
            { path: 'kbs', loadChildren: './pages/kbs/kbs.module#KbsModule', data: { breadcrumb: 'All databases' } },
            { path: 'tags/management',
                loadChildren: './pages/tags-management/tags-management.module#TagsManagementModule', 
                data: { breadcrumb: 'Tag', minimumRole: Role.ROLE_KB_ADMIN } 
            },
            { path: '', loadChildren: './pages/items/items.module#ItemsModule', data: { breadcrumb: 'All Items' } },
        ]
    },
    { path: 'login', component: LoginComponent, pathMatch: 'full', canActivate: [GuardIsSamlLoginService] },
    { path: 'login-meetsys', component: LoginComponent, pathMatch: 'full' }, // login-meetsys used for i2kn auth if front has saml enabled
    { path: 'login-saml-test', component: LoginComponent, pathMatch: 'full', canActivate: [GuardIsSamlLoginTestModeService] }, // login-saml-test for test purpose
    { path: 'tokenize', component: LoginTokenizerComponent, pathMatch: 'full' }, // Redirection from IDP to Front if SAML process succeed
    {
        path: 'agreements',
        canActivate: [GuardMinimumRoleService, GuardIsIdentifiedService],
        data: { minimumRole: Role.ROLE_PUBLIC },
        loadChildren: './pages/agreements/agreements.module#AgreementsModule'
    },
    { path: 'register', loadChildren: './pages/register/register.module#RegisterModule' },
    { path: 'i-forgot-my-password', loadChildren: './pages/forgot-password/forgot-password.module#ForgotPasswordModule' },
    { path: '', loadChildren: './pages/errors/errors.module#ErrorsModule' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
        // enableTracing: true,
        // useHash: true
    })],
    exports: [RouterModule]
})
export class AppRouting { }
