import * as _ from 'lodash';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeUntil, finalize, first } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { AppSettings } from '@app/app.settings';

import { Skin } from '@models';
import { ServiceKb, ServicePreferences } from '@services';
import { MatSelectChange } from '@angular/material';

@Component({
    selector: 'preferences-skin',
    templateUrl: './preferences-skin.component.html',
    styleUrls: ['./preferences-skin.component.scss']
})
export class PreferencesSkinComponent implements OnInit, OnDestroy {

    public loading = false;
    public saving = false;
    public availableSkins: Skin[] = [];
    public currentUserSkin: string;

    private _destroyed$ = new Subject();

    constructor(
        private appSettings: AppSettings,
        private serviceKb: ServiceKb,
        private servicePreferences: ServicePreferences
    ) {
    }

    ngOnInit() {
        this.serviceKb.getAvailableSkins().pipe(
            finalize(() => {
                this.loading = false;
            }),
            first(),
            takeUntil(this._destroyed$)
        ).subscribe(
            (skins: Skin[]) => {
                this.availableSkins = _.filter(skins || [], function (o: Skin) {
                    return o.enabled === true;
                });
                this.currentUserSkin = this.servicePreferences.getPreference('theme');
            });
    }

    trackSkins(index: number, o: Skin) {
        return o ? o.INTERNAL_ID : index;
    }

    changeTheme($event: MatSelectChange) {
        this.appSettings.settings.theme = $event.value;

        this.saving = true;
        this.servicePreferences.savePreference({ 'theme': $event.value }).pipe(
            finalize(() => {
                this.saving = false;
            }),
            first(),
            takeUntil(this._destroyed$)
        ).subscribe();
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }
}
