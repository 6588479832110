import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, ViewChild, HostListener, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { AppSettings, Settings } from '@app/app.settings';

import { MenuService } from '@shared/components/menu/menu.service';
import { ServiceDatastore } from '@services/datastore.service';
import { ServiceListings } from '@services/listings.service';
import { ServiceItems, ServiceNotifications, ServiceSecurity } from '@services';
import { User, Item, Page, Member, Worklab, Notification } from '@models';

import { Subject } from 'rxjs/Subject';
import { takeUntil, share } from 'rxjs/operators';

@Component({
    selector: 'app-pages',
    templateUrl: './pages.component.html',
    styleUrls: ['./pages.component.scss'],
    providers: [MenuService]
})
export class PagesComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('sidenav', {static: false}) sidenav: any;
    @ViewChild('sideNavRight', {static: false}) sideNavRight: any;
    @ViewChildren(PerfectScrollbarDirective) pss: QueryList<PerfectScrollbarDirective>;

    public settings: Settings;
    public menuOption: string;
    public menuTypeOption: string;
    public isStickyMenu = false;
    public lastScrollTop = 0;
    public toggleSearchBar = false;
    public isUserAnonymous: boolean;
    public showBecomeContributorButton = false;
    public sideNavRightTabIndex = 0;
    public item: Item;
    public showWarning = true;
    public nbNotifications = 0;
    public q: string;

    private _interval$;
    private _destroyed$ = new Subject();

    constructor(
        private serviceItems: ServiceItems,
        private appSettings: AppSettings,
        private router: Router,
        private menuService: MenuService,
        private serviceDatastore: ServiceDatastore,
        private serviceListings: ServiceListings,
        private serviceNotifications: ServiceNotifications,
        private serviceSecurity: ServiceSecurity
    ) {
        this.settings = this.appSettings.settings;
    }

    ngOnInit() {
        if (window.innerWidth <= 768) {
            this.settings.menu = 'vertical';
            this.settings.sidenavIsOpened = false;
            this.settings.sidenavIsPinned = false;
        }

        // Check current user
        this.serviceSecurity.currentUserObservable.pipe(
            takeUntil(this._destroyed$)
        ).subscribe(
            () => {
                this.isUserAnonymous = this.serviceSecurity.isCurrentUserAnonymous();
                this.showBecomeContributorButton = this.serviceSecurity.isReader() && !this.serviceSecurity.hasRequestedToBecomeContributor();
            }
        );

        // Check current item for edition warning
        this.serviceItems.itemObservable
            .pipe(
                takeUntil(this._destroyed$),
                share()
            ).subscribe(
                (item) => {
                    if (item instanceof Page || item instanceof Member || item instanceof Worklab) {
                        this.item = item;
                        this.showWarning = this.item.editionMode && !_.isUndefined(this.item.id);
                    } else {
                        this.showWarning = false;
                    }
                }
            );

        // Refresh data every 60 seconds TODO
        this._loadNotifications();
        this._interval$ = setInterval(() => {
            this._loadNotifications();
        }, 60000);

        this.serviceNotifications.notificationsObservable.pipe(
            takeUntil(this._destroyed$)
        ).subscribe((notifications: Notification[]) => {
            this.nbNotifications = _.size(notifications);
        });
    }

    private _loadNotifications() {
        if (!this.isUserAnonymous) {
            this.serviceNotifications.getAllNotifications().pipe(
                takeUntil(this._destroyed$),
                share()
            ).subscribe();
        }
    }

    ngOnDestroy() {
        clearInterval(this._interval$);

        // this._destroyed$.next();
        // this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }

    ngAfterViewInit() {
        setTimeout(() => { this.settings.loadingSpinner = false; }, 300);
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (!this.settings.sidenavIsPinned) {
                    this.sidenav.close();
                }
                if (window.innerWidth <= 768) {
                    this.sidenav.close();
                }
            }
        });
        this.menuService.expandActiveSubMenu(this.menuService.getVerticalMenuItems());

        // Return to the top of the listing
        this.serviceListings.listingViewObservable.pipe(
            takeUntil(this._destroyed$)
        ).subscribe((value) => {
            if (value === 'list') {
                this.scrollToTop();
            }
        });
    }

    toggleSidenav() {
        this.sidenav.toggle();
        this.settings.sidenavIsOpened = !this.settings.sidenavIsOpened;
    }

    scrollToTop() {
        this.pss.forEach(ps => {
            if (ps.elementRef.nativeElement.id === 'main' || ps.elementRef.nativeElement.id === 'main-content') {
                ps.scrollToTop(0, 250);
            }
        });
    }

    login() {
        this.router.navigate(['/login']);
    }

    opensideNavRight($event: number) {
        if ($event >= 0) {
            this.sideNavRight.open();
            this.sideNavRightTabIndex = $event;
        }
    }

    @HostListener('window:resize')
    onWindowResize(): void {
        this.settings.menu = 'vertical';
        if (window.innerWidth <= 768) {
            this.settings.sidenavIsOpened = false;
            this.settings.sidenavIsPinned = false;
        } else {
            this.settings.sidenavIsOpened = true;
            this.settings.sidenavIsPinned = true;
        }
    }
}
