import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceSecurity } from '@services/security.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(
        private serviceSecurity: ServiceSecurity
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Disable Bearer for some urls
        const excludedUrl = [
            '/login',
            '/tokenize',
            '/authentication/get-token',
            '/register',
            '/i-forgot-my-password',
            '/refresh'
        ];
        if (excludedUrl.some((url) => (request.url.includes(url) && !request.url.includes('requests/register')))) {
            return next.handle(request);
        }

        // Add authorization header with jwt token if available
        // const currentUser = this.serviceSecurity.user;
        const token = this.serviceSecurity.getAccessToken();
        if (token) {
            request = request.clone({
                setHeaders: {
                    'Authorization': `Bearer ${token}`,
                    'X-Requested-With': 'XMLHttpRequest'
                }
            });
        }
        return next.handle(request);
    }
}
