import { Component, OnInit, OnDestroy } from '@angular/core';
import { finalize, first, takeUntil } from 'rxjs/operators';
import { AppSettings } from '@app/app.settings';

import { Kb } from '@models';
import { ServicePreferences } from '@services';
import { Subject } from 'rxjs';

@Component({
    selector: 'preferences-default-listing-view',
    templateUrl: './preferences-default-listing-view.component.html',
    styleUrls: ['./preferences-default-listing-view.component.scss']
})
export class PreferencesDefaultListingViewComponent implements OnInit, OnDestroy {

    public saving = false;
    public availableDefaultListingViews: string[] = [
        'TABLE',
        'TILES',
        'MAP',
        'SUMMARY'
    ];
    public currentUserDefaultListingView: string;

    private _destroyed$ = new Subject();

    constructor(
        private appSettings: AppSettings,
        private servicePreferences: ServicePreferences
    ) {
    }

    ngOnInit() {
        this.currentUserDefaultListingView = this.servicePreferences.getPreference('defaultListingView');
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }

    trackValues(index: number, o: string) {
        return o ? o : index;
    }

    changeDefaultListingViews($event) {
        this.appSettings.settings.defaultListingView = $event.value;

        this.saving = true;
        this.servicePreferences.savePreference({ 'defaultListingView': $event.value }).pipe(
            finalize(() => {
                this.saving = false;
            }),
            first(),
            takeUntil(this._destroyed$)
        ).subscribe();
    }
}
