import * as _ from 'lodash';
import { ViewChild, ElementRef } from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ServicePages, ServiceMembers, ServiceWorklabs, ServiceTextAnalyser } from '@services';
import { Item, Page, Member, Worklab } from '@models';
import { MultiAttributesComponent } from './multi-attributes.component';

export abstract class LinkedItemsComponent extends MultiAttributesComponent {

    @ViewChild('newLinkInput', {static: false}) newLinkInput: ElementRef<HTMLInputElement>;

    protected filteredItems: Observable<(Page | Member | Worklab)[]>;

    protected allPotentialLinkedItems: (Page | Member | Worklab)[] = [];

    protected handleOrder(orderDirection: 'asc' | 'desc') { }

    protected handleEditionMode(value: boolean) { }

    protected loadAllPotentialLinkedItems<T extends Item>(alreadyLinkedItems: T[], currentItem: Page | Member | Worklab) {
        this.loading = true;
        this.serviceTypeItem.fetchAllPotentialLinkedItems<T>(alreadyLinkedItems, currentItem).pipe(
            takeUntil(this._destroyed$)
        ).subscribe(
            (allPotentialLinkedItems: T[]) => {
                this.loading = false;
                this.allPotentialLinkedItems = allPotentialLinkedItems as T[];
            }
        );
    }

    protected resetInput() {
        this.ctrl.reset();
        this.ctrl.enable();
    }

    private _filterItems(query: string): Item[] {
        const queryLowerUnaccent = this.serviceTextAnalyser.removeDiacritics(query).toLowerCase();
        return this.allPotentialLinkedItems.filter(potentialLinkedItem => {
            let title = _.get(potentialLinkedItem, 'title', '').toLowerCase();
            let titleWithoutAccent = _.get(potentialLinkedItem, 'titleWithoutAccent', '').toLowerCase();
            return (title.indexOf(queryLowerUnaccent) !== -1 || titleWithoutAccent.indexOf(queryLowerUnaccent) !== -1);
        });
    }

    constructor(
        protected serviceTypeItem: ServicePages | ServiceMembers | ServiceWorklabs,
        protected serviceTextAnalyser: ServiceTextAnalyser
    ) {
        super(serviceTypeItem, serviceTextAnalyser);

        this.filteredItems = this.ctrl.valueChanges
            .pipe(
                startWith(null),
                debounceTime(200),
                distinctUntilChanged(),
                map(
                    (query: string) => {
                        return query ? this._filterItems(query) : []
                    }
                )
            );
    }
}
