import * as _ from 'lodash';
import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { takeUntil } from 'rxjs/operators';

import { Page, Worklab } from '@models';
import { ServiceItems, ServiceAliases, ServiceTextAnalyser } from '@services';
import { MultiAttributesComponent } from '../multi-attributes.component';

@Component({
    selector: 'aliases',
    templateUrl: './aliases.component.html',
    styleUrls: ['./aliases.component.scss']
})
export class AliasesComponent extends MultiAttributesComponent {

    readonly removable: boolean = true;
    readonly addOnBlur: boolean = false;
    readonly selectable: boolean = true;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];

    @ViewChild('chipList', {static: false}) chipList;
    @ViewChild('aliasInput', {static: false}) aliasInput: ElementRef<HTMLInputElement>;

    private _item: Page | Worklab;
    @Input()
    set item(value: Page | Worklab) {
        this._item = value;
        this.handleEditionMode(this._editionMode);
    }
    get item(): Page | Worklab {
        return this._item;
    }

    public isGranted = false;
    public newAliasAlreadyUsed: boolean;
    public newAliasValid = true;

    constructor(
        private serviceItems: ServiceItems,
        private serviceAliases: ServiceAliases,
        protected serviceTextAnalyser: ServiceTextAnalyser
    ) {
        super(serviceAliases, serviceTextAnalyser);
        this.field = 'aliases';
    }

    private _loadExistingAliasesAndTitles() {
        this.loading = true;
        this.serviceAliases.fetchAllAliasesAndTitles().pipe(
            takeUntil(this._destroyed$)
        ).subscribe(() => {
            this.loading = false;
        });
    }

    protected handleOrder(orderDirection: 'asc' | 'desc') {
        if (this._item instanceof Page || this._item instanceof Worklab) {
            this._item.aliases = this.serviceAliases.sortArray(this._item.aliases, orderDirection);
        }
    }

    protected handleEditionMode(value: boolean) {
        this._setAttributes();
        if (this._item instanceof Page || this._item instanceof Worklab) {
            this.isCreation = _.isUndefined(this._item.id);
            if (value === true && this.isGranted === true) {
                this._loadExistingAliasesAndTitles();
            } else {
                this._destroyed$.next();
                this._destroyed$.complete();
                // this._destroyed$.unsubscribe();
            }
        }
    }

    private _setAttributes() {
        if (this._item instanceof Page || this._item instanceof Worklab) {
            this.isGranted = this.serviceAliases.userCanModifyAliases(this._item);
        }
    }

    newAliasBlured(): void {
        this.ctrl.reset();
        this.chipList.errorState = false;
        this.aliasInput.nativeElement.value = '';
    }

    checkNewAlias(): void {
        const cleanTitle = this.serviceAliases.cleanAlias(_.get(this, 'item.title'));
        const newAlias = this.serviceAliases.cleanAlias(this.aliasInput.nativeElement.value);
        if (newAlias !== '') {
            this.newAliasAlreadyUsed = (this.serviceAliases.isNewAliasNotAlreadyUsed(newAlias) === false) || newAlias === cleanTitle;
            this.newAliasValid = (this.serviceAliases.isNewAliasValid(newAlias) === true);
            this.chipList.errorState = this.newAliasAlreadyUsed || !this.newAliasValid;
        } else {
            this.newAliasValid = true;
            this.chipList.errorState = false;
        }
    }

    addAlias(): void {
        const alias = this.aliasInput.nativeElement.value;
        const cleanTitle = this.serviceAliases.cleanAlias(_.get(this, 'item.title'));
        const newAlias = this.serviceAliases.cleanAlias(alias);
        this.newAliasAlreadyUsed = (this.serviceAliases.isNewAliasNotAlreadyUsed(newAlias) === false) || newAlias === cleanTitle;
        this.newAliasValid = (this.serviceAliases.isNewAliasValid(newAlias) === true) || alias === '';
        this.chipList.errorState = (alias !== '') && (this.newAliasAlreadyUsed || !this.newAliasValid);

        if (!this.newAliasAlreadyUsed && this.newAliasValid) {
            this.ctrl.reset();
            this.chipList.errorState = false;
            this.aliasInput.nativeElement.value = '';

            let aliases = this._item.aliases;
            aliases.push(newAlias);
            aliases = this.serviceAliases.sortArray(aliases, 'asc');
            this._item.aliases = aliases;
            this.serviceItems.item = this._item;

            this.serviceAliases.addAlias(this._item, newAlias);
        }
    }

    removeAlias(alias: string): void {
        this.ctrl.reset();
        this.chipList.errorState = false;
        this.aliasInput.nativeElement.value = '';

        const aliases = this._item.aliases;
        _.remove(aliases, function(o: string) {
            return o === alias;
        });
        this._item.aliases = aliases;
        this.serviceItems.item = this._item;

        this.serviceAliases.removeAlias(this._item, alias);
    }

    trackValues(index: number, o: string) {
        return o ? o : index;
    }
}
