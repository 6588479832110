import * as _ from 'lodash';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient, HttpRequest, HttpResponse, HttpErrorResponse, HttpEvent } from '@angular/common/http';

import { Kb } from '@models';
import { ServiceErrors } from '@services/errors.service';
import { ServiceToaster, ServiceKb, ServiceUrls } from '@src/app/services';
import { takeUntil, finalize } from 'rxjs/operators';
import { Subject } from 'rxjs';

const DEFAULT_UPLOAD_FORM = {
    filename: '',
    userFile: null
};

@Component({
    selector: 'kb-logo',
    templateUrl: './kb-logo.component.html',
    styleUrls: ['./kb-logo.component.scss']
})
export class KbLogoComponent implements OnInit, OnDestroy {

    @ViewChild('fileInput', {static: false})
    fileInput: ElementRef;

    private _editionMode: boolean;
    @Input()
    set editionMode(value: boolean) {
        this._editionMode = value;
    }
    get editionMode(): boolean {
        return this._editionMode;
    }

    private _kb: Kb;
    @Input()
    set kb(value: Kb) {
        this._kb = value;
    }
    get kb(): Kb {
        return this._kb;
    }

    public loading: boolean;
    public file: File;
    public uploadForm: FormGroup;
    public imageSrc: string;
    public imageAlt: string;
    public defaultPicture: string;
    private imagePath: string;

    private _destroyed$ = new Subject();

    constructor(
        private httpClient: HttpClient,
        private formBuilder: FormBuilder,
        private serviceToaster: ServiceToaster,
        private serviceErrors: ServiceErrors,
        private serviceUrls: ServiceUrls
    ) {
        const urlSso = this.serviceUrls.sso;
        const currentSocietySlug = this.serviceUrls.societySlug;

        this.imagePath = urlSso + 'images/' + currentSocietySlug + '/';
        this.defaultPicture = urlSso + 'images/i2kn.png';
    }

    ngOnInit() {
        const currentKbSlug = this.serviceUrls.kbSlug;

        this.imageSrc = this.imagePath + _.get(this, 'kb.slug', currentKbSlug) + '.png';
        this.imageAlt = _.get(this, 'kb.title', 'i2kn');

        this.uploadForm = this.formBuilder.group(DEFAULT_UPLOAD_FORM);
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }

    onSelectFile(event: any) {
        if (event.target.files && event.target.files.length > 0) {
            this.file = event.target.files[0];
            this.uploadForm.get('filename').setValue(this.file.name);
            this.sendFile();
        }
    }

    selectFile() {
        this.fileInput.nativeElement.click();
    }

    sendFile() {
        const url = this.serviceUrls.api + 'files/logo';

        this.loading = true;

        const formData: FormData = new FormData();
        formData.append('picture', this.file, this.file.name);

        const req = new HttpRequest('POST', url, formData);
        this.httpClient.request(req).pipe(
            finalize(() => {
                this.loading = false;
                this.uploadForm = this.formBuilder.group(DEFAULT_UPLOAD_FORM);
            }),
            takeUntil(this._destroyed$)
        ).subscribe(
            (event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    const currentKbSlug = this.serviceUrls.kbSlug;
                    this.imageSrc = this.imagePath + _.get(this, 'kb.slug', currentKbSlug) + '.png' + '?time=' + new Date().getTime();

                    this.serviceToaster.success('KB.LOGO.UPLOAD');
                }
            },
            (error: HttpErrorResponse) => {
                this.serviceErrors.handleError('KB.LOGO.UPLOAD', error);
            }
        );
    }
}
