import * as _ from 'lodash';
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize, first, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AppSettings } from '@app/app.settings';
import { EmailValidator } from '@shared/utils/app-validators';
import { ServiceErrors, ServiceSecurity, ServiceKb, ServiceToaster } from '@services';
import { Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-login',
    styleUrls: ['./login.scss'],
    templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {

    public form: FormGroup;
    public submitted = false;
    public loading = false;
    public passwordType = 'password';
    public viewPasswordIcon: any = 'fas fa-eye';

    private _returnUrl: string;
    private _destroyed$ = new Subject();

    constructor(
        private appSettings: AppSettings,
        private router: Router,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private serviceToaster: ServiceToaster,
        private serviceSecurity: ServiceSecurity,
        private serviceErrors: ServiceErrors,
        private serviceTranslate: TranslateService,
        private serviceKb: ServiceKb
    ) {
    }

    ngOnInit() {
        this._returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

        const email = this.serviceSecurity.getCookie('saved-email') || this.serviceSecurity.getUsername();
        this.form = this.formBuilder.group({
            'email': [email, Validators.compose([Validators.required, EmailValidator])],
            'password': [null, Validators.compose([Validators.required, Validators.minLength(6)])],
            'rememberMe': [null]
        });

        const isKbPublic = (!this.serviceKb.kb|| this.serviceKb.kb.isPublic);
        this.serviceSecurity.logout(isKbPublic, false);
        if (email && _.trim(email) !== '') {
            this.serviceSecurity.setCookie('saved-email', email, 0);
        }
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }

    ngAfterViewInit() {
        this.appSettings.settings.loadingSpinner = false;
    }

    onSubmit(): void {
        this.serviceToaster.clearAll();

        const email = this.form.controls.email.value;
        this.saveUsername();

        this.submitted = true;
        if (this.form.valid) {
            this.loading = true;
            this.form.get('email').disable();

            this.serviceSecurity
                .login(email, this.form.controls.password.value)
                .pipe(
                    finalize(() => {
                        this.form.get('email').enable();
                    }),
                    first(),
                    takeUntil(this._destroyed$)
                )
                .subscribe(
                    () => {
                        this.serviceSecurity.removeCookie('saved-email');
                        this.router.navigate([this._returnUrl]);
                    },
                    (error: HttpErrorResponse) => {
                        this.loading = false;

                        const message = _.get(error, 'error.message', '');
                        if ( message.substring(0, 9) === 'Username ' && message.substring(message.length - 16, message.length) === ' does not exist.') {
                            this.serviceToaster.error(this.serviceTranslate.instant('ERRORS.UNKNOWN_USER.TEXT'), this.serviceTranslate.instant('ERRORS.UNKNOWN_USER.TITLE'));
                            this.router.navigate(['/register']);
                        } else {
                            this.serviceErrors.handleError('LOGIN', error);
                        }
                    }
                );
        }
        return;
    }

    changePasswordType(passwordType) {
        this.passwordType = passwordType;
        this.viewPasswordIcon = (passwordType === 'text') ? 'fas fa-eye-slash' : 'fas fa-eye';
    }

    saveUsername() {
        const email = this.form.controls.email.value;
        if (email && _.trim(email) !== '') {
            this.serviceSecurity.setCookie('saved-email', email, 0);
        }
    }

    changeLanguage(language) {
        this.serviceSecurity.changeLanguage(language);
    }

    clickRememberMe(event) {
        const duration = (!event.checked) ? 0 : 10;
        this.serviceSecurity.setCookieExpiration(duration);
    }
}
