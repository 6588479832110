import * as _ from 'lodash';
import { Component, Input, OnInit, OnDestroy, ElementRef, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { takeUntil, mergeMap } from 'rxjs/operators';

import { Order, PreferencesTableColumns } from '@models';
import { ServiceListings, ServicePreferences } from '@services';
import { MatTableDataSource } from '@angular/material';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';

@Component({
    selector: 'items-as-table',
    styleUrls: ['./items-as-table.component.scss'],
    templateUrl: './items-as-table.component.html',
})
export class ItemsAsTableComponent implements OnInit, OnDestroy {
    @Input() options?: any;
    @Input() listOrder?: Order;

    public datasource: MatTableDataSource<any>;
    public displayedColumns: string[] = ['group'];
    public showNoItem = false;
    public tableColumns: PreferencesTableColumns;

    private filters: any;
    private _destroyed$ = new Subject();

    constructor(
        public elm: ElementRef,
        private servicePreferences: ServicePreferences,
        private renderer: Renderer2,
        public serviceListings: ServiceListings
    ) {
        this.serviceListings.datasourceObservable.pipe(
            takeUntil(this._destroyed$)
        ).subscribe(datasource => {
            this.datasource = datasource;
            this.showNoItem = (!datasource || _.size(datasource.filteredData) === 0);
        });

        const listingFiltersObservable = this.serviceListings.listingFiltersObservable;
        const tableColumnsObservable = this.serviceListings.tableColumnsObservable;
        combineLatest([listingFiltersObservable, tableColumnsObservable]).pipe(
            takeUntil(this._destroyed$)
        ).subscribe(([filters, tableColumns]) => {
            this.filters = filters;
            this.tableColumns = tableColumns;
            this.setDisplayedColumns();
        });
    }

    ngOnInit() {
        this.tableColumns = this.servicePreferences.getPreference('tableColumns');
        this.setDisplayedColumns();

        const isInItemDetailView = (this.serviceListings.type === 'detail');
        const offset = (isInItemDetailView === true) ? 200 : 250;
        this.renderer.setStyle(this.elm.nativeElement.querySelector('.items-as-table'), 'height', (window.innerHeight - offset) + 'px');
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();
    }

    private setDisplayedColumns() {
        const filters = this.filters;
        let displayedColumns = [];

        const columnsPages = this.tableColumns.Page;
        const keysPages = _.reduceRight(columnsPages, function (result, value, key) {
            if (value === true || key === 'title') {
                result.unshift(key);
            }
            return result;
        }, []);

        const columnsMembers = this.tableColumns.Member;
        const keysMembers = _.reduceRight(columnsMembers, function (result, value, key) {
            if (value === true || key === 'title') {
                result.unshift(key);
            }
            return result;
        }, []);

        const columnsWorklabs = this.tableColumns.Worklab;
        const keysWorklabs = _.reduceRight(columnsWorklabs, function (result, value, key) {
            if (value === true || key === 'title') {
                result.unshift(key);
            }
            return result;
        }, []);

        const showPages = (!filters || (_.has(filters, 'groups') && _.get(filters, 'groups').indexOf('Page') !== -1));
        if (showPages) {
            displayedColumns = _.union(displayedColumns, keysPages);
        }

        const showMembers = (!filters || (_.has(filters, 'groups') && _.get(filters, 'groups').indexOf('Member') !== -1));
        if (showMembers) {
            displayedColumns = _.union(displayedColumns, keysMembers);
        }

        const showWorklabs = (!filters || (_.has(filters, 'groups') && _.get(filters, 'groups').indexOf('Worklab') !== -1));
        if (showWorklabs) {
            displayedColumns = _.union(displayedColumns, keysWorklabs);
        }

        if (this.serviceListings.type === 'detail') {
            displayedColumns.push('linkTypes');
        }

        const orderedDisplayedColumns = [];
        if (displayedColumns.includes('isUserFollowing')) {
            orderedDisplayedColumns.push('isUserFollowing');
        }
        if (displayedColumns.includes('group')) {
            orderedDisplayedColumns.push('group');
        }
        if (displayedColumns.includes('pageStatus')) {
            orderedDisplayedColumns.push('pageStatus');
        }
        if (displayedColumns.includes('title')) {
            orderedDisplayedColumns.push('title');
        }
        if (displayedColumns.includes('roleInDatabase')) {
            orderedDisplayedColumns.push('roleInDatabase');
        }
        if (displayedColumns.includes('linkTypes')) {
            orderedDisplayedColumns.push('linkTypes');
        }
        if (displayedColumns.includes('nbTopics')) {
            orderedDisplayedColumns.push('nbTopics');
        }
        if (displayedColumns.includes('aliases')) {
            orderedDisplayedColumns.push('aliases');
        }
        if (displayedColumns.includes('tags')) {
            orderedDisplayedColumns.push('tags');
        }
        if (displayedColumns.includes('creators')) {
            orderedDisplayedColumns.push('creators');
        }
        if (displayedColumns.includes('modifiers')) {
            orderedDisplayedColumns.push('modifiers');
        }
        if (displayedColumns.includes('experts')) {
            orderedDisplayedColumns.push('experts');
        }
        if (displayedColumns.includes('followers')) {
            orderedDisplayedColumns.push('followers');
        }
        if (displayedColumns.includes('associatedPagesOut')) {
            orderedDisplayedColumns.push('associatedPagesOut');
        }
        if (displayedColumns.includes('linkedWorklabs')) {
            orderedDisplayedColumns.push('linkedWorklabs');
        }
        if (displayedColumns.includes('expertises')) {
            orderedDisplayedColumns.push('expertises');
        }
        if (displayedColumns.includes('followedPages')) {
            orderedDisplayedColumns.push('followedPages');
        }
        if (displayedColumns.includes('followedMembers')) {
            orderedDisplayedColumns.push('followedMembers');
        }
        if (displayedColumns.includes('followedWorklabs')) {
            orderedDisplayedColumns.push('followedWorklabs');
        }
        if (displayedColumns.includes('participants')) {
            orderedDisplayedColumns.push('participants');
        }
        if (displayedColumns.includes('linkedPages')) {
            orderedDisplayedColumns.push('linkedPages');
        }
        this.displayedColumns = orderedDisplayedColumns;
    }
}
